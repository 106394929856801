<div class="bg-white pb-3">
  <!-- <div class="sched-header text-white">UPCOMING GAMES</div> -->
  <div class="container">
    <div class="row">
      <p-table #dt [value]="games" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th>
              <div>TEAM</div>
            </th>
            <th>
              <div>DATE</div>
            </th>
            <th>
              <div>TIME</div>
            </th>
            <th>TOURNAMENT</th>
            <th>DIVISION</th>
            <th>COURT</th>
            <th>OPPONENT</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-game>
          <tr>
            <td>
              {{
                game.team1.coach == null ||
                game.team1.coach.id == null ||
                game.team1.coach.id !== user.id
                  ? game.team2.name
                  : game.team1.name
              }}
            </td>

            <td>{{ game.date | date: "dd-MM-yyyy" }}</td>
            <td>{{ game.timeslot }}</td>
            <td>{{ game.division.tournament.name }}</td>
            <td>
              {{ game.division.name }}
            </td>
            <td>
              {{ game.court.name }}
            </td>
            <td>
              {{
                game.team1.coach !== null &&
                game.team1.coach.id !== null &&
                game.team1.coach.id == user.id
                  ? game.team2.name
                  : game.team1.name
              }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No games found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
