import { Component, OnInit } from '@angular/core';

// SERVICES
import { HttpService, ToastService } from '@services/index';

// ENV
import { environment } from '@env/environment';

// PRIME
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  constructor(
    private httpService: HttpService,
    private toastService: ToastService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close();
  }

  onSubmit(): void {
    const data = this.config.data;
    if( data.url ) {
      this.httpService
      .delete({
        url: data.url,
        payload: data?.payload
      })
      .subscribe(
        (_) => {
          this.toastService.successMsg({ message: 'Successfully Deleted.'});
          this.ref.close(1);
        },
        (err) => {
          this.toastService.errorMsg({
            message: err.error.message,
          });
        }
      );
    } else {
      this.ref.close(1);
    }
  }
}
