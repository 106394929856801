<div class="p-grid">

  <div class="p-col-1"></div>
  <div class="p-col-10">
    <div class="p-grid">
      <div class="p-col-7 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Start Time</label>
      </div>
      <div class="p-col-5 gen-info-dropdown">
        <p-dropdown
          [options]="startTimeOptions"
          [(ngModel)]="availability.startTime"
          autoWidth="false"
          [style]="{ width: '100%' }"
          placeholder="Select..."
        ></p-dropdown>
      
      </div>

      <div class="p-col-7 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Game Duration</label>
      </div>
      <div class="p-col-5 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 100%; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="availability.gameDuration"
        />
      </div>

      <!-- <div class="p-col-8 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Maximum Games per Team</label>
      </div>
      <div class="p-col-4 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 8rem; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="settings.maxGames"
        />
      </div>
      
      <div class="p-col-8 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Travel Time in Minutes</label>
      </div>
      <div class="p-col-4 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 8rem; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="settings.travelTime"
        />
      </div> -->
    </div>
  </div>
  <div class="p-col-1"></div>

  <div class="p-col-12 flex flex-center">
    <button
      pButton
      iconPos="right"
      type="button"
      label="Save"
      class="
        p-button-raised p-button-rounded p-button-danger p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="onSubmit()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="Cancel"
      class="
        p-button-raised p-button-rounded p-button-text p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="cancel()"
    ></button>
  </div>
</div>
