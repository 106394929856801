import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from '@core/services';

import { TeamDivisionsService } from '@core/services/team-divisions.service';

import { TeamDivision } from '@core/models/team-division';

@Component({
  selector: 'checkin-terms',
  templateUrl: './checkin-terms.component.html',
  styleUrls: ['./checkin-terms.component.scss'],
})
export class CheckinTermsComponent implements OnInit {
  /**
   * Initial terms and condition
   */
  initTerms = `By registering you understand that you and or your players cannot hold the tourney organizer liable for any injury possibly incurred and that we may use you or your teams likeness in our media.`;

  /**
   * Initial refund policy
   */
  initCodeOfConduct = `• Refunds for cancelled team/individual registrations will be granted if processed 30 days BEFORE the start of an event. NOTE: There will be a ($50.00) charge assessed to process each request.

  • There will be no refunds processed for teams/individuals who withdraw, cancel, or forfeit games/participation within 30 days of the start of an event.

  • All refunds (if granted) will be processed at the end of each specified event.

  • In a circumstance in which the weather causes game cancellations, there will be no refunds granted.

  • Note: If an ENTIRE event is cancelled due to Covid-19, all teams will have their registration fees refunded.`;

  _teamDivision: TeamDivision;
  terms: string;
  codeOfConduct: string;
  termsAccepted: boolean;
  codeOfConductAccepted: boolean;
  isShowTerms = false;
  isShowCodeOfConduct = false;

  @Input()
  set teamDivision(teamDivision: TeamDivision) {
    console.log('team divisionnnnn', teamDivision);
    console.log('team divisionnnnn', teamDivision);
    console.log('team divisionnnnn', teamDivision);
    console.log('team divisionnnnn', teamDivision);
    console.log('team divisionnnnn', teamDivision);

    this._teamDivision = teamDivision;
    this.processCheckInShowTerms(
      this.teamDivision.division.tournament.checkInShowTerms
    );
    this.terms = teamDivision?.division?.tournament?.terms;
    this.codeOfConduct = teamDivision?.division?.tournament?.codeOfConduct;
    this.termsAccepted = teamDivision?.termsAccepted;
    this.codeOfConductAccepted = teamDivision?.codeOfConductAccepted;
    this.emitisTermsOrConductIsShown(
      'terms',
      this.isShowTerms,
      this.termsAccepted
    );
    this.emitisTermsOrConductIsShown(
      'codeOfConduct',
      this.isShowCodeOfConduct,
      this.codeOfConductAccepted
    );
  }

  get teamDivision(): TeamDivision {
    return this._teamDivision;
  }

  @Output() checkIfTermsOrConductIsShown = new EventEmitter<any>();

  constructor(private teamDivisionService: TeamDivisionsService) {
    this.teamDivisionService.teamDivision$.subscribe((teamDivision) => {
      this.teamDivision = teamDivision;
    });
  }

  ngOnInit() {
    // this.processCheckInShowTerms(
    //   this.teamDivision.division.tournament.checkInShowTerms
    // );
  }

  saveTerms() {
    console.log('Saving Terms');

    let teamDivision = this._teamDivision;
    teamDivision.termsAccepted = this.termsAccepted;

    this.teamDivisionService
      .saveTeamDivision(teamDivision)
      .subscribe((teamDivision) => {
        this.teamDivisionService.teamDivision$.next(teamDivision);
      });

    // this.emitisTermsOrConductIsShown('terms', this.termsAccepted);
    console.log('this.teamDivision', this.teamDivision);
  }

  saveCodeOfConduct() {
    console.log('Saving Code of Conduct');

    let teamDivision = this._teamDivision;
    teamDivision.codeOfConductAccepted = this.codeOfConductAccepted;

    this.teamDivisionService
      .saveTeamDivision(teamDivision)
      .subscribe((teamDivision) => {
        this.teamDivisionService.teamDivision$.next(teamDivision);
      });

    // this.emitisTermsOrConductIsShown(
    //   'codeOfConduct',
    //   this.codeOfConductAccepted
    // );
    console.log('this.teamDivision', this.teamDivision);
  }

  processCheckInShowTerms(checkInShowTerms: string) {
    console.log('ENTERED processCheckInShowTerms');
    console.log('ENTERED processCheckInShowTerms');
    console.log('ENTERED processCheckInShowTerms');

    switch (checkInShowTerms) {
      case 'TERMS_ONLY':
        this.isShowTerms = true;
        this.isShowCodeOfConduct = false;
        break;
      case 'CODE_OF_CONDUCT_ONLY':
        this.isShowTerms = false;
        this.isShowCodeOfConduct = true;
        console.log('PUMASOK HERE');

        break;
      case 'BOTH':
        this.isShowTerms = true;
        this.isShowCodeOfConduct = true;
        break;
      default:
        this.isShowTerms = false;
        this.isShowCodeOfConduct = false;
        break;
    }

    console.log('checkInShowTerms', checkInShowTerms);
    console.log(this.isShowCodeOfConduct);
  }

  emitisTermsOrConductIsShown(prop, bool, isAccepted) {
    const data = {
      prop: prop,
      isShown: bool,
      isAccepted: isAccepted,
    };

    console.log('this is emitisTermsOrConductIsShown', data);

    this.checkIfTermsOrConductIsShown.emit(data);
  }
}
