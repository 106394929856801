import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Division } from '@core/models/division';
import { Tournament } from '@core/models/tournament';
import { TournamentsService } from '@core/services/tournaments.service';
import { VenueService } from '@core/services/venue.service';
import { log } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Button } from 'protractor';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() config: any = {};
  @Input() data: any = [];
  @Input() thead: any = [];
  @Input() hasResult = true;
  @Input() isFetching = true;
  @Input() noResult = 'No results.';
  @Input() textAlignment = 'center';
  @Input() button: string = '';

  @Output() onActionButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() singleButtonAction: EventEmitter<any> = new EventEmitter<any>();

  message = 'Do you want to delete this tournament?';
  tabIndex = 0;
  tooltip: string;
  hasActionButtons = false;
  isDeleteDialog = false;
  itemData: any;
  btnData: any;
  tournamentId: number;
  isCheckInUrl: boolean;

  constructor(
    private tournamentService: TournamentsService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private venueService: VenueService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isCheckInUrl = this.router.url.includes('/check-in');
    this.cdref.detectChanges();
    if (this.config?.actionButtons) {
      this.hasActionButtons = true;
      this.config.actionReference = 'id';
    }
  }

  ngOnChanges(changes: SimpleChanges) {}

  showDialog(item?: any, btn?: any) {
    this.checkMessage(btn);
    this.itemData = item;
    this.btnData = btn;
  }

  checkMessage(type) {
    switch (type) {
      case 'deleteVenue':
        this.isDeleteDialog = !this.isDeleteDialog;
        this.message = 'Do you want to delete this venue?';
        break;
      case 'delete':
        this.message = 'Do you want to delete this tournament?';
        this.isDeleteDialog = !this.isDeleteDialog;

      default:
        this.message = 'Do you want to delete this tournament?';

        break;
    }
  }

  hide() {
    this.isDeleteDialog = false;
  }

  deleteNow() {
    this.onClick(this.itemData, this.btnData);
  }

  setAlignHeader(header: any) {
    if (this.textAlignment != 'center') {
      return this.textAlignment;
    } else {
      let textAlign = 'center';
      const index = this.thead.findIndex((res) => res.id === header);
      if (index == 0) {
        textAlign = 'left';
      } else if (index == this.thead.length - (this.hasActionButtons ? 0 : 1)) {
        textAlign = 'right';
      }
      return textAlign;
    }
  }

  setTextAlign(header: any) {
    let textAlign = 'center';
    const index = this.thead.findIndex((res) => res.id === header);
    if (index == 0) {
      textAlign = 'left';
    } else if (index == this.thead.length - (this.hasActionButtons ? 0 : 1)) {
      textAlign = 'right';
    }
    if (this.button != '') {
      textAlign = 'center';
    }
    return textAlign;
  }

  setToolTip(action: string): string {
    switch (action) {
      case 'delete':
        this.tooltip = 'Delete Tournament';
        break;
      case 'deleteVenue':
        this.tooltip = 'Delete Venue';
        break;
      case 'view':
        this.tooltip = 'View Division';
        break;
      case 'edit':
        this.tooltip = 'Edit Tournament';
        break;
      case 'delete':
        this.tooltip = 'Delete Tournament';
        break;
      case 'editVenue':
        this.tooltip = 'Edit Venue';
        break;
      case 'schedule':
        this.tooltip = 'View Scheduling Grid';
        break;
      case 'divisions':
        this.tooltip = 'Manage Divisions';
        break;
      case 'selectDivision':
        this.tooltip = 'Manage Division';
        break;
      case 'selectTeam':
        this.tooltip = 'Select Team';
        break;
      case 'selected':
        this.tooltip = 'Unselect Team';
        break;
      default:
        break;
    }
    return this.tooltip;
  }

  setBtnIcon(action: string) {
    let icon;
    switch (action) {
      case 'delete':
        icon = 'pi-trash';
        break;
      case 'deleteVenue':
        icon = 'pi-trash';
        break;
      case 'view':
        icon = 'pi-table';
        break;
      case 'edit':
        icon = 'pi-pencil';
        break;
      case 'delete':
        icon = 'pi-trash';
        break;
      case 'editVenue':
        icon = 'pi-pencil';
        break;
      case 'schedule':
        icon = 'pi-table';
        break;
      case 'divisions':
        icon = 'pi-book';
        break;
      case 'selectDivision':
        icon = 'pi-book';
        break;
      case 'selectTeam':
        icon = 'pi-table';
        break;
      case 'selected':
        icon = 'pi-check';
        break;
      case 'startCheckIn':
        break;
      default:
        icon = 'pi-pencil';
        break;
    }
    return icon;
  }

  getTournamentStatus(status: boolean, index: number) {
    if (status && index === 0) {
      return 'active';
    } else if (status && index === 1) {
      return 'list event';
    } else if (status && index === 2) {
      return 'app';
    } else if (status && index === 3) {
      return 'archive';
    } else {
      return null;
    }
  }

  onClick(id: any, action: string, data?: any): void {
    this.onActionButtonClick.emit({ id: id, action: action, data: data });
  }
  clickSingleButton(item) {
    this.singleButtonAction.emit({
      tournamentId: item.id,
      action: this.button,
    });
  }
}
