import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// MODELS
import { Games } from '@core/models/games';
import { Venue } from '@core/models/venue';

// SERVICES
import { VenueService } from '@core/services/venue.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import { HttpService } from '@core/services';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-public-venue-schedules',
  templateUrl: './public-venue-schedules.component.html',
  styleUrls: ['./public-venue-schedules.component.scss'],
})
export class PublicVenueSchedulesComponent implements OnInit {
  venueId: number;
  venue: Venue;

  teamName: string;
  games: Games[];
  game: Games;

  doesHaveGames: boolean = false;
  dateAvailable: Schedule[] = [];

  initLength: number;

  tournamentId: any;

  logoUrl: string = '';
  isReview: boolean = false;

  primaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  constructor(
    private route: ActivatedRoute,
    private venueService: VenueService,
    private publicTournamentService: PublicTournamentsService,
    private http: HttpService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.primaryColor = JSON.parse(sessionStorage.getItem('org'))?.primaryColor;

    this.route.paramMap.subscribe(async (params) => {
      console.log('paramss', params);

      this.tournamentId = params.get('tournamentId');
      this.venueId = parseInt(params.get('venueId'));

      this.isReview = this.publicTournamentService.isReview;
      this.isReview = JSON.parse(localStorage.getItem('isReview')) ?? false;

      this.getVenue();
    });
  }

  async getVenue() {
    this.venue = await this.venueService.getVenue(this.venueId);
    console.log(this.venue);
    if (null == this.primaryColor) {
      this.primaryColor = '#a90000';
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    } else {
      this.primaryColor = this.primaryColor;
      this.getFontColor(this.primaryColor);
    }
    if (this.venue?.photo) {
      try {
        this.logoUrl = `${environment.api.venuePhoto}/${this.venue.photo}`;
      } catch (e) {
        console.log(e, 'may error');
        return null;
      }
    } else {
      console.log('no photo');
    }
  }

  getFontColor(primary: string) {
    let pRed = primary.substring(1, 3);
    let pGreen = primary.substring(3, 5);
    let pBlue = primary.substring(5, 7);
    let primaryInt =
      parseInt(pRed, 16) * 0.299 +
      parseInt(pGreen, 16) * 0.587 +
      parseInt(pBlue, 16) * 0.114;

    if (primaryInt > 150) {
      console.log('MAIN IS BLACK');
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      console.log('MAIN IS WHITE');
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }
  }

  goToTournamentPublicPage() {
    this.router.navigate([
      '/public-tournament/atx-winter-havoc/divisions/',
      this.tournamentId,
    ]);
  }
}
