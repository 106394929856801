<div class="public-division">
  <div class="header-container">
    <div class="nav">
      <app-public-header *ngIf="!isReview"></app-public-header>
    </div>
  </div>
  <div
    class="detail-header"
    [style.backgroundColor]="primaryColor"
    [style.color]="fontColor"
  >
    <div class="back" (click)="goToTournamentPublicPage()">
      <i class="pi pi-arrow-left"></i>
    </div>
    <div class="image-container" *ngIf="this.innerWidth >= 769">
      <div
        class="tourney-image"
        *ngIf="division?.tournament?.photo; else noPhoto"
      >
        <img class="image" [src]="logoUrl" />
      </div>
      <ng-template #noPhoto>
        <div class="tourney-image">
          <img class="image" src="../../assets/img/tk-placeholder.png" />
        </div>
      </ng-template>
    </div>
    <div class="division-details">
      <div class="info">
        <div class="line2">{{ division?.name }}</div>
        <div class="line3">
          Registered Teams: {{ division?.registeredTeams }}
        </div>
      </div>
      <div class="played" *ngIf="!hasChampionTeam">
        <div class="line4">Games played at:</div>
        <div *ngIf="isLoading; else dataLoaded">Loading...</div>
        <ng-template #dataLoaded>
          <div *ngIf="venues && venues.length > 0; else noVenue" class="venues">
            <ng-container *ngFor="let venue of venues; let i = index">
              <ng-container *ngIf="i < venues.length - 2; else lastVenue">
                <div class="venue-link" (click)="openVenue(venue.id)">
                  {{ venue.name }},
                </div>
              </ng-container>
              <ng-template #lastVenue>
                <ng-container *ngIf="i === venues.length - 2">
                  <div class="venue-link" (click)="openVenue(venue.id)">
                    {{ venue.name }} &
                  </div>
                </ng-container>
                <ng-container *ngIf="i === venues.length - 1">
                  <div class="venue-link" (click)="openVenue(venue.id)">
                    {{ venue.name }}
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
        </ng-template>
        <ng-template #noVenue>
          <div class="no-venue">TBD</div>
        </ng-template>
      </div>
      <div class="champion" *ngIf="hasChampionTeam">
        <div class="line2">{{ championTeam }}</div>
        <div class="line3">Champion</div>
      </div>
    </div>
  </div>
  <div class="main-container">
    <div *ngIf="division?.tournament?.showStandings">
      <div class="section-header text-white">STANDINGS</div>
      <p-card>
        <div class="standing-details p-grid m-0">
          <div
            *ngFor="let pool of pools | slice : 0 : 5"
            class="p-0 border-right"
            [ngClass]="isScreenLess850 === true ? 'p-col-12' : 'p-col-6'"
          >
            <div class="standings-header p-3">
              <div class="mb-3">
                <label class="TheBoldFont-Bold fs-4">{{ pool?.name }}</label>
              </div>

              <div class="standings-row row-header">
                <div [style]="{ width: isScreenLess450 ? '2%' : '' }">
                  TEAMS
                </div>
                <div>W</div>
                <div>L</div>
                <div>PD</div>
                <div>PA</div>
                <div>PS</div>
                <div>RANK</div>
              </div>
            </div>

            <div *ngFor="let standings of pool.standings; let i = index">
              <div class="p-2 toggle">
                <p-accordion>
                  <p-accordionTab>
                    <ng-template pTemplate="header">
                      <div class="standings-row values" style="width: 100%">
                        <div
                          class="team-name TheBoldFont-Bold"
                          (click)="
                            openTeam(
                              $event,
                              standings.teamId,
                              standings.teamName,
                              standings
                            )
                          "
                        >
                          {{ standings?.teamName }}
                        </div>
                        <div>{{ standings?.wins }}</div>
                        <div>{{ standings?.losses }}</div>
                        <div>{{ standings?.pointsDifferential }}</div>
                        <div>{{ standings?.pointsAllowed }}</div>
                        <div>{{ standings?.pointsScored }}</div>
                        <div>{{ standings?.rank }}</div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content"
                      ><app-team-info
                        [teamId]="standings?.teamId"
                        [teamName]="standings?.teamName"
                        [tournamentId]="tournamentId"
                      ></app-team-info
                    ></ng-template>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>

    <br />

    <app-division-schedule [division]="division"></app-division-schedule>

    <br />

    <div class="section-header text-white">DIVISION BRACKET</div>
    <div *ngIf="division == null; else setBracket" class="text-center p-3">
      <p-progressSpinner
        [style]="{ width: '40px', height: '40px' }"
      ></p-progressSpinner>
    </div>
    <ng-template #setBracket>
      <app-public-bracket [divisionInfo]="division"></app-public-bracket>
    </ng-template>
  </div>

  <ng-template #dontHaveGames>
    <div class="sched-details p-grid p-3">
      <div class="p-col-3"></div>

      <div class="p-col-6 text-center">
        <label class="TheBoldFont-Bold fs-3"
          >No games have been played yet.</label
        >
      </div>

      <div class="p-col-3"></div>
    </div>
  </ng-template>
</div>
