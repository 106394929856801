import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Division } from '@core/models/division';
import { GamePeriod } from '@core/models/game-period';
import { Games } from '@core/models/games';
import { Tournament } from '@core/models/tournament';
import { HttpService, ToastService } from '@core/services';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import { rejects } from 'assert';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-public-schedules',
  templateUrl: './public-schedules.component.html',
  styleUrls: ['./public-schedules.component.scss'],
})
export class PublicSchedulesComponent implements OnInit {
  division: any = {};
  divisionId: string;
  divisions: Division[] = [];
  games: Games[];
  game: Games;
  doesHaveGames: boolean = false;
  dateAvailable: Schedule[] = [];
  initLength: number;
  tournament: Tournament;
  tournamentId: any;
  tournamentDates: any[] = [];
  pools: any[] = [];
  gamePeriods: GamePeriod[] = [];
  allGames: any[] = [];
  games$ = new BehaviorSubject<Games[]>([]);

  isFetching: boolean;
  schedules:any = [];

  constructor(
    private route: ActivatedRoute,
    private publicTournamentsService: PublicTournamentsService,
    private toastService: ToastService,
    private httpService: HttpService,
    private router: Router,
    private _http: HttpService
  ) {}

  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      this.tournamentId = params.get('tournamentId');
      this.divisionId = params.get('divisionId');
    });
    this.division = this.route.paramMap.pipe(() => window.history.state);
    this.getTournamentByParams(this.tournamentId);
    this.getAssignedGames();
  }

  convertedDate(date) {
    var formattedDate = date.split('/');
    return formattedDate[2] + formattedDate[0] + formattedDate[1];
  }

  getAssignedGames() {

    const url = `${environment.api.assignedGames}?divisionId=${this.divisionId}`;
    this.httpService
      .get({
        url: url,
      })
      .toPromise()
      .then((games) => {
        if (games?.length > 0) {

          this.games = games;

          let schedules:any[] = [];
          let schedule:any = null;
          let lastDate:string = '';
          
          games.forEach((game:Games) => {
            const date:string = moment(game.date).format('YYYYMMDD');
            if (lastDate !== date) {
              if (null !== schedule) schedules.push(schedule);
              lastDate = date;
              schedule = {date: game.date, timeslots: []};
            }
            let timeslot = schedule.timeslots.find((group:any) => {
              return group.time.toLowerCase() == game.timeslot.toLowerCase();
            });
            if (!timeslot) {
              timeslot = {time: game.timeslot, games: []};
              schedule.timeslots.push(timeslot);
            }
            timeslot.games.push(game);
          })
          if (null !== schedule) schedules.push(schedule);

          this.schedules = schedules;

          console.log(schedules);
        }

        setTimeout((_) => {
          this.isFetching = true;
        }, 2000);
      },
      (err) => {
        setTimeout((_) => {
          this.isFetching = true;
        }, 2000);
      }
    );
  }
  
  getTournamentByParams(id) {
    return new Promise((resolve, reject) => {
      this._http
        .get({
          url: `${environment.api.tournaments}/${id}`,
        })
        .toPromise()
        .then((res) => {
          resolve(true);
          this.tournament = res;
        });
    }).catch((err) => {
      console.log('error...');
      rejects(err);
    });
  }

  getRank(i: number) {
    if (i == 1) return '1st';
    else if (i == 2) return '2nd';
    else if (i == 3) return '3rd';
    else return i+'th';
  }
}
