import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

// FIREBASE
import { AngularFireAuth } from '@angular/fire/auth';

import { SessionStorageService } from './session-storage.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public angularFireAuth: AngularFireAuth,
    private sessionStorageService: SessionStorageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.angularFireAuth.idToken.pipe(
      take(1),
      switchMap((token: any) => {
        if (token) {
          // this.sessionStorageService.setToken(token);
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
        }
        this.sessionStorageService.setExpTime();
        return next.handle(request);
      })
    );
  }
}
