<div class="container">
    <div class="row">
      <i class="pi pi-arrow-left back" (click)="navigateBack()"></i>
      <!-- <div class="p-col-2 dropdownBtns">
        <p-dropdown
          [placeholder]="'BATCH ACTIONS'"
          optionLabel="name"
          optionValue="id"
          styleClass="autocomplete"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
      </div>
      <div class="p-col-2 dropdownBtns">
        <p-dropdown
          [placeholder]="'FILTERS'"
          optionLabel="name"
          optionValue="id"
          styleClass="autocomplete"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
      </div> -->
      <!-- <div class="p-col-4 searchBar">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input type="text" 
          pInputText 
          placeholder="Find a Team" >        
      </span> 
      </div> -->
      <div class="rightBtns">
        <button pButton (click)="showAddPlayer()" type="button" label="ADD PLAYER" class="p-button-rounded p-button-danger addBtn"></button>
        <button  pButton type="button"  [disabled]="(this.selectedPlayers == null || this.selectedPlayers.length == 0 ) ? true : false" label="DELETE PLAYER" class="p-button-rounded p-button-danger deleteBtn" (click)="onDeletePlayer()"></button>
      </div>
    </div>
    
    <div class="row">
        <p-table [value]="listPlayers" selectionMode="multiple" [(selection)]="selectedPlayers" dataKey="id">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>PLAYERS</th>
                    <th>AGE</th>
                    <th>POINTS</th>
                    <th>ASSISTS</th>
                    <th>COACH</th>
                    <th>STATUS</th>
                    <th><i class="pi pi-ellipsis-h"></i></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-player>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="player"></p-tableCheckbox>
                    </td>
                    <td>{{player.fullName}}</td>
                    <td>{{calculateAge(player.birthdate)}}</td>
                    <td>{{player.points}}</td>
                    <td>{{player.assists}}</td>
                    <td>{{player.team.coach.fullName}}</td>
                    <td></td>
                    <td>
                      <i class="pi pi-pencil edit-player" (click)="editPlayer(player)" pTooltip="Edit Player"></i>

                      <!-- <button (click)="showEditTeam()" pButton type="button" label="EDIT TEAM" class="p-button-rounded p-button-danger addBtn"></button> -->
                    </td>
                </tr>
            </ng-template>

      
        </p-table>
    </div>
  </div>