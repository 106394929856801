<div class="p-col-12">
  <div
    class="container-fields container-margin-alignment mb-5"
    *ngIf="isShowTerms"
  >
    <label class="pb-3 TheOpenSans-Regular text-bold3"
      >Terms and Policies <span class="required-indicator"></span
    ></label>
    <textarea
      [placeholder]="initTerms"
      rows="4"
      cols="30"
      class="tournament-inputarea-text"
      autoResize="autoResize"
      [readonly]="true"
      [(ngModel)]="terms"
    ></textarea>
  </div>
  <div
    class="container-fields container-margin-alignment mb-5"
    *ngIf="isShowCodeOfConduct"
  >
    <label class="pb-3 TheOpenSans-Regular text-bold3"
      >Code of Conduct <span class="required-indicator"></span
    ></label>
    <textarea
      [placeholder]="initCodeOfConduct"
      rows="14"
      cols="30"
      class="tournament-inputarea-text"
      autoResize="autoResize"
      [readonly]="true"
      [(ngModel)]="codeOfConduct"
    ></textarea>
  </div>
  <div class="agree-boxes">
    <div class="p-col-6 py-2 m-0" *ngIf="isShowTerms">
      <p-checkbox
        [(ngModel)]="termsAccepted"
        class="tk-checkbox"
        name="tourney-division"
        [value]="true"
        label="I agree to the Terms and Conditions"
        [binary]="true"
        (click)="saveTerms()"
      ></p-checkbox>
    </div>
    <div class="p-col-6 py-2 m-0" *ngIf="isShowCodeOfConduct">
      <p-checkbox
        [(ngModel)]="codeOfConductAccepted"
        class="tk-checkbox"
        name="tourney-team-name"
        [value]="true"
        label="I agree to abide by the Code of Conduct"
        [binary]="true"
        (click)="saveCodeOfConduct()"
      ></p-checkbox>
    </div>
    <div></div>
  </div>
</div>
