<div class="p-grid custom-modal">
  <div class="p-col-12 text-center my-2">
    <span class="fs-2 text-black5 text-bold1">
      {{ message }}
    </span>
  </div>
  <div class="p-col-12 flex flex-center">
    <span class="my-2 mx-3">
      <button mat-raised-button mat-dialog-close>No</button>
    </span>
    <span class="my-2 mx-3">
      <button mat-raised-button color="warn" (click)="confirm()">Yes</button>
    </span>
  </div>
</div>
