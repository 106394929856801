<div class="p-grid">
  <div class="p-col-12 text-center my-2">
    <span class="fs-2 text-black5 text-bold1">
      This game has been marked as final already.
    </span>
    <br />
    <span class="fs-2 text-black5 text-bold1">
      Do you still want to edit the final score?
    </span>
  </div>
  <div class="p-col-12 flex flex-center">
    <button
      pButton
      pRipple
      type="button"
      label="No"
      class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
      (click)="cancel()"
    ></button>
    <button
      pButton
      iconPos="right"
      type="button"
      label="Yes"
      class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
      (click)="onSubmit()"
    ></button>
  </div>
</div>
