import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  toast(title: string, message: string, type?: any) {
    switch (type) {
      case 'success':
        this.toastr.success(message, title, {
          timeOut: 2000
        });
        break;
      case 'warning':
        this.toastr.warning(message, title, {
          timeOut: 2000
        });
        break;
      case 'error':
        this.toastr.error(message, title, {
          timeOut: 2000
        });
        break;
      case 'info':
        this.toastr.info(message, title, {
          timeOut: 2000
        });
        break;
      default:
        this.toastr.success(message, title, {
          timeOut: 2000
        });
        break;
    }
  }

  successMsg({title = 'Success', message}): void {
    this.toastr.success(message, title, {
      timeOut: 2000
    });
  }

  errorMsg({title = 'Failed', message}): void {
    this.toastr.error(message, title, {
      timeOut: 2000
    });
  }

  warningMsg({title = 'Warning', message}): void {
    this.toastr.warning(message, title, {
      timeOut: 2000
    });
  }

  infoMsg({title = 'Info', message}): void {
    this.toastr.info(message, title, {
      timeOut: 2000
    });
  }
}
