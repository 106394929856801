import { Component, OnInit } from '@angular/core';
// PRIME
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';


interface NotifyCoach {
  firstName: string;
  lastName: string;
  email: string;
}

@Component({
  selector: 'app-notify-coach',
  templateUrl: './notify-coach.component.html',
  styleUrls: ['./notify-coach.component.scss']
})

export class NotifyCoachComponent implements OnInit {

  coaches: NotifyCoach[] = [{
    firstName: '',
    lastName: '',
    email: ''
  }]

  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  ngOnInit(): void {
  }

  addCoach(): void {
    let newCoach = {
      firstName: '',
      lastName: '',
      email: ''
    }

    this.coaches.push(
      newCoach
      )
  }

  cancel(): void {
    this.ref.close()
  }

  submit(): void {
    this.ref.close(this.coaches)
  }

}
