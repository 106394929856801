export const exposureTournaments = [
  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'Tournament',
  },
  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'All teams',
  },
  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'Boys & Girls: 12th-3rd',
  },
  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'Boys',
  },
  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'entry fee',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Tournament',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'All teams',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Boys & Girls: 11th-3rd',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Ability: Elite/Competitive/Developmental',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: '$150.00-$175.00 entry fee',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: '3 game guarantee',
  },

  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'KJ Witt',
    contactNumber: '206-476-3405',
    contactEmail: 'kwitt@westcoastelitebasketball.com',
    venues3: 'BELGRADE EVENTS CENTER',
  },
  {
    organization: 'Nashville Youth Basketball Association',
    tournament: 'The National PrimeTime Showcase',
    date: 'May 4-5, 2024',
    location: 'Murfreesboro, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    field2: 'Tournament',
  },
  {
    organization: 'Texas GameTime Basketball',
    tournament: '"GO HARD OR GO HOME"',
    date: 'May 4-5, 2024',
    location: 'Dallas, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    field2: 'Tournament',
  },
  {
    organization: 'Nashville Youth Basketball Association',
    tournament: 'The National PrimeTime Showcase',
    date: 'May 4-5, 2024',
    location: 'Murfreesboro, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    field2: 'All teams',
  },
  {
    organization: 'Texas GameTime Basketball',
    tournament: '"GO HARD OR GO HOME"',
    date: 'May 4-5, 2024',
    location: 'Dallas, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    field2: 'All teams',
  },
  {
    organization: 'Nashville Youth Basketball Association',
    tournament: 'The National PrimeTime Showcase',
    date: 'May 4-5, 2024',
    location: 'Murfreesboro, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    field2: 'Boys & Girls',
  },
  {
    organization: 'Texas GameTime Basketball',
    tournament: '"GO HARD OR GO HOME"',
    date: 'May 4-5, 2024',
    location: 'Dallas, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    field2: 'Boys & Girls: 12th-1st',
  },
  {
    organization: 'Nashville Youth Basketball Association',
    tournament: 'The National PrimeTime Showcase',
    date: 'May 4-5, 2024',
    location: 'Murfreesboro, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    field2: 'Ability: Elite/Competitive/Developmental',
  },
  {
    organization: 'Texas GameTime Basketball',
    tournament: '"GO HARD OR GO HOME"',
    date: 'May 4-5, 2024',
    location: 'Dallas, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    field2: '$250.00-$275.00 entry fee',
  },
  {
    organization: 'Nashville Youth Basketball Association',
    tournament: 'The National PrimeTime Showcase',
    date: 'May 4-5, 2024',
    location: 'Murfreesboro, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/267541?v=47264548&w=300&h=200',
    field2: '$295.00 entry fee',
  },
  {
    organization: 'Texas GameTime Basketball',
    tournament: '"GO HARD OR GO HOME"',
    date: 'May 4-5, 2024',
    location: 'Dallas, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    field2: 'Ability: Elite/Competitive/Developmental',
  },
  {
    organization: 'Texas GameTime Basketball',
    tournament: '"GO HARD OR GO HOME"',
    date: 'May 4-5, 2024',
    location: 'Dallas, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/267763?v=47325562&w=300&h=200',
    field2: '3 game guarantee',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'HAROLD DEAN REC CENTER',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'HOOPTOWN',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'OAKLAND HIGH SCHOOL',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'OAKLAND MIDDLE SCHOOL',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'RIVERDALE HIGH SCHOOL',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'WALTER J BAIRD MIDDLE SCHOOL',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'WILSON CENTRAL HIGH SCHOOL',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
  },
  {
    organization: 'Middle Tennessee Basketball Showcase',
    tournament: '2024 SPRING FLING',
    date: 'May 3-5, 2024',
    location: 'Lebanon, Tennessee',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258974?v=44344871&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Kris Hooper',
    contactNumber: '615-473-1025',
    contactEmail: 'muhoop@hotmail.com',
    venues: 'WINFREE BRYANT MIDDLE SCHOOL',
    venues2: 'CARROLL OAKLAND ELEMENTARY',
    venues3: 'CARROLL OAKLAND ELEMENTARY',
  },

  {
    organization: 'West Coast Elite',
    tournament: 'MT CHASE FOR THE CHAIN',
    date: 'May 4-5, 2024',
    location: 'Bozeman, Montana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/24414?v=27868216&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'KJ Witt',
    contactNumber: '206-476-3405',
    contactEmail: 'kwitt@westcoastelitebasketball.com',
    venues3: 'BELGRADE MIDDLE SCHOOL',
  },
  {
    organization: 'PrimeTime Sports',
    tournament: 'CLUTCH CITY CLASSIC - HTX',
    date: 'May 4-5, 2024',
    location: 'Cypress, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    field2: 'Tournament',
  },
  {
    organization: 'PrimeTime Sports',
    tournament: 'CLUTCH CITY CLASSIC - HTX',
    date: 'May 4-5, 2024',
    location: 'Cypress, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    field2: 'All teams',
  },
  {
    organization: 'PrimeTime Sports',
    tournament: 'CLUTCH CITY CLASSIC - HTX',
    date: 'May 4-5, 2024',
    location: 'Cypress, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    field2: 'Boys & Girls: 12th-3rd',
  },
  {
    organization: 'PrimeTime Sports',
    tournament: 'CLUTCH CITY CLASSIC - HTX',
    date: 'May 4-5, 2024',
    location: 'Cypress, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    field2: 'Ability: Elite/Competitive/Developmental',
  },
  {
    organization: 'PrimeTime Sports',
    tournament: 'CLUTCH CITY CLASSIC - HTX',
    date: 'May 4-5, 2024',
    location: 'Cypress, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    field2: '$360.00 entry fee',
  },
  {
    organization: 'PrimeTime Sports',
    tournament: 'CLUTCH CITY CLASSIC - HTX',
    date: 'May 4-5, 2024',
    location: 'Cypress, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/262335?v=49818999&w=300&h=200',
    field2: '3 game guarantee',
  },

  {
    organization: 'Alodia Basketball Academy',
    tournament: 'ALODIA GMOTORS SUMMER SHOWCASE - NATIONAL QUALIFIER',
    date: 'June 1-2, 2024',
    location: 'Tomball, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    field2: 'Boys & Girls: 12th-4th',
  },
  {
    organization: 'Alodia Basketball Academy',
    tournament: 'ALODIA GMOTORS SUMMER SHOWCASE - NATIONAL QUALIFIER',
    date: 'June 1-2, 2024',
    location: 'Tomball, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    field2: 'Ability: Competitive',
  },
  {
    organization: 'Alodia Basketball Academy',
    tournament: 'ALODIA GMOTORS SUMMER SHOWCASE - NATIONAL QUALIFIER',
    date: 'June 1-2, 2024',
    location: 'Tomball, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    field2: '$195.00-$225.00 entry fee',
  },
  {
    organization: 'Alodia Basketball Academy',
    tournament: 'ALODIA GMOTORS SUMMER SHOWCASE - NATIONAL QUALIFIER',
    date: 'June 1-2, 2024',
    location: 'Tomball, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    field2: '3 game guarantee',
  },
  {
    organization: 'Alodia Basketball Academy',
    tournament: 'ALODIA GMOTORS SUMMER SHOWCASE - NATIONAL QUALIFIER',
    date: 'June 1-2, 2024',
    location: 'Tomball, Texas',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/jpg/258030?v=44306833&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Alodia Basketball Academy',
    contactNumber: '281-255-2552',
    contactEmail: 'tournaments@alodiaconsulting.com',
  },
  {
    organization: 'Midwest Youth Tournaments',
    tournament: 'MYT "BOYS AND GIRLS NIT"',
    date: 'June 1-2, 2024',
    location: 'Franklin and others, Indiana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    field2: 'Tournament',
  },
  {
    organization: 'Midwest Youth Tournaments',
    tournament: 'MYT "BOYS AND GIRLS NIT"',
    date: 'June 1-2, 2024',
    location: 'Franklin and others, Indiana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    field2: 'All teams',
  },
  {
    organization: 'Midwest Youth Tournaments',
    tournament: 'MYT "BOYS AND GIRLS NIT"',
    date: 'June 1-2, 2024',
    location: 'Franklin and others, Indiana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    field2: 'Boys & Girls',
  },
  {
    organization: 'Midwest Youth Tournaments',
    tournament: 'MYT "BOYS AND GIRLS NIT"',
    date: 'June 1-2, 2024',
    location: 'Franklin and others, Indiana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    field2: '$175.00 entry fee',
  },
  {
    organization: 'Midwest Youth Tournaments',
    tournament: 'MYT "BOYS AND GIRLS NIT"',
    date: 'June 1-2, 2024',
    location: 'Franklin and others, Indiana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    field2: '3 game guarantee',
  },
  {
    organization: 'Midwest Youth Tournaments',
    tournament: 'MYT "BOYS AND GIRLS NIT"',
    date: 'June 1-2, 2024',
    location: 'Franklin and others, Indiana',
    imageUrl:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    imageUrl1:
      'https://cdn.exposureevents.com/assets/files/png/20414?v=2322621&w=300&h=200',
    field2: 'Exposure Certified',
    contactName: 'Mitchell Speedy',
    contactNumber: '502-415-6943',
    contactEmail: 'mitchellspeedy@yahoo.com',
    venues3: 'FRANKLIN COMMUNITY HIGH SCHOOL',
  },
];
