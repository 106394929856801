import { Component, OnInit } from '@angular/core';
import { Venue } from '@core/models/venue';
import { VenueService } from '@core/services/venue.service';

// MODELS
import { GENDER_TYPES } from '@models/gender-type';
// import { GRADE_GROUP_TYPES } from '@models/tournament-grade-group';
// import { AGE_GROUP_TYPES } from '@models/tournament-age-group';

// PRIME
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService,
} from 'primeng/dynamicdialog';

// SERVICES
import { HttpService, ToastService } from '@services/index';

// ENV
import { environment } from '@env/environment';
import { defConstant } from '@core/data/constant-data';
import { MessageComponent } from '@components/modal/message/message.component';
import { rejects } from 'assert';
import { MatDialog } from '@angular/material/dialog';

const VIEWABLE_SIZE = 15;

@Component({
  selector: 'app-select-venue-modal',
  templateUrl: './select-venue-modal.component.html',
  styleUrls: ['./select-venue-modal.component.scss']
})
export class SelectVenueModalComponent implements OnInit {

  data = null;
  venues: Venue[] = [];
  playerLimit: number;
  meta: any = {};
  q: string = '';
  searchTimeout: any;
  fetching: boolean = false;
  isLoading: boolean = false;
  isValidated: boolean = false;
  selectedVenues: Venue;
  isShowSelectMessage: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    private httpService: HttpService,
    private toastService: ToastService,
    private venueService: VenueService,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.data = this.config.data;
    this.initVenues();
  }

  pageChanged(event: any) {
    console.log('event', event);
  }

  async initVenues() {
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.globalVenues}?action=init&index=1&viewableSize=${VIEWABLE_SIZE}&totalSize=330&ascending=true`,
          payload: [],
        })
        .toPromise();

      this.venues = list.items;
      this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }

  // + '?action='+action
  // + '&index='+meta.index
  // + '&sortField='+meta.sortField
  // + '&ascending=true'
  // + '&viewableSize='+VIEWABLE_SIZE
  // + '&totalSize='+meta.totalSize;

  async getVenues(action: string, meta: any) {

    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.globalVenues}?action=${action}&q=${this.q}&index=${meta.index}&viewableSize=${VIEWABLE_SIZE}&totalSize=${meta.totalSize}&ascending=true`,
          payload: [],
        })
        .toPromise();

      this.venues = list.items;
      this.meta = list.meta;
      this.fetching = false;

    } catch (err) {
      console.log(err);
    }
  }

  // first() {
  //     this.getVenues('first', this.meta);
  // }

  previous() {
    this.getVenues('previous', this.meta);
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    this.getVenues('index', meta);
  }

  next() {
    this.getVenues('next', this.meta);
  }


  debounceSearch() {
    clearTimeout(this.searchTimeout);
    this.search();
  }

  search() {

    if (this.q.length >= 3 || this.q.length === 0) {
      this.searchTimeout = setTimeout(() => {
        let meta: any = Object.assign({}, this.meta);
        meta.search = this.q;
        this.getVenues('search', meta);
      }, 750); // delay search for 750 milliseconds and do search if no key press is detected after
    }
  }

  print() {
    console.log('event', this.selectedVenues);
  }

  close(): void {
    this.ref.close(false);
  }

  async confirmVenues() {
    try {
      console.log(this.selectedVenues);
      if (this.selectedVenues == undefined) {
        this.isShowSelectMessage = true;
      }
      else {
        this.venueService.selectedVenue.next(this.selectedVenues);
        this.ref.close(true);
        this.dialog.closeAll();
      }
    } catch (err) {
      this.ref.close(false);
      this.ref = this.dialogService.open(MessageComponent, {
        header: 'Error',
        width: defConstant.modalConfirmWidth,
        closable: false,
        data: {
          result: {
            message: err.error.message,
            hasCancel: true,
          },
        },
      });
    }
  }

}
