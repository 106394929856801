<div class=" mb-4 text-center">
  Hello <b>{{ user?.fullName }}</b>. Welcome back to Tourney Key!
</div>
<section class="flex flex-center">
  <button class="btn-rounded-white mx-2" (click)="close()">
    Return Home
  </button>

  <button (click)="gotoDashboard()" class="btn-rounded-red mx-2">
    Go to Dashboard
  </button>
</section>
