<div class="text-center" *ngIf="isLoading; else notLoading">
  <p-progressSpinner
    [style]="{ width: '40px', height: '40px' }"
  ></p-progressSpinner>
</div>
<ng-template #notLoading>
  <div *ngIf="doesHaveGames; else dontHaveGames">
    <!-- <div *ngFor="let date of tournamentDates"> -->
    <!-- <div class="sched-header fs-6">{{ date.date.label }}</div> -->

    <div class="sched-details p-grid">
      <div class="p-col-12">
        <label class="TheBoldFont-Bold fs-6 text-center">GAME RESULTS</label>
      </div>

      <div
        *ngFor="let game of allGames | slice : 0 : 5; let i = index"
        class="p-col-12 border-right"
      >
        <div class="p-grid">
          <div class="p-col-6">
            <label>{{ game?.name }}</label>
          </div>
          <div class="p-col-6 text-right">
            <label *ngIf="game?.date"
              >{{ game?.date | date : "MMM dd" }} at {{ game?.timeslot }}</label
            >
          </div>

          <div class="results-row">
            <div *ngIf="!isScreenLess450">
              <div *ngIf="game.team1.photo; else altPhoto1">
                <img [src]="photoUrl + game.team1.photo" alt="Logo" />
              </div>
            </div>

            <div class="team-name px-2">
              <label class="TheBoldFont-Bold fs-3 text-center">{{
                game?.team1?.name
              }}</label>
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[0]?.team1Score
                  ? game.gamePeriods[0]?.team1Score
                  : "-"
              }}
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[1]?.team1Score
                  ? game.gamePeriods[1]?.team1Score
                  : "-"
              }}
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[2]?.team1Score
                  ? game.gamePeriods[2]?.team1Score
                  : "-"
              }}
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[3]?.team1Score
                  ? game.gamePeriods[3]?.team1Score
                  : "-"
              }}
            </div>
            <div
              class="align-center"
              [class]="
                game?.team1Score > game?.team2Score
                  ? 'TheBoldFont fs-10 score-win'
                  : 'TheBoldFont fs-10 score-lose'
              "
            >
              {{ game?.team1Score }}
            </div>
            <ng-template #altPhoto1>
              <div class="text-center">
                <img src="assets/img/ball-avatar.png" alt="Logo" />
              </div>
            </ng-template>
          </div>

          <div class="results-row">
            <div *ngIf="!isScreenLess450">
              <div *ngIf="game.team1.photo; else altPhoto2">
                <img [src]="photoUrl + game.team1.photo" alt="Logo" />
              </div>
            </div>
            <div class="team-name px-2">
              <label class="TheBoldFont-Bold fs-3 text-center">{{
                game?.team2?.name
              }}</label>
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[0]?.team2Score
                  ? game.gamePeriods[0]?.team2Score
                  : "-"
              }}
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[1]?.team2Score
                  ? game.gamePeriods[1]?.team2Score
                  : "-"
              }}
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[2]?.team2Score
                  ? game.gamePeriods[2]?.team2Score
                  : "-"
              }}
            </div>
            <div class="fs-2 align-center">
              {{
                game.gamePeriods[3]?.team2Score
                  ? game.gamePeriods[3]?.team2Score
                  : "-"
              }}
            </div>
            <div
              class="align-center"
              [class]="
                game?.team2Score > game?.team1Score
                  ? 'TheBoldFont fs-10 score-win'
                  : 'TheBoldFont fs-10 score-lose'
              "
            >
              {{ game?.team2Score }}
            </div>
            <ng-template #altPhoto2>
              <div class="text-center">
                <img src="assets/img/ball-avatar.png" alt="Logo" />
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <!---
            <div class="p-col-6 border-bot disp-flex flex-center">
              <div class="p-col-12">
                <div class="text-center center-align">
                  <label class="TheBoldFont-Bold fs-1"
                    >Player Stats Not Available.</label
                  >
                </div>
              </div>
            </div>
            -->
    </div>

    <!-- </div> -->
  </div>

  <ng-template #dontHaveGames>
    <div class="sched-details p-grid p-3">
      <!-- <div class="p-col-3"></div> -->

      <div class="p-col-12 text-center">
        <label class="TheBoldFont-Bold fs-3"
          >No games have been played yet.</label
        >
      </div>

      <!-- <div class="p-col-3"></div> -->
    </div>
  </ng-template>
</ng-template>
