import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Division } from '@core/models/division';
import { GamePeriod } from '@core/models/game-period';
import { Games } from '@core/models/games';
import { Tournament } from '@core/models/tournament';
import { HttpService } from '@core/services';
import { BracketService } from '@core/services/bracket-service';
import { GamesService } from '@core/services/games.service';
import { TournamentsService } from '@core/services/tournaments.service';
import { VenueService } from '@core/services/venue.service';
import { environment } from '@env/environment';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { log } from 'console';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-team-schedule',
  templateUrl: './team-schedule.component.html',
  styleUrls: ['./team-schedule.component.scss'],
})
export class TeamScheduleComponent implements OnInit {
  @Output() venueChange = new EventEmitter<string[]>();

  @Input() teamId: number;
  @Input() tournamentId: number;
  @Input() postSchedule: boolean;
  @Input() hasPotentialVenues: boolean;

  // _postSchedule: boolean;
  // get postSchedule(): boolean {
  //   console.log('posttddd', this.postSchedule);

  //   return this._postSchedule;
  // }
  // @Input() set postSchedule(value: boolean) {
  //   this._postSchedule = value;
  // }

  // _postSchedule: boolean;
  // @Input()
  // set postSchedule(postSchedule: boolean) {
  //   this._postSchedule = postSchedule;
  //   console.log(this.postSchedule, 'POSTDSJKFLA;SDJ');
  // }
  // get name() {
  //   return this._postSchedule;
  // }

  venueNames: string[] = [];

  team: any = {};
  games: Games[];
  game: Games;
  doesHaveGames: boolean = false;
  dateAvailable: Object[] = [];
  initLength: number;
  divisionId: any;
  tournament: Tournament;
  tournamentDates: any[] = [];
  pools: any[] = [];
  gamePeriods: GamePeriod[] = [];
  allGames: any[] = [];
  games$ = new BehaviorSubject<Games[]>([]);
  isLoading = true;

  dates: any[] = null;
  venues: any[] = null;

  faFilter = faFilter;
  isFilter: boolean = false;

  isFetching: boolean;
  schedules: any = [];
  isHovered = false;
  filterOption: any = 'all';
  filterOptions: any = [
    { label: 'All', value: 'all' },
    { label: 'By Date', value: 'date' },
    { label: 'By Venue', value: 'venue' },
  ];

  filterSubOption: any;
  filterSubOptions: any = [''];

  innerWidth: number;
  isScreen850Below: boolean;
  isScreen450Below: boolean;

  divisionBracketsDetails: any[] = [];
  refType1: string;
  refType2: string;
  refDescription1: string;
  refDescription2: string;
  selectedOptionDescription1: string;
  selectedOptionDescription2: string;

  photoUrl =
    'http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/';

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    private _http: HttpService,
    private gamesService: GamesService,
    private venueService: VenueService,
    private bracketService: BracketService
  ) {}

  ngOnInit(): void {
    this.onResize('event');
    this.subscribeToDivisionBracketsDetails();
    this.route.paramMap.subscribe(async (params) => {
      this.teamId = parseInt(params.get('teamId'));
      console.log('teeeeam id', this.teamId);
    });
    this.getTournament();
    this.getInitSchedule();
    this.getAllAssignedGames();
    console.log(this.postSchedule, 'postsched');
  }

  async getTournament() {
    try {
      await this._http
        .get({
          url: `${environment.api.tournaments}/${this.tournamentId}`,
        })
        .toPromise()
        .then((res) => {
          this.tournament = res;
          console.log(this.tournament, 'tourna');
        });
    } catch (e) {
      console.log(e);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 850) {
      this.isScreen850Below = true;
    } else {
      this.isScreen850Below = false;
    }
    if (this.innerWidth <= 450) {
      this.isScreen450Below = true;
    } else {
      this.isScreen450Below = false;
    }
  }

  sortTimeslots(timeslots: any[]): any[] {
    return timeslots.sort((a, b) => {
      const timeA = new Date('1970/01/01 ' + a.time);
      const timeB = new Date('1970/01/01 ' + b.time);
      return timeA.getTime() - timeB.getTime();
    });
  }

  sortGames(games: any[]): any[] {
    return games.sort((a, b) => {
      const venueCompare = (a.court?.venueName || '').localeCompare(
        b.court?.venueName || ''
      );
      if (venueCompare !== 0) return venueCompare;

      const extractNumber = (str: string) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const courtA = extractNumber(a.court?.name || '');
      const courtB = extractNumber(b.court?.name || '');
      return courtA - courtB;
    });
  }

  convertedDate(date) {
    var formattedDate = date.split('/');
    return formattedDate[2] + formattedDate[0] + formattedDate[1];
  }

  onSelectFilter() {
    switch (this.filterOption) {
      case 'all':
        this.getInitSchedule();
        break;
      case 'date':
        this.getInitSchedule();
        this.getDates();
        break;
      case 'venue':
        this.getInitSchedule();
        this.getVenues();
        break;
    }
  }

  onSelectSubFilter() {
    switch (this.filterOption) {
      case 'date':
        this.getDateGames();
        break;
      case 'venue':
        this.getVenueGames();
        this.openVenueSchedule(this.filterSubOption);
        break;
    }
  }

  getInitSchedule(): void {
    if (
      this.tournament?.startDate != null &&
      this.tournament?.endDate != null
    ) {
      this.enumerateDays(this.tournament?.startDate, this.tournament?.endDate);
    }
  }

  enumerateDays(fromDate, toDate): void {
    const day1 = moment(toDate);
    const day2 = moment(fromDate);
    const week = moment(
      day1.format('MM/DD/YYYY'),
      day2.format('MM/DD/YYYY')
    ).week();
    // console.log(week);
    const result = [moment({ ...day2 })];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment({ ...day2 }));
    }

    this.dates = [];
    result.map((x) => {
      const newDate = x.format('MM/DD/YYYY').split('/');
      this.dateAvailable.push({
        label: x.format('dddd, MMMM D, YYYY'),
        value: newDate[2] + newDate[0] + newDate[1],
      });
      this.dates.push({
        label: x.format('MMM DD, YYYY'),
        value: x.format('YYYYMMDD'),
      });
    });

    this.initLength = result.map((x) => x.format('MM/DD/YYYY')).length;
    this.dateAvailable.map((date) => {
      this.getAllAssignedGames();
    });
    this.isLoading = false;
  }

  getAllAssignedGames() {
    this.isFetching = true;

    const url = `${environment.api.assignedGames}?tournamentId=${this.tournamentId}&teamId=${this.teamId}`;
    this.httpService
      .get({
        url: url,
      })
      .toPromise()
      .then(
        (games) => {
          console.log('getAllAssignedGames gamess', games);

          if (games.length > 0) {
            this.divisionId = games[0].division.id;
            this.toScheduledList(games);
          } else {
            this.games = []; // Add this line
          }

          // setTimeout(() => {
          //   this.isFetching = false;
          // }, 2000);
          this.isFetching = false;
        },
        (err) => {
          this.games = []; // Add this line in error case as well
          setTimeout(() => {
            this.isFetching = false;
          }, 2000);
        }
      );

    console.log(this.isFetching, 'fetching');
  }

  toScheduledList(games: any) {
    if (games?.length > 0) {
      this.games = games;

      console.log('geymzzz', this.games);

      let schedules: any[] = [];
      let schedule: any = null;
      let lastDate: string = '';

      games.forEach((game: Games) => {
        const date: string = moment(game.date).format('YYYYMMDD');
        if (lastDate !== date) {
          if (null !== schedule) schedules.push(schedule);
          lastDate = date;
          schedule = { date: game.date, timeslots: [] };
        }
        let timeslot = schedule.timeslots.find((group: any) => {
          return group.time.toLowerCase() == game.timeslot.toLowerCase();
        });
        if (!timeslot) {
          timeslot = { time: game.timeslot, games: [] };
          schedule.timeslots.push(timeslot);
        }
        timeslot.games.push(game);
      });
      if (null !== schedule) schedules.push(schedule);

      this.schedules = schedules;
      this.venueChange.emit(this.games.map((game) => game.court?.venueName));
      this.venueNames = [
        ...new Set(this.games.map((game) => game.court?.venueName)),
      ];
    } else {
      console.log('here...');
      this.schedules = [];
    }
  }

  async getDates() {
    this.filterSubOption = this.dates.length > 0 ? this.dates[0].value : null;
    this.filterSubOptions = this.dates;

    console.log('this.filterSubOption', this.filterSubOption);

    if (this.filterSubOption) {
      this.getDateGames();
    }
  }

  async getVenues() {
    if (!this.venues) {
      this.venues = await this.venueService
        .getDivisionVenues(this.divisionId)
        .toPromise();
    }

    let options = [];
    options.push({ label: 'All', value: null });

    // Cross-reference courts and games
    const courtIdsInGames = new Set(this.games.map((game) => game.court.id));

    for (let i = 0; i < this.venues.length; i++) {
      // Check if this venue has any courts with games assigned
      if (
        this.venues[i].courts.some((court) => courtIdsInGames.has(court.id))
      ) {
        options.push({ label: this.venues[i].name, value: this.venues[i].id });
      }
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;

    console.log('this.filterSubOption', this.filterSubOption);

    if (this.filterSubOption) {
      this.getVenueGames();
    }
  }

  async getDateGames() {
    const games = await this.gamesService.getDateGames(
      this.tournamentId,
      this.filterSubOption
    );
    this.toScheduledList(games);
    console.log(games, 'dategames');
  }

  async getVenueGames() {
    const games = await this.gamesService.getVenueGames(
      this.divisionId,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  openDivision(division: Division): void {
    this.router.navigate(
      [`/public-division/${this.tournamentId}/${division.id}`],
      { state: division }
    );
  }

  openVenueSchedule(venueId: number): void {
    this.router.navigate([
      `/public-venue-schedules/${this.tournamentId}/${venueId}`,
    ]);
  }

  openTeam(team: any): void {
    this.router.navigate(
      [
        `/public-team-games/${this.tournamentId}/${team.id}/${team.name}/${this.divisionId}`,
      ],
      {
        state: {
          teamValue: team,
          // divisionId: this.divisionId
        },
      }
    );
  }

  getGameNumber(gameName: string): string {
    if (gameName.includes('#')) {
      const match = gameName.match(/Game # \d+/);
      return match ? match[0] : '';
    } else {
      return gameName;
    }
  }

  addToSureVenuesList(data) {
    console.log('WEW GUMANA', this.venueService.sureGameVenues$.value);

    let sureGameVenues = this.venueService.sureGameVenues$.value;

    if (!sureGameVenues.includes(data)) {
      sureGameVenues.push(data);
      this.venueService.sureGameVenues$.next(sureGameVenues);
    }

    console.log('WEW GUMANA part 2', this.venueService.sureGameVenues$.value);

    return false;
  }

  subscribeToDivisionBracketsDetails() {
    this.bracketService.divisionBracketsDetails$.subscribe((details) => {
      this.divisionBracketsDetails = details;
      console.log('BRACKETTT', this.divisionBracketsDetails);
    });
  }

  fetchFromBracketDetails(game) {
    let gameBracketDetails = this.divisionBracketsDetails?.find(
      (detail) => detail.bracketName === game.name
    );

    console.log('game brackk', gameBracketDetails);

    this.refType1 = gameBracketDetails?.r1?.refType;
    this.refType2 = gameBracketDetails?.r2?.refType;
    this.refDescription1 = gameBracketDetails?.r1Sub?.description;
    this.refDescription2 = gameBracketDetails?.r2Sub?.description;
    this.selectedOptionDescription1 =
      gameBracketDetails?.topSelectedOption?.description;
    this.selectedOptionDescription2 =
      gameBracketDetails?.bottomSelectedOption?.description;

    let data = {
      refType1: this.refType1,
      refType2: this.refType2,
      refDescription1: this.refDescription1,
      refDescription2: this.refDescription2,
      selectedOptionDescription1: this.selectedOptionDescription1,
      selectedOptionDescription2: this.selectedOptionDescription2,
    };

    let info;

    if (this.refType1 == 'POOL_PLACER' || this.refType2 == 'POOL_PLACER') {
      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.refDescription1 + ' in',
        top2: this.refDescription2 + ' in',
        bottom1: this.selectedOptionDescription1,
        bottom2: this.selectedOptionDescription2,
      };
    } else if (
      this.refType1 == 'WINNER' ||
      this.refType1 == 'LOSER' ||
      this.refType2 == 'WINNER' ||
      this.refType2 == 'LOSER'
    ) {
      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.selectedOptionDescription1 + ' of',
        top2: this.selectedOptionDescription2 + ' of',
        bottom1: this.refDescription1,
        bottom2: this.refDescription2,
      };
    } else {
      console.log('ELSEEEEE', data);

      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.refDescription1,
        top2: this.refDescription2,
        bottom1: this.selectedOptionDescription1,
        bottom2: this.selectedOptionDescription2,
      };
    }

    return info;
  }
}
