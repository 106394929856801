<form [formGroup]="playerForm" class="py-2 ml-1 container-form p-grid">
  <button type="button" (click)="exit()" class="btn-exit">
    <i class="pi pi-times"></i>
  </button>
  <ng-container *ngIf="!isCoach">
    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-2 mb-2 disp-block"
        >Player Photo
      </span>
    </div>
    <p-fileUpload
      name="fileLogo[]"
      accept="image/*"
      [customUpload]="true"
      (onClear)="clearLogo()"
      (onSelect)="onSelectLogo($event)"
      [showCancelButton]="false"
      (uploadHandler)="upload($event)"
      [class.uploaded]="isLogoUploaded == true"
    >
      <ng-template let-file pTemplate="file">
        <div
          *ngIf="isLogoFetching; else isFetchedLogo"
          class="text-center upload-content flex flex-center"
        >
          <p-progressSpinner
            [style]="{ width: '40px', height: '40px' }"
          ></p-progressSpinner>
        </div>
        <ng-template #isFetchedLogo>
          <div class="disp-block text-center p-0" *ngIf="isLogoUploaded">
            <img class="player-image" [src]="logoUrl" alt="Logo" />
          </div>
        </ng-template>
      </ng-template>

      <ng-template pTemplate="content" let-files>
        <div *ngIf="!isLogoUploaded" class="upload-content flex flex-center">
          <div
            *ngIf="isLogoFetching; else isFetchedLogo"
            class="text-center flex flex-center"
          >
            <p-progressSpinner
              [style]="{ width: '40px', height: '40px' }"
            ></p-progressSpinner>
          </div>

          <ng-template #isFetchedLogo>
            <div *ngIf="!hasLogo; else hasLogoTmp">
              <div class="text-center text-gray3">
                Upload&#160;<span class="text-bold2 text-primary1">Photo</span
                >&#160;or Just Drag and Drop
              </div>
              <span class="text-gray3 disp-block text-center"
                >150px X 150px</span
              >
            </div>
            <ng-template #hasLogoTmp>
              <div *ngIf="logoUrl" class="text-center">
                <img [src]="logoUrl" alt="Logo" class="player-image" />
              </div>
            </ng-template>
          </ng-template>
        </div>
      </ng-template> </p-fileUpload
  ></ng-container>

  <div class="p-col-12" *ngIf="hasSelectTeam">
    <label class="text-bold3 TheOpenSans-Regular disp-block"
      >Team <span class="required-indicator">*</span></label
    >

    <div class="gen-info-dropdown" style="align-items: flex-start">
      <p-dropdown
        [options]="teams"
        placeholder="Select Team"
        optionLabel="name"
        formControlName="team"
        autoWidth="false"
        optionValue="id"
        style="
          padding-left: 15px;
          width: 100%;
          height: 3rem;
          margin-bottom: 10px;
        "
      ></p-dropdown>
    </div>
  </div>

  <!-- <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"
        >First Name <span class="required-indicator">*</span></span
      >
      <input
        id="firstName"
        type="text"
        pInputText
        formControlName="firstName"
        style="padding-left: 15px"
      class="tournament-input-text"
      />
    <small
      *ngIf="
        playerForm.controls['firstName'].invalid &&
        (playerForm.controls['firstName'].dirty ||
          playerForm.controls['firstName'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="playerForm.controls['firstName'].errors.required"
        class="mb-3 ml-2"
      >
        First name is required.
      </div>
    </small>
  </div> -->

  <div class="p-col-12">
    <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"
      >First Name <span class="required-indicator">*</span></span
    >
    <input
      id="firstName"
      type="text"
      pInputText
      formControlName="firstName"
      style="padding-left: 15px"
      class="tournament-input-text"
    />
    <small
      *ngIf="
        playerForm.controls['firstName'].invalid &&
        (playerForm.controls['firstName'].dirty ||
          playerForm.controls['firstName'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="playerForm.controls['firstName'].errors.required"
        class="mb-3 ml-2"
      >
        First name is required.
      </div>
    </small>
  </div>

  <div class="p-col-12">
    <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"
      >Last Name <span class="required-indicator">*</span></span
    >
    <input
      id="firstName"
      type="text"
      pInputText
      formControlName="lastName"
      style="padding-left: 15px"
      class="tournament-input-text"
    />
    <small
      *ngIf="
        playerForm.controls['lastName'].invalid &&
        (playerForm.controls['lastName'].dirty ||
          playerForm.controls['lastName'].touched)
      "
      class="t10px !important;rror text-left text-primary2"
    >
      <div
        *ngIf="playerForm.controls['lastName'].errors.required"
        class="mb-3 ml-2"
      >
        Last name is required.
      </div>
    </small>
  </div>

  <div class="p-col-12">
    <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
      Jersey Number <span class="required-indicator">*</span></span
    >
    <input
      id="jerseyNumber1"
      class="addTeamInput"
      type="text"
      maxlength="2"
      minlength="0"
      pInputText
      formControlName="jerseyNumber1"
      style="padding-left: 15px"
      class="tournament-input-text"
      appNumericInput
    />
    <small
      *ngIf="
        playerForm.controls['jerseyNumber1'].invalid &&
        (playerForm.controls['jerseyNumber1'].dirty ||
          playerForm.controls['jerseyNumber1'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="playerForm.controls['jerseyNumber1'].errors.required"
        class="mb-3 ml-2"
      >
        Jersey Number is required.
      </div>
    </small>
  </div>
  <ng-container *ngIf="!isCoach">
    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        Birth Date</span
      >
      <p-calendar
        formControlName="birthdate"
        type="date"
        dateFormat="mm/dd/yy"
        class="tk-date-picker"
        appendTo="body"
      >
      </p-calendar>
    </div>

    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        Height</span
      >
      <input
        id="height"
        class="addTeamInput"
        type="text"
        pInputText
        formControlName="height"
        style="padding-left: 15px"
        class="tournament-input-text"
      />
    </div>

    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"> GPA</span>
      <div>
        <input
          id="gpa"
          class="addTeamInput"
          type="text"
          pInputText
          formControlName="gpa"
          style="padding-left: 15px"
          class="tournament-input-text"
        />
      </div>
    </div>

    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        School</span
      >
      <div>
        <input
          id="school"
          class="addTeamInput"
          type="text"
          pInputText
          formControlName="school"
          style="padding-left: 15px"
          class="tournament-input-text"
        />
      </div>
    </div>

    <!-- <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        Address</span
      >
    <div>
      <input
        id="address"
        class="addTeamInput"
        type="text"
        pInputText
        formControlName="address"
        style="padding-left: 15px"
      class="tournament-input-text"
      />
    </div>
  </div> -->

    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        Graduation Year</span
      >
      <input
        id="graduationYear"
        class="addTeamInput"
        type="text"
        pInputText
        formControlName="graduationYear"
        style="padding-left: 15px"
        class="tournament-input-text"
      />
    </div>

    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        Position</span
      >
      <input
        id="points"
        type="text"
        pInputText
        formControlName="position"
        style="padding-left: 15px"
        class="tournament-input-text"
      />
      <!-- <small
        *ngIf="
          playerForm.controls['position'].invalid &&
          (playerForm.controls['position'].dirty ||
            playerForm.controls['position'].touched)
        "
        class="text-error text-left text-primary2"
      >
        <div
          *ngIf="playerForm.controls['points'].errors.required"
          class="mb-3 ml-2"
        >
          Position is required.
        </div>
      </small> -->
    </div>
    <div class="p-col-12">
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
        Player Notes</span
      >
      <textarea
        formControlName="note"
        rows="4"
        cols="30"
        class="tournament-inputarea-text"
        autoResize="autoResize"
      ></textarea>
    </div>

    <div class="p-col-12 pl-3">
      <p-fieldset [toggleable]="true" [collapsed]="true">
        <p-header>
          <div class="p-col-12">
            <span class="text-bold3 TheOpenSans-Regular mb-2">
              Parent/Guardian Info
            </span>
          </div>
        </p-header>
        <div class="p-col-12">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Parent's/Guardian's Name</span
              >
              <input
                id="parentName1"
                type="text"
                pInputText
                formControlName="parentName1"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Parent's/Guardian's Email</span
              >
              <input
                id="parentEmail1"
                type="email"
                pInputText
                formControlName="parentEmail1"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Parent's/Guardian's Phone</span
              >
              <input
                id="parentPhone1"
                type="tel"
                pInputText
                formControlName="parentPhone1"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                2nd Parent's/Guardian's Name</span
              >

              <input
                id="parentName2"
                type="text"
                pInputText
                formControlName="parentName2"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                2nd Parent's/Guardian's Email</span
              >

              <input
                id="parentEmail2"
                type="email"
                pInputText
                formControlName="parentEmail2"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                2nd Parent's/Guardian's Phone</span
              >
              <input
                id="parentPhone2"
                type="tel"
                pInputText
                formControlName="parentPhone2"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
    <div class="p-col-12 pl-3">
      <p-fieldset [toggleable]="true" [collapsed]="true">
        <p-header>
          <div class="p-col-12">
            <span class="text-bold3 TheOpenSans-Regular mb-2">
              Social & Contact Info
            </span>
          </div>
        </p-header>
        <div class="p-col-12">
          <div class="p-fluid p-grid">
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Email</span
              >
              <input
                id="email"
                type="email"
                pInputText
                formControlName="email"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Phone Number</span
              >
              <input
                id="phone"
                type="tel"
                pInputText
                formControlName="phone"
                style="padding-left: 15px"
                class="tournament-input-text"
                maxlength="10"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Facebook Page</span
              >
              <input
                id="facebookPage"
                type="text"
                pInputText
                formControlName="facebookPage"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                Instagram</span
              >

              <input
                id="instagramHandle"
                type="text"
                pInputText
                formControlName="instagramHandle"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
            <div class="p-field p-col-12">
              <div class="twitter-pos">
                <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
                  X Account
                </span>
                <div class="description">(Formerly known as Twitter)</div>
              </div>
              <input
                id="twitterHandle"
                type="text"
                pInputText
                formControlName="twitterHandle"
                style="padding-left: 15px"
                class="tournament-input-text"
              />
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
  </ng-container>

  <div class="flex flex-center my-4">
    <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
      Cancel
    </button>

    <button
      type="submit"
      (click)="submit()"
      class="btn-rounded-red mx-2"
      [disabled]="!playerForm.valid"
    >
      SAVE
    </button>
  </div>
</form>

<!-- <div>
    <p-autoComplete
      field="label"
      appendTo="body"
      inputStyleClass="input-text disp-block autocomplete my-2"
      [required]="true"
      autocomplete="noauto"
      emptyMessage="No results found"
      placeholder="City & State"
      [autoHighlight]="true"
      [forceSelection]="true"
      [suggestions]="cityStates"
      formControlName="cityState"
      (completeMethod)="searchCityState($event)"
      dropdownIcon="pi pi-angle-down"
    >
    </p-autoComplete>
    <small
      *ngIf="
      playerForm.controls['cityStateZip'].invalid &&
        (playerForm.controls['cityStateZip'].dirty ||
        playerForm.controls['cityStateZip'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="playerForm.controls['cityStateZip'].errors.required"
        class="mb-3 ml-2"
      >
        City & State is required.
      </div>
    </small>
  </div> -->
