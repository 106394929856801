import { Component, HostListener, Input, OnInit } from '@angular/core';

// SERVICES
import { CoachTeamsService } from '@core/services/coach-teams.service';

// MODELS
import { Player } from '@core/models/players';

// ENV
import moment from 'moment';
import { Games } from '@core/models/games';
import { Tournament } from '@core/models/tournament';

@Component({
  selector: 'app-team-roster',
  templateUrl: './team-roster.component.html',
  styleUrls: ['./team-roster.component.scss'],
})
export class TeamRosterComponent implements OnInit {
  _tournament: Tournament;

  get tournament(): any {
    return this._tournament;
  }

  @Input() set tournament(value: any) {
    this._tournament = value;
    console.log(this._tournament);
  }

  @Input() teamId: number;
  @Input() hasPotentialVenues: boolean;

  players: Player[];

  isFetching: boolean;
  isLoading = true;

  innerWidth: any;

  constructor(private playerService: CoachTeamsService) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;

    this.getPlayers();
  }

  /**
   * Gets the players for the team from the server.
   *
   * The teamId passed from the parent public-team-games component is used to identify the team
   * which players are being viewed.
   */
  async getPlayers(): Promise<any> {
    // this.user = await this.httpService.me().toPromise();
    const data = {
      teamId: this.teamId,
    };
    this.playerService
      .getPlayers(data)
      .toPromise()
      .then((players) => {
        this.players = players;
      });
  }

  /**
   * Calculate the age of the player based on the birthdate.
   *
   * @param birthdate
   * @returns the age
   */
  calculateAge(birthdate: Date) {
    const age = '-';
    if (moment().diff(birthdate, 'years') > 0) {
      return moment().diff(birthdate, 'years');
    } else {
      return age;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
}
