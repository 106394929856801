import { Component, OnInit } from '@angular/core';

// SERVICES
import { AuthService, EmitterService, HttpService } from '@services/index';

// MODELS
import { User } from '@models/user';
import { Organization } from '@models/organization';
import { defConstant } from '@core/data/constant-data';

// ENV
import { environment } from '@env/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { log } from 'console';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  isChangePassowrd: boolean;
  currentVersion = defConstant.currentVersion;

  pageName: string = 'Dashboard'; //former overview//
  org: Organization;
  user: User;

  constructor(
    private authService: AuthService,
    private emitterService: EmitterService,
    private httpService: HttpService,
    private auth: AuthService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router
  ) {
    this.emitterService.pageNameEmitter.subscribe((pageName) => {
      this.pageName = pageName;
    });

    this.emitterService.orgEmitter.subscribe((org) => {
      this.org = org;
    });

    this.emitterService.meEmitter.subscribe((user) => {
      console.log('user', user);
      this.user = user;
      this.ngxLoader.stop();
    });
    this.auth.isShowChangePass$.subscribe((res) => {
      console.log('iopen', res);
      this.isChangePassowrd = res;
    });
  }

  ngOnInit(): void {
    this.getData();
  }
  changePassword() {
    this.auth.isShowChangePass$.next(true);
  }
  async getData() {
    const userData = this.emitterService.userData;
    if (userData) {
      this.user = userData;
      this.ngxLoader.stop();
    } else {
      this.user = await this.httpService.me().toPromise();
      this.ngxLoader.stop();
    }

    const orgData = this.emitterService.orgData;
    if (orgData?.name) {
      this.org = orgData;
    } else {
      this.org = await this.httpService
        .get({
          url: environment.api.org,
          payload: {
            userId: this.user.id,
          },
        })
        .toPromise();
    }
  }

  logout(): void {
    this.authService.logout();
  }
}
