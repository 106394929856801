import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from '@components/public/login/login.component';
import { SignupComponent } from '@components/public/signup/signup.component';
import { defConstant, errConstant } from '@core/data/constant-data';
import {
  AuthService,
  EmitterService,
  HttpService,
  SessionStorageService,
  ToastService,
} from '@core/services';
import { environment } from '@env/environment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  openLogin: boolean = false;
  isLogged: boolean = false;
  role: string;
  @ViewChild('opLogin', { static: false }) opLogin;
  @ViewChild(LoginComponent) child: LoginComponent;

  @ViewChild('opLogins') opLogins: OverlayPanel;
  toggleOverlay = ({ originalEvent }) => this.opLogins.toggle(originalEvent);

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    private httpService: HttpService,
    private emitterService: EmitterService,
    private toastService: ToastService,
    private sessionStorageService: SessionStorageService,
    public ref: DynamicDialogRef
  ) {
    this.authService.isLoggedInEmitter.subscribe((isLoggedIn) => {
      this.isLogged = isLoggedIn;
    });
    this.role = sessionStorage.getItem('role')

  }

  ngOnInit(): void {}

  signup(): void {
    this.ref = this.dialogService.open(SignupComponent, {
      width: defConstant.modalWidth,
      closable: true,
      contentStyle: { overflow: 'auto' },
    });

    this.ref.onClose.subscribe((res: any) => {
      if (res) {
      }
    });
  }

  async gotoCoachDashboard(me: any) {
    this.router.navigate(['/coach-dashboard']);
  }

  async gotoDashboard() {
    const me = await this.httpService.me().toPromise();
    if (me.userType === 'director') {
      this.gotoDirectorDashboard(me);
    } else if (me.userType === 'coach') {
      this.gotoCoachDashboard(me);
    }
  }

  async gotoDirectorDashboard(me: any) {
    try {
      const org = await this.httpService
        .get({
          url: environment.api.org,
          payload: {
            userId: me.id,
          },
        })
        .toPromise();

      this.sessionStorageService.setSession('org', org);
      this.emitterService.orgEmit(org);

      if (org.completed) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/dashboard/organization-settings/info']);
        this.emitterService.pageNameEmit('Organization Settings');
      }
    } catch (err) {
      console.log(err);
      this.toastService.errorMsg({ message: errConstant.anErrOccurred });
    }
  }

  toggleLogIn(event, element) {
    element.toggle(event);
    this.openLogin = true;
  }

  logout(): void {
    this.authService.logout();
  }
  goToBrowse(): void {
    this.router.navigate(['/browse-tournaments']);
  }
  gotoHome(): void {
    this.authService.isLoggedInEmitter.next(true);
    this.router.navigate(['']);
  }

  goToPricingPage() : void {
    this.router.navigate(['/pricing']);
  }

  goToSupportPage() : void {
    this.router.navigate(['/support']);
  }
}
