import { Component, OnInit } from '@angular/core';
import { Teams } from '@core/models/teams';
import { ToastService } from '@core/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-team-modal',
  templateUrl: './team-modal.component.html',
  styleUrls: ['./team-modal.component.scss']
})
export class TeamModalComponent implements OnInit {
  teams: Teams[];
  team: Teams;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.teams = this.config.data.teams;
  }

  close(): void {
    this.ref.close();
  }

  select(): void {
    if (!this.team) return this.toastService.errorMsg({message: 'No team selected.'})
    console.log('select: ', this.team);
    this.ref.close(this.team);
  }

}
