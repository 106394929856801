import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// MODELS
import { Division } from '@core/models/division';
import { Court } from '@core/models/courts';

// SERVICES
import { HttpService } from '@services/index';

// ENV
import { environment } from '@env/environment';
import moment from 'moment';
import { Tournament } from '@core/models/tournament';
import { rejects } from 'assert';
import { Games } from '@core/models/games';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { TeamsService } from '@core/services/teams.service';
import { Teams } from '@core/models/teams';
import { GamesService } from '@core/services/games.service';
import { PlayerService } from '@core/services/player.service';
import { VenueService } from '@core/services/venue.service';
import { BracketService } from '@core/services/bracket-service';
import { log, time } from 'console';
import { DatePipe } from '@angular/common';
import { TournamentsService } from '@core/services/tournaments.service';
import { DivisionService } from '@core/services/division.service';
import { BracketSet } from '@core/models/bracket-set';
import { BracketSources } from '@core/models/bracket-sources';
import { BracketPreviewComponent } from '@components/admin/bracket-builder/bracket-preview/bracket-preview.component';
import { PublicBracketComponent } from '@public/public-bracket/public-bracket.component';
import { Bracket } from '@core/models/brackets';
import * as objectScan from 'object-scan';

@Component({
  selector: 'app-team-potential-games',
  templateUrl: './team-potential-games.component.html',
  styleUrls: ['./team-potential-games.component.scss'],
})
export class TeamPotentialGamesComponent implements OnInit {
  @ViewChild('bracket', { read: ViewContainerRef }) target: ViewContainerRef;

  _division: Division;
  get division(): Division {
    return this._division;
  }

  @Input()
  set division(value: Division) {
    this._division = value;
    if (value) this.getAllAssignedGames();
  }

  @Input() hasPotentialVenues: boolean;
  @Input() teamName: string;

  data: any = [];
  isFetching: boolean;
  dateAvailable: Object[] = [];
  initLength: number;
  games: Games[];
  isLoading = true;
  week = 1;

  faFilter = faFilter;
  isFilter: boolean = false;

  schedules: any = [];
  schedulesDuplicate: any = [];
  isHovered = false;
  filterOption: any = 'all';
  filterOptions: any = [
    { label: 'All', value: 'all' },
    { label: 'By Team', value: 'team' },
    { label: 'By Player', value: 'player' },
    { label: 'By Date', value: 'date' },
    { label: 'By Venue', value: 'venue' },
  ];

  filterSubOption: any;
  filterSubOptions: any = [''];

  teams: any[] = null;
  players: any[] = null;
  dates: any[] = null;
  venues: any[] = null;
  venuesDuplicate: any[] = [];
  divisionTeams: any[] = [];
  divisionBracketsDetails: any[] = [];
  refType1: string;
  refType2: string;
  refDescription1: string;
  refDescription2: string;
  selectedOptionDescription1: string;
  selectedOptionDescription2: string;

  divisionTournDates: any[] = [];

  divisionId: number;
  tournamentId: number;

  bracketSet: BracketSet;
  isHasSet = false;
  stageHeight: number;
  stageWidth: number;
  firstOptions: BracketSources[];
  dynamicComponent: any[] = [];
  topBrackets: any;
  bottomBrackets: any;

  innerWidth: number;
  isScreen850Below: boolean;
  isScreen450Below: boolean;

  private componentRef: ComponentRef<any>;

  potentialVenues: any[] = [];
  sureVenues: any[] = [];

  constructor(
    private router: Router,
    private httpService: HttpService,
    private gamesService: GamesService,
    private playerService: PlayerService,
    private venueService: VenueService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private bracketService: BracketService,
    private tournamentService: TournamentsService,
    private route: ActivatedRoute,
    private divisionService: DivisionService
  ) {}

  ngOnInit(): void {
    this.onResize('event');
    this.route.paramMap.subscribe(async (params) => {
      this.divisionId = parseInt(params.get('divisionId'));
      this.tournamentId = parseInt(params.get('tournamentId'));
      console.log('tournamentIdtournamentId', this.tournamentId);

      this.division = await this.divisionService.getDivision(this.divisionId);
    });

    this.getInitSchedule();
    // this.subscribeToDivisionBracketsDetails();
    this.initBracketSet();
    this.subscribeToSureGameVenues();
    this.subscribeToVenuesDuplicate();
  }

  // subscribeToDivisionBracketsDetails() {
  //   this.bracketService.divisionBracketsDetails$.subscribe((details) => {
  //     this.divisionBracketsDetails = details;
  //   });
  // }

  initBracketSet() {
    this.bracketService
      .getBracketSet(this.divisionId)
      .then((bracketSet) => {
        console.log('BRACKET SET', bracketSet);

        if (bracketSet == null) {
          this.isHasSet = false;
        } else {
          this.isHasSet = true;
          this.bracketSet = bracketSet;
          this.stageHeight = this.bracketSet.height;
          this.stageWidth = this.bracketSet.width;

          this.bracketService
            .getBracketsSources(this.divisionId, this.bracketSet.id)
            .then((res: BracketSources[]) => {
              console.log('BRACKET SOURCES', res);

              this.firstOptions = res;
              this.renderBrackets();
            });
        }
      })
      .catch((err) => {});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 850) {
      this.isScreen850Below = true;
    } else {
      this.isScreen850Below = false;
    }
    if (this.innerWidth <= 450) {
      this.isScreen450Below = true;
    } else {
      this.isScreen450Below = false;
    }
  }

  renderBrackets() {
    this.bracketSet.brackets.forEach((v: Bracket, index) => {
      this.addBracket(
        v.x1,
        v.y1,
        v.name,
        v.topTeam,
        v.bottomTeam,
        v.topSource,
        v.bottomSource,
        this.calculateHeight(v.y1, v.y2),
        this.calculateWidth(v.x3, v.x1),
        v,
        v.bracketType,
        v.courtName,
        v.venueName,
        v.name
      );
      // this.venues.push(`${v?.venueName} ${v?.courtName}`);

      this.bottomBrackets = this.searchBottomBracket(v);
      this.topBrackets = this.searchTopBracket(v);
      for (
        var i = 0, j = 0;
        i < this.bottomBrackets.length || j < this.topBrackets.length;

      ) {
        if (i < this.bottomBrackets.length) {
          if (this.bottomBrackets[i] != null) {
            // this.venues.push(`${v?.venueName} ${v?.courtName}`);

            this.addBracket(
              this.bottomBrackets[i].x1,
              this.bottomBrackets[i].y1,
              this.bottomBrackets[i].name,
              this.bottomBrackets[i].topTeam,
              this.bottomBrackets[i].bottomTeam,
              this.bottomBrackets[i].topSource,
              this.bottomBrackets[i].bottomSource,
              this.calculateHeight(
                this.bottomBrackets[i].y1,
                this.bottomBrackets[i].y2
              ),
              this.calculateWidth(
                this.bottomBrackets[i].x3,
                this.bottomBrackets[i].x1
              ),

              this.bottomBrackets[i],
              v.bracketType,
              v.courtName,
              v.venueName,
              v.name
            );
            // console.log('bottom ', this.bottomBrackets[i].name);
          }

          i++;
        }
        if (j < this.topBrackets.length) {
          if (this.topBrackets[j] != null) {
            // this.venues.push(`${v?.venueName} ${v?.courtName}`);
            this.addBracket(
              this.topBrackets[j].x1,
              this.topBrackets[j].y1,
              this.topBrackets[j].name,
              this.topBrackets[j].topTeam,
              this.topBrackets[j].bottomTeam,
              this.topBrackets[j].topSource,
              this.topBrackets[j].bottomSource,
              this.calculateHeight(
                this.topBrackets[j].y1,
                this.topBrackets[j].y2
              ),
              this.calculateWidth(
                this.topBrackets[j].x3,
                this.topBrackets[j].x1
              ),
              this.topBrackets[j],
              v.bracketType,
              v.courtName,
              v.venueName,
              v.name
            );
            // console.log('top ', this.topBrackets[j].name);
          }

          j++;
        }
      }
    });
  }

  addBracket(
    x: number,
    y: number,
    name: string,
    topTeam,
    bottomTeam,
    topSource,
    bottomSource,
    h: number,
    w: number,
    br?: any,
    bracketType?: string,
    courtName?: string,
    venueName?,
    gameName?: string
  ) {
    console.log('bracket render', gameName);

    let childComponent = this.componentFactoryResolver.resolveComponentFactory(
      BracketPreviewComponent
    );

    console.log('childComponentttt', childComponent);

    this.componentRef = this.target.createComponent(childComponent);

    console.log('componenetRef', this.componentRef);

    this.componentRef.instance.firstOptions = this.firstOptions;
    this.componentRef.instance.height = h;
    this.componentRef.instance.width = w;
    this.componentRef.instance.left = x;
    this.componentRef.instance.top = y;
    this.componentRef.instance.id = br.id == null ? 0 : br.id;
    this.componentRef.instance.bracketName = name;
    this.componentRef.instance.topSelectedOption = topSource;
    this.componentRef.instance.bottomSelectedOption = bottomSource;
    this.componentRef.instance.topTeam = topTeam ? topTeam.name : '';
    this.componentRef.instance.bottomTeam = bottomTeam ? bottomTeam.name : '';
    this.componentRef.instance.bracketName = name;
    this.componentRef.instance.parentRef = PublicBracketComponent;
    this.componentRef.instance.bracketType = bracketType;
    this.componentRef.instance.courtName = courtName;
    this.componentRef.instance.gameName = gameName;
    this.componentRef.instance.venueName = venueName;

    this.componentRef.instance.bracketObject = {
      division: this.division,
      br: br,
      bracketSet: this.bracketSet,
    };

    // Pass bracket details to be fetched by Team Schedules Component
    this.divisionBracketsDetails.push(this.componentRef.instance);
    this.bracketService.divisionBracketsDetails$.next(
      this.divisionBracketsDetails
    );

    console.log('this.divisionBracketsDetailss', this.divisionBracketsDetails);

    this.dynamicComponent.push(this.componentRef.instance);
  }

  calculateHeight(y1, y2): number {
    let y = y2 - y1;
    return y;
  }

  calculateWidth(x3, x1): number {
    return x3 - x1;
  }

  searchTopBracket(obj: any) {
    let temp = [];
    let arr = objectScan(['**.topBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }

  searchBottomBracket(obj: any) {
    let temp = [];

    let arr = objectScan(['**.bottomBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }

  sortTimeslots(timeslots: any[]): any[] {
    console.log('timeslots', timeslots);

    return timeslots.sort((a, b) => {
      const timeA = new Date('1970/01/01 ' + a.time);
      const timeB = new Date('1970/01/01 ' + b.time);
      return timeA.getTime() - timeB.getTime();
    });
  }

  sortGames(games: any[]): any[] {
    return games.sort((a, b) => {
      const venueCompare = (a.court?.venueName || '').localeCompare(
        b.court?.venueName || ''
      );
      if (venueCompare !== 0) return venueCompare;

      const extractNumber = (str: string) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const courtA = extractNumber(a.court?.name || '');
      const courtB = extractNumber(b.court?.name || '');
      return courtA - courtB;
    });
  }

  checkIfTimeslotHasGames(timeslot: any) {
    console.log('to check', timeslot);

    let filteredGames: any[] = [];
    let hasFutureGames = false;

    filteredGames = timeslot.games.filter((game) => !game.team1 && !game.team2);

    console.log('filtered games', filteredGames);

    if (filteredGames.length > 0) {
      hasFutureGames = true;
    }

    return hasFutureGames;
  }

  checkIfScheduleHasGames(schedule: any) {
    console.log('schedule to check', schedule);

    let filteredGames: any[] = [];
    let hasFutureGames = false;

    filteredGames = schedule.timeslots.filter(
      (timeslot) =>
        timeslot.games.filter((game) => !game.team1 && !game.team2).length > 0
    );

    // filteredGames = timeslot.games.filter((game) => !game.team1 && !game.team2);

    console.log('filtered games', filteredGames);

    if (filteredGames.length > 0) {
      hasFutureGames = true;
    }

    return hasFutureGames;
  }

  getScheduleLabel() {
    switch (this.filterOption) {
      case 'all':
        return 'SCHEDULE';
      case 'team':
        return 'SCHEDULE';
        break;
      case 'player':
        return 'SCHEDULE';
        break;
      case 'date':
        return 'SCHEDULE';
        break;
      case 'venue':
        return 'SCHEDULE';
    }
  }

  getTeamLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.teams[ndx].name.toUpperCase();
  }

  getPlayerLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.players[ndx].fullName.toUpperCase();
  }

  getDateLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.dates[ndx].label.toUpperCase();
  }

  getVenueLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.venues[ndx].name.toUpperCase();
  }

  getInitSchedule(): void {
    if (
      this.division?.tournament?.startDate != null &&
      this.division?.tournament?.endDate != null
    ) {
      this.enumerateDays(
        this.division.tournament?.startDate,
        this.division.tournament?.endDate
      );
    }
  }

  enumerateDays(fromDate, toDate): void {
    const day1 = moment(toDate);
    const day2 = moment(fromDate);
    const week = moment(
      day1.format('MM/DD/YYYY'),
      day2.format('MM/DD/YYYY')
    ).week();
    // console.log(week);
    const result = [moment({ ...day2 })];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment({ ...day2 }));
    }

    this.dates = [];
    result.map((x) => {
      const newDate = x.format('MM/DD/YYYY').split('/');
      this.dateAvailable.push({
        label: x.format('dddd, MMMM D, YYYY'),
        value: newDate[2] + newDate[0] + newDate[1],
      });
      this.dates.push({
        label: x.format('MMM DD, YYYY'),
        value: x.format('YYYYMMDD'),
      });
    });

    this.initLength = result.map((x) => x.format('MM/DD/YYYY')).length;
    this.dateAvailable.map((date) => {
      this.getAllAssignedGames();
    });
    this.isLoading = false;
  }

  getAllAssignedGames() {
    this.isFetching = true;

    const url = `${environment.api.assignedGames}?divisionId=${this.division.id}`;
    this.httpService
      .get({
        url: url,
      })
      .toPromise()
      .then(
        (games) => {
          this.toScheduledList(games);

          setTimeout((_) => {
            this.isFetching = false;
          }, 250);
        },
        (err) => {
          setTimeout((_) => {
            this.isFetching = false;
          }, 250);
        }
      );
  }

  openDivision(division: Division): void {
    this.router.navigate(
      [`/public-division/${this.division.tournament.id}/${division.id}`],
      { state: division }
    );
  }

  openVenueSchedule(venueId: number): void {
    this.router.navigate([
      `/public-venue-schedules/${this.division.tournament.id}/${venueId}`,
    ]);
  }

  openTeam(team: any): void {
    this.router.navigate(
      [
        `/public-team-games/${this.division.tournament.id}/${team.id}/${team.name}`,
      ],
      {
        state: {
          teamValue: team,
          // divisionId: this.divisionId
        },
      }
    );
  }

  onSelectFilter() {
    switch (this.filterOption) {
      case 'all':
        this.getInitSchedule();
        break;
      case 'team':
        // this.getInitSchedule();
        this.getTeams();
        break;
      case 'player':
        this.getPlayers();
        break;
      case 'date':
        this.getDates();
        break;
      case 'venue':
        // this.getInitSchedule();
        this.getVenues();
        break;
    }
  }

  /**
   * Updates the schedule on sub filter changes (the right one).
   */
  onSelectSubFilter() {
    switch (this.filterOption) {
      case 'team':
        // this.getTeamGames();
        const teamIndex = this.teams.findIndex(
          (teams: Teams) => teams.id === this.filterSubOption
        );
        if (teamIndex !== -1) {
          console.log(teamIndex);
          this.openTeam(this.teams[teamIndex]);
        }
        break;
      case 'player':
        this.getPlayerGames();
        break;
      case 'date':
        this.getDateGames();
        break;
      case 'venue':
        this.getVenueGames();
        this.openVenueSchedule(this.filterSubOption);
        break;
    }
  }

  async getTeams() {
    if (!this.teams) {
      this.teams = [];
      const url = `${environment.api.divisionsTeams}?divisionId=${this.division.id}`;
      this.httpService
        .get({
          url: url,
        })
        .subscribe(
          (res: any) => {
            this.teams = res;

            let options = [];
            options.push({ label: '-', value: null });
            for (let i = 0; i < this.teams.length; i++) {
              options.push({
                label: this.teams[i].name,
                value: this.teams[i].id,
              });
            }

            this.filterSubOption = options.length > 0 ? options[0].value : null;
            this.filterSubOptions = options;

            if (this.filterSubOption) {
              // this.getTeamGames();
            }
          },
          (err) => {}
        );
    }
  }

  async getPlayers() {
    if (!this.players) {
      this.players = await this.playerService
        .getPlayersByDivision(this.division.id)
        .toPromise();
    }

    let options = [];
    options.push({ label: '-', value: null });
    for (let i = 0; i < this.players.length; i++) {
      options.push({
        label: `${this.players[i].fullName} (${this.players[i].team.name})`,
        value: this.players[i].id,
      });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;
    console.log(options);

    if (this.filterSubOption) {
      this.getPlayerGames();
    }
  }

  async getVenues() {
    if (!this.venues) {
      this.venues = await this.venueService
        .getDivisionVenues(this.division.id)
        .toPromise();
    }

    let options = [];
    options.push({ label: '-', value: null });
    for (let i = 0; i < this.venues.length; i++) {
      options.push({ label: this.venues[i].name, value: this.venues[i].id });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;

    console.log('this.filterSubOption', this.filterSubOption);

    if (this.filterSubOption) {
      this.getVenueGames();
    }
  }

  async getPlayerGames() {
    const games = await this.gamesService.getPlayerGames(
      this.division.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  async getDates() {
    console.log('datess', this.divisionTournDates);

    // this.filterSubOption = this.dates.length > 0 ? this.dates[0].value : null;
    // this.filterSubOptions = this.dates;
    let dates: any[] = [];

    this.divisionTournDates.map((date) => {
      dates.push(new DatePipe('en-US').transform(date, 'MMM d, y'));
    });

    console.log('dateeee', dates);

    this.filterSubOption = dates.length > 0 ? dates[0] : null;
    this.filterSubOptions = dates;

    console.log('this.filterSubOption', this.filterSubOption);

    if (this.filterSubOption) {
      this.getDateGames();
    }
  }

  async getDateGames() {
    console.log('getDateGames passed');

    let selectedDate: any;

    selectedDate = this.divisionTournDates.find(
      (tournDate) =>
        new DatePipe('en-US').transform(tournDate, 'MMM d, y') ==
        this.filterSubOption
    );

    console.log('chosenDATE', selectedDate);

    this.schedules = this.schedulesDuplicate;

    this.schedules = this.schedules.filter(
      (schedule) => schedule.date == selectedDate
    );

    // const games = await this.gamesService.getDateGames(
    //   this.division.tournament.id,
    //   selectedDate
    // );
    // this.toScheduledList(games);
  }

  async getVenueGames() {
    const games = await this.gamesService.getVenueGames(
      this.division.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  toScheduledList(games: any) {
    if (games && games.length > 0) {
      this.games = games;

      games = games.filter((game) => game.division.id === this.division.id);

      let schedules: any[] = [];
      let schedule: any = null;
      let lastDate: string = '';

      games.forEach((game: Games) => {
        const date: string = moment(game.date).format('YYYYMMDD');
        if (lastDate !== date) {
          if (null !== schedule) schedules.push(schedule);
          lastDate = date;
          schedule = { date: game.date, timeslots: [] };
        }
        let timeslot = schedule.timeslots.find((group: any) => {
          return group.time?.toLowerCase() == game.timeslot?.toLowerCase();
        });
        if (!timeslot) {
          timeslot = { time: game.timeslot, games: [] };
          schedule.timeslots.push(timeslot);
        }
        timeslot.games.push(game);
      });
      if (null !== schedule) schedules.push(schedule);

      this.schedules = schedules;
      this.schedulesDuplicate = this.schedules;
      console.log('schedss', this.schedules);

      this.getPotentialVenues(this.schedules);

      this.schedules.map((sched) => {
        this.divisionTournDates.push(sched.date);
        console.log('divisionTournDates', this.divisionTournDates);
      });
    } else {
      console.log('here...');
      this.schedules = [];
    }
  }

  getGameNumber(gameName: string): string {
    if (gameName.includes('#')) {
      const match = gameName.match(/Game # \d+/);
      return match ? match[0] : '';
    } else {
      return gameName;
    }
  }

  fetchFromBracketDetails(game) {
    let gameBracketDetails = this.divisionBracketsDetails?.find(
      (detail) => detail.bracketName === game.name
    );

    console.log('game brackk', gameBracketDetails);

    this.refType1 = gameBracketDetails?.r1?.refType;
    this.refType2 = gameBracketDetails?.r2?.refType;
    this.refDescription1 = gameBracketDetails?.r1Sub?.description;
    this.refDescription2 = gameBracketDetails?.r2Sub?.description;
    this.selectedOptionDescription1 =
      gameBracketDetails?.topSelectedOption?.description;
    this.selectedOptionDescription2 =
      gameBracketDetails?.bottomSelectedOption?.description;

    // if (this.hasNumber(this.refDescription1)) {
    //   this.refDescription1 = this.refDescription1 + ' in';
    // } else {
    //   this.refDescription1 = this.refDescription1 + ' of';
    // }

    // if (this.hasNumber(this.refDescription2)) {
    //   this.refDescription2 = this.refDescription2 + ' in';
    // } else {
    //   this.refDescription2 = this.refDescription2 + ' of';
    // }

    let data = {
      refType1: this.refType1,
      refType2: this.refType2,
      refDescription1: this.refDescription1,
      refDescription2: this.refDescription2,
      selectedOptionDescription1: this.selectedOptionDescription1,
      selectedOptionDescription2: this.selectedOptionDescription2,
    };

    let info;

    if (this.refType1 == 'POOL_PLACER' || this.refType2 == 'POOL_PLACER') {
      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.refDescription1 + ' in',
        top2: this.refDescription2 + ' in',
        bottom1: this.selectedOptionDescription1,
        bottom2: this.selectedOptionDescription2,
      };
    } else if (
      this.refType1 == 'WINNER' ||
      this.refType1 == 'LOSER' ||
      this.refType2 == 'WINNER' ||
      this.refType2 == 'LOSER'
    ) {
      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.selectedOptionDescription1 + ' of',
        top2: this.selectedOptionDescription2 + ' of',
        bottom1: this.refDescription1,
        bottom2: this.refDescription2,
      };
    } else {
      console.log('ELSEEEEE', data);

      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.refDescription1,
        top2: this.refDescription2,
        bottom1: this.selectedOptionDescription1,
        bottom2: this.selectedOptionDescription2,
      };
    }

    return info;
  }

  getPotentialVenues(schedules: any) {
    console.log('ETO SCHEDU', schedules);

    let potentialVenues = [];

    schedules.forEach((sched) => {
      sched.timeslots.forEach((timeslot) => {
        timeslot.games.forEach((game) => {
          if (!potentialVenues.includes(game.court?.venueName)) {
            potentialVenues.push(game.court?.venueName);
          }
        });
      });
    });

    // this.potentialVenues = potentialVenues;
    // this.venueService.potentialVenues$.next(potentialVenues);
    this.potentialVenues = potentialVenues?.filter(
      (ven) => !this.sureVenues.includes(ven)
    );

    console.log('eto poten ven', potentialVenues);
  }

  openVenue(venueName: string) {
    console.log('VENUEEEEEES', this.venuesDuplicate);

    let selectedVenue = this.venuesDuplicate.find(
      (venue) => (venue.name = venueName)
    );
    console.log('selected', selectedVenue);

    this.router.navigate([
      `/public-venue-schedules/${this.tournamentId}/${selectedVenue.id}`,
    ]);
  }

  subscribeToSureGameVenues() {
    this.venueService.sureGameVenues$.subscribe((sureVenues) => {
      console.log('una', this.potentialVenues);
      this.sureVenues = sureVenues;

      this.potentialVenues = this.potentialVenues?.filter(
        (ven) => !sureVenues.includes(ven)
      );

      console.log('pangalawa', this.potentialVenues);
      console.log('surevens', this.sureVenues);
    });
  }

  subscribeToVenuesDuplicate() {
    this.venueService.venuesDuplicate$.subscribe((venuesD) => {
      console.log('hayst', venuesD);
      this.venuesDuplicate = venuesD;
    });
  }
}
