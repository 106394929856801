import { Component, OnInit } from '@angular/core';
import { BracketService } from '@core/services/bracket.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-unsave-changes',
  templateUrl: './unsave-changes.component.html',
  styleUrls: ['./unsave-changes.component.scss']
})
export class UnsaveChangesComponent implements OnInit {

  constructor(
    public ref: DynamicDialogRef,
    private bracketService: BracketService,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
  }

  async onSubmit() {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close();
  }

}
