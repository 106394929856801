import { Component, OnInit, Input } from '@angular/core';
import { Division } from '@core/models/division';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RegistrationModalComponent } from '@public/public-registration/registration-modal/registration-modal.component';
import { Registration } from '@core/models/registration';
import { GeneralInfo } from '@core/models/gen-info';
import { Tournament } from '@core/models/tournament';

@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss'],
})
export class DivisionsComponent implements OnInit {
  ref: DynamicDialogRef;
  @Input() isLoggedIn = false;
  @Input() isReview;
  @Input() tournamentId: number;
  @Input() registration: Registration;

  _tournament: Tournament;

  get tournament(): any {
    return this._tournament;
  }

  @Input() set tournament(value: any) {
    this._tournament = value;
    console.log(this._tournament);
  }

  // tournamentId: string;
  generalInfo: GeneralInfo;
  divisions: Division[] = [];

  division: Division;

  menuItems: MenuItem[];

  primaryColor: string;
  secondaryColor: string;
  isBlack: boolean;
  fontColor: string;
  fontColorOpposite: string;

  constructor(
    private route: ActivatedRoute,
    public publicTournamentsService: PublicTournamentsService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tournament = this.route.paramMap.pipe(() => window.history.state);
    this.generalInfo = this.tournament;

    this.getPublicDivisionsList(this.tournamentId);

    // this.publicTournamentsService
    //   .getRegistration(this.tournamentId)
    //   .then((res) => {
    //     this.registration = res;
    //   });
  }

  selectDivision(division: Division) {
    this.division = division;
  }

  getPublicDivisionsList(tournamentId): void {
    this.publicTournamentsService
      .getPublicDivisions(tournamentId)
      .subscribe((divisions) => {
        console.log('PASSED HEREEEEEEEEEEEE');

        if (null == this._tournament.organization?.primaryColor) {
          this.primaryColor = '#a90000';
        } else {
          this.primaryColor = this._tournament.organization?.primaryColor;
          console.log('ALSO HEREEEEEEEEEEEE');
        }
        if (null == this._tournament.organization?.secondaryColor) {
          this.secondaryColor = '#000000';
        } else {
          this.secondaryColor = this._tournament.organization?.secondaryColor;
          console.log('ALSO 2 HEREEEEEEEEEEEE');
        }
        this.getFontColor(this.secondaryColor);
        this.divisions = divisions;
      });
  }

  getFontColor(secondary: string) {
    let sRed = secondary.substring(1, 3);
    let sGreen = secondary.substring(3, 5);
    let sBlue = secondary.substring(5, 7);

    let secondaryInt =
      parseInt(sRed, 16) * 0.299 +
      parseInt(sGreen, 16) * 0.587 +
      parseInt(sBlue, 16) * 0.114;

    if (secondaryInt > 150) {
      console.log('DIVISION SECONDARY IS BLACK');
      this.fontColor = '#000000';
    } else {
      console.log('DIVISION SECONDARY IS WHITE');
      this.fontColor = '#ffffff';
    }

    if (this.fontColor == '#ffffff') {
      this.isBlack = false;
    } else {
      this.isBlack = true;
    }
  }

  openDivisionDetail(division: any): void {
    this.router.navigate(
      [`/public-division/${this.tournamentId}/${division.id}`],
      { state: division }
    );
  }

  openTeams(division: any): void {
    this.router.navigate(
      [`/teams-tournament/${this.tournamentId}/${division.id}`],
      { state: division }
    );
  }

  openRegister(division: any): void {
    sessionStorage.setItem('divId', division.id);
    sessionStorage.setItem('tournamentId', this.tournamentId.toString());

    if (this.validURL(this.registration?.website)) {
      window.open(this.registration.website, '_blank');
    } else {
      this.publicTournamentsService.checkIfCoach();
      this.goToRegistration();
    }
  }

  goToRegistration() {
    if (this.registration.requireRegister == true) {
      this.router.navigate([
        `/public-registration/${this.tournamentId}/registration`,
      ]);
    } else {
      console.log('not required');
    }
  }

  async showConfirmCoach(division: Division) {
    // if (this.me?.userType !== 'coach') {
    this.publicTournamentsService.setDivision(division);
    this.publicTournamentsService.setTournamentId(this.tournamentId);
    this.publicTournamentsService.checkIfCoach();
    if (this.publicTournamentsService.isCoach == false) {
      this.ref = this.dialogService.open(RegistrationModalComponent, {
        width: '750px',
        dismissableMask: true,
        closable: false,
        data: { tournamentId: this.tournamentId },
      });
    } else {
      this.goToRegistration();
    }
  }

  validURL(str?) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  checkDate() {
    var d1 = new Date(this.registration?.startDate).getTime();
    var d2 = new Date(this.registration?.endDate).getTime();
    var now = new Date().getTime();
    if (d1 < now && d2 > now) {
      return true;
    } else {
      return false;
    }
  }
}
