import { Component, OnInit, Input } from '@angular/core';
import { AddTeamComponent } from '../add-team/add-team.component';
import { EditTeamComponent } from '@coach/edit-team/edit-team.component';

// PRIMENG
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import {FilterService} from 'primeng/api';

// MODELS
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import { CoachTeams } from '@core/models/coach-teams';

// SERVICES
import {
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
  AuthService,
} from '@core/services';
import { CoachTeamsService } from '@core/services/coach-teams.service';

// ENV
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core'
import { Player } from '@core/models/players';
import { AddPlayerComponent } from '@coach/add-player/add-player.component';

@Component({
  selector: 'app-list-players',
  templateUrl: './list-players.component.html',
  styleUrls: ['./list-players.component.scss']
})
export class ListPlayersComponent implements OnInit {

  user: User;
  selectedPlayers: Player[];
  teamData: any;
  data;
  teams: Teams[];
  players: Player[];
  hasTeam: boolean = false;

  @ViewChild('dt') dt: Table | undefined;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private authService: AuthService,
    private emitterService: EmitterService,
    private toastService: ToastService,
    private httpService: HttpService,
    private auth: AuthService,
    private primengConfig: PrimeNGConfig,
    private filterService: FilterService,
    private ngxLoader: NgxUiLoaderService,
    private coachTeamsService: CoachTeamsService,
    private router: Router
  ) {
    this.emitterService.meEmitter.subscribe((user) => {
      this.user = user;
      this.ngxLoader.stop();
    });
  }

  ngOnInit(): void {
    this.getPlayers();
  }

  async getPlayers(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    this.coachTeamsService.getCoachPlayers(userId).subscribe((players) => {
      this.players = players;
    });
  }

  applyFilterGlobal($event, stringVal) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
  }


  clear(table: Table) {
    table.clear();
}

showAddPlayer(): void {
  const ref = this.dialogService.open(AddPlayerComponent, {
       header: 'Add Player',
       width: '600px',
       closable: false,
       height: 'calc(100vh - 120px)',
        contentStyle: { 'overflow-y': 'scroll' },
       data: {
        isEdit: false,
        hasSelectTeam: true
       }
   });
  ref.onClose.subscribe(() => {
    this.getPlayers();
  })
}

  showEditPlayer(player): void {
    const ref = this.dialogService.open(AddPlayerComponent, {
      header: 'Edit Player',
      width: '600px',
      contentStyle: { 'overflow-y': 'scroll' },
      closable: false,
      data: {
        player,
        isEdit: true,
        hasSelectTeam: true
      },
    });
    ref.onClose.subscribe(() => {
      this.getPlayers();
    });
  }

  ageGradeDisplay(input : string) {
    if(input != null) {
      return input.replace('_', ' ')
    }
  }



  goToPlayers(teamId: any) {
    sessionStorage.setItem('teamId', teamId);
    this.router.navigate(['coach-dashboard/players/' + teamId]);
  }

  goTopage() {
    this.router.navigate(['coach-dashboard/page']);
  }

  onDeletePlayer(): void {
    this.toastService.toast('Please Wait', 'Deleting Player', 'info')
     setTimeout(() => {
      this.selectedPlayers.forEach((i) => {
       
        i.id = i.id;
        this.coachTeamsService.deletePlayer(i.id).toPromise().then(res => {
        }).catch(e => console.log('error ', e))
      });
      this.getPlayers();
      this.toastService.successMsg({message: 'Player Deleted'});
  
     },1000)
  
  }

}
