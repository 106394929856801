<!-- <div
  class="grid-main"
  [ngStyle]="{
    'background-image': 'url(' + orgInfo?.logoUrl + ')',
    'background-repeat': ' no-repeat',
    'background-size': '80vh 80vh ',
    'background-position': 'center'
  }"
> -->
<div class="grid-main">
  <div class="grid-layout">
    <ng-container
      *ngFor="
        let tournament of tournaments
          | paginate : { itemsPerPage: 24, currentPage: page, totalItems: 24 }
      "
    >
      <p-card
        [style]="{
          cursor: 'pointer',
          width: '18em',
          margin: '2em'
        }"
      >
        <ng-template pTemplate="header">
          <img
            *ngIf="!tournament?.photo; else hasPhoto"
            class="card-image"
            alt="Card"
            src="../../../../assets/img/tk-placeholder.png"
            (click)="goToPublicTournament(tournament)"
          />
          <ng-template #hasPhoto>
            <img
              class="card-image"
              alt="Card"
              [src]="getPhoto(tournament)"
              (click)="goToPublicTournament(tournament)"
            />
          </ng-template>
        </ng-template>
        <div class="card-content">
          <div
            class="card-content-upper"
            (click)="goToPublicTournament(tournament)"
          >
            <div class="card-date">
              <div class="date-day">
                <label>{{ tournament?.startDate | date : "dd" }}</label>
              </div>
              <div class="date-month">
                <label>{{ tournament?.startDate | date : "MMM" }}</label>
              </div>
            </div>
            <div class="divider">.</div>
            <div class="card-date">
              <div class="date-day">
                <label>{{ tournament?.endDate | date : "dd" }}</label>
              </div>
              <div class="date-month">
                <label>{{ tournament?.endDate | date : "MMM" }}</label>
              </div>
            </div>
            <div
              class="card-subtitle"
              (click)="
                goToBrowseDirectorTournaments(tournament.organization.id)
              "
            >
              <i>
                <span class="subtitle"
                  >{{ tournament?.organization?.name }}
                </span>
              </i>
              <i> presents</i>
            </div>
          </div>
          <div class="card-content-lower">
            <div
              class="card-tournament-name"
              (click)="goToPublicTournament(tournament)"
            >
              <label>{{ tournament?.name }}</label>
            </div>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <div
            [style.backgroundColor]="
              orgInfo?.primaryColor ? orgInfo.primaryColor : '#ffffff'
            "
            [style.color]="orgInfo?.fontColor ? orgInfo.fontColor : '#000000'"
            class="container-footer card-gender-fee"
            (click)="goToPublicTournament(tournament)"
          >
            <label>{{ getGender(tournament?.gender) }}</label>
            <label>Fee: ${{ tournament?.entryFee }}</label>
          </div>
          <div
            class="container-footer"
            [style.backgroundColor]="
              orgInfo?.primaryColor ? orgInfo.primaryColor : '#ffffff'
            "
            [style.color]="orgInfo?.fontColor ? orgInfo.fontColor : '#000000'"
            (click)="goToPublicTournament(tournament)"
          >
            <fa-icon class="faicon" [icon]="faMarker"></fa-icon>
            <label>{{ tournament?.city }}</label
            ><label *ngIf="tournament?.state">, {{ tournament?.state }}</label>
          </div>
        </ng-template>
      </p-card>
    </ng-container>
  </div>
</div>
