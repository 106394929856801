<div class="p-grid">

  <div class="p-col-12">
    <label class="text-bold1 p-0 ml-4 mb-2">Where To Add</label>
    <div class="full-dropdown mt-2">
      <p-dropdown
        [options]="whereOptions"
        placeholder="Where to add timeslot"
        optionLabel="name"
        [(ngModel)]="whereToAdd"
      ></p-dropdown>
    </div>
  </div>

  <div class="p-col-12 mb-2">
    <label class="text-bold1 p-0 ml-4 mb-2">Number of Blocks</label>
    <div class="full-dropdown mt-2">
      <p-dropdown
        [options]="blockOptions"
        placeholder="Number of blocks"
        optionLabel="name"
        [(ngModel)]="blocks"
      ></p-dropdown>
    </div>
  </div>

  <div class="p-col-12 flex flex-center">
    <button
      pButton
      iconPos="right"
      type="button"
      label="Add Time"
      class="
        p-button-raised p-button-rounded p-button-danger p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="onSubmit()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="Cancel"
      class="
        p-button-raised p-button-rounded p-button-text p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="cancel()"
    ></button>
  </div>
</div>
