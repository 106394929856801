import { Injectable, ViewChild } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Division } from '@core/models/division';
import { Teams } from '@core/models/teams';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from '.';
import { Games } from '@core/models/games';

@Injectable({
  providedIn: 'root',
})
export class PublicTournamentsService {
  teams$ = new BehaviorSubject<Teams[]>([]);
  teams: Teams;
  isCoach: boolean = false;
  viewableSize = 24;
  division$ = new BehaviorSubject<Division>(null);
  divisions$ = new BehaviorSubject<Division[]>(null);
  assignedGames$ = new BehaviorSubject<Games[]>(null);
  tournamentId: string = '';
  division: Division;
  isRegister: boolean = false;
  isReview: boolean = false;
  @ViewChild('opLogin', { static: false }) opLogin;

  constructor(private http: HttpService, private toastService: ToastService) {}

  getPublicFeaturedTournaments(): any {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.publicFeaturedTournaments}`,
          payload: [],
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }
  
  getPublicBracketSet(id: any): any {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.baseUrl}/bracket-set?id=${id}`,
          payload: [],
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getRegistration(id: string | number): any {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.tournamentRegistration}?tournamentId=${id}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  setReviewToTrue() {
    this.isReview = true;
  }

  setReviewToFalse() {
    this.isReview = false;
  }

  setRegisterToTrue() {
    this.isRegister = true;
  }

  setRegisterToFalse() {
    this.isRegister = false;
  }

  setTournamentId(id) {
    this.tournamentId = id;
  }

  setDivision(division: Division) {
    this.division = division;
  }

  initPublicBrowseTournaments(
    name = '',
    city = '',
    state = '',
    startDate = '',
    gender = '',
    action = 'init',
    isBrowseTournaments = true
  ): any {
    const url =
      `${environment.api.tournaments}` +
      `?action=${action}` +
      `&index=1` +
      `&viewableSize=${this.viewableSize}` +
      `&q=${name}` +
      `&gender=${gender}` +
      `&city=${city}` +
      `&state=${state}` +
      `&startDate=${startDate}` +
      `&isBrowseTournaments=${isBrowseTournaments}`;

    return new Promise((resolve, reject) => {
      this.http
        .get({ url: url, payload: [] })
        .toPromise()
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  }

  getPublicBrowseTournaments(
    action: string,
    meta: any,
    search = '',
    city = '',
    state = '',
    startDate = '',
    gender = ''
  ): any {
    this.viewableSize = 24;

    const url =
      `${environment.api.tournaments}` +
      `?action=${action}` +
      `&index=${meta.index}` +
      `&viewableSize=${this.viewableSize}` +
      `&totalSize=${meta.totalSize}` +
      `&q=${search}` +
      `&gender=${gender}` +
      `&city=${city}` +
      `&state=${state}` +
      `&startDate=${startDate}`;

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: url,
          payload: [],
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getPublicDivisions(tournamentId: any): Observable<Division[]> {
    return this.http.get({
      url: `${environment.api.divisions}?tournamentId=${tournamentId}`,
      payload: [],
    });
  }

  async getDivisions(tournamentId) {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.divisions}?tournamentId=${tournamentId}`,
          payload: [],
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
          this.divisions$.next(res);
        });
    }).catch((err) => {
      this.toastService.toast(
        'Failed',
        `Something went wrong when getting the divisions for this public tournament (id: ${tournamentId})`,
        'error'
      );
    });
  }

  async getDivision(divisionId) {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.divisions}/${divisionId}`,
          payload: [],
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
          this.division$.next(res);
        });
    }).catch((err) => {
      this.toastService.toast('Failed', err.error, 'error');
    });
  }

  getAssignedGames(id: number, date, division = '', pool = '') {
    return this.http
      .get({
        url: `${environment.api.games}/assigned?tournamentId=${id}&date=${date}&divisionId=${division}&poolId=${pool}`,
        payload: [],
      })
      .toPromise()
      .then((res: any) => {
        this.assignedGames$.next(res);
      })
      .catch((err) => {
        this.toastService.toast('Failed', 'Something went wrong', 'error');
      });
  }

  addCoachTeam(data) {
    const currentValue = this.teams$?.value;
    const updatedValue = [...currentValue, data];
    this.teams$.next(updatedValue);
  }

  initialTeam() {
    this.teams$.next([]);
  }

  getVenues(tournamentId, organizationId) {
    return this.http.get({
      url: `${environment.api.venues}?tournamentId=${tournamentId}&organizationId=${organizationId}`,
    });
  }

  async checkIfCoach() {
    const me = await this.http.me().toPromise();
    if (me?.userType == 'coach') {
      this.isCoach = true;
    } else {
      this.isCoach = false;
    }
  }
}
