import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(
    private http: HttpService,
  ) {
  }

  getPrimaryUser(organizationId: any) {
    return this.http
    .get({
      url: `${environment.api.primaryOrgUser}?organizationId=${organizationId}`,
        payload: [],
    });
  }
}
