import { Component, OnInit } from '@angular/core';
import { Tournament } from '@core/models/tournament';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faTable } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-public-featured-tournaments',
  templateUrl: './public-featured-tournaments.component.html',
  styleUrls: ['./public-featured-tournaments.component.scss']
})
export class PublicFeaturedTournamentsComponent implements OnInit {
  tournaments: Tournament[];
  faList = faList;
  faGrid = faTable;
  isGrid = true;
  constructor(private publicTournament: PublicTournamentsService) { 
    
    this.getFeaturedTournament();

  }

  ngOnInit(): void {
  }

  getFeaturedTournament(): void{
    this.publicTournament.getPublicFeaturedTournaments().then(res => {
      this.tournaments = res.items;

    }).catch(e => console.log('featured', e));
  }
  viewList(): void{
    
    this.isGrid = false;
  }
  viewGrid(): void{

    this.isGrid = true;
  }
}
