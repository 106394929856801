import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BracketComponent } from '@components/admin/bracket-builder/bracket/bracket.component';
import { BracketSet } from '@core/models/bracket-set';
import { Bracket } from '@core/models/brackets';
import { Division } from '@core/models/division';
import { ToastService } from '@core/services';
import { BracketService } from '@core/services/bracket-service';
import { DialogService } from 'primeng/dynamicdialog';
import * as objectScan from 'object-scan';
import { BracketPreviewComponent } from '@components/admin/bracket-builder/bracket-preview/bracket-preview.component';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';

@Component({
  selector: 'app-public-bracket-stage',
  templateUrl: './public-bracket-stage.component.html',
  styleUrls: ['./public-bracket-stage.component.scss']
})
export class PublicBracketStageComponent implements OnInit, AfterViewInit {
  @Input('stageWidth') public stageWidth: string;
  @Input('stageHeight') public stageHeight: string;
  @Input('bracketSet') public bracketSet: BracketSet;
  @Input('divisionInfo') public divisionInfo: Division;
  @Input('isPreview') public isPreview: boolean;
  @ViewChild('scrollContainer') private scrollContainer: ElementRef<HTMLDivElement>;
  preview = false;
  private isMouseDown = false;
  private startX = 0;
  private startY = 0;
  private scrollLeft = 0;
  private scrollTop = 0;
  private draggingChild = false;
  venues = [];
  dynamicComponent: any[] = [];
  topBrackets: Bracket[];
  bottomBrackets: Bracket[];
  zoomScale = 1;
  bracketCoordinates: any[] = [];
  bracketDimension: any[] = [];
  setBrackets: any[] = [];
  isOpenBracket = false;
  isHasSet = true;
  isChangeName = false;
  panZoom: PanzoomObject;
  bracketTypes = [
    { name: 'Right Facing', value: 'RIGHT_FACING' },
    { name: 'Left Facing', value: 'LEFT_FACING' },
    { name: 'Middle', value: 'MIDDLE' },
    { name: 'Bracket Winner', value: 'BRACKET_WINNER' },
    { name: 'Box', value: 'BOX' },
  ];
  @ViewChild('bracket', { read: ViewContainerRef }) target: ViewContainerRef;
  @ViewChild('stage', { static: false }) stage: ElementRef;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private bracketService: BracketService,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) { }
  ngAfterViewInit(): void {
  }

  ngOnInit(
  ): void {
    this.preview = this.isPreview
    this.initializeBracket();

  }
 
  initializeBracket(){
    for(let h = 0; h < this.bracketSet.brackets.length; h++){
      let bracket = this.bracketSet.brackets[h]
      this.bottomBrackets = this.searchBottomBracket(bracket);
      this.topBrackets = this.searchTopBracket(bracket);
      this.createBracket(bracket)
      for (var i = 0, j = 0; i < this.bottomBrackets.length || j < this.topBrackets.length;) {
        if (i < this.bottomBrackets.length) {
          if (this.bottomBrackets[i] != null) {
            this.createBracket(this.bottomBrackets[i])
          }
          i++;
        }
  
        if (j < this.topBrackets.length) {
          if (this.topBrackets[j] != null) {
            this.createBracket(this.topBrackets[j])
          }
          j++;
        }
      }
    }
  }
  onMouseDown(event: MouseEvent) {
      this.isMouseDown = true;
      this.startX = event.pageX - this.scrollContainer.nativeElement.offsetLeft;
      this.startY = event.pageY - this.scrollContainer.nativeElement.offsetTop;
      this.scrollLeft = this.scrollContainer.nativeElement.scrollLeft;
      this.scrollTop = this.scrollContainer.nativeElement.scrollTop;
      this.scrollContainer.nativeElement.style.cursor = 'grab';
    event.preventDefault();

  }
  onWheel(event: WheelEvent) {
    event.preventDefault(); 
  }
  onMouseUp() {
    this.isMouseDown = false;
    this.scrollContainer.nativeElement.style.cursor = 'default';
  }

  async createBracket( bracket: Bracket){
    console.log("creating bracket ", bracket)
    const childComponent = this.componentFactoryResolver.resolveComponentFactory(BracketPreviewComponent);
    let bracketComponent: ComponentRef<any>;
    const mainOptions = await this.bracketService.getBracketsSources(this.divisionInfo.id,this.bracketSet.id);
    bracketComponent = this.target.createComponent(childComponent);
    bracketComponent.instance.bracket = bracket;
    bracketComponent.instance.isPreview = true;
    bracketComponent.instance.bracketSet = this.bracketSet;
    bracketComponent.instance.mainOptions = mainOptions;
    this.dynamicComponent.push(bracketComponent.instance)
  }
  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
      const x = event.pageX - this.scrollContainer.nativeElement.offsetLeft;
      const y = event.pageY - this.scrollContainer.nativeElement.offsetTop;
      const walkX = (x - this.startX);
      const walkY = (y - this.startY);
      this.scrollContainer.nativeElement.scrollLeft = this.scrollLeft - walkX;
      this.scrollContainer.nativeElement.scrollTop = this.scrollTop - walkY;

  }
  zoomIn() {
    this.zoomScale += 0.1;
  }

  zoomOut() {
    this.zoomScale -= 0.1;
  }
  searchTopBracket(obj:any) {
    let temp = [];
    let arr = objectScan(['**.topBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }

  searchBottomBracket(obj:any) {
    let temp = [];

    let arr = objectScan(['**.bottomBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }
  openBracket(){
    // console.log("idd", this.bracketSet.id)
    window.open(`/bracket-preview/${this.bracketSet.id}`, "_blank");
  }
}
