<span
  class="pi pi-info-circle"
  style="padding-left: 5px"
  pTooltip="{{ shortDescription }} {{ '\n' }} {{ '\n' }}  (Click to learn more)"
  (click)="
    showDialog(
      header,
      content,
      shortDescription,
      videoId,
      note,
      example,
      typesOfTie,
      examplep1,
      examplep2,
      examplep3,
      examplep4,
      examplep5
    )
  "
>
</span>

<p-dialog
  [(visible)]="display"
  [dismissableMask]="true"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  position="center"
  styleClass="custom-modal"
>
  <p-header class="header TheBoldFont-Bold fs-4">{{ dialogHeader }}</p-header>
  <div class="content">
    <p>{{ dialogContent }}</p>

    <ng-container *ngIf="dialogTypes">
      <div [innerHTML]="dialogTypes" style="padding-top: 20px"></div>
    </ng-container>
    <ng-container *ngIf="dialogExamplep1">
      <p>{{ dialogExamplep1 }}</p>
    </ng-container>
    <ng-container *ngIf="dialogExamplep2">
      <p>{{ dialogExamplep2 }}</p>
    </ng-container>
    <ng-container *ngIf="dialogExamplep3">
      <p>{{ dialogExamplep3 }}</p>
    </ng-container>
    <ng-container *ngIf="dialogExamplep4">
      <p>{{ dialogExamplep4 }}</p>
    </ng-container>
    <ng-container *ngIf="dialogExamplep5">
      <p style="padding-bottom: 20px">{{ dialogExamplep5 }}</p>
    </ng-container>
    <ng-container *ngIf="dialogExample">
      <div
        [innerHTML]="dialogExample"
        style="padding-bottom: 30px; padding-top: 20px"
      ></div>
    </ng-container>
    <ng-container *ngIf="dialogNote">
      <div [innerHTML]="dialogNote"></div>
    </ng-container>
    <ng-container *ngIf="videoId">
      <youtube-player
        class="youtube-player"
        [videoId]="videoId"
      ></youtube-player>
    </ng-container>
  </div>
</p-dialog>
