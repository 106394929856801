export enum PoolTiebreakerEnum {
  RECORD = 'RECORD',
  POINTS = 'POINTS',
  WINNING_PERCENTAGE = 'WINNING_PERCENTAGE',
  HEAD_TO_HEAD = 'HEAD_TO_HEAD',
  HEAD_TO_HEAD_TWO_TEAMS_ONLY = 'HEAD_TO_HEAD_TWO_TEAMS',
  HEAD_TO_HEAD_PT_DIFF = 'HEAD_TO_HEAD_PTS_DIFF',
  PT_DIFF = 'PTS_DIFF',
  PTS_SCORED = 'PTS_SCORED',
  PTS_ALLOWED = 'PTS_ALLOWED',
  MAX_POINT_DIFFERENTIAL = 'MAX_POINT_DIFFERENTIAL',
}

export interface PoolTiebreakerType {
  id?: number;
  poolTieBreakerType: string;
  seqNo?: number;
  label: string;
}

export const POOL_TIEBREAKER_TYPE: PoolTiebreakerType[] = [
  {
    poolTieBreakerType: PoolTiebreakerEnum.RECORD,
    label: PoolTiebreakerEnum.RECORD,
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.POINTS,
    label: PoolTiebreakerEnum.POINTS,
  },
];

export const POOL_TIEBREAKER_OPTIONS: PoolTiebreakerType[] = [
  {
    poolTieBreakerType: PoolTiebreakerEnum.WINNING_PERCENTAGE,
    label: 'WINNING PERCENTAGE',
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.HEAD_TO_HEAD,
    label: 'HEAD TO HEAD',
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.HEAD_TO_HEAD_TWO_TEAMS_ONLY,
    label: 'HEAD TO HEAD TWO TEAMS ONLY',
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.HEAD_TO_HEAD_PT_DIFF,
    label: 'HEAD TO HEAD POINT DIFFERENTIAL',
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.PT_DIFF,
    label: 'POINT DIFFERENTIAL',
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.PTS_SCORED,
    label: 'POINTS SCORED',
  },
  {
    poolTieBreakerType: PoolTiebreakerEnum.PTS_ALLOWED,
    label: 'POINTS ALLOWED',
  },
  // {
  //   poolTieBreakerType: PoolTiebreakerEnum.MAX_POINT_DIFFERENTIAL,
  //   label: 'MAX POINT DIFFERENTIAL',
  // },
];
