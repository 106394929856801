import { Injectable } from '@angular/core';
import { AssistantCoach } from '@core/models/assistant-coach';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CoachCoachService {
  constructor(private http: HttpService) {}

  /**
   * Get the array of assistant coaches given the head coach id.
   * @param coachId
   * @returns
   */
  getTeamAsstCoaches(teamId: string | number): Observable<any> {
    return this.http.get({
      url: `${environment.api.coachAssistants}/team/${teamId}`,
    });
  }

  /**
   * Get the array of assistant coaches given the head coach id.
   * @param coachId
   * @returns
   */
  getCoachAsstCoaches(coachId: string | number): Observable<any> {
    return this.http.get({
      url: `${environment.api.coachAssistants}?coachId=${coachId}`,
    });
  }

  /**
   * Saves a new assistant coach
   * @param payload
   * @returns
   */
  addAssistantCoach(coachId: string | number, payload: AssistantCoach) {
    return this.http.post({
      url: `${environment.api.coachAssistants}?coachId=${coachId}`,
      payload: payload,
    });
  }

  deleteAssistantCoach(coachId: string | number) {
    console.log('pumasok???');
    return this.http.delete({
      url: `${environment.api.coachAssistants}?coachId=${coachId}`,
    });
  }

  /**
   * Saves new team coach
   * @param teamId
   * @returns
   */
  addTeamCoach(teamId: string | number, payload: AssistantCoach) {
    return this.http.post({
      url: `${environment.api.teamCoach}?teamId=${teamId}`,
      payload: payload,
    });
  }

  /**
   * Saves new team coach batch
   * @param teamId
   * @returns
   */
  addTeamCoachBatch(teamId: string | number, payload: AssistantCoach[]) {
    return this.http.post({
      url: `${environment.api.teamCoachBatch}?teamId=${teamId}`,
      payload: payload,
    });
  }

  /**
   * Edit team coach
   * @param teamId
   * @returns
   */
  editTeamCoach(teamId: string | number, payload: AssistantCoach) {
    return this.http.post({
      url: `${environment.api.assistantTeamCoach}?teamId=${teamId}`,
      payload: payload,
    });
  }

  /**
   * Deletes team coach
   * @param coachId
   * @returns
   */
  deleteCoach(coachId: string | number) {
    return this.http.delete({
      url: `${environment.api.assistantCoach}?coachId=${coachId}`,
    });
  }
}
