<div class="px-4 container-admin">
  <div class="flex flex-center mr-4">
    <div class="flex-grow1">
      <!-- <span
        class="hamburger-icon pi pi-align-justify mr-4 fs-3 pointer p-2 disp-none"
      ></span> -->
      <div class="a-header-org">
        <span class="TheBoldFont-Bold fs-2 text-black4">{{ pageName }}</span>
      </div>
    </div>
    <div class="flex">
      <section class="flex flex-center">
        <div class="flex flex-center" #here>
          <button
            (click)="goBrowseTournaments()"
            pButton
            type="button"
            label="Browse Tournaments"
            class="p-button-rounded p-button-danger goHome"
          ></button>
        </div>
      </section>
    </div>
    <div class="container-ball" style="display: flex; align-items: center">
      <img
        src="assets/img/ball-avatar.png"
        width="50"
        class="ball-avatar br-circle"
        (click)="userDetails.toggle($event)"
      />
      <i
        class="pi pi-chevron-circle-down"
        style="position: absolute; bottom: 0; right: 0"
        (click)="userDetails.toggle($event)"
      ></i>
    </div>
  </div>
</div>

<p-overlayPanel #userDetails appendTo="body" styleClass="panel-content">
  <ng-template pTemplate>
    <div class="flex flex-row profile flex-center">
      <div>
        <img
          src="assets/img/ball-avatar.png"
          width="50"
          class="ball-avatar br-circle"
        />
        <section
          class="fs-xsmall disp-block text-center text-white"
          style="height: 20px"
        >
          {{ currentVersion }}
        </section>
      </div>

      <div class="header-name">
        <label class="name">{{ user?.fullName | uppercase }} </label>
        <label class="text-bold3">{{ user?.userType | uppercase }} </label>
        <label>{{ user?.principal }} </label>
      </div>
    </div>

    <div class="container-actions">
      <div class="container-changepw">
        <h4 (click)="changePassword()" class="text-changepw">
          <i class="pi pi-angle-double-right icon-red"> </i>&nbsp; Change
          Password
        </h4>
      </div>
    </div>
    <div class="footer flex flex-center">
      <button class="btn-rounded-red" (click)="logout()">Log out</button>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog
  header="Reset Password"
  [(visible)]="isChangePassowrd"
  [modal]="true"
  [dismissableMask]="true"
  appendTo="body"
>
  <app-change-password></app-change-password>
</p-dialog>
