import { Component, OnInit } from '@angular/core';
import { HttpService, ToastService } from '@core/services';
import { DivisionService } from '@core/services/division.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-pools',
  templateUrl: './pools.component.html',
  styleUrls: ['./pools.component.scss'],
})
export class PoolsComponent implements OnInit {
  constructor(
    private httpService: HttpService,
    private toastService: ToastService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private divisionService: DivisionService
  ) {}

  cancel() {
    this.ref.close(false);
  }

  ngOnInit(): void {}

  onSubmit(): void {

    const data = this.config.data;

    data?.payload.map((res) => {
      this.divisionService.deletePools(res.id);
    });

    this.ref.close(true);
  }
}
