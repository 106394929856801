import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Games } from '@core/models/games';

import { PoolService } from '@core/services/pool.service';
import { TeamsService } from '@core/services/teams.service';
import { ToastService } from '@core/services';

@Component({
  selector: 'add-game-modal',
  templateUrl: './add-game-modal.component.html',
  styleUrls: ['./add-game-modal.component.scss'],
})

export class AddGameModalComponent implements OnInit {

  settings:any = {
    minRest: 0,
    maxRest: 0,
    maxGames: 0,
    travelTime: 0
  }

  game:Games = {
    name: null,
    pool: null,
    team1: null,
    team2: null
  }

  pools: any;
  teams: any;
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private poolService: PoolService,
    private teamsService: TeamsService,
    private toastService: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {

    let pools = await this.poolService.getTournamentPools(this.config.data.tournamentId);
    pools.forEach((pool:any) => {
      pool.fullName = `${pool.division.name} - ${pool.name}`;
    });
    this.pools = pools;
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  async onPoolChange() {
    this.game.division = this.game.pool.division;
    this.teams = await this.teamsService.getPoolTeams(this.game.pool.id);
  }

  cancel() {
    this.ref.close();
  }

  onSubmit() {
    
    if (!this.game.name) {
      this.toastService.errorMsg({ message: 'Name cannot be empty.' });
      return;
    }

    if (!this.game.pool) {
      this.toastService.errorMsg({ message: 'Pool cannot be empty.' });
      return;
    }

    if (!this.game.team1) {
      this.toastService.errorMsg({ message: 'Team 1 cannot be empty.' });
      return;
    }

    if (!this.game.team2) {
      this.toastService.errorMsg({ message: 'Team 2 cannot be empty.' });
      return;
    }

    if (this.game.team1.id == this.game.team2.id) {
      this.toastService.errorMsg({ message: 'You cannot select same teams.' });
      return;
    }

    this.ref.close(this.game);
  }
}
