import {
  AfterContentInit,
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  QueryList,
  ViewChildren
} from '@angular/core';

@Directive({
  selector: '[tourneykeyRequiredInput]'
})
export class RequiredInputDirective implements AfterViewInit, AfterContentInit{

  @ContentChildren(RequiredInputDirective, { descendants: true }) inputs: QueryList<any>

  // need this to be public so associated directives can access it
  constructor(public element: ElementRef) {
    // console.log(element);
  }

  ngAfterViewInit() {
    if(this.inputs.length) {
      //console.log(this.inputs);
    }
  }

  ngAfterContentInit(): void {
    if(this.inputs.length) {
      // console.log(this.inputs);
    }
  }

}
