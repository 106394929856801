<div class="container">
    <div class="row">
      <div class="rightBtns">
        <button
          pButton
          type="button"
          label="ADD COACH"
          class="p-button-rounded p-button-danger addBtn"
          (click)="showAddCoach()"
        ></button>
        <button
          [disabled]="
            this.selectedCoaches == null || this.selectedCoaches.length == 0
              ? true
              : false
          "
          (click)="onDeleteCoach()"
          pButton
          type="button"
          label="DELETE COACH"
          class="p-button-rounded p-button-danger deleteBtn"
        ></button>
      </div>
    </div>
    <div class="row">
      <p-table #dt
        [value]="coaches"
        selectionMode="multiple"
        [(selection)]="selectedCoaches"
        [globalFilterFields]="['firstName','lastName','role']"
        dataKey="id"
      >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <button pButton label="Clear" class="p-button-rounded p-button-danger" icon="pi pi-filter-slash" (click)="clear(dt)"></button>
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th colspan="2">
              <div>
                FIRST NAME
            </div>
            </th>
            <th colspan="2">
              <div>
                LAST NAME
              </div>
            </th>
            <th colspan="2"></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-coach>
          <tr>
            <td>
              <p-tableCheckbox [value]="coach"></p-tableCheckbox>
            </td>
            <td colspan="2">{{ coach?.firstName }}</td>
            
            <td colspan="2">{{ coach?.lastName}}</td>
            <td></td>
            <td>
              <!-- <button (click)="showEditTeam()" pButton type="button" label="EDIT TEAM" class="p-button-rounded p-button-danger addBtn"></button> -->
              <i
                class="pi pi-pencil icon-cursor"
                style="cursor: pointer;"
                pTooltip="Edit Coach"
                (click)="showEditCoach(coach)"
              ></i>
              &nbsp;&nbsp;

            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="7">No Coaches found.</td>
          </tr>
      </ng-template>
      </p-table>
    </div>
    <!-- <div>
      <p (click)="goTopage()">select</p>
    </div> -->
  </div>
  