<div class="teams">
    <div class="p-col-12 text-bold1">
      Please select a coach from the list to add to the registered team.
    </div>
  
    <app-public-table
      [thead]="headers"
      [data]="coaches"
      [config]="{ actionButtons: ['selectTeam'] }"
      (onActionButtonClick)="selectCoach($event)"
      [isFetching]="fetching"
      [hasResult]="true"
    >
    </app-public-table>
  
    <div class="p-col-12 flex flex-center mt-4">
      <button
        pButton
        type="button"
        label="Cancel"
        class="
          p-button-raised p-button-rounded p-button-text p-button-plain
          mx-2
          px-5
          py-3
        "
        (click)="close()"
      ></button>
    </div>
  </div>
