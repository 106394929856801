<div class="game-scores">
  <div class="title">
    <div class="game-name">{{ gameDate | date : "h:mm a EEE MMM d" }}</div>

    <div class="game-name">{{ divisionName }} - {{ gameName }}</div>
    <div class="close" (click)="close()">X</div>
  </div>
  <p-tabView styleClass="tabview-custom" [(activeIndex)]="activeIndex">
    <p-tabPanel header="Header II">
      <ng-template pTemplate="header">
        <span>Scoresheet</span>
      </ng-template>
      <!-- SCORESHEET LIST VIEW -->
      <div class="scoresheet" *ngIf="innerWidth > 630">
        <div class="away-team">
          <div class="team">AWAY</div>
          <div class="team bold">{{ rawData?.currentGame.away.name }}</div>
          <div class="period-stats">
            <div class="header-container">
              <div
                class="period"
                [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
              >
                1st
              </div>
              <div
                class="period"
                [class.half]="is2Period"
                *ngIf="is2Period || is4Period"
              >
                2nd
              </div>
              <div class="period" *ngIf="is4Period">3rd</div>
              <div class="period" *ngIf="is4Period">4th</div>
              <div
                class="period"
                [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
              >
                OT
              </div>
              <div
                class="period"
                [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
              >
                Total
              </div>
            </div>
            <div class="points-container">
              <div
                class="period"
                [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
              >
                {{ showProperDisplay(awayTeam1stPeriod) }}
              </div>
              <div
                class="period"
                [class.half]="is2Period"
                *ngIf="is2Period || is4Period"
              >
                {{ showProperDisplay(awayTeam2ndPeriod) }}
              </div>
              <div class="period" *ngIf="is4Period">
                {{ showProperDisplay(awayTeam3rdPeriod) }}
              </div>
              <div class="period" *ngIf="is4Period">
                {{ showProperDisplay(awayTeam4thPeriod) }}
              </div>
              <div
                class="period"
                [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
              >
                {{ showProperDisplay(awayTeamOT) }}
              </div>
              <div
                class="period"
                [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
              >
                {{
                  showProperDisplay(
                    awayTeam1stPeriod +
                      awayTeam2ndPeriod +
                      awayTeam3rdPeriod +
                      awayTeam4thPeriod +
                      awayTeamOT
                  )
                }}
              </div>
            </div>
          </div>
          <div class="header">
            <div class="number">No.</div>
            <div
              class="name"
              [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
            >
              Player Name
            </div>
            <div class="half" [class.full]="is1Period">
              <div class="top" *ngIf="is4Period">1st Half</div>
              <div class="bottom">
                <h4>1st</h4>
                <h4 *ngIf="is2Period || is4Period">2nd</h4>
              </div>
            </div>
            <div class="half" *ngIf="is4Period">
              <div class="top">2nd Half</div>
              <div class="bottom">
                <h4>3rd</h4>
                <h4>4th</h4>
              </div>
            </div>
            <div class="overtime">OT</div>
            <div
              class="total"
              [class]="is1Period ? 'full' : is2Period ? 'half' : ''"
            >
              <div class="top">Total</div>
              <div class="bottom">
                <h3>Fouls</h3>
                <h4>Points</h4>
              </div>
            </div>
          </div>
          <div class="body">
            <div
              class="player"
              *ngFor="
                let player of sortPlayerByJerseyNumber(rawData?.awayPlayers)
              "
            >
              <div class="number">{{ player.jerseyNumber }}</div>
              <div class="name">
                {{ player.firstName }} {{ player.lastName }}
              </div>
              <div class="half">
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[0]?.pt2 * 2 +
                        player.periodStats[0]?.pt3 * 3 +
                        player.periodStats[0]?.ft
                    )
                  }}
                </h4>
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[1]?.pt2 * 2 +
                        player.periodStats[1]?.pt3 * 3 +
                        player.periodStats[1]?.ft
                    )
                  }}
                </h4>
              </div>
              <div class="half">
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[2]?.pt2 * 2 +
                        player.periodStats[2]?.pt3 * 3 +
                        player.periodStats[2]?.ft
                    )
                  }}
                </h4>
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[3]?.pt2 * 2 +
                        player.periodStats[3]?.pt3 * 3 +
                        player.periodStats[3]?.ft
                    )
                  }}
                </h4>
              </div>
              <div class="overtime">
                {{ showProperDisplay(getOvertimePoints(player.periodStats)) }}
              </div>
              <div class="total">
                <h3>
                  {{
                    showProperDisplay(
                      getCountTotalsPerPlayer(player.periodStats, "rf", "tf")
                    )
                  }}
                </h3>
                <h4>
                  {{
                    showProperDisplay(
                      getCountTotalsPerPlayer(
                        player.periodStats,
                        "pt2",
                        "pt3",
                        "ft"
                      )
                    )
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="home-team">
          <div class="team">HOME</div>
          <div class="team bold">{{ rawData?.currentGame.home.name }}</div>
          <div class="period-stats">
            <div class="header-container">
              <div class="period">1st</div>
              <div class="period">2nd</div>
              <div class="period">3rd</div>
              <div class="period">4th</div>
              <div class="period">OT</div>
              <div class="period">Total</div>
            </div>
            <div class="points-container">
              <div class="period">
                {{ showProperDisplay(homeTeam1stPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam2ndPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam3rdPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam4thPeriod) }}
              </div>
              <div class="period">{{ showProperDisplay(homeTeamOT) }}</div>
              <div class="period">
                {{
                  showProperDisplay(
                    homeTeam1stPeriod +
                      homeTeam2ndPeriod +
                      homeTeam3rdPeriod +
                      homeTeam4thPeriod +
                      homeTeamOT
                  )
                }}
              </div>
            </div>
          </div>
          <div class="header">
            <div class="number">No.</div>
            <div class="name">Player Name</div>
            <div class="half">
              <div class="top">1st Half</div>
              <div class="bottom">
                <h4>1st</h4>
                <h4>2nd</h4>
              </div>
            </div>
            <div class="half">
              <div class="top">2nd Half</div>
              <div class="bottom">
                <h4>3rd</h4>
                <h4>4th</h4>
              </div>
            </div>
            <div class="overtime">OT</div>
            <div class="total">
              <div class="top">Total</div>
              <div class="bottom">
                <h3>Fouls</h3>
                <h4>Points</h4>
              </div>
            </div>
          </div>
          <div class="body">
            <div
              class="player"
              *ngFor="
                let player of sortPlayerByJerseyNumber(rawData?.homePlayers)
              "
            >
              <div class="number">{{ player.jerseyNumber }}</div>
              <div class="name">
                {{ player.firstName }} {{ player.lastName }}
              </div>
              <div class="half">
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[0]?.pt2 * 2 +
                        player.periodStats[0]?.pt3 * 3 +
                        player.periodStats[0]?.ft
                    )
                  }}
                </h4>
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[1]?.pt2 * 2 +
                        player.periodStats[1]?.pt3 * 3 +
                        player.periodStats[1]?.ft
                    )
                  }}
                </h4>
              </div>
              <div class="half">
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[2]?.pt2 * 2 +
                        player.periodStats[2]?.pt3 * 3 +
                        player.periodStats[2]?.ft
                    )
                  }}
                </h4>
                <h4>
                  {{
                    showProperDisplay(
                      player.periodStats[3]?.pt2 * 2 +
                        player.periodStats[3]?.pt3 * 3 +
                        player.periodStats[3]?.ft
                    )
                  }}
                </h4>
              </div>
              <div class="overtime">
                {{ showProperDisplay(getOvertimePoints(player.periodStats)) }}
              </div>
              <div class="total">
                <h3>
                  {{
                    showProperDisplay(
                      getCountTotalsPerPlayer(player.periodStats, "rf", "tf")
                    )
                  }}
                </h3>
                <h4>
                  {{
                    showProperDisplay(
                      getCountTotalsPerPlayer(
                        player.periodStats,
                        "pt2",
                        "pt3",
                        "ft"
                      )
                    )
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SCORESHEET GRID VIEW -->
      <div class="scoresheet-grid" *ngIf="innerWidth <= 630">
        <div class="away-team">
          <div class="team">AWAY</div>
          <div class="team bold">{{ rawData?.currentGame.away.name }}</div>
          <div class="period-stats">
            <div class="header-container">
              <div class="period">1st</div>
              <div class="period">2nd</div>
              <div class="period">3rd</div>
              <div class="period">4th</div>
              <div class="period">OT</div>
              <div class="period">Total</div>
            </div>
            <div class="points-container">
              <div class="period">
                {{ showProperDisplay(awayTeam1stPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(awayTeam2ndPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(awayTeam3rdPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(awayTeam4thPeriod) }}
              </div>
              <div class="period">{{ showProperDisplay(awayTeamOT) }}</div>
              <div class="period">
                {{
                  showProperDisplay(
                    awayTeam1stPeriod +
                      awayTeam2ndPeriod +
                      awayTeam3rdPeriod +
                      awayTeam4thPeriod +
                      awayTeamOT
                  )
                }}
              </div>
            </div>
          </div>
          <div class="body">
            <div
              class="player"
              *ngFor="
                let player of sortPlayerByJerseyNumber(rawData?.awayPlayers)
              "
            >
              <div class="top">
                <div class="number">{{ player.jerseyNumber }}</div>
                <div class="name">
                  {{ player.firstName }} {{ player.lastName }}
                </div>
              </div>

              <div class="quarter">
                <div class="half">
                  <div class="title">
                    <h4>1st Half</h4>
                    <div class="qtr">
                      <h4>1st</h4>
                      <h4>2nd</h4>
                    </div>
                  </div>
                  <div class="value">
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[0]?.pt2 * 2 +
                            player.periodStats[0]?.pt3 * 3 +
                            player.periodStats[0]?.ft
                        )
                      }}
                    </h4>
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[1]?.pt2 * 2 +
                            player.periodStats[1]?.pt3 * 3 +
                            player.periodStats[1]?.ft
                        )
                      }}
                    </h4>
                  </div>
                </div>
                <div class="half">
                  <div class="title">
                    <h4>2nd Half</h4>
                    <div class="qtr">
                      <h4>3rd</h4>
                      <h4>4th</h4>
                    </div>
                  </div>
                  <div class="value">
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[2]?.pt2 * 2 +
                            player.periodStats[2]?.pt3 * 3 +
                            player.periodStats[2]?.ft
                        )
                      }}
                    </h4>
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[3]?.pt2 * 2 +
                            player.periodStats[3]?.pt3 * 3 +
                            player.periodStats[3]?.ft
                        )
                      }}
                    </h4>
                  </div>
                </div>
                <div class="overtime">
                  <div class="title">OT</div>
                  <div class="value">
                    {{
                      showProperDisplay(getOvertimePoints(player.periodStats))
                    }}
                  </div>
                </div>
              </div>
              <div class="total">
                <div class="title">
                  <div class="top">Total</div>
                  <div class="bot">
                    <h4>Fouls</h4>
                    <h4>Points</h4>
                  </div>
                </div>
                <div class="value">
                  <h4>
                    {{
                      showProperDisplay(
                        getCountTotalsPerPlayer(player.periodStats, "rf", "tf")
                      )
                    }}
                  </h4>
                  <h4>
                    {{
                      showProperDisplay(
                        getCountTotalsPerPlayer(
                          player.periodStats,
                          "pt2",
                          "pt3",
                          "ft"
                        )
                      )
                    }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="home-team">
          <div class="team">HOME</div>
          <div class="team bold">{{ rawData?.currentGame.home.name }}</div>
          <div class="period-stats">
            <div class="header-container">
              <div class="period">1st</div>
              <div class="period">2nd</div>
              <div class="period">3rd</div>
              <div class="period">4th</div>
              <div class="period">OT</div>
              <div class="period">Total</div>
            </div>
            <div class="points-container">
              <div class="period">
                {{ showProperDisplay(homeTeam1stPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam2ndPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam3rdPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam4thPeriod) }}
              </div>
              <div class="period">{{ showProperDisplay(homeTeamOT) }}</div>
              <div class="period">
                {{
                  showProperDisplay(
                    homeTeam1stPeriod +
                      homeTeam2ndPeriod +
                      homeTeam3rdPeriod +
                      homeTeam4thPeriod +
                      homeTeamOT
                  )
                }}
              </div>
            </div>
          </div>
          <div class="body">
            <div
              class="player"
              *ngFor="
                let player of sortPlayerByJerseyNumber(rawData?.homePlayers)
              "
            >
              <div class="top">
                <div class="number">{{ player.jerseyNumber }}</div>
                <div class="name">
                  {{ player.firstName }} {{ player.lastName }}
                </div>
              </div>

              <div class="quarter">
                <div class="half">
                  <div class="title">
                    <h4>1st Half</h4>
                    <div class="qtr">
                      <h4>1st</h4>
                      <h4>2nd</h4>
                    </div>
                  </div>
                  <div class="value">
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[0]?.pt2 * 2 +
                            player.periodStats[0]?.pt3 * 3 +
                            player.periodStats[0]?.ft
                        )
                      }}
                    </h4>
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[1]?.pt2 * 2 +
                            player.periodStats[1]?.pt3 * 3 +
                            player.periodStats[1]?.ft
                        )
                      }}
                    </h4>
                  </div>
                </div>
                <div class="half">
                  <div class="title">
                    <h4>2nd Half</h4>
                    <div class="qtr">
                      <h4>3rd</h4>
                      <h4>4th</h4>
                    </div>
                  </div>
                  <div class="value">
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[2]?.pt2 * 2 +
                            player.periodStats[2]?.pt3 * 3 +
                            player.periodStats[2]?.ft
                        )
                      }}
                    </h4>
                    <h4>
                      {{
                        showProperDisplay(
                          player.periodStats[3]?.pt2 * 2 +
                            player.periodStats[3]?.pt3 * 3 +
                            player.periodStats[3]?.ft
                        )
                      }}
                    </h4>
                  </div>
                </div>
                <div class="overtime">
                  <div class="title">OT</div>
                  <div class="value">
                    {{
                      showProperDisplay(getOvertimePoints(player.periodStats))
                    }}
                  </div>
                </div>
              </div>
              <div class="total">
                <div class="title">
                  <div class="top">Total</div>
                  <div class="bot">
                    <h4>Fouls</h4>
                    <h4>Points</h4>
                  </div>
                </div>
                <div class="value">
                  <h4>
                    {{
                      showProperDisplay(
                        getCountTotalsPerPlayer(player.periodStats, "rf", "tf")
                      )
                    }}
                  </h4>
                  <h4>
                    {{
                      showProperDisplay(
                        getCountTotalsPerPlayer(
                          player.periodStats,
                          "pt2",
                          "pt3",
                          "ft"
                        )
                      )
                    }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span>Box Score</span>
      </ng-template>
      <div class="box-score">
        <div class="away-team">
          <div class="team">AWAY</div>
          <div class="team bold">{{ rawData?.currentGame?.away.name }}</div>
          <div class="period-stats">
            <div class="header-container">
              <div class="period">1st</div>
              <div class="period">2nd</div>
              <div class="period">3rd</div>
              <div class="period">4th</div>
              <div class="period">OT</div>
              <div class="period">Total</div>
            </div>
            <div class="points-container">
              <div class="period">
                {{ showProperDisplay(awayTeam1stPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(awayTeam2ndPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(awayTeam3rdPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(awayTeam4thPeriod) }}
              </div>
              <div class="period">{{ showProperDisplay(awayTeamOT) }}</div>
              <div class="period">
                {{
                  showProperDisplay(
                    awayTeam1stPeriod +
                      awayTeam2ndPeriod +
                      awayTeam3rdPeriod +
                      awayTeam4thPeriod +
                      awayTeamOT
                  )
                }}
              </div>
            </div>
          </div>
          <div class="header">
            <div class="number">N0.</div>
            <div class="name">PLAYER</div>
            <div class="point">FG</div>
            <div class="point">3PT</div>
            <div class="point">FT</div>
            <div class="point">OREB</div>
            <div class="point">DREB</div>
            <div class="point">REB</div>
            <div class="point">AST</div>
            <div class="point">STL</div>
            <div class="point">BLK</div>
            <div class="point">TO</div>
            <div class="point">PF</div>
            <div class="point">PTS</div>
          </div>
          <div class="body">
            <div
              class="player"
              *ngFor="
                let player of sortPlayerByJerseyNumber(rawData?.awayPlayers)
              "
            >
              <div class="number">{{ player.jerseyNumber }}</div>
              <div class="name">
                {{ player.firstName }} {{ player.lastName }}
              </div>
              <!-- FIELD GOAL -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(player.periodStats, "pt2", "pt3")
                  )
                }}
              </div>
              <!-- 3 POINTS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "pt3")
                  )
                }}
              </div>
              <!-- FREE THROW -->
              <div class="point">
                {{
                  showProperDisplay(getCountPerPlayer(player.periodStats, "ft"))
                }}
              </div>
              <!-- OREB -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "oreb")
                  )
                }}
              </div>
              <!-- DREB -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "dreb")
                  )
                }}
              </div>
              <!-- TOTAL REBOUNDS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(player.periodStats, "oreb", "dreb")
                  )
                }}
              </div>
              <!-- ASSIST -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "ast")
                  )
                }}
              </div>
              <!-- STEAL -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "stl")
                  )
                }}
              </div>
              <!-- BLOCK -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "blk")
                  )
                }}
              </div>
              <!-- TIMEOUT -->
              <div class="point">
                {{
                  showProperDisplay(getCountPerPlayer(player.periodStats, "to"))
                }}
              </div>
              <!-- FOULS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(player.periodStats, "rf", "tf")
                  )
                }}
              </div>
              <!-- TOTAL POINTS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(
                      player.periodStats,
                      "pt2",
                      "pt3",
                      "ft"
                    )
                  )
                }}
              </div>
            </div>
            <div class="totals">
              <div class="title">TEAM</div>
              <div class="value">{{ awayTeamTotalFieldGoals || "-" }}</div>
              <div class="value">{{ awayTeamTotalThreePoints || "-" }}</div>
              <div class="value">{{ awayTeamTotalFreeThrows || "-" }}</div>
              <div class="value">{{ awayTeamTotalOREB || "-" }}</div>
              <div class="value">{{ awayTeamTotalDRB || "-" }}</div>
              <div class="value">{{ awayTeamTotalRebounds || "-" }}</div>
              <div class="value">{{ awayTeamTotalAssists || "-" }}</div>
              <div class="value">{{ awayTeamTotalSteals || "-" }}</div>
              <div class="value">{{ awayTeamTotalBlocks || "-" }}</div>
              <div class="value">{{ awayTeamTotalTimeouts || "-" }}</div>
              <div class="value">{{ awayTeamTotalPersonalFouls || "-" }}</div>
              <div class="value">{{ awayTeamTotalPoints || "-" }}</div>
            </div>
          </div>
        </div>
        <div class="home-team">
          <div class="team">HOME</div>
          <div class="team bold">{{ rawData?.currentGame.home.name }}</div>
          <div class="period-stats">
            <div class="header-container">
              <div class="period">1st</div>
              <div class="period">2nd</div>
              <div class="period">3rd</div>
              <div class="period">4th</div>
              <div class="period">OT</div>
              <div class="period">Total</div>
            </div>
            <div class="points-container">
              <div class="period">
                {{ showProperDisplay(homeTeam1stPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam2ndPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam3rdPeriod) }}
              </div>
              <div class="period">
                {{ showProperDisplay(homeTeam4thPeriod) }}
              </div>
              <div class="period">{{ showProperDisplay(homeTeamOT) }}</div>
              <div class="period">
                {{
                  showProperDisplay(
                    homeTeam1stPeriod +
                      homeTeam2ndPeriod +
                      homeTeam3rdPeriod +
                      homeTeam4thPeriod +
                      homeTeamOT
                  )
                }}
              </div>
            </div>
          </div>
          <div class="header">
            <div class="number">N0.</div>
            <div class="name">PLAYER</div>
            <div class="point">FG</div>
            <div class="point">3PT</div>
            <div class="point">FT</div>
            <div class="point">OREB</div>
            <div class="point">DREB</div>
            <div class="point">REB</div>
            <div class="point">AST</div>
            <div class="point">STL</div>
            <div class="point">BLK</div>
            <div class="point">TO</div>
            <div class="point">PF</div>
            <div class="point">PTS</div>
          </div>
          <div class="body">
            <div
              class="player"
              *ngFor="
                let player of sortPlayerByJerseyNumber(rawData?.homePlayers)
              "
            >
              <div class="number">{{ player.jerseyNumber }}</div>
              <div class="name">
                {{ player.firstName }} {{ player.lastName }}
              </div>
              <!-- FIELD GOAL -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(player.periodStats, "pt2", "pt3")
                  )
                }}
              </div>
              <!-- 3 POINTS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "pt3")
                  )
                }}
              </div>
              <!-- FREE THROW -->
              <div class="point">
                {{
                  showProperDisplay(getCountPerPlayer(player.periodStats, "ft"))
                }}
              </div>
              <!-- OREB -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "oreb")
                  )
                }}
              </div>
              <!-- DREB -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "dreb")
                  )
                }}
              </div>
              <!-- TOTAL REBOUNDS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(player.periodStats, "oreb", "dreb")
                  )
                }}
              </div>
              <!-- ASSIST -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "ast")
                  )
                }}
              </div>
              <!-- STEAL -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "stl")
                  )
                }}
              </div>
              <!-- BLOCK -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountPerPlayer(player.periodStats, "blk")
                  )
                }}
              </div>
              <!-- TIMEOUT -->
              <div class="point">
                {{
                  showProperDisplay(getCountPerPlayer(player.periodStats, "to"))
                }}
              </div>
              <!-- FOULS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(player.periodStats, "rf", "tf")
                  )
                }}
              </div>
              <!-- TOTAL POINTS -->
              <div class="point">
                {{
                  showProperDisplay(
                    getCountTotalsPerPlayer(
                      player.periodStats,
                      "pt2",
                      "pt3",
                      "ft"
                    )
                  )
                }}
              </div>
            </div>
            <div class="totals">
              <div class="title">TEAM</div>
              <div class="value">{{ homeTeamTotalFieldGoals || "-" }}</div>
              <div class="value">{{ homeTeamTotalThreePoints || "-" }}</div>
              <div class="value">{{ homeTeamTotalFreeThrows || "-" }}</div>
              <div class="value">{{ homeTeamTotalOREB || "-" }}</div>
              <div class="value">{{ homeTeamTotalDRB || "-" }}</div>
              <div class="value">{{ homeTeamTotalRebounds || "-" }}</div>
              <div class="value">{{ homeTeamTotalAssists || "-" }}</div>
              <div class="value">{{ homeTeamTotalSteals || "-" }}</div>
              <div class="value">{{ homeTeamTotalBlocks || "-" }}</div>
              <div class="value">{{ homeTeamTotalTimeouts }}</div>
              <div class="value">{{ homeTeamTotalPersonalFouls || "-" }}</div>
              <div class="value">{{ homeTeamTotalPoints || "-" }}</div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Header III">
      <ng-template pTemplate="header">
        <span>Game Log</span>
      </ng-template>

      <div class="game-log">
        <div class="teams-container">
          <div class="team">
            <div class="team">AWAY</div>
            <div class="team bold">{{ rawData?.currentGame.away.name }}</div>
          </div>
          <div class="versus">VS</div>
          <div class="team">
            <div class="team">HOME</div>
            <div class="team bold">{{ rawData?.currentGame.home.name }}</div>
          </div>
        </div>
        <div class="header">
          <div class="timestamp">Timestamp</div>
          <div class="action">Action</div>
          <div class="period">{{ innerWidth > 450 ? "Period" : "PER." }}</div>
          <div class="score">{{ innerWidth > 450 ? "Score" : "SCO." }}</div>
        </div>
        <div class="body">
          <ng-container *ngFor="let log of logDataWithScores">
            <div class="row">
              <div class="timestamp">
                {{ log.createdAt | date : "h:mm a EEE MMM d" }}
              </div>
              <div class="action">
                {{
                  (log.action == "cw-hc" || log.action == "cw-ac") && log.isHome
                    ? rawData?.currentGame.home.name
                    : ""
                }}
                {{
                  (log.action == "cw-hc" || log.action == "cw-ac") &&
                  !log.isHome
                    ? rawData?.currentGame.away.name
                    : ""
                }}
                {{ getLogFromLog(log.log, log.action) }}
              </div>
              <div class="period">
                {{ getPeriodString(log.period) }}
              </div>
              <div class="score">{{ log.awayScore }} - {{ log.homeScore }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
