<div class="container">
    <h2>Payment Form</h2>
    <form action="/process_payment" method="post">
      <div class="form-group">
        <label for="card_number">Card Number</label>
        <input type="text" id="card_number" name="card_number" placeholder="1234 5678 9012 3456" required>
      </div>
      <div class="form-group">
        <label for="expiry_date">Expiry Date</label>
        <input type="text" id="expiry_date" name="expiry_date" placeholder="MM/YY" required>
      </div>
      <div class="form-group">
        <label for="cvv">CVV</label>
        <input type="text" id="cvv" name="cvv" placeholder="123" required>
      </div>
      <div class="form-group">
        <label for="name_on_card">Name on Card</label>
        <input type="text" id="name_on_card" name="name_on_card" placeholder="John Doe" required>
      </div>
      <div class="form-group">
        <button type="submit">Pay Now</button>
      </div>
    </form>
  </div>