import { Component, OnInit, Input } from '@angular/core';
import { AuthService, HttpService, ToastService } from '@core/services';

import { TeamDivisionsService } from '@core/services/team-divisions.service';

import { TeamDivision } from '@core/models/team-division';
import { environment } from '@env/environment';
import { log } from 'console';
import { AngularFireAuth } from '@angular/fire/auth';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaymentAuthModalComponent } from './payment-auth-modal/payment-auth-modal.component';
import { PaymentAddModalComponent } from './payment-add-modal/payment-add-modal.component';

@Component({
  selector: 'checkin-payment',
  templateUrl: './checkin-payment.component.html',
  styleUrls: ['./checkin-payment.component.scss'],
})
export class CheckinPaymentComponent implements OnInit {
  paidAmount: number = 0;
  discount: number = 0;
  note: string = '';
  _teamDivision: TeamDivision;
  payments: any[] = [];
  ref: DynamicDialogRef;

  @Input()
  set teamDivision(teamDivision: TeamDivision) {
    this._teamDivision = teamDivision;
    console.log('THIS TEAMDIVISION', teamDivision);

    this.paidAmount = teamDivision?.paidAmount;
    this.discount = teamDivision?.discount;
    this.note = teamDivision?.note;
    this.getCheckInPayment(teamDivision?.id);
  }

  get teamDivision(): TeamDivision {
    return this._teamDivision;
  }

  constructor(
    private httpService: HttpService,
    private teamDivisionService: TeamDivisionsService,
    private toastService: ToastService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) {
    this.teamDivisionService.teamDivision$.subscribe((teamDivision) => {
      this.teamDivision = teamDivision;
    });
  }

  ngOnInit() {
    
  }

  getCheckInPayment(teamDivisionId) {
    this.httpService
      .get({
        url: `${environment.api.checkInPayment}?teamDivisionId=${teamDivisionId}`,
        payload: [],
      })
      .subscribe((payments) => {
        console.log('DATAAAAAA', payments);

        let runner = 0;
        let discountTotal = 0;
        let entryFee = this.teamDivision.division.entryFee;
        this.payments = [];

        payments.map((payment) => {
          runner = runner + payment.paidAmount + payment.discount;
          discountTotal = discountTotal + payment.discount;

          let toPush = {
            teamDivisionId: payment.teamDivisionId,
            paymentDate: payment.paymentDate,
            paidAmount: payment.paidAmount,
            note: payment.note,
            runningTotal: runner,
            owed: entryFee - runner,
            discount: payment.discount,
          };
          this.payments.push(toPush);
        });
        console.log('with running', this.payments);
      });
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  getBalance() {
    const balance =
      this._teamDivision.division.entryFee - (this.paidAmount + this.discount);
    return balance > 0 ? balance : 0;
  }

  savePayment() {
    let teamDivision = this._teamDivision;
    teamDivision.paidAmount = this.paidAmount;
    teamDivision.discount = this.discount;
    teamDivision.paymentDate = new Date();
    teamDivision.note = this.note;
    console.log('this is note', this.note);

    this.teamDivisionService
      .saveTeamDivision(teamDivision)
      .subscribe((teamDivision) => {
        this.teamDivisionService.teamDivision$.next(teamDivision);
        this.getCheckInPayment(teamDivision.id);
        this.toastService.successMsg({
          message: 'Payments saved successfully',
        });
      });

    let body = {
      teamDivisionId: this.teamDivision.id,
      paymentDate: new Date(),
      paidAmount: this.paidAmount,
      note: this.note,
      discount: this.discount,
    };

    this.httpService
      .post({
        url: `${environment.api.checkInPayment}`,
        payload: body,
      })
      .subscribe((data) => {
        console.log('SAVED PAYMENT DATA', data);
      });
  }

  addPaymentAuth() {
    this.ref = this.dialogService.open(PaymentAuthModalComponent, {
      header: 'Add Payment',
      width: '600px',
      height: 'auto',
      contentStyle: { 'overflow-y': 'hidden' },
      closable: false,
      data: {
        
      },
    });
    this.ref.onClose.subscribe((res) => {
      console.log("Modal Result", res);
      if (res) {
        this.addPayment();
      }
    });
  }

  addPayment() {
    this.ref = this.dialogService.open(PaymentAddModalComponent, {
      header: 'Add Payment',
      width: '600px',
      height: 'auto',
      contentStyle: { 'overflow-y': 'hidden' },
      closable: false,
      data: {
        teamDivision: this._teamDivision,
      },
    });
    this.ref.onClose.subscribe(res => {
      if (res) {
        this.getCheckInPayment(this._teamDivision.id);
      }
    });
  }
}
