<div class="container-featured">
  <div class="container-featured-header TheBoldFont-Bold">
    FEATURED TOURNAMENTS
  </div>
  <div class="container-styles">
    <fa-icon
      (click)="viewGrid()"
      [ngClass]="{ red: isGrid, grey: !isGrid }"
      class="faicon"
      [icon]="faGrid"
    ></fa-icon>
    <fa-icon
      (click)="viewList()"
      [ngClass]="{ red: !isGrid, grey: isGrid }"
      class="faicon"
      [icon]="faList"
    ></fa-icon>
  </div>

  <div
    *ngIf="!tournaments || tournaments.length == 0"
    class="no-featured-message"
  >
    There are no featured tournaments at the moment.
  </div>

  <ng-container *ngIf="isGrid; else elseBlock">
    <app-grid-layout [tournaments]="tournaments"> </app-grid-layout>
  </ng-container>
  <ng-template #elseBlock>
    <app-list-layout [tournaments]="tournaments"> </app-list-layout>
  </ng-template>
  <div class="btn-bottom">
    <a [routerLink]="['/browse-tournaments']">
      <button class="btn-rounded-red">Browse All Tournaments</button>
    </a>
  </div>
</div>
