<div class="container-main">
  <div class="logo order">
    <img src="./assets/img/logo_only.svg" />
    <!-- <img src="./assets/img/logo_white_text_only.svg" /> -->
    <h1 class="title-header" (click)="gotoHome()">TOURNEYKEY</h1>
    <i
      class="pi pi-bars"
      *ngIf="innerWidth <= 680"
      [ngClass]="isMobileNaviClicked ? 'hide' : 'show'"
      (click)="mobileNavi()"
    ></i>
    <i
      class="pi pi-times"
      *ngIf="innerWidth <= 680"
      [ngClass]="isMobileNaviClicked ? 'show' : 'hide'"
      (click)="mobileNavi()"
    ></i>
  </div>

  <div class="navigations order">
    <div class="nav-item">
      <a (click)="goToFeatures()">Features</a>
    </div>
    <div class="nav-item">
      <a (click)="goToPricingPage()">Pricing</a>
    </div>
    <div class="nav-item">
      <a (click)="goToBrowse()">Browse Tournaments</a>
    </div>
    <div class="nav-item">
      <a (click)="goToSupportPage()">Support</a>
    </div>
  </div>

  <div class="buttons order" *ngIf="!isLogged && !isLoad; else loggedTemp">
    <p-overlayPanel
      [appendTo]="checkDevice() ? 'container-content' : null"
      #opLogin
    >
      <ng-template pTemplate>
        <app-login
          [opLogin]="opLogin"
          (isSignup)="loginButton($event)"
        ></app-login>
      </ng-template>
    </p-overlayPanel>
    <button class="btn-rounded-red" #btnLoginWeb (click)="openLogin()">
      Login
    </button>

    <button class="btn-rounded-white" (click)="signup()">Sign Up</button>
  </div>

  <ng-template #loggedTemp>
    <div class="buttons order">
      <button class="btn-rounded-red" (click)="gotoDashboard()">
        Go to Dashboard
      </button>
      <button class="btn-rounded-red" (click)="logout()">Log out</button>
    </div>
  </ng-template>
</div>

<div class="navigations-modal" [class.clicked]="isMobileNaviClicked">
  <div class="nav-item" [class.show]="isMobileNaviClicked">
    <a (click)="goToFeatures()">Features</a>
  </div>
  <div class="nav-item" [class.show]="isMobileNaviClicked">
    <a (click)="goToPricingPage()">Pricing</a>
  </div>
  <div class="nav-item" [class.show]="isMobileNaviClicked">
    <a (click)="goToBrowse()">Browse Tournaments</a>
  </div>
  <div class="nav-item" [class.show]="isMobileNaviClicked">
    <a (click)="goToSupportPage()">Support</a>
  </div>

  <button
    class="btn-rounded-red"
    [class.show]="isMobileNaviClicked"
    (click)="opLogin.toggle($event)"
    #btnLogin
  >
    Login
  </button>

  <p-overlayPanel
    [appendTo]="checkDevice() ? 'container-content' : null"
    #opLogin
  >
    <ng-template pTemplate>
      <app-login
        [opLogin]="opLogin"
        (isSignup)="loginButton($event)"
      ></app-login>
    </ng-template>
  </p-overlayPanel>

  <button
    class="btn-rounded-white"
    [class.show]="isMobileNaviClicked"
    (click)="signup()"
  >
    Sign Up
  </button>
</div>
