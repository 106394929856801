import { Component, OnInit } from '@angular/core';
import { faCalendar, faLaptop, faMobile, faRegistered, faRobot, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faHammer } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-public-features-main',
  templateUrl: './public-features-main.component.html',
  styleUrls: ['./public-features-main.component.scss']
})
export class PublicFeaturesMainComponent implements OnInit {
  faRobot = faRobot;
  faAddressCard = faAddressCard;
  faPen = faPen;
  faChartLine = faChartLine;
  faHammer = faHammer;
  faRegistered = faRegistered;
  faCalendar = faCalendar;
  faToolbox = faToolbox;
  faMobile = faMobile;
  faLaptop = faLaptop;
  show = false;
  constructor() { }

  ngOnInit(): void {
  }

}
