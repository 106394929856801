import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BracketSet } from '@core/models/bracket-set';
import { BracketSources } from '@core/models/bracket-sources';
import { Bracket } from '@core/models/brackets';
import { BracketService } from '@core/services/bracket-service';
import { BehaviorSubject } from 'rxjs';
import * as objectScan from 'object-scan';
import { BracketPreviewComponent } from '@components/admin/bracket-builder/bracket-preview/bracket-preview.component';
import { PublicBracketStageComponent } from '@public/public-bracket-stage/public-bracket-stage.component';

@Component({
  selector: 'app-public-bracket',
  templateUrl: './public-bracket.component.html',
  styleUrls: ['./public-bracket.component.scss'],
})
export class PublicBracketComponent implements OnInit {
  @ViewChild('bracket', { read: ViewContainerRef }) target: ViewContainerRef;

  data = new BehaviorSubject<any>({});
  dynamicComponent: any[] = [];
  stageHeight: number;
  stageWidth: number;
  selectedDivision: any;
  height = 60;
  width = 240;
  firstOptions: BracketSources[];
  bracketValidated: boolean = true;
  isHasSet = false;
  topBrackets: any;
  bottomBrackets: any;
  venues = [];
  divisionBracketsDetails: any[] = [];
  bracketSet: BracketSet[];
  @ViewChild('bracketStage', { read: ViewContainerRef }) bracketStage: ViewContainerRef;

  @Input()
  set divisionInfo(value) {
    this.selectedDivision = value;
    this.data.next(value);
    console.log('DIVISIONNNNNN', this.selectedDivision);
  }

  get divisionInfo() {
    return this.data.getValue();
  }

  private componentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private bracketService: BracketService
  ) {}

  calculateHeight(y1, y2): number {
    let y = y2 - y1;
    return y;
  }

  calculateWidth(x3, x1): number {
    return x3 - x1;
  }

  ngOnInit(): void {
    this.init();
  }

  async renderBrackets() {

    for(let y = 0; y < this.bracketSet.length; y++){
      let brackets = this.bracketSet[y].brackets
      this.createNewBracketSet(this.bracketSet[y]);
    }
  }
  async createNewBracketSet(bracketSet:BracketSet): Promise<void>{
    this.stageHeight = 1280;
    this.stageWidth = 520;
    const bracketStageComponent =this.componentFactoryResolver.resolveComponentFactory(PublicBracketStageComponent);
    let stageComponent: ComponentRef<any>;
    stageComponent = this.bracketStage.createComponent(bracketStageComponent);
    stageComponent.instance.bracketSet = bracketSet
    stageComponent.instance.stageHeight = this.stageHeight
    stageComponent.instance.stageWidth = this.stageWidth
    stageComponent.instance.divisionInfo = this.divisionInfo
    stageComponent.instance.isPreview = false;
    this.dynamicComponent.push(stageComponent.instance)
  }
  init() {
    this.bracketService.getBracketSet(this.selectedDivision.id)
    .then(async (bracketSet: BracketSet[]) => {
      if (bracketSet?.length == 0 || bracketSet == null) {
        this.isHasSet = false;
      } else {
        this.isHasSet = true;
        this.stageHeight = 1280;
        this.stageWidth = 520;
        this.bracketSet = bracketSet
        this.renderBrackets();
      }
    })
    .catch((err) => {});
  }
  

  addBracket(
    x: number,
    y: number,
    name: string,
    topTeam,
    bottomTeam,
    topSource,
    bottomSource,
    h: number,
    w: number,
    br?: any,
    bracketType?: string,
    courtName?: string,
    venueName?,
    gameName?: string
  ) {
    let childComponent = this.componentFactoryResolver.resolveComponentFactory(
      BracketPreviewComponent
    );

    console.log(
      'addBracket funccc',
      bottomSource,
    );

    this.componentRef = this.target.createComponent(childComponent);
    this.componentRef.instance.firstOptions = this.firstOptions;
    this.componentRef.instance.height = h;
    this.componentRef.instance.width = w;
    this.componentRef.instance.left = x;
    this.componentRef.instance.top = y;
    this.componentRef.instance.id = br.id == null ? 0 : br.id;
    this.componentRef.instance.bracketName = name;
    this.componentRef.instance.topSelectedOption = topSource;
    this.componentRef.instance.bottomSelectedOption = bottomSource;
    this.componentRef.instance.topTeam = topTeam ? topTeam.name : '';
    this.componentRef.instance.bottomTeam = bottomTeam ? bottomTeam.name : '';
    this.componentRef.instance.bracketName = name;
    this.componentRef.instance.parentRef = PublicBracketComponent;
    this.componentRef.instance.bracketType = bracketType;
    this.componentRef.instance.courtName = courtName;
    this.componentRef.instance.gameName = gameName;
    this.componentRef.instance.venueName = venueName;

    this.componentRef.instance.bracketObject = {
      division: this.divisionInfo,
      br: br,
      bracketSet: this.bracketSet,
    };

    // Pass bracket details to be fetched by Division Schedules Component
    this.divisionBracketsDetails.push(this.componentRef.instance);
    this.bracketService.divisionBracketsDetails$.next(
      this.divisionBracketsDetails
    );

    console.log('this.divisionBracketsDetailss', this.divisionBracketsDetails);

    this.dynamicComponent.push(this.componentRef.instance);
  }

  // renderBrackets() {
  //   this.bracketSet.brackets.forEach((v: Bracket, index) => {
  //     if(v.bracketType =="RIGHT_FACING"){
  //       console.log("CHECK BRACKET ", v);

  //     }
  //     this.addBracket(
  //       v.x1,
  //       v.y1,
  //       v.name,
  //       v.topTeam,
  //       v.bottomTeam,
  //       v.topSource,
  //       v.bottomSource,
  //       this.calculateHeight(v.y1, v.y2),
  //       this.calculateWidth(v.x3, v.x1),
  //       v,
  //       v.bracketType,
  //       v.courtName,
  //       v.venueName,
  //       v.name
  //     );
  //     this.venues.push(`${v?.venueName} ${v?.courtName}`);

  //     this.bottomBrackets = this.searchBottomBracket(v);
  //     this.topBrackets = this.searchTopBracket(v);
  //     for (
  //       var i = 0, j = 0;
  //       i < this.bottomBrackets.length || j < this.topBrackets.length;

  //     ) {
  //       if (i < this.bottomBrackets.length) {
  //         if (this.bottomBrackets[i] != null) {
  //           this.venues.push(
  //             `${v?.venueName} ${this.bottomBrackets[i].courtName}`
  //           );
  //           console.log('THISI IS v innerrr', v);

  //           this.addBracket(
  //             this.bottomBrackets[i].x1,
  //             this.bottomBrackets[i].y1,
  //             this.bottomBrackets[i].name,
  //             this.bottomBrackets[i].topTeam,
  //             this.bottomBrackets[i].bottomTeam,
  //             this.bottomBrackets[i].topSource,
  //             this.bottomBrackets[i].bottomSource,
  //             this.calculateHeight(
  //               this.bottomBrackets[i].y1,
  //               this.bottomBrackets[i].y2
  //             ),
  //             this.calculateWidth(
  //               this.bottomBrackets[i].x3,
  //               this.bottomBrackets[i].x1
  //             ),

  //             this.bottomBrackets[i],
  //             v.bracketType,
  //             this.bottomBrackets[i].courtName,
  //             v.venueName,
  //             v.name
  //           );
  //           // console.log('bottom ', this.bottomBrackets[i].name);
  //         }

  //         i++;
  //       }
  //       if (j < this.topBrackets.length) {
  //         if (this.topBrackets[j] != null) {
  //           this.venues.push(
  //             `${v?.venueName} ${this.topBrackets[j].courtName}`
  //           );
  //           console.log('THISI IS v outerrr', v);

  //           this.addBracket(
  //             this.topBrackets[j].x1,
  //             this.topBrackets[j].y1,
  //             this.topBrackets[j].name,
  //             this.topBrackets[j].topTeam,
  //             this.topBrackets[j].bottomTeam,
  //             this.topBrackets[j].topSource,
  //             this.topBrackets[j].bottomSource,
  //             this.calculateHeight(
  //               this.topBrackets[j].y1,
  //               this.topBrackets[j].y2
  //             ),
  //             this.calculateWidth(
  //               this.topBrackets[j].x3,
  //               this.topBrackets[j].x1
  //             ),
  //             this.topBrackets[j],
  //             v.bracketType,
  //             this.topBrackets[j].courtName,
  //             v.venueName,
  //             v.name
  //           );
  //           // console.log('top ', this.topBrackets[j].name);
  //         }

  //         j++;
  //       }
  //     }
  //   });
  //   console.log('SHOULD DISPLAY ALL LIST', this.divisionBracketsDetails);
  //   this.divisionBracketsDetails?.map((data) => {
  //     if (data.bracketName === 'Champion') {
  //       this.bracketService.championTeam$.next(data.topTeam);
  //       console.log('champss', data.topTeam);
  //     }
  //   });
  // }

  searchTopBracket(obj: any) {
    let temp = [];
    let arr = objectScan(['**.topBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }

  searchBottomBracket(obj: any) {
    let temp = [];

    let arr = objectScan(['**.bottomBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }
}
