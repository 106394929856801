<div class="px-4 container-admin">
  <div class="main-container-org mr-4">
    <div class="flex mt-5 flex-align-center">
      <div class="a-header-org" *ngIf="org?.name; else noOrg">
        <span class="TheBoldFont-Bold fs-6 text-primary3">{{ org?.name }}</span>
        <span class="TheBoldFont-Bold fs-2 text-black4">{{ pageName }}</span>
      </div>
      <ng-template #noOrg>
        <div class="a-header-org">
          <span class="TheBoldFont-Bold fs-6 text-primary3">Loading ...</span>
          <span class="TheBoldFont-Bold fs-2 text-black4">{{ pageName }}</span>
        </div>
      </ng-template>
    </div>
    <div class="container-ball">
      <img
        src="assets/img/ball-avatar.png"
        width="50"
        class="ball-avatar br-circle"
        (click)="userDetails.toggle($event)"
      />
    </div>
  </div>
</div>

<p-overlayPanel #userDetails appendTo="body" styleClass="panel-content">
  <ng-template pTemplate>
    <div class="flex flex-row profile flex-center">
      <div>
        <img
          src="assets/img/ball-avatar.png"
          width="50"
          class="ball-avatar br-circle"
        />
        <section
          class="fs-xsmall disp-block text-center text-white"
          style="height: 20px"
        >
          {{ currentVersion }}
        </section>
      </div>
      <div class="header-name">
        <label class="name">{{ user?.fullName | uppercase }} </label>
        <label class="text-bold3">{{ user?.userType | uppercase }} </label>
        <label>{{ user?.principal }} </label>
      </div>
    </div>
    <div class="container-actions">
      <div class="container-changepw">
        <!-- <h4 (click)="changePassword()" class="text-changepw"> -->
        <h4 class="text-changepw">
          <i class="pi pi-angle-double-right icon-red"> </i>&nbsp; Change
          Password
        </h4>
      </div>
    </div>
    <div class="footer flex flex-center">
      <button class="btn-rounded-red" (click)="logout()">Log out</button>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog
  header="Reset Password"
  [(visible)]="isChangePassowrd"
  [modal]="true"
  [dismissableMask]="true"
  appendTo="body"
>
  <app-change-password></app-change-password>
</p-dialog>
