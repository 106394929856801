import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import { EmitterService, HttpService, ToastService } from '@core/services';
import { CoachCoachService } from '@core/services/coach-coach.service';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-coach-modal',
  templateUrl: './coach-modal.component.html',
  styleUrls: ['./coach-modal.component.scss']
})
export class CoachModalComponent implements OnInit {

  coachForm: FormGroup;
  existingCoach: any;
  user: User;
  team: Teams;
  teams: Teams[];

  hasLogo: boolean;
  isLogoUploaded: boolean;
  isLogoFetching: boolean;

  isEdit = false;
  hasSelectTeam = false;
  isCoach = false;
  // roles = [
  //   {name: 'Coach', value: 'COACH'},
  //   {name: 'Head Coach', value: 'HEAD_COACH'},
  //   {name: 'Administrator', value: 'ADMINISTRATOR'},
  //   {name: 'Parent', value: 'PARENT'},
  //   {name: 'Other', value: 'OTHER'}
  // ]

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private emitterService: EmitterService,
    private toast: ToastService,
    private coachCoachService: CoachCoachService,
    private coachTeamService: CoachTeamsService
  ) {


  }

  ngOnInit(): void {
    this.initForm();
    this.existingCoach = this.config.data.coach;
    this.isEdit = this.config.data.isEdit;
    this.team = this.config.data.team;
    this.isCoach = this.config.data?.isCoach ?? false;

    this.emitterService.meEmitter.subscribe(user => {
      this.user = user;
    });
    this.checkIsEdit();
    this.getTeams();
  }

  initForm(): void {
    this.coachForm = this.fb.group({
      id: [null],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phoneNumber: [''],
      // role: ['',[Validators.required]],
      city: [''],
      state: [''],
      address: [''],
      team: [{},]
    });
  }

  checkIsEdit() {

    if (this.isEdit) {
      let coach = this.existingCoach;
      this.coachForm.patchValue({
        id: coach.id,
        firstName: coach?.firstName,
        lastName: coach?.lastName,
        email: coach?.email,
        phoneNumber: coach?.phoneNumber,
        // role: coach?.role,
        city: coach?.city,
        state: coach?.state,
        address: coach?.address,
        team: coach?.team
      });

    } else {
      this.coachForm.patchValue({
        team: {
          id: sessionStorage.getItem('teamId'),
        },
      });
    }
  }

  async getTeams(): Promise<any> {

    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    this.coachTeamService.getTeams(userId).subscribe((teams) => {
      this.teams = teams;
    });
  }


  async submit() {

    let teamOpen = this.team
    let formValue = {
      ...this.coachForm.value,
      team: this.team
    }

    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    if (this.isEdit) {
      this.toast.toast('Please Wait', 'Editing Coach', 'info')
      this.isLogoFetching = true;
    } else {
      this.toast.toast('Please Wait', 'Creating Coach', 'info')
      formValue = this.coachForm.value;
    }

    if (teamOpen != null) {

      if (this.isEdit) {

        this.coachCoachService.editTeamCoach(teamOpen.id, formValue).toPromise().then(coach => {
          this.toast.successMsg({ message: this.isEdit ? 'Coach Edited' : 'Coach Created' });
          this.ref.close();
        }).catch(err => {
          this.toast.errorMsg({ message: this.isEdit ? 'Editing Coach Failed' : 'Creating Coach Failed' });
        });

      } else {

        this.coachCoachService.addTeamCoach(teamOpen.id, formValue).toPromise().then(coach => {

          this.toast.successMsg({ message: this.isEdit ? 'Coach Edited' : 'Coach Created' });
          this.ref.close();

        }).catch(err => {
          this.toast.errorMsg({ message: this.isEdit ? 'Editing Coach Failed' : 'Creating Coach Failed' });
        });
      }

    } else {

      this.coachCoachService.addAssistantCoach(userId, formValue).toPromise().then(coach => {

        this.toast.successMsg({ message: this.isEdit ? 'Coach Edited' : 'Coach Created' });
        this.ref.close();

      }).catch(err => {
        this.toast.errorMsg({ message: this.isEdit ? 'Editing Coach Failed' : 'Creating Coach Failed' });
      })
    };
  }

  cancel(): void {
    this.ref.close();
  }
}
