import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// SERVICES
import { AuthService, EmitterService, HttpService } from '@services/index';

// MODELS
import { User } from '@models/user';
import { Organization } from '@models/organization';
import { defConstant } from '@core/data/constant-data';

// ENV
import { environment } from '@env/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { log } from 'console';

@Component({
  selector: 'app-coach-header',
  templateUrl: './coach-header.component.html',
  styleUrls: ['./coach-header.component.scss'],
})
export class CoachHeaderComponent implements OnInit {
  isChangePassowrd: boolean;
  currentVersion = defConstant.currentVersion;

  pageName: string = "Coach's Dashboard";

  user: User;

  constructor(
    private router: Router,
    private authService: AuthService,
    private emitterService: EmitterService,
    private httpService: HttpService,
    private auth: AuthService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.emitterService.pageNameEmitter.subscribe((pageName) => {
      this.pageName = pageName;
    });

    this.emitterService.meEmitter.subscribe((user) => {
      console.log('user', user);
      this.user = user;
      this.ngxLoader.stop();
    });
    this.auth.isShowChangePass$.subscribe((res) => {
      console.log('iopen', res);
      this.isChangePassowrd = res;
      console.log('DUMAAN DIO');
    });
  }

  ngOnInit(): void {
    this.getData();
    this.auth.isShowChangePass$.next(false);
  }
  changePassword() {
    this.auth.isShowChangePass$.next(true);
  }
  async getData() {
    const userData = this.emitterService.userData;
    if (userData) {
      this.user = userData;
      this.ngxLoader.stop();
    } else {
      this.user = await this.httpService.me().toPromise();
      this.ngxLoader.stop();
    }
  }

  logout(): void {
    this.authService.logout();
  }

  goBrowseTournaments() {
    this.authService.isLoggedInEmitter.next(true);
    this.router.navigate(['/browse-tournaments']);
  }
}
