import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '@core/services';

import { TeamsService } from '@core/services/teams.service';
import { VenueService } from '@core/services/venue.service';

@Component({
  selector: 'app-tournament-teams',
  templateUrl: './tournament-teams.component.html',
  styleUrls: ['./tournament-teams.component.scss']
})
export class TournamentTeamsComponent implements OnInit {
  teamList: any[] = [];
  isLoading: boolean = true;
  tournamentId: number;
  venueId: number;
  isFetching: boolean = false;
  team: any[] = [];

  meta: any = {};
  q: string = '';
  searchTimeout: any;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private teamsService: TeamsService,
    private venueService: VenueService,
  ) { }

  ngOnInit() {
    this.tournamentId = parseInt(sessionStorage.getItem('tournamentId'));
    this.venueId = parseInt(sessionStorage.getItem("venueId"));
    this.initTeams();
  }

  async initTeams() {

    this.isFetching = true;
    try {
      const list = await this.teamsService.initCheckInTeams(this.tournamentId);
      console.log(list);

      this.teamList = list.items;
      this.meta = list.meta;

      this.isFetching = false;
    } catch (err) {
      console.log(err);
      this.isFetching = false;
    }
  }

  async getTeams(action: string, meta: any) {

    this.isFetching = true;

    try {

      const list = await this.teamsService.getCheckInTeams(this.tournamentId, action, meta, this.q);
      console.log(list);

      this.teamList = list.items;
      this.meta = list.meta;

      this.isFetching = false;
    } catch (err) {
      console.log(err);
      this.isFetching = false;
    }
  }

  debounceSearch() {
    clearTimeout(this.searchTimeout);
    this.search();
  }

  search() {
    if (this.q.length >= 3 || this.q.length === 0) {
      this.searchTimeout = setTimeout(() => {
        let meta: any = Object.assign({}, this.meta);
        meta.search = this.q;
        this.getTeams('search', meta);
      }, 750); // delay search for 750 milliseconds and do search if no key press is detected after
    }
  }

  previous() {
    this.getTeams('previous', this.meta);
  }

  next() {
    this.getTeams('next', this.meta);
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    this.getTeams('index', meta);
  }

  selectedTeam(team: any) {
    this.router.navigate(['/dashboard', 'check-in', 'checkin-team-details', team.divisionName, team.teamId, team.teamDivisionId, this.tournamentId, this.venueId]);
  }
}
