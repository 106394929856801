import { Component, Input, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-shared-tooltip',
  templateUrl: './shared-tooltip.component.html',
  styleUrls: ['./shared-tooltip.component.scss'],
})
export class SharedTooltipComponent implements OnInit {
  @HostListener('click', ['$event'])
  @Input()
  header: string;
  @Input() content: string;
  @Input() shortDescription: string;
  @Input() videoId: string = '';
  @Input() note: string = '';
  @Input() example: string = '';
  @Input() typesOfTie: string = '';
  @Input() examplep1: string;
  @Input() examplep2: string;
  @Input() examplep3: string;
  @Input() examplep4: string;
  @Input() examplep5: string;

  dialogHeader: string = '';
  dialogDescription: string = '';
  dialogNote: string = '';
  dialogExample: string = '';
  dialogContent: string = '';
  dialogTypes: string = '';
  dialogExamplep1: string = '';
  dialogExamplep2: string = '';
  dialogExamplep3: string = '';
  dialogExamplep4: string = '';
  dialogExamplep5: string = '';
  apiLoaded: boolean = false;

  display: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  onTooltipClick(event: Event): void {
    event.stopPropagation();
    this.showDialog(
      this.header,
      this.content,
      this.shortDescription,
      this.videoId,
      this.note,
      this.example,
      this.typesOfTie,
      this.examplep1,
      this.examplep2,
      this.examplep3,
      this.examplep4,
      this.examplep5
    );
  }

  showDialog(
    header: string,
    content: string,
    shortDescription: string,
    videoId: string,
    note: string,
    example: string,
    typesOfTie: string,
    examplep1: string,
    examplep2: string,
    examplep3: string,
    examplep4: string,
    examplep5: string
  ) {
    this.dialogHeader = header;
    this.dialogContent = content;
    this.dialogDescription = shortDescription;
    this.dialogNote = note ? `<strong>Note:</strong> ${note}` : '';
    this.dialogExample = example ? `<strong>Example:</strong> ${example}` : '';
    this.dialogTypes = typesOfTie
      ? `<strong>Types of Tiebreakers:</strong> ${typesOfTie}`
      : '';
    this.videoId = videoId;
    this.dialogExamplep1 = examplep1;
    this.dialogExamplep2 = examplep2;
    this.dialogExamplep3 = examplep3;
    this.dialogExamplep4 = examplep4;
    this.dialogExamplep5 = examplep5;
    this.display = true;
  }
}
