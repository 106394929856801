import { Component, OnInit } from '@angular/core';
import { Court } from '@core/models/courts';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-edit-multiple-games',
  templateUrl: './edit-multiple-games.component.html',
  styleUrls: ['./edit-multiple-games.component.scss']
})
export class EditMultipleGamesComponent implements OnInit {
  courts: Court[]
  selectedCourt: Court
  courtAvail: Court[];
  selectedDate: any

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) { 
    this.courts = this.config.data.courts
    this.courtAvail = this.config.data.courtAvail
  }

  ngOnInit(): void {
  }

  filterCourtAvail(court: Court) {
    let newArr = this.courtAvail.filter((res) => res.id == court?.id);

    return newArr[0]?.dateAvail;
  }

  close(): void {
    this.ref.close()
  }

  save(): void {
    let data = {
      "court": this.selectedCourt,
      "date": this.selectedDate
    }

    this.ref.close(data)
  }

}
