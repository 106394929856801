<div class="change-bracket">
    <div class="p-grid p-3" >
      <div class="message">You have unsave changes and you are trying to leave the page.</div>
      <br/>
      <div class="message">Are you sure you want to continue?</div>
      <br/>
      <br/>
  
      <div class="p-col-12 flex flex-center mt-4">
        <button
          pButton
          type="button"
          label="Cancel"
          class="
            p-button-raised p-button-rounded p-button-text p-button-plain
            mx-2
            px-5
            py-3
          "
          (click)="close()"
        ></button>
        <button
          pButton
          type="button"
          label="Proceed"
          class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
          (click)="onSubmit()"
        ></button>
      </div>
    </div>
  </div>
  