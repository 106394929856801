import { Component, OnInit, Input } from '@angular/core';
import { AddTeamComponent } from '../add-team/add-team.component';
import { EditTeamComponent } from '@coach/edit-team/edit-team.component';

// PRIMENG
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import {FilterService} from 'primeng/api';

// MODELS
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import { CoachTeams } from '@core/models/coach-teams';

// SERVICES
import {
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
  AuthService,
} from '@core/services';
import { CoachTeamsService } from '@core/services/coach-teams.service';

// ENV
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core'

@Component({
  selector: 'app-view-teams',
  templateUrl: './view-teams.component.html',
  styleUrls: ['./view-teams.component.scss'],
})
export class ViewTeamsComponent implements OnInit {
  user: User;
  selectedTeams: Teams[];
  teamData: any;
  data;
  teams: Teams[];
  hasTeam: boolean = false;

  @ViewChild('dt') dt: Table | undefined;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private authService: AuthService,
    private emitterService: EmitterService,
    private toastService: ToastService,
    private httpService: HttpService,
    private auth: AuthService,
    private primengConfig: PrimeNGConfig,
    private filterService: FilterService,
    private ngxLoader: NgxUiLoaderService,
    private coachTeamsService: CoachTeamsService,
    private router: Router
  ) {
    this.emitterService.meEmitter.subscribe((user) => {
      this.user = user;
      console.log(user.id);
      this.ngxLoader.stop();
    });
  }

  ngOnInit(): void {
    this.getTeam();
  }

  async getTeam(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    console.log(userId);
    this.coachTeamsService.getTeams(userId).subscribe((teams) => {
      this.teams = teams;
      console.log(teams);
    });
  }

  applyFilterGlobal($event, stringVal) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
    console.log($event.target);
  }


  clear(table: Table) {
    table.clear();
}

  showAddTeam(): void {
    const ref = this.dialogService.open(AddTeamComponent, {
      header: 'Add Team',
      width: '600px',
      closable: false,
      contentStyle: { 'overflow-y': 'scroll' },
    });
    console.log('works');
    ref.onClose.subscribe(() => {
      this.getTeam();
    });
  }

  showEditTeam(id): void {
    const ref = this.dialogService.open(EditTeamComponent, {
      header: 'Edit Team',
      width: '600px',
      closable: false,
      contentStyle: { 'overflow-y': 'scroll' },
      data: {
        id,
      },
    });
    console.log('works');
    ref.onClose.subscribe(() => {
      this.getTeam();
    });
  }

  ageGradeDisplay(input : string) {
    if(input != null) {
      return input.replace('_', ' ')
    }
  }


  onDelete(): void {
    if (this.selectedTeams.length === 0) {
      this.toastService.errorMsg({ message: 'Please select a team to delete' });
    } else {
      this.selectedTeams.forEach((selectedTeam) => {
        this.coachTeamsService
          .deleteTeam(selectedTeam.id)
          .subscribe(
            () =>
              (this.teams = this.teams.filter((t) => t.id !== selectedTeam.id))
          );
      });
      this.toastService.successMsg({ message: 'Team Deleted' });
    }
  }

  goToPlayers(teamId: any) {
    sessionStorage.setItem('teamId', teamId);
    this.router.navigate(['coach-dashboard/players/' + teamId]);
  }

  goTopage() {
    this.router.navigate(['coach-dashboard/page']);
  }

  goToCoach() {
    this.router.navigate(['coach-dashboard/coaches']);
  }
}
