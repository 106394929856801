import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// PRIME
import { DynamicDialogRef } from 'primeng/dynamicdialog';

// SERVICES
import {
  SessionStorageService,
  HttpService,
  EmitterService,
} from '@services/index';

// MODELS
import { User } from '@models/user';
import { Organization } from '@models/organization';

// ENV
import { environment } from '@env/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-login-success-modal',
  templateUrl: './login-success-modal.component.html',
  styleUrls: ['./login-success-modal.component.scss'],
})
export class LoginSuccessModalComponent implements OnInit {
  user: User;

  constructor(
    public ref: DynamicDialogRef,
    private router: Router,
    private httpService: HttpService,
    private emitterService: EmitterService,
    private sessionStorageService: SessionStorageService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.user = this.emitterService.userData;

    if (!this.user) {
      this.httpService.me().subscribe(
        (user: User) => {
          this.user = user;
        },
        (err) => {}
      );
    }
  }

  close(): void {
    this.ref.close();
  }

  gotoDashboard(): void {
    // if (this.user.userType === 'director') {
    //   this.gotoDirectorDashboard();
    // } else {
    //   this.gotoCoachDashboard();
    // }
    this.ngxLoader.start();

    switch (this.user.userType) {
      case 'director':
        this.gotoDirectorDashboard();
        break;
      case 'coach':
        this.gotoCoachDashboard();
        break;
      case 'staff':
        this.gotoStaffDashboard();
        break;
      default:
        console.log('*******************************************');
        console.log('*******************************************');
        console.log('USER TYPE INVALID', this.user.userType);
        console.log('*******************************************');
        console.log('*******************************************');
    }
  }

  gotoDirectorDashboard(): void {
    this.httpService
      .get({
        url: environment.api.org,
        payload: {
          userId: this.user.id,
        },
      })
      .subscribe(
        (org: Organization) => {
          this.ref.close();

          this.sessionStorageService.setSession('org', org);
          this.emitterService.orgEmit(org);

          if (org.completed) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/dashboard/organization-settings/info']);
            this.emitterService.pageNameEmit('Organization Settings');
          }
        },
        (err) => {}
      );
  }

  gotoCoachDashboard(): void {
    this.router.navigate(['/coach-dashboard']);
    this.ref.close();
  }

  gotoStaffDashboard(): void {
    this.router.navigate(['/staff-dashboard']);
    this.ref.close();
  }
}
