import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Court } from '@core/models/courts';
import { Division } from '@core/models/division';
import { DivisionGroupings } from '@core/models/division-groupings';
import { Games } from '@core/models/games';
import { GeneralInfo } from '@core/models/gen-info';
import { Organization } from '@core/models/organization';
import { Photo } from '@core/models/photo';
import { Registration } from '@core/models/registration';
import { Teams } from '@core/models/teams';
import { Tournament } from '@core/models/tournament';
import { Venue } from '@core/models/venue';
import { environment } from '@env/environment';
import { rejects } from 'assert';
import { resolve } from 'dns';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';
import { log } from 'console';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';

interface tab {
  index: number;
  isEdit: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class TournamentsService {
  generalInfo$ = new BehaviorSubject<GeneralInfo>({
    name: '',
    ageGroups: null,
    gradeGroups: null,
    published: false,
    base64Photo: '',
  });

  isLoading$ = new BehaviorSubject<boolean>(true);
  registration$ = new BehaviorSubject<Registration>(null);
  venues$ = new BehaviorSubject<Venue>(null);
  division$ = new BehaviorSubject<Division>(null);
  division = new BehaviorSubject<Division>(null);
  divisionList$ = new BehaviorSubject<Division[]>(null);
  venueList$ = new BehaviorSubject<Venue[]>(null);
  venueListGlobal$ = new BehaviorSubject<Venue[]>(null);
  court$ = new BehaviorSubject<Court>(null);
  publicGames$ = new BehaviorSubject<Games[]>(null);
  venueInformation: Venue;
  currentOrganization: Organization;
  generalInformation: GeneralInfo;
  generalInformation$ = new BehaviorSubject<GeneralInfo>(null);
  divisionInformation: Division;
  registrationInformation: Registration;
  gamesList$ = new BehaviorSubject<Games[]>(null);
  games$ = new BehaviorSubject<Games>(null);
  reloadTournament = new BehaviorSubject<boolean>(false);
  reloadDivision = new BehaviorSubject<boolean>(false);
  tournament$ = new BehaviorSubject<Tournament>(null);
  tournamentId$ = new BehaviorSubject<number>(null);
  tournament: Tournament;
  tournaments$ = new BehaviorSubject<Tournament[]>(null);
  tournamentDateId$ = new BehaviorSubject<number>(null);
  isVenuesSetupDone$ = new BehaviorSubject<boolean>(false);
  publishTournament$ = new BehaviorSubject<Tournament>(null);
  poolTeams$ = new BehaviorSubject<Teams[]>(null);
  poolTeam$ = new BehaviorSubject<Teams>(null);
  divisionGroups$ = new BehaviorSubject<DivisionGroupings[]>(null);
  divisionGroup$ = new BehaviorSubject<DivisionGroupings>(null);
  tournamentPhoto$ = new BehaviorSubject<Photo>(null);
  hasDivisionForm$ = new BehaviorSubject<boolean>(null);
  tabIndex$ = new BehaviorSubject<tab>({
    index: 0,
    isEdit: false,
  });
  existingVenues$ = new BehaviorSubject<Venue>(null);
  existingVenues: Venue;
  isAddDiv: boolean = false;

  tournaments: Tournament;
  finalTournamentId: number;

  constructor(
    private _http: HttpService,
    private httpClient: HttpClient,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.getAll();
  }

  getAll() {
    this.getOrg();
    this.getGenInfo();
    this.getRegInfo();
    this.getVenues();
    this.getDivisions();
    this.getExsiting();
  }

  setAddDiv() {
    this.isAddDiv = true;
  }

  validate(steps: number) {
    return new Promise((resolve, reject) => {
      switch (steps) {
        case 0:
          this.postGeneralInfo(false)
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              reject(e);
            });
          break;
        case 1:
          resolve(true);

          break;
        case 2:
          resolve(true);

          break;
        case 3:
          resolve(true);

        default:
          break;
      }
    });
  }

  clearGames() {
    this.gamesList$.next([]);
  }

  getTournamentById() {
    return new Promise((resolve, reject) => {
      this.finalTournamentId = +this.getSessionTournamentId();
      this._http
        .get({
          url: `${environment.api.tournaments}/${this.finalTournamentId}`,
        })
        .toPromise()
        .then((res) => {
          resolve(true);
          this.tournament$.next(res);
          this.tournaments = res;
        });
    }).catch((err) => {
      console.log('error...');
      rejects(err);
    });
  }

  getTournamentByParams(id) {
    return new Promise((resolve, reject) => {
      this.finalTournamentId = +this.getSessionTournamentId();
      this._http
        .get({
          url: `${environment.api.tournaments}/${id}`,
        })
        .toPromise()
        .then((res) => {
          resolve(true);
          this.tournament$.next(res);
          this.tournaments = res;
        });
    }).catch((err) => {
      console.log('error...');
      rejects(err);
    });
  }

  postGeneralInfo(isPhotoUploadOnly: boolean): Promise<any> {
    console.log('geninfo', this.generalInformation);
    this.getOrg();
    console.log('THISSS ISSS ORG', JSON.parse(sessionStorage.getItem('org')));

    this.generalInformation.startDate = this.datePipe.transform(
      this.generalInformation.startDate,
      'yyyy-MM-ddThh:mm:ss.SSSZZZZZ'
    );
    this.generalInformation.endDate = this.datePipe.transform(
      this.generalInformation.endDate,
      'yyyy-MM-ddThh:mm:ss.SSSZZZZZ'
    );
    // this.generalInformation.description = 'try ulet';

    let genInfoDuplicate = this.generalInformation;

    let message;
    if (isPhotoUploadOnly) {
      message = 'Photo Saved Successfully.';
    } else {
      message = 'Tournament Saved Successfully.';
    }

    return new Promise((resolve, reject) => {
      const data = {
        ...this.generalInformation,
        organization: this.currentOrganization,
      };
      console.log('dataa', data);

      this._http
        .post({
          url: environment.api.tournaments,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              this.tournaments = res;
              this.setSessionTournamentId(this.tournaments.id);
              resolve(res);
              this.toastService.toast('Success!', message, 'success');
            } else {
              reject(false);
              this.toastService.toast(
                'Failed',
                'Failed Creating General Information',
                'error'
              );
            }
          },
          (_) => {
            reject(false);
            this.toastService.toast(
              'Failed',
              'Something went wrong when saving the general information',
              'error'
            );
          }
        );
    });
  }

  deleteTournamentAccessToUser(tournamentId: number, userId: number): void {
    const data = {
      tournamentId: tournamentId,
      userId: userId,
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        tournamentId: tournamentId,
        userId: userId,
      },
    };
    this.httpClient.delete(environment.api.event, options).subscribe(
      (res: any) => {
        this.toastService.toast(
          'Success',
          'Tournament access successfully deleted.',
          'success'
        );
      },
      (_) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while deleting the tournament access',
          'error'
        );
      }
    );
  }

  addTournamentAccessToUser(
    tournamentId: number,
    userId: number
  ): Promise<any> {
    const data = {
      tournamentId: tournamentId,
      userId: userId,
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        tournamentId: tournamentId,
        userId: userId,
      },
    };

    return new Promise((resolve, reject) => {
      this._http
        .post({
          url: environment.api.event,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            console.log('RESSS', res);
            resolve(res);
            this.toastService.toast(
              'Success',
              'Tournament access successfully added.',
              'success'
            );
          },
          (_) => {
            reject(false);
            this.toastService.toast(
              'Failed',
              'Something went wrong while adding the tournament access',
              'error'
            );
          }
        );
    });
  }

  publishTourna(message: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = {
        ...this.generalInformation,
        organization: this.currentOrganization,
      };
      this._http
        .post({
          url: environment.api.tournaments,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              this.tournaments = res;
              this.setSessionTournamentId(this.tournaments.id);
              resolve(true);
              this.toastService.toast(
                'Success!',
                `Tournament ${message} Successfully.`,
                'success'
              );
            } else {
              reject(false);
              this.toastService.toast(
                'Failed',
                'Failed Creating Tournament',
                'error'
              );
            }
          },
          (_) => {
            reject(false);
            this.toastService.toast(
              'Failed',
              'Something went wrong while publishing the tournament',
              'error'
            );
          }
        );
    });
  }

  postTournament(): Promise<any> {
    return new Promise((resolve, reject) => {
      const data = {
        ...this.generalInformation,
        organization: this.currentOrganization,
        // published: true,
      };
      this._http
        .post({
          url: `${environment.api.tournaments}/publish/${this.tournaments.id}`,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              this.tournaments = res;
              this.setSessionTournamentId(this.tournaments.id);
              resolve(true);
              this.toastService.toast(
                'Success!',
                `Tournament Posted Successfully!`,
                'success'
              );
            } else {
              reject(false);
              this.toastService.toast(
                'Failed',
                'Failed Creating Tournament',
                'error'
              );
            }
          },
          (_) => {
            reject(false);
            this.toastService.toast(
              'Failed',
              'Something went wrong while posting the tournament',
              'error'
            );
          }
        );
    });
  }

  postRegistration(genInfo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let data = {
        ...this.registrationInformation,
        tournament: genInfo,
      };

      this._http
        .post({
          url: environment.api.tournamentRegistration,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              this.registrationInformation = res;
              resolve(res);
              this.toastService.toast(
                'Success!',
                'Registration Information Successfully Updated.',
                'success'
              );
            } else {
              reject(res);
              this.toastService.toast(
                'Failed',
                'Failed Creating Registration Information',
                'error'
              );
            }
          },
          (_) => {
            reject(false);
            this.toastService.toast('Failed', 'Something went wrong', 'error');
          }
        );
    });
  }

  getDivisionList(): void {
    this.finalTournamentId = +this.getSessionTournamentId();
    this.getTournamentById()
      .then((response) => {
        this._http
          .get({
            url: `${environment.api.divisions}?tournamentId=${this.finalTournamentId}`,
            payload: [],
          })
          .toPromise()
          .then((res: any) => {
            this.divisionList$.next(res);
          });
      })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while getting the divisions of a tournament',
          'error'
        );
      });
  }

  getDivision(): void {
    this.finalTournamentId = +this.getSessionTournamentId();
    this.getTournamentById()
      .then((response) => {
        this._http
          .get({
            url: `${environment.api.divisions}/${this.finalTournamentId}`,
            payload: [],
          })
          .toPromise()
          .then((res: any) => {
            this.division$.next(res);
          });
      })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while getting the divisions',
          'error'
        );
      });
  }
  postDivisionSorted(data: any) {
    this._http
      .post({
        url: `${environment.api.divisions}/sort`,
        payload: data,
      })
      .toPromise()
      .then((res: any) => { })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while rearranging the order of the divisions.  Please try again.',
          'error'
        );
        this.getDivisionList();
      });
  }
  getDivisionListFilter(tournamentId: any): void {
    this.getTournamentById()
      .then((response) => {
        this._http
          .get({
            url: `${environment.api.divisions}?tournamentId=${tournamentId}`,
            payload: [],
          })
          .toPromise()
          .then((res: any) => {
            this.divisionList$.next(res);
          });
      })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong when getting the filtered divisions',
          'error'
        );
      });
  }

  postDivisions() {
    let id = this.divisionInformation?.id;
    let data;
    if (this.divisionInformation.group == 'GRADE') {
      data = {
        abbreviation: this.divisionInformation.abbreviation,
        entryFee: this.divisionInformation.entryFee,
        gender: this.divisionInformation.gender,
        maxTeams: this.divisionInformation.maxTeams ?? '9999',
        name: this.divisionInformation.name,
        resultsMessage: this.divisionInformation.resultsMessage,
        description: this.divisionInformation?.description,
        scheduleColor: this.divisionInformation.scheduleColor,
        textColor: this.divisionInformation.textColor,
        version: 0,
        tournament: this.tournaments,
        gameDuration: this.divisionInformation.gameDuration,
        numPeriod: this.divisionInformation.numPeriod,
        timePerPeriod: this.divisionInformation.timePerPeriod,
        fullTimeOuts: this.divisionInformation.fullTimeOuts,
        thirtySecTimeOuts: this.divisionInformation.thirtySecTimeOuts,
        foulsToOneBonus: this.divisionInformation.foulsToOneBonus,
        foulsToTwoBonus: this.divisionInformation.foulsToTwoBonus,
        foulsReset: this.divisionInformation.foulsReset,
        personalFouls: this.divisionInformation.personalFouls,
        foulsResetOnOvertime: this.divisionInformation.foulsResetOnOvertime,
        timeoutsReset: this.divisionInformation.timeoutsReset,
        timeoutsResetOnOvertime:
          this.divisionInformation.timeoutsResetOnOvertime,
        numOvertimeTimeouts: this.divisionInformation.numOvertimeTimeouts,
        id:
          this.divisionInformation.id == null ? 0 : this.divisionInformation.id,
        gradeGroup:
          this.divisionInformation.group == 'GRADE'
            ? this.divisionInformation.gradeGroup
            : '',
      };
    } else {
      data = {
        abbreviation: this.divisionInformation.abbreviation,
        entryFee: this.divisionInformation.entryFee,
        gender: this.divisionInformation.gender,
        maxTeams: this.divisionInformation.maxTeams ?? '9999',
        name: this.divisionInformation.name,
        resultsMessage: this.divisionInformation.resultsMessage,
        description: this.divisionInformation?.description,
        scheduleColor: this.divisionInformation.scheduleColor,
        textColor: this.divisionInformation.textColor,
        version: 0,
        tournament: this.tournaments,
        gameDuration: this.divisionInformation.gameDuration,
        numPeriod: this.divisionInformation.numPeriod,
        timePerPeriod: this.divisionInformation.timePerPeriod,
        fullTimeOuts: this.divisionInformation.fullTimeOuts,
        thirtySecTimeOuts: this.divisionInformation.thirtySecTimeOuts,
        foulsToOneBonus: this.divisionInformation.foulsToOneBonus,
        foulsToTwoBonus: this.divisionInformation.foulsToTwoBonus,
        foulsReset: this.divisionInformation.foulsReset,
        foulsResetOnOvertime: this.divisionInformation.foulsResetOnOvertime,
        timeoutsReset: this.divisionInformation.timeoutsReset,
        timeoutsResetOnOvertime:
          this.divisionInformation.timeoutsResetOnOvertime,
        numOvertimeTimeouts: this.divisionInformation.numOvertimeTimeouts,
        id:
          this.divisionInformation.id == null ? 0 : this.divisionInformation.id,
        ageGroup:
          this.divisionInformation.group == 'AGE'
            ? this.divisionInformation.ageGroup
            : '',
      };
    }

    return new Promise((resolve, reject) => {
      this._http
        .post({
          url: environment.api.divisions,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              // resolve(true);
              this.toastService.toast(
                'Success',
                !id ? 'The division was created.' : 'The division was updated.'
              );
              this.getDivisionList();
            } else {
              // reject(false);
              this.toastService.toast(
                'Failed',
                'Creating the division failed.',
                'error'
              );
            }
          },
          (_) => {
            // reject(false);
            this.toastService.toast(
              'Failed',
              'Something went wrong when saving/updating the division',
              'error'
            );
          }
        );
    });
  }

  subscribeGenInfo() {
    this.generalInfo$.subscribe((res) => {
      this.generalInformation = res;
    });
  }

  subscribeRegistrationInfo() {
    this.registration$.subscribe((res) => {
      this.registrationInformation = res;
    });
  }

  getOrg(): void {
    this.currentOrganization = JSON.parse(sessionStorage.getItem('org'));
  }

  getGenInfo(): void {
    this.generalInfo$.subscribe((res) => (this.generalInformation = res));
  }

  getRegInfo(): void {
    this.registration$.subscribe((res) => {
      this.registrationInformation = res;
    });
  }

  getVenues(): void {
    this.venues$.subscribe((res) => (this.venueInformation = res));
  }

  getExsiting(): void {
    this.existingVenues$.subscribe((res) => (this.existingVenues = res));
  }

  getDivisions(): void {
    this.division$.subscribe((res) => {
      this.divisionInformation = res;
    });
  }

  deleteDivisions(id: number) {
    this._http
      .delete({
        url: `${environment.api.divisions}/${id}`,
      })
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastService.toast(
              'Success',
              'The Division was successfully deleted.',
              'success'
            );
            this.reloadDivision.next(true);
          } else {
            this.toastService.toast(
              'Failed',
              'The division was not deleted',
              'error'
            );
          }
        },
        (_) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while deleting the division',
            'error'
          );
        }
      );
  }

  deleteTournament(id: number): void {
    this._http
      .delete({
        url: `${environment.api.tournaments}/${id}`,
      })
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastService.toast(
              'Success',
              'Tournament successfully deleted.',
              'success'
            );
            this.reloadTournament.next(true);
          } else {
            this.toastService.toast(
              'Failed',
              'Tournament not deleted',
              'error'
            );
          }
        },
        (_) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while deleting the tournament',
            'error'
          );
        }
      );
  }

  setSessionTournamentId(id: number): void {
    sessionStorage.setItem('tournamentId', id.toString());
    this.tournamentId$.next(id);
  }

  getSessionTournamentId(): string {
    return sessionStorage.getItem('tournamentId');
  }

  getSessionOrg(): Organization {
    return JSON.parse(sessionStorage.getItem('org'));
  }
  getSessionOrganization(): string {
    let org;
    org = sessionStorage.getItem('org');
    org = JSON.parse(org);
    return org['id'];
  }

  getAssignedGames(tournamentId, date, divisionId, poolId) {
    let url = `${environment.api.games}/assigned?tournamentId=${tournamentId}`;

    if (poolId && date) {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}&date=${date}&divisionId=${divisionId}&poolId=${poolId}`;
    } else if (poolId && !date) {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}&divisionId=${divisionId}&poolId=${poolId}`;
    } else if (divisionId && date) {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}&date=${date}&divisionId=${divisionId}`;
    } else if (divisionId && !date) {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}&divisionId=${divisionId}`;
    } else if (!divisionId && !poolId && date) {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}&date=${date}`;
    } else if (poolId && divisionId && date) {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}&date=${date}&divisionId=${divisionId}&poolId=${poolId}`;
    } else {
      url = `${environment.api.games}/assigned?tournamentId=${tournamentId}`;
    }

    return this._http
      .get({
        url: url,
        payload: [],
      })
      .toPromise()
      .then((res: any) => {
        this.gamesList$.next(res);
      })
      .catch((err) => { });
  }

  async editAssignedGames(data: any) {
    await this._http
      .post({
        url: `${environment.api.games}/edit`,
        payload: data,
      })
      .toPromise()
      .then((res: any) => {
        this.toastService.toast('Success!', 'Edit successful', 'success');
      })
      .catch((err) => {
        console.log('err: ', err);
        this.toastService.toast('Failed', 'Score was not posted', 'error');
        throw err;
      });
  }

  uploadTeamsCSV(formData: FormData) {
    const url = `${environment.api.tournaments}/teams/csv`;
    return this._http.post({ url: url, payload: formData });
  }

  uploadPlayersCSV(formData: FormData) {
    const url = `${environment.api.tournaments}/players/csv`;
    return this._http.post({ url: url, payload: formData });
  }

  uploadCoachesCSV(formData: FormData) {
    const url = `${environment.api.tournaments}/coaches/csv`;
    return this._http.post({ url: url, payload: formData });
  }

  exportTeamsCSV(tournamentId: number) {
    const url = `${environment.api.tournaments}/teams/csv?tournamentId=${tournamentId}`;
    return this._http.getBytes({ url: url, payload: {} });
  }

  exportPlayersCSV(tournamentId: number) {
    const url = `${environment.api.tournaments}/players/csv?tournamentId=${tournamentId}`;
    return this._http.getBytes({ url: url, payload: {} });
  }

  exportCoachesCSV(tournamentId: number) {
    const url = `${environment.api.tournaments}/coaches/csv?tournamentId=${tournamentId}`;
    return this._http.getBytes({ url: url, payload: {} });
  }

  async postGames(gameId) {
    this._http
      .post({
        url: `${environment.api.games}/post/${gameId}`,
      })
      .toPromise()
      .then((res: any) => {
        this.toastService.toast(
          'Success!',
          'Game was posted successfully',
          'success'
        );
      })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while posting the game',
          'error'
        );
      });
  }

  async getAllTournaments() {
    try {
      const user = await this._http.me().toPromise();
      this._http
        .get({
          url: `${environment.api.usersTournaments}?userId=${user.id}`,
          payload: [],
        })
        .toPromise()
        .then((res: any) => {
          this.tournaments$.next(res);
        })
        .catch((err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the tournaments of the director',
            'error'
          );
        });
    } catch (err) { }
  }

  async editTournament(id: any) {
    try {
      this._http
        .get({
          url: `${environment.api.tournaments}/${id}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          this.generalInformation$.next(res);
        })
        .catch((err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while editing the tournament',
            'error'
          );
        });
    } catch (err) { }
  }

  async getTournament(id: any) {
    try {
      return new Promise((resolve, reject) => {
        this._http
          .get({
            url: `${environment.api.tournaments}/${id}`,
            payload: {},
          })
          .toPromise()
          .then((res: any) => {
            this.generalInformation$.next(res);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            this.toastService.toast(
              'Failed',
              'Something went wrong while getting the tournament',
              'error'
            );
          });
      });
    } catch (err) { }
  }

  fetchTournament(tournamentId: string | number) {
    return new Promise((resolve, reject) => {
      this._http
        .get({
          url: `${environment.api.tournaments}/${tournamentId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while fetching the tournament',
            'error'
          );
        });
    });
  }

  async getRegistration(tournamentId: any) {
    try {
      this._http
        .get({
          url: `${environment.api.tournamentRegistration}?tournamentId=${tournamentId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          this.registration$.next(res);
        })
        .catch((err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the registration info of the tournament',
            'error'
          );
        });
    } catch (err) { }
  }

  async getPoolTeams(poolId: any) {
    return new Promise((resolve, reject) => {
      try {
        this._http
          .get({
            url: `${environment.api.poolTeams}?poolId=${poolId}`,
            payload: {},
          })
          .toPromise()
          .then((res: any) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
            this.toastService.toast(
              'Failed',
              'Something went wrong while getting the pool and team groupings',
              'error'
            );
          });
      } catch (err) { }
    });
  }

  async getDivisionGroupings(tournamentId: any) {
    try {
      this._http
        .get({
          url: `${environment.api.teamsDivisionGroupings}?tournamentId=${tournamentId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          this.divisionGroups$.next(res);
        })
        .catch((err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the team and division groupings',
            'error'
          );
        });
    } catch (err) { }
  }

  searchAddress(address: string): any {
    const geocoder = new google.maps.Geocoder();
    const geocoderRequest = { address };
    try {
      return geocoder.geocode(geocoderRequest, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          console.log('asdasd ', results);
          return results;
        } else {
        }
      });
    } catch (e) {
      this.toastService.toast(
        'Failed',
        'Something went wrong while searching for the address of a venue',
        'error'
      );
    }
  }

  getCourts(venueId: any) {
    return new Promise((resolve, reject) => {
      this.finalTournamentId = +this.getSessionTournamentId();
      this._http
        .get({
          url: `${environment.api.courts}?tournamentId=${this.finalTournamentId}&venueId=${venueId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the courts of a venue',
            'error'
          );
        });
    });
  }
  catch(err) { }

  async getAllGames(tournamentId: any, divisionId: any, poolId: any) {
    let url = `${environment.api.games}?tournamentId=${tournamentId}`;
    if (divisionId) url += `&divisionId=${divisionId}`;
    if (poolId) url += `&poolId=${poolId}`;

    try {
      this._http
        .get({
          url: url,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          this.publicGames$.next(res);
        })
        .catch((err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the games',
            'error'
          );
        });
    } catch (err) { }
  }

  /**
   * This is temporary. Please refactor this service to make useable in other areas of the app.
   * @param tournamentId
   * @returns
   */
  async getTourney(tournamentId: number): Promise<Tournament> {
    const tourney: Tournament = await this._http
      .get({
        url: `${environment.api.tournaments}/${tournamentId}`,
      })
      .toPromise();
    return tourney;
  }

  async publishTournament(tournamentId: string): Promise<Tournament> {
    try {
      return new Promise((resolve, reject) => {
        this._http
          .post({
            url: `${environment.api.tournaments}/publish/${tournamentId}`,
            payload: this.tournament,
          })
          .toPromise()
          .then((tourney) => {
            this.toastService.toast(
              'Success!',
              'Tournament Published Successfully',
              'success'
            );
            this.tournament$.next(tourney);
            this.tournaments = tourney;

            resolve(tourney);
          });
      });
    } catch (err) {
      this.toastService.toast(
        'Failed',
        'Something went wrong while publishing the tournament',
        'error'
      );
      return;
    }
  }

  async setTournamentStatus(data: any): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        console.log('data', data);

        this._http
          .post({
            url: `${environment.api.tournamentStatus}`,
            payload: data,
          })
          .toPromise()
          .then((tourney) => {
            this.toastService.toast(
              'Success!',
              'Settings Updated Successfully',
              'success'
            );
            this.tournament$.next(tourney);
            this.tournaments = tourney;

            resolve(tourney);
          });
      });
    } catch (err) {
      this.toastService.toast(
        'Failed',
        'Something went wrong while publishing the tournament',
        'error'
      );
      return;
    }
  }
  async getTournamentStatus(tournamentId: number): Promise<any> {
    try {
      return new Promise((resolve, reject) => {
        this._http
          .get({
            url: `${environment.api.tournamentStatus}/${tournamentId}`,
          })
          .toPromise()
          .then((tourney) => {
            resolve(tourney);
          });
      });
    } catch (err) {
      this.toastService.toast(
        'Failed',
        'Something went wrong getting the tournament status',
        'error'
      );
      return;
    }
  }

  getTournamentToPublish(tournament) {
    this.tournament = tournament;
  }

  async savetournamentSchedule(tournament: any) {
    this._http
      .post({
        url: `${environment.api.tournamentSchedule}`,
        payload: tournament,
      })
      .toPromise()
      .then((res: any) => {
        this.toastService.toast(
          'Success!',
          'Timeslot was added successfully',
          'success'
        );
      })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while adding the timeslot',
          'error'
        );
      });
  }
}
