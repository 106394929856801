<div class="public-venue">
  <div class="header-container">
    <div class="nav">
      <app-public-header *ngIf="!isReview"></app-public-header>
    </div>
  </div>
  <div
    class="detail-header"
    [style.backgroundColor]="primaryColor"
    [style.color]="fontColor"
  >
    <div class="back" (click)="goToTournamentPublicPage()">
      <i class="pi pi-arrow-left"></i>
    </div>
    <div class="tourney-image" *ngIf="venue?.photo; else noPhoto">
      <img class="image" alt="Logo" [src]="logoUrl" />
    </div>

    <ng-template #noPhoto>
      <div class="venue-map">
        <div>
          <agm-map
            style="width: 320px; height: 240px"
            [latitude]="venue?.lat"
            [longitude]="venue?.lng"
            [zoom]="14"
            [disableDefaultUI]="false"
            [scrollwheel]="null"
          >
            <agm-marker
              [latitude]="venue?.lat"
              [longitude]="venue?.lng"
            ></agm-marker>
          </agm-map>
        </div>
      </div>
    </ng-template>
    <div>
      <div class="line1">{{ venue?.name }}</div>
      <div class="line2">
        {{ venue?.address?.address1 }} {{ venue?.address?.address2 }}
      </div>
      <div class="line3">
        {{ venue?.address?.city }} {{ venue?.address?.state }}
      </div>
      <div class="line3">{{ venue?.additionalInformation }}</div>
    </div>
  </div>
  <div class="main-container">
    <app-venue-schedule
      [venueId]="venue?.id"
      [tournamentId]="tournamentId"
    ></app-venue-schedule>
  </div>
</div>
