import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styleUrls: ['./shared-modal.component.scss'],
})
export class SharedModalComponent implements OnInit {
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input() visible: boolean;
  @Input() modalTitle: string;
  @Input() modalContent: string;
  @Output() onHide: EventEmitter<any> = new EventEmitter();

  constructor() {}
  ngOnInit() {}

  closeModal() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
