<div class="container">
    <div #payment>
        <p-fieldset
          *ngIf="registration?.requirePayment != 'NOT_REQUIRED'"
          [toggleable]="true"
          [collapsed]="collapseDivision"
        >
          <p-header
            ><label class="py-3 TheBoldFont-Bold text-bold3 fs-6"
              >Payment</label
            ></p-header
          >
    
          <div class="p-col-12 py-5">
            <div *ngIf="teams.length == 0; else havePayingTeams">
              <div class="text-center">
                <label class="TheBoldFont-Bold fs-3">
                  No Teams Registered.
                </label>
              </div>
            </div>
    
            <ng-template #havePayingTeams>
              <div
                class="p-col-12 px-2"
                style="display: flex; flex-direction: row; width: 100%"
              >
                <div class="p-col-1"></div>
                <div class="p-col-3 p-md-3 text-center">
                  <label
                    class="fs-normal font10"
                    style="font-weight: bold; text-decoration: underline"
                    >Team(s)</label
                  >
                </div>
                <div class="p-col-3 p-md-4 text-center">
                  <label
                    class="fs-normal font10"
                    style="font-weight: bold; text-decoration: underline"
                    >Division</label
                  >
                </div>
                <div class="p-col-5 p-md-3 text-center">
                  <label
                    class="fs-normal font10"
                    style="font-weight: bold; text-decoration: underline"
                    >Payment</label
                  >
                </div>
                <div class="p-col-0 p-md-1"></div>
              </div>
              <div
                class="p-col-12"
                style="
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                "
                *ngFor="let tpc of teamPlayerCoaches; let i = index"
              >
                <div class="p-col-1">
                  <label class="fs-normal font10" style="font-weight: bold"
                    >{{ i + 1 }}:</label
                  >
                </div>
                <div class="p-col-3 p-md-3 text-center">
                  <label
                    class="fs-normal font10"
                    style="font-weight: bold"
                    >{{ tpc?.team?.name }}</label
                  >
                </div>
                <div class="p-col-3 p-md-4 text-center">
                  <label
                    class="fs-normal font10"
                    style="font-weight: bold"
                    >{{ tpc?.team?.division.name }}</label
                  >
                </div>
                <div
                  class="p-col-5 p-md-3 text-center"
                  formGroupName="payments"
                >
                  <div class="full-dropdown">
                    <p-dropdown
                      [options]="payments"
                      [formControlName]="generatePaymentFormCtrl(i)"
                      placeholder="Select Payment"
                      optionLabel="name"
                      styleClass="m-0"
                      (onChange)="calculateTotalPayment()"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="p-col-0 p-md-1"></div>
              </div>
              <div class="mt-3">
                <label class="fs-normal font10" style="font-weight: bold"
                  >Total: ${{ totalPayment }}</label
                >
              </div>
            </ng-template>
          </div>
    
          <div class="payment-container">
            <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Email
                <span
                  *ngIf="registration?.requirePayment != 'OPTIONAL'"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="ccname"
                type="text"
                class="tournament-input-text"
              />
              <div
                *ngIf="!isValidated && form.controls['ccname'].invalid"
                class="alert"
              >
                <label class="red-label"
                  >Credit Card Name is required.</label
                >
              </div>
            </div>
            <ngx-stripe-card
              [options]="cardOptions"
              [elementsOptions]="elementsOptions"
            ></ngx-stripe-card>
            <!-- <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Credit Card Number
                <span
                  *ngIf="registration?.requirePayment != 'OPTIONAL'"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="ccnumber"
                type="text"
                class="tournament-input-text"
                maxlength="16"
              />
              <div
                *ngIf="!isValidated && form.controls['ccnumber'].invalid"
                class="alert"
              >
                <label class="red-label"
                  >Credit Card Number is required.</label
                >
              </div>
            </div>
    
            <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Expiration Date
                <span
                  *ngIf="registration?.requirePayment != 'OPTIONAL'"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="ccexpiration"
                type="text"
                class="tournament-input-text"
                placeholder="MM/YYYY"
                maxlength="7"
              />
              <div
                *ngIf="
                  !isValidated && form.controls['ccexpiration'].invalid
                "
                class="alert"
              >
                <label class="red-label"
                  >Expiration Date is required.</label
                >
              </div>
            </div>
    
            <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Security Code
                <span
                  *ngIf="registration?.requirePayment != 'OPTIONAL'"
                  class="required-indicator"
                  >*</span
                >
              </label>
              <input
                formControlName="cccode"
                type="text"
                class="tournament-input-text"
                maxlength="3"
                placeholder="CVC/CVV"
              />
              <div
                *ngIf="!isValidated && form.controls['cccode'].invalid"
                class="alert"
              >
                <label class="red-label">Security Code is required.</label>
              </div>
              <div *ngIf="stripeError != ''" class="alert">
                <label class="red-label">{{ stripeError }}</label>
              </div>
            </div> -->
          </div>
        </p-fieldset>
      </div>
</div>