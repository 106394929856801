import { Component, Input, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

// SERVICES
import { HttpService, ToastService } from '@services/index';
import { TournamentsService } from '@core/services/tournaments.service';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { GRADE_GROUP_TYPES } from '@core/models/tournament-grade-group';
import { AGE_GROUP_TYPES } from '@core/models/tournament-age-group';
import { DropdownModule } from 'primeng/dropdown';

import { GENDER_TYPES_DIVISION } from '@models/gender-type';

import { environment } from '@env/environment';
import { DivisionService } from '@core/services/division.service';
import { Division } from '@core/models/division';

@Component({
  selector: 'app-bulk-edit-divs',
  templateUrl: './bulk-edit-divs.component.html',
  styleUrls: ['./bulk-edit-divs.component.scss'],
})
export class BulkEditDivsComponent implements OnInit {
  isFetchingDiv: boolean = true;
  divisions: any = [];
  division;
  data;
  timer;
  gender = GENDER_TYPES_DIVISION;
  selectedGender;
  isVisible: boolean = false;
  divisionIdToDelete: string;
  isModified = false;
  isShowUnsavedChangesPopup = false;
  group1Options = [
    { label: 'Age', value: 'ageGroup' },
    { label: 'Grade', value: 'gradeGroup' },
  ];
  group1Selected: string;
  group2Selected: string;
  ageGroupTypes: any[] = AGE_GROUP_TYPES;
  gradeGroupTypes: any[] = GRADE_GROUP_TYPES;
  divisionForm: FormGroup;
  isAgeGroup = false;
  isGradeGroup = false;
  selectedGroup1: string;

  frozenCols = [{ field: 'name', header: 'NAME' }];
  scrollableCols = [
    { field: 'abbreviation', header: 'ABBREVIATION' },
    { field: 'gender', header: 'GENDER' },
    { field: 'group1', header: 'GRADE/AGE' },
    { field: 'group2', header: 'GRADE/AGE GROUP' },
    { field: 'fee', header: 'ENTRY FEE' },
    { field: 'teams', header: 'MAX TEAMS' },
    // {field: 'message', header: 'PUBLIC RESULTS MESSAGE'},
    { field: 'color', header: 'SCHEDULE COLOR' },
    { field: 'text', header: 'TEXT COLOR' },
    // {field: 'block', header: 'GAME TIME BLOCK IN MINUTES'},
    // {field: 'periods', header: 'NUMBER OF PERIODS'},
    // {field: 'time period', header: 'TIME PER PERIOD IN MINUTES'},
    // {field: 'full timeout', header: 'NUMBER OF FULL TIMEOUTS'},
    // {field: '30 sec timeouts', header: 'NUMBER OF 30 SECOND TIMEOUTS'},
    { field: 'delete', header: '' },
  ];
  periods = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
  ];

  constructor(
    private httpService: HttpService,
    private tournamentService: TournamentsService,
    private fb: FormBuilder,
    private toastService: ToastService,
    public ref: DynamicDialogRef,
    private divisionService: DivisionService
  ) {}

  ngOnInit(): void {
    this.divisionForm = this.fb.group({
      group: [''],
    });

    this.divisionForm.valueChanges.subscribe((form: any) => {
      if (form.group === 'AGE') {
        this.isAgeGroup = true;
        this.isGradeGroup = false;
      } else if (form.group === 'GRADE') {
        this.isAgeGroup = false;
        this.isGradeGroup = true;
      } else {
        this.isAgeGroup = false;
        this.isGradeGroup = false;
      }
    });

    this.getDivisions();
  }

  async getDivisions() {
    this.getDivisionList();
  }

  getDivisionList() {
    setTimeout(() => {
      this.isFetchingDiv = true;
    }, 1000);
    this.tournamentService.divisionList$.subscribe((res) => {
      if (res != null) {
        this.divisions = res;
        this.isFetchingDiv = false;
      }

      console.log(this.divisions, 'aaaaa');
    });
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  updateDivision(division) {
    console.log(division);
    this.isModified = true;
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout (()=> {
    //     this.httpService
    //     .post({
    //       url: environment.api.divisions,
    //       payload: division,
    //     })
    //     .toPromise()
    //     .then((res: any) => {
    //     this.toastService.toast(
    //       'Success!',
    //       `${division.name} Updated Successfully!`,
    //       'success'
    //     );
    //     this.getDivisions();
    //   })
    //   .catch((err) => {
    //     this.toastService.toast('Failed', 'Something went wrong while mass-editing divisions', 'error');
    //   });
    //   }, 1000)
  }

  submitDivision(divisions: Division[]) {
    console.log('divisions', divisions);
    this.httpService
      .post({
        url: environment.api.divisionsBulk,
        payload: divisions,
      })
      .toPromise()
      .then((res: any) => {
        this.toastService.toast('Success!', `Updated Successfully!`, 'success');
        this.getDivisions();
        this.handleClose();
      })
      .catch((err) => {
        this.toastService.toast(
          'Failed',
          'Something went wrong while mass-editing divisions',
          'error'
        );
      });
    this.isModified = false;
  }

  deleteDivision(id) {
    console.log(id, 'deleted');
    this.httpService
      .delete({
        url: `${environment.api.divisions}/${id}`,
      })
      .subscribe(
        () => {
          this.divisions = this.divisions.filter((t) => t.id !== id);
          this.toastService.successMsg({ message: 'Division Deleted' });
          this.divisionService.reloadDivision.next();
        },
        (err) => {
          console.log(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while deleting division',
            'error'
          );
        }
      );
    this.isVisible = false;
    this.divisionIdToDelete = null;
  }

  handleClose(): void {
    if (this.isModified) {
      // show a popup
      this.isShowUnsavedChangesPopup = true;
    } else {
      this.ref.close();
    }
  }

  closePopUp(): void {
    this.ref.close();
  }

  hide() {
    this.isShowUnsavedChangesPopup = false;
  }

  showModal() {
    this.isVisible = true;
  }

  closeModal() {
    this.isVisible = false;
  }
  updateGroup1(event: any) {
    console.log('Group 1 selected:', event.value);
    this.group1Selected = event.value;
  }

  updateGroup2(event: any) {
    this.group2Selected = event.value;
  }
  onGroup1Change(event: any) {
    console.log('Selected group1:', event.value);
    this.selectedGroup1 = event.value;
  }
  getOptions(division: any, selectedGroup1Value: string): any[] {
    if (selectedGroup1Value === 'ageGroup') {
      // return options for ageGroup in group2
      return this.ageGroupTypes;
    } else if (selectedGroup1Value === 'gradeGroup') {
      // return options for gradeGroup in group2
      return this.gradeGroupTypes;
    } else {
      return [];
    }
  }
  getGroup(group: any) {
    console.log(group);
    return group;
  }
}
