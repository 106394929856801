import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// FIREBASE
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {

  constructor(
    public router: Router,
    public angularFireAuth: AngularFireAuth,
  ) {}

  getToken() {
    const token = sessionStorage.getItem('token');
    return JSON.parse(token);
  }
  setToken(token): void {
    if (token) sessionStorage.setItem('token', JSON.stringify(token));
  }
  getExpTime() {
    return sessionStorage.getItem('expTime');
  }
  setExpTime(): void {
    const timeNow = new Date().getTime();
    sessionStorage.setItem('expTime', JSON.stringify(timeNow));
  }

  getSession(val) {
    const newVal = sessionStorage.getItem(val);
    return JSON.parse(newVal);
  }
  setSession(val, data): void {
    sessionStorage.setItem(val, JSON.stringify(data));
  }
}
