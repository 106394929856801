import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Games } from '@core/models/games';
import { ToastService } from '@core/services';
import { TournamentsService } from '@core/services/tournaments.service';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-post-score',
  templateUrl: './post-score.component.html',
  styleUrls: ['./post-score.component.scss'],
})
export class PostScoreComponent implements OnInit {
  editForm: FormGroup;
  team1Score: number;
  team2Score: number;
  match: Games;
  query: any;
  gameEnded: Boolean;
  period: string;
  tournamentPeriod: number;

  fourPeriod = [ {key:"Q1", value:"Q1"},  {key:"Q2", value:"Q2"}, {key:"Q3", value:"Q3"}, {key:"Q4", value:"Q4"}];
  twoPeriod = [{key:"H1", value:"H1"}, {key:"H2", value:"H2"}];
  defaultPeriod = [{key:"1st", value:"1st"}, {key:"2nd", value:"2nd"}, {key:"3rd", value:"3rd"},{key:"4th", value:"4th"},{key:"5th", value:"5th"},{key:"6th", value:"6th"}];
  periodList: any[];
  selectedPeriod;

  constructor(
    private fb: FormBuilder,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private dialogService: DialogService,
    private tournamentService: TournamentsService,
    private toastrService: ToastService
  ) {

    this.match = this.config.data.match;
    this.query = this.config.data.query;
    this.team1Score = this.match.team1Score;
    this.team2Score = this.match.team2Score;
    this.gameEnded = this.match.gameEnded;
    this.selectedPeriod = this.match?.period
    this.tournamentPeriod = this.match.division.tournament.numPeriod ?? 0;
    
    this.initPeriodList();
  }
  initPeriodList() {

    if(this.tournamentPeriod == 4){
      this.periodList = this.fourPeriod;
    }else if(this.tournamentPeriod == 2 ){
      this.periodList = this.twoPeriod;
    }else{
      this.periodList = this.defaultPeriod;
    }
    this.selectedPeriod = this.periodList.find(period => period.value == this.selectedPeriod)
    console.log("selected period? ", this.selectedPeriod)
  }

  ngOnInit(): void {}

  editGames() {
    if (this.gameEnded) {
        this.selectedPeriod = this.selectedPeriod 
            ? this.periodList.find(period => period.key === this.selectedPeriod.key) 
            : { value: '' };
            
        this.ref.close({
            action: true, 
            team1Score: this.team1Score, 
            team2Score: this.team2Score,
            period: this.selectedPeriod.value,
            gameEnded: this.gameEnded
        });
    } else {
        if (this.team1Score > 0 && this.team2Score > 0 && (!this.selectedPeriod || this.selectedPeriod.value.trim() === '')) {
            this.toastrService.errorMsg({ message: 'Please Select a Period.' });
        } else if (this.selectedPeriod === null) {
            this.toastrService.errorMsg({ message: 'Please Select a Period.' });
        } else {
            this.selectedPeriod = this.periodList.find(period => period.key === this.selectedPeriod.key);
            
            this.ref.close({
                action: true, 
                team1Score: this.team1Score, 
                team2Score: this.team2Score,
                period: this.selectedPeriod.value,
                gameEnded: this.gameEnded
            });
        }
    }
}



  // fetchGames() {
  //   this.tournamentService.getAssignedGames(
  //     this.query?.tournamentId,
  //     this.query?.date,
  //     this.query?.divisionId,
  //     this.query?.poolId
  //   );
  // }

  close() {
    this.ref.close({action: false});
  }

  numericOnly(event: KeyboardEvent): boolean {
    const input = event.target as HTMLInputElement;
    const newValue = input.value + event.key;
    if (isNaN(Number(newValue))) {
      return false;
    }
    return true;
  }
}
