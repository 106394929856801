<div class="container-main p-5">
  <!-- <section class="sized-box"></section> -->
  <section>
    <img src="./assets/img/logo.svg" />
  </section>
  <section class="padding-adjustment">
    <a class="btn-link pr-2">Features</a>
    <a class="btn-link pr-2" (click)="goToPricingPage()">Pricing</a>
    <a class="btn-link pr-2">Browse Tournaments</a>
    <a class="btn-link pr-2" (click)="goToSupportPage()">Support</a>
  </section>
  <div *ngIf="true">
    <section class="disp-flex flex-center" *ngIf="isLogged && role; else loggedTemp">
      <button class="btn-rounded-red b-shadow mx-2" (click)="gotoDashboard()">
        Go to Dashboard
      </button>
      <button class="btn-rounded-red b-shadow" (click)="logout()">
        Log out
      </button>
    </section>
    <ng-template #loggedTemp>
      <section class="disp-flex flex-center">
         <button
        class="btn-rounded-red b-shadow mx-2"
        (click)="toggleLogIn($event, opLogins)"
      >
        Login
      </button>
      <button class="btn-rounded-white b-shadow mx-2" (click)="signup()">
        Sign Up
      </button>
      </section>
    </ng-template>
  </div>
  <!-- <section class="sized-box"></section> -->
</div>

<p-overlayPanel #opLogins>
  <ng-template pTemplate>
    <app-login [opLogin]="opLogin"></app-login>
  </ng-template>
</p-overlayPanel>
