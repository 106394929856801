import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmTeamComponent } from '@components/modal/confirm-team/confirm-team.component';
import { MessageComponent } from '@components/modal/message/message.component';
import { defConstant } from '@core/data/constant-data';
import { Teams } from '@core/models/teams';
import { HttpService, ToastService } from '@core/services';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

const VIEWABLE_SIZE = 15;

@Component({
  selector: 'app-add-existing-team',
  templateUrl: './add-existing-team.component.html',
  styleUrls: ['./add-existing-team.component.scss'],
})
export class AddExistingTeamComponent implements OnInit {
  headers: any = [
    {
      id: 'name',
      value: 'Team',
      textStyles: 'text-black3 text-bold1',
    },
    {
      id: 'ageGroup',
      value: 'Age Group',
    },
    {
      id: 'gradeGroup',
      value: 'Grade Group',
    },
    {
      id: 'gender',
      value: 'Gender',
    },
  ];

  data = null;
  teams: Teams[] = [];
  meta: any = {};
  q: string;
  fetching: boolean = false;
  showConfirm: boolean = false;
  selectedTeam: any;
  message: string = 'Team has a different gender category. Are you sure you want to proceed?';

  constructor(
    public ref: DynamicDialogRef,
    private httpService: HttpService,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private publicTournament: PublicTournamentsService,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;

    this.initTeams();
  }

  pageChanged(event: any) {}

  async initTeams() {
    const me = await this.httpService.me().toPromise();
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.coachTeams}?userId=${me.id}&action=init&index=1&viewableSize=${VIEWABLE_SIZE}`,
          payload: [],
        })
        .toPromise();

      this.teams = list;
      console.log(list);
      // this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }


  async getTeams(action: string, meta: any) {
    const me = await this.httpService.me().toPromise();
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.coachTeams}?userId=${me.id}&action=${action}&index=${meta.index}&viewableSize=${VIEWABLE_SIZE}&totalSize=${meta.totalSize}&ascending=true`,
          payload: [],
        })
        .toPromise();

      this.teams = list.items;
      this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }


  previous() {
    this.getTeams('previous', this.meta);
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    this.getTeams('index', meta);
  }

  next() {
    this.getTeams('next', this.meta);
  }


  search() {
    let meta: any = Object.assign({}, this.meta);
    meta.search = this.q;
    this.getTeams('search', meta);
  }

  async selectTeam(event: any) {
    const team: any = event.data;
    this.selectedTeam = team;

    const { division } = this.data.result;
    team.division = division;

    if (
      team.gender == division.gender ||
      team.gender.charAt(0) == division.gender
    ) {
      await this.httpService
        .post({
          url: `${environment.api.teamDivisions}/validate`,
          payload: {
            team: team,
            division,
          },
        })
        .toPromise()
        .then((res) => {
          if (res) {
            this.ref.close(team);
            this.publicTournament.addCoachTeam(team);
          } else {
            this.showConfirm = true;
          }
        });
    } else {
      this.showConfirm = true;
    }
  }

  close(): void {
    this.ref.close(null);
  }

  cancel(): void {
    this.showConfirm = false;
  }

  confirm(): void {
    this.ref.close(this.selectedTeam);
    this.publicTournament.addCoachTeam(this.selectedTeam);
  }
}
