<form [formGroup]="loginForm" class="p-4 login-wrapper" (ngSubmit)="login()">
  <h3 class="TheBoldFont-Bold m-0 fs-7">Login</h3>
  
  <div class="inputBox">
    <span style="position:absolute;">Email</span>
    <input
    formControlName="email"
    type="text"
    placeholder="Email"
  />
  </div>
  

  <section class="text-right py-2">
    <span class="login-placeholder text-right" (click)="showResetPassword()"
      >Forgot password?</span
    >
  </section>
  <div class="inputBox">
    <span style="position: absolute;">Password</span>
    <input
      formControlName="password"
      type="password"
      placeholder="Password"
    />
  </div>
  


  <section class="my-2">
    <p-checkbox label="Keep me signed in"></p-checkbox>
  </section>

  <div
    class="flex flex-center flex-column mt-4"
    *ngIf="showCancel; else hideCancel"
  >
    <div class="flex flex-center flex-row">
      <div class="">
        <button type="submit" class="btn-rounded-white" (click)="close()">
          Cancel
        </button>
        <button
          type="submit"
          class="btn-rounded-red"
          [disabled]="!loginForm.valid || isLoginClick"
        >
          Login
        </button>
      </div>
    </div>

    <section class="my-2">
      New to TourneyKey?
      <span (click)="signup()" class="login-placeholder">Create an Account</span>
    </section>
  </div>

  <ng-template #hideCancel>
    <div class="flex flex-center flex-column mt-4">
      <button
        type="submit"
        class="btn-rounded-red"
        [disabled]="!loginForm.valid || isLoginClick"
      >
        Login
      </button>

      <section class="my-2">
        New to TourneyKey?
        <span (click)="signup()" class="login-placeholder">Create an Account</span>
      </section>
    </div>
  </ng-template>
</form>
