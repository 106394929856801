<div class="venues">
    <div class="p-col-12 text-bold1">
        Please let us know which venues you are currently at?
    </div>

    <div class="p-col-12">
        <div class="p-grid">
            <div class="p-col-6 text-left">
                <div>
                    <input class="input-text my-2" placeholder="Search venue..." [(ngModel)]="q"
                        (keyup)="debounceSearch()" />
                </div>
            </div>
            <div class="p-col-6 text-right" style="margin-top: 20px">
                <button [disabled]="meta.index == 1" class="btn-link" (click)="previous()">
                    « Previous
                </button>
                <button [disabled]="meta.index == segment" *ngFor="let segment of meta.segments; let i = index"
                    class="btn-segment" [class.btn-segment-active]="meta.index == segment" (click)="navigate(segment)">
                    {{ segment }}
                </button>
                <button [disabled]="meta.index == meta.totalSegments || meta.totalSize === 0" class="btn-link"
                    (click)="next()">
                    Next »
                </button>
            </div>
        </div>
    </div>

    <p-table [value]="venues" [loading]="fetching" [(selection)]="selectedVenues" dataKey="id" responsiveLayout="scroll"
        scrollHeight="400px" selectionMode="single">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <!--<p-tableHeaderCheckbox></p-tableHeaderCheckbox>-->
                </th>
                <th style="width: 7rem">Venue</th>
                <th>Address</th>
                <th style="width: 5rem">Courts</th>
                <th style="width: 4rem">WiFi</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-venue>
            <tr>
                <td>
                    <p-tableRadioButton [value]="venue"></p-tableRadioButton>
                </td>
                <td>{{ venue?.name }}</td>
                <td>{{ venue?.address.fullAddress }}</td>
                <td style="text-align: center">{{ venue?.courtCount }}</td>
                <td style="text-align: center">{{ venue?.wifi }}</td>
            </tr>
        </ng-template>
    </p-table>

    <div *ngIf="isShowSelectMessage" class="select-venue-message flex flex-center mt-3">Please select a Venue</div>

    <div class="p-col-12 flex flex-center" [class.mt-4]="!isShowSelectMessage" [class.mt-1]="isShowSelectMessage">
        <button pButton type="button" label="Cancel"
            class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
            (click)="close()"></button>
        <button pButton type="button"
            class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
            (click)="confirmVenues()">
            <div *ngIf="isLoading; else notLoading">
                <p-progressSpinner [style]="{ width: '25px', height: '25px', color: 'white' }"></p-progressSpinner>
            </div>
            <ng-template #notLoading> Confirm </ng-template>
        </button>
    </div>
</div>