import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericInput]'
})
export class NumericInputDirective {

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const input = event.key;
    const isNumber = /^[0-9]$/.test(input);
    if (!isNumber) {
      event.preventDefault();
    }
  }

}
