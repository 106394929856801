<div class="py-2 ml-1 container-form p-grid">
    <div class="checkin-label p-col-12 mb-4">Thanks for checking in</div>
    <div class="p-col-12 mb-3">
        <div class="p-col-12 p-grid">
            <div class="tournament-label p-col-4">Tournament:</div>
            <div class="p-col-8">{{ tournamentName }}</div>
        </div>
        <div class="p-col-12 p-grid">
            <div class="division-label p-col-4">Division:</div>
            <div class="p-col-8">{{ divisionName }}</div>
        </div>
        <div class="p-col-12 p-grid">
            <div class="team-label p-col-4">Team:</div>
            <div class="p-col-8">{{ teamName }}</div>
        </div>
    </div>

    <div class="coach-label p-col-12">Coach(es) who checked in</div>

    <div class="p-col-12 mb-5">
        <p-table [loading]="isLoading" *ngIf="coaches.length > 0 || isLoading; else noCoaches" [value]="coaches"
            responsiveLayout="scroll" scrollHeight="400px">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 5rem">Coach</th>
                    <th style="width: 7rem">Venue</th>
                    <th style="width: 4rem">Checked-In Date</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-coach>
                <tr>
                    <td>{{ coach.coach.fullName }}</td>
                    <td>{{ coach.venue?.name }}</td>
                    <td>{{ coach.dateCheckedIn | date : "MM/dd/yyyy hh:mm a" }}</td>
                </tr>
            </ng-template>
        </p-table>
        <ng-template #noCoaches>
            <div class="no-coach-label">No coach(es)</div>
        </ng-template>
    </div>

    <div class="flex flex-center">
        <button type="submit" (click)="onOkay()" class="btn-rounded-red">
            OKAY
        </button>
    </div>
</div>