import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(public datePipe: DatePipe) {}

  serialize(obj: any) {
    Object.keys(obj).forEach(
      (key) => (obj[key] === undefined || obj[key] === null) && delete obj[key]
    );
    return Object.keys(obj)
      .map((k) => `?${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
      .join('&');
  }

  dateBasicFormat(date: Date) {
    return this.datePipe.transform(date, 'MMM d, y');
  }

  dateDefaultFormat(date: Date) {
    return this.datePipe.transform(date, 'MM/dd/yyyy');
  }

  dateFormatFromTo(fromDate: Date, toDate: Date) {
    return `${this.datePipe.transform(
      fromDate,
      'MMM d'
    )} - ${this.datePipe.transform(toDate, 'MMM d, y')}`;
  }

  dateConvert(date: string){
    if(date !== null){
      const splitDate = date.substring(0, 10).split('-')
    const newDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`
    return newDate
    } else {
      return date;
    }
  }
  dateConvertObject(date: string, time?: string): Date{
    return moment(date, 'MM/DD/YYYY').toDate();
  }
  dateToApi(date: string, time?: string){
    return moment(date,"MM/DD/YYYY").toISOString()
  }

  emailPattern() {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  fileReader(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
}
