import { Component, OnInit } from '@angular/core';
import { TeamDivision } from '@core/models/team-division';
import { HttpService, ToastService } from '@core/services';
import { TeamDivisionsService } from '@core/services/team-divisions.service';
import { environment } from '@env/environment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-payment-add-modal',
  templateUrl: './payment-add-modal.component.html',
  styleUrls: ['./payment-add-modal.component.scss']
})
export class PaymentAddModalComponent implements OnInit {

  paidAmount: number = 0;
  discount: number = 0;
  note: string = '';
  isLoading: boolean = false;
  _teamDivision: TeamDivision;

  constructor(
    public ref: DynamicDialogRef,
    private toastService: ToastService,
    private httpService: HttpService,
    public config: DynamicDialogConfig,
    private teamDivisionService: TeamDivisionsService,
  ) { }

  ngOnInit(): void {
    this._teamDivision = this.config.data.teamDivision;
    console.log("TEAM DIVISION", this._teamDivision);
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  submit() {
    this.isLoading = true;

    let teamDivision = this._teamDivision;
    teamDivision.paidAmount = this.paidAmount;
    teamDivision.discount = this.discount;
    teamDivision.paymentDate = new Date();
    teamDivision.note = this.note;

    this.toastService.infoMsg({ message: "Saving" });

    this.teamDivisionService
      .saveTeamDivision(teamDivision)
      .subscribe((teamDivision) => {
        this.teamDivisionService.teamDivision$.next(teamDivision);
        this.toastService.successMsg({
          message: 'Payments saved successfully',
        });
        this.isLoading = false;
        this.ref.close(true);
      });

    let body = {
      teamDivisionId: this._teamDivision.id,
      paymentDate: new Date(),
      paidAmount: this.paidAmount,
      note: this.note,
      discount: this.discount,
    };

    this.httpService
      .post({
        url: `${environment.api.checkInPayment}`,
        payload: body,
      })
      .subscribe((data) => {
        console.log('SAVED PAYMENT DATA', data);
      });
  }

  cancel() {
    this.ref.close(false);
  }

}
