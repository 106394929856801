<div class="sign-up-container">
  <div class="close-btn" style="float: right">
    <p-button (click)="cancel()" icon="pi pi-times"></p-button>
  </div>
  <form [formGroup]="signupForm" class="signup-wrapper text-center py-3">
    <div style="margin-bottom: 20px">
      <label class="sign-up-header">Let's Get Started</label>
    </div>

    <div class="">
      <div class="dropdown" style="margin-bottom: 5px">
        <span class="dropdown-label">Account Type</span>
        <p-dropdown
          [options]="userTypes"
          formControlName="userType"
          optionLabel="name"
          optionValue="type"
          autoWidth="false"
          styleClass=""
          placeholder="Account Type"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
        <small
          *ngIf="
            signupForm.controls['userType'].invalid &&
            (signupForm.controls['userType'].dirty ||
              signupForm.controls['userType'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['userType'].errors.required"
            class="mb-3 ml-2"
          >
            Account type is required.
          </div>
        </small>
      </div>

      <div
        class="container-fields"
        *ngIf="user == 'director'"
        style="margin-bottom: 20px"
      >
        <label
          class="mt-2 text-left disp-block mx-0"
          style="margin-bottom: 5px"
        >
          How many tournaments do you run annually?
        </label>
        <input
          formControlName="howManyTourneys"
          type="number"
          pInputText
          placeholder=""
          min="0"
          style="padding-left: 20px"
          class="tournament-input-text py-2"
        />
      </div>
      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span>First Name</span>
          <input
            formControlName="firstName"
            [ngClass]="{ 'valid-border': signupForm.get('firstName').valid }"
            type="text"
            minlength="2"
            maxlength="50"
            pInputText
            placeholder="First name: `John`"
            style="padding-left: 20px"
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['firstName'].invalid &&
            (signupForm.controls['firstName'].dirty ||
              signupForm.controls['firstName'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['firstName'].errors.required"
            class="mb-3 ml-2"
          >
            First name is required.
          </div>
          <div
            *ngIf="signupForm.controls['firstName'].errors.minlength"
            class="mb-3 ml-2"
          >
            First name must contain
            {{
              signupForm.controls["firstName"].errors.minlength.requiredLength
            }}
            characters.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span>Last Name</span>
          <input
            formControlName="lastName"
            [ngClass]="{ 'valid-border': signupForm.get('lastName').valid }"
            type="text"
            minlength="2"
            maxlength="50"
            pInputText
            placeholder="Last name: `Doe`"
            style="padding-left: 20px"
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['lastName'].invalid &&
            (signupForm.controls['lastName'].dirty ||
              signupForm.controls['lastName'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['lastName'].errors.required"
            class="mb-3 ml-2"
          >
            Last name is required.
          </div>
          <div
            *ngIf="signupForm.controls['lastName'].errors.minlength"
            class="mb-3 ml-2"
          >
            Last name must contain
            {{
              signupForm.controls["lastName"].errors.minlength.requiredLength
            }}
            characters.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span>Email</span>
          <input
            formControlName="email"
            type="text"
            [ngClass]="{ 'valid-border': signupForm.get('email').valid }"
            minlength="6"
            maxlength="80"
            pInputText
            placeholder="Email: `example@gmail.com`"
            style="padding-left: 20px"
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['email'].invalid &&
            (signupForm.controls['email'].dirty ||
              signupForm.controls['email'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['email'].errors.required"
            class="mb-3 ml-2"
          >
            Email is required.
          </div>
          <div
            *ngIf="signupForm.controls['email'].errors.minlength"
            class="mb-3 ml-2"
          >
            Email must contain
            {{ signupForm.controls["email"].errors.minlength.requiredLength }}
            characters.
          </div>
          <div
            *ngIf="
              !signupForm.controls['email'].errors.minlength &&
              signupForm.controls['email'].errors.pattern
            "
            class="mb-3 ml-2"
          >
            Email is invalid.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span> Password</span>
          <input
            formControlName="password"
            type="password"
            [ngClass]="{ 'valid-border': signupForm.get('password').valid }"
            minlength="6"
            maxlength="80"
            pPassword
            placeholder="Password: `******`"
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['password'].invalid &&
            (signupForm.controls['password'].dirty ||
              signupForm.controls['password'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['password'].errors.required"
            class="mb-3 ml-2"
          >
            Password is required.
          </div>
          <div
            *ngIf="signupForm.controls['password'].errors.minlength"
            class="mb-3 ml-2"
          >
            Password must contain
            {{
              signupForm.controls["password"].errors.minlength.requiredLength
            }}
            characters.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span>Confirm Password</span>
          <input
            formControlName="confirmPassword"
            type="password"
            [ngClass]="{
              'valid-border': signupForm.get('confirmPassword').valid
            }"
            minlength="6"
            maxlength="80"
            pPassword
            placeholder="Confirm Password: `******`"
          />
        </div>
        <small
          *ngIf="
            signupForm.controls['confirmPassword'].invalid &&
            (signupForm.controls['confirmPassword'].dirty ||
              signupForm.controls['confirmPassword'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['confirmPassword'].errors.required"
            class="mb-3 ml-2"
          >
            Confirm password is required.
          </div>
          <div
            *ngIf="signupForm.controls['confirmPassword'].errors.minlength"
            class="mb-3 ml-2"
          >
            Confirm password must contain
            {{
              signupForm.controls["confirmPassword"].errors.minlength
                .requiredLength
            }}
            characters.
          </div>
          <div
            *ngIf="
              !signupForm.controls['confirmPassword'].errors.minlength &&
              signupForm.controls['confirmPassword'].errors.mustMatch
            "
            class="mb-3 ml-2"
          >
            It doesn’t match the password given.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span>Phone Number</span>
          <input
            formControlName="phoneNumber"
            pInputText
            type="phone"
            [ngClass]="{ 'valid-border': signupForm.get('phoneNumber').valid }"
            placeholder="Phone"
            maxlength="12"
            style="padding-left: 20px"
            placeholder="Phone Number: `XXX-XXX-XXXX`"
            appPhoneFormat
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['phoneNumber'].invalid &&
            (signupForm.controls['phoneNumber'].dirty ||
              signupForm.controls['phoneNumber'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['phoneNumber'].errors.required"
            class="mb-3 ml-2"
          >
            Phone number is required.
          </div>
          <div
            *ngIf="signupForm.controls['phoneNumber'].errors.minLength"
            class="mb-3 ml-2"
          >
            Phone number is invalid.
          </div>
          <div
            *ngIf="signupForm.controls['phoneNumber'].errors.pattern"
            class="mb-3 ml-2"
          >
            Phone number is invalid.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 10px">
        <div class="inputBox">
          <span>Organization Name</span>
          <input
            formControlName="organizationName"
            type="text"
            minlength="1"
            [ngClass]="{
              'valid-border': signupForm.get('organizationName').valid
            }"
            maxlength="255"
            pInputText
            placeholder="Organization Name"
            style="padding-left: 20px"
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['organizationName'].invalid &&
            (signupForm.controls['organizationName'].dirty ||
              signupForm.controls['organizationName'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['organizationName'].errors.required"
            class="mb-3 ml-2"
          >
            Organization name is required.
          </div>
        </small>
      </div>

      <div class="container-fields" style="margin-bottom: 5px">
        <div class="inputBox">
          <span>City</span>
          <input
            formControlName="city"
            type="text"
            [ngClass]="{ 'valid-border': signupForm.get('city').valid }"
            minlength="2"
            maxlength="50"
            pInputText
            placeholder="City"
            style="padding-left: 20px"
          />
        </div>

        <small
          *ngIf="
            signupForm.controls['city'].invalid &&
            (signupForm.controls['city'].dirty ||
              signupForm.controls['city'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['city'].errors.required"
            class="mb-3 ml-2"
          >
            City is required.
          </div>
          <div
            *ngIf="signupForm.controls['city'].errors.minlength"
            class="mb-3 ml-2"
          >
            City must contain
            {{ signupForm.controls["city"].errors.minlength.requiredLength }}
            characters.
          </div>
        </small>
      </div>

      <div class="dropdown" style="margin-bottom: 5px">
        <span class="dropdown-label">State</span>
        <p-dropdown
          [options]="states"
          formControlName="state"
          optionLabel="name"
          optionValue="name"
          autoWidth="false"
          styleClass=""
          placeholder="State"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
      </div>

      <!-- <div class="container-fields" style="margin-bottom: 10px;">
        <p-autoComplete
          field="label"
          appendTo="body"
          inputStyleClass="tournament-input-text py-2"
          [required]="true"
          autocomplete="noauto"
          emptyMessage="No results found"
          placeholder="City & State"
          [autoHighlight]="true"
          [forceSelection]="true"
          [suggestions]="cityStates"
          formControlName="cityState"
          (completeMethod)="searchCityState($event)"
          dropdownIcon="pi pi-angle-down"
        >
        </p-autoComplete>
        <small
          *ngIf="
            signupForm.controls['cityState'].invalid &&
            (signupForm.controls['cityState'].dirty ||
              signupForm.controls['cityState'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['cityState'].errors.required"
            class="mb-3 ml-2"
          >
            City & State is required.
          </div>
        </small>
      </div> -->

      <div class="container-fields" style="margin-bottom: 5px">
        <div class="inputBox">
          <span>Zip Code</span>
          <input
            formControlName="zipCode"
            type="text"
            placeholder="Zip Code"
            [ngClass]="{ 'valid-border': signupForm.get('zipCode').valid }"
            maxlength="5"
            pInputText
            style="padding-left: 20px"
          />
        </div>
        <small
          *ngIf="
            signupForm.controls['zipCode'].invalid &&
            (signupForm.controls['zipCode'].dirty ||
              signupForm.controls['zipCode'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="signupForm.controls['zipCode'].errors.required"
            class="mb-3 ml-2"
          >
            Zip code is required.
          </div>
        </small>
      </div>

      <div class="dropdown" style="margin-bottom: 5px">
        <span class="dropdown-label">Time Zone</span>
        <p-dropdown
          [options]="timeZones"
          formControlName="timeZone"
          optionLabel="name"
          optionValue="type"
          styleClass=""
          [style]="{ width: '100%' }"
          placeholder="Time Zone"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="flex flex-center my-4 p-error" *ngIf="errorMsg">
      {{ errorMsg }}
    </div>

    <div class="flex flex-center my-4">
      <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
        Cancel
      </button>

      <button
        type="submit"
        (click)="signUp()"
        class="btn-rounded-red mx-2"
        [disabled]="!signupForm.valid"
      >
        Create Account
      </button>
    </div>

    <div class="text-center">
      <p>
        By clicking Create Account, you agree to our
        <span class="text-primary1 pointer">Terms</span> and that you have read
        our <span class="text-primary1 pointer">Data Usage Policy</span>,
        including our <span class="text-primary1 pointer">Cookie Use</span>.
      </p>

      <p (click)="loginEvent()" class="p-0">
        Already have an account?&#160;<span class="text-primary1 pointer"
          >Login</span
        >.
      </p>
    </div>
  </form>
</div>
