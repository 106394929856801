import {Injectable} from '@angular/core';
import { CityState, getStates, getUSCityStates, State } from '@core/utils/city-state';

@Injectable({
    providedIn: 'root'
})
export class CityStateLookupService {

    cityStates: CityState[];
    states: State[]

    constructor() {
        this.cityStates = getUSCityStates();
        this.states = getStates();
    }

    getResults(query, maxResults = 20): Promise<CityState[]> {
        let ret = [];

        if (query && query.trim().length > 0) {
            const searchQuery = query.trim().toLowerCase();
            for (let i = 0; i < this.cityStates.length && ret.length <= maxResults; i++) {
                const obj = this.cityStates[i];
                if (obj.city.toLowerCase().startsWith(searchQuery) ||
                    obj.state.toLowerCase().startsWith(searchQuery) ||
                    obj.value.toLowerCase().startsWith(searchQuery)) {
                    ret.push(obj);
                }
            }
        }

        return Promise.resolve(ret);
    }
    
    
    getStateResults(query, maxResults = 20): Promise<State[]> {
        let ret = [];

        if (query && query.trim().length > 0) {
            const searchQuery = query.trim().toLowerCase();
            for (let i = 0; i < this.states.length && ret.length <= maxResults; i++) {
                const obj = this.states[i];
                if ( obj.state.toLowerCase().startsWith(searchQuery) ||
                    obj.abbreviation.toLowerCase().startsWith(searchQuery)) {
                    ret.push(obj);
                }
            }
        }

        return Promise.resolve(ret);
    }
}
