import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormArray, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Player } from '@core/models/players';
import { Teams } from '@core/models/teams';
import {
  AuthService,
  EmitterService,
  HttpService,
  ToastService,
} from '@core/services';
import { CheckInService } from '@core/services/check-in.service';
import { CoachCoachService } from '@core/services/coach-coach.service';
import { PlayerService } from '@core/services/player.service';
import { TeamsService } from '@core/services/teams.service';
import { TournamentsService } from '@core/services/tournaments.service';
import { environment } from '@env/environment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-public-roster-edit',
  templateUrl: './public-roster-edit.component.html',
  styleUrls: ['./public-roster-edit.component.scss'],
})
export class PublicRosterEditComponent implements OnInit {
  playersForm = this.fb.group({
    players: this.fb.array([]),
  });

  coachesForm = this.fb.group({
    coaches: this.fb.array([]),
  });

  tournament: any[] = [];
  players: any[];
  coaches: any[];

  isCoachesLoading = true;
  isPlayersLoading = true;

  numCoach: number;
  numPlayer: number;
  teamId: number;
  team: Teams;
  teamName: string;
  teamCity: string;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    public ref: DynamicDialogRef,
    private toast: ToastService,
    private tournamentService: TournamentsService,
    private playerService: PlayerService,
    private teamService: TeamsService,
    private coachesService: CoachCoachService,
    private route: ActivatedRoute,

    private toastrService: ToastService,
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService,
    private emitterService: EmitterService
  ) {
    this.login;
    this.route.paramMap.subscribe(async (params) => {
      this.teamId = parseInt(params.get('teamId'));
      this.numCoach = parseInt(params.get('numCoach'));
      this.numPlayer = parseInt(params.get('numPlayer'));

      console.log('teamId', this.teamId);
      console.log('numCoach', this.numCoach);
      console.log('numPlayer', this.numPlayer);
    });
  }

  ngOnInit(): void {
    this.initForm();
    // this.httpService
    //   .get({
    //     url: `${environment.api.tournaments}/${this.tournamentId}`,
    //   })
    //   .subscribe((tourna) => {
    //     console.log('tourna', tourna);
    //   });
    this.teamService.getTeam(this.teamId).then((team) => {
      this.team = team;
      this.teamName = team.name;
      this.teamCity = team.city;
      console.log('this.team', this.team);

      this.playerService.getPlayersByTeam(team).subscribe((players) => {
        this.players = players;
        console.log('this.players', this.players);
        this.patchPlayers();
      });
    });
    this.coachesService.getTeamAsstCoaches(this.teamId).subscribe((coaches) => {
      this.coaches = coaches;
      console.log('coaches', coaches);
      this.patchCoaches();
    });
  }

  login() {
    let email = 'firebase@gmail.com';
    let password = 'password123';

    this.angularFireAuth
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        this.httpService.me().subscribe(
          (res) => {
            // Set to log in
            this.authService.setLoggedIn(true);

            // Set UID
            sessionStorage.setItem('uid', res.uid);

            // Emit data
            this.emitterService.meEmit(res);

            // Set Role
            sessionStorage.setItem('role', res.userType);
          },
          (err) => {
            // Logout firebase
            this.angularFireAuth.signOut();

            console.log('error', err);
          }
        );
      })
      .catch((err) => {
        this.toastrService.errorMsg({ message: err.message });
      });
  }

  initForm(): void {
    for (let i = 0; i < this.numPlayer; i++) {
      console.log(i);

      const playerForm = this.fb.group({
        id: [0],
        jerseyNumber1: [''],
        firstName: [''],
        lastName: [''],
        position: [''],
      });
      this.bulkPlayers.push(playerForm);
    }

    for (let i = 0; i < this.numCoach; i++) {
      console.log(i);

      const coachesForm = this.fb.group({
        id: [0],
        firstName: [''],
        lastName: [''],
        email: [''],
        phoneNumber: [''],
      });
      this.bulkCoaches.push(coachesForm);
    }
  }

  get bulkPlayers() {
    return this.playersForm.controls['players'] as FormArray;
  }

  get bulkCoaches() {
    return this.coachesForm.controls['coaches'] as FormArray;
  }
  patchPlayers() {
    console.log('PATCHING PLAYERS.....');

    for (let i = 0; i < this.players.length; i++) {
      let form = this.bulkPlayers.at(i);
      form.patchValue({
        id: this.players[i].id,
        jerseyNumber1: this.players[i].jerseyNumber1,
        firstName: this.players[i].firstName,
        lastName: this.players[i].lastName,
        position: this.players[i].position,
      });
    }
    this.isPlayersLoading = false;
  }

  patchCoaches() {
    console.log('PATCHING COACHES.....');

    for (let i = 0; i < this.coaches.length; i++) {
      let form = this.bulkCoaches.at(i);
      form.patchValue({
        id: this.coaches[i].id,
        firstName: this.coaches[i].firstName,
        lastName: this.coaches[i].lastName,
        email: this.coaches[i].email,
        phoneNumber: this.coaches[i].phoneNumber,
      });
    }

    this.isCoachesLoading = false;
  }

  async submit() {
    this.toast.toast('Please Wait', 'Updating Players', 'info');

    let valuePlayers = this.playersForm.value.players;

    let players = [];

    for (let i = 0; i < valuePlayers.length; i++) {
      let player = valuePlayers[i];
      if (null != player.jerseyNumber1 && '' != player.jerseyNumber1) {
        players.push(player);
      }
    }

    for (let i = 0; i < players.length; i++) {
      players[i].team = this.team;
    }

    console.log('Playersss', players);

    this.playerService.savePlayers(players).subscribe(
      () => {
        this.toast.successMsg({ message: 'Players Updated' });
        this.ref.close();
      },
      () => {
        this.toast.errorMsg({ message: 'Players List Update Failed' });
      }
    );
  }

  async submitCoaches() {
    this.toast.toast('Please Wait', 'Updating Coaches', 'info');

    let valueCoaches = this.coachesForm.value.coaches;

    let coaches = [];

    for (let i = 0; i < valueCoaches.length; i++) {
      let coach = valueCoaches[i];
      if (null != coach.firstName && '' != coach.firstName) {
        coaches.push(coach);
      }
    }

    console.log('coaches', coaches);

    this.coachesService.addTeamCoachBatch(this.teamId, coaches).subscribe(
      () => {
        this.toast.successMsg({ message: 'Coaches Updated' });
        this.ref.close();
      },
      () => {
        this.toast.errorMsg({ message: 'Coaches List Update Failed' });
      }
    );
    // for (let i = 0; i < coaches.length; i++) {
    //   coaches[i].team = this.team;
    // }

    // this.playerService.savePlayers(players).subscribe(
    //   () => {
    //     this.toast.successMsg({ message: 'Players Updated' });
    //     this.ref.close();
    //   },
    //   () => {
    //     this.toast.errorMsg({ message: 'Update Failed' });
    //   }
    // );
  }

  cancel(): void {
    this.ref.close();
  }
}
