import { Tournament } from './tournament';
import { TkModel } from './tk-model';

export enum AgeGroupLabelEnum {
  U_18_PLUS = '18+U',
  U_18 = '18U',
  U_17 = '17U',
  U_16 = '16U',
  U_15 = '15U',
  U_14 = '14U',
  U_13 = '13U',
  U_12 = '12U',
  U_11 = '11U',
  U_10 = '10U',
  U_9 = '9U',
  U_8 = '8U',
}

export enum AgeGroupEnum {
  U_18_PLUS = 'U_18_PLUS',
  U_18 = 'U_18',
  U_17 = 'U_17',
  U_16 = 'U_16',
  U_15 = 'U_15',
  U_14 = 'U_14',
  U_13 = 'U_13',
  U_12 = 'U_12',
  U_11 = 'U_11',
  U_10 = 'U_10',
  U_9 = 'U_9',
  U_8 = 'U_8',
}

export const AGE_GROUP_TYPES: AgeGroupType[] = [
  {
    ageGroup: AgeGroupEnum.U_8,
    label: AgeGroupLabelEnum.U_8,
  },
  {
    ageGroup: AgeGroupEnum.U_9,
    label: AgeGroupLabelEnum.U_9,
  },
  {
    ageGroup: AgeGroupEnum.U_10,
    label: AgeGroupLabelEnum.U_10,
  },
  {
    ageGroup: AgeGroupEnum.U_11,
    label: AgeGroupLabelEnum.U_11,
  },
  {
    ageGroup: AgeGroupEnum.U_12,
    label: AgeGroupLabelEnum.U_12,
  },
  {
    ageGroup: AgeGroupEnum.U_13,
    label: AgeGroupLabelEnum.U_13,
  },
  {
    ageGroup: AgeGroupEnum.U_14,
    label: AgeGroupLabelEnum.U_14,
  },
  {
    ageGroup: AgeGroupEnum.U_15,
    label: AgeGroupLabelEnum.U_15,
  },
  {
    ageGroup: AgeGroupEnum.U_16,
    label: AgeGroupLabelEnum.U_16,
  },
  {
    ageGroup: AgeGroupEnum.U_17,
    label: AgeGroupLabelEnum.U_17,
  },
  {
    ageGroup: AgeGroupEnum.U_18,
    label: AgeGroupLabelEnum.U_18,
  },
  {
    ageGroup: AgeGroupEnum.U_18_PLUS,
    label: AgeGroupLabelEnum.U_18_PLUS,
  },
];

export interface AgeGroupType {
  ageGroup: string;
  label: string;
}

export interface TournamentAgeGroup extends TkModel {
  id: number;
  tournament?: Tournament;
  ageGroup: AgeGroupEnum;
  version: number;
}
