import { Component, OnInit, Input } from '@angular/core';
import { EditTeamComponent } from '@coach/edit-team/edit-team.component';

// PRIMENG
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import {FilterService} from 'primeng/api';

// MODELS
import { User } from '@core/models/user';

// SERVICES
import {
  EmitterService,
  HttpService,
  ToastService,
  AuthService,
} from '@core/services';

// ENV
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core'
import { Player } from '@core/models/players';
import { CoachCoachService } from '@core/services/coach-coach.service';
import { CoachModalComponent } from '@coach/modals/coach-modal/coach-modal.component';

@Component({
  selector: 'app-list-coaches',
  templateUrl: './list-coaches.component.html',
  styleUrls: ['./list-coaches.component.scss']
})
export class ListCoachesComponent implements OnInit {

  user: User;
  selectedCoaches: any[];
  teamData: any;
  teams: any[];
  data;
  coaches: any[];
  players: Player[];
  hasTeam: boolean = false;

  @ViewChild('dt') dt: Table | undefined;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    private authService: AuthService,
    private emitterService: EmitterService,
    private toastService: ToastService,
    private httpService: HttpService,
    private auth: AuthService,
    private primengConfig: PrimeNGConfig,
    private filterService: FilterService,
    private ngxLoader: NgxUiLoaderService,
    // private coachTeamsService: CoachTeamsService,
    private coachCoachService: CoachCoachService,
    private router: Router
  ) {
    this.emitterService.meEmitter.subscribe((user) => {
      this.user = user;
      console.log(user.id);
      this.ngxLoader.stop();
    });
  }

  ngOnInit(): void {
    this.getCoaches();
  }

  async getCoaches(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    console.log(userId);
    this.coachCoachService.getCoachAsstCoaches(userId).subscribe((coaches) => {
      this.coaches = coaches;
      console.log('list of coaches: ', coaches);
    });
  }

  applyFilterGlobal($event, stringVal) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, 'contains');
    console.log($event.target);
  }


  clear(table: Table) {
    table.clear();
}

showAddCoach(): void {
  const teamId = sessionStorage.getItem('teamId');
  const ref = this.dialogService.open(CoachModalComponent, {
       header: 'Add Coach',
       width: '600px',
       closable: false,
       height: 'calc(100vh - 120px)',
       contentStyle: { 'overflow-y': 'scroll' },
       data: {
          isEdit: false,
       }
   });
  ref.onClose.subscribe(() => {
    this.getCoaches();
  })
}

  showEditCoach(coach: any): void {
    const ref = this.dialogService.open(CoachModalComponent, {
      header: 'Edit Coach',
      width: '600px',
      closable: false,
      contentStyle: { 'overflow-y': 'scroll' },
      data: {
        coach,
        isEdit: true,
      },
    });
    ref.onClose.subscribe(() => {
      this.getCoaches();
    });
  }


  goToPlayers(teamId: any) {
    sessionStorage.setItem('teamId', teamId);
    this.router.navigate(['coach-dashboard/players/' + teamId]);
  }

  goTopage() {
    this.router.navigate(['coach-dashboard/page']);
  }

  onDeleteCoach(): void {
    this.toastService.toast('Please Wait', 'Deleting Coach', 'info')
    this.selectedCoaches.map((coach) => {
      this.coachCoachService.deleteAssistantCoach(coach.id).subscribe();
     });
     this.getCoaches();
     this.toastService.successMsg({message: 'Coach Deleted'});
  
  }

}
