<div class="container">
  <div class="p-grid">
    <div class="p-col-12">
      <label class="text-bold1 p-0 ml-4 mb-2">Name *</label>
      <div class="pl-2 pr-2 pt-2">
        <input
          type="text"
          class="tournament-input-text"
          max="255"
          placeholder="Enter name to identify account"
          [(ngModel)]="account.name"
        />
      </div>
    </div>

    <div class="p-col-12">
      <label class="text-bold1 p-0 ml-4 mb-2">Email *</label>
      <div class="pl-2 pr-2 pt-2">
        <input
          type="text"
          class="tournament-input-text"
          max="255"
          placeholder="Enter email"
          [(ngModel)]="account.email"
        />
      </div>
    </div>

    <div class="p-col-12 flex flex-center">
      <button
        pButton
        iconPos="right"
        type="button"
        label="Save"
        class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
        (click)="onSubmit()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Cancel"
        class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
        (click)="cancel()"
      ></button>
    </div>
  </div>
</div>
