export interface CityState {
    city: string;
    state: string;
    value?: string;
    label?: string;
}

export interface State {
    name: string;
    abbreviation?: string;
    label?: string;
    state?: string;
}
export function getUSCityStates(): CityState[] {
    return USA_CITY_STATES.map(itm => {
        return {
            city: itm.city,
            state: itm.state,
            value: `${itm.city}, ${itm.state}`,
            label: `${itm.city}, ${itm.state}`
        }
    });
}
export function getStates(): State[]{
    return USA_STATES.map(itm => {
        return {
            state: itm.name,
            name: `${itm.name}`,
            abbreviation: `${itm.abbreviation}`,
            label: itm.name
        }
    });
}
export const USA_STATES = <State[]>[
    {
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "name": "American Samoa",
        "abbreviation": "AS"
    },
    {
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "name": "District Of Columbia",
        "abbreviation": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
    },
    {
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "name": "Marshall Islands",
        "abbreviation": "MH"
    },
    {
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "name": "Palau",
        "abbreviation": "PW"
    },
    {
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]
export const USA_CITY_STATES = <CityState[]>[
        {'city': 'Abbeville', 'state': 'Louisiana'},
        {'city': 'Aberdeen', 'state': 'Maryland'},
        {'city': 'Aberdeen', 'state': 'Mississippi'},
        {'city': 'Aberdeen', 'state': 'South Dakota'},
        {'city': 'Aberdeen', 'state': 'Washington'},
        {'city': 'Abilene', 'state': 'Texas'},
        {'city': 'Abilene', 'state': 'Kansas'},
        {'city': 'Abingdon', 'state': 'Virginia'},
        {'city': 'Abington', 'state': 'Massachusetts'},
        {'city': 'Abington', 'state': 'Massachusetts'},
        {'city': 'Absecon', 'state': 'New Jersey'},
        {'city': 'Accokeek', 'state': 'Maryland'},
        {'city': 'Acton', 'state': 'Massachusetts'},
        {'city': 'Acushnet', 'state': 'Massachusetts'},
        {'city': 'Acworth', 'state': 'Georgia'},
        {'city': 'Ada', 'state': 'Oklahoma'},
        {'city': 'Adams', 'state': 'Massachusetts'},
        {'city': 'Addison', 'state': 'Illinois'},
        {'city': 'Addison', 'state': 'Texas'},
        {'city': 'Adelanto', 'state': 'California'},
        {'city': 'Adelphi', 'state': 'Maryland'},
        {'city': 'Adrian', 'state': 'Michigan'},
        {'city': 'Affton', 'state': 'Missouri'},
        {'city': 'Agawam', 'state': 'Massachusetts'},
        {'city': 'Agoura Hills', 'state': 'California'},
        {'city': 'Ahuimanu', 'state': 'Hawaii'},
        {'city': 'Aiea', 'state': 'Hawaii'},
        {'city': 'Aiken', 'state': 'South Carolina'},
        {'city': 'Air Force Academy', 'state': 'Colorado'},
        {'city': 'Airmont', 'state': 'New York'},
        {'city': 'Akron', 'state': 'Ohio'},
        {'city': 'Alabaster', 'state': 'Alabama'},
        {'city': 'Alachua', 'state': 'Florida'},
        {'city': 'Alameda', 'state': 'California'},
        {'city': 'Alamo', 'state': 'California'},
        {'city': 'Alamo', 'state': 'Texas'},
        {'city': 'Alamo Heights', 'state': 'Texas'},
        {'city': 'Alamogordo', 'state': 'New Mexico'},
        {'city': 'Alamosa', 'state': 'Colorado'},
        {'city': 'Albany', 'state': 'Georgia'},
        {'city': 'Albany', 'state': 'California'},
        {'city': 'Albany', 'state': 'New York'},
        {'city': 'Albany', 'state': 'Oregon'},
        {'city': 'Albemarle', 'state': 'North Carolina'},
        {'city': 'Albert Lea', 'state': 'Minnesota'},
        {'city': 'Albertville', 'state': 'Alabama'},
        {'city': 'Albion', 'state': 'Michigan'},
        {'city': 'Albion', 'state': 'New York'},
        {'city': 'Albion', 'state': 'New York'},
        {'city': 'Albuquerque', 'state': 'New Mexico'},
        {'city': 'Alcoa', 'state': 'Tennessee'},
        {'city': 'Alden', 'state': 'New York'},
        {'city': 'Alderwood Manor', 'state': 'Washington'},
        {'city': 'Aldine', 'state': 'Texas'},
        {'city': 'Alexander City', 'state': 'Alabama'},
        {'city': 'Alexandria', 'state': 'Indiana'},
        {'city': 'Alexandria', 'state': 'Minnesota'},
        {'city': 'Alexandria', 'state': 'Kentucky'},
        {'city': 'Alexandria', 'state': 'Louisiana'},
        {'city': 'Alexandria', 'state': 'Virginia'},
        {'city': 'Algonquin', 'state': 'Illinois'},
        {'city': 'Alhambra', 'state': 'California'},
        {'city': 'Alice', 'state': 'Texas'},
        {'city': 'Aliquippa', 'state': 'Pennsylvania'},
        {'city': 'Aliso Viejo', 'state': 'California'},
        {'city': 'Allegany', 'state': 'New York'},
        {'city': 'Allen', 'state': 'Texas'},
        {'city': 'Allen Park', 'state': 'Michigan'},
        {'city': 'Allendale', 'state': 'Michigan'},
        {'city': 'Allendale', 'state': 'New Jersey'},
        {'city': 'Allentown', 'state': 'Pennsylvania'},
        {'city': 'Alliance', 'state': 'Ohio'},
        {'city': 'Alliance', 'state': 'Nebraska'},
        {'city': 'Allouez', 'state': 'Wisconsin'},
        {'city': 'Alma', 'state': 'Michigan'},
        {'city': 'Aloha', 'state': 'Oregon'},
        {'city': 'Alondra Park', 'state': 'California'},
        {'city': 'Alpena', 'state': 'Michigan'},
        {'city': 'Alpharetta', 'state': 'Georgia'},
        {'city': 'Alpine', 'state': 'California'},
        {'city': 'Alpine', 'state': 'Utah'},
        {'city': 'Alsip', 'state': 'Illinois'},
        {'city': 'Alta Sierra', 'state': 'California'},
        {'city': 'Altadena', 'state': 'California'},
        {'city': 'Altamont', 'state': 'Oregon'},
        {'city': 'Altamont', 'state': 'New York'},
        {'city': 'Altamonte Springs', 'state': 'Florida'},
        {'city': 'Alton', 'state': 'Illinois'},
        {'city': 'Altoona', 'state': 'Iowa'},
        {'city': 'Altoona', 'state': 'Pennsylvania'},
        {'city': 'Altoona', 'state': 'Wisconsin'},
        {'city': 'Altus', 'state': 'Oklahoma'},
        {'city': 'Alum Rock', 'state': 'California'},
        {'city': 'Alvin', 'state': 'Texas'},
        {'city': 'Amarillo', 'state': 'Texas'},
        {'city': 'Ambler', 'state': 'Pennsylvania'},
        {'city': 'Ambridge', 'state': 'Pennsylvania'},
        {'city': 'American Canyon', 'state': 'California'},
        {'city': 'American Fork', 'state': 'Utah'},
        {'city': 'Americus', 'state': 'Georgia'},
        {'city': 'Ames', 'state': 'Iowa'},
        {'city': 'Amesbury', 'state': 'Massachusetts'},
        {'city': 'Amesbury', 'state': 'Massachusetts'},
        {'city': 'Amherst', 'state': 'New Hampshire'},
        {'city': 'Amherst', 'state': 'Massachusetts'},
        {'city': 'Amherst', 'state': 'Ohio'},
        {'city': 'Amherst', 'state': 'New York'},
        {'city': 'Amherst Center', 'state': 'Massachusetts'},
        {'city': 'Amityville', 'state': 'New York'},
        {'city': 'Ammon', 'state': 'Idaho'},
        {'city': 'Amory', 'state': 'Mississippi'},
        {'city': 'Amsterdam', 'state': 'New York'},
        {'city': 'Anaconda-Deer Lodge County', 'state': 'Montana'},
        {'city': 'Anacortes', 'state': 'Washington'},
        {'city': 'Anadarko', 'state': 'Oklahoma'},
        {'city': 'Anaheim', 'state': 'California'},
        {'city': 'Anchorage', 'state': 'Alaska'},
        {'city': 'Andalusia', 'state': 'Alabama'},
        {'city': 'Anderson', 'state': 'California'},
        {'city': 'Anderson', 'state': 'Indiana'},
        {'city': 'Anderson', 'state': 'South Carolina'},
        {'city': 'Anderson Mill', 'state': 'Texas'},
        {'city': 'Andover', 'state': 'Florida'},
        {'city': 'Andover', 'state': 'Minnesota'},
        {'city': 'Andover', 'state': 'Massachusetts'},
        {'city': 'Andover', 'state': 'Kansas'},
        {'city': 'Andover', 'state': 'Massachusetts'},
        {'city': 'Andrews', 'state': 'Texas'},
        {'city': 'Andrews AFB', 'state': 'Maryland'},
        {'city': 'Angleton', 'state': 'Texas'},
        {'city': 'Angola', 'state': 'Indiana'},
        {'city': 'Ankeny', 'state': 'Iowa'},
        {'city': 'Ann Arbor', 'state': 'Michigan'},
        {'city': 'Annandale', 'state': 'Virginia'},
        {'city': 'Annapolis', 'state': 'Maryland'},
        {'city': 'Anniston', 'state': 'Alabama'},
        {'city': 'Anoka', 'state': 'Minnesota'},
        {'city': 'Ansonia', 'state': 'Connecticut'},
        {'city': 'Ansonia', 'state': 'Connecticut'},
        {'city': 'Anthony', 'state': 'New Mexico'},
        {'city': 'Antigo', 'state': 'Wisconsin'},
        {'city': 'Antioch', 'state': 'Illinois'},
        {'city': 'Antioch', 'state': 'California'},
        {'city': 'Apache Junction', 'state': 'Arizona'},
        {'city': 'Apex', 'state': 'North Carolina'},
        {'city': 'Apollo Beach', 'state': 'Florida'},
        {'city': 'Apopka', 'state': 'Florida'},
        {'city': 'Apple Valley', 'state': 'California'},
        {'city': 'Apple Valley', 'state': 'Minnesota'},
        {'city': 'Appleton', 'state': 'Wisconsin'},
        {'city': 'Applewood', 'state': 'Colorado'},
        {'city': 'Aptos', 'state': 'California'},
        {'city': 'Aquia Harbour', 'state': 'Virginia'},
        {'city': 'Arab', 'state': 'Alabama'},
        {'city': 'Arabi', 'state': 'Louisiana'},
        {'city': 'Aransas Pass', 'state': 'Texas'},
        {'city': 'Arbutus', 'state': 'Maryland'},
        {'city': 'Arcadia', 'state': 'California'},
        {'city': 'Arcadia', 'state': 'Florida'},
        {'city': 'Arcadia', 'state': 'New York'},
        {'city': 'Arcata', 'state': 'California'},
        {'city': 'Archbald', 'state': 'Pennsylvania'},
        {'city': 'Archdale', 'state': 'North Carolina'},
        {'city': 'Arden Hills', 'state': 'Minnesota'},
        {'city': 'Arden-Arcade', 'state': 'California'},
        {'city': 'Ardmore', 'state': 'Oklahoma'},
        {'city': 'Ardmore', 'state': 'Pennsylvania'},
        {'city': 'Arkadelphia', 'state': 'Arkansas'},
        {'city': 'Arkansas City', 'state': 'Kansas'},
        {'city': 'Arlington', 'state': 'Massachusetts'},
        {'city': 'Arlington', 'state': 'Massachusetts'},
        {'city': 'Arlington', 'state': 'Texas'},
        {'city': 'Arlington', 'state': 'New York'},
        {'city': 'Arlington', 'state': 'Virginia'},
        {'city': 'Arlington', 'state': 'Washington'},
        {'city': 'Arlington Heights', 'state': 'Illinois'},
        {'city': 'Arnold', 'state': 'Missouri'},
        {'city': 'Arnold', 'state': 'Maryland'},
        {'city': 'Arroyo Grande', 'state': 'California'},
        {'city': 'Artesia', 'state': 'California'},
        {'city': 'Artesia', 'state': 'New Mexico'},
        {'city': 'Artondale', 'state': 'Washington'},
        {'city': 'Arvada', 'state': 'Colorado'},
        {'city': 'Arvin', 'state': 'California'},
        {'city': 'Asbury Park', 'state': 'New Jersey'},
        {'city': 'Asheboro', 'state': 'North Carolina'},
        {'city': 'Asheville', 'state': 'North Carolina'},
        {'city': 'Ashland', 'state': 'Oregon'},
        {'city': 'Ashland', 'state': 'Ohio'},
        {'city': 'Ashland', 'state': 'Virginia'},
        {'city': 'Ashland', 'state': 'Wisconsin'},
        {'city': 'Ashland', 'state': 'New Jersey'},
        {'city': 'Ashland', 'state': 'Massachusetts'},
        {'city': 'Ashland', 'state': 'Kentucky'},
        {'city': 'Ashland', 'state': 'California'},
        {'city': 'Ashtabula', 'state': 'Ohio'},
        {'city': 'Ashwaubenon', 'state': 'Wisconsin'},
        {'city': 'Aspen Hill', 'state': 'Maryland'},
        {'city': 'Astoria', 'state': 'Oregon'},
        {'city': 'Atascadero', 'state': 'California'},
        {'city': 'Atascocita', 'state': 'Texas'},
        {'city': 'Atchison', 'state': 'Kansas'},
        {'city': 'Athens', 'state': 'Alabama'},
        {'city': 'Athens', 'state': 'Texas'},
        {'city': 'Athens', 'state': 'Tennessee'},
        {'city': 'Athens', 'state': 'Ohio'},
        {'city': 'Athens-Clarke County', 'state': 'Georgia'},
        {'city': 'Atherton', 'state': 'California'},
        {'city': 'Athol', 'state': 'Massachusetts'},
        {'city': 'Athol', 'state': 'Massachusetts'},
        {'city': 'Atkinson', 'state': 'New Hampshire'},
        {'city': 'Atlanta', 'state': 'Georgia'},
        {'city': 'Atlantic', 'state': 'Iowa'},
        {'city': 'Atlantic Beach', 'state': 'Florida'},
        {'city': 'Atlantic City', 'state': 'New Jersey'},
        {'city': 'Atmore', 'state': 'Alabama'},
        {'city': 'Attalla', 'state': 'Alabama'},
        {'city': 'Attica', 'state': 'New York'},
        {'city': 'Attleboro', 'state': 'Massachusetts'},
        {'city': 'Atwater', 'state': 'California'},
        {'city': 'Auburn', 'state': 'California'},
        {'city': 'Auburn', 'state': 'Alabama'},
        {'city': 'Auburn', 'state': 'Georgia'},
        {'city': 'Auburn', 'state': 'Indiana'},
        {'city': 'Auburn', 'state': 'Massachusetts'},
        {'city': 'Auburn', 'state': 'Maine'},
        {'city': 'Auburn', 'state': 'New York'},
        {'city': 'Auburn', 'state': 'Washington'},
        {'city': 'Auburn Hills', 'state': 'Michigan'},
        {'city': 'Auburndale', 'state': 'Florida'},
        {'city': 'Audubon', 'state': 'New Jersey'},
        {'city': 'Audubon', 'state': 'Pennsylvania'},
        {'city': 'August', 'state': 'California'},
        {'city': 'Augusta', 'state': 'Maine'},
        {'city': 'Augusta', 'state': 'Kansas'},
        {'city': 'Augusta-Richmond County', 'state': 'Georgia'},
        {'city': 'Aurora', 'state': 'Illinois'},
        {'city': 'Aurora', 'state': 'Colorado'},
        {'city': 'Aurora', 'state': 'Missouri'},
        {'city': 'Aurora', 'state': 'New York'},
        {'city': 'Aurora', 'state': 'Ohio'},
        {'city': 'Austin', 'state': 'Texas'},
        {'city': 'Austin', 'state': 'Minnesota'},
        {'city': 'Austintown', 'state': 'Ohio'},
        {'city': 'Avenal', 'state': 'California'},
        {'city': 'Avenel', 'state': 'New Jersey'},
        {'city': 'Aventura', 'state': 'Florida'},
        {'city': 'Avocado Heights', 'state': 'California'},
        {'city': 'Avon', 'state': 'Connecticut'},
        {'city': 'Avon', 'state': 'Indiana'},
        {'city': 'Avon', 'state': 'Ohio'},
        {'city': 'Avon', 'state': 'New York'},
        {'city': 'Avon Lake', 'state': 'Ohio'},
        {'city': 'Avon Park', 'state': 'Florida'},
        {'city': 'Avondale', 'state': 'Arizona'},
        {'city': 'Ayer', 'state': 'Massachusetts'},
        {'city': 'Azalea Park', 'state': 'Florida'},
        {'city': 'Azle', 'state': 'Texas'},
        {'city': 'Aztec', 'state': 'New Mexico'},
        {'city': 'Azusa', 'state': 'California'},

        {'city': 'Babylon', 'state': 'New York'},
        {'city': 'Babylon', 'state': 'New York'},
        {'city': 'Back Mountain', 'state': 'Pennsylvania'},
        {'city': 'Bacliff', 'state': 'Texas'},
        {'city': 'Bailey’s Crossroads', 'state': 'Virginia'},
        {'city': 'Bainbridge', 'state': 'Georgia'},
        {'city': 'Bainbridge Island', 'state': 'Washington'},
        {'city': 'Baker', 'state': 'Louisiana'},
        {'city': 'Baker City', 'state': 'Oregon'},
        {'city': 'Bakersfield', 'state': 'California'},
        {'city': 'Balch Springs', 'state': 'Texas'},
        {'city': 'Baldwin', 'state': 'Pennsylvania'},
        {'city': 'Baldwin', 'state': 'New York'},
        {'city': 'Baldwin Harbor', 'state': 'New York'},
        {'city': 'Baldwin Park', 'state': 'California'},
        {'city': 'Baldwinsville', 'state': 'New York'},
        {'city': 'Ballenger Creek', 'state': 'Maryland'},
        {'city': 'Ballston', 'state': 'New York'},
        {'city': 'Ballwin', 'state': 'Missouri'},
        {'city': 'Baltimore', 'state': 'Maryland'},
        {'city': 'Bangor', 'state': 'Maine'},
        {'city': 'Bangor Trident Base', 'state': 'Washington'},
        {'city': 'Banning', 'state': 'California'},
        {'city': 'Baraboo', 'state': 'Wisconsin'},
        {'city': 'Barberton', 'state': 'Ohio'},
        {'city': 'Barclay-Kingston', 'state': 'New Jersey'},
        {'city': 'Bardstown', 'state': 'Kentucky'},
        {'city': 'Barnhart', 'state': 'Missouri'},
        {'city': 'Barnstable Town', 'state': 'Massachusetts'},
        {'city': 'Barre', 'state': 'Vermont'},
        {'city': 'Barre', 'state': 'Vermont'},
        {'city': 'Barrington', 'state': 'Rhode Island'},
        {'city': 'Barrington', 'state': 'Rhode Island'},
        {'city': 'Barrington', 'state': 'New Jersey'},
        {'city': 'Barrington', 'state': 'New Hampshire'},
        {'city': 'Barrington', 'state': 'Illinois'},
        {'city': 'Barstow', 'state': 'California'},
        {'city': 'Bartlesville', 'state': 'Oklahoma'},
        {'city': 'Bartlett', 'state': 'Illinois'},
        {'city': 'Bartlett', 'state': 'Tennessee'},
        {'city': 'Barton', 'state': 'New York'},
        {'city': 'Bartonville', 'state': 'Illinois'},
        {'city': 'Bartow', 'state': 'Florida'},
        {'city': 'Bastrop', 'state': 'Louisiana'},
        {'city': 'Batavia', 'state': 'Illinois'},
        {'city': 'Batavia', 'state': 'New York'},
        {'city': 'Batesville', 'state': 'Mississippi'},
        {'city': 'Batesville', 'state': 'Indiana'},
        {'city': 'Batesville', 'state': 'Arkansas'},
        {'city': 'Bath', 'state': 'Maine'},
        {'city': 'Bath', 'state': 'New York'},
        {'city': 'Baton Rouge', 'state': 'Louisiana'},
        {'city': 'Battle Creek', 'state': 'Michigan'},
        {'city': 'Battle Ground', 'state': 'Washington'},
        {'city': 'Bay City', 'state': 'Texas'},
        {'city': 'Bay City', 'state': 'Michigan'},
        {'city': 'Bay Minette', 'state': 'Alabama'},
        {'city': 'Bay Point', 'state': 'California'},
        {'city': 'Bay Shore', 'state': 'New York'},
        {'city': 'Bay St. Louis', 'state': 'Mississippi'},
        {'city': 'Bay Village', 'state': 'Ohio'},
        {'city': 'Bayonet Point', 'state': 'Florida'},
        {'city': 'Bayonne', 'state': 'New Jersey'},
        {'city': 'Bayou Cane', 'state': 'Louisiana'},
        {'city': 'Bayport', 'state': 'New York'},
        {'city': 'Bayshore Gardens', 'state': 'Florida'},
        {'city': 'Baytown', 'state': 'Texas'},
        {'city': 'Bayville', 'state': 'New York'},
        {'city': 'Baywood', 'state': 'New York'},
        {'city': 'Baywood-Los Osos', 'state': 'California'},
        {'city': 'Beach Park', 'state': 'Illinois'},
        {'city': 'Beachwood', 'state': 'Ohio'},
        {'city': 'Beachwood', 'state': 'New Jersey'},
        {'city': 'Beacon', 'state': 'New York'},
        {'city': 'Beacon Square', 'state': 'Florida'},
        {'city': 'Bear', 'state': 'Delaware'},
        {'city': 'Beatrice', 'state': 'Nebraska'},
        {'city': 'Beaufort', 'state': 'South Carolina'},
        {'city': 'Beaumont', 'state': 'Texas'},
        {'city': 'Beaumont', 'state': 'California'},
        {'city': 'Beaver Dam', 'state': 'Wisconsin'},
        {'city': 'Beaver Falls', 'state': 'Pennsylvania'},
        {'city': 'Beavercreek', 'state': 'Ohio'},
        {'city': 'Beaverton', 'state': 'Oregon'},
        {'city': 'Beckett Ridge', 'state': 'Ohio'},
        {'city': 'Beckley', 'state': 'West Virginia'},
        {'city': 'Bedford', 'state': 'Virginia'},
        {'city': 'Bedford', 'state': 'Texas'},
        {'city': 'Bedford', 'state': 'Ohio'},
        {'city': 'Bedford', 'state': 'New York'},
        {'city': 'Bedford', 'state': 'New Hampshire'},
        {'city': 'Bedford', 'state': 'Massachusetts'},
        {'city': 'Bedford', 'state': 'Indiana'},
        {'city': 'Bedford Heights', 'state': 'Ohio'},
        {'city': 'Bee Ridge', 'state': 'Florida'},
        {'city': 'Beech Grove', 'state': 'Indiana'},
        {'city': 'Beecher', 'state': 'Michigan'},
        {'city': 'Beekman', 'state': 'New York'},
        {'city': 'Beeville', 'state': 'Texas'},
        {'city': 'Bel Air', 'state': 'Maryland'},
        {'city': 'Bel Air North', 'state': 'Maryland'},
        {'city': 'Bel Air South', 'state': 'Maryland'},
        {'city': 'Belchertown', 'state': 'Massachusetts'},
        {'city': 'Belen', 'state': 'New Mexico'},
        {'city': 'Belfast', 'state': 'Maine'},
        {'city': 'Bell', 'state': 'California'},
        {'city': 'Bell Gardens', 'state': 'California'},
        {'city': 'Bella Vista', 'state': 'Arkansas'},
        {'city': 'Bellair-Meadowbrook Terrace', 'state': 'Florida'},
        {'city': 'Bellaire', 'state': 'Texas'},
        {'city': 'Bellbrook', 'state': 'Ohio'},
        {'city': 'Belle Chasse', 'state': 'Louisiana'},
        {'city': 'Belle Glade', 'state': 'Florida'},
        {'city': 'Belle Haven', 'state': 'Virginia'},
        {'city': 'Bellefontaine', 'state': 'Ohio'},
        {'city': 'Bellefontaine Neighbors', 'state': 'Missouri'},
        {'city': 'Bellefonte', 'state': 'Pennsylvania'},
        {'city': 'Belleville', 'state': 'New Jersey'},
        {'city': 'Belleville', 'state': 'Illinois'},
        {'city': 'Bellevue', 'state': 'Kentucky'},
        {'city': 'Bellevue', 'state': 'Nebraska'},
        {'city': 'Bellevue', 'state': 'Ohio'},
        {'city': 'Bellevue', 'state': 'Pennsylvania'},
        {'city': 'Bellevue', 'state': 'Wisconsin'},
        {'city': 'Bellevue', 'state': 'Washington'},
        {'city': 'Bellevue Town', 'state': 'Wisconsin'},
        {'city': 'Bellflower', 'state': 'California'},
        {'city': 'Bellingham', 'state': 'Massachusetts'},
        {'city': 'Bellingham', 'state': 'Washington'},
        {'city': 'Bellmawr', 'state': 'New Jersey'},
        {'city': 'Bellmead', 'state': 'Texas'},
        {'city': 'Bellmore', 'state': 'New York'},
        {'city': 'Bellview', 'state': 'Florida'},
        {'city': 'Bellwood', 'state': 'Illinois'},
        {'city': 'Belmar', 'state': 'New Jersey'},
        {'city': 'Belmont', 'state': 'Massachusetts'},
        {'city': 'Belmont', 'state': 'Massachusetts'},
        {'city': 'Belmont', 'state': 'New Hampshire'},
        {'city': 'Belmont', 'state': 'North Carolina'},
        {'city': 'Belmont', 'state': 'California'},
        {'city': 'Beloit', 'state': 'Wisconsin'},
        {'city': 'Beloit', 'state': 'Wisconsin'},
        {'city': 'Belpre', 'state': 'Ohio'},
        {'city': 'Belton', 'state': 'Missouri'},
        {'city': 'Belton', 'state': 'Texas'},
        {'city': 'Beltsville', 'state': 'Maryland'},
        {'city': 'Belvedere Park', 'state': 'Georgia'},
        {'city': 'Belvidere', 'state': 'Illinois'},
        {'city': 'Bemidji', 'state': 'Minnesota'},
        {'city': 'Benbrook', 'state': 'Texas'},
        {'city': 'Bend', 'state': 'Oregon'},
        {'city': 'Benicia', 'state': 'California'},
        {'city': 'Bennettsville', 'state': 'South Carolina'},
        {'city': 'Bennington', 'state': 'Vermont'},
        {'city': 'Bennington', 'state': 'Vermont'},
        {'city': 'Bennsville', 'state': 'Maryland'},
        {'city': 'Bensenville', 'state': 'Illinois'},
        {'city': 'Benton', 'state': 'Illinois'},
        {'city': 'Benton', 'state': 'Arkansas'},
        {'city': 'Benton Harbor', 'state': 'Michigan'},
        {'city': 'Bentonville', 'state': 'Arkansas'},
        {'city': 'Berea', 'state': 'Kentucky'},
        {'city': 'Berea', 'state': 'Ohio'},
        {'city': 'Berea', 'state': 'South Carolina'},
        {'city': 'Bergenfield', 'state': 'New Jersey'},
        {'city': 'Berkeley', 'state': 'Missouri'},
        {'city': 'Berkeley', 'state': 'California'},
        {'city': 'Berkeley Heights', 'state': 'New Jersey'},
        {'city': 'Berkley', 'state': 'Michigan'},
        {'city': 'Berkley', 'state': 'Colorado'},
        {'city': 'Berlin', 'state': 'Connecticut'},
        {'city': 'Berlin', 'state': 'New Jersey'},
        {'city': 'Berlin', 'state': 'New Hampshire'},
        {'city': 'Bermuda Dunes', 'state': 'California'},
        {'city': 'Bernalillo', 'state': 'New Mexico'},
        {'city': 'Bernardsville', 'state': 'New Jersey'},
        {'city': 'Berwick', 'state': 'Maine'},
        {'city': 'Berwick', 'state': 'Pennsylvania'},
        {'city': 'Berwyn', 'state': 'Illinois'},
        {'city': 'Bessemer', 'state': 'Alabama'},
        {'city': 'Bethalto', 'state': 'Illinois'},
        {'city': 'Bethany', 'state': 'Oklahoma'},
        {'city': 'Bethel', 'state': 'Connecticut'},
        {'city': 'Bethel', 'state': 'Connecticut'},
        {'city': 'Bethel Park', 'state': 'Pennsylvania'},
        {'city': 'Bethesda', 'state': 'Maryland'},
        {'city': 'Bethlehem', 'state': 'New York'},
        {'city': 'Bethlehem', 'state': 'Pennsylvania'},
        {'city': 'Bethpage', 'state': 'New York'},
        {'city': 'Bettendorf', 'state': 'Iowa'},
        {'city': 'Beverly', 'state': 'Massachusetts'},
        {'city': 'Beverly Hills', 'state': 'Michigan'},
        {'city': 'Beverly Hills', 'state': 'Florida'},
        {'city': 'Beverly Hills', 'state': 'California'},
        {'city': 'Bexley', 'state': 'Ohio'},
        {'city': 'Biddeford', 'state': 'Maine'},
        {'city': 'Big Flats', 'state': 'New York'},
        {'city': 'Big Lake', 'state': 'Minnesota'},
        {'city': 'Big Rapids', 'state': 'Michigan'},
        {'city': 'Big Spring', 'state': 'Texas'},
        {'city': 'Billerica', 'state': 'Massachusetts'},
        {'city': 'Billings', 'state': 'Montana'},
        {'city': 'Biloxi', 'state': 'Mississippi'},
        {'city': 'Binghamton', 'state': 'New York'},
        {'city': 'Birmingham', 'state': 'Michigan'},
        {'city': 'Birmingham', 'state': 'Alabama'},
        {'city': 'Bisbee', 'state': 'Arizona'},
        {'city': 'Bismarck', 'state': 'North Dakota'},
        {'city': 'Bixby', 'state': 'Oklahoma'},
        {'city': 'Black Forest', 'state': 'Colorado'},
        {'city': 'Black Jack', 'state': 'Missouri'},
        {'city': 'Black Mountain', 'state': 'North Carolina'},
        {'city': 'Blackfoot', 'state': 'Idaho'},
        {'city': 'Blackhawk-Camino Tassajara', 'state': 'California'},
        {'city': 'Blacklick Estates', 'state': 'Ohio'},
        {'city': 'Blacksburg', 'state': 'Virginia'},
        {'city': 'Blackstone', 'state': 'Massachusetts'},
        {'city': 'Blackwell', 'state': 'Oklahoma'},
        {'city': 'Bladensburg', 'state': 'Maryland'},
        {'city': 'Blaine', 'state': 'Minnesota'},
        {'city': 'Blair', 'state': 'Nebraska'},
        {'city': 'Blakely', 'state': 'Pennsylvania'},
        {'city': 'Bloomfield', 'state': 'New Jersey'},
        {'city': 'Bloomfield', 'state': 'New Mexico'},
        {'city': 'Bloomfield', 'state': 'Connecticut'},
        {'city': 'Bloomfield Township', 'state': 'Michigan'},
        {'city': 'Blooming Grove', 'state': 'New York'},
        {'city': 'Bloomingdale', 'state': 'New Jersey'},
        {'city': 'Bloomingdale', 'state': 'Florida'},
        {'city': 'Bloomingdale', 'state': 'Illinois'},
        {'city': 'Bloomingdale', 'state': 'Tennessee'},
        {'city': 'Bloomington', 'state': 'Illinois'},
        {'city': 'Bloomington', 'state': 'Indiana'},
        {'city': 'Bloomington', 'state': 'California'},
        {'city': 'Bloomington', 'state': 'Minnesota'},
        {'city': 'Bloomsburg', 'state': 'Pennsylvania'},
        {'city': 'Blue Ash', 'state': 'Ohio'},
        {'city': 'Blue Bell', 'state': 'Pennsylvania'},
        {'city': 'Blue Island', 'state': 'Illinois'},
        {'city': 'Blue Springs', 'state': 'Missouri'},
        {'city': 'Bluefield', 'state': 'West Virginia'},
        {'city': 'Bluffton', 'state': 'Indiana'},
        {'city': 'Blythe', 'state': 'California'},
        {'city': 'Blytheville', 'state': 'Arkansas'},
        {'city': 'Boardman', 'state': 'Ohio'},
        {'city': 'Boaz', 'state': 'Alabama'},
        {'city': 'Boca Del Mar', 'state': 'Florida'},
        {'city': 'Boca Raton', 'state': 'Florida'},
        {'city': 'Boerne', 'state': 'Texas'},
        {'city': 'Bogalusa', 'state': 'Louisiana'},
        {'city': 'Bogota', 'state': 'New Jersey'},
        {'city': 'Bohemia', 'state': 'New York'},
        {'city': 'Boise City', 'state': 'Idaho'},
        {'city': 'Bolingbrook', 'state': 'Illinois'},
        {'city': 'Bolivar', 'state': 'Missouri'},
        {'city': 'Bon Air', 'state': 'Virginia'},
        {'city': 'Bonadelle Ranchos-Madera Ranchos', 'state': 'California'},
        {'city': 'Bonham', 'state': 'Texas'},
        {'city': 'Bonita', 'state': 'California'},
        {'city': 'Bonita Springs', 'state': 'Florida'},
        {'city': 'Bonner Springs', 'state': 'Kansas'},
        {'city': 'Bonney Lake', 'state': 'Washington'},
        {'city': 'Boone', 'state': 'Iowa'},
        {'city': 'Boone', 'state': 'North Carolina'},
        {'city': 'Booneville', 'state': 'Mississippi'},
        {'city': 'Boonton', 'state': 'New Jersey'},
        {'city': 'Boonville', 'state': 'Missouri'},
        {'city': 'Boonville', 'state': 'Indiana'},
        {'city': 'Borger', 'state': 'Texas'},
        {'city': 'Bossier City', 'state': 'Louisiana'},
        {'city': 'Boston', 'state': 'Massachusetts'},
        {'city': 'Boston', 'state': 'New York'},
        {'city': 'Bostonia', 'state': 'California'},
        {'city': 'Bothell', 'state': 'Washington'},
        {'city': 'Boulder', 'state': 'Colorado'},
        {'city': 'Boulder City', 'state': 'Nevada'},
        {'city': 'Boulder Hill', 'state': 'Illinois'},
        {'city': 'Bound Brook', 'state': 'New Jersey'},
        {'city': 'Bountiful', 'state': 'Utah'},
        {'city': 'Bourbonnais', 'state': 'Illinois'},
        {'city': 'Bourne', 'state': 'Massachusetts'},
        {'city': 'Bow', 'state': 'New Hampshire'},
        {'city': 'Bowie', 'state': 'Maryland'},
        {'city': 'Bowleys Quarters', 'state': 'Maryland'},
        {'city': 'Bowling Green', 'state': 'Kentucky'},
        {'city': 'Bowling Green', 'state': 'Ohio'},
        {'city': 'Boxford', 'state': 'Massachusetts'},
        {'city': 'Boyes Hot Springs', 'state': 'California'},
        {'city': 'Boynton Beach', 'state': 'Florida'},
        {'city': 'Bozeman', 'state': 'Montana'},
        {'city': 'Bradenton', 'state': 'Florida'},
        {'city': 'Bradford', 'state': 'Pennsylvania'},
        {'city': 'Bradley', 'state': 'Illinois'},
        {'city': 'Brainerd', 'state': 'Minnesota'},
        {'city': 'Braintree', 'state': 'Massachusetts'},
        {'city': 'Braintree', 'state': 'Massachusetts'},
        {'city': 'Brandon', 'state': 'Mississippi'},
        {'city': 'Brandon', 'state': 'Florida'},
        {'city': 'Branford', 'state': 'Connecticut'},
        {'city': 'Branson', 'state': 'Missouri'},
        {'city': 'Brattleboro', 'state': 'Vermont'},
        {'city': 'Brattleboro', 'state': 'Vermont'},
        {'city': 'Brawley', 'state': 'California'},
        {'city': 'Brazil', 'state': 'Indiana'},
        {'city': 'Brea', 'state': 'California'},
        {'city': 'Breaux Bridge', 'state': 'Louisiana'},
        {'city': 'Brecksville', 'state': 'Ohio'},
        {'city': 'Bremerton', 'state': 'Washington'},
        {'city': 'Brenham', 'state': 'Texas'},
        {'city': 'Brent', 'state': 'Florida'},
        {'city': 'Brentwood', 'state': 'California'},
        {'city': 'Brentwood', 'state': 'New York'},
        {'city': 'Brentwood', 'state': 'Missouri'},
        {'city': 'Brentwood', 'state': 'Pennsylvania'},
        {'city': 'Brentwood', 'state': 'Tennessee'},
        {'city': 'Brevard', 'state': 'North Carolina'},
        {'city': 'Brewer', 'state': 'Maine'},
        {'city': 'Brewster', 'state': 'Massachusetts'},
        {'city': 'Briarcliff Manor', 'state': 'New York'},
        {'city': 'Bridge City', 'state': 'Louisiana'},
        {'city': 'Bridge City', 'state': 'Texas'},
        {'city': 'Bridgeport', 'state': 'West Virginia'},
        {'city': 'Bridgeport', 'state': 'Connecticut'},
        {'city': 'Bridgeport', 'state': 'Connecticut'},
        {'city': 'Bridgeport', 'state': 'Michigan'},
        {'city': 'Bridgeton', 'state': 'Missouri'},
        {'city': 'Bridgeton', 'state': 'New Jersey'},
        {'city': 'Bridgetown North', 'state': 'Ohio'},
        {'city': 'Bridgeview', 'state': 'Illinois'},
        {'city': 'Bridgewater', 'state': 'Massachusetts'},
        {'city': 'Bridgewater', 'state': 'Massachusetts'},
        {'city': 'Brier', 'state': 'Washington'},
        {'city': 'Brigantine', 'state': 'New Jersey'},
        {'city': 'Brigham City', 'state': 'Utah'},
        {'city': 'Brighton', 'state': 'Michigan'},
        {'city': 'Brighton', 'state': 'New York'},
        {'city': 'Brighton', 'state': 'New York'},
        {'city': 'Brighton', 'state': 'Colorado'},
        {'city': 'Bristol', 'state': 'Connecticut'},
        {'city': 'Bristol', 'state': 'Connecticut'},
        {'city': 'Bristol', 'state': 'Rhode Island'},
        {'city': 'Bristol', 'state': 'Tennessee'},
        {'city': 'Bristol', 'state': 'Rhode Island'},
        {'city': 'Bristol', 'state': 'Virginia'},
        {'city': 'Bristol', 'state': 'Pennsylvania'},
        {'city': 'Broadview', 'state': 'Illinois'},
        {'city': 'Broadview Heights', 'state': 'Ohio'},
        {'city': 'Broadview Park', 'state': 'Florida'},
        {'city': 'Brockport', 'state': 'New York'},
        {'city': 'Brockton', 'state': 'Massachusetts'},
        {'city': 'Broken Arrow', 'state': 'Oklahoma'},
        {'city': 'Bronxville', 'state': 'New York'},
        {'city': 'Brook Park', 'state': 'Ohio'},
        {'city': 'Brookfield', 'state': 'Connecticut'},
        {'city': 'Brookfield', 'state': 'Illinois'},
        {'city': 'Brookfield', 'state': 'Wisconsin'},
        {'city': 'Brookfield', 'state': 'Wisconsin'},
        {'city': 'Brookhaven', 'state': 'Pennsylvania'},
        {'city': 'Brookhaven', 'state': 'New York'},
        {'city': 'Brookhaven', 'state': 'Mississippi'},
        {'city': 'Brookings', 'state': 'South Dakota'},
        {'city': 'Brookline', 'state': 'Massachusetts'},
        {'city': 'Brookline', 'state': 'Massachusetts'},
        {'city': 'Brooklyn', 'state': 'Ohio'},
        {'city': 'Brooklyn', 'state': 'Connecticut'},
        {'city': 'Brooklyn Center', 'state': 'Minnesota'},
        {'city': 'Brooklyn Park', 'state': 'Minnesota'},
        {'city': 'Brooklyn Park', 'state': 'Maryland'},
        {'city': 'Brookside', 'state': 'Delaware'},
        {'city': 'Brooksville', 'state': 'Florida'},
        {'city': 'Broomall', 'state': 'Pennsylvania'},
        {'city': 'Broomfield', 'state': 'Colorado'},
        {'city': 'Brown Deer', 'state': 'Wisconsin'},
        {'city': 'Brownfield', 'state': 'Texas'},
        {'city': 'Browns Mills', 'state': 'New Jersey'},
        {'city': 'Brownsburg', 'state': 'Indiana'},
        {'city': 'Brownsville', 'state': 'Florida'},
        {'city': 'Brownsville', 'state': 'Texas'},
        {'city': 'Brownsville', 'state': 'Tennessee'},
        {'city': 'Brownsville-Bawcomville', 'state': 'Louisiana'},
        {'city': 'Brownwood', 'state': 'Texas'},
        {'city': 'Brunswick', 'state': 'Maine'},
        {'city': 'Brunswick', 'state': 'Maine'},
        {'city': 'Brunswick', 'state': 'Georgia'},
        {'city': 'Brunswick', 'state': 'Ohio'},
        {'city': 'Brunswick', 'state': 'New York'},
        {'city': 'Brushy Creek', 'state': 'Texas'},
        {'city': 'Bryan', 'state': 'Texas'},
        {'city': 'Bryan', 'state': 'Ohio'},
        {'city': 'Bryant', 'state': 'Arkansas'},
        {'city': 'Bryn Mawr-Skyway', 'state': 'Washington'},
        {'city': 'Buckeye', 'state': 'Arizona'},
        {'city': 'Bucyrus', 'state': 'Ohio'},
        {'city': 'Budd Lake', 'state': 'New Jersey'},
        {'city': 'Buechel', 'state': 'Kentucky'},
        {'city': 'Buena Park', 'state': 'California'},
        {'city': 'Buena Vista', 'state': 'Michigan'},
        {'city': 'Buena Vista', 'state': 'Virginia'},
        {'city': 'Buffalo', 'state': 'Minnesota'},
        {'city': 'Buffalo', 'state': 'New York'},
        {'city': 'Buffalo Grove', 'state': 'Illinois'},
        {'city': 'Buford', 'state': 'Georgia'},
        {'city': 'Bull Run', 'state': 'Virginia'},
        {'city': 'Bullhead City', 'state': 'Arizona'},
        {'city': 'Burbank', 'state': 'California'},
        {'city': 'Burbank', 'state': 'Illinois'},
        {'city': 'Burien', 'state': 'Washington'},
        {'city': 'Burkburnett', 'state': 'Texas'},
        {'city': 'Burke', 'state': 'Virginia'},
        {'city': 'Burleson', 'state': 'Texas'},
        {'city': 'Burley', 'state': 'Idaho'},
        {'city': 'Burlingame', 'state': 'California'},
        {'city': 'Burlington', 'state': 'Connecticut'},
        {'city': 'Burlington', 'state': 'Kentucky'},
        {'city': 'Burlington', 'state': 'Iowa'},
        {'city': 'Burlington', 'state': 'North Carolina'},
        {'city': 'Burlington', 'state': 'Massachusetts'},
        {'city': 'Burlington', 'state': 'Massachusetts'},
        {'city': 'Burlington', 'state': 'New Jersey'},
        {'city': 'Burlington', 'state': 'Vermont'},
        {'city': 'Burlington', 'state': 'Washington'},
        {'city': 'Burlington', 'state': 'Wisconsin'},
        {'city': 'Burlington', 'state': 'Wisconsin'},
        {'city': 'Burnsville', 'state': 'Minnesota'},
        {'city': 'Burr Ridge', 'state': 'Illinois'},
        {'city': 'Burrillville', 'state': 'Rhode Island'},
        {'city': 'Burton', 'state': 'South Carolina'},
        {'city': 'Burton', 'state': 'Michigan'},
        {'city': 'Burtonsville', 'state': 'Maryland'},
        {'city': 'Busti', 'state': 'New York'},
        {'city': 'Butler', 'state': 'New Jersey'},
        {'city': 'Butler', 'state': 'Pennsylvania'},
        {'city': 'Butte-Silver Bow', 'state': 'Montana'},
        {'city': 'Buxton', 'state': 'Maine'},
        {'city': 'Byram', 'state': 'Mississippi'},

        {'city': 'Cabot', 'state': 'Arkansas'},
        {'city': 'Cadillac', 'state': 'Michigan'},
        {'city': 'Cahokia', 'state': 'Illinois'},
        {'city': 'Cairo', 'state': 'Georgia'},
        {'city': 'Cairo', 'state': 'New York'},
        {'city': 'Calabasas', 'state': 'California'},
        {'city': 'Caldwell', 'state': 'New Jersey'},
        {'city': 'Caldwell', 'state': 'Idaho'},
        {'city': 'Caledonia', 'state': 'Wisconsin'},
        {'city': 'Calexico', 'state': 'California'},
        {'city': 'Calhoun', 'state': 'Georgia'},
        {'city': 'California', 'state': 'Maryland'},
        {'city': 'California City', 'state': 'California'},
        {'city': 'Calimesa', 'state': 'California'},
        {'city': 'Calipatria', 'state': 'California'},
        {'city': 'Callaway', 'state': 'Florida'},
        {'city': 'Calumet City', 'state': 'Illinois'},
        {'city': 'Calumet Park', 'state': 'Illinois'},
        {'city': 'Calverton', 'state': 'Maryland'},
        {'city': 'Camano', 'state': 'Washington'},
        {'city': 'Camarillo', 'state': 'California'},
        {'city': 'Camas', 'state': 'Washington'},
        {'city': 'Cambria', 'state': 'California'},
        {'city': 'Cambridge', 'state': 'Maryland'},
        {'city': 'Cambridge', 'state': 'Massachusetts'},
        {'city': 'Cambridge', 'state': 'Ohio'},
        {'city': 'Camden', 'state': 'South Carolina'},
        {'city': 'Camden', 'state': 'New Jersey'},
        {'city': 'Camden', 'state': 'Arkansas'},
        {'city': 'Cameron', 'state': 'Missouri'},
        {'city': 'Cameron Park', 'state': 'California'},
        {'city': 'Camillus', 'state': 'New York'},
        {'city': 'Camp Hill', 'state': 'Pennsylvania'},
        {'city': 'Camp Pendleton North', 'state': 'California'},
        {'city': 'Camp Pendleton South', 'state': 'California'},
        {'city': 'Camp Springs', 'state': 'Maryland'},
        {'city': 'Camp Verde', 'state': 'Arizona'},
        {'city': 'Campbell', 'state': 'California'},
        {'city': 'Campbell', 'state': 'Ohio'},
        {'city': 'Campbellsville', 'state': 'Kentucky'},
        {'city': 'Canandaigua', 'state': 'New York'},
        {'city': 'Canandaigua', 'state': 'New York'},
        {'city': 'Canby', 'state': 'Oregon'},
        {'city': 'Candler-McAfee', 'state': 'Georgia'},
        {'city': 'Canfield', 'state': 'Ohio'},
        {'city': 'Canon City', 'state': 'Colorado'},
        {'city': 'Canonsburg', 'state': 'Pennsylvania'},
        {'city': 'Canton', 'state': 'Ohio'},
        {'city': 'Canton', 'state': 'Georgia'},
        {'city': 'Canton', 'state': 'Illinois'},
        {'city': 'Canton', 'state': 'Connecticut'},
        {'city': 'Canton', 'state': 'New York'},
        {'city': 'Canton', 'state': 'Mississippi'},
        {'city': 'Canton', 'state': 'Massachusetts'},
        {'city': 'Canton', 'state': 'Michigan'},
        {'city': 'Canyon', 'state': 'Texas'},
        {'city': 'Canyon Lake', 'state': 'Texas'},
        {'city': 'Canyon Lake', 'state': 'California'},
        {'city': 'Canyon Rim', 'state': 'Utah'},
        {'city': 'Cape Canaveral', 'state': 'Florida'},
        {'city': 'Cape Coral', 'state': 'Florida'},
        {'city': 'Cape Elizabeth', 'state': 'Maine'},
        {'city': 'Cape Girardeau', 'state': 'Missouri'},
        {'city': 'Cape St. Claire', 'state': 'Maryland'},
        {'city': 'Capitola', 'state': 'California'},
        {'city': 'Carbondale', 'state': 'Illinois'},
        {'city': 'Carbondale', 'state': 'Pennsylvania'},
        {'city': 'Carencro', 'state': 'Louisiana'},
        {'city': 'Caribou', 'state': 'Maine'},
        {'city': 'Carlisle', 'state': 'Pennsylvania'},
        {'city': 'Carlsbad', 'state': 'New Mexico'},
        {'city': 'Carlsbad', 'state': 'California'},
        {'city': 'Carmel', 'state': 'Indiana'},
        {'city': 'Carmel', 'state': 'New York'},
        {'city': 'Carmichael', 'state': 'California'},
        {'city': 'Carnegie', 'state': 'Pennsylvania'},
        {'city': 'Carney', 'state': 'Maryland'},
        {'city': 'Carneys Point', 'state': 'New Jersey'},
        {'city': 'Carnot-Moon', 'state': 'Pennsylvania'},
        {'city': 'Carol City', 'state': 'Florida'},
        {'city': 'Carol Stream', 'state': 'Illinois'},
        {'city': 'Carpentersville', 'state': 'Illinois'},
        {'city': 'Carpinteria', 'state': 'California'},
        {'city': 'Carrboro', 'state': 'North Carolina'},
        {'city': 'Carroll', 'state': 'Iowa'},
        {'city': 'Carrollton', 'state': 'Michigan'},
        {'city': 'Carrollton', 'state': 'Georgia'},
        {'city': 'Carrollton', 'state': 'Texas'},
        {'city': 'Carson', 'state': 'California'},
        {'city': 'Carson City', 'state': 'Nevada'},
        {'city': 'Carteret', 'state': 'New Jersey'},
        {'city': 'Cartersville', 'state': 'Georgia'},
        {'city': 'Carthage', 'state': 'Missouri'},
        {'city': 'Carthage', 'state': 'Texas'},
        {'city': 'Caruthersville', 'state': 'Missouri'},
        {'city': 'Carver', 'state': 'Massachusetts'},
        {'city': 'Cary', 'state': 'Illinois'},
        {'city': 'Cary', 'state': 'North Carolina'},
        {'city': 'Casa de Oro-Mount Helix', 'state': 'California'},
        {'city': 'Casa Grande', 'state': 'Arizona'},
        {'city': 'Casas Adobes', 'state': 'Arizona'},
        {'city': 'Cascade-Fairwood', 'state': 'Washington'},
        {'city': 'Casper', 'state': 'Wyoming'},
        {'city': 'Casselberry', 'state': 'Florida'},
        {'city': 'Castle Rock', 'state': 'Colorado'},
        {'city': 'Castle Shannon', 'state': 'Pennsylvania'},
        {'city': 'Castlewood', 'state': 'Colorado'},
        {'city': 'Castro Valley', 'state': 'California'},
        {'city': 'Castroville', 'state': 'California'},
        {'city': 'Catalina', 'state': 'Arizona'},
        {'city': 'Catalina Foothills', 'state': 'Arizona'},
        {'city': 'Catasauqua', 'state': 'Pennsylvania'},
        {'city': 'Cathedral City', 'state': 'California'},
        {'city': 'Catonsville', 'state': 'Maryland'},
        {'city': 'Catskill', 'state': 'New York'},
        {'city': 'Cave Spring', 'state': 'Virginia'},
        {'city': 'Cayce', 'state': 'South Carolina'},
        {'city': 'Cazenovia', 'state': 'New York'},
        {'city': 'Cedar City', 'state': 'Utah'},
        {'city': 'Cedar Falls', 'state': 'Iowa'},
        {'city': 'Cedar Grove', 'state': 'New Jersey'},
        {'city': 'Cedar Hill', 'state': 'Texas'},
        {'city': 'Cedar Hills', 'state': 'Oregon'},
        {'city': 'Cedar Lake', 'state': 'Indiana'},
        {'city': 'Cedar Mill', 'state': 'Oregon'},
        {'city': 'Cedar Park', 'state': 'Texas'},
        {'city': 'Cedar Rapids', 'state': 'Iowa'},
        {'city': 'Cedarburg', 'state': 'Wisconsin'},
        {'city': 'Cedarhurst', 'state': 'New York'},
        {'city': 'Cedartown', 'state': 'Georgia'},
        {'city': 'Celina', 'state': 'Ohio'},
        {'city': 'Center Line', 'state': 'Michigan'},
        {'city': 'Center Moriches', 'state': 'New York'},
        {'city': 'Center Point', 'state': 'Alabama'},
        {'city': 'Centereach', 'state': 'New York'},
        {'city': 'Centerville', 'state': 'Ohio'},
        {'city': 'Centerville', 'state': 'Utah'},
        {'city': 'Central Falls', 'state': 'Rhode Island'},
        {'city': 'Central Islip', 'state': 'New York'},
        {'city': 'Central Manchester', 'state': 'Connecticut'},
        {'city': 'Central Point', 'state': 'Oregon'},
        {'city': 'Centralia', 'state': 'Washington'},
        {'city': 'Centralia', 'state': 'Illinois'},
        {'city': 'Centreville', 'state': 'Virginia'},
        {'city': 'Century Village', 'state': 'Florida'},
        {'city': 'Ceres', 'state': 'California'},
        {'city': 'Cerritos', 'state': 'California'},
        {'city': 'Chalco', 'state': 'Nebraska'},
        {'city': 'Chalmette', 'state': 'Louisiana'},
        {'city': 'Chambersburg', 'state': 'Pennsylvania'},
        {'city': 'Chamblee', 'state': 'Georgia'},
        {'city': 'Champaign', 'state': 'Illinois'},
        {'city': 'Champlin', 'state': 'Minnesota'},
        {'city': 'Chandler', 'state': 'Arizona'},
        {'city': 'Chanhassen', 'state': 'Minnesota'},
        {'city': 'Channahon', 'state': 'Illinois'},
        {'city': 'Channelview', 'state': 'Texas'},
        {'city': 'Chantilly', 'state': 'Virginia'},
        {'city': 'Chanute', 'state': 'Kansas'},
        {'city': 'Chaparral', 'state': 'New Mexico'},
        {'city': 'Chapel Hill', 'state': 'North Carolina'},
        {'city': 'Chappaqua', 'state': 'New York'},
        {'city': 'Charles City', 'state': 'Iowa'},
        {'city': 'Charleston', 'state': 'Illinois'},
        {'city': 'Charleston', 'state': 'West Virginia'},
        {'city': 'Charleston', 'state': 'South Carolina'},
        {'city': 'Charlestown', 'state': 'Rhode Island'},
        {'city': 'Charlotte', 'state': 'North Carolina'},
        {'city': 'Charlotte', 'state': 'Michigan'},
        {'city': 'Charlottesville', 'state': 'Virginia'},
        {'city': 'Charlton', 'state': 'Massachusetts'},
        {'city': 'Charter Oak', 'state': 'California'},
        {'city': 'Chaska', 'state': 'Minnesota'},
        {'city': 'Chatham', 'state': 'Massachusetts'},
        {'city': 'Chatham', 'state': 'New Jersey'},
        {'city': 'Chatham', 'state': 'Illinois'},
        {'city': 'Chattanooga', 'state': 'Tennessee'},
        {'city': 'Cheat Lake', 'state': 'West Virginia'},
        {'city': 'Cheektowaga', 'state': 'New York'},
        {'city': 'Cheektowaga', 'state': 'New York'},
        {'city': 'Chehalis', 'state': 'Washington'},
        {'city': 'Chelmsford', 'state': 'Massachusetts'},
        {'city': 'Chelsea', 'state': 'Massachusetts'},
        {'city': 'Chenango', 'state': 'New York'},
        {'city': 'Cheney', 'state': 'Washington'},
        {'city': 'Cherry Hill Mall', 'state': 'New Jersey'},
        {'city': 'Cherryland', 'state': 'California'},
        {'city': 'Chesapeake', 'state': 'Virginia'},
        {'city': 'Chesapeake Ranch Estates-Drum Point', 'state': 'Maryland'},
        {'city': 'Cheshire', 'state': 'Connecticut'},
        {'city': 'Chester', 'state': 'New York'},
        {'city': 'Chester', 'state': 'Virginia'},
        {'city': 'Chester', 'state': 'South Carolina'},
        {'city': 'Chester', 'state': 'Pennsylvania'},
        {'city': 'Chesterfield', 'state': 'Missouri'},
        {'city': 'Chesterton', 'state': 'Indiana'},
        {'city': 'Chestnut Ridge', 'state': 'New York'},
        {'city': 'Cheval', 'state': 'Florida'},
        {'city': 'Cheverly', 'state': 'Maryland'},
        {'city': 'Cheviot', 'state': 'Ohio'},
        {'city': 'Chevy Chase', 'state': 'Maryland'},
        {'city': 'Cheyenne', 'state': 'Wyoming'},
        {'city': 'Chicago', 'state': 'Illinois'},
        {'city': 'Chicago Heights', 'state': 'Illinois'},
        {'city': 'Chicago Ridge', 'state': 'Illinois'},
        {'city': 'Chickasaw', 'state': 'Alabama'},
        {'city': 'Chickasha', 'state': 'Oklahoma'},
        {'city': 'Chico', 'state': 'California'},
        {'city': 'Chicopee', 'state': 'Massachusetts'},
        {'city': 'Childress', 'state': 'Texas'},
        {'city': 'Chili', 'state': 'New York'},
        {'city': 'Chillicothe', 'state': 'Missouri'},
        {'city': 'Chillicothe', 'state': 'Ohio'},
        {'city': 'Chillum', 'state': 'Maryland'},
        {'city': 'Chino', 'state': 'California'},
        {'city': 'Chino Hills', 'state': 'California'},
        {'city': 'Chino Valley', 'state': 'Arizona'},
        {'city': 'Chippewa Falls', 'state': 'Wisconsin'},
        {'city': 'Choctaw', 'state': 'Oklahoma'},
        {'city': 'Chowchilla', 'state': 'California'},
        {'city': 'Christiansburg', 'state': 'Virginia'},
        {'city': 'Chubbuck', 'state': 'Idaho'},
        {'city': 'Chula Vista', 'state': 'California'},
        {'city': 'Cicero', 'state': 'Illinois'},
        {'city': 'Cicero', 'state': 'New York'},
        {'city': 'Cimarron Hills', 'state': 'Colorado'},
        {'city': 'Cincinnati', 'state': 'Ohio'},
        {'city': 'Cinco Ranch', 'state': 'Texas'},
        {'city': 'Circleville', 'state': 'Ohio'},
        {'city': 'Citrus', 'state': 'California'},
        {'city': 'Citrus Heights', 'state': 'California'},
        {'city': 'Citrus Park', 'state': 'Florida'},
        {'city': 'Citrus Ridge', 'state': 'Florida'},
        {'city': 'City of The Dalles', 'state': 'Oregon'},
        {'city': 'Claiborne', 'state': 'Louisiana'},
        {'city': 'Clairton', 'state': 'Pennsylvania'},
        {'city': 'Clanton', 'state': 'Alabama'},
        {'city': 'Claremont', 'state': 'California'},
        {'city': 'Claremont', 'state': 'New Hampshire'},
        {'city': 'Claremore', 'state': 'Oklahoma'},
        {'city': 'Clarence', 'state': 'New York'},
        {'city': 'Clarendon Hills', 'state': 'Illinois'},
        {'city': 'Clarion', 'state': 'Pennsylvania'},
        {'city': 'Clark', 'state': 'New Jersey'},
        {'city': 'Clarksburg', 'state': 'West Virginia'},
        {'city': 'Clarksdale', 'state': 'Mississippi'},
        {'city': 'Clarkson', 'state': 'New York'},
        {'city': 'Clarkston', 'state': 'Georgia'},
        {'city': 'Clarkston', 'state': 'Washington'},
        {'city': 'Clarkston Heights-Vineland', 'state': 'Washington'},
        {'city': 'Clarkstown', 'state': 'New York'},
        {'city': 'Clarksville', 'state': 'Indiana'},
        {'city': 'Clarksville', 'state': 'Arkansas'},
        {'city': 'Clarksville', 'state': 'Tennessee'},
        {'city': 'Claverack', 'state': 'New York'},
        {'city': 'Clawson', 'state': 'Michigan'},
        {'city': 'Clay', 'state': 'New York'},
        {'city': 'Claymont', 'state': 'Delaware'},
        {'city': 'Clayton', 'state': 'California'},
        {'city': 'Clayton', 'state': 'Missouri'},
        {'city': 'Clayton', 'state': 'New Jersey'},
        {'city': 'Clayton', 'state': 'Ohio'},
        {'city': 'Clayton', 'state': 'North Carolina'},
        {'city': 'Clear Lake', 'state': 'Iowa'},
        {'city': 'Clearfield', 'state': 'Pennsylvania'},
        {'city': 'Clearfield', 'state': 'Utah'},
        {'city': 'Clearlake', 'state': 'California'},
        {'city': 'Clearwater', 'state': 'Florida'},
        {'city': 'Cleburne', 'state': 'Texas'},
        {'city': 'Clemmons', 'state': 'North Carolina'},
        {'city': 'Clemson', 'state': 'South Carolina'},
        {'city': 'Clermont', 'state': 'Florida'},
        {'city': 'Cleveland', 'state': 'Mississippi'},
        {'city': 'Cleveland', 'state': 'Tennessee'},
        {'city': 'Cleveland', 'state': 'Texas'},
        {'city': 'Cleveland', 'state': 'Ohio'},
        {'city': 'Cleveland Heights', 'state': 'Ohio'},
        {'city': 'Clewiston', 'state': 'Florida'},
        {'city': 'Cliffside Park', 'state': 'New Jersey'},
        {'city': 'Clifton', 'state': 'New Jersey'},
        {'city': 'Clifton', 'state': 'Colorado'},
        {'city': 'Clifton Heights', 'state': 'Pennsylvania'},
        {'city': 'Clifton Park', 'state': 'New York'},
        {'city': 'Clinton', 'state': 'Mississippi'},
        {'city': 'Clinton', 'state': 'Missouri'},
        {'city': 'Clinton', 'state': 'Iowa'},
        {'city': 'Clinton', 'state': 'Maryland'},
        {'city': 'Clinton', 'state': 'Michigan'},
        {'city': 'Clinton', 'state': 'Massachusetts'},
        {'city': 'Clinton', 'state': 'Massachusetts'},
        {'city': 'Clinton', 'state': 'Connecticut'},
        {'city': 'Clinton', 'state': 'Illinois'},
        {'city': 'Clinton', 'state': 'Oklahoma'},
        {'city': 'Clinton', 'state': 'North Carolina'},
        {'city': 'Clinton', 'state': 'Tennessee'},
        {'city': 'Clinton', 'state': 'South Carolina'},
        {'city': 'Clinton', 'state': 'Utah'},
        {'city': 'Clive', 'state': 'Iowa'},
        {'city': 'Cloquet', 'state': 'Minnesota'},
        {'city': 'Closter', 'state': 'New Jersey'},
        {'city': 'Cloverdale', 'state': 'California'},
        {'city': 'Cloverleaf', 'state': 'Texas'},
        {'city': 'Cloverly', 'state': 'Maryland'},
        {'city': 'Clovis', 'state': 'New Mexico'},
        {'city': 'Clovis', 'state': 'California'},
        {'city': 'Clute', 'state': 'Texas'},
        {'city': 'Clyde', 'state': 'Ohio'},
        {'city': 'Coachella', 'state': 'California'},
        {'city': 'Coalinga', 'state': 'California'},
        {'city': 'Coatesville', 'state': 'Pennsylvania'},
        {'city': 'Cobleskill', 'state': 'New York'},
        {'city': 'Cochituate', 'state': 'Massachusetts'},
        {'city': 'Cockeysville', 'state': 'Maryland'},
        {'city': 'Cocoa', 'state': 'Florida'},
        {'city': 'Cocoa Beach', 'state': 'Florida'},
        {'city': 'Coconut Creek', 'state': 'Florida'},
        {'city': 'Cody', 'state': 'Wyoming'},
        {'city': 'Coeur d’Alene', 'state': 'Idaho'},
        {'city': 'Coeymans', 'state': 'New York'},
        {'city': 'Coffeyville', 'state': 'Kansas'},
        {'city': 'Cohasset', 'state': 'Massachusetts'},
        {'city': 'Cohoes', 'state': 'New York'},
        {'city': 'Colchester', 'state': 'Vermont'},
        {'city': 'Colchester', 'state': 'Connecticut'},
        {'city': 'Coldwater', 'state': 'Michigan'},
        {'city': 'Colesville', 'state': 'Maryland'},
        {'city': 'College', 'state': 'Alaska'},
        {'city': 'College Park', 'state': 'Georgia'},
        {'city': 'College Park', 'state': 'Maryland'},
        {'city': 'College Place', 'state': 'Washington'},
        {'city': 'College Station', 'state': 'Texas'},
        {'city': 'Collegedale', 'state': 'Tennessee'},
        {'city': 'Collegeville', 'state': 'Pennsylvania'},
        {'city': 'Colleyville', 'state': 'Texas'},
        {'city': 'Collier Manor-Cresthaven', 'state': 'Florida'},
        {'city': 'Collierville', 'state': 'Tennessee'},
        {'city': 'Collingdale', 'state': 'Pennsylvania'},
        {'city': 'Collingswood', 'state': 'New Jersey'},
        {'city': 'Collins', 'state': 'New York'},
        {'city': 'Collinsville', 'state': 'Illinois'},
        {'city': 'Collinsville', 'state': 'Virginia'},
        {'city': 'Colonia', 'state': 'New Jersey'},
        {'city': 'Colonial Heights', 'state': 'Virginia'},
        {'city': 'Colonial Heights', 'state': 'Tennessee'},
        {'city': 'Colonial Park', 'state': 'Pennsylvania'},
        {'city': 'Colonie', 'state': 'New York'},
        {'city': 'Colonie', 'state': 'New York'},
        {'city': 'Colorado Springs', 'state': 'Colorado'},
        {'city': 'Colton', 'state': 'California'},
        {'city': 'Columbia', 'state': 'Illinois'},
        {'city': 'Columbia', 'state': 'Missouri'},
        {'city': 'Columbia', 'state': 'Mississippi'},
        {'city': 'Columbia', 'state': 'Maryland'},
        {'city': 'Columbia', 'state': 'Pennsylvania'},
        {'city': 'Columbia', 'state': 'Tennessee'},
        {'city': 'Columbia', 'state': 'South Carolina'},
        {'city': 'Columbia City', 'state': 'Indiana'},
        {'city': 'Columbia Heights', 'state': 'Minnesota'},
        {'city': 'Columbine', 'state': 'Colorado'},
        {'city': 'Columbus', 'state': 'Indiana'},
        {'city': 'Columbus', 'state': 'Georgia'},
        {'city': 'Columbus', 'state': 'Mississippi'},
        {'city': 'Columbus', 'state': 'Nebraska'},
        {'city': 'Columbus', 'state': 'Ohio'},
        {'city': 'Commack', 'state': 'New York'},
        {'city': 'Commerce', 'state': 'Texas'},
        {'city': 'Commerce', 'state': 'California'},
        {'city': 'Commerce City', 'state': 'Colorado'},
        {'city': 'Compton', 'state': 'California'},
        {'city': 'Comstock Park', 'state': 'Michigan'},
        {'city': 'Concord', 'state': 'Massachusetts'},
        {'city': 'Concord', 'state': 'Missouri'},
        {'city': 'Concord', 'state': 'New Hampshire'},
        {'city': 'Concord', 'state': 'New York'},
        {'city': 'Concord', 'state': 'California'},
        {'city': 'Concord', 'state': 'North Carolina'},
        {'city': 'Congers', 'state': 'New York'},
        {'city': 'Conley', 'state': 'Georgia'},
        {'city': 'Conneaut', 'state': 'Ohio'},
        {'city': 'Connellsville', 'state': 'Pennsylvania'},
        {'city': 'Connersville', 'state': 'Indiana'},
        {'city': 'Conning Towers-Nautilus Park', 'state': 'Connecticut'},
        {'city': 'Conover', 'state': 'North Carolina'},
        {'city': 'Conroe', 'state': 'Texas'},
        {'city': 'Conshohocken', 'state': 'Pennsylvania'},
        {'city': 'Converse', 'state': 'Texas'},
        {'city': 'Conway', 'state': 'South Carolina'},
        {'city': 'Conway', 'state': 'Florida'},
        {'city': 'Conway', 'state': 'Arkansas'},
        {'city': 'Conway', 'state': 'New Hampshire'},
        {'city': 'Conyers', 'state': 'Georgia'},
        {'city': 'Cookeville', 'state': 'Tennessee'},
        {'city': 'Coolidge', 'state': 'Arizona'},
        {'city': 'Coon Rapids', 'state': 'Minnesota'},
        {'city': 'Cooper City', 'state': 'Florida'},
        {'city': 'Coos Bay', 'state': 'Oregon'},
        {'city': 'Copiague', 'state': 'New York'},
        {'city': 'Coppell', 'state': 'Texas'},
        {'city': 'Copperas Cove', 'state': 'Texas'},
        {'city': 'Coral Gables', 'state': 'Florida'},
        {'city': 'Coral Hills', 'state': 'Maryland'},
        {'city': 'Coral Springs', 'state': 'Florida'},
        {'city': 'Coral Terrace', 'state': 'Florida'},
        {'city': 'Coralville', 'state': 'Iowa'},
        {'city': 'Coram', 'state': 'New York'},
        {'city': 'Coraopolis', 'state': 'Pennsylvania'},
        {'city': 'Corbin', 'state': 'Kentucky'},
        {'city': 'Corcoran', 'state': 'California'},
        {'city': 'Cordele', 'state': 'Georgia'},
        {'city': 'Corinth', 'state': 'Mississippi'},
        {'city': 'Corinth', 'state': 'Texas'},
        {'city': 'Cornelius', 'state': 'Oregon'},
        {'city': 'Cornelius', 'state': 'North Carolina'},
        {'city': 'Corning', 'state': 'New York'},
        {'city': 'Corning', 'state': 'New York'},
        {'city': 'Corning', 'state': 'California'},
        {'city': 'Cornwall', 'state': 'New York'},
        {'city': 'Corona', 'state': 'California'},
        {'city': 'Coronado', 'state': 'California'},
        {'city': 'Corpus Christi', 'state': 'Texas'},
        {'city': 'Corrales', 'state': 'New Mexico'},
        {'city': 'Corry', 'state': 'Pennsylvania'},
        {'city': 'Corsicana', 'state': 'Texas'},
        {'city': 'Corte Madera', 'state': 'California'},
        {'city': 'Cortez', 'state': 'Colorado'},
        {'city': 'Cortland', 'state': 'New York'},
        {'city': 'Cortland', 'state': 'Ohio'},
        {'city': 'Cortlandt', 'state': 'New York'},
        {'city': 'Cortlandville', 'state': 'New York'},
        {'city': 'Corvallis', 'state': 'Oregon'},
        {'city': 'Coshocton', 'state': 'Ohio'},
        {'city': 'Costa Mesa', 'state': 'California'},
        {'city': 'Cotati', 'state': 'California'},
        {'city': 'Coto de Caza', 'state': 'California'},
        {'city': 'Cottage Grove', 'state': 'Minnesota'},
        {'city': 'Cottage Grove', 'state': 'Oregon'},
        {'city': 'Cottage Lake', 'state': 'Washington'},
        {'city': 'Cottonwood', 'state': 'Arizona'},
        {'city': 'Cottonwood Heights', 'state': 'Utah'},
        {'city': 'Cottonwood West', 'state': 'Utah'},
        {'city': 'Cottonwood-Verde Village', 'state': 'Arizona'},
        {'city': 'Council Bluffs', 'state': 'Iowa'},
        {'city': 'Country Club', 'state': 'California'},
        {'city': 'Country Club', 'state': 'Florida'},
        {'city': 'Country Club Estates', 'state': 'Georgia'},
        {'city': 'Country Club Hills', 'state': 'Illinois'},
        {'city': 'Country Walk', 'state': 'Florida'},
        {'city': 'Covedale', 'state': 'Ohio'},
        {'city': 'Coventry', 'state': 'Rhode Island'},
        {'city': 'Coventry', 'state': 'Connecticut'},
        {'city': 'Covina', 'state': 'California'},
        {'city': 'Covington', 'state': 'Georgia'},
        {'city': 'Covington', 'state': 'Kentucky'},
        {'city': 'Covington', 'state': 'Louisiana'},
        {'city': 'Covington', 'state': 'Washington'},
        {'city': 'Covington', 'state': 'Virginia'},
        {'city': 'Covington', 'state': 'Tennessee'},
        {'city': 'Coweta', 'state': 'Oklahoma'},
        {'city': 'Coxsackie', 'state': 'New York'},
        {'city': 'Crafton', 'state': 'Pennsylvania'},
        {'city': 'Craig', 'state': 'Colorado'},
        {'city': 'Cranford', 'state': 'New Jersey'},
        {'city': 'Cranston', 'state': 'Rhode Island'},
        {'city': 'Crawford', 'state': 'New York'},
        {'city': 'Crawfordsville', 'state': 'Indiana'},
        {'city': 'Cresskill', 'state': 'New Jersey'},
        {'city': 'Crest Hill', 'state': 'Illinois'},
        {'city': 'Crestline', 'state': 'California'},
        {'city': 'Creston', 'state': 'Iowa'},
        {'city': 'Crestview', 'state': 'Florida'},
        {'city': 'Crestwood', 'state': 'Illinois'},
        {'city': 'Crestwood', 'state': 'Missouri'},
        {'city': 'Crestwood Village', 'state': 'New Jersey'},
        {'city': 'Crete', 'state': 'Nebraska'},
        {'city': 'Crete', 'state': 'Illinois'},
        {'city': 'Creve Coeur', 'state': 'Missouri'},
        {'city': 'Crockett', 'state': 'Texas'},
        {'city': 'Crofton', 'state': 'Maryland'},
        {'city': 'Cromwell', 'state': 'Connecticut'},
        {'city': 'Crookston', 'state': 'Minnesota'},
        {'city': 'Cross Lanes', 'state': 'West Virginia'},
        {'city': 'Crossett', 'state': 'Arkansas'},
        {'city': 'Crossville', 'state': 'Tennessee'},
        {'city': 'Croton-on-Hudson', 'state': 'New York'},
        {'city': 'Crowley', 'state': 'Texas'},
        {'city': 'Crowley', 'state': 'Louisiana'},
        {'city': 'Crown Point', 'state': 'Indiana'},
        {'city': 'Croydon', 'state': 'Pennsylvania'},
        {'city': 'Crystal', 'state': 'Minnesota'},
        {'city': 'Crystal City', 'state': 'Texas'},
        {'city': 'Crystal Lake', 'state': 'Illinois'},
        {'city': 'Cudahy', 'state': 'California'},
        {'city': 'Cudahy', 'state': 'Wisconsin'},
        {'city': 'Cuero', 'state': 'Texas'},
        {'city': 'Cullman', 'state': 'Alabama'},
        {'city': 'Culpeper', 'state': 'Virginia'},
        {'city': 'Culver City', 'state': 'California'},
        {'city': 'Cumberland', 'state': 'Maine'},
        {'city': 'Cumberland', 'state': 'Maryland'},
        {'city': 'Cumberland', 'state': 'Rhode Island'},
        {'city': 'Cumberland Hill', 'state': 'Rhode Island'},
        {'city': 'Cupertino', 'state': 'California'},
        {'city': 'Cushing', 'state': 'Oklahoma'},
        {'city': 'Cutler', 'state': 'Florida'},
        {'city': 'Cutler Ridge', 'state': 'Florida'},
        {'city': 'Cutlerville', 'state': 'Michigan'},
        {'city': 'Cuyahoga Falls', 'state': 'Ohio'},
        {'city': 'Cynthiana', 'state': 'Kentucky'},
        {'city': 'Cypress', 'state': 'California'},
        {'city': 'Cypress Gardens', 'state': 'Florida'},
        {'city': 'Cypress Lake', 'state': 'Florida'},

        {'city': 'D’Iberville', 'state': 'Mississippi'},
        {'city': 'Dade City', 'state': 'Florida'},
        {'city': 'Dale City', 'state': 'Virginia'},
        {'city': 'Dalhart', 'state': 'Texas'},
        {'city': 'Dallas', 'state': 'Texas'},
        {'city': 'Dallas', 'state': 'Oregon'},
        {'city': 'Dalton', 'state': 'Massachusetts'},
        {'city': 'Dalton', 'state': 'Georgia'},
        {'city': 'Daly City', 'state': 'California'},
        {'city': 'Damascus', 'state': 'Maryland'},
        {'city': 'Dana Point', 'state': 'California'},
        {'city': 'Danbury', 'state': 'Connecticut'},
        {'city': 'Danbury', 'state': 'Connecticut'},
        {'city': 'Dania Beach', 'state': 'Florida'},
        {'city': 'Danvers', 'state': 'Massachusetts'},
        {'city': 'Danvers', 'state': 'Massachusetts'},
        {'city': 'Danville', 'state': 'California'},
        {'city': 'Danville', 'state': 'Kentucky'},
        {'city': 'Danville', 'state': 'Indiana'},
        {'city': 'Danville', 'state': 'Illinois'},
        {'city': 'Danville', 'state': 'Virginia'},
        {'city': 'Daphne', 'state': 'Alabama'},
        {'city': 'Darby', 'state': 'Pennsylvania'},
        {'city': 'Darby Township', 'state': 'Pennsylvania'},
        {'city': 'Darien', 'state': 'Connecticut'},
        {'city': 'Darien', 'state': 'Connecticut'},
        {'city': 'Darien', 'state': 'Illinois'},
        {'city': 'Darlington', 'state': 'South Carolina'},
        {'city': 'Darnestown', 'state': 'Maryland'},
        {'city': 'Dartmouth', 'state': 'Massachusetts'},
        {'city': 'Davenport', 'state': 'Iowa'},
        {'city': 'Davidson', 'state': 'North Carolina'},
        {'city': 'Davie', 'state': 'Florida'},
        {'city': 'Davis', 'state': 'California'},
        {'city': 'Dayton', 'state': 'Ohio'},
        {'city': 'Dayton', 'state': 'New Jersey'},
        {'city': 'Dayton', 'state': 'Tennessee'},
        {'city': 'Daytona Beach', 'state': 'Florida'},
        {'city': 'De Bary', 'state': 'Florida'},
        {'city': 'De Land', 'state': 'Florida'},
        {'city': 'De Pere', 'state': 'Wisconsin'},
        {'city': 'De Ridder', 'state': 'Louisiana'},
        {'city': 'De Soto', 'state': 'Missouri'},
        {'city': 'De Witt', 'state': 'New York'},
        {'city': 'Dearborn', 'state': 'Michigan'},
        {'city': 'Dearborn Heights', 'state': 'Michigan'},
        {'city': 'Decatur', 'state': 'Indiana'},
        {'city': 'Decatur', 'state': 'Illinois'},
        {'city': 'Decatur', 'state': 'Georgia'},
        {'city': 'Decatur', 'state': 'Alabama'},
        {'city': 'Decorah', 'state': 'Iowa'},
        {'city': 'Dedham', 'state': 'Massachusetts'},
        {'city': 'Dedham', 'state': 'Massachusetts'},
        {'city': 'Deer Park', 'state': 'New York'},
        {'city': 'Deer Park', 'state': 'Texas'},
        {'city': 'Deerfield', 'state': 'Illinois'},
        {'city': 'Deerfield Beach', 'state': 'Florida'},
        {'city': 'Deerpark', 'state': 'New York'},
        {'city': 'Defiance', 'state': 'Ohio'},
        {'city': 'DeForest', 'state': 'Wisconsin'},
        {'city': 'DeKalb', 'state': 'Illinois'},
        {'city': 'Del Aire', 'state': 'California'},
        {'city': 'Del City', 'state': 'Oklahoma'},
        {'city': 'Del Rio', 'state': 'Texas'},
        {'city': 'Delafield', 'state': 'Wisconsin'},
        {'city': 'Delafield', 'state': 'Wisconsin'},
        {'city': 'Delano', 'state': 'California'},
        {'city': 'Delavan', 'state': 'Wisconsin'},
        {'city': 'Delaware', 'state': 'Ohio'},
        {'city': 'Delhi', 'state': 'California'},
        {'city': 'Delmar', 'state': 'New York'},
        {'city': 'Delphos', 'state': 'Ohio'},
        {'city': 'Delray Beach', 'state': 'Florida'},
        {'city': 'Delta', 'state': 'Colorado'},
        {'city': 'Deltona', 'state': 'Florida'},
        {'city': 'Deming', 'state': 'New Mexico'},
        {'city': 'Demopolis', 'state': 'Alabama'},
        {'city': 'Denham Springs', 'state': 'Louisiana'},
        {'city': 'Denison', 'state': 'Iowa'},
        {'city': 'Denison', 'state': 'Texas'},
        {'city': 'Dennis', 'state': 'Massachusetts'},
        {'city': 'Dent', 'state': 'Ohio'},
        {'city': 'Denton', 'state': 'Texas'},
        {'city': 'Dentsville', 'state': 'South Carolina'},
        {'city': 'Denver', 'state': 'Colorado'},
        {'city': 'Depew', 'state': 'New York'},
        {'city': 'Derby', 'state': 'Colorado'},
        {'city': 'Derby', 'state': 'Connecticut'},
        {'city': 'Derby', 'state': 'Connecticut'},
        {'city': 'Derby', 'state': 'Kansas'},
        {'city': 'Derry', 'state': 'New Hampshire'},
        {'city': 'Derry', 'state': 'New Hampshire'},
        {'city': 'Des Moines', 'state': 'Iowa'},
        {'city': 'Des Moines', 'state': 'Washington'},
        {'city': 'Des Peres', 'state': 'Missouri'},
        {'city': 'Des Plaines', 'state': 'Illinois'},
        {'city': 'Desert Hot Springs', 'state': 'California'},
        {'city': 'DeSoto', 'state': 'Texas'},
        {'city': 'Destin', 'state': 'Florida'},
        {'city': 'Destrehan', 'state': 'Louisiana'},
        {'city': 'Detroit', 'state': 'Michigan'},
        {'city': 'Detroit Lakes', 'state': 'Minnesota'},
        {'city': 'Devils Lake', 'state': 'North Dakota'},
        {'city': 'Dewey-Humboldt', 'state': 'Arizona'},
        {'city': 'Dexter', 'state': 'Missouri'},
        {'city': 'Diamond Bar', 'state': 'California'},
        {'city': 'Dickinson', 'state': 'North Dakota'},
        {'city': 'Dickinson', 'state': 'Texas'},
        {'city': 'Dickson', 'state': 'Tennessee'},
        {'city': 'Dickson City', 'state': 'Pennsylvania'},
        {'city': 'Dighton', 'state': 'Massachusetts'},
        {'city': 'Dillon', 'state': 'South Carolina'},
        {'city': 'Dinuba', 'state': 'California'},
        {'city': 'Discovery Bay', 'state': 'California'},
        {'city': 'Dishman', 'state': 'Washington'},
        {'city': 'Dix Hills', 'state': 'New York'},
        {'city': 'Dixon', 'state': 'California'},
        {'city': 'Dixon', 'state': 'Illinois'},
        {'city': 'Dobbs Ferry', 'state': 'New York'},
        {'city': 'Dock Junction', 'state': 'Georgia'},
        {'city': 'Doctor Phillips', 'state': 'Florida'},
        {'city': 'Dodge City', 'state': 'Kansas'},
        {'city': 'Dolton', 'state': 'Illinois'},
        {'city': 'Donaldsonville', 'state': 'Louisiana'},
        {'city': 'Donna', 'state': 'Texas'},
        {'city': 'Doral', 'state': 'Florida'},
        {'city': 'Doraville', 'state': 'Georgia'},
        {'city': 'Dormont', 'state': 'Pennsylvania'},
        {'city': 'Dothan', 'state': 'Alabama'},
        {'city': 'Douglas', 'state': 'Arizona'},
        {'city': 'Douglas', 'state': 'Georgia'},
        {'city': 'Douglas', 'state': 'Massachusetts'},
        {'city': 'Douglasville', 'state': 'Georgia'},
        {'city': 'Dover', 'state': 'Delaware'},
        {'city': 'Dover', 'state': 'New Hampshire'},
        {'city': 'Dover', 'state': 'New York'},
        {'city': 'Dover', 'state': 'New Jersey'},
        {'city': 'Dover', 'state': 'Ohio'},
        {'city': 'Dowagiac', 'state': 'Michigan'},
        {'city': 'Downers Grove', 'state': 'Illinois'},
        {'city': 'Downey', 'state': 'California'},
        {'city': 'Downingtown', 'state': 'Pennsylvania'},
        {'city': 'Doylestown', 'state': 'Pennsylvania'},
        {'city': 'Dracut', 'state': 'Massachusetts'},
        {'city': 'Draper', 'state': 'Utah'},
        {'city': 'Drexel Heights', 'state': 'Arizona'},
        {'city': 'Drexel Hill', 'state': 'Pennsylvania'},
        {'city': 'Druid Hills', 'state': 'Georgia'},
        {'city': 'Dry Run', 'state': 'Ohio'},
        {'city': 'Dryden', 'state': 'New York'},
        {'city': 'Du Quoin', 'state': 'Illinois'},
        {'city': 'Duarte', 'state': 'California'},
        {'city': 'Dublin', 'state': 'California'},
        {'city': 'Dublin', 'state': 'Georgia'},
        {'city': 'Dublin', 'state': 'Ohio'},
        {'city': 'DuBois', 'state': 'Pennsylvania'},
        {'city': 'Dubuque', 'state': 'Iowa'},
        {'city': 'Dudley', 'state': 'Massachusetts'},
        {'city': 'Duluth', 'state': 'Minnesota'},
        {'city': 'Duluth', 'state': 'Georgia'},
        {'city': 'Dumas', 'state': 'Texas'},
        {'city': 'Dumbarton', 'state': 'Virginia'},
        {'city': 'Dumont', 'state': 'New Jersey'},
        {'city': 'Dunbar', 'state': 'West Virginia'},
        {'city': 'Duncan', 'state': 'Oklahoma'},
        {'city': 'Duncanville', 'state': 'Texas'},
        {'city': 'Dundalk', 'state': 'Maryland'},
        {'city': 'Dunedin', 'state': 'Florida'},
        {'city': 'Dunellen', 'state': 'New Jersey'},
        {'city': 'Dunkirk', 'state': 'New York'},
        {'city': 'Dunmore', 'state': 'Pennsylvania'},
        {'city': 'Dunn', 'state': 'North Carolina'},
        {'city': 'Dunn Loring', 'state': 'Virginia'},
        {'city': 'Dunwoody', 'state': 'Georgia'},
        {'city': 'Duquesne', 'state': 'Pennsylvania'},
        {'city': 'Durango', 'state': 'Colorado'},
        {'city': 'Durant', 'state': 'Oklahoma'},
        {'city': 'Durham', 'state': 'Connecticut'},
        {'city': 'Durham', 'state': 'North Carolina'},
        {'city': 'Durham', 'state': 'New Hampshire'},
        {'city': 'Durham', 'state': 'New Hampshire'},
        {'city': 'Duxbury', 'state': 'Massachusetts'},
        {'city': 'Dyer', 'state': 'Indiana'},
        {'city': 'Dyersburg', 'state': 'Tennessee'},
        {'city': 'Eagan', 'state': 'Minnesota'},
        {'city': 'Eagle', 'state': 'Idaho'},
        {'city': 'Eagle Mountain', 'state': 'Texas'},
        {'city': 'Eagle Pass', 'state': 'Texas'},
        {'city': 'Earlimart', 'state': 'California'},
        {'city': 'Easley', 'state': 'South Carolina'},
        {'city': 'East Alton', 'state': 'Illinois'},
        {'city': 'East Aurora', 'state': 'New York'},
        {'city': 'East Bethel', 'state': 'Minnesota'},
        {'city': 'East Brainerd', 'state': 'Tennessee'},
        {'city': 'East Bridgewater', 'state': 'Massachusetts'},
        {'city': 'East Brunswick', 'state': 'New Jersey'},
        {'city': 'East Chicago', 'state': 'Indiana'},
        {'city': 'East Cleveland', 'state': 'Ohio'},
        {'city': 'East Compton', 'state': 'California'},
        {'city': 'East Falmouth', 'state': 'Massachusetts'},
        {'city': 'East Fishkill', 'state': 'New York'},
        {'city': 'East Foothills', 'state': 'California'},
        {'city': 'East Glenville', 'state': 'New York'},
        {'city': 'East Grand Forks', 'state': 'Minnesota'},
        {'city': 'East Grand Rapids', 'state': 'Michigan'},
        {'city': 'East Greenbush', 'state': 'New York'},
        {'city': 'East Greenwich', 'state': 'Rhode Island'},
        {'city': 'East Haddam', 'state': 'Connecticut'},
        {'city': 'East Hampton', 'state': 'Connecticut'},
        {'city': 'East Hampton', 'state': 'New York'},
        {'city': 'East Hartford', 'state': 'Connecticut'},
        {'city': 'East Hartford', 'state': 'Connecticut'},
        {'city': 'East Haven', 'state': 'Connecticut'},
        {'city': 'East Haven', 'state': 'Connecticut'},
        {'city': 'East Hemet', 'state': 'California'},
        {'city': 'East Highland Park', 'state': 'Virginia'},
        {'city': 'East Hill-Meridian', 'state': 'Washington'},
        {'city': 'East Hills', 'state': 'New York'},
        {'city': 'East Islip', 'state': 'New York'},
        {'city': 'East La Mirada', 'state': 'California'},
        {'city': 'East Lake', 'state': 'Florida'},
        {'city': 'East Lansing', 'state': 'Michigan'},
        {'city': 'East Liverpool', 'state': 'Ohio'},
        {'city': 'East Longmeadow', 'state': 'Massachusetts'},
        {'city': 'East Los Angeles', 'state': 'California'},
        {'city': 'East Lyme', 'state': 'Connecticut'},
        {'city': 'East Massapequa', 'state': 'New York'},
        {'city': 'East Meadow', 'state': 'New York'},
        {'city': 'East Millcreek', 'state': 'Utah'},
        {'city': 'East Moline', 'state': 'Illinois'},
        {'city': 'East Norriton', 'state': 'Pennsylvania'},
        {'city': 'East Northport', 'state': 'New York'},
        {'city': 'East Orange', 'state': 'New Jersey'},
        {'city': 'East Palo Alto', 'state': 'California'},
        {'city': 'East Pasadena', 'state': 'California'},
        {'city': 'East Patchogue', 'state': 'New York'},
        {'city': 'East Peoria', 'state': 'Illinois'},
        {'city': 'East Perrine', 'state': 'Florida'},
        {'city': 'East Point', 'state': 'Georgia'},
        {'city': 'East Porterville', 'state': 'California'},
        {'city': 'East Providence', 'state': 'Rhode Island'},
        {'city': 'East Renton Highlands', 'state': 'Washington'},
        {'city': 'East Ridge', 'state': 'Tennessee'},
        {'city': 'East Riverdale', 'state': 'Maryland'},
        {'city': 'East Rochester', 'state': 'New York'},
        {'city': 'East Rochester', 'state': 'New York'},
        {'city': 'East Rockaway', 'state': 'New York'},
        {'city': 'East Rutherford', 'state': 'New Jersey'},
        {'city': 'East San Gabriel', 'state': 'California'},
        {'city': 'East St. Louis', 'state': 'Illinois'},
        {'city': 'East Stroudsburg', 'state': 'Pennsylvania'},
        {'city': 'East Wenatchee Bench', 'state': 'Washington'},
        {'city': 'East Windsor', 'state': 'Connecticut'},
        {'city': 'East York', 'state': 'Pennsylvania'},
        {'city': 'Eastchester', 'state': 'New York'},
        {'city': 'Eastchester', 'state': 'New York'},
        {'city': 'Easthampton', 'state': 'Massachusetts'},
        {'city': 'Eastlake', 'state': 'Ohio'},
        {'city': 'Easton', 'state': 'Pennsylvania'},
        {'city': 'Easton', 'state': 'Maryland'},
        {'city': 'Easton', 'state': 'Massachusetts'},
        {'city': 'Easton', 'state': 'Connecticut'},
        {'city': 'Eastpointe', 'state': 'Michigan'},
        {'city': 'Eastwood', 'state': 'Michigan'},
        {'city': 'Eaton', 'state': 'Ohio'},
        {'city': 'Eatonton', 'state': 'Georgia'},
        {'city': 'Eatontown', 'state': 'New Jersey'},
        {'city': 'Eau Claire', 'state': 'Wisconsin'},
        {'city': 'Echelon', 'state': 'New Jersey'},
        {'city': 'Economy', 'state': 'Pennsylvania'},
        {'city': 'Ecorse', 'state': 'Michigan'},
        {'city': 'Eden', 'state': 'New York'},
        {'city': 'Eden', 'state': 'North Carolina'},
        {'city': 'Eden Isle', 'state': 'Louisiana'},
        {'city': 'Eden Prairie', 'state': 'Minnesota'},
        {'city': 'Edgemere', 'state': 'Maryland'},
        {'city': 'Edgewater', 'state': 'New Jersey'},
        {'city': 'Edgewater', 'state': 'Florida'},
        {'city': 'Edgewood', 'state': 'Kentucky'},
        {'city': 'Edgewood', 'state': 'Maryland'},
        {'city': 'Edgewood', 'state': 'Washington'},
        {'city': 'Edina', 'state': 'Minnesota'},
        {'city': 'Edinboro', 'state': 'Pennsylvania'},
        {'city': 'Edinburg', 'state': 'Texas'},
        {'city': 'Edison', 'state': 'New Jersey'},
        {'city': 'Edmond', 'state': 'Oklahoma'},
        {'city': 'Edmonds', 'state': 'Washington'},
        {'city': 'Edwards', 'state': 'Colorado'},
        {'city': 'Edwardsville', 'state': 'Illinois'},
        {'city': 'Effingham', 'state': 'Illinois'},
        {'city': 'Eglin AFB', 'state': 'Florida'},
        {'city': 'Egypt Lake-Leto', 'state': 'Florida'},
        {'city': 'Eidson Road', 'state': 'Texas'},
        {'city': 'El Cajon', 'state': 'California'},
        {'city': 'El Campo', 'state': 'Texas'},
        {'city': 'El Centro', 'state': 'California'},
        {'city': 'El Cerrito', 'state': 'California'},
        {'city': 'El Dorado', 'state': 'Arkansas'},
        {'city': 'El Dorado', 'state': 'Kansas'},
        {'city': 'El Dorado Hills', 'state': 'California'},
        {'city': 'El Mirage', 'state': 'Arizona'},
        {'city': 'El Monte', 'state': 'California'},
        {'city': 'El Paso', 'state': 'Texas'},
        {'city': 'El Paso de Robles', 'state': 'California'},
        {'city': 'El Reno', 'state': 'Oklahoma'},
        {'city': 'El Rio', 'state': 'California'},
        {'city': 'El Segundo', 'state': 'California'},
        {'city': 'El Sobrante', 'state': 'California'},
        {'city': 'Elbridge', 'state': 'New York'},
        {'city': 'Eldersburg', 'state': 'Maryland'},
        {'city': 'Elfers', 'state': 'Florida'},
        {'city': 'Elgin', 'state': 'Illinois'},
        {'city': 'Elizabeth', 'state': 'New Jersey'},
        {'city': 'Elizabeth City', 'state': 'North Carolina'},
        {'city': 'Elizabethton', 'state': 'Tennessee'},
        {'city': 'Elizabethtown', 'state': 'Pennsylvania'},
        {'city': 'Elizabethtown', 'state': 'Kentucky'},
        {'city': 'Elk City', 'state': 'Oklahoma'},
        {'city': 'Elk Grove', 'state': 'California'},
        {'city': 'Elk Grove Village', 'state': 'Illinois'},
        {'city': 'Elk Plain', 'state': 'Washington'},
        {'city': 'Elk River', 'state': 'Minnesota'},
        {'city': 'Elkhart', 'state': 'Indiana'},
        {'city': 'Elkhorn', 'state': 'Nebraska'},
        {'city': 'Elkhorn', 'state': 'Wisconsin'},
        {'city': 'Elkins', 'state': 'West Virginia'},
        {'city': 'Elko', 'state': 'Nevada'},
        {'city': 'Elkridge', 'state': 'Maryland'},
        {'city': 'Elkton', 'state': 'Maryland'},
        {'city': 'Ellensburg', 'state': 'Washington'},
        {'city': 'Ellicott', 'state': 'New York'},
        {'city': 'Ellicott City', 'state': 'Maryland'},
        {'city': 'Ellington', 'state': 'Connecticut'},
        {'city': 'Ellisville', 'state': 'Missouri'},
        {'city': 'Ellsworth', 'state': 'Maine'},
        {'city': 'Ellwood City', 'state': 'Pennsylvania'},
        {'city': 'Elm Grove', 'state': 'Wisconsin'},
        {'city': 'Elma', 'state': 'New York'},
        {'city': 'Elmhurst', 'state': 'Illinois'},
        {'city': 'Elmira', 'state': 'New York'},
        {'city': 'Elmira', 'state': 'New York'},
        {'city': 'Elmont', 'state': 'New York'},
        {'city': 'Elmwood Park', 'state': 'New Jersey'},
        {'city': 'Elmwood Park', 'state': 'Illinois'},
        {'city': 'Elon College', 'state': 'North Carolina'},
        {'city': 'Eloy', 'state': 'Arizona'},
        {'city': 'Elsmere', 'state': 'Kentucky'},
        {'city': 'Elwood', 'state': 'Indiana'},
        {'city': 'Elwood', 'state': 'New York'},
        {'city': 'Elyria', 'state': 'Ohio'},
        {'city': 'Emerson', 'state': 'New Jersey'},
        {'city': 'Emeryville', 'state': 'California'},
        {'city': 'Emmaus', 'state': 'Pennsylvania'},
        {'city': 'Emporia', 'state': 'Kansas'},
        {'city': 'Encinitas', 'state': 'California'},
        {'city': 'Endicott', 'state': 'New York'},
        {'city': 'Endwell', 'state': 'New York'},
        {'city': 'Enfield', 'state': 'Connecticut'},
        {'city': 'Englewood', 'state': 'Colorado'},
        {'city': 'Englewood', 'state': 'Florida'},
        {'city': 'Englewood', 'state': 'New Jersey'},
        {'city': 'Englewood', 'state': 'Ohio'},
        {'city': 'Enid', 'state': 'Oklahoma'},
        {'city': 'Ennis', 'state': 'Texas'},
        {'city': 'Ensley', 'state': 'Florida'},
        {'city': 'Enterprise', 'state': 'Alabama'},
        {'city': 'Enterprise', 'state': 'Nevada'},
        {'city': 'Enumclaw', 'state': 'Washington'},
        {'city': 'Ephrata', 'state': 'Washington'},
        {'city': 'Ephrata', 'state': 'Pennsylvania'},
        {'city': 'Erie', 'state': 'Pennsylvania'},
        {'city': 'Erie', 'state': 'Colorado'},
        {'city': 'Erlanger', 'state': 'Kentucky'},
        {'city': 'Erlton-Ellisburg', 'state': 'New Jersey'},
        {'city': 'Erwin', 'state': 'New York'},
        {'city': 'Escanaba', 'state': 'Michigan'},
        {'city': 'Escondido', 'state': 'California'},
        {'city': 'Esopus', 'state': 'New York'},
        {'city': 'Espanola', 'state': 'New Mexico'},
        {'city': 'Essex', 'state': 'Maryland'},
        {'city': 'Essex', 'state': 'Connecticut'},
        {'city': 'Essex', 'state': 'Vermont'},
        {'city': 'Essex Junction', 'state': 'Vermont'},
        {'city': 'Estelle', 'state': 'Louisiana'},
        {'city': 'Estero', 'state': 'Florida'},
        {'city': 'Estherville', 'state': 'Iowa'},
        {'city': 'Euclid', 'state': 'Ohio'},
        {'city': 'Eufaula', 'state': 'Alabama'},
        {'city': 'Eugene', 'state': 'Oregon'},
        {'city': 'Euless', 'state': 'Texas'},
        {'city': 'Eunice', 'state': 'Louisiana'},
        {'city': 'Eureka', 'state': 'California'},
        {'city': 'Eureka', 'state': 'Missouri'},
        {'city': 'Eustis', 'state': 'Florida'},
        {'city': 'Evans', 'state': 'Georgia'},
        {'city': 'Evans', 'state': 'Colorado'},
        {'city': 'Evans', 'state': 'New York'},
        {'city': 'Evanston', 'state': 'Illinois'},
        {'city': 'Evanston', 'state': 'Wyoming'},
        {'city': 'Evansville', 'state': 'Indiana'},
        {'city': 'Everett', 'state': 'Massachusetts'},
        {'city': 'Everett', 'state': 'Washington'},
        {'city': 'Evergreen', 'state': 'Montana'},
        {'city': 'Evergreen', 'state': 'Colorado'},
        {'city': 'Evergreen Park', 'state': 'Illinois'},
        {'city': 'Ewa Beach', 'state': 'Hawaii'},
        {'city': 'Ewing', 'state': 'New Jersey'},
        {'city': 'Excelsior Springs', 'state': 'Missouri'},
        {'city': 'Exeter', 'state': 'New Hampshire'},
        {'city': 'Exeter', 'state': 'New Hampshire'},
        {'city': 'Exeter', 'state': 'California'},
        {'city': 'Exeter', 'state': 'Rhode Island'},
        {'city': 'Fabens', 'state': 'Texas'},
        {'city': 'Fair Lawn', 'state': 'New Jersey'},
        {'city': 'Fair Oaks', 'state': 'California'},
        {'city': 'Fair Oaks', 'state': 'Georgia'},
        {'city': 'Fair Plain', 'state': 'Michigan'},
        {'city': 'Fairbanks', 'state': 'Alaska'},
        {'city': 'Fairborn', 'state': 'Ohio'},
        {'city': 'Fairdale', 'state': 'Kentucky'},
        {'city': 'Fairfax', 'state': 'California'},
        {'city': 'Fairfax', 'state': 'Virginia'},
        {'city': 'Fairfield', 'state': 'Ohio'},
        {'city': 'Fairfield', 'state': 'New Jersey'},
        {'city': 'Fairfield', 'state': 'California'},
        {'city': 'Fairfield', 'state': 'Alabama'},
        {'city': 'Fairfield', 'state': 'Connecticut'},
        {'city': 'Fairfield', 'state': 'Iowa'},
        {'city': 'Fairfield', 'state': 'Maine'},
        {'city': 'Fairhaven', 'state': 'Massachusetts'},
        {'city': 'Fairhope', 'state': 'Alabama'},
        {'city': 'Fairland', 'state': 'Maryland'},
        {'city': 'Fairlawn', 'state': 'Ohio'},
        {'city': 'Fairless Hills', 'state': 'Pennsylvania'},
        {'city': 'Fairmont', 'state': 'West Virginia'},
        {'city': 'Fairmont', 'state': 'Minnesota'},
        {'city': 'Fairmount', 'state': 'New York'},
        {'city': 'Fairview', 'state': 'Oregon'},
        {'city': 'Fairview', 'state': 'New Jersey'},
        {'city': 'Fairview', 'state': 'California'},
        {'city': 'Fairview', 'state': 'Georgia'},
        {'city': 'Fairview Heights', 'state': 'Illinois'},
        {'city': 'Fairview Park', 'state': 'Ohio'},
        {'city': 'Fairview Shores', 'state': 'Florida'},
        {'city': 'Fairwood', 'state': 'Washington'},
        {'city': 'Fall River', 'state': 'Massachusetts'},
        {'city': 'Fallbrook', 'state': 'California'},
        {'city': 'Fallon', 'state': 'Nevada'},
        {'city': 'Falls Church', 'state': 'Virginia'},
        {'city': 'Fallsburg', 'state': 'New York'},
        {'city': 'Fallston', 'state': 'Maryland'},
        {'city': 'Falmouth', 'state': 'Maine'},
        {'city': 'Falmouth', 'state': 'Massachusetts'},
        {'city': 'Fanwood', 'state': 'New Jersey'},
        {'city': 'Fargo', 'state': 'North Dakota'},
        {'city': 'Faribault', 'state': 'Minnesota'},
        {'city': 'Farmers Branch', 'state': 'Texas'},
        {'city': 'Farmersville', 'state': 'California'},
        {'city': 'Farmingdale', 'state': 'New York'},
        {'city': 'Farmington', 'state': 'New Mexico'},
        {'city': 'Farmington', 'state': 'New York'},
        {'city': 'Farmington', 'state': 'Utah'},
        {'city': 'Farmington', 'state': 'Minnesota'},
        {'city': 'Farmington', 'state': 'Missouri'},
        {'city': 'Farmington', 'state': 'Maine'},
        {'city': 'Farmington', 'state': 'Michigan'},
        {'city': 'Farmington', 'state': 'Connecticut'},
        {'city': 'Farmington Hills', 'state': 'Michigan'},
        {'city': 'Farmingville', 'state': 'New York'},
        {'city': 'Farmville', 'state': 'Virginia'},
        {'city': 'Farragut', 'state': 'Tennessee'},
        {'city': 'Farrell', 'state': 'Pennsylvania'},
        {'city': 'Fayetteville', 'state': 'North Carolina'},
        {'city': 'Fayetteville', 'state': 'Tennessee'},
        {'city': 'Fayetteville', 'state': 'Arkansas'},
        {'city': 'Fayetteville', 'state': 'Georgia'},
        {'city': 'Feasterville-Trevose', 'state': 'Pennsylvania'},
        {'city': 'Federal Heights', 'state': 'Colorado'},
        {'city': 'Federal Way', 'state': 'Washington'},
        {'city': 'Fenton', 'state': 'New York'},
        {'city': 'Fenton', 'state': 'Michigan'},
        {'city': 'Fergus Falls', 'state': 'Minnesota'},
        {'city': 'Ferguson', 'state': 'Missouri'},
        {'city': 'Fern Creek', 'state': 'Kentucky'},
        {'city': 'Fern Park', 'state': 'Florida'},
        {'city': 'Fernandina Beach', 'state': 'Florida'},
        {'city': 'Ferndale', 'state': 'Maryland'},
        {'city': 'Ferndale', 'state': 'Michigan'},
        {'city': 'Ferndale', 'state': 'Washington'},
        {'city': 'Fernley', 'state': 'Nevada'},
        {'city': 'Fernway', 'state': 'Pennsylvania'},
        {'city': 'Ferry Pass', 'state': 'Florida'},
        {'city': 'Festus', 'state': 'Missouri'},
        {'city': 'Fillmore', 'state': 'California'},
        {'city': 'Findlay', 'state': 'Ohio'},
        {'city': 'Finneytown', 'state': 'Ohio'},
        {'city': 'Fishers', 'state': 'Indiana'},
        {'city': 'Fishkill', 'state': 'New York'},
        {'city': 'Fitchburg', 'state': 'Wisconsin'},
        {'city': 'Fitchburg', 'state': 'Massachusetts'},
        {'city': 'Fitzgerald', 'state': 'Georgia'},
        {'city': 'Five Corners', 'state': 'Washington'},
        {'city': 'Five Forks', 'state': 'South Carolina'},
        {'city': 'Flagstaff', 'state': 'Arizona'},
        {'city': 'Flat Rock', 'state': 'Michigan'},
        {'city': 'Flatwoods', 'state': 'Kentucky'},
        {'city': 'Flint', 'state': 'Michigan'},
        {'city': 'Floral Park', 'state': 'New York'},
        {'city': 'Florence', 'state': 'South Carolina'},
        {'city': 'Florence', 'state': 'Oregon'},
        {'city': 'Florence', 'state': 'Kentucky'},
        {'city': 'Florence', 'state': 'Arizona'},
        {'city': 'Florence', 'state': 'Alabama'},
        {'city': 'Florence-Graham', 'state': 'California'},
        {'city': 'Florence-Roebling', 'state': 'New Jersey'},
        {'city': 'Florham Park', 'state': 'New Jersey'},
        {'city': 'Florida City', 'state': 'Florida'},
        {'city': 'Florida Ridge', 'state': 'Florida'},
        {'city': 'Florin', 'state': 'California'},
        {'city': 'Florissant', 'state': 'Missouri'},
        {'city': 'Flossmoor', 'state': 'Illinois'},
        {'city': 'Flower Mound', 'state': 'Texas'},
        {'city': 'Flowing Wells', 'state': 'Arizona'},
        {'city': 'Flushing', 'state': 'Michigan'},
        {'city': 'Folcroft', 'state': 'Pennsylvania'},
        {'city': 'Foley', 'state': 'Alabama'},
        {'city': 'Folsom', 'state': 'California'},
        {'city': 'Folsom', 'state': 'Pennsylvania'},
        {'city': 'Fond du Lac', 'state': 'Wisconsin'},
        {'city': 'Fontana', 'state': 'California'},
        {'city': 'Foothill Farms', 'state': 'California'},
        {'city': 'Foothill Ranch', 'state': 'California'},
        {'city': 'Fords', 'state': 'New Jersey'},
        {'city': 'Forest', 'state': 'Virginia'},
        {'city': 'Forest Acres', 'state': 'South Carolina'},
        {'city': 'Forest City', 'state': 'North Carolina'},
        {'city': 'Forest City', 'state': 'Florida'},
        {'city': 'Forest Glen', 'state': 'Maryland'},
        {'city': 'Forest Grove', 'state': 'Oregon'},
        {'city': 'Forest Hill', 'state': 'Texas'},
        {'city': 'Forest Hills', 'state': 'Pennsylvania'},
        {'city': 'Forest Hills', 'state': 'Michigan'},
        {'city': 'Forest Lake', 'state': 'Minnesota'},
        {'city': 'Forest Park', 'state': 'Illinois'},
        {'city': 'Forest Park', 'state': 'Georgia'},
        {'city': 'Forest Park', 'state': 'Ohio'},
        {'city': 'Forestdale', 'state': 'Alabama'},
        {'city': 'Forestville', 'state': 'Maryland'},
        {'city': 'Forestville', 'state': 'Ohio'},
        {'city': 'Forrest City', 'state': 'Arkansas'},
        {'city': 'Fort Ann', 'state': 'New York'},
        {'city': 'Fort Atkinson', 'state': 'Wisconsin'},
        {'city': 'Fort Belvoir', 'state': 'Virginia'},
        {'city': 'Fort Benning South', 'state': 'Georgia'},
        {'city': 'Fort Bliss', 'state': 'Texas'},
        {'city': 'Fort Bragg', 'state': 'North Carolina'},
        {'city': 'Fort Bragg', 'state': 'California'},
        {'city': 'Fort Campbell North', 'state': 'Kentucky'},
        {'city': 'Fort Carson', 'state': 'Colorado'},
        {'city': 'Fort Collins', 'state': 'Colorado'},
        {'city': 'Fort Dix', 'state': 'New Jersey'},
        {'city': 'Fort Dodge', 'state': 'Iowa'},
        {'city': 'Fort Drum', 'state': 'New York'},
        {'city': 'Fort Hood', 'state': 'Texas'},
        {'city': 'Fort Hunt', 'state': 'Virginia'},
        {'city': 'Fort Knox', 'state': 'Kentucky'},
        {'city': 'Fort Lauderdale', 'state': 'Florida'},
        {'city': 'Fort Lee', 'state': 'New Jersey'},
        {'city': 'Fort Lee', 'state': 'Virginia'},
        {'city': 'Fort Leonard Wood', 'state': 'Missouri'},
        {'city': 'Fort Lewis', 'state': 'Washington'},
        {'city': 'Fort Lupton', 'state': 'Colorado'},
        {'city': 'Fort Madison', 'state': 'Iowa'},
        {'city': 'Fort Meade', 'state': 'Maryland'},
        {'city': 'Fort Mill', 'state': 'South Carolina'},
        {'city': 'Fort Mitchell', 'state': 'Kentucky'},
        {'city': 'Fort Morgan', 'state': 'Colorado'},
        {'city': 'Fort Myers', 'state': 'Florida'},
        {'city': 'Fort Myers Beach', 'state': 'Florida'},
        {'city': 'Fort Oglethorpe', 'state': 'Georgia'},
        {'city': 'Fort Payne', 'state': 'Alabama'},
        {'city': 'Fort Pierce', 'state': 'Florida'},
        {'city': 'Fort Pierce North', 'state': 'Florida'},
        {'city': 'Fort Polk South', 'state': 'Louisiana'},
        {'city': 'Fort Riley North', 'state': 'Kansas'},
        {'city': 'Fort Rucker', 'state': 'Alabama'},
        {'city': 'Fort Salonga', 'state': 'New York'},
        {'city': 'Fort Scott', 'state': 'Kansas'},
        {'city': 'Fort Smith', 'state': 'Arkansas'},
        {'city': 'Fort Stewart', 'state': 'Georgia'},
        {'city': 'Fort Stockton', 'state': 'Texas'},
        {'city': 'Fort Thomas', 'state': 'Kentucky'},
        {'city': 'Fort Valley', 'state': 'Georgia'},
        {'city': 'Fort Walton Beach', 'state': 'Florida'},
        {'city': 'Fort Washington', 'state': 'Maryland'},
        {'city': 'Fort Wayne', 'state': 'Indiana'},
        {'city': 'Fort Worth', 'state': 'Texas'},
        {'city': 'Fortuna', 'state': 'California'},
        {'city': 'Fortuna Foothills', 'state': 'Arizona'},
        {'city': 'Foster City', 'state': 'California'},
        {'city': 'Fostoria', 'state': 'Ohio'},
        {'city': 'Fountain', 'state': 'Colorado'},
        {'city': 'Fountain Hills', 'state': 'Arizona'},
        {'city': 'Fountain Inn', 'state': 'South Carolina'},
        {'city': 'Fountain Valley', 'state': 'California'},
        {'city': 'Fountainbleau', 'state': 'Florida'},
        {'city': 'Four Corners', 'state': 'Oregon'},
        {'city': 'Fox Lake', 'state': 'Illinois'},
        {'city': 'Fox Point', 'state': 'Wisconsin'},
        {'city': 'Foxborough', 'state': 'Massachusetts'},
        {'city': 'Framingham', 'state': 'Massachusetts'},
        {'city': 'Framingham', 'state': 'Massachusetts'},
        {'city': 'Franconia', 'state': 'Virginia'},
        {'city': 'Frankfort', 'state': 'New York'},
        {'city': 'Frankfort', 'state': 'Indiana'},
        {'city': 'Frankfort', 'state': 'Kentucky'},
        {'city': 'Frankfort', 'state': 'Illinois'},
        {'city': 'Frankfort Square', 'state': 'Illinois'},
        {'city': 'Franklin', 'state': 'Kentucky'},
        {'city': 'Franklin', 'state': 'Indiana'},
        {'city': 'Franklin', 'state': 'Louisiana'},
        {'city': 'Franklin', 'state': 'Massachusetts'},
        {'city': 'Franklin', 'state': 'New Hampshire'},
        {'city': 'Franklin', 'state': 'Ohio'},
        {'city': 'Franklin', 'state': 'Virginia'},
        {'city': 'Franklin', 'state': 'Pennsylvania'},
        {'city': 'Franklin', 'state': 'Wisconsin'},
        {'city': 'Franklin', 'state': 'Tennessee'},
        {'city': 'Franklin Lakes', 'state': 'New Jersey'},
        {'city': 'Franklin Park', 'state': 'Illinois'},
        {'city': 'Franklin Park', 'state': 'Pennsylvania'},
        {'city': 'Franklin Square', 'state': 'New York'},
        {'city': 'Fraser', 'state': 'Michigan'},
        {'city': 'Frederick', 'state': 'Maryland'},
        {'city': 'Fredericksburg', 'state': 'Virginia'},
        {'city': 'Fredericksburg', 'state': 'Texas'},
        {'city': 'Fredonia', 'state': 'New York'},
        {'city': 'Freehold', 'state': 'New Jersey'},
        {'city': 'Freeport', 'state': 'Maine'},
        {'city': 'Freeport', 'state': 'Illinois'},
        {'city': 'Freeport', 'state': 'New York'},
        {'city': 'Freeport', 'state': 'Texas'},
        {'city': 'Freetown', 'state': 'Massachusetts'},
        {'city': 'Fremont', 'state': 'California'},
        {'city': 'Fremont', 'state': 'Ohio'},
        {'city': 'Fremont', 'state': 'Nebraska'},
        {'city': 'Fresno', 'state': 'Texas'},
        {'city': 'Fresno', 'state': 'California'},
        {'city': 'Fridley', 'state': 'Minnesota'},
        {'city': 'Friendly', 'state': 'Maryland'},
        {'city': 'Friendswood', 'state': 'Texas'},
        {'city': 'Frisco', 'state': 'Texas'},
        {'city': 'Front Royal', 'state': 'Virginia'},
        {'city': 'Frostburg', 'state': 'Maryland'},
        {'city': 'Fruit Cove', 'state': 'Florida'},
        {'city': 'Fruita', 'state': 'Colorado'},
        {'city': 'Fruitvale', 'state': 'Colorado'},
        {'city': 'Fruitville', 'state': 'Florida'},
        {'city': 'Fullerton', 'state': 'California'},
        {'city': 'Fullerton', 'state': 'Pennsylvania'},
        {'city': 'Fulton', 'state': 'New York'},
        {'city': 'Fulton', 'state': 'Missouri'},
        {'city': 'Fultondale', 'state': 'Alabama'},
        {'city': 'Fuquay-Varina', 'state': 'North Carolina'},
        {'city': 'Gadsden', 'state': 'Alabama'},
        {'city': 'Gaffney', 'state': 'South Carolina'},
        {'city': 'Gages Lake', 'state': 'Illinois'},
        {'city': 'Gahanna', 'state': 'Ohio'},
        {'city': 'Gainesville', 'state': 'Texas'},
        {'city': 'Gainesville', 'state': 'Georgia'},
        {'city': 'Gainesville', 'state': 'Florida'},
        {'city': 'Gaithersburg', 'state': 'Maryland'},
        {'city': 'Galax', 'state': 'Virginia'},
        {'city': 'Galena Park', 'state': 'Texas'},
        {'city': 'Galesburg', 'state': 'Illinois'},
        {'city': 'Galion', 'state': 'Ohio'},
        {'city': 'Gallatin', 'state': 'Tennessee'},
        {'city': 'Galliano', 'state': 'Louisiana'},
        {'city': 'Gallup', 'state': 'New Mexico'},
        {'city': 'Galt', 'state': 'California'},
        {'city': 'Galveston', 'state': 'Texas'},
        {'city': 'Gantt', 'state': 'South Carolina'},
        {'city': 'Garden Acres', 'state': 'California'},
        {'city': 'Garden City', 'state': 'Georgia'},
        {'city': 'Garden City', 'state': 'Kansas'},
        {'city': 'Garden City', 'state': 'Idaho'},
        {'city': 'Garden City', 'state': 'Michigan'},
        {'city': 'Garden City', 'state': 'South Carolina'},
        {'city': 'Garden City', 'state': 'New York'},
        {'city': 'Garden City Park', 'state': 'New York'},
        {'city': 'Garden Grove', 'state': 'California'},
        {'city': 'Garden Home-Whitford', 'state': 'Oregon'},
        {'city': 'Gardena', 'state': 'California'},
        {'city': 'Gardendale', 'state': 'Alabama'},
        {'city': 'Gardere', 'state': 'Louisiana'},
        {'city': 'Gardiner', 'state': 'Maine'},
        {'city': 'Gardner', 'state': 'Kansas'},
        {'city': 'Gardner', 'state': 'Massachusetts'},
        {'city': 'Gardnerville Ranchos', 'state': 'Nevada'},
        {'city': 'Garfield', 'state': 'New Jersey'},
        {'city': 'Garfield Heights', 'state': 'Ohio'},
        {'city': 'Garland', 'state': 'Texas'},
        {'city': 'Garner', 'state': 'North Carolina'},
        {'city': 'Garrison', 'state': 'Maryland'},
        {'city': 'Gary', 'state': 'Indiana'},
        {'city': 'Gastonia', 'state': 'North Carolina'},
        {'city': 'Gates', 'state': 'New York'},
        {'city': 'Gates-North Gates', 'state': 'New York'},
        {'city': 'Gatesville', 'state': 'Texas'},
        {'city': 'Gautier', 'state': 'Mississippi'},
        {'city': 'Geddes', 'state': 'New York'},
        {'city': 'Genesee', 'state': 'Wisconsin'},
        {'city': 'Geneseo', 'state': 'New York'},
        {'city': 'Geneseo', 'state': 'New York'},
        {'city': 'Geneseo', 'state': 'Illinois'},
        {'city': 'Geneva', 'state': 'Illinois'},
        {'city': 'Geneva', 'state': 'New York'},
        {'city': 'Geneva', 'state': 'Ohio'},
        {'city': 'Georgetown', 'state': 'Texas'},
        {'city': 'Georgetown', 'state': 'South Carolina'},
        {'city': 'Georgetown', 'state': 'Georgia'},
        {'city': 'Georgetown', 'state': 'Massachusetts'},
        {'city': 'Georgetown', 'state': 'Kentucky'},
        {'city': 'Georgetown County', 'state': 'South Carolina'},
        {'city': 'Gering', 'state': 'Nebraska'},
        {'city': 'German Flatts', 'state': 'New York'},
        {'city': 'Germantown', 'state': 'Tennessee'},
        {'city': 'Germantown', 'state': 'Wisconsin'},
        {'city': 'Germantown', 'state': 'Maryland'},
        {'city': 'Gettysburg', 'state': 'Pennsylvania'},
        {'city': 'Gibsonton', 'state': 'Florida'},
        {'city': 'Gifford', 'state': 'Florida'},
        {'city': 'Gig Harbor', 'state': 'Washington'},
        {'city': 'Gilbert', 'state': 'Arizona'},
        {'city': 'Gilford', 'state': 'New Hampshire'},
        {'city': 'Gillette', 'state': 'Wyoming'},
        {'city': 'Gilroy', 'state': 'California'},
        {'city': 'Girard', 'state': 'Ohio'},
        {'city': 'Gladeview', 'state': 'Florida'},
        {'city': 'Gladewater', 'state': 'Texas'},
        {'city': 'Gladstone', 'state': 'Oregon'},
        {'city': 'Gladstone', 'state': 'Missouri'},
        {'city': 'Glasgow', 'state': 'Kentucky'},
        {'city': 'Glasgow', 'state': 'Delaware'},
        {'city': 'Glassboro', 'state': 'New Jersey'},
        {'city': 'Glastonbury', 'state': 'Connecticut'},
        {'city': 'Glastonbury Center', 'state': 'Connecticut'},
        {'city': 'Glen Allen', 'state': 'Virginia'},
        {'city': 'Glen Avon', 'state': 'California'},
        {'city': 'Glen Burnie', 'state': 'Maryland'},
        {'city': 'Glen Carbon', 'state': 'Illinois'},
        {'city': 'Glen Cove', 'state': 'New York'},
        {'city': 'Glen Ellyn', 'state': 'Illinois'},
        {'city': 'Glen Ridge', 'state': 'New Jersey'},
        {'city': 'Glen Rock', 'state': 'New Jersey'},
        {'city': 'Glenarden', 'state': 'Maryland'},
        {'city': 'Glencoe', 'state': 'Illinois'},
        {'city': 'Glendale', 'state': 'California'},
        {'city': 'Glendale', 'state': 'Arizona'},
        {'city': 'Glendale', 'state': 'Wisconsin'},
        {'city': 'Glendale Heights', 'state': 'Illinois'},
        {'city': 'Glendora', 'state': 'California'},
        {'city': 'Glenn Dale', 'state': 'Maryland'},
        {'city': 'Glenn Heights', 'state': 'Texas'},
        {'city': 'Glenolden', 'state': 'Pennsylvania'},
        {'city': 'Glenpool', 'state': 'Oklahoma'},
        {'city': 'Glens Falls', 'state': 'New York'},
        {'city': 'Glens Falls North', 'state': 'New York'},
        {'city': 'Glenside', 'state': 'Pennsylvania'},
        {'city': 'Glenvar Heights', 'state': 'Florida'},
        {'city': 'Glenview', 'state': 'Illinois'},
        {'city': 'Glenville', 'state': 'New York'},
        {'city': 'Glenwood', 'state': 'Illinois'},
        {'city': 'Glenwood Springs', 'state': 'Colorado'},
        {'city': 'Globe', 'state': 'Arizona'},
        {'city': 'Glocester', 'state': 'Rhode Island'},
        {'city': 'Gloucester', 'state': 'Massachusetts'},
        {'city': 'Gloucester City', 'state': 'New Jersey'},
        {'city': 'Gloucester Point', 'state': 'Virginia'},
        {'city': 'Gloversville', 'state': 'New York'},
        {'city': 'Godfrey', 'state': 'Illinois'},
        {'city': 'Goffstown', 'state': 'New Hampshire'},
        {'city': 'Gold Camp', 'state': 'Arizona'},
        {'city': 'Gold River', 'state': 'California'},
        {'city': 'Golden', 'state': 'Colorado'},
        {'city': 'Golden Gate', 'state': 'Florida'},
        {'city': 'Golden Glades', 'state': 'Florida'},
        {'city': 'Golden Hills', 'state': 'California'},
        {'city': 'Golden Lakes', 'state': 'Florida'},
        {'city': 'Golden Valley', 'state': 'Minnesota'},
        {'city': 'Goldenrod', 'state': 'Florida'},
        {'city': 'Goldsboro', 'state': 'North Carolina'},
        {'city': 'Goleta', 'state': 'California'},
        {'city': 'Gonzales', 'state': 'California'},
        {'city': 'Gonzales', 'state': 'Louisiana'},
        {'city': 'Gonzales', 'state': 'Texas'},
        {'city': 'Gonzalez', 'state': 'Florida'},
        {'city': 'Goodings Grove', 'state': 'Illinois'},
        {'city': 'Goodlettsville', 'state': 'Tennessee'},
        {'city': 'Goodyear', 'state': 'Arizona'},
        {'city': 'Goose Creek', 'state': 'South Carolina'},
        {'city': 'Gorham', 'state': 'Maine'},
        {'city': 'Goshen', 'state': 'Indiana'},
        {'city': 'Goshen', 'state': 'New York'},
        {'city': 'Goulds', 'state': 'Florida'},
        {'city': 'Gouverneur', 'state': 'New York'},
        {'city': 'Grafton', 'state': 'Massachusetts'},
        {'city': 'Grafton', 'state': 'Wisconsin'},
        {'city': 'Graham', 'state': 'Washington'},
        {'city': 'Graham', 'state': 'North Carolina'},
        {'city': 'Graham', 'state': 'Texas'},
        {'city': 'Granby', 'state': 'New York'},
        {'city': 'Granby', 'state': 'Connecticut'},
        {'city': 'Granby', 'state': 'Massachusetts'},
        {'city': 'Grand Blanc', 'state': 'Michigan'},
        {'city': 'Grand Chute', 'state': 'Wisconsin'},
        {'city': 'Grand Forks', 'state': 'North Dakota'},
        {'city': 'Grand Haven', 'state': 'Michigan'},
        {'city': 'Grand Island', 'state': 'Nebraska'},
        {'city': 'Grand Island', 'state': 'New York'},
        {'city': 'Grand Junction', 'state': 'Colorado'},
        {'city': 'Grand Ledge', 'state': 'Michigan'},
        {'city': 'Grand Prairie', 'state': 'Texas'},
        {'city': 'Grand Rapids', 'state': 'Wisconsin'},
        {'city': 'Grand Rapids', 'state': 'Michigan'},
        {'city': 'Grand Rapids', 'state': 'Minnesota'},
        {'city': 'Grand Terrace', 'state': 'California'},
        {'city': 'Grandview', 'state': 'Missouri'},
        {'city': 'Grandview', 'state': 'Washington'},
        {'city': 'Grandview Heights', 'state': 'Ohio'},
        {'city': 'Grandville', 'state': 'Michigan'},
        {'city': 'Granger', 'state': 'Indiana'},
        {'city': 'Granite Bay', 'state': 'California'},
        {'city': 'Granite City', 'state': 'Illinois'},
        {'city': 'Grants', 'state': 'New Mexico'},
        {'city': 'Grants Pass', 'state': 'Oregon'},
        {'city': 'Grantsville', 'state': 'Utah'},
        {'city': 'Granville', 'state': 'New York'},
        {'city': 'Grapevine', 'state': 'Texas'},
        {'city': 'Grass Valley', 'state': 'California'},
        {'city': 'Gray', 'state': 'Maine'},
        {'city': 'Grayslake', 'state': 'Illinois'},
        {'city': 'Great Barrington', 'state': 'Massachusetts'},
        {'city': 'Great Bend', 'state': 'Kansas'},
        {'city': 'Great Falls', 'state': 'Montana'},
        {'city': 'Great Falls', 'state': 'Virginia'},
        {'city': 'Great Neck', 'state': 'New York'},
        {'city': 'Great Neck Plaza', 'state': 'New York'},
        {'city': 'Greater Carrollwood', 'state': 'Florida'},
        {'city': 'Greater Landover', 'state': 'Maryland'},
        {'city': 'Greater Northdale', 'state': 'Florida'},
        {'city': 'Greater Sun Center', 'state': 'Florida'},
        {'city': 'Greater Upper Marlboro', 'state': 'Maryland'},
        {'city': 'Greatwood', 'state': 'Texas'},
        {'city': 'Greece', 'state': 'New York'},
        {'city': 'Greece', 'state': 'New York'},
        {'city': 'Greeley', 'state': 'Colorado'},
        {'city': 'Green', 'state': 'Ohio'},
        {'city': 'Green', 'state': 'Oregon'},
        {'city': 'Green Bay', 'state': 'Wisconsin'},
        {'city': 'Green Haven', 'state': 'Maryland'},
        {'city': 'Green Hill', 'state': 'Tennessee'},
        {'city': 'Green River', 'state': 'Wyoming'},
        {'city': 'Green Valley', 'state': 'Maryland'},
        {'city': 'Green Valley', 'state': 'Arizona'},
        {'city': 'Greenacres', 'state': 'Florida'},
        {'city': 'Greenbelt', 'state': 'Maryland'},
        {'city': 'Greenburgh', 'state': 'New York'},
        {'city': 'Greencastle', 'state': 'Indiana'},
        {'city': 'Greendale', 'state': 'Wisconsin'},
        {'city': 'Greeneville', 'state': 'Tennessee'},
        {'city': 'Greenfield', 'state': 'Wisconsin'},
        {'city': 'Greenfield', 'state': 'New York'},
        {'city': 'Greenfield', 'state': 'Indiana'},
        {'city': 'Greenfield', 'state': 'Massachusetts'},
        {'city': 'Greenfield', 'state': 'Massachusetts'},
        {'city': 'Greenfield', 'state': 'California'},
        {'city': 'Greenlawn', 'state': 'New York'},
        {'city': 'Greensboro', 'state': 'North Carolina'},
        {'city': 'Greensburg', 'state': 'Pennsylvania'},
        {'city': 'Greensburg', 'state': 'Indiana'},
        {'city': 'Greentree', 'state': 'New Jersey'},
        {'city': 'Greenville', 'state': 'Mississippi'},
        {'city': 'Greenville', 'state': 'Michigan'},
        {'city': 'Greenville', 'state': 'Alabama'},
        {'city': 'Greenville', 'state': 'Illinois'},
        {'city': 'Greenville', 'state': 'Pennsylvania'},
        {'city': 'Greenville', 'state': 'Rhode Island'},
        {'city': 'Greenville', 'state': 'Texas'},
        {'city': 'Greenville', 'state': 'South Carolina'},
        {'city': 'Greenville', 'state': 'North Carolina'},
        {'city': 'Greenville', 'state': 'Ohio'},
        {'city': 'Greenville', 'state': 'New York'},
        {'city': 'Greenville', 'state': 'Wisconsin'},
        {'city': 'Greenwich', 'state': 'Connecticut'},
        {'city': 'Greenwood', 'state': 'Indiana'},
        {'city': 'Greenwood', 'state': 'Mississippi'},
        {'city': 'Greenwood', 'state': 'Arkansas'},
        {'city': 'Greenwood', 'state': 'South Carolina'},
        {'city': 'Greenwood Village', 'state': 'Colorado'},
        {'city': 'Greer', 'state': 'South Carolina'},
        {'city': 'Grenada', 'state': 'Mississippi'},
        {'city': 'Gresham', 'state': 'Oregon'},
        {'city': 'Gresham Park', 'state': 'Georgia'},
        {'city': 'Gretna', 'state': 'Louisiana'},
        {'city': 'Griffin', 'state': 'Georgia'},
        {'city': 'Griffith', 'state': 'Indiana'},
        {'city': 'Grinnell', 'state': 'Iowa'},
        {'city': 'Griswold', 'state': 'Connecticut'},
        {'city': 'Groesbeck', 'state': 'Ohio'},
        {'city': 'Grosse Ile', 'state': 'Michigan'},
        {'city': 'Grosse Pointe Farms', 'state': 'Michigan'},
        {'city': 'Grosse Pointe Park', 'state': 'Michigan'},
        {'city': 'Grosse Pointe Woods', 'state': 'Michigan'},
        {'city': 'Groton', 'state': 'Massachusetts'},
        {'city': 'Groton', 'state': 'Connecticut'},
        {'city': 'Groton', 'state': 'Connecticut'},
        {'city': 'Grove City', 'state': 'Ohio'},
        {'city': 'Grove City', 'state': 'Pennsylvania'},
        {'city': 'Groveland', 'state': 'Massachusetts'},
        {'city': 'Grover Beach', 'state': 'California'},
        {'city': 'Groves', 'state': 'Texas'},
        {'city': 'Groveton', 'state': 'Virginia'},
        {'city': 'Grovetown', 'state': 'Georgia'},
        {'city': 'Guilderland', 'state': 'New York'},
        {'city': 'Guilford', 'state': 'Connecticut'},
        {'city': 'Gulf Gate Estates', 'state': 'Florida'},
        {'city': 'Gulfport', 'state': 'Florida'},
        {'city': 'Gulfport', 'state': 'Mississippi'},
        {'city': 'Gunbarrel', 'state': 'Colorado'},
        {'city': 'Guntersville', 'state': 'Alabama'},
        {'city': 'Gurnee', 'state': 'Illinois'},
        {'city': 'Guthrie', 'state': 'Oklahoma'},
        {'city': 'Guttenberg', 'state': 'New Jersey'},
        {'city': 'Guymon', 'state': 'Oklahoma'},
        {'city': 'Hacienda Heights', 'state': 'California'},
        {'city': 'Hackensack', 'state': 'New Jersey'},
        {'city': 'Hackettstown', 'state': 'New Jersey'},
        {'city': 'Haddam', 'state': 'Connecticut'},
        {'city': 'Haddon Heights', 'state': 'New Jersey'},
        {'city': 'Haddonfield', 'state': 'New Jersey'},
        {'city': 'Hagerstown', 'state': 'Maryland'},
        {'city': 'Haiku-Pauwela', 'state': 'Hawaii'},
        {'city': 'Hailey', 'state': 'Idaho'},
        {'city': 'Haines City', 'state': 'Florida'},
        {'city': 'Halawa', 'state': 'Hawaii'},
        {'city': 'Haledon', 'state': 'New Jersey'},
        {'city': 'Hales Corners', 'state': 'Wisconsin'},
        {'city': 'Half Moon', 'state': 'North Carolina'},
        {'city': 'Half Moon Bay', 'state': 'California'},
        {'city': 'Halfmoon', 'state': 'New York'},
        {'city': 'Halfway', 'state': 'Maryland'},
        {'city': 'Halifax', 'state': 'Massachusetts'},
        {'city': 'Hallandale', 'state': 'Florida'},
        {'city': 'Haltom City', 'state': 'Texas'},
        {'city': 'Ham Lake', 'state': 'Minnesota'},
        {'city': 'Hamburg', 'state': 'New York'},
        {'city': 'Hamburg', 'state': 'New York'},
        {'city': 'Hamden', 'state': 'Connecticut'},
        {'city': 'Hamilton', 'state': 'Alabama'},
        {'city': 'Hamilton', 'state': 'Massachusetts'},
        {'city': 'Hamilton', 'state': 'Ohio'},
        {'city': 'Hamlet', 'state': 'North Carolina'},
        {'city': 'Hamlin', 'state': 'New York'},
        {'city': 'Hammond', 'state': 'Louisiana'},
        {'city': 'Hammond', 'state': 'Indiana'},
        {'city': 'Hammonton', 'state': 'New Jersey'},
        {'city': 'Hampden', 'state': 'Maine'},
        {'city': 'Hampstead', 'state': 'New Hampshire'},
        {'city': 'Hampton', 'state': 'New Hampshire'},
        {'city': 'Hampton', 'state': 'New Hampshire'},
        {'city': 'Hampton', 'state': 'Virginia'},
        {'city': 'Hampton Bays', 'state': 'New York'},
        {'city': 'Hampton Township', 'state': 'Pennsylvania'},
        {'city': 'Hamptons at Boca Raton', 'state': 'Florida'},
        {'city': 'Hamtramck', 'state': 'Michigan'},
        {'city': 'Hanahan', 'state': 'South Carolina'},
        {'city': 'Hanford', 'state': 'California'},
        {'city': 'Hannibal', 'state': 'Missouri'},
        {'city': 'Hanover', 'state': 'New Hampshire'},
        {'city': 'Hanover', 'state': 'New Hampshire'},
        {'city': 'Hanover', 'state': 'Massachusetts'},
        {'city': 'Hanover', 'state': 'Pennsylvania'},
        {'city': 'Hanover', 'state': 'New York'},
        {'city': 'Hanover Park', 'state': 'Illinois'},
        {'city': 'Hanson', 'state': 'Massachusetts'},
        {'city': 'Hapeville', 'state': 'Georgia'},
        {'city': 'Harahan', 'state': 'Louisiana'},
        {'city': 'Harker Heights', 'state': 'Texas'},
        {'city': 'Harleysville', 'state': 'Pennsylvania'},
        {'city': 'Harlingen', 'state': 'Texas'},
        {'city': 'Harper Woods', 'state': 'Michigan'},
        {'city': 'Harriman', 'state': 'Tennessee'},
        {'city': 'Harrisburg', 'state': 'Pennsylvania'},
        {'city': 'Harrisburg', 'state': 'Illinois'},
        {'city': 'Harrison', 'state': 'Arkansas'},
        {'city': 'Harrison', 'state': 'Michigan'},
        {'city': 'Harrison', 'state': 'New Jersey'},
        {'city': 'Harrison', 'state': 'New York'},
        {'city': 'Harrison', 'state': 'Ohio'},
        {'city': 'Harrison', 'state': 'New York'},
        {'city': 'Harrison', 'state': 'Tennessee'},
        {'city': 'Harrison Township', 'state': 'Pennsylvania'},
        {'city': 'Harrisonburg', 'state': 'Virginia'},
        {'city': 'Harrisonville', 'state': 'Missouri'},
        {'city': 'Harrodsburg', 'state': 'Kentucky'},
        {'city': 'Hartford', 'state': 'Connecticut'},
        {'city': 'Hartford', 'state': 'Connecticut'},
        {'city': 'Hartford', 'state': 'Wisconsin'},
        {'city': 'Hartford', 'state': 'Vermont'},
        {'city': 'Hartford City', 'state': 'Indiana'},
        {'city': 'Hartland', 'state': 'Wisconsin'},
        {'city': 'Hartsdale', 'state': 'New York'},
        {'city': 'Hartselle', 'state': 'Alabama'},
        {'city': 'Hartsville', 'state': 'South Carolina'},
        {'city': 'Harvard', 'state': 'Illinois'},
        {'city': 'Harvey', 'state': 'Illinois'},
        {'city': 'Harvey', 'state': 'Louisiana'},
        {'city': 'Harwich', 'state': 'Massachusetts'},
        {'city': 'Harwood Heights', 'state': 'Illinois'},
        {'city': 'Hasbrouck Heights', 'state': 'New Jersey'},
        {'city': 'Haslett', 'state': 'Michigan'},
        {'city': 'Hastings', 'state': 'Michigan'},
        {'city': 'Hastings', 'state': 'Nebraska'},
        {'city': 'Hastings', 'state': 'Minnesota'},
        {'city': 'Hastings', 'state': 'New York'},
        {'city': 'Hastings-on-Hudson', 'state': 'New York'},
        {'city': 'Hatboro', 'state': 'Pennsylvania'},
        {'city': 'Hattiesburg', 'state': 'Mississippi'},
        {'city': 'Hauppauge', 'state': 'New York'},
        {'city': 'Havelock', 'state': 'North Carolina'},
        {'city': 'Haverhill', 'state': 'Massachusetts'},
        {'city': 'Haverstraw', 'state': 'New York'},
        {'city': 'Haverstraw', 'state': 'New York'},
        {'city': 'Havre', 'state': 'Montana'},
        {'city': 'Havre de Grace', 'state': 'Maryland'},
        {'city': 'Hawaiian Gardens', 'state': 'California'},
        {'city': 'Hawaiian Paradise Park', 'state': 'Hawaii'},
        {'city': 'Hawthorn Woods', 'state': 'Illinois'},
        {'city': 'Hawthorne', 'state': 'California'},
        {'city': 'Hawthorne', 'state': 'New Jersey'},
        {'city': 'Hayden', 'state': 'Idaho'},
        {'city': 'Hayesville', 'state': 'Oregon'},
        {'city': 'Hays', 'state': 'Kansas'},
        {'city': 'Haysville', 'state': 'Kansas'},
        {'city': 'Hayward', 'state': 'California'},
        {'city': 'Hazel Crest', 'state': 'Illinois'},
        {'city': 'Hazel Dell North', 'state': 'Washington'},
        {'city': 'Hazel Dell South', 'state': 'Washington'},
        {'city': 'Hazel Park', 'state': 'Michigan'},
        {'city': 'Hazelwood', 'state': 'Missouri'},
        {'city': 'Hazleton', 'state': 'Pennsylvania'},
        {'city': 'Healdsburg', 'state': 'California'},
        {'city': 'Heath', 'state': 'Ohio'},
        {'city': 'Heber', 'state': 'Utah'},
        {'city': 'Heber Springs', 'state': 'Arkansas'},
        {'city': 'Hebron', 'state': 'Connecticut'},
        {'city': 'Helena', 'state': 'Arkansas'},
        {'city': 'Helena', 'state': 'Alabama'},
        {'city': 'Helena', 'state': 'Montana'},
        {'city': 'Helena Valley Southeast', 'state': 'Montana'},
        {'city': 'Helena Valley West Central', 'state': 'Montana'},
        {'city': 'Hemet', 'state': 'California'},
        {'city': 'Hempstead', 'state': 'New York'},
        {'city': 'Hempstead', 'state': 'New York'},
        {'city': 'Henderson', 'state': 'North Carolina'},
        {'city': 'Henderson', 'state': 'Texas'},
        {'city': 'Henderson', 'state': 'Kentucky'},
        {'city': 'Henderson', 'state': 'Nevada'},
        {'city': 'Hendersonville', 'state': 'Tennessee'},
        {'city': 'Hendersonville', 'state': 'North Carolina'},
        {'city': 'Henrietta', 'state': 'New York'},
        {'city': 'Henryetta', 'state': 'Oklahoma'},
        {'city': 'Hercules', 'state': 'California'},
        {'city': 'Hereford', 'state': 'Texas'},
        {'city': 'Herkimer', 'state': 'New York'},
        {'city': 'Herkimer', 'state': 'New York'},
        {'city': 'Hermantown', 'state': 'Minnesota'},
        {'city': 'Hermiston', 'state': 'Oregon'},
        {'city': 'Hermitage', 'state': 'Pennsylvania'},
        {'city': 'Hermosa Beach', 'state': 'California'},
        {'city': 'Hernando', 'state': 'Florida'},
        {'city': 'Hernando', 'state': 'Mississippi'},
        {'city': 'Herndon', 'state': 'Virginia'},
        {'city': 'Herrin', 'state': 'Illinois'},
        {'city': 'Hershey', 'state': 'Pennsylvania'},
        {'city': 'Hesperia', 'state': 'California'},
        {'city': 'Hewitt', 'state': 'Texas'},
        {'city': 'Hewlett', 'state': 'New York'},
        {'city': 'Hialeah', 'state': 'Florida'},
        {'city': 'Hialeah Gardens', 'state': 'Florida'},
        {'city': 'Hiawatha', 'state': 'Iowa'},
        {'city': 'Hibbing', 'state': 'Minnesota'},
        {'city': 'Hickory', 'state': 'North Carolina'},
        {'city': 'Hickory Hills', 'state': 'Illinois'},
        {'city': 'Hicksville', 'state': 'New York'},
        {'city': 'Hidalgo', 'state': 'Texas'},
        {'city': 'High Point', 'state': 'North Carolina'},
        {'city': 'Highland', 'state': 'Utah'},
        {'city': 'Highland', 'state': 'Illinois'},
        {'city': 'Highland', 'state': 'Indiana'},
        {'city': 'Highland', 'state': 'California'},
        {'city': 'Highland Heights', 'state': 'Kentucky'},
        {'city': 'Highland Heights', 'state': 'Ohio'},
        {'city': 'Highland Park', 'state': 'Texas'},
        {'city': 'Highland Park', 'state': 'Illinois'},
        {'city': 'Highland Park', 'state': 'New Jersey'},
        {'city': 'Highland Park', 'state': 'Michigan'},
        {'city': 'Highland Springs', 'state': 'Virginia'},
        {'city': 'Highland Village', 'state': 'Texas'},
        {'city': 'Highlands', 'state': 'Texas'},
        {'city': 'Highlands', 'state': 'New York'},
        {'city': 'Highlands Ranch', 'state': 'Colorado'},
        {'city': 'Highview', 'state': 'Kentucky'},
        {'city': 'Hillcrest', 'state': 'New York'},
        {'city': 'Hillcrest Heights', 'state': 'Maryland'},
        {'city': 'Hilliard', 'state': 'Ohio'},
        {'city': 'Hillsboro', 'state': 'Ohio'},
        {'city': 'Hillsboro', 'state': 'Oregon'},
        {'city': 'Hillsboro', 'state': 'Texas'},
        {'city': 'Hillsborough', 'state': 'California'},
        {'city': 'Hillsdale', 'state': 'Michigan'},
        {'city': 'Hillsdale', 'state': 'New Jersey'},
        {'city': 'Hillside', 'state': 'New Jersey'},
        {'city': 'Hillside', 'state': 'Illinois'},
        {'city': 'Hillview', 'state': 'Kentucky'},
        {'city': 'Hilo', 'state': 'Hawaii'},
        {'city': 'Hilton Head Island', 'state': 'South Carolina'},
        {'city': 'Hinesville', 'state': 'Georgia'},
        {'city': 'Hingham', 'state': 'Massachusetts'},
        {'city': 'Hinsdale', 'state': 'Illinois'},
        {'city': 'Hitchcock', 'state': 'Texas'},
        {'city': 'Hobart', 'state': 'Washington'},
        {'city': 'Hobart', 'state': 'Indiana'},
        {'city': 'Hobbs', 'state': 'New Mexico'},
        {'city': 'Hobe Sound', 'state': 'Florida'},
        {'city': 'Hoboken', 'state': 'New Jersey'},
        {'city': 'Hockessin', 'state': 'Delaware'},
        {'city': 'Hoffman Estates', 'state': 'Illinois'},
        {'city': 'Holbrook', 'state': 'Massachusetts'},
        {'city': 'Holbrook', 'state': 'Massachusetts'},
        {'city': 'Holbrook', 'state': 'New York'},
        {'city': 'Holden', 'state': 'Massachusetts'},
        {'city': 'Holiday', 'state': 'Florida'},
        {'city': 'Holiday City-Berkeley', 'state': 'New Jersey'},
        {'city': 'Holladay', 'state': 'Utah'},
        {'city': 'Holland', 'state': 'Michigan'},
        {'city': 'Hollins', 'state': 'Virginia'},
        {'city': 'Hollis', 'state': 'New Hampshire'},
        {'city': 'Hollister', 'state': 'California'},
        {'city': 'Holliston', 'state': 'Massachusetts'},
        {'city': 'Holly', 'state': 'Michigan'},
        {'city': 'Holly Hill', 'state': 'Florida'},
        {'city': 'Holly Springs', 'state': 'Mississippi'},
        {'city': 'Holly Springs', 'state': 'North Carolina'},
        {'city': 'Hollywood', 'state': 'Florida'},
        {'city': 'Holmen', 'state': 'Wisconsin'},
        {'city': 'Holt', 'state': 'Michigan'},
        {'city': 'Holtsville', 'state': 'New York'},
        {'city': 'Holualoa', 'state': 'Hawaii'},
        {'city': 'Holyoke', 'state': 'Massachusetts'},
        {'city': 'Home Gardens', 'state': 'California'},
        {'city': 'Homeacre-Lyndora', 'state': 'Pennsylvania'},
        {'city': 'Homeland Park', 'state': 'South Carolina'},
        {'city': 'Homer', 'state': 'New York'},
        {'city': 'Homestead', 'state': 'Florida'},
        {'city': 'Homestead Meadows South', 'state': 'Texas'},
        {'city': 'Homewood', 'state': 'Alabama'},
        {'city': 'Homewood', 'state': 'Illinois'},
        {'city': 'Homosassa Springs', 'state': 'Florida'},
        {'city': 'Hondo', 'state': 'Texas'},
        {'city': 'Honolulu', 'state': 'Hawaii'},
        {'city': 'Hooksett', 'state': 'New Hampshire'},
        {'city': 'Hoosick', 'state': 'New York'},
        {'city': 'Hoover', 'state': 'Alabama'},
        {'city': 'Hopatcong', 'state': 'New Jersey'},
        {'city': 'Hope', 'state': 'Arkansas'},
        {'city': 'Hope Mills', 'state': 'North Carolina'},
        {'city': 'Hopewell', 'state': 'Virginia'},
        {'city': 'Hopkins', 'state': 'Minnesota'},
        {'city': 'Hopkinsville', 'state': 'Kentucky'},
        {'city': 'Hopkinton', 'state': 'Massachusetts'},
        {'city': 'Hopkinton', 'state': 'Rhode Island'},
        {'city': 'Hoquiam', 'state': 'Washington'},
        {'city': 'Horn Lake', 'state': 'Mississippi'},
        {'city': 'Hornell', 'state': 'New York'},
        {'city': 'Horseheads', 'state': 'New York'},
        {'city': 'Horseheads', 'state': 'New York'},
        {'city': 'Horsham', 'state': 'Pennsylvania'},
        {'city': 'Hot Springs', 'state': 'Arkansas'},
        {'city': 'Hot Springs Village', 'state': 'Arkansas'},
        {'city': 'Houghton', 'state': 'Michigan'},
        {'city': 'Houlton', 'state': 'Maine'},
        {'city': 'Houma', 'state': 'Louisiana'},
        {'city': 'Houston', 'state': 'Texas'},
        {'city': 'Howard', 'state': 'Wisconsin'},
        {'city': 'Howell', 'state': 'Michigan'},
        {'city': 'Howland Center', 'state': 'Ohio'},
        {'city': 'Hubbard', 'state': 'Ohio'},
        {'city': 'Huber Heights', 'state': 'Ohio'},
        {'city': 'Hudson', 'state': 'Ohio'},
        {'city': 'Hudson', 'state': 'New York'},
        {'city': 'Hudson', 'state': 'Wisconsin'},
        {'city': 'Hudson', 'state': 'Wisconsin'},
        {'city': 'Hudson', 'state': 'Massachusetts'},
        {'city': 'Hudson', 'state': 'Massachusetts'},
        {'city': 'Hudson', 'state': 'New Hampshire'},
        {'city': 'Hudson', 'state': 'New Hampshire'},
        {'city': 'Hudson', 'state': 'Florida'},
        {'city': 'Hudson Falls', 'state': 'New York'},
        {'city': 'Hudsonville', 'state': 'Michigan'},
        {'city': 'Hueytown', 'state': 'Alabama'},
        {'city': 'Hugo', 'state': 'Minnesota'},
        {'city': 'Hull', 'state': 'Massachusetts'},
        {'city': 'Hull', 'state': 'Massachusetts'},
        {'city': 'Humble', 'state': 'Texas'},
        {'city': 'Humboldt', 'state': 'Tennessee'},
        {'city': 'Hunters Creek', 'state': 'Florida'},
        {'city': 'Huntersville', 'state': 'North Carolina'},
        {'city': 'Huntingdon', 'state': 'Pennsylvania'},
        {'city': 'Huntington', 'state': 'New York'},
        {'city': 'Huntington', 'state': 'New York'},
        {'city': 'Huntington', 'state': 'Virginia'},
        {'city': 'Huntington', 'state': 'West Virginia'},
        {'city': 'Huntington', 'state': 'Indiana'},
        {'city': 'Huntington Beach', 'state': 'California'},
        {'city': 'Huntington Park', 'state': 'California'},
        {'city': 'Huntington Station', 'state': 'New York'},
        {'city': 'Huntington Woods', 'state': 'Michigan'},
        {'city': 'Huntsville', 'state': 'Alabama'},
        {'city': 'Huntsville', 'state': 'Texas'},
        {'city': 'Hurley', 'state': 'New York'},
        {'city': 'Huron', 'state': 'Ohio'},
        {'city': 'Huron', 'state': 'South Dakota'},
        {'city': 'Huron', 'state': 'California'},
        {'city': 'Hurricane', 'state': 'Utah'},
        {'city': 'Hurst', 'state': 'Texas'},
        {'city': 'Hutchinson', 'state': 'Kansas'},
        {'city': 'Hutchinson', 'state': 'Minnesota'},
        {'city': 'Hyattsville', 'state': 'Maryland'},
        {'city': 'Hybla Valley', 'state': 'Virginia'},
        {'city': 'Hyde Park', 'state': 'New York'},
        {'city': 'Hyrum', 'state': 'Utah'},
        {'city': 'Idabel', 'state': 'Oklahoma'},
        {'city': 'Idaho Falls', 'state': 'Idaho'},
        {'city': 'Idylwood', 'state': 'Virginia'},
        {'city': 'Ilion', 'state': 'New York'},
        {'city': 'Immokalee', 'state': 'Florida'},
        {'city': 'Imperial', 'state': 'California'},
        {'city': 'Imperial Beach', 'state': 'California'},
        {'city': 'Incline Village-Crystal Bay', 'state': 'Nevada'},
        {'city': 'Independence', 'state': 'Oregon'},
        {'city': 'Independence', 'state': 'Missouri'},
        {'city': 'Independence', 'state': 'Ohio'},
        {'city': 'Independence', 'state': 'Iowa'},
        {'city': 'Independence', 'state': 'Kansas'},
        {'city': 'Independence', 'state': 'Kentucky'},
        {'city': 'Indian Harbour Beach', 'state': 'Florida'},
        {'city': 'Indian Trail', 'state': 'North Carolina'},
        {'city': 'Indiana', 'state': 'Pennsylvania'},
        {'city': 'Indianapolis', 'state': 'Indiana'},
        {'city': 'Indianola', 'state': 'Mississippi'},
        {'city': 'Indianola', 'state': 'Iowa'},
        {'city': 'Indio', 'state': 'California'},
        {'city': 'Ingleside', 'state': 'Texas'},
        {'city': 'Inglewood', 'state': 'California'},
        {'city': 'Inglewood-Finn Hill', 'state': 'Washington'},
        {'city': 'Inkster', 'state': 'Michigan'},
        {'city': 'Interlaken', 'state': 'California'},
        {'city': 'International Falls', 'state': 'Minnesota'},
        {'city': 'Inver Grove Heights', 'state': 'Minnesota'},
        {'city': 'Inverness', 'state': 'Florida'},
        {'city': 'Inverness', 'state': 'Illinois'},
        {'city': 'Inwood', 'state': 'Florida'},
        {'city': 'Inwood', 'state': 'New York'},
        {'city': 'Iola', 'state': 'Kansas'},
        {'city': 'Iona', 'state': 'Florida'},
        {'city': 'Ione', 'state': 'California'},
        {'city': 'Ionia', 'state': 'Michigan'},
        {'city': 'Iowa City', 'state': 'Iowa'},
        {'city': 'Iowa Park', 'state': 'Texas'},
        {'city': 'Ipswich', 'state': 'Massachusetts'},
        {'city': 'Irmo', 'state': 'South Carolina'},
        {'city': 'Iron Mountain', 'state': 'Michigan'},
        {'city': 'Irondale', 'state': 'Alabama'},
        {'city': 'Irondale', 'state': 'Georgia'},
        {'city': 'Irondequoit', 'state': 'New York'},
        {'city': 'Irondequoit', 'state': 'New York'},
        {'city': 'Ironton', 'state': 'Ohio'},
        {'city': 'Ironwood', 'state': 'Michigan'},
        {'city': 'Irvine', 'state': 'California'},
        {'city': 'Irving', 'state': 'Texas'},
        {'city': 'Irvington', 'state': 'New Jersey'},
        {'city': 'Irvington', 'state': 'New York'},
        {'city': 'Iselin', 'state': 'New Jersey'},
        {'city': 'Ishpeming', 'state': 'Michigan'},
        {'city': 'Isla Vista', 'state': 'California'},
        {'city': 'Islamorada', 'state': 'Florida'},
        {'city': 'Island Lake', 'state': 'Illinois'},
        {'city': 'Islip', 'state': 'New York'},
        {'city': 'Islip', 'state': 'New York'},
        {'city': 'Issaquah', 'state': 'Washington'},
        {'city': 'Itasca', 'state': 'Illinois'},
        {'city': 'Ithaca', 'state': 'New York'},
        {'city': 'Ithaca', 'state': 'New York'},
        {'city': 'Ives Estates', 'state': 'Florida'},
        {'city': 'Jacinto City', 'state': 'Texas'},
        {'city': 'Jackson', 'state': 'Tennessee'},
        {'city': 'Jackson', 'state': 'Ohio'},
        {'city': 'Jackson', 'state': 'Wyoming'},
        {'city': 'Jackson', 'state': 'Michigan'},
        {'city': 'Jackson', 'state': 'Mississippi'},
        {'city': 'Jackson', 'state': 'Missouri'},
        {'city': 'Jacksonville', 'state': 'Alabama'},
        {'city': 'Jacksonville', 'state': 'Arkansas'},
        {'city': 'Jacksonville', 'state': 'Florida'},
        {'city': 'Jacksonville', 'state': 'Illinois'},
        {'city': 'Jacksonville', 'state': 'North Carolina'},
        {'city': 'Jacksonville', 'state': 'Texas'},
        {'city': 'Jacksonville Beach', 'state': 'Florida'},
        {'city': 'Jamesburg', 'state': 'New Jersey'},
        {'city': 'Jamestown', 'state': 'North Dakota'},
        {'city': 'Jamestown', 'state': 'New York'},
        {'city': 'Janesville', 'state': 'Wisconsin'},
        {'city': 'Jasmine Estates', 'state': 'Florida'},
        {'city': 'Jasper', 'state': 'Alabama'},
        {'city': 'Jasper', 'state': 'Indiana'},
        {'city': 'Jasper', 'state': 'Texas'},
        {'city': 'Jeannette', 'state': 'Pennsylvania'},
        {'city': 'Jefferson', 'state': 'Wisconsin'},
        {'city': 'Jefferson', 'state': 'Virginia'},
        {'city': 'Jefferson', 'state': 'Louisiana'},
        {'city': 'Jefferson City', 'state': 'Missouri'},
        {'city': 'Jefferson City', 'state': 'Tennessee'},
        {'city': 'Jefferson Hills', 'state': 'Pennsylvania'},
        {'city': 'Jefferson Valley-Yorktown', 'state': 'New York'},
        {'city': 'Jeffersontown', 'state': 'Kentucky'},
        {'city': 'Jeffersonville', 'state': 'Indiana'},
        {'city': 'Jenison', 'state': 'Michigan'},
        {'city': 'Jenks', 'state': 'Oklahoma'},
        {'city': 'Jennings', 'state': 'Louisiana'},
        {'city': 'Jennings', 'state': 'Missouri'},
        {'city': 'Jennings Lodge', 'state': 'Oregon'},
        {'city': 'Jensen Beach', 'state': 'Florida'},
        {'city': 'Jericho', 'state': 'New York'},
        {'city': 'Jerome', 'state': 'Idaho'},
        {'city': 'Jersey City', 'state': 'New Jersey'},
        {'city': 'Jersey Village', 'state': 'Texas'},
        {'city': 'Jerseyville', 'state': 'Illinois'},
        {'city': 'Jessup', 'state': 'Maryland'},
        {'city': 'Jesup', 'state': 'Georgia'},
        {'city': 'Johnson City', 'state': 'New York'},
        {'city': 'Johnson City', 'state': 'Tennessee'},
        {'city': 'Johnston', 'state': 'Rhode Island'},
        {'city': 'Johnston', 'state': 'Iowa'},
        {'city': 'Johnstown', 'state': 'New York'},
        {'city': 'Johnstown', 'state': 'New York'},
        {'city': 'Johnstown', 'state': 'Pennsylvania'},
        {'city': 'Joliet', 'state': 'Illinois'},
        {'city': 'Jollyville', 'state': 'Texas'},
        {'city': 'Jonesboro', 'state': 'Arkansas'},
        {'city': 'Joplin', 'state': 'Missouri'},
        {'city': 'Joppatowne', 'state': 'Maryland'},
        {'city': 'Junction City', 'state': 'Kansas'},
        {'city': 'Juneau and', 'state': 'Alaska'},
        {'city': 'Jupiter', 'state': 'Florida'},
        {'city': 'Justice', 'state': 'Illinois'},
        {'city': 'Kahului', 'state': 'Hawaii'},
        {'city': 'Kailua', 'state': 'Hawaii'},
        {'city': 'Kailua', 'state': 'Hawaii'},
        {'city': 'Kalamazoo', 'state': 'Michigan'},
        {'city': 'Kalaoa', 'state': 'Hawaii'},
        {'city': 'Kalispell', 'state': 'Montana'},
        {'city': 'Kaneohe', 'state': 'Hawaii'},
        {'city': 'Kaneohe Station', 'state': 'Hawaii'},
        {'city': 'Kankakee', 'state': 'Illinois'},
        {'city': 'Kannapolis', 'state': 'North Carolina'},
        {'city': 'Kansas City', 'state': 'Missouri'},
        {'city': 'Kansas City', 'state': 'Kansas'},
        {'city': 'Kapaa', 'state': 'Hawaii'},
        {'city': 'Katy', 'state': 'Texas'},
        {'city': 'Kaufman', 'state': 'Texas'},
        {'city': 'Kaukauna', 'state': 'Wisconsin'},
        {'city': 'Kaysville', 'state': 'Utah'},
        {'city': 'Keansburg', 'state': 'New Jersey'},
        {'city': 'Kearney', 'state': 'Nebraska'},
        {'city': 'Kearns', 'state': 'Utah'},
        {'city': 'Kearny', 'state': 'New Jersey'},
        {'city': 'Keene', 'state': 'New Hampshire'},
        {'city': 'Keizer', 'state': 'Oregon'},
        {'city': 'Keller', 'state': 'Texas'},
        {'city': 'Kelso', 'state': 'Washington'},
        {'city': 'Kemp Mill', 'state': 'Maryland'},
        {'city': 'Ken Caryl', 'state': 'Colorado'},
        {'city': 'Kenai', 'state': 'Alaska'},
        {'city': 'Kendale Lakes', 'state': 'Florida'},
        {'city': 'Kendall', 'state': 'Florida'},
        {'city': 'Kendall Park', 'state': 'New Jersey'},
        {'city': 'Kendall West', 'state': 'Florida'},
        {'city': 'Kendallville', 'state': 'Indiana'},
        {'city': 'Kenilworth', 'state': 'New Jersey'},
        {'city': 'Kenmore', 'state': 'New York'},
        {'city': 'Kenmore', 'state': 'Washington'},
        {'city': 'Kennebunk', 'state': 'Maine'},
        {'city': 'Kennedy Township', 'state': 'Pennsylvania'},
        {'city': 'Kenner', 'state': 'Louisiana'},
        {'city': 'Kennesaw', 'state': 'Georgia'},
        {'city': 'Kennett', 'state': 'Missouri'},
        {'city': 'Kennewick', 'state': 'Washington'},
        {'city': 'Kenosha', 'state': 'Wisconsin'},
        {'city': 'Kensington', 'state': 'Connecticut'},
        {'city': 'Kent', 'state': 'Washington'},
        {'city': 'Kent', 'state': 'Ohio'},
        {'city': 'Kent', 'state': 'New York'},
        {'city': 'Kentfield', 'state': 'California'},
        {'city': 'Kenton', 'state': 'Ohio'},
        {'city': 'Kentwood', 'state': 'Michigan'},
        {'city': 'Kenwood', 'state': 'Ohio'},
        {'city': 'Keokuk', 'state': 'Iowa'},
        {'city': 'Kerman', 'state': 'California'},
        {'city': 'Kernersville', 'state': 'North Carolina'},
        {'city': 'Kerrville', 'state': 'Texas'},
        {'city': 'Ketchikan', 'state': 'Alaska'},
        {'city': 'Kettering', 'state': 'Maryland'},
        {'city': 'Kettering', 'state': 'Ohio'},
        {'city': 'Kewanee', 'state': 'Illinois'},
        {'city': 'Key Biscayne', 'state': 'Florida'},
        {'city': 'Key Largo', 'state': 'Florida'},
        {'city': 'Key West', 'state': 'Florida'},
        {'city': 'Keyport', 'state': 'New Jersey'},
        {'city': 'Keystone', 'state': 'Florida'},
        {'city': 'Kihei', 'state': 'Hawaii'},
        {'city': 'Kilgore', 'state': 'Texas'},
        {'city': 'Killeen', 'state': 'Texas'},
        {'city': 'Killingly', 'state': 'Connecticut'},
        {'city': 'Killingworth', 'state': 'Connecticut'},
        {'city': 'Kimberly', 'state': 'Wisconsin'},
        {'city': 'Kinderhook', 'state': 'New York'},
        {'city': 'King City', 'state': 'California'},
        {'city': 'King of Prussia', 'state': 'Pennsylvania'},
        {'city': 'Kingman', 'state': 'Arizona'},
        {'city': 'Kings Grant', 'state': 'North Carolina'},
        {'city': 'Kings Mountain', 'state': 'North Carolina'},
        {'city': 'Kings Park', 'state': 'New York'},
        {'city': 'Kings Point', 'state': 'Florida'},
        {'city': 'Kingsburg', 'state': 'California'},
        {'city': 'Kingsbury', 'state': 'New York'},
        {'city': 'Kingsgate', 'state': 'Washington'},
        {'city': 'Kingsland', 'state': 'Georgia'},
        {'city': 'Kingsport', 'state': 'Tennessee'},
        {'city': 'Kingston', 'state': 'Pennsylvania'},
        {'city': 'Kingston', 'state': 'New York'},
        {'city': 'Kingston', 'state': 'Massachusetts'},
        {'city': 'Kingsville', 'state': 'Texas'},
        {'city': 'Kinnelon', 'state': 'New Jersey'},
        {'city': 'Kinston', 'state': 'North Carolina'},
        {'city': 'Kirby', 'state': 'Texas'},
        {'city': 'Kirkland', 'state': 'Washington'},
        {'city': 'Kirkland', 'state': 'New York'},
        {'city': 'Kirksville', 'state': 'Missouri'},
        {'city': 'Kirkwood', 'state': 'Missouri'},
        {'city': 'Kirtland', 'state': 'New Mexico'},
        {'city': 'Kirtland', 'state': 'Ohio'},
        {'city': 'Kiryas Joel', 'state': 'New York'},
        {'city': 'Kissimmee', 'state': 'Florida'},
        {'city': 'Kittery', 'state': 'Maine'},
        {'city': 'Klamath Falls', 'state': 'Oregon'},
        {'city': 'Knik-Fairview', 'state': 'Alaska'},
        {'city': 'Knoxville', 'state': 'Iowa'},
        {'city': 'Knoxville', 'state': 'Tennessee'},
        {'city': 'Kodiak', 'state': 'Alaska'},
        {'city': 'Kokomo', 'state': 'Indiana'},
        {'city': 'Kosciusko', 'state': 'Mississippi'},
        {'city': 'Kulpsville', 'state': 'Pennsylvania'},
        {'city': 'La Canada Flintridge', 'state': 'California'},
        {'city': 'La Crescenta-Montrose', 'state': 'California'},
        {'city': 'La Crosse', 'state': 'Wisconsin'},
        {'city': 'La Fayette', 'state': 'Georgia'},
        {'city': 'La Feria', 'state': 'Texas'},
        {'city': 'La Follette', 'state': 'Tennessee'},
        {'city': 'La Grande', 'state': 'Oregon'},
        {'city': 'La Grange', 'state': 'New York'},
        {'city': 'La Grange', 'state': 'Illinois'},
        {'city': 'La Grange Park', 'state': 'Illinois'},
        {'city': 'La Habra', 'state': 'California'},
        {'city': 'La Homa', 'state': 'Texas'},
        {'city': 'La Junta', 'state': 'Colorado'},
        {'city': 'La Marque', 'state': 'Texas'},
        {'city': 'La Mesa', 'state': 'California'},
        {'city': 'La Mirada', 'state': 'California'},
        {'city': 'La Palma', 'state': 'California'},
        {'city': 'La Plata', 'state': 'Maryland'},
        {'city': 'La Porte', 'state': 'Indiana'},
        {'city': 'La Porte', 'state': 'Texas'},
        {'city': 'La Presa', 'state': 'California'},
        {'city': 'La Puente', 'state': 'California'},
        {'city': 'La Quinta', 'state': 'California'},
        {'city': 'La Riviera', 'state': 'California'},
        {'city': 'La Salle', 'state': 'Illinois'},
        {'city': 'La Vergne', 'state': 'Tennessee'},
        {'city': 'La Verne', 'state': 'California'},
        {'city': 'La Vista', 'state': 'Nebraska'},
        {'city': 'Lacey', 'state': 'Washington'},
        {'city': 'Lackawanna', 'state': 'New York'},
        {'city': 'Lackland AFB', 'state': 'Texas'},
        {'city': 'Lacombe', 'state': 'Louisiana'},
        {'city': 'Laconia', 'state': 'New Hampshire'},
        {'city': 'Ladera Heights', 'state': 'California'},
        {'city': 'Ladson', 'state': 'South Carolina'},
        {'city': 'Ladue', 'state': 'Missouri'},
        {'city': 'Lady Lake', 'state': 'Florida'},
        {'city': 'Lafayette', 'state': 'Colorado'},
        {'city': 'Lafayette', 'state': 'Louisiana'},
        {'city': 'Lafayette', 'state': 'Indiana'},
        {'city': 'Lafayette', 'state': 'California'},
        {'city': 'LaGrange', 'state': 'Georgia'},
        {'city': 'Laguna', 'state': 'California'},
        {'city': 'Laguna Beach', 'state': 'California'},
        {'city': 'Laguna Hills', 'state': 'California'},
        {'city': 'Laguna Niguel', 'state': 'California'},
        {'city': 'Laguna West-Lakeside', 'state': 'California'},
        {'city': 'Laguna Woods', 'state': 'California'},
        {'city': 'Lahaina', 'state': 'Hawaii'},
        {'city': 'Lake Arbor', 'state': 'Maryland'},
        {'city': 'Lake Arrowhead', 'state': 'California'},
        {'city': 'Lake Barcroft', 'state': 'Virginia'},
        {'city': 'Lake Bluff', 'state': 'Illinois'},
        {'city': 'Lake Butter', 'state': 'Florida'},
        {'city': 'Lake Carmel', 'state': 'New York'},
        {'city': 'Lake Charles', 'state': 'Louisiana'},
        {'city': 'Lake City', 'state': 'Florida'},
        {'city': 'Lake City', 'state': 'South Carolina'},
        {'city': 'Lake Dallas', 'state': 'Texas'},
        {'city': 'Lake Elmo', 'state': 'Minnesota'},
        {'city': 'Lake Elsinore', 'state': 'California'},
        {'city': 'Lake Forest', 'state': 'California'},
        {'city': 'Lake Forest', 'state': 'Illinois'},
        {'city': 'Lake Forest Park', 'state': 'Washington'},
        {'city': 'Lake Geneva', 'state': 'Wisconsin'},
        {'city': 'Lake Grove', 'state': 'New York'},
        {'city': 'Lake Havasu City', 'state': 'Arizona'},
        {'city': 'Lake in the Hills', 'state': 'Illinois'},
        {'city': 'Lake Jackson', 'state': 'Texas'},
        {'city': 'Lake Lorraine', 'state': 'Florida'},
        {'city': 'Lake Los Angeles', 'state': 'California'},
        {'city': 'Lake Lucerne', 'state': 'Florida'},
        {'city': 'Lake Magdalene', 'state': 'Florida'},
        {'city': 'Lake Mary', 'state': 'Florida'},
        {'city': 'Lake Mohawk', 'state': 'New Jersey'},
        {'city': 'Lake Monticello', 'state': 'Virginia'},
        {'city': 'Lake Morton-Berrydale', 'state': 'Washington'},
        {'city': 'Lake Oswego', 'state': 'Oregon'},
        {'city': 'Lake Park', 'state': 'Florida'},
        {'city': 'Lake Ridge', 'state': 'Virginia'},
        {'city': 'Lake Ronkonkoma', 'state': 'New York'},
        {'city': 'Lake Shore', 'state': 'Maryland'},
        {'city': 'Lake Shore', 'state': 'Washington'},
        {'city': 'Lake St. Louis', 'state': 'Missouri'},
        {'city': 'Lake Station', 'state': 'Indiana'},
        {'city': 'Lake Stevens', 'state': 'Washington'},
        {'city': 'Lake Wales', 'state': 'Florida'},
        {'city': 'Lake Worth', 'state': 'Florida'},
        {'city': 'Lake Worth Corridor', 'state': 'Florida'},
        {'city': 'Lake Zurich', 'state': 'Illinois'},
        {'city': 'Lakeland', 'state': 'Florida'},
        {'city': 'Lakeland', 'state': 'Tennessee'},
        {'city': 'Lakeland Highlands', 'state': 'Florida'},
        {'city': 'Lakeland North', 'state': 'Washington'},
        {'city': 'Lakeland South', 'state': 'Washington'},
        {'city': 'Lakes', 'state': 'Alaska'},
        {'city': 'Lakes by the Bay', 'state': 'Florida'},
        {'city': 'Lakes of the Four Seasons', 'state': 'Indiana'},
        {'city': 'Lakeside', 'state': 'Florida'},
        {'city': 'Lakeside', 'state': 'California'},
        {'city': 'Lakeside', 'state': 'Virginia'},
        {'city': 'Lakeville', 'state': 'Minnesota'},
        {'city': 'Lakeville', 'state': 'Massachusetts'},
        {'city': 'Lakeway', 'state': 'Texas'},
        {'city': 'Lakewood', 'state': 'Ohio'},
        {'city': 'Lakewood', 'state': 'New Jersey'},
        {'city': 'Lakewood', 'state': 'California'},
        {'city': 'Lakewood', 'state': 'Colorado'},
        {'city': 'Lakewood', 'state': 'Washington'},
        {'city': 'Lakewood Park', 'state': 'Florida'},
        {'city': 'Lamar', 'state': 'Colorado'},
        {'city': 'Lambertville', 'state': 'Michigan'},
        {'city': 'Lamesa', 'state': 'Texas'},
        {'city': 'Lamont', 'state': 'California'},
        {'city': 'Lampasas', 'state': 'Texas'},
        {'city': 'Lancaster', 'state': 'Texas'},
        {'city': 'Lancaster', 'state': 'South Carolina'},
        {'city': 'Lancaster', 'state': 'Pennsylvania'},
        {'city': 'Lancaster', 'state': 'Ohio'},
        {'city': 'Lancaster', 'state': 'New York'},
        {'city': 'Lancaster', 'state': 'Massachusetts'},
        {'city': 'Lancaster', 'state': 'New York'},
        {'city': 'Lancaster', 'state': 'California'},
        {'city': 'Land O’ Lakes', 'state': 'Florida'},
        {'city': 'Landen', 'state': 'Ohio'},
        {'city': 'Lander', 'state': 'Wyoming'},
        {'city': 'Lanett', 'state': 'Alabama'},
        {'city': 'Langley Park', 'state': 'Maryland'},
        {'city': 'Lanham-Seabrook', 'state': 'Maryland'},
        {'city': 'Lansdale', 'state': 'Pennsylvania'},
        {'city': 'Lansdowne', 'state': 'Pennsylvania'},
        {'city': 'Lansdowne-Baltimore Highlands', 'state': 'Maryland'},
        {'city': 'Lansing', 'state': 'Kansas'},
        {'city': 'Lansing', 'state': 'Illinois'},
        {'city': 'Lansing', 'state': 'New York'},
        {'city': 'Lansing', 'state': 'Michigan'},
        {'city': 'Lantana', 'state': 'Florida'},
        {'city': 'Lapeer', 'state': 'Michigan'},
        {'city': 'Laplace', 'state': 'Louisiana'},
        {'city': 'Laramie', 'state': 'Wyoming'},
        {'city': 'Larchmont', 'state': 'New York'},
        {'city': 'Laredo', 'state': 'Texas'},
        {'city': 'Largo', 'state': 'Maryland'},
        {'city': 'Largo', 'state': 'Florida'},
        {'city': 'Larkfield-Wikiup', 'state': 'California'},
        {'city': 'Larkspur', 'state': 'California'},
        {'city': 'Larose', 'state': 'Louisiana'},
        {'city': 'Las Cruces', 'state': 'New Mexico'},
        {'city': 'Las Vegas', 'state': 'New Mexico'},
        {'city': 'Las Vegas', 'state': 'Nevada'},
        {'city': 'Lathrop', 'state': 'California'},
        {'city': 'Latrobe', 'state': 'Pennsylvania'},
        {'city': 'Lauderdale Lakes', 'state': 'Florida'},
        {'city': 'Lauderhill', 'state': 'Florida'},
        {'city': 'Laughlin', 'state': 'Nevada'},
        {'city': 'Laurel', 'state': 'Mississippi'},
        {'city': 'Laurel', 'state': 'Montana'},
        {'city': 'Laurel', 'state': 'Florida'},
        {'city': 'Laurel', 'state': 'Maryland'},
        {'city': 'Laurel', 'state': 'Virginia'},
        {'city': 'Laurel Bay', 'state': 'South Carolina'},
        {'city': 'Laurence Harbor', 'state': 'New Jersey'},
        {'city': 'Laurens', 'state': 'South Carolina'},
        {'city': 'Laurinburg', 'state': 'North Carolina'},
        {'city': 'Lawndale', 'state': 'California'},
        {'city': 'Lawrence', 'state': 'Kansas'},
        {'city': 'Lawrence', 'state': 'Indiana'},
        {'city': 'Lawrence', 'state': 'New York'},
        {'city': 'Lawrence', 'state': 'Massachusetts'},
        {'city': 'Lawrenceburg', 'state': 'Tennessee'},
        {'city': 'Lawrenceburg', 'state': 'Kentucky'},
        {'city': 'Lawrenceville', 'state': 'Georgia'},
        {'city': 'Lawton', 'state': 'Oklahoma'},
        {'city': 'Layton', 'state': 'Utah'},
        {'city': 'Le Mars', 'state': 'Iowa'},
        {'city': 'Le Ray', 'state': 'New York'},
        {'city': 'Le Roy', 'state': 'New York'},
        {'city': 'Lea Hill', 'state': 'Washington'},
        {'city': 'Leacock-Leola-Bareville', 'state': 'Pennsylvania'},
        {'city': 'League City', 'state': 'Texas'},
        {'city': 'Leander', 'state': 'Texas'},
        {'city': 'Leavenworth', 'state': 'Kansas'},
        {'city': 'Leawood', 'state': 'Kansas'},
        {'city': 'Lebanon', 'state': 'Indiana'},
        {'city': 'Lebanon', 'state': 'Connecticut'},
        {'city': 'Lebanon', 'state': 'Pennsylvania'},
        {'city': 'Lebanon', 'state': 'Tennessee'},
        {'city': 'Lebanon', 'state': 'Oregon'},
        {'city': 'Lebanon', 'state': 'Ohio'},
        {'city': 'Lebanon', 'state': 'New Hampshire'},
        {'city': 'Lebanon', 'state': 'Missouri'},
        {'city': 'Ledyard', 'state': 'Connecticut'},
        {'city': 'Lee', 'state': 'New York'},
        {'city': 'Lee’s Summit', 'state': 'Missouri'},
        {'city': 'Leeds', 'state': 'Alabama'},
        {'city': 'Leesburg', 'state': 'Florida'},
        {'city': 'Leesburg', 'state': 'Virginia'},
        {'city': 'Leesville', 'state': 'Louisiana'},
        {'city': 'Lehi', 'state': 'Utah'},
        {'city': 'Lehigh Acres', 'state': 'Florida'},
        {'city': 'Leicester', 'state': 'Massachusetts'},
        {'city': 'Leisure City', 'state': 'Florida'},
        {'city': 'Leisure Village West-Pine Lake Park', 'state': 'New Jersey'},
        {'city': 'Leitchfield', 'state': 'Kentucky'},
        {'city': 'Lemay', 'state': 'Missouri'},
        {'city': 'Lemmon Valley-Golden Valley', 'state': 'Nevada'},
        {'city': 'Lemon Grove', 'state': 'California'},
        {'city': 'Lemont', 'state': 'Illinois'},
        {'city': 'Lemoore', 'state': 'California'},
        {'city': 'Lenexa', 'state': 'Kansas'},
        {'city': 'Lennox', 'state': 'California'},
        {'city': 'Lenoir', 'state': 'North Carolina'},
        {'city': 'Lenoir City', 'state': 'Tennessee'},
        {'city': 'Lenox', 'state': 'New York'},
        {'city': 'Leominster', 'state': 'Massachusetts'},
        {'city': 'Leon Valley', 'state': 'Texas'},
        {'city': 'Leonia', 'state': 'New Jersey'},
        {'city': 'Levelland', 'state': 'Texas'},
        {'city': 'Levittown', 'state': 'Pennsylvania'},
        {'city': 'Levittown', 'state': 'New York'},
        {'city': 'Lewisboro', 'state': 'New York'},
        {'city': 'Lewisburg', 'state': 'Tennessee'},
        {'city': 'Lewiston', 'state': 'Maine'},
        {'city': 'Lewiston', 'state': 'New York'},
        {'city': 'Lewiston', 'state': 'Idaho'},
        {'city': 'Lewistown', 'state': 'Pennsylvania'},
        {'city': 'Lewisville', 'state': 'North Carolina'},
        {'city': 'Lewisville', 'state': 'Texas'},
        {'city': 'Lexington', 'state': 'Virginia'},
        {'city': 'Lexington', 'state': 'North Carolina'},
        {'city': 'Lexington', 'state': 'South Carolina'},
        {'city': 'Lexington', 'state': 'Tennessee'},
        {'city': 'Lexington', 'state': 'Massachusetts'},
        {'city': 'Lexington', 'state': 'Nebraska'},
        {'city': 'Lexington', 'state': 'Massachusetts'},
        {'city': 'Lexington Park', 'state': 'Maryland'},
        {'city': 'Lexington-Fayette', 'state': 'Kentucky'},
        {'city': 'Liberal', 'state': 'Kansas'},
        {'city': 'Liberty', 'state': 'Missouri'},
        {'city': 'Liberty', 'state': 'New York'},
        {'city': 'Liberty', 'state': 'Texas'},
        {'city': 'Libertyville', 'state': 'Illinois'},
        {'city': 'Lighthouse Point', 'state': 'Florida'},
        {'city': 'Lilburn', 'state': 'Georgia'},
        {'city': 'Lima', 'state': 'Ohio'},
        {'city': 'Lincoln', 'state': 'Rhode Island'},
        {'city': 'Lincoln', 'state': 'Nebraska'},
        {'city': 'Lincoln', 'state': 'Massachusetts'},
        {'city': 'Lincoln', 'state': 'Illinois'},
        {'city': 'Lincoln', 'state': 'California'},
        {'city': 'Lincoln City', 'state': 'Oregon'},
        {'city': 'Lincoln Park', 'state': 'Michigan'},
        {'city': 'Lincoln Park', 'state': 'New Jersey'},
        {'city': 'Lincoln Village', 'state': 'Ohio'},
        {'city': 'Lincolnia', 'state': 'Virginia'},
        {'city': 'Lincolnshire', 'state': 'Illinois'},
        {'city': 'Lincolnton', 'state': 'North Carolina'},
        {'city': 'Lincolnwood', 'state': 'Illinois'},
        {'city': 'Lincroft', 'state': 'New Jersey'},
        {'city': 'Linda', 'state': 'California'},
        {'city': 'Linden', 'state': 'New Jersey'},
        {'city': 'Lindenhurst', 'state': 'New York'},
        {'city': 'Lindenhurst', 'state': 'Illinois'},
        {'city': 'Lindenwold', 'state': 'New Jersey'},
        {'city': 'Lindon', 'state': 'Utah'},
        {'city': 'Lindsay', 'state': 'California'},
        {'city': 'Linganore-Bartonsville', 'state': 'Maryland'},
        {'city': 'Linglestown', 'state': 'Pennsylvania'},
        {'city': 'Lino Lakes', 'state': 'Minnesota'},
        {'city': 'Linthicum', 'state': 'Maryland'},
        {'city': 'Linton Hall', 'state': 'Virginia'},
        {'city': 'Linwood', 'state': 'New Jersey'},
        {'city': 'Lionville-Marchwood', 'state': 'Pennsylvania'},
        {'city': 'Lisbon', 'state': 'Maine'},
        {'city': 'Lisbon', 'state': 'Wisconsin'},
        {'city': 'Lisle', 'state': 'Illinois'},
        {'city': 'Litchfield', 'state': 'Illinois'},
        {'city': 'Litchfield', 'state': 'Connecticut'},
        {'city': 'Litchfield', 'state': 'Minnesota'},
        {'city': 'Litchfield', 'state': 'New Hampshire'},
        {'city': 'Lititz', 'state': 'Pennsylvania'},
        {'city': 'Little Canada', 'state': 'Minnesota'},
        {'city': 'Little Chute', 'state': 'Wisconsin'},
        {'city': 'Little Cottonwood Creek Valley', 'state': 'Utah'},
        {'city': 'Little Falls', 'state': 'Minnesota'},
        {'city': 'Little Falls', 'state': 'New Jersey'},
        {'city': 'Little Ferry', 'state': 'New Jersey'},
        {'city': 'Little River', 'state': 'South Carolina'},
        {'city': 'Little Rock', 'state': 'Arkansas'},
        {'city': 'Little Silver', 'state': 'New Jersey'},
        {'city': 'Littlefield', 'state': 'Texas'},
        {'city': 'Littleton', 'state': 'Massachusetts'},
        {'city': 'Littleton', 'state': 'Colorado'},
        {'city': 'Live Oak', 'state': 'Florida'},
        {'city': 'Live Oak', 'state': 'California'},
        {'city': 'Live Oak', 'state': 'California'},
        {'city': 'Live Oak', 'state': 'Texas'},
        {'city': 'Livermore', 'state': 'California'},
        {'city': 'Livingston', 'state': 'California'},
        {'city': 'Livingston', 'state': 'Montana'},
        {'city': 'Livingston', 'state': 'New Jersey'},
        {'city': 'Livonia', 'state': 'New York'},
        {'city': 'Livonia', 'state': 'Michigan'},
        {'city': 'Lloyd', 'state': 'New York'},
        {'city': 'Lochearn', 'state': 'Maryland'},
        {'city': 'Lock Haven', 'state': 'Pennsylvania'},
        {'city': 'Lockhart', 'state': 'Florida'},
        {'city': 'Lockhart', 'state': 'Texas'},
        {'city': 'Lockport', 'state': 'Illinois'},
        {'city': 'Lockport', 'state': 'New York'},
        {'city': 'Lockport', 'state': 'New York'},
        {'city': 'Lodi', 'state': 'New Jersey'},
        {'city': 'Lodi', 'state': 'California'},
        {'city': 'Logan', 'state': 'Ohio'},
        {'city': 'Logan', 'state': 'Utah'},
        {'city': 'Logansport', 'state': 'Indiana'},
        {'city': 'Loma Linda', 'state': 'California'},
        {'city': 'Lombard', 'state': 'Illinois'},
        {'city': 'Lomita', 'state': 'California'},
        {'city': 'Lompoc', 'state': 'California'},
        {'city': 'London', 'state': 'Ohio'},
        {'city': 'Londonderry', 'state': 'New Hampshire'},
        {'city': 'Londonderry', 'state': 'New Hampshire'},
        {'city': 'Londontowne', 'state': 'Maryland'},
        {'city': 'Long Beach', 'state': 'California'},
        {'city': 'Long Beach', 'state': 'Mississippi'},
        {'city': 'Long Beach', 'state': 'New York'},
        {'city': 'Long Branch', 'state': 'New Jersey'},
        {'city': 'Long Grove', 'state': 'Illinois'},
        {'city': 'Longboat Key', 'state': 'Florida'},
        {'city': 'Longmeadow', 'state': 'Massachusetts'},
        {'city': 'Longmeadow', 'state': 'Massachusetts'},
        {'city': 'Longmont', 'state': 'Colorado'},
        {'city': 'Longview', 'state': 'Washington'},
        {'city': 'Longview', 'state': 'Texas'},
        {'city': 'Longwood', 'state': 'Florida'},
        {'city': 'Loomis', 'state': 'California'},
        {'city': 'Lorain', 'state': 'Ohio'},
        {'city': 'Lorton', 'state': 'Virginia'},
        {'city': 'Los Alamitos', 'state': 'California'},
        {'city': 'Los Alamos', 'state': 'New Mexico'},
        {'city': 'Los Altos', 'state': 'California'},
        {'city': 'Los Altos Hills', 'state': 'California'},
        {'city': 'Los Angeles', 'state': 'California'},
        {'city': 'Los Banos', 'state': 'California'},
        {'city': 'Los Gatos', 'state': 'California'},
        {'city': 'Los Lunas', 'state': 'New Mexico'},
        {'city': 'Louisville', 'state': 'Mississippi'},
        {'city': 'Louisville', 'state': 'Ohio'},
        {'city': 'Louisville', 'state': 'Colorado'},
        {'city': 'Louisville', 'state': 'Kentucky'},
        {'city': 'Loveland', 'state': 'Colorado'},
        {'city': 'Loveland', 'state': 'Ohio'},
        {'city': 'Loves Park', 'state': 'Illinois'},
        {'city': 'Lovington', 'state': 'New Mexico'},
        {'city': 'Lowell', 'state': 'Massachusetts'},
        {'city': 'Lowell', 'state': 'Indiana'},
        {'city': 'Lower Allen', 'state': 'Pennsylvania'},
        {'city': 'Lower Burrell', 'state': 'Pennsylvania'},
        {'city': 'Lubbock', 'state': 'Texas'},
        {'city': 'Lucas Valley-Marinwood', 'state': 'California'},
        {'city': 'Ludington', 'state': 'Michigan'},
        {'city': 'Ludlow', 'state': 'Massachusetts'},
        {'city': 'Lufkin', 'state': 'Texas'},
        {'city': 'Lugoff', 'state': 'South Carolina'},
        {'city': 'Luling', 'state': 'Louisiana'},
        {'city': 'Lumberton', 'state': 'North Carolina'},
        {'city': 'Lumberton', 'state': 'Texas'},
        {'city': 'Lunenburg', 'state': 'Massachusetts'},
        {'city': 'Lutherville-Timonium', 'state': 'Maryland'},
        {'city': 'Lutz', 'state': 'Florida'},
        {'city': 'Lynbrook', 'state': 'New York'},
        {'city': 'Lynchburg', 'state': 'Virginia'},
        {'city': 'Lynden', 'state': 'Washington'},
        {'city': 'Lyndhurst', 'state': 'Ohio'},
        {'city': 'Lyndhurst', 'state': 'New Jersey'},
        {'city': 'Lyndon', 'state': 'Kentucky'},
        {'city': 'Lynn', 'state': 'Massachusetts'},
        {'city': 'Lynn Haven', 'state': 'Florida'},
        {'city': 'Lynnfield', 'state': 'Massachusetts'},
        {'city': 'Lynnfield', 'state': 'Massachusetts'},
        {'city': 'Lynnwood', 'state': 'Washington'},
        {'city': 'Lynwood', 'state': 'Illinois'},
        {'city': 'Lynwood', 'state': 'California'},
        {'city': 'Lyons', 'state': 'Illinois'},
        {'city': 'Lysander', 'state': 'New York'},
        {'city': 'Mableton', 'state': 'Georgia'},
        {'city': 'Macedon', 'state': 'New York'},
        {'city': 'Macedonia', 'state': 'Ohio'},
        {'city': 'Machesney Park', 'state': 'Illinois'},
        {'city': 'Macomb', 'state': 'Illinois'},
        {'city': 'Macon', 'state': 'Georgia'},
        {'city': 'Madeira', 'state': 'Ohio'},
        {'city': 'Madera', 'state': 'California'},
        {'city': 'Madera Acres', 'state': 'California'},
        {'city': 'Madison', 'state': 'Alabama'},
        {'city': 'Madison', 'state': 'Indiana'},
        {'city': 'Madison', 'state': 'Connecticut'},
        {'city': 'Madison', 'state': 'New Jersey'},
        {'city': 'Madison', 'state': 'Mississippi'},
        {'city': 'Madison', 'state': 'South Dakota'},
        {'city': 'Madison', 'state': 'Wisconsin'},
        {'city': 'Madison', 'state': 'Wisconsin'},
        {'city': 'Madison Heights', 'state': 'Virginia'},
        {'city': 'Madison Heights', 'state': 'Michigan'},
        {'city': 'Madison Park', 'state': 'New Jersey'},
        {'city': 'Madisonville', 'state': 'Kentucky'},
        {'city': 'Magalia', 'state': 'California'},
        {'city': 'Magna', 'state': 'Utah'},
        {'city': 'Magnolia', 'state': 'Arkansas'},
        {'city': 'Mahopac', 'state': 'New York'},
        {'city': 'Mahtomedi', 'state': 'Minnesota'},
        {'city': 'Maitland', 'state': 'Florida'},
        {'city': 'Makaha', 'state': 'Hawaii'},
        {'city': 'Makakilo City', 'state': 'Hawaii'},
        {'city': 'Makawao', 'state': 'Hawaii'},
        {'city': 'Malden', 'state': 'Massachusetts'},
        {'city': 'Malibu', 'state': 'California'},
        {'city': 'Malone', 'state': 'New York'},
        {'city': 'Malone', 'state': 'New York'},
        {'city': 'Malta', 'state': 'New York'},
        {'city': 'Maltby', 'state': 'Washington'},
        {'city': 'Malvern', 'state': 'Arkansas'},
        {'city': 'Malverne', 'state': 'New York'},
        {'city': 'Mamakating', 'state': 'New York'},
        {'city': 'Mamaroneck', 'state': 'New York'},
        {'city': 'Mamaroneck', 'state': 'New York'},
        {'city': 'Mammoth Lakes', 'state': 'California'},
        {'city': 'Manasquan', 'state': 'New Jersey'},
        {'city': 'Manassas', 'state': 'Virginia'},
        {'city': 'Manassas Park', 'state': 'Virginia'},
        {'city': 'Manchester', 'state': 'Tennessee'},
        {'city': 'Manchester', 'state': 'New Hampshire'},
        {'city': 'Manchester', 'state': 'New York'},
        {'city': 'Manchester', 'state': 'Missouri'},
        {'city': 'Manchester', 'state': 'Connecticut'},
        {'city': 'Mandan', 'state': 'North Dakota'},
        {'city': 'Mandeville', 'state': 'Louisiana'},
        {'city': 'Mango', 'state': 'Florida'},
        {'city': 'Manhasset', 'state': 'New York'},
        {'city': 'Manhattan', 'state': 'Kansas'},
        {'city': 'Manhattan Beach', 'state': 'California'},
        {'city': 'Manistee', 'state': 'Michigan'},
        {'city': 'Manitowoc', 'state': 'Wisconsin'},
        {'city': 'Mankato', 'state': 'Minnesota'},
        {'city': 'Manlius', 'state': 'New York'},
        {'city': 'Manorhaven', 'state': 'New York'},
        {'city': 'Manorville', 'state': 'New York'},
        {'city': 'Mansfield', 'state': 'Massachusetts'},
        {'city': 'Mansfield', 'state': 'Connecticut'},
        {'city': 'Mansfield', 'state': 'Texas'},
        {'city': 'Mansfield', 'state': 'Ohio'},
        {'city': 'Mansfield Center', 'state': 'Massachusetts'},
        {'city': 'Manteca', 'state': 'California'},
        {'city': 'Manteno', 'state': 'Illinois'},
        {'city': 'Mantua', 'state': 'Virginia'},
        {'city': 'Manville', 'state': 'New Jersey'},
        {'city': 'Maple Glen', 'state': 'Pennsylvania'},
        {'city': 'Maple Grove', 'state': 'Minnesota'},
        {'city': 'Maple Heights', 'state': 'Ohio'},
        {'city': 'Maple Valley', 'state': 'Washington'},
        {'city': 'Maplewood', 'state': 'Minnesota'},
        {'city': 'Maplewood', 'state': 'Missouri'},
        {'city': 'Maplewood', 'state': 'New Jersey'},
        {'city': 'Maquoketa', 'state': 'Iowa'},
        {'city': 'Marana', 'state': 'Arizona'},
        {'city': 'Marathon', 'state': 'Florida'},
        {'city': 'Marblehead', 'state': 'Massachusetts'},
        {'city': 'Marblehead', 'state': 'Massachusetts'},
        {'city': 'Marcellus', 'state': 'New York'},
        {'city': 'Marco Island', 'state': 'Florida'},
        {'city': 'Marcy', 'state': 'New York'},
        {'city': 'Marengo', 'state': 'Illinois'},
        {'city': 'Margate', 'state': 'Florida'},
        {'city': 'Margate City', 'state': 'New Jersey'},
        {'city': 'Marianna', 'state': 'Florida'},
        {'city': 'Marietta', 'state': 'Georgia'},
        {'city': 'Marietta', 'state': 'Ohio'},
        {'city': 'Marina', 'state': 'California'},
        {'city': 'Marina del Rey', 'state': 'California'},
        {'city': 'Marinette', 'state': 'Wisconsin'},
        {'city': 'Marion', 'state': 'Virginia'},
        {'city': 'Marion', 'state': 'Ohio'},
        {'city': 'Marion', 'state': 'South Carolina'},
        {'city': 'Marion', 'state': 'Arkansas'},
        {'city': 'Marion', 'state': 'Indiana'},
        {'city': 'Marion', 'state': 'Iowa'},
        {'city': 'Marion', 'state': 'Illinois'},
        {'city': 'Markham', 'state': 'Illinois'},
        {'city': 'Marlborough', 'state': 'New York'},
        {'city': 'Marlborough', 'state': 'Massachusetts'},
        {'city': 'Marlin', 'state': 'Texas'},
        {'city': 'Marlow Heights', 'state': 'Maryland'},
        {'city': 'Marlton', 'state': 'Maryland'},
        {'city': 'Marlton', 'state': 'New Jersey'},
        {'city': 'Marquette', 'state': 'Michigan'},
        {'city': 'Marrero', 'state': 'Louisiana'},
        {'city': 'Marshall', 'state': 'Michigan'},
        {'city': 'Marshall', 'state': 'Minnesota'},
        {'city': 'Marshall', 'state': 'Missouri'},
        {'city': 'Marshall', 'state': 'Texas'},
        {'city': 'Marshalltown', 'state': 'Iowa'},
        {'city': 'Marshfield', 'state': 'Massachusetts'},
        {'city': 'Marshfield', 'state': 'Wisconsin'},
        {'city': 'Martha Lake', 'state': 'Washington'},
        {'city': 'Martin', 'state': 'Tennessee'},
        {'city': 'Martinez', 'state': 'Georgia'},
        {'city': 'Martinez', 'state': 'California'},
        {'city': 'Martins Ferry', 'state': 'Ohio'},
        {'city': 'Martinsburg', 'state': 'West Virginia'},
        {'city': 'Martinsville', 'state': 'Virginia'},
        {'city': 'Martinsville', 'state': 'Indiana'},
        {'city': 'Maryland City', 'state': 'Maryland'},
        {'city': 'Maryland Heights', 'state': 'Missouri'},
        {'city': 'Marysville', 'state': 'Michigan'},
        {'city': 'Marysville', 'state': 'California'},
        {'city': 'Marysville', 'state': 'Washington'},
        {'city': 'Marysville', 'state': 'Ohio'},
        {'city': 'Maryville', 'state': 'Tennessee'},
        {'city': 'Maryville', 'state': 'Missouri'},
        {'city': 'Mashpee', 'state': 'Massachusetts'},
        {'city': 'Mason', 'state': 'Michigan'},
        {'city': 'Mason', 'state': 'Ohio'},
        {'city': 'Mason City', 'state': 'Iowa'},
        {'city': 'Masonboro', 'state': 'North Carolina'},
        {'city': 'Massapequa', 'state': 'New York'},
        {'city': 'Massapequa Park', 'state': 'New York'},
        {'city': 'Massena', 'state': 'New York'},
        {'city': 'Massena', 'state': 'New York'},
        {'city': 'Massillon', 'state': 'Ohio'},
        {'city': 'Mastic', 'state': 'New York'},
        {'city': 'Mastic Beach', 'state': 'New York'},
        {'city': 'Matawan', 'state': 'New Jersey'},
        {'city': 'Mattapoisett', 'state': 'Massachusetts'},
        {'city': 'Matteson', 'state': 'Illinois'},
        {'city': 'Matthews', 'state': 'North Carolina'},
        {'city': 'Mattoon', 'state': 'Illinois'},
        {'city': 'Mattydale', 'state': 'New York'},
        {'city': 'Mauldin', 'state': 'South Carolina'},
        {'city': 'Maumee', 'state': 'Ohio'},
        {'city': 'Maumelle', 'state': 'Arkansas'},
        {'city': 'Mayfield', 'state': 'Kentucky'},
        {'city': 'Mayfield', 'state': 'New York'},
        {'city': 'Mayfield Heights', 'state': 'Ohio'},
        {'city': 'Maynard', 'state': 'Massachusetts'},
        {'city': 'Maynard', 'state': 'Massachusetts'},
        {'city': 'Mays Chapel', 'state': 'Maryland'},
        {'city': 'Maysville', 'state': 'Kentucky'},
        {'city': 'Maywood', 'state': 'Illinois'},
        {'city': 'Maywood', 'state': 'California'},
        {'city': 'Maywood', 'state': 'New Jersey'},
        {'city': 'McAlester', 'state': 'Oklahoma'},
        {'city': 'McAllen', 'state': 'Texas'},
        {'city': 'McCandless Township', 'state': 'Pennsylvania'},
        {'city': 'McComb', 'state': 'Mississippi'},
        {'city': 'McCook', 'state': 'Nebraska'},
        {'city': 'McDonough', 'state': 'Georgia'},
        {'city': 'McFarland', 'state': 'California'},
        {'city': 'McFarland', 'state': 'Wisconsin'},
        {'city': 'McGregor', 'state': 'Florida'},
        {'city': 'McGuire AFB', 'state': 'New Jersey'},
        {'city': 'McHenry', 'state': 'Illinois'},
        {'city': 'McKees Rocks', 'state': 'Pennsylvania'},
        {'city': 'McKeesport', 'state': 'Pennsylvania'},
        {'city': 'McKinleyville', 'state': 'California'},
        {'city': 'McKinney', 'state': 'Texas'},
        {'city': 'McLean', 'state': 'Virginia'},
        {'city': 'McMinnville', 'state': 'Tennessee'},
        {'city': 'McMinnville', 'state': 'Oregon'},
        {'city': 'McPherson', 'state': 'Kansas'},
        {'city': 'Meadow Woods', 'state': 'Florida'},
        {'city': 'Meadville', 'state': 'Pennsylvania'},
        {'city': 'Mebane', 'state': 'North Carolina'},
        {'city': 'Mechanicsburg', 'state': 'Pennsylvania'},
        {'city': 'Mechanicstown', 'state': 'New York'},
        {'city': 'Mechanicsville', 'state': 'Virginia'},
        {'city': 'Medfield', 'state': 'Massachusetts'},
        {'city': 'Medfield', 'state': 'Massachusetts'},
        {'city': 'Medford', 'state': 'Massachusetts'},
        {'city': 'Medford', 'state': 'New York'},
        {'city': 'Medford', 'state': 'Oregon'},
        {'city': 'Medina', 'state': 'Ohio'},
        {'city': 'Medina', 'state': 'New York'},
        {'city': 'Medulla', 'state': 'Florida'},
        {'city': 'Medway', 'state': 'Massachusetts'},
        {'city': 'Mehlville', 'state': 'Missouri'},
        {'city': 'Melbourne', 'state': 'Florida'},
        {'city': 'Melrose', 'state': 'Massachusetts'},
        {'city': 'Melrose Park', 'state': 'Florida'},
        {'city': 'Melrose Park', 'state': 'Illinois'},
        {'city': 'Melville', 'state': 'New York'},
        {'city': 'Melvindale', 'state': 'Michigan'},
        {'city': 'Memphis', 'state': 'Florida'},
        {'city': 'Memphis', 'state': 'Tennessee'},
        {'city': 'Menasha', 'state': 'Wisconsin'},
        {'city': 'Menasha', 'state': 'Wisconsin'},
        {'city': 'Mendon', 'state': 'New York'},
        {'city': 'Mendota', 'state': 'California'},
        {'city': 'Mendota', 'state': 'Illinois'},
        {'city': 'Mendota Heights', 'state': 'Minnesota'},
        {'city': 'Menlo Park', 'state': 'California'},
        {'city': 'Menominee', 'state': 'Michigan'},
        {'city': 'Menomonee Falls', 'state': 'Wisconsin'},
        {'city': 'Menomonie', 'state': 'Wisconsin'},
        {'city': 'Mentone', 'state': 'California'},
        {'city': 'Mentor', 'state': 'Ohio'},
        {'city': 'Mentor-on-the-Lake', 'state': 'Ohio'},
        {'city': 'Mequon', 'state': 'Wisconsin'},
        {'city': 'Meraux', 'state': 'Louisiana'},
        {'city': 'Merced', 'state': 'California'},
        {'city': 'Mercedes', 'state': 'Texas'},
        {'city': 'Mercer Island', 'state': 'Washington'},
        {'city': 'Mercerville-Hamilton Square', 'state': 'New Jersey'},
        {'city': 'Meriden', 'state': 'Connecticut'},
        {'city': 'Meriden', 'state': 'Connecticut'},
        {'city': 'Meridian', 'state': 'Idaho'},
        {'city': 'Meridian', 'state': 'Mississippi'},
        {'city': 'Merriam', 'state': 'Kansas'},
        {'city': 'Merrick', 'state': 'New York'},
        {'city': 'Merrifield', 'state': 'Virginia'},
        {'city': 'Merrill', 'state': 'Wisconsin'},
        {'city': 'Merrillville', 'state': 'Indiana'},
        {'city': 'Merrimac', 'state': 'Massachusetts'},
        {'city': 'Merrimack', 'state': 'New Hampshire'},
        {'city': 'Merritt Island', 'state': 'Florida'},
        {'city': 'Merrydale', 'state': 'Louisiana'},
        {'city': 'Merton', 'state': 'Wisconsin'},
        {'city': 'Mesa', 'state': 'Arizona'},
        {'city': 'Mesquite', 'state': 'Nevada'},
        {'city': 'Mesquite', 'state': 'Texas'},
        {'city': 'Metairie', 'state': 'Louisiana'},
        {'city': 'Methuen', 'state': 'Massachusetts'},
        {'city': 'Metropolis', 'state': 'Illinois'},
        {'city': 'Metuchen', 'state': 'New Jersey'},
        {'city': 'Mexia', 'state': 'Texas'},
        {'city': 'Mexico', 'state': 'Missouri'},
        {'city': 'Miami', 'state': 'Florida'},
        {'city': 'Miami', 'state': 'Oklahoma'},
        {'city': 'Miami Beach', 'state': 'Florida'},
        {'city': 'Miami Lakes', 'state': 'Florida'},
        {'city': 'Miami Shores', 'state': 'Florida'},
        {'city': 'Miami Springs', 'state': 'Florida'},
        {'city': 'Miamisburg', 'state': 'Ohio'},
        {'city': 'Micco', 'state': 'Florida'},
        {'city': 'Michigan City', 'state': 'Indiana'},
        {'city': 'Middle Island', 'state': 'New York'},
        {'city': 'Middle River', 'state': 'Maryland'},
        {'city': 'Middle Valley', 'state': 'Tennessee'},
        {'city': 'Middleborough', 'state': 'Massachusetts'},
        {'city': 'Middleborough Center', 'state': 'Massachusetts'},
        {'city': 'Middleburg', 'state': 'Florida'},
        {'city': 'Middleburg Heights', 'state': 'Ohio'},
        {'city': 'Middlebury', 'state': 'Vermont'},
        {'city': 'Middlebury', 'state': 'Vermont'},
        {'city': 'Middlebury', 'state': 'Connecticut'},
        {'city': 'Middlesborough', 'state': 'Kentucky'},
        {'city': 'Middlesex', 'state': 'New Jersey'},
        {'city': 'Middleton', 'state': 'Massachusetts'},
        {'city': 'Middleton', 'state': 'Wisconsin'},
        {'city': 'Middletown', 'state': 'Ohio'},
        {'city': 'Middletown', 'state': 'Pennsylvania'},
        {'city': 'Middletown', 'state': 'Pennsylvania'},
        {'city': 'Middletown', 'state': 'Rhode Island'},
        {'city': 'Middletown', 'state': 'New York'},
        {'city': 'Middletown', 'state': 'Connecticut'},
        {'city': 'Middletown', 'state': 'Connecticut'},
        {'city': 'Middletown', 'state': 'Delaware'},
        {'city': 'Midland', 'state': 'Michigan'},
        {'city': 'Midland', 'state': 'Texas'},
        {'city': 'Midland', 'state': 'Washington'},
        {'city': 'Midland Park', 'state': 'New Jersey'},
        {'city': 'Midlothian', 'state': 'Illinois'},
        {'city': 'Midlothian', 'state': 'Texas'},
        {'city': 'Midvale', 'state': 'Utah'},
        {'city': 'Midwest City', 'state': 'Oklahoma'},
        {'city': 'Milan', 'state': 'Tennessee'},
        {'city': 'Miles City', 'state': 'Montana'},
        {'city': 'Milford', 'state': 'New Hampshire'},
        {'city': 'Milford', 'state': 'New Hampshire'},
        {'city': 'Milford', 'state': 'Michigan'},
        {'city': 'Milford', 'state': 'Massachusetts'},
        {'city': 'Milford', 'state': 'Massachusetts'},
        {'city': 'Milford', 'state': 'Delaware'},
        {'city': 'Milford', 'state': 'Connecticut'},
        {'city': 'Milford', 'state': 'Connecticut'},
        {'city': 'Milford', 'state': 'Ohio'},
        {'city': 'Milford Mill', 'state': 'Maryland'},
        {'city': 'Mililani Town', 'state': 'Hawaii'},
        {'city': 'Mill Creek', 'state': 'Washington'},
        {'city': 'Mill Plain', 'state': 'Washington'},
        {'city': 'Mill Valley', 'state': 'California'},
        {'city': 'Millbrae', 'state': 'California'},
        {'city': 'Millbrook', 'state': 'Alabama'},
        {'city': 'Millburn', 'state': 'New Jersey'},
        {'city': 'Millbury', 'state': 'Massachusetts'},
        {'city': 'Millcreek', 'state': 'Utah'},
        {'city': 'Milledgeville', 'state': 'Georgia'},
        {'city': 'Miller Place', 'state': 'New York'},
        {'city': 'Millersville', 'state': 'Pennsylvania'},
        {'city': 'Millington', 'state': 'Tennessee'},
        {'city': 'Millis', 'state': 'Massachusetts'},
        {'city': 'Milltown', 'state': 'New Jersey'},
        {'city': 'Millville', 'state': 'New Jersey'},
        {'city': 'Milo', 'state': 'New York'},
        {'city': 'Milpitas', 'state': 'California'},
        {'city': 'Milton', 'state': 'Florida'},
        {'city': 'Milton', 'state': 'New York'},
        {'city': 'Milton', 'state': 'Massachusetts'},
        {'city': 'Milton', 'state': 'Massachusetts'},
        {'city': 'Milton', 'state': 'Pennsylvania'},
        {'city': 'Milton', 'state': 'Vermont'},
        {'city': 'Milton-Freewater', 'state': 'Oregon'},
        {'city': 'Milwaukee', 'state': 'Wisconsin'},
        {'city': 'Milwaukie', 'state': 'Oregon'},
        {'city': 'Mims', 'state': 'Florida'},
        {'city': 'Minden', 'state': 'Louisiana'},
        {'city': 'Mineola', 'state': 'New York'},
        {'city': 'Mineral Wells', 'state': 'Texas'},
        {'city': 'Minneapolis', 'state': 'Minnesota'},
        {'city': 'Minnehaha', 'state': 'Washington'},
        {'city': 'Minnetonka', 'state': 'Minnesota'},
        {'city': 'Minot', 'state': 'North Dakota'},
        {'city': 'Minot AFB', 'state': 'North Dakota'},
        {'city': 'Mint Hill', 'state': 'North Carolina'},
        {'city': 'Mira Loma', 'state': 'California'},
        {'city': 'Mira Monte', 'state': 'California'},
        {'city': 'Miramar', 'state': 'Florida'},
        {'city': 'Mishawaka', 'state': 'Indiana'},
        {'city': 'Mission', 'state': 'Kansas'},
        {'city': 'Mission', 'state': 'Texas'},
        {'city': 'Mission Bend', 'state': 'Texas'},
        {'city': 'Mission Viejo', 'state': 'California'},
        {'city': 'Missoula', 'state': 'Montana'},
        {'city': 'Missouri City', 'state': 'Texas'},
        {'city': 'Mitchell', 'state': 'South Dakota'},
        {'city': 'Mitchellville', 'state': 'Maryland'},
        {'city': 'Moberly', 'state': 'Missouri'},
        {'city': 'Mobile', 'state': 'Alabama'},
        {'city': 'Modesto', 'state': 'California'},
        {'city': 'Mohave Valley', 'state': 'Arizona'},
        {'city': 'Mokena', 'state': 'Illinois'},
        {'city': 'Moline', 'state': 'Illinois'},
        {'city': 'Monaca', 'state': 'Pennsylvania'},
        {'city': 'Monahans', 'state': 'Texas'},
        {'city': 'Monessen', 'state': 'Pennsylvania'},
        {'city': 'Monett', 'state': 'Missouri'},
        {'city': 'Monmouth', 'state': 'Illinois'},
        {'city': 'Monmouth', 'state': 'Oregon'},
        {'city': 'Monona', 'state': 'Wisconsin'},
        {'city': 'Monroe', 'state': 'Wisconsin'},
        {'city': 'Monroe', 'state': 'Washington'},
        {'city': 'Monroe', 'state': 'Ohio'},
        {'city': 'Monroe', 'state': 'Connecticut'},
        {'city': 'Monroe', 'state': 'Georgia'},
        {'city': 'Monroe', 'state': 'Michigan'},
        {'city': 'Monroe', 'state': 'Louisiana'},
        {'city': 'Monroe', 'state': 'North Carolina'},
        {'city': 'Monroe', 'state': 'New York'},
        {'city': 'Monroe', 'state': 'New York'},
        {'city': 'Monroeville', 'state': 'Alabama'},
        {'city': 'Monrovia', 'state': 'California'},
        {'city': 'Monsey', 'state': 'New York'},
        {'city': 'Monson', 'state': 'Massachusetts'},
        {'city': 'Montague', 'state': 'Massachusetts'},
        {'city': 'Montclair', 'state': 'New Jersey'},
        {'city': 'Montclair', 'state': 'California'},
        {'city': 'Montclair', 'state': 'Virginia'},
        {'city': 'Montebello', 'state': 'California'},
        {'city': 'Montecito', 'state': 'California'},
        {'city': 'Monterey', 'state': 'California'},
        {'city': 'Monterey Park', 'state': 'California'},
        {'city': 'Montgomery', 'state': 'Alabama'},
        {'city': 'Montgomery', 'state': 'New York'},
        {'city': 'Montgomery', 'state': 'Ohio'},
        {'city': 'Montgomery Village', 'state': 'Maryland'},
        {'city': 'Montgomeryville', 'state': 'Pennsylvania'},
        {'city': 'Monticello', 'state': 'Minnesota'},
        {'city': 'Monticello', 'state': 'New York'},
        {'city': 'Monticello', 'state': 'Arkansas'},
        {'city': 'Montpelier', 'state': 'Vermont'},
        {'city': 'Montrose', 'state': 'Virginia'},
        {'city': 'Montrose', 'state': 'Colorado'},
        {'city': 'Montvale', 'state': 'New Jersey'},
        {'city': 'Montville', 'state': 'Connecticut'},
        {'city': 'Moody', 'state': 'Alabama'},
        {'city': 'Moore', 'state': 'Oklahoma'},
        {'city': 'Moorestown-Lenola', 'state': 'New Jersey'},
        {'city': 'Mooresville', 'state': 'North Carolina'},
        {'city': 'Mooresville', 'state': 'Indiana'},
        {'city': 'Moorhead', 'state': 'Minnesota'},
        {'city': 'Moorpark', 'state': 'California'},
        {'city': 'Moraga', 'state': 'California'},
        {'city': 'Moraine', 'state': 'Ohio'},
        {'city': 'Moreau', 'state': 'New York'},
        {'city': 'Morehead City', 'state': 'North Carolina'},
        {'city': 'Moreno Valley', 'state': 'California'},
        {'city': 'Morgan City', 'state': 'Louisiana'},
        {'city': 'Morgan Hill', 'state': 'California'},
        {'city': 'Morganton', 'state': 'North Carolina'},
        {'city': 'Morgantown', 'state': 'West Virginia'},
        {'city': 'Morganville', 'state': 'New Jersey'},
        {'city': 'Morrilton', 'state': 'Arkansas'},
        {'city': 'Morris', 'state': 'Illinois'},
        {'city': 'Morristown', 'state': 'New Jersey'},
        {'city': 'Morristown', 'state': 'Tennessee'},
        {'city': 'Morrisville', 'state': 'Pennsylvania'},
        {'city': 'Morro Bay', 'state': 'California'},
        {'city': 'Morton', 'state': 'Illinois'},
        {'city': 'Morton Grove', 'state': 'Illinois'},
        {'city': 'Moscow', 'state': 'Idaho'},
        {'city': 'Moses Lake', 'state': 'Washington'},
        {'city': 'Moss Bluff', 'state': 'Louisiana'},
        {'city': 'Moss Point', 'state': 'Mississippi'},
        {'city': 'Moultrie', 'state': 'Georgia'},
        {'city': 'Mound', 'state': 'Minnesota'},
        {'city': 'Mounds View', 'state': 'Minnesota'},
        {'city': 'Moundsville', 'state': 'West Virginia'},
        {'city': 'Mount Airy', 'state': 'Maryland'},
        {'city': 'Mount Airy', 'state': 'North Carolina'},
        {'city': 'Mount Carmel', 'state': 'Illinois'},
        {'city': 'Mount Carmel', 'state': 'Pennsylvania'},
        {'city': 'Mount Clemens', 'state': 'Michigan'},
        {'city': 'Mount Dora', 'state': 'Florida'},
        {'city': 'Mount Healthy', 'state': 'Ohio'},
        {'city': 'Mount Holly', 'state': 'North Carolina'},
        {'city': 'Mount Hope', 'state': 'New York'},
        {'city': 'Mount Ivy', 'state': 'New York'},
        {'city': 'Mount Joy', 'state': 'Pennsylvania'},
        {'city': 'Mount Juliet', 'state': 'Tennessee'},
        {'city': 'Mount Kisco', 'state': 'New York'},
        {'city': 'Mount Kisco', 'state': 'New York'},
        {'city': 'Mount Lebanon', 'state': 'Pennsylvania'},
        {'city': 'Mount Olympus', 'state': 'Utah'},
        {'city': 'Mount Pleasant', 'state': 'Texas'},
        {'city': 'Mount Pleasant', 'state': 'Wisconsin'},
        {'city': 'Mount Pleasant', 'state': 'South Carolina'},
        {'city': 'Mount Pleasant', 'state': 'New York'},
        {'city': 'Mount Pleasant', 'state': 'Michigan'},
        {'city': 'Mount Pleasant', 'state': 'Iowa'},
        {'city': 'Mount Prospect', 'state': 'Illinois'},
        {'city': 'Mount Rainier', 'state': 'Maryland'},
        {'city': 'Mount Sinai', 'state': 'New York'},
        {'city': 'Mount Vernon', 'state': 'New York'},
        {'city': 'Mount Vernon', 'state': 'Illinois'},
        {'city': 'Mount Vernon', 'state': 'Indiana'},
        {'city': 'Mount Vernon', 'state': 'Ohio'},
        {'city': 'Mount Vernon', 'state': 'Washington'},
        {'city': 'Mount Vernon', 'state': 'Virginia'},
        {'city': 'Mount Washington', 'state': 'Kentucky'},
        {'city': 'Mountain Brook', 'state': 'Alabama'},
        {'city': 'Mountain Home', 'state': 'Arkansas'},
        {'city': 'Mountain Home', 'state': 'Idaho'},
        {'city': 'Mountain Home AFB', 'state': 'Idaho'},
        {'city': 'Mountain Park', 'state': 'Georgia'},
        {'city': 'Mountain Top', 'state': 'Pennsylvania'},
        {'city': 'Mountain View', 'state': 'California'},
        {'city': 'Mountainside', 'state': 'New Jersey'},
        {'city': 'Mountlake Terrace', 'state': 'Washington'},
        {'city': 'Mukilteo', 'state': 'Washington'},
        {'city': 'Mukwonago', 'state': 'Wisconsin'},
        {'city': 'Mukwonago', 'state': 'Wisconsin'},
        {'city': 'Muncie', 'state': 'Indiana'},
        {'city': 'Mundelein', 'state': 'Illinois'},
        {'city': 'Munhall', 'state': 'Pennsylvania'},
        {'city': 'Municipality of Monroeville', 'state': 'Pennsylvania'},
        {'city': 'Municipality of Murrysville', 'state': 'Pennsylvania'},
        {'city': 'Munster', 'state': 'Indiana'},
        {'city': 'Murfreesboro', 'state': 'Tennessee'},
        {'city': 'Murphy', 'state': 'Missouri'},
        {'city': 'Murphysboro', 'state': 'Illinois'},
        {'city': 'Murray', 'state': 'Kentucky'},
        {'city': 'Murray', 'state': 'New York'},
        {'city': 'Murray', 'state': 'Utah'},
        {'city': 'Murraysville', 'state': 'North Carolina'},
        {'city': 'Murrieta', 'state': 'California'},
        {'city': 'Muscatine', 'state': 'Iowa'},
        {'city': 'Muscle Shoals', 'state': 'Alabama'},
        {'city': 'Muscoy', 'state': 'California'},
        {'city': 'Muskego', 'state': 'Wisconsin'},
        {'city': 'Muskegon', 'state': 'Michigan'},
        {'city': 'Muskegon Heights', 'state': 'Michigan'},
        {'city': 'Muskogee', 'state': 'Oklahoma'},
        {'city': 'Mustang', 'state': 'Oklahoma'},
        {'city': 'Myrtle Beach', 'state': 'South Carolina'},
        {'city': 'Myrtle Grove', 'state': 'North Carolina'},
        {'city': 'Myrtle Grove', 'state': 'Florida'},
        {'city': 'Mystic Island', 'state': 'New Jersey'},
        {'city': 'Nacogdoches', 'state': 'Texas'},
        {'city': 'Nampa', 'state': 'Idaho'},
        {'city': 'Nanakuli', 'state': 'Hawaii'},
        {'city': 'Nanticoke', 'state': 'Pennsylvania'},
        {'city': 'Nantucket', 'state': 'Massachusetts'},
        {'city': 'Nanuet', 'state': 'New York'},
        {'city': 'Napa', 'state': 'California'},
        {'city': 'Naperville', 'state': 'Illinois'},
        {'city': 'Napili-Honokowai', 'state': 'Hawaii'},
        {'city': 'Naples', 'state': 'Florida'},
        {'city': 'Naples Park', 'state': 'Florida'},
        {'city': 'Napoleon', 'state': 'Ohio'},
        {'city': 'Nappanee', 'state': 'Indiana'},
        {'city': 'Narragansett', 'state': 'Rhode Island'},
        {'city': 'Nashua', 'state': 'New Hampshire'},
        {'city': 'Nashville-Davidson', 'state': 'Tennessee'},
        {'city': 'Natchez', 'state': 'Mississippi'},
        {'city': 'Natchitoches', 'state': 'Louisiana'},
        {'city': 'Natick', 'state': 'Massachusetts'},
        {'city': 'National City', 'state': 'California'},
        {'city': 'Naugatuck', 'state': 'Connecticut'},
        {'city': 'Naugatuck', 'state': 'Connecticut'},
        {'city': 'Navasota', 'state': 'Texas'},
        {'city': 'Nazareth', 'state': 'Pennsylvania'},
        {'city': 'Nebraska City', 'state': 'Nebraska'},
        {'city': 'Nederland', 'state': 'Texas'},
        {'city': 'Needham', 'state': 'Massachusetts'},
        {'city': 'Needham', 'state': 'Massachusetts'},
        {'city': 'Neenah', 'state': 'Wisconsin'},
        {'city': 'Nellis AFB', 'state': 'Nevada'},
        {'city': 'Neosho', 'state': 'Missouri'},
        {'city': 'Neptune Beach', 'state': 'Florida'},
        {'city': 'Nesconset', 'state': 'New York'},
        {'city': 'Nether Providence Township', 'state': 'Pennsylvania'},
        {'city': 'Nevada', 'state': 'Missouri'},
        {'city': 'Nevada', 'state': 'Iowa'},
        {'city': 'New Albany', 'state': 'Indiana'},
        {'city': 'New Albany', 'state': 'Mississippi'},
        {'city': 'New Baltimore', 'state': 'Michigan'},
        {'city': 'New Bedford', 'state': 'Massachusetts'},
        {'city': 'New Berlin', 'state': 'Wisconsin'},
        {'city': 'New Bern', 'state': 'North Carolina'},
        {'city': 'New Braunfels', 'state': 'Texas'},
        {'city': 'New Brighton', 'state': 'Pennsylvania'},
        {'city': 'New Brighton', 'state': 'Minnesota'},
        {'city': 'New Britain', 'state': 'Connecticut'},
        {'city': 'New Britain', 'state': 'Connecticut'},
        {'city': 'New Brunswick', 'state': 'New Jersey'},
        {'city': 'New Canaan', 'state': 'Connecticut'},
        {'city': 'New Carrollton', 'state': 'Maryland'},
        {'city': 'New Cassel', 'state': 'New York'},
        {'city': 'New Castle', 'state': 'New York'},
        {'city': 'New Castle', 'state': 'Pennsylvania'},
        {'city': 'New Castle', 'state': 'Indiana'},
        {'city': 'New City', 'state': 'New York'},
        {'city': 'New Cumberland', 'state': 'Pennsylvania'},
        {'city': 'New Fairfield', 'state': 'Connecticut'},
        {'city': 'New Hartford', 'state': 'Connecticut'},
        {'city': 'New Hartford', 'state': 'New York'},
        {'city': 'New Haven', 'state': 'Connecticut'},
        {'city': 'New Haven', 'state': 'Connecticut'},
        {'city': 'New Haven', 'state': 'Indiana'},
        {'city': 'New Hope', 'state': 'Minnesota'},
        {'city': 'New Hyde Park', 'state': 'New York'},
        {'city': 'New Iberia', 'state': 'Louisiana'},
        {'city': 'New Kensington', 'state': 'Pennsylvania'},
        {'city': 'New Kingman-Butler', 'state': 'Arizona'},
        {'city': 'New Lenox', 'state': 'Illinois'},
        {'city': 'New London', 'state': 'Connecticut'},
        {'city': 'New London', 'state': 'Connecticut'},
        {'city': 'New London', 'state': 'Wisconsin'},
        {'city': 'New Milford', 'state': 'Connecticut'},
        {'city': 'New Milford', 'state': 'Connecticut'},
        {'city': 'New Milford', 'state': 'New Jersey'},
        {'city': 'New Orleans', 'state': 'Louisiana'},
        {'city': 'New Paltz', 'state': 'New York'},
        {'city': 'New Paltz', 'state': 'New York'},
        {'city': 'New Philadelphia', 'state': 'Ohio'},
        {'city': 'New Port Richey', 'state': 'Florida'},
        {'city': 'New Port Richey East', 'state': 'Florida'},
        {'city': 'New Providence', 'state': 'New Jersey'},
        {'city': 'New Richmond', 'state': 'Wisconsin'},
        {'city': 'New River', 'state': 'Arizona'},
        {'city': 'New Rochelle', 'state': 'New York'},
        {'city': 'New Scotland', 'state': 'New York'},
        {'city': 'New Smyrna Beach', 'state': 'Florida'},
        {'city': 'New Territory', 'state': 'Texas'},
        {'city': 'New Ulm', 'state': 'Minnesota'},
        {'city': 'New Windsor', 'state': 'New York'},
        {'city': 'New Windsor', 'state': 'New York'},
        {'city': 'New York', 'state': 'New York'},
        {'city': 'Newark', 'state': 'New Jersey'},
        {'city': 'Newark', 'state': 'New York'},
        {'city': 'Newark', 'state': 'Ohio'},
        {'city': 'Newark', 'state': 'Delaware'},
        {'city': 'Newark', 'state': 'California'},
        {'city': 'Newberg', 'state': 'Oregon'},
        {'city': 'Newberry', 'state': 'South Carolina'},
        {'city': 'Newburg', 'state': 'Kentucky'},
        {'city': 'Newburgh', 'state': 'New York'},
        {'city': 'Newburgh', 'state': 'New York'},
        {'city': 'Newbury', 'state': 'Massachusetts'},
        {'city': 'Newburyport', 'state': 'Massachusetts'},
        {'city': 'Newcastle', 'state': 'Washington'},
        {'city': 'Newfane', 'state': 'New York'},
        {'city': 'Newington', 'state': 'Virginia'},
        {'city': 'Newington', 'state': 'Connecticut'},
        {'city': 'Newington', 'state': 'Connecticut'},
        {'city': 'Newman', 'state': 'California'},
        {'city': 'Newmarket', 'state': 'New Hampshire'},
        {'city': 'Newnan', 'state': 'Georgia'},
        {'city': 'Newport', 'state': 'Arkansas'},
        {'city': 'Newport', 'state': 'Kentucky'},
        {'city': 'Newport', 'state': 'New Hampshire'},
        {'city': 'Newport', 'state': 'Tennessee'},
        {'city': 'Newport', 'state': 'Rhode Island'},
        {'city': 'Newport', 'state': 'Oregon'},
        {'city': 'Newport Beach', 'state': 'California'},
        {'city': 'Newport East', 'state': 'Rhode Island'},
        {'city': 'Newport News', 'state': 'Virginia'},
        {'city': 'Newstead', 'state': 'New York'},
        {'city': 'Newton', 'state': 'North Carolina'},
        {'city': 'Newton', 'state': 'Kansas'},
        {'city': 'Newton', 'state': 'Iowa'},
        {'city': 'Newton', 'state': 'Massachusetts'},
        {'city': 'Newton', 'state': 'New Jersey'},
        {'city': 'Newtown', 'state': 'Connecticut'},
        {'city': 'Niagara', 'state': 'New York'},
        {'city': 'Niagara Falls', 'state': 'New York'},
        {'city': 'Niceville', 'state': 'Florida'},
        {'city': 'Nicholasville', 'state': 'Kentucky'},
        {'city': 'Niles', 'state': 'Michigan'},
        {'city': 'Niles', 'state': 'Illinois'},
        {'city': 'Niles', 'state': 'Ohio'},
        {'city': 'Nipomo', 'state': 'California'},
        {'city': 'Niskayuna', 'state': 'New York'},
        {'city': 'Nitro', 'state': 'West Virginia'},
        {'city': 'Nixa', 'state': 'Missouri'},
        {'city': 'Noblesville', 'state': 'Indiana'},
        {'city': 'Nogales', 'state': 'Arizona'},
        {'city': 'Norco', 'state': 'California'},
        {'city': 'Norcross', 'state': 'Georgia'},
        {'city': 'Norfolk', 'state': 'Massachusetts'},
        {'city': 'Norfolk', 'state': 'Nebraska'},
        {'city': 'Norfolk', 'state': 'Virginia'},
        {'city': 'Norland', 'state': 'Florida'},
        {'city': 'Normal', 'state': 'Illinois'},
        {'city': 'Norman', 'state': 'Oklahoma'},
        {'city': 'Normandy Park', 'state': 'Washington'},
        {'city': 'Norridge', 'state': 'Illinois'},
        {'city': 'Norristown', 'state': 'Pennsylvania'},
        {'city': 'North Adams', 'state': 'Massachusetts'},
        {'city': 'North Amherst', 'state': 'Massachusetts'},
        {'city': 'North Amityville', 'state': 'New York'},
        {'city': 'North Andover', 'state': 'Massachusetts'},
        {'city': 'North Andrews Gardens', 'state': 'Florida'},
        {'city': 'North Arlington', 'state': 'New Jersey'},
        {'city': 'North Atlanta', 'state': 'Georgia'},
        {'city': 'North Attleborough', 'state': 'Massachusetts'},
        {'city': 'North Attleborough Center', 'state': 'Massachusetts'},
        {'city': 'North Auburn', 'state': 'California'},
        {'city': 'North Augusta', 'state': 'South Carolina'},
        {'city': 'North Aurora', 'state': 'Illinois'},
        {'city': 'North Babylon', 'state': 'New York'},
        {'city': 'North Bay Shore', 'state': 'New York'},
        {'city': 'North Bay Village', 'state': 'Florida'},
        {'city': 'North Bellmore', 'state': 'New York'},
        {'city': 'North Bellport', 'state': 'New York'},
        {'city': 'North Bend', 'state': 'Oregon'},
        {'city': 'North Bethesda', 'state': 'Maryland'},
        {'city': 'North Braddock', 'state': 'Pennsylvania'},
        {'city': 'North Branch', 'state': 'Minnesota'},
        {'city': 'North Branford', 'state': 'Connecticut'},
        {'city': 'North Brunswick Township', 'state': 'New Jersey'},
        {'city': 'North Caldwell', 'state': 'New Jersey'},
        {'city': 'North Canton', 'state': 'Ohio'},
        {'city': 'North Castle', 'state': 'New York'},
        {'city': 'North Charleston', 'state': 'South Carolina'},
        {'city': 'North Chicago', 'state': 'Illinois'},
        {'city': 'North College Hill', 'state': 'Ohio'},
        {'city': 'North Creek', 'state': 'Washington'},
        {'city': 'North Decatur', 'state': 'Georgia'},
        {'city': 'North Druid Hills', 'state': 'Georgia'},
        {'city': 'North Elba', 'state': 'New York'},
        {'city': 'North Fair Oaks', 'state': 'California'},
        {'city': 'North Fort Myers', 'state': 'Florida'},
        {'city': 'North Greenbush', 'state': 'New York'},
        {'city': 'North Haledon', 'state': 'New Jersey'},
        {'city': 'North Haven', 'state': 'Connecticut'},
        {'city': 'North Haven', 'state': 'Connecticut'},
        {'city': 'North Hempstead', 'state': 'New York'},
        {'city': 'North Highlands', 'state': 'California'},
        {'city': 'North Kensington', 'state': 'Maryland'},
        {'city': 'North Kingstown', 'state': 'Rhode Island'},
        {'city': 'North Las Vegas', 'state': 'Nevada'},
        {'city': 'North Lauderdale', 'state': 'Florida'},
        {'city': 'North Laurel', 'state': 'Maryland'},
        {'city': 'North Lindenhurst', 'state': 'New York'},
        {'city': 'North Little Rock', 'state': 'Arkansas'},
        {'city': 'North Logan', 'state': 'Utah'},
        {'city': 'North Madison', 'state': 'Ohio'},
        {'city': 'North Manchester', 'state': 'Indiana'},
        {'city': 'North Mankato', 'state': 'Minnesota'},
        {'city': 'North Marysville', 'state': 'Washington'},
        {'city': 'North Massapequa', 'state': 'New York'},
        {'city': 'North Merrick', 'state': 'New York'},
        {'city': 'North Miami', 'state': 'Florida'},
        {'city': 'North Miami Beach', 'state': 'Florida'},
        {'city': 'North Myrtle Beach', 'state': 'South Carolina'},
        {'city': 'North New Hyde Park', 'state': 'New York'},
        {'city': 'North Ogden', 'state': 'Utah'},
        {'city': 'North Olmsted', 'state': 'Ohio'},
        {'city': 'North Palm Beach', 'state': 'Florida'},
        {'city': 'North Patchogue', 'state': 'New York'},
        {'city': 'North Plainfield', 'state': 'New Jersey'},
        {'city': 'North Platte', 'state': 'Nebraska'},
        {'city': 'North Port', 'state': 'Florida'},
        {'city': 'North Potomac', 'state': 'Maryland'},
        {'city': 'North Providence', 'state': 'Rhode Island'},
        {'city': 'North Providence', 'state': 'Rhode Island'},
        {'city': 'North Reading', 'state': 'Massachusetts'},
        {'city': 'North Richland Hills', 'state': 'Texas'},
        {'city': 'North Ridgeville', 'state': 'Ohio'},
        {'city': 'North Riverside', 'state': 'Illinois'},
        {'city': 'North Royalton', 'state': 'Ohio'},
        {'city': 'North Salt Lake', 'state': 'Utah'},
        {'city': 'North Sarasota', 'state': 'Florida'},
        {'city': 'North Smithfield', 'state': 'Rhode Island'},
        {'city': 'North Springfield', 'state': 'Virginia'},
        {'city': 'North St. Paul', 'state': 'Minnesota'},
        {'city': 'North Star', 'state': 'Delaware'},
        {'city': 'North Syracuse', 'state': 'New York'},
        {'city': 'North Tonawanda', 'state': 'New York'},
        {'city': 'North Valley', 'state': 'New Mexico'},
        {'city': 'North Valley Stream', 'state': 'New York'},
        {'city': 'North Vernon', 'state': 'Indiana'},
        {'city': 'North Versailles', 'state': 'Pennsylvania'},
        {'city': 'North Wantagh', 'state': 'New York'},
        {'city': 'Northampton', 'state': 'Pennsylvania'},
        {'city': 'Northampton', 'state': 'Massachusetts'},
        {'city': 'Northborough', 'state': 'Massachusetts'},
        {'city': 'Northborough', 'state': 'Massachusetts'},
        {'city': 'Northbridge', 'state': 'Massachusetts'},
        {'city': 'Northbrook', 'state': 'Illinois'},
        {'city': 'Northbrook', 'state': 'Ohio'},
        {'city': 'Northfield', 'state': 'Minnesota'},
        {'city': 'Northfield', 'state': 'New Jersey'},
        {'city': 'Northgate', 'state': 'Ohio'},
        {'city': 'Northglenn', 'state': 'Colorado'},
        {'city': 'Northlake', 'state': 'Illinois'},
        {'city': 'Northport', 'state': 'Alabama'},
        {'city': 'Northport', 'state': 'New York'},
        {'city': 'Northridge', 'state': 'Ohio'},
        {'city': 'Northridge', 'state': 'Ohio'},
        {'city': 'Northview', 'state': 'Michigan'},
        {'city': 'Northville', 'state': 'Michigan'},
        {'city': 'Northwest Harborcreek', 'state': 'Pennsylvania'},
        {'city': 'Norton', 'state': 'Ohio'},
        {'city': 'Norton', 'state': 'Massachusetts'},
        {'city': 'Norton Shores', 'state': 'Michigan'},
        {'city': 'Norwalk', 'state': 'Iowa'},
        {'city': 'Norwalk', 'state': 'Connecticut'},
        {'city': 'Norwalk', 'state': 'California'},
        {'city': 'Norwalk', 'state': 'Connecticut'},
        {'city': 'Norwalk', 'state': 'Ohio'},
        {'city': 'Norway', 'state': 'Wisconsin'},
        {'city': 'Norwell', 'state': 'Massachusetts'},
        {'city': 'Norwich', 'state': 'New York'},
        {'city': 'Norwich', 'state': 'Connecticut'},
        {'city': 'Norwich', 'state': 'Connecticut'},
        {'city': 'Norwood', 'state': 'Massachusetts'},
        {'city': 'Norwood', 'state': 'Massachusetts'},
        {'city': 'Norwood', 'state': 'Ohio'},
        {'city': 'Novato', 'state': 'California'},
        {'city': 'Novi', 'state': 'Michigan'},
        {'city': 'Nutley', 'state': 'New Jersey'},
        {'city': 'Nyack', 'state': 'New York'},
        {'city': 'O’Fallon', 'state': 'Illinois'},
        {'city': 'O’Fallon', 'state': 'Missouri'},
        {'city': 'O’Hara Township', 'state': 'Pennsylvania'},
        {'city': 'Oak Brook', 'state': 'Illinois'},
        {'city': 'Oak Creek', 'state': 'Wisconsin'},
        {'city': 'Oak Forest', 'state': 'Illinois'},
        {'city': 'Oak Grove', 'state': 'Kentucky'},
        {'city': 'Oak Grove', 'state': 'South Carolina'},
        {'city': 'Oak Grove', 'state': 'Oregon'},
        {'city': 'Oak Grove', 'state': 'Minnesota'},
        {'city': 'Oak Harbor', 'state': 'Washington'},
        {'city': 'Oak Hill', 'state': 'West Virginia'},
        {'city': 'Oak Hills', 'state': 'Oregon'},
        {'city': 'Oak Hills Place', 'state': 'Louisiana'},
        {'city': 'Oak Island', 'state': 'North Carolina'},
        {'city': 'Oak Lawn', 'state': 'Illinois'},
        {'city': 'Oak Park', 'state': 'Illinois'},
        {'city': 'Oak Park', 'state': 'Michigan'},
        {'city': 'Oak Ridge', 'state': 'Tennessee'},
        {'city': 'Oak Ridge', 'state': 'Florida'},
        {'city': 'Oakbrook', 'state': 'Kentucky'},
        {'city': 'Oakdale', 'state': 'Louisiana'},
        {'city': 'Oakdale', 'state': 'California'},
        {'city': 'Oakdale', 'state': 'Minnesota'},
        {'city': 'Oakdale', 'state': 'New York'},
        {'city': 'Oakland', 'state': 'New Jersey'},
        {'city': 'Oakland', 'state': 'California'},
        {'city': 'Oakland Park', 'state': 'Florida'},
        {'city': 'Oakley', 'state': 'California'},
        {'city': 'Oakmont', 'state': 'Pennsylvania'},
        {'city': 'Oakton', 'state': 'Virginia'},
        {'city': 'Oakville', 'state': 'Missouri'},
        {'city': 'Oakville', 'state': 'Connecticut'},
        {'city': 'Oakwood', 'state': 'Ohio'},
        {'city': 'Oatfield', 'state': 'Oregon'},
        {'city': 'Oberlin', 'state': 'Ohio'},
        {'city': 'Ocala', 'state': 'Florida'},
        {'city': 'Ocean Acres', 'state': 'New Jersey'},
        {'city': 'Ocean City', 'state': 'New Jersey'},
        {'city': 'Ocean City', 'state': 'Maryland'},
        {'city': 'Ocean Pines', 'state': 'Maryland'},
        {'city': 'Ocean Springs', 'state': 'Mississippi'},
        {'city': 'Oceano', 'state': 'California'},
        {'city': 'Oceanside', 'state': 'California'},
        {'city': 'Oceanside', 'state': 'New York'},
        {'city': 'Ocoee', 'state': 'Florida'},
        {'city': 'Oconomowoc', 'state': 'Wisconsin'},
        {'city': 'Oconomowoc', 'state': 'Wisconsin'},
        {'city': 'Odenton', 'state': 'Maryland'},
        {'city': 'Odessa', 'state': 'Texas'},
        {'city': 'Oelwein', 'state': 'Iowa'},
        {'city': 'Offutt AFB', 'state': 'Nebraska'},
        {'city': 'Ogden', 'state': 'New York'},
        {'city': 'Ogden', 'state': 'Utah'},
        {'city': 'Ogdensburg', 'state': 'New York'},
        {'city': 'Oil City', 'state': 'Pennsylvania'},
        {'city': 'Oildale', 'state': 'California'},
        {'city': 'Ojai', 'state': 'California'},
        {'city': 'Ojus', 'state': 'Florida'},
        {'city': 'Okemos', 'state': 'Michigan'},
        {'city': 'Oklahoma City', 'state': 'Oklahoma'},
        {'city': 'Okmulgee', 'state': 'Oklahoma'},
        {'city': 'Okolona', 'state': 'Kentucky'},
        {'city': 'Olathe', 'state': 'Kansas'},
        {'city': 'Old Bridge', 'state': 'New Jersey'},
        {'city': 'Old Forge', 'state': 'Pennsylvania'},
        {'city': 'Old Lyme', 'state': 'Connecticut'},
        {'city': 'Old Orchard Beach', 'state': 'Maine'},
        {'city': 'Old Orchard Beach', 'state': 'Maine'},
        {'city': 'Old Saybrook', 'state': 'Connecticut'},
        {'city': 'Old Town', 'state': 'Maine'},
        {'city': 'Oldsmar', 'state': 'Florida'},
        {'city': 'Olean', 'state': 'New York'},
        {'city': 'Olive Branch', 'state': 'Mississippi'},
        {'city': 'Olivehurst', 'state': 'California'},
        {'city': 'Olivette', 'state': 'Missouri'},
        {'city': 'Olmsted Falls', 'state': 'Ohio'},
        {'city': 'Olney', 'state': 'Maryland'},
        {'city': 'Olney', 'state': 'Illinois'},
        {'city': 'Olympia', 'state': 'Washington'},
        {'city': 'Olympia Heights', 'state': 'Florida'},
        {'city': 'Omaha', 'state': 'Nebraska'},
        {'city': 'Onalaska', 'state': 'Wisconsin'},
        {'city': 'Oneida', 'state': 'New York'},
        {'city': 'Oneonta', 'state': 'New York'},
        {'city': 'Onondaga', 'state': 'New York'},
        {'city': 'Ontario', 'state': 'New York'},
        {'city': 'Ontario', 'state': 'Oregon'},
        {'city': 'Ontario', 'state': 'California'},
        {'city': 'Opa-locka', 'state': 'Florida'},
        {'city': 'Opa-locka North', 'state': 'Florida'},
        {'city': 'Opal Cliffs', 'state': 'California'},
        {'city': 'Opelika', 'state': 'Alabama'},
        {'city': 'Opelousas', 'state': 'Louisiana'},
        {'city': 'Opp', 'state': 'Alabama'},
        {'city': 'Opportunity', 'state': 'Washington'},
        {'city': 'Oquirrh', 'state': 'Utah'},
        {'city': 'Oradell', 'state': 'New Jersey'},
        {'city': 'Orange', 'state': 'New Jersey'},
        {'city': 'Orange', 'state': 'Massachusetts'},
        {'city': 'Orange', 'state': 'Texas'},
        {'city': 'Orange', 'state': 'Connecticut'},
        {'city': 'Orange', 'state': 'California'},
        {'city': 'Orange', 'state': 'Connecticut'},
        {'city': 'Orange City', 'state': 'Florida'},
        {'city': 'Orange Cove', 'state': 'California'},
        {'city': 'Orange Lake', 'state': 'New York'},
        {'city': 'Orange Park', 'state': 'Florida'},
        {'city': 'Orangeburg', 'state': 'South Carolina'},
        {'city': 'Orangetown', 'state': 'New York'},
        {'city': 'Orangevale', 'state': 'California'},
        {'city': 'Orchard Mesa', 'state': 'Colorado'},
        {'city': 'Orchard Park', 'state': 'New York'},
        {'city': 'Orchards', 'state': 'Washington'},
        {'city': 'Orcutt', 'state': 'California'},
        {'city': 'Oregon', 'state': 'Ohio'},
        {'city': 'Oregon', 'state': 'Wisconsin'},
        {'city': 'Oregon City', 'state': 'Oregon'},
        {'city': 'Orem', 'state': 'Utah'},
        {'city': 'Orinda', 'state': 'California'},
        {'city': 'Orland', 'state': 'California'},
        {'city': 'Orland Hills', 'state': 'Illinois'},
        {'city': 'Orland Park', 'state': 'Illinois'},
        {'city': 'Orlando', 'state': 'Florida'},
        {'city': 'Orleans', 'state': 'Massachusetts'},
        {'city': 'Orlovista', 'state': 'Florida'},
        {'city': 'Ormond Beach', 'state': 'Florida'},
        {'city': 'Ormond-By-The-Sea', 'state': 'Florida'},
        {'city': 'Oro Valley', 'state': 'Arizona'},
        {'city': 'Orono', 'state': 'Maine'},
        {'city': 'Orono', 'state': 'Maine'},
        {'city': 'Orono', 'state': 'Minnesota'},
        {'city': 'Orosi', 'state': 'California'},
        {'city': 'Oroville', 'state': 'California'},
        {'city': 'Oroville East', 'state': 'California'},
        {'city': 'Orrville', 'state': 'Ohio'},
        {'city': 'Osceola', 'state': 'Arkansas'},
        {'city': 'Oshkosh', 'state': 'Wisconsin'},
        {'city': 'Oskaloosa', 'state': 'Iowa'},
        {'city': 'Ossining', 'state': 'New York'},
        {'city': 'Ossining', 'state': 'New York'},
        {'city': 'Oswego', 'state': 'New York'},
        {'city': 'Oswego', 'state': 'New York'},
        {'city': 'Oswego', 'state': 'Illinois'},
        {'city': 'Otis Orchards-East Farms', 'state': 'Washington'},
        {'city': 'Otsego', 'state': 'Minnesota'},
        {'city': 'Ottawa', 'state': 'Illinois'},
        {'city': 'Ottawa', 'state': 'Kansas'},
        {'city': 'Ottumwa', 'state': 'Iowa'},
        {'city': 'Overland', 'state': 'Missouri'},
        {'city': 'Overland Park', 'state': 'Kansas'},
        {'city': 'Overlea', 'state': 'Maryland'},
        {'city': 'Oviedo', 'state': 'Florida'},
        {'city': 'Owasso', 'state': 'Oklahoma'},
        {'city': 'Owatonna', 'state': 'Minnesota'},
        {'city': 'Owego', 'state': 'New York'},
        {'city': 'Owensboro', 'state': 'Kentucky'},
        {'city': 'Owings Mills', 'state': 'Maryland'},
        {'city': 'Owosso', 'state': 'Michigan'},
        {'city': 'Oxford', 'state': 'Massachusetts'},
        {'city': 'Oxford', 'state': 'Mississippi'},
        {'city': 'Oxford', 'state': 'North Carolina'},
        {'city': 'Oxford', 'state': 'Ohio'},
        {'city': 'Oxford', 'state': 'Connecticut'},
        {'city': 'Oxford', 'state': 'Alabama'},
        {'city': 'Oxnard', 'state': 'California'},
        {'city': 'Oxon Hill-Glassmanor', 'state': 'Maryland'},
        {'city': 'Oyster Bay', 'state': 'New York'},
        {'city': 'Oyster Bay', 'state': 'New York'},
        {'city': 'Ozark', 'state': 'Missouri'},
        {'city': 'Ozark', 'state': 'Alabama'},
        {'city': 'Pace', 'state': 'Florida'},
        {'city': 'Pacific Grove', 'state': 'California'},
        {'city': 'Pacifica', 'state': 'California'},
        {'city': 'Paducah', 'state': 'Kentucky'},
        {'city': 'Page', 'state': 'Arizona'},
        {'city': 'Pahrump', 'state': 'Nevada'},
        {'city': 'Paine Field-Lake Stickney', 'state': 'Washington'},
        {'city': 'Painesville', 'state': 'Ohio'},
        {'city': 'Palatine', 'state': 'Illinois'},
        {'city': 'Palatka', 'state': 'Florida'},
        {'city': 'Palestine', 'state': 'Texas'},
        {'city': 'Palisades Park', 'state': 'New Jersey'},
        {'city': 'Palm Bay', 'state': 'Florida'},
        {'city': 'Palm Beach', 'state': 'Florida'},
        {'city': 'Palm Beach Gardens', 'state': 'Florida'},
        {'city': 'Palm City', 'state': 'Florida'},
        {'city': 'Palm Coast', 'state': 'Florida'},
        {'city': 'Palm Desert', 'state': 'California'},
        {'city': 'Palm Harbor', 'state': 'Florida'},
        {'city': 'Palm River-Clair Mel', 'state': 'Florida'},
        {'city': 'Palm Springs', 'state': 'Florida'},
        {'city': 'Palm Springs', 'state': 'California'},
        {'city': 'Palm Valley', 'state': 'Florida'},
        {'city': 'Palmdale', 'state': 'California'},
        {'city': 'Palmer', 'state': 'Massachusetts'},
        {'city': 'Palmetto', 'state': 'Florida'},
        {'city': 'Palmetto Estates', 'state': 'Florida'},
        {'city': 'Palmview South', 'state': 'Texas'},
        {'city': 'Palmyra', 'state': 'Pennsylvania'},
        {'city': 'Palmyra', 'state': 'New Jersey'},
        {'city': 'Palmyra', 'state': 'New York'},
        {'city': 'Palo Alto', 'state': 'California'},
        {'city': 'Palos Heights', 'state': 'Illinois'},
        {'city': 'Palos Hills', 'state': 'Illinois'},
        {'city': 'Palos Verdes Estates', 'state': 'California'},
        {'city': 'Pampa', 'state': 'Texas'},
        {'city': 'Panama City', 'state': 'Florida'},
        {'city': 'Panama City Beach', 'state': 'Florida'},
        {'city': 'Panthersville', 'state': 'Georgia'},
        {'city': 'Papillion', 'state': 'Nebraska'},
        {'city': 'Paradise', 'state': 'Nevada'},
        {'city': 'Paradise', 'state': 'California'},
        {'city': 'Paradise Valley', 'state': 'Arizona'},
        {'city': 'Paragould', 'state': 'Arkansas'},
        {'city': 'Paramount', 'state': 'California'},
        {'city': 'Paramus', 'state': 'New Jersey'},
        {'city': 'Paris', 'state': 'Texas'},
        {'city': 'Paris', 'state': 'Tennessee'},
        {'city': 'Paris', 'state': 'Illinois'},
        {'city': 'Paris', 'state': 'Kentucky'},
        {'city': 'Park City', 'state': 'Illinois'},
        {'city': 'Park City', 'state': 'Utah'},
        {'city': 'Park Forest', 'state': 'Illinois'},
        {'city': 'Park Forest Village', 'state': 'Pennsylvania'},
        {'city': 'Park Hills', 'state': 'Missouri'},
        {'city': 'Park Ridge', 'state': 'New Jersey'},
        {'city': 'Park Ridge', 'state': 'Illinois'},
        {'city': 'Parker', 'state': 'Colorado'},
        {'city': 'Parker', 'state': 'South Carolina'},
        {'city': 'Parkersburg', 'state': 'West Virginia'},
        {'city': 'Parkland', 'state': 'Washington'},
        {'city': 'Parkland', 'state': 'Florida'},
        {'city': 'Parkville', 'state': 'Maryland'},
        {'city': 'Parkville', 'state': 'Pennsylvania'},
        {'city': 'Parkway-South Sacramento', 'state': 'California'},
        {'city': 'Parkwood', 'state': 'Washington'},
        {'city': 'Parlier', 'state': 'California'},
        {'city': 'Parma', 'state': 'New York'},
        {'city': 'Parma', 'state': 'Ohio'},
        {'city': 'Parma Heights', 'state': 'Ohio'},
        {'city': 'Parole', 'state': 'Maryland'},
        {'city': 'Parsons', 'state': 'Kansas'},
        {'city': 'Pasadena', 'state': 'Maryland'},
        {'city': 'Pasadena', 'state': 'California'},
        {'city': 'Pasadena', 'state': 'Texas'},
        {'city': 'Pascagoula', 'state': 'Mississippi'},
        {'city': 'Pasco', 'state': 'Washington'},
        {'city': 'Pass Christian', 'state': 'Mississippi'},
        {'city': 'Passaic', 'state': 'New Jersey'},
        {'city': 'Pataskala', 'state': 'Ohio'},
        {'city': 'Patchogue', 'state': 'New York'},
        {'city': 'Paterson', 'state': 'New Jersey'},
        {'city': 'Patterson', 'state': 'New York'},
        {'city': 'Patterson', 'state': 'California'},
        {'city': 'Pauls Valley', 'state': 'Oklahoma'},
        {'city': 'Paulsboro', 'state': 'New Jersey'},
        {'city': 'Pawling', 'state': 'New York'},
        {'city': 'Pawtucket', 'state': 'Rhode Island'},
        {'city': 'Payette', 'state': 'Idaho'},
        {'city': 'Payson', 'state': 'Arizona'},
        {'city': 'Payson', 'state': 'Utah'},
        {'city': 'Pea Ridge', 'state': 'West Virginia'},
        {'city': 'Peabody', 'state': 'Massachusetts'},
        {'city': 'Peachtree City', 'state': 'Georgia'},
        {'city': 'Pearl', 'state': 'Mississippi'},
        {'city': 'Pearl City', 'state': 'Hawaii'},
        {'city': 'Pearl River', 'state': 'New York'},
        {'city': 'Pearland', 'state': 'Texas'},
        {'city': 'Pearsall', 'state': 'Texas'},
        {'city': 'Pecan Grove', 'state': 'Texas'},
        {'city': 'Pecos', 'state': 'Texas'},
        {'city': 'Pedley', 'state': 'California'},
        {'city': 'Peekskill', 'state': 'New York'},
        {'city': 'Pekin', 'state': 'Illinois'},
        {'city': 'Pelham', 'state': 'Alabama'},
        {'city': 'Pelham', 'state': 'New York'},
        {'city': 'Pelham', 'state': 'New York'},
        {'city': 'Pelham', 'state': 'New Hampshire'},
        {'city': 'Pell City', 'state': 'Alabama'},
        {'city': 'Pella', 'state': 'Iowa'},
        {'city': 'Pembroke', 'state': 'Massachusetts'},
        {'city': 'Pembroke', 'state': 'New Hampshire'},
        {'city': 'Pembroke Park', 'state': 'Florida'},
        {'city': 'Pembroke Pines', 'state': 'Florida'},
        {'city': 'Pendleton', 'state': 'New York'},
        {'city': 'Pendleton', 'state': 'Oregon'},
        {'city': 'Penfield', 'state': 'New York'},
        {'city': 'Penn Hills', 'state': 'Pennsylvania'},
        {'city': 'Pennsauken', 'state': 'New Jersey'},
        {'city': 'Pennsville', 'state': 'New Jersey'},
        {'city': 'Pensacola', 'state': 'Florida'},
        {'city': 'Peoria', 'state': 'Arizona'},
        {'city': 'Peoria', 'state': 'Illinois'},
        {'city': 'Peoria Heights', 'state': 'Illinois'},
        {'city': 'Pepper Pike', 'state': 'Ohio'},
        {'city': 'Pepperell', 'state': 'Massachusetts'},
        {'city': 'Perinton', 'state': 'New York'},
        {'city': 'Perkasie', 'state': 'Pennsylvania'},
        {'city': 'Perris', 'state': 'California'},
        {'city': 'Perry', 'state': 'Florida'},
        {'city': 'Perry', 'state': 'Iowa'},
        {'city': 'Perry', 'state': 'Georgia'},
        {'city': 'Perry', 'state': 'New York'},
        {'city': 'Perry Hall', 'state': 'Maryland'},
        {'city': 'Perry Heights', 'state': 'Ohio'},
        {'city': 'Perrysburg', 'state': 'Ohio'},
        {'city': 'Perryton', 'state': 'Texas'},
        {'city': 'Perryville', 'state': 'Missouri'},
        {'city': 'Perth Amboy', 'state': 'New Jersey'},
        {'city': 'Peru', 'state': 'New York'},
        {'city': 'Peru', 'state': 'Illinois'},
        {'city': 'Peru', 'state': 'Indiana'},
        {'city': 'Petal', 'state': 'Mississippi'},
        {'city': 'Petaluma', 'state': 'California'},
        {'city': 'Petersburg', 'state': 'Virginia'},
        {'city': 'Petoskey', 'state': 'Michigan'},
        {'city': 'Pewaukee', 'state': 'Wisconsin'},
        {'city': 'Pewaukee', 'state': 'Wisconsin'},
        {'city': 'Pflugerville', 'state': 'Texas'},
        {'city': 'Pharr', 'state': 'Texas'},
        {'city': 'Phelps', 'state': 'New York'},
        {'city': 'Phenix City', 'state': 'Alabama'},
        {'city': 'Philadelphia', 'state': 'Mississippi'},
        {'city': 'Philadelphia', 'state': 'Pennsylvania'},
        {'city': 'Philipstown', 'state': 'New York'},
        {'city': 'Phillipsburg', 'state': 'New Jersey'},
        {'city': 'Phoenix', 'state': 'Arizona'},
        {'city': 'Phoenixville', 'state': 'Pennsylvania'},
        {'city': 'Picayune', 'state': 'Mississippi'},
        {'city': 'Pickerington', 'state': 'Ohio'},
        {'city': 'Picnic Point-North Lynnwood', 'state': 'Washington'},
        {'city': 'Pico Rivera', 'state': 'California'},
        {'city': 'Picture Rocks', 'state': 'Arizona'},
        {'city': 'Piedmont', 'state': 'California'},
        {'city': 'Pierre', 'state': 'South Dakota'},
        {'city': 'Pike Creek', 'state': 'Delaware'},
        {'city': 'Pikesville', 'state': 'Maryland'},
        {'city': 'Pikeville', 'state': 'Kentucky'},
        {'city': 'Pimmit Hills', 'state': 'Virginia'},
        {'city': 'Pine Bluff', 'state': 'Arkansas'},
        {'city': 'Pine Castle', 'state': 'Florida'},
        {'city': 'Pine Hill', 'state': 'New Jersey'},
        {'city': 'Pine Hills', 'state': 'Florida'},
        {'city': 'Pinecrest', 'state': 'Florida'},
        {'city': 'Pinehurst', 'state': 'Massachusetts'},
        {'city': 'Pinehurst', 'state': 'North Carolina'},
        {'city': 'Pinellas Park', 'state': 'Florida'},
        {'city': 'Pineville', 'state': 'Louisiana'},
        {'city': 'Pinewood', 'state': 'Florida'},
        {'city': 'Piney Green', 'state': 'North Carolina'},
        {'city': 'Pinole', 'state': 'California'},
        {'city': 'Piqua', 'state': 'Ohio'},
        {'city': 'Pismo Beach', 'state': 'California'},
        {'city': 'Pitman', 'state': 'New Jersey'},
        {'city': 'Pittsburg', 'state': 'California'},
        {'city': 'Pittsburg', 'state': 'Kansas'},
        {'city': 'Pittsburgh', 'state': 'Pennsylvania'},
        {'city': 'Pittsfield', 'state': 'Massachusetts'},
        {'city': 'Pittsford', 'state': 'New York'},
        {'city': 'Pittston', 'state': 'Pennsylvania'},
        {'city': 'Placentia', 'state': 'California'},
        {'city': 'Placerville', 'state': 'California'},
        {'city': 'Plainedge', 'state': 'New York'},
        {'city': 'Plainfield', 'state': 'New Jersey'},
        {'city': 'Plainfield', 'state': 'Connecticut'},
        {'city': 'Plainfield', 'state': 'Indiana'},
        {'city': 'Plainfield', 'state': 'Illinois'},
        {'city': 'Plainview', 'state': 'New York'},
        {'city': 'Plainview', 'state': 'Texas'},
        {'city': 'Plainville', 'state': 'Massachusetts'},
        {'city': 'Plainville', 'state': 'Connecticut'},
        {'city': 'Plaistow', 'state': 'New Hampshire'},
        {'city': 'Plano', 'state': 'Texas'},
        {'city': 'Plant City', 'state': 'Florida'},
        {'city': 'Plantation', 'state': 'Florida'},
        {'city': 'Plaquemine', 'state': 'Louisiana'},
        {'city': 'Plattekill', 'state': 'New York'},
        {'city': 'Platteville', 'state': 'Wisconsin'},
        {'city': 'Plattsburgh', 'state': 'New York'},
        {'city': 'Plattsburgh', 'state': 'New York'},
        {'city': 'Plattsmouth', 'state': 'Nebraska'},
        {'city': 'Pleasant Grove', 'state': 'Utah'},
        {'city': 'Pleasant Grove', 'state': 'Alabama'},
        {'city': 'Pleasant Hill', 'state': 'California'},
        {'city': 'Pleasant Hills', 'state': 'Pennsylvania'},
        {'city': 'Pleasant Prairie', 'state': 'Wisconsin'},
        {'city': 'Pleasant Valley', 'state': 'New York'},
        {'city': 'Pleasanton', 'state': 'Texas'},
        {'city': 'Pleasanton', 'state': 'California'},
        {'city': 'Pleasantville', 'state': 'New York'},
        {'city': 'Pleasantville', 'state': 'New Jersey'},
        {'city': 'Pleasure Ridge Park', 'state': 'Kentucky'},
        {'city': 'Plover', 'state': 'Wisconsin'},
        {'city': 'Plum', 'state': 'Pennsylvania'},
        {'city': 'Plymouth', 'state': 'Pennsylvania'},
        {'city': 'Plymouth', 'state': 'Massachusetts'},
        {'city': 'Plymouth', 'state': 'Michigan'},
        {'city': 'Plymouth', 'state': 'Massachusetts'},
        {'city': 'Plymouth', 'state': 'Minnesota'},
        {'city': 'Plymouth', 'state': 'Indiana'},
        {'city': 'Plymouth', 'state': 'Connecticut'},
        {'city': 'Plymouth', 'state': 'Wisconsin'},
        {'city': 'Plymouth Township', 'state': 'Michigan'},
        {'city': 'Pocahontas', 'state': 'Arkansas'},
        {'city': 'Pocatello', 'state': 'Idaho'},
        {'city': 'Poinciana', 'state': 'Florida'},
        {'city': 'Point Pleasant', 'state': 'New Jersey'},
        {'city': 'Pomfret', 'state': 'New York'},
        {'city': 'Pomona', 'state': 'California'},
        {'city': 'Pompano Beach', 'state': 'Florida'},
        {'city': 'Pompano Beach Highlands', 'state': 'Florida'},
        {'city': 'Pompey', 'state': 'New York'},
        {'city': 'Pompton Lakes', 'state': 'New Jersey'},
        {'city': 'Ponca City', 'state': 'Oklahoma'},
        {'city': 'Pontiac', 'state': 'Illinois'},
        {'city': 'Pontiac', 'state': 'Michigan'},
        {'city': 'Pooler', 'state': 'Georgia'},
        {'city': 'Poplar Bluff', 'state': 'Missouri'},
        {'city': 'Poquoson', 'state': 'Virginia'},
        {'city': 'Port Angeles', 'state': 'Washington'},
        {'city': 'Port Arthur', 'state': 'Texas'},
        {'city': 'Port Charlotte', 'state': 'Florida'},
        {'city': 'Port Chester', 'state': 'New York'},
        {'city': 'Port Clinton', 'state': 'Ohio'},
        {'city': 'Port Hueneme', 'state': 'California'},
        {'city': 'Port Huron', 'state': 'Michigan'},
        {'city': 'Port Jefferson', 'state': 'New York'},
        {'city': 'Port Jefferson Station', 'state': 'New York'},
        {'city': 'Port Jervis', 'state': 'New York'},
        {'city': 'Port Lavaca', 'state': 'Texas'},
        {'city': 'Port Neches', 'state': 'Texas'},
        {'city': 'Port Orange', 'state': 'Florida'},
        {'city': 'Port Orchard', 'state': 'Washington'},
        {'city': 'Port Salerno', 'state': 'Florida'},
        {'city': 'Port St. John', 'state': 'Florida'},
        {'city': 'Port St. Lucie', 'state': 'Florida'},
        {'city': 'Port Townsend', 'state': 'Washington'},
        {'city': 'Port Washington', 'state': 'New York'},
        {'city': 'Port Washington', 'state': 'Wisconsin'},
        {'city': 'Portage', 'state': 'Wisconsin'},
        {'city': 'Portage', 'state': 'Indiana'},
        {'city': 'Portage', 'state': 'Michigan'},
        {'city': 'Portage Lakes', 'state': 'Ohio'},
        {'city': 'Portales', 'state': 'New Mexico'},
        {'city': 'Porter', 'state': 'New York'},
        {'city': 'Porterville', 'state': 'California'},
        {'city': 'Portland', 'state': 'Connecticut'},
        {'city': 'Portland', 'state': 'Maine'},
        {'city': 'Portland', 'state': 'Indiana'},
        {'city': 'Portland', 'state': 'Tennessee'},
        {'city': 'Portland', 'state': 'Texas'},
        {'city': 'Portland', 'state': 'Oregon'},
        {'city': 'Portola Hills', 'state': 'California'},
        {'city': 'Portsmouth', 'state': 'Rhode Island'},
        {'city': 'Portsmouth', 'state': 'Ohio'},
        {'city': 'Portsmouth', 'state': 'New Hampshire'},
        {'city': 'Portsmouth', 'state': 'Virginia'},
        {'city': 'Post Falls', 'state': 'Idaho'},
        {'city': 'Poteau', 'state': 'Oklahoma'},
        {'city': 'Potomac', 'state': 'Maryland'},
        {'city': 'Potsdam', 'state': 'New York'},
        {'city': 'Potsdam', 'state': 'New York'},
        {'city': 'Pottstown', 'state': 'Pennsylvania'},
        {'city': 'Pottsville', 'state': 'Pennsylvania'},
        {'city': 'Poughkeepsie', 'state': 'New York'},
        {'city': 'Poughkeepsie', 'state': 'New York'},
        {'city': 'Poulsbo', 'state': 'Washington'},
        {'city': 'Poway', 'state': 'California'},
        {'city': 'Powder Springs', 'state': 'Georgia'},
        {'city': 'Powell', 'state': 'Ohio'},
        {'city': 'Prairie du Chien', 'state': 'Wisconsin'},
        {'city': 'Prairie Ridge', 'state': 'Washington'},
        {'city': 'Prairie Village', 'state': 'Kansas'},
        {'city': 'Pratt', 'state': 'Kansas'},
        {'city': 'Prattville', 'state': 'Alabama'},
        {'city': 'Prescott', 'state': 'Arizona'},
        {'city': 'Prescott Valley', 'state': 'Arizona'},
        {'city': 'Presque Isle', 'state': 'Maine'},
        {'city': 'Price', 'state': 'Utah'},
        {'city': 'Prichard', 'state': 'Alabama'},
        {'city': 'Prien', 'state': 'Louisiana'},
        {'city': 'Princeton', 'state': 'Kentucky'},
        {'city': 'Princeton', 'state': 'Florida'},
        {'city': 'Princeton', 'state': 'Indiana'},
        {'city': 'Princeton', 'state': 'Illinois'},
        {'city': 'Princeton', 'state': 'New Jersey'},
        {'city': 'Princeton', 'state': 'West Virginia'},
        {'city': 'Princeton Meadows', 'state': 'New Jersey'},
        {'city': 'Prineville', 'state': 'Oregon'},
        {'city': 'Prior Lake', 'state': 'Minnesota'},
        {'city': 'Progress', 'state': 'Pennsylvania'},
        {'city': 'Prospect', 'state': 'Connecticut'},
        {'city': 'Prospect Heights', 'state': 'Illinois'},
        {'city': 'Prospect Park', 'state': 'Pennsylvania'},
        {'city': 'Providence', 'state': 'Rhode Island'},
        {'city': 'Provo', 'state': 'Utah'},
        {'city': 'Prunedale', 'state': 'California'},
        {'city': 'Pryor Creek', 'state': 'Oklahoma'},
        {'city': 'Pueblo', 'state': 'Colorado'},
        {'city': 'Pueblo West', 'state': 'Colorado'},
        {'city': 'Pukalani', 'state': 'Hawaii'},
        {'city': 'Pulaski', 'state': 'Tennessee'},
        {'city': 'Pulaski', 'state': 'Virginia'},
        {'city': 'Pullman', 'state': 'Washington'},
        {'city': 'Punta Gorda', 'state': 'Florida'},
        {'city': 'Punxsutawney', 'state': 'Pennsylvania'},
        {'city': 'Putnam', 'state': 'Connecticut'},
        {'city': 'Putnam District', 'state': 'Connecticut'},
        {'city': 'Putnam Valley', 'state': 'New York'},
        {'city': 'Puyallup', 'state': 'Washington'},
        {'city': 'Quakertown', 'state': 'Pennsylvania'},
        {'city': 'Quantico Station', 'state': 'Virginia'},
        {'city': 'Quartz Hill', 'state': 'California'},
        {'city': 'Queensbury', 'state': 'New York'},
        {'city': 'Quincy', 'state': 'Florida'},
        {'city': 'Quincy', 'state': 'Illinois'},
        {'city': 'Quincy', 'state': 'Massachusetts'},
        {'city': 'Raceland', 'state': 'Louisiana'},
        {'city': 'Racine', 'state': 'Wisconsin'},
        {'city': 'Radcliff', 'state': 'Kentucky'},
        {'city': 'Radford', 'state': 'Virginia'},
        {'city': 'Radnor Township', 'state': 'Pennsylvania'},
        {'city': 'Rahway', 'state': 'New Jersey'},
        {'city': 'Rainbow City', 'state': 'Alabama'},
        {'city': 'Raleigh', 'state': 'North Carolina'},
        {'city': 'Ralston', 'state': 'Nebraska'},
        {'city': 'Ramapo', 'state': 'New York'},
        {'city': 'Ramblewood', 'state': 'New Jersey'},
        {'city': 'Ramona', 'state': 'California'},
        {'city': 'Ramsey', 'state': 'New Jersey'},
        {'city': 'Ramsey', 'state': 'Minnesota'},
        {'city': 'Rancho Cordova', 'state': 'California'},
        {'city': 'Rancho Cucamonga', 'state': 'California'},
        {'city': 'Rancho Mirage', 'state': 'California'},
        {'city': 'Rancho Palos Verdes', 'state': 'California'},
        {'city': 'Rancho San Diego', 'state': 'California'},
        {'city': 'Rancho Santa Margarita', 'state': 'California'},
        {'city': 'Randallstown', 'state': 'Maryland'},
        {'city': 'Randolph', 'state': 'Massachusetts'},
        {'city': 'Randolph', 'state': 'Massachusetts'},
        {'city': 'Rantoul', 'state': 'Illinois'},
        {'city': 'Rapid City', 'state': 'South Dakota'},
        {'city': 'Rapid Valley', 'state': 'South Dakota'},
        {'city': 'Raritan', 'state': 'New Jersey'},
        {'city': 'Raton', 'state': 'New Mexico'},
        {'city': 'Ravenna', 'state': 'Ohio'},
        {'city': 'Rawlins', 'state': 'Wyoming'},
        {'city': 'Raymond', 'state': 'New Hampshire'},
        {'city': 'Raymondville', 'state': 'Texas'},
        {'city': 'Raymore', 'state': 'Missouri'},
        {'city': 'Rayne', 'state': 'Louisiana'},
        {'city': 'Raynham', 'state': 'Massachusetts'},
        {'city': 'Raytown', 'state': 'Missouri'},
        {'city': 'Reading', 'state': 'Massachusetts'},
        {'city': 'Reading', 'state': 'Massachusetts'},
        {'city': 'Reading', 'state': 'Pennsylvania'},
        {'city': 'Reading', 'state': 'Ohio'},
        {'city': 'Red Bank', 'state': 'South Carolina'},
        {'city': 'Red Bank', 'state': 'Tennessee'},
        {'city': 'Red Bank', 'state': 'New Jersey'},
        {'city': 'Red Bluff', 'state': 'California'},
        {'city': 'Red Hill', 'state': 'South Carolina'},
        {'city': 'Red Hook', 'state': 'New York'},
        {'city': 'Red Lion', 'state': 'Pennsylvania'},
        {'city': 'Red Oak', 'state': 'Iowa'},
        {'city': 'Red Wing', 'state': 'Minnesota'},
        {'city': 'Redan', 'state': 'Georgia'},
        {'city': 'Redding', 'state': 'Connecticut'},
        {'city': 'Redding', 'state': 'California'},
        {'city': 'Redford', 'state': 'Michigan'},
        {'city': 'Redland', 'state': 'Maryland'},
        {'city': 'Redlands', 'state': 'California'},
        {'city': 'Redlands', 'state': 'Colorado'},
        {'city': 'Redmond', 'state': 'Oregon'},
        {'city': 'Redmond', 'state': 'Washington'},
        {'city': 'Redondo Beach', 'state': 'California'},
        {'city': 'Redwood City', 'state': 'California'},
        {'city': 'Reedley', 'state': 'California'},
        {'city': 'Reedsburg', 'state': 'Wisconsin'},
        {'city': 'Rehoboth', 'state': 'Massachusetts'},
        {'city': 'Reidsville', 'state': 'North Carolina'},
        {'city': 'Reisterstown', 'state': 'Maryland'},
        {'city': 'Rendon', 'state': 'Texas'},
        {'city': 'Reno', 'state': 'Nevada'},
        {'city': 'Rensselaer', 'state': 'New York'},
        {'city': 'Renton', 'state': 'Washington'},
        {'city': 'Republic', 'state': 'Missouri'},
        {'city': 'Reserve', 'state': 'Louisiana'},
        {'city': 'Reston', 'state': 'Virginia'},
        {'city': 'Revere', 'state': 'Massachusetts'},
        {'city': 'Rexburg', 'state': 'Idaho'},
        {'city': 'Reynoldsburg', 'state': 'Ohio'},
        {'city': 'Rhinebeck', 'state': 'New York'},
        {'city': 'Rhinelander', 'state': 'Wisconsin'},
        {'city': 'Rialto', 'state': 'California'},
        {'city': 'Rib Mountain', 'state': 'Wisconsin'},
        {'city': 'Rib Mountain', 'state': 'Wisconsin'},
        {'city': 'Rice Lake', 'state': 'Wisconsin'},
        {'city': 'Richardson', 'state': 'Texas'},
        {'city': 'Richboro', 'state': 'Pennsylvania'},
        {'city': 'Richfield', 'state': 'Utah'},
        {'city': 'Richfield', 'state': 'Wisconsin'},
        {'city': 'Richfield', 'state': 'Minnesota'},
        {'city': 'Richland', 'state': 'Washington'},
        {'city': 'Richland', 'state': 'Mississippi'},
        {'city': 'Richland Hills', 'state': 'Texas'},
        {'city': 'Richmond', 'state': 'Texas'},
        {'city': 'Richmond', 'state': 'Rhode Island'},
        {'city': 'Richmond', 'state': 'Virginia'},
        {'city': 'Richmond', 'state': 'Missouri'},
        {'city': 'Richmond', 'state': 'Kentucky'},
        {'city': 'Richmond', 'state': 'Indiana'},
        {'city': 'Richmond', 'state': 'California'},
        {'city': 'Richmond Heights', 'state': 'Florida'},
        {'city': 'Richmond Heights', 'state': 'Missouri'},
        {'city': 'Richmond Heights', 'state': 'Ohio'},
        {'city': 'Richmond Hill', 'state': 'Georgia'},
        {'city': 'Richmond West', 'state': 'Florida'},
        {'city': 'Richton Park', 'state': 'Illinois'},
        {'city': 'Ridge', 'state': 'New York'},
        {'city': 'Ridgecrest', 'state': 'California'},
        {'city': 'Ridgefield', 'state': 'Connecticut'},
        {'city': 'Ridgefield', 'state': 'Connecticut'},
        {'city': 'Ridgefield', 'state': 'New Jersey'},
        {'city': 'Ridgefield Park', 'state': 'New Jersey'},
        {'city': 'Ridgeland', 'state': 'Mississippi'},
        {'city': 'Ridgeway', 'state': 'New York'},
        {'city': 'Ridgewood', 'state': 'New Jersey'},
        {'city': 'Ridley Park', 'state': 'Pennsylvania'},
        {'city': 'Rifle', 'state': 'Colorado'},
        {'city': 'Ringwood', 'state': 'New Jersey'},
        {'city': 'Rio del Mar', 'state': 'California'},
        {'city': 'Rio Grande City', 'state': 'Texas'},
        {'city': 'Rio Linda', 'state': 'California'},
        {'city': 'Rio Rancho', 'state': 'New Mexico'},
        {'city': 'Ripley', 'state': 'Tennessee'},
        {'city': 'Ripon', 'state': 'Wisconsin'},
        {'city': 'Ripon', 'state': 'California'},
        {'city': 'Rittman', 'state': 'Ohio'},
        {'city': 'River Edge', 'state': 'New Jersey'},
        {'city': 'River Falls', 'state': 'Wisconsin'},
        {'city': 'River Forest', 'state': 'Illinois'},
        {'city': 'River Grove', 'state': 'Illinois'},
        {'city': 'River Oaks', 'state': 'Texas'},
        {'city': 'River Ridge', 'state': 'Louisiana'},
        {'city': 'River Rouge', 'state': 'Michigan'},
        {'city': 'River Vale', 'state': 'New Jersey'},
        {'city': 'Riverbank', 'state': 'California'},
        {'city': 'Riverdale', 'state': 'Illinois'},
        {'city': 'Riverdale', 'state': 'Georgia'},
        {'city': 'Riverdale', 'state': 'Utah'},
        {'city': 'Riverdale Park', 'state': 'Maryland'},
        {'city': 'Riverhead', 'state': 'New York'},
        {'city': 'Riverhead', 'state': 'New York'},
        {'city': 'Riverside', 'state': 'Ohio'},
        {'city': 'Riverside', 'state': 'Maryland'},
        {'city': 'Riverside', 'state': 'Illinois'},
        {'city': 'Riverside', 'state': 'California'},
        {'city': 'Riverton', 'state': 'Utah'},
        {'city': 'Riverton', 'state': 'Wyoming'},
        {'city': 'Riverton-Boulevard Park', 'state': 'Washington'},
        {'city': 'Riverview', 'state': 'Florida'},
        {'city': 'Riverview', 'state': 'Michigan'},
        {'city': 'Riviera Beach', 'state': 'Maryland'},
        {'city': 'Riviera Beach', 'state': 'Florida'},
        {'city': 'Roanoke', 'state': 'Alabama'},
        {'city': 'Roanoke', 'state': 'Virginia'},
        {'city': 'Roanoke Rapids', 'state': 'North Carolina'},
        {'city': 'Robbins', 'state': 'Illinois'},
        {'city': 'Robbinsdale', 'state': 'Minnesota'},
        {'city': 'Robinson', 'state': 'Illinois'},
        {'city': 'Robinson', 'state': 'Texas'},
        {'city': 'Robinson Township', 'state': 'Pennsylvania'},
        {'city': 'Robstown', 'state': 'Texas'},
        {'city': 'Rochelle', 'state': 'Illinois'},
        {'city': 'Rochester', 'state': 'Minnesota'},
        {'city': 'Rochester', 'state': 'Michigan'},
        {'city': 'Rochester', 'state': 'Indiana'},
        {'city': 'Rochester', 'state': 'New York'},
        {'city': 'Rochester', 'state': 'New York'},
        {'city': 'Rochester', 'state': 'New Hampshire'},
        {'city': 'Rochester Hills', 'state': 'Michigan'},
        {'city': 'Rock Falls', 'state': 'Illinois'},
        {'city': 'Rock Hill', 'state': 'South Carolina'},
        {'city': 'Rock Island', 'state': 'Illinois'},
        {'city': 'Rock Springs', 'state': 'Wyoming'},
        {'city': 'Rockaway', 'state': 'New Jersey'},
        {'city': 'Rockcreek', 'state': 'Oregon'},
        {'city': 'Rockford', 'state': 'Illinois'},
        {'city': 'Rockingham', 'state': 'North Carolina'},
        {'city': 'Rockland', 'state': 'Massachusetts'},
        {'city': 'Rockland', 'state': 'Maine'},
        {'city': 'Rockledge', 'state': 'Florida'},
        {'city': 'Rocklin', 'state': 'California'},
        {'city': 'Rockport', 'state': 'Massachusetts'},
        {'city': 'Rockport', 'state': 'Texas'},
        {'city': 'Rockville', 'state': 'Maryland'},
        {'city': 'Rockville', 'state': 'Connecticut'},
        {'city': 'Rockville Centre', 'state': 'New York'},
        {'city': 'Rockwall', 'state': 'Texas'},
        {'city': 'Rocky Hill', 'state': 'Connecticut'},
        {'city': 'Rocky Mount', 'state': 'North Carolina'},
        {'city': 'Rocky Point', 'state': 'New York'},
        {'city': 'Rocky River', 'state': 'Ohio'},
        {'city': 'Rodeo', 'state': 'California'},
        {'city': 'Roeland Park', 'state': 'Kansas'},
        {'city': 'Rogers', 'state': 'Arkansas'},
        {'city': 'Rohnert Park', 'state': 'California'},
        {'city': 'Rolla', 'state': 'Missouri'},
        {'city': 'Rolling Hills Estates', 'state': 'California'},
        {'city': 'Rolling Meadows', 'state': 'Illinois'},
        {'city': 'Roma', 'state': 'Texas'},
        {'city': 'Rome', 'state': 'New York'},
        {'city': 'Rome', 'state': 'Georgia'},
        {'city': 'Romeoville', 'state': 'Illinois'},
        {'city': 'Romulus', 'state': 'Michigan'},
        {'city': 'Ronkonkoma', 'state': 'New York'},
        {'city': 'Roosevelt', 'state': 'New York'},
        {'city': 'Rosamond', 'state': 'California'},
        {'city': 'Rosaryville', 'state': 'Maryland'},
        {'city': 'Roscoe', 'state': 'Illinois'},
        {'city': 'Rose Hill', 'state': 'Virginia'},
        {'city': 'Roseburg', 'state': 'Oregon'},
        {'city': 'Rosedale', 'state': 'Maryland'},
        {'city': 'Rosedale', 'state': 'California'},
        {'city': 'Roseland', 'state': 'California'},
        {'city': 'Roselle', 'state': 'Illinois'},
        {'city': 'Roselle', 'state': 'New Jersey'},
        {'city': 'Roselle Park', 'state': 'New Jersey'},
        {'city': 'Rosemead', 'state': 'California'},
        {'city': 'Rosemont', 'state': 'California'},
        {'city': 'Rosemount', 'state': 'Minnesota'},
        {'city': 'Rosenberg', 'state': 'Texas'},
        {'city': 'Rosendale', 'state': 'New York'},
        {'city': 'Roseville', 'state': 'Minnesota'},
        {'city': 'Roseville', 'state': 'Michigan'},
        {'city': 'Roseville', 'state': 'California'},
        {'city': 'Roslyn Heights', 'state': 'New York'},
        {'city': 'Ross Township', 'state': 'Pennsylvania'},
        {'city': 'Rossford', 'state': 'Ohio'},
        {'city': 'Rossmoor', 'state': 'California'},
        {'city': 'Rossmoor', 'state': 'Maryland'},
        {'city': 'Rossville', 'state': 'Maryland'},
        {'city': 'Roswell', 'state': 'Georgia'},
        {'city': 'Roswell', 'state': 'New Mexico'},
        {'city': 'Rotonda', 'state': 'Florida'},
        {'city': 'Rotterdam', 'state': 'New York'},
        {'city': 'Rotterdam', 'state': 'New York'},
        {'city': 'Round Lake Beach', 'state': 'Illinois'},
        {'city': 'Round Lake Park', 'state': 'Illinois'},
        {'city': 'Round Rock', 'state': 'Texas'},
        {'city': 'Rowland Heights', 'state': 'California'},
        {'city': 'Rowlett', 'state': 'Texas'},
        {'city': 'Roxboro', 'state': 'North Carolina'},
        {'city': 'Roy', 'state': 'Utah'},
        {'city': 'Royal Oak', 'state': 'Michigan'},
        {'city': 'Royal Palm Beach', 'state': 'Florida'},
        {'city': 'Royalton', 'state': 'New York'},
        {'city': 'Rubidoux', 'state': 'California'},
        {'city': 'Ruidoso', 'state': 'New Mexico'},
        {'city': 'Rumford', 'state': 'Maine'},
        {'city': 'Rumson', 'state': 'New Jersey'},
        {'city': 'Runnemede', 'state': 'New Jersey'},
        {'city': 'Ruskin', 'state': 'Florida'},
        {'city': 'Russellville', 'state': 'Arkansas'},
        {'city': 'Russellville', 'state': 'Alabama'},
        {'city': 'Russellville', 'state': 'Kentucky'},
        {'city': 'Ruston', 'state': 'Louisiana'},
        {'city': 'Rutherford', 'state': 'New Jersey'},
        {'city': 'Rutland', 'state': 'Massachusetts'},
        {'city': 'Rutland', 'state': 'Vermont'},
        {'city': 'Rye', 'state': 'New York'},
        {'city': 'Rye', 'state': 'New York'},
        {'city': 'Rye Brook', 'state': 'New York'},
        {'city': 'Sachse', 'state': 'Texas'},
        {'city': 'Saco', 'state': 'Maine'},
        {'city': 'Sacramento', 'state': 'California'},
        {'city': 'Saddle Brook', 'state': 'New Jersey'},
        {'city': 'Safety Harbor', 'state': 'Florida'},
        {'city': 'Safford', 'state': 'Arizona'},
        {'city': 'Saginaw', 'state': 'Michigan'},
        {'city': 'Saginaw', 'state': 'Texas'},
        {'city': 'Saginaw Township North', 'state': 'Michigan'},
        {'city': 'Saginaw Township South', 'state': 'Michigan'},
        {'city': 'Saks', 'state': 'Alabama'},
        {'city': 'Salamanca', 'state': 'New York'},
        {'city': 'Salem', 'state': 'New Hampshire'},
        {'city': 'Salem', 'state': 'Ohio'},
        {'city': 'Salem', 'state': 'Oregon'},
        {'city': 'Salem', 'state': 'Virginia'},
        {'city': 'Salem', 'state': 'Wisconsin'},
        {'city': 'Salem', 'state': 'Massachusetts'},
        {'city': 'Salem', 'state': 'Illinois'},
        {'city': 'Salem', 'state': 'Indiana'},
        {'city': 'Salida', 'state': 'California'},
        {'city': 'Salina', 'state': 'Kansas'},
        {'city': 'Salina', 'state': 'New York'},
        {'city': 'Salinas', 'state': 'California'},
        {'city': 'Saline', 'state': 'Michigan'},
        {'city': 'Salisbury', 'state': 'Massachusetts'},
        {'city': 'Salisbury', 'state': 'Maryland'},
        {'city': 'Salisbury', 'state': 'New York'},
        {'city': 'Salisbury', 'state': 'North Carolina'},
        {'city': 'Sallisaw', 'state': 'Oklahoma'},
        {'city': 'Salmon Creek', 'state': 'Washington'},
        {'city': 'Salt Lake City', 'state': 'Utah'},
        {'city': 'Sammamish', 'state': 'Washington'},
        {'city': 'San Angelo', 'state': 'Texas'},
        {'city': 'San Anselmo', 'state': 'California'},
        {'city': 'San Antonio', 'state': 'Texas'},
        {'city': 'San Benito', 'state': 'Texas'},
        {'city': 'San Bernardino', 'state': 'California'},
        {'city': 'San Bruno', 'state': 'California'},
        {'city': 'San Buenaventura', 'state': 'California'},
        {'city': 'San Carlos', 'state': 'California'},
        {'city': 'San Carlos Park', 'state': 'Florida'},
        {'city': 'San Clemente', 'state': 'California'},
        {'city': 'San Diego', 'state': 'California'},
        {'city': 'San Diego Country Estates', 'state': 'California'},
        {'city': 'San Dimas', 'state': 'California'},
        {'city': 'San Elizario', 'state': 'Texas'},
        {'city': 'San Fernando', 'state': 'California'},
        {'city': 'San Francisco', 'state': 'California'},
        {'city': 'San Gabriel', 'state': 'California'},
        {'city': 'San Jacinto', 'state': 'California'},
        {'city': 'San Jose', 'state': 'California'},
        {'city': 'San Juan', 'state': 'Texas'},
        {'city': 'San Juan Capistrano', 'state': 'California'},
        {'city': 'San Leandro', 'state': 'California'},
        {'city': 'San Lorenzo', 'state': 'California'},
        {'city': 'San Luis', 'state': 'Arizona'},
        {'city': 'San Luis Obispo', 'state': 'California'},
        {'city': 'San Marcos', 'state': 'California'},
        {'city': 'San Marcos', 'state': 'Texas'},
        {'city': 'San Marino', 'state': 'California'},
        {'city': 'San Mateo', 'state': 'California'},
        {'city': 'San Pablo', 'state': 'California'},
        {'city': 'San Rafael', 'state': 'California'},
        {'city': 'San Ramon', 'state': 'California'},
        {'city': 'Sanatoga', 'state': 'Pennsylvania'},
        {'city': 'Sand Lake', 'state': 'New York'},
        {'city': 'Sand Springs', 'state': 'Oklahoma'},
        {'city': 'Sandalfoot Cove', 'state': 'Florida'},
        {'city': 'Sandersville', 'state': 'Georgia'},
        {'city': 'Sandpoint', 'state': 'Idaho'},
        {'city': 'Sandusky', 'state': 'Ohio'},
        {'city': 'Sandusky South', 'state': 'Ohio'},
        {'city': 'Sandwich', 'state': 'Illinois'},
        {'city': 'Sandwich', 'state': 'Massachusetts'},
        {'city': 'Sandy', 'state': 'Utah'},
        {'city': 'Sandy Springs', 'state': 'Georgia'},
        {'city': 'Sanford', 'state': 'Florida'},
        {'city': 'Sanford', 'state': 'Maine'},
        {'city': 'Sanford', 'state': 'Maine'},
        {'city': 'Sanford', 'state': 'North Carolina'},
        {'city': 'Sanger', 'state': 'California'},
        {'city': 'Sanibel', 'state': 'Florida'},
        {'city': 'Sans Souci', 'state': 'South Carolina'},
        {'city': 'Santa Ana', 'state': 'California'},
        {'city': 'Santa Barbara', 'state': 'California'},
        {'city': 'Santa Clara', 'state': 'California'},
        {'city': 'Santa Clarita', 'state': 'California'},
        {'city': 'Santa Cruz', 'state': 'California'},
        {'city': 'Santa Fe', 'state': 'New Mexico'},
        {'city': 'Santa Fe', 'state': 'Texas'},
        {'city': 'Santa Fe Springs', 'state': 'California'},
        {'city': 'Santa Maria', 'state': 'California'},
        {'city': 'Santa Monica', 'state': 'California'},
        {'city': 'Santa Paula', 'state': 'California'},
        {'city': 'Santa Rosa', 'state': 'California'},
        {'city': 'Santee', 'state': 'California'},
        {'city': 'Sappington', 'state': 'Missouri'},
        {'city': 'Sapulpa', 'state': 'Oklahoma'},
        {'city': 'Saraland', 'state': 'Alabama'},
        {'city': 'Sarasota', 'state': 'Florida'},
        {'city': 'Sarasota Springs', 'state': 'Florida'},
        {'city': 'Saratoga', 'state': 'California'},
        {'city': 'Saratoga Springs', 'state': 'New York'},
        {'city': 'Sartell', 'state': 'Minnesota'},
        {'city': 'Satellite Beach', 'state': 'Florida'},
        {'city': 'Saugerties', 'state': 'New York'},
        {'city': 'Saugus', 'state': 'Massachusetts'},
        {'city': 'Saugus', 'state': 'Massachusetts'},
        {'city': 'Sauk Rapids', 'state': 'Minnesota'},
        {'city': 'Sauk Village', 'state': 'Illinois'},
        {'city': 'Sault Ste. Marie', 'state': 'Michigan'},
        {'city': 'Sausalito', 'state': 'California'},
        {'city': 'Savage', 'state': 'Minnesota'},
        {'city': 'Savage-Guilford', 'state': 'Maryland'},
        {'city': 'Savannah', 'state': 'Georgia'},
        {'city': 'Savannah', 'state': 'Tennessee'},
        {'city': 'Sayreville', 'state': 'New Jersey'},
        {'city': 'Sayville', 'state': 'New York'},
        {'city': 'Scarborough', 'state': 'Maine'},
        {'city': 'Scarsdale', 'state': 'New York'},
        {'city': 'Scarsdale', 'state': 'New York'},
        {'city': 'Schaghticoke', 'state': 'New York'},
        {'city': 'Schaumburg', 'state': 'Illinois'},
        {'city': 'Schenectady', 'state': 'New York'},
        {'city': 'Schererville', 'state': 'Indiana'},
        {'city': 'Schertz', 'state': 'Texas'},
        {'city': 'Schiller Park', 'state': 'Illinois'},
        {'city': 'Schodack', 'state': 'New York'},
        {'city': 'Schofield Barracks', 'state': 'Hawaii'},
        {'city': 'Schroeppel', 'state': 'New York'},
        {'city': 'Scituate', 'state': 'Rhode Island'},
        {'city': 'Scituate', 'state': 'Massachusetts'},
        {'city': 'Scotch Plains', 'state': 'New Jersey'},
        {'city': 'Scotchtown', 'state': 'New York'},
        {'city': 'Scotia', 'state': 'New York'},
        {'city': 'Scott', 'state': 'Louisiana'},
        {'city': 'Scott Lake', 'state': 'Florida'},
        {'city': 'Scott Township', 'state': 'Pennsylvania'},
        {'city': 'Scottdale', 'state': 'Georgia'},
        {'city': 'Scotts Valley', 'state': 'California'},
        {'city': 'Scottsbluff', 'state': 'Nebraska'},
        {'city': 'Scottsboro', 'state': 'Alabama'},
        {'city': 'Scottsburg', 'state': 'Indiana'},
        {'city': 'Scottsdale', 'state': 'Arizona'},
        {'city': 'Scranton', 'state': 'Pennsylvania'},
        {'city': 'Scriba', 'state': 'New York'},
        {'city': 'Seabrook', 'state': 'New Hampshire'},
        {'city': 'Seabrook', 'state': 'Texas'},
        {'city': 'Seaford', 'state': 'New York'},
        {'city': 'Seaford', 'state': 'Delaware'},
        {'city': 'Seagoville', 'state': 'Texas'},
        {'city': 'Seal Beach', 'state': 'California'},
        {'city': 'Searcy', 'state': 'Arkansas'},
        {'city': 'Seaside', 'state': 'California'},
        {'city': 'SeaTac', 'state': 'Washington'},
        {'city': 'Seattle', 'state': 'Washington'},
        {'city': 'Seattle Hill-Silver Firs', 'state': 'Washington'},
        {'city': 'Sebastian', 'state': 'Florida'},
        {'city': 'Sebastopol', 'state': 'California'},
        {'city': 'Sebring', 'state': 'Florida'},
        {'city': 'Secaucus', 'state': 'New Jersey'},
        {'city': 'Security-Widefield', 'state': 'Colorado'},
        {'city': 'Sedalia', 'state': 'Missouri'},
        {'city': 'Sedona', 'state': 'Arizona'},
        {'city': 'Sedro-Woolley', 'state': 'Washington'},
        {'city': 'Seekonk', 'state': 'Massachusetts'},
        {'city': 'Seguin', 'state': 'Texas'},
        {'city': 'Selah', 'state': 'Washington'},
        {'city': 'Selden', 'state': 'New York'},
        {'city': 'Sellersburg', 'state': 'Indiana'},
        {'city': 'Selma', 'state': 'Alabama'},
        {'city': 'Selma', 'state': 'California'},
        {'city': 'Seminole', 'state': 'Florida'},
        {'city': 'Seminole', 'state': 'Oklahoma'},
        {'city': 'Senatobia', 'state': 'Mississippi'},
        {'city': 'Seneca', 'state': 'South Carolina'},
        {'city': 'Seneca Falls', 'state': 'New York'},
        {'city': 'Seneca Falls', 'state': 'New York'},
        {'city': 'Setauket-East Setauket', 'state': 'New York'},
        {'city': 'Seven Corners', 'state': 'Virginia'},
        {'city': 'Seven Hills', 'state': 'Ohio'},
        {'city': 'Seven Oaks', 'state': 'South Carolina'},
        {'city': 'Severn', 'state': 'Maryland'},
        {'city': 'Severna Park', 'state': 'Maryland'},
        {'city': 'Sevierville', 'state': 'Tennessee'},
        {'city': 'Seward', 'state': 'Nebraska'},
        {'city': 'Seymour', 'state': 'Tennessee'},
        {'city': 'Seymour', 'state': 'Indiana'},
        {'city': 'Seymour', 'state': 'Connecticut'},
        {'city': 'Shady Hills', 'state': 'Florida'},
        {'city': 'Shafter', 'state': 'California'},
        {'city': 'Shaker Heights', 'state': 'Ohio'},
        {'city': 'Shakopee', 'state': 'Minnesota'},
        {'city': 'Shaler Township', 'state': 'Pennsylvania'},
        {'city': 'Shamokin', 'state': 'Pennsylvania'},
        {'city': 'Sharon', 'state': 'Pennsylvania'},
        {'city': 'Sharon', 'state': 'Massachusetts'},
        {'city': 'Sharonville', 'state': 'Ohio'},
        {'city': 'Shasta Lake', 'state': 'California'},
        {'city': 'Shawangunk', 'state': 'New York'},
        {'city': 'Shawano', 'state': 'Wisconsin'},
        {'city': 'Shawnee', 'state': 'Oklahoma'},
        {'city': 'Shawnee', 'state': 'Kansas'},
        {'city': 'Sheboygan', 'state': 'Wisconsin'},
        {'city': 'Sheboygan Falls', 'state': 'Wisconsin'},
        {'city': 'Sheffield', 'state': 'Alabama'},
        {'city': 'Sheffield Lake', 'state': 'Ohio'},
        {'city': 'Shelburne', 'state': 'Vermont'},
        {'city': 'Shelby', 'state': 'Ohio'},
        {'city': 'Shelby', 'state': 'North Carolina'},
        {'city': 'Shelby', 'state': 'Michigan'},
        {'city': 'Shelbyville', 'state': 'Kentucky'},
        {'city': 'Shelbyville', 'state': 'Indiana'},
        {'city': 'Shelbyville', 'state': 'Tennessee'},
        {'city': 'Shelton', 'state': 'Washington'},
        {'city': 'Shelton', 'state': 'Connecticut'},
        {'city': 'Shelton', 'state': 'Connecticut'},
        {'city': 'Shenandoah', 'state': 'Louisiana'},
        {'city': 'Shepherdsville', 'state': 'Kentucky'},
        {'city': 'Sheridan', 'state': 'Wyoming'},
        {'city': 'Sherman', 'state': 'Texas'},
        {'city': 'Sherrelwood', 'state': 'Colorado'},
        {'city': 'Sherwood', 'state': 'Arkansas'},
        {'city': 'Sherwood', 'state': 'Oregon'},
        {'city': 'Shields', 'state': 'Michigan'},
        {'city': 'Shiloh', 'state': 'Illinois'},
        {'city': 'Shiloh', 'state': 'Pennsylvania'},
        {'city': 'Shiloh', 'state': 'Ohio'},
        {'city': 'Shiprock', 'state': 'New Mexico'},
        {'city': 'Shirley', 'state': 'New York'},
        {'city': 'Shirley', 'state': 'Massachusetts'},
        {'city': 'Shively', 'state': 'Kentucky'},
        {'city': 'Shoreline', 'state': 'Washington'},
        {'city': 'Shoreview', 'state': 'Minnesota'},
        {'city': 'Shorewood', 'state': 'Minnesota'},
        {'city': 'Shorewood', 'state': 'Illinois'},
        {'city': 'Shorewood', 'state': 'Wisconsin'},
        {'city': 'Show Low', 'state': 'Arizona'},
        {'city': 'Shreveport', 'state': 'Louisiana'},
        {'city': 'Shrewsbury', 'state': 'Missouri'},
        {'city': 'Shrewsbury', 'state': 'Massachusetts'},
        {'city': 'Sidney', 'state': 'New York'},
        {'city': 'Sidney', 'state': 'Nebraska'},
        {'city': 'Sidney', 'state': 'Ohio'},
        {'city': 'Sierra Madre', 'state': 'California'},
        {'city': 'Sierra Vista', 'state': 'Arizona'},
        {'city': 'Sierra Vista Southeast', 'state': 'Arizona'},
        {'city': 'Siesta Key', 'state': 'Florida'},
        {'city': 'Signal Hill', 'state': 'California'},
        {'city': 'Signal Mountain', 'state': 'Tennessee'},
        {'city': 'Sikeston', 'state': 'Missouri'},
        {'city': 'Siler City', 'state': 'North Carolina'},
        {'city': 'Siloam Springs', 'state': 'Arkansas'},
        {'city': 'Silsbee', 'state': 'Texas'},
        {'city': 'Silver City', 'state': 'New Mexico'},
        {'city': 'Silver Spring', 'state': 'Maryland'},
        {'city': 'Silver Springs Shores', 'state': 'Florida'},
        {'city': 'Silverdale', 'state': 'Washington'},
        {'city': 'Silverton', 'state': 'Oregon'},
        {'city': 'Silvis', 'state': 'Illinois'},
        {'city': 'Simi Valley', 'state': 'California'},
        {'city': 'Simpsonville', 'state': 'South Carolina'},
        {'city': 'Simsbury', 'state': 'Connecticut'},
        {'city': 'Sioux Center', 'state': 'Iowa'},
        {'city': 'Sioux City', 'state': 'Iowa'},
        {'city': 'Sioux Falls', 'state': 'South Dakota'},
        {'city': 'Sitka and', 'state': 'Alaska'},
        {'city': 'Skaneateles', 'state': 'New York'},
        {'city': 'Skidaway Island', 'state': 'Georgia'},
        {'city': 'Skokie', 'state': 'Illinois'},
        {'city': 'Skowhegan', 'state': 'Maine'},
        {'city': 'Skowhegan', 'state': 'Maine'},
        {'city': 'Slaton', 'state': 'Texas'},
        {'city': 'Sleepy Hollow', 'state': 'New York'},
        {'city': 'Slidell', 'state': 'Louisiana'},
        {'city': 'Smithfield', 'state': 'North Carolina'},
        {'city': 'Smithfield', 'state': 'Rhode Island'},
        {'city': 'Smithfield', 'state': 'Utah'},
        {'city': 'Smithfield', 'state': 'Virginia'},
        {'city': 'Smiths', 'state': 'Alabama'},
        {'city': 'Smithtown', 'state': 'New York'},
        {'city': 'Smithtown', 'state': 'New York'},
        {'city': 'Smyrna', 'state': 'Tennessee'},
        {'city': 'Smyrna', 'state': 'Georgia'},
        {'city': 'Snellville', 'state': 'Georgia'},
        {'city': 'Snohomish', 'state': 'Washington'},
        {'city': 'Snyder', 'state': 'Texas'},
        {'city': 'Socastee', 'state': 'South Carolina'},
        {'city': 'Socorro', 'state': 'New Mexico'},
        {'city': 'Socorro', 'state': 'Texas'},
        {'city': 'Soddy-Daisy', 'state': 'Tennessee'},
        {'city': 'Sodus', 'state': 'New York'},
        {'city': 'Solana Beach', 'state': 'California'},
        {'city': 'Soledad', 'state': 'California'},
        {'city': 'Solon', 'state': 'Ohio'},
        {'city': 'Solvay', 'state': 'New York'},
        {'city': 'Somers', 'state': 'New York'},
        {'city': 'Somers', 'state': 'Wisconsin'},
        {'city': 'Somers', 'state': 'Connecticut'},
        {'city': 'Somers Point', 'state': 'New Jersey'},
        {'city': 'Somerset', 'state': 'New Jersey'},
        {'city': 'Somerset', 'state': 'Pennsylvania'},
        {'city': 'Somerset', 'state': 'Kentucky'},
        {'city': 'Somerset', 'state': 'Massachusetts'},
        {'city': 'Somerset', 'state': 'Massachusetts'},
        {'city': 'Somersworth', 'state': 'New Hampshire'},
        {'city': 'Somerton', 'state': 'Arizona'},
        {'city': 'Somerville', 'state': 'Massachusetts'},
        {'city': 'Somerville', 'state': 'New Jersey'},
        {'city': 'Sonoma', 'state': 'California'},
        {'city': 'Souderton', 'state': 'Pennsylvania'},
        {'city': 'Sound Beach', 'state': 'New York'},
        {'city': 'South Amboy', 'state': 'New Jersey'},
        {'city': 'South Bend', 'state': 'Indiana'},
        {'city': 'South Berwick', 'state': 'Maine'},
        {'city': 'South Boston', 'state': 'Virginia'},
        {'city': 'South Bradenton', 'state': 'Florida'},
        {'city': 'South Burlington', 'state': 'Vermont'},
        {'city': 'South Charleston', 'state': 'West Virginia'},
        {'city': 'South Cleveland', 'state': 'Tennessee'},
        {'city': 'South Daytona', 'state': 'Florida'},
        {'city': 'South El Monte', 'state': 'California'},
        {'city': 'South Elgin', 'state': 'Illinois'},
        {'city': 'South Euclid', 'state': 'Ohio'},
        {'city': 'South Farmingdale', 'state': 'New York'},
        {'city': 'South Gate', 'state': 'Maryland'},
        {'city': 'South Gate', 'state': 'California'},
        {'city': 'South Hadley', 'state': 'Massachusetts'},
        {'city': 'South Highpoint', 'state': 'Florida'},
        {'city': 'South Hill', 'state': 'New York'},
        {'city': 'South Hill', 'state': 'Washington'},
        {'city': 'South Holland', 'state': 'Illinois'},
        {'city': 'South Houston', 'state': 'Texas'},
        {'city': 'South Huntington', 'state': 'New York'},
        {'city': 'South Jordan', 'state': 'Utah'},
        {'city': 'South Kensington', 'state': 'Maryland'},
        {'city': 'South Kingstown', 'state': 'Rhode Island'},
        {'city': 'South Lake Tahoe', 'state': 'California'},
        {'city': 'South Laurel', 'state': 'Maryland'},
        {'city': 'South Lockport', 'state': 'New York'},
        {'city': 'South Lyon', 'state': 'Michigan'},
        {'city': 'South Miami', 'state': 'Florida'},
        {'city': 'South Miami Heights', 'state': 'Florida'},
        {'city': 'South Milwaukee', 'state': 'Wisconsin'},
        {'city': 'South Monroe', 'state': 'Michigan'},
        {'city': 'South Ogden', 'state': 'Utah'},
        {'city': 'South Orange', 'state': 'New Jersey'},
        {'city': 'South Oroville', 'state': 'California'},
        {'city': 'South Park Township', 'state': 'Pennsylvania'},
        {'city': 'South Pasadena', 'state': 'California'},
        {'city': 'South Patrick Shores', 'state': 'Florida'},
        {'city': 'South Plainfield', 'state': 'New Jersey'},
        {'city': 'South Portland', 'state': 'Maine'},
        {'city': 'South River', 'state': 'New Jersey'},
        {'city': 'South Salt Lake', 'state': 'Utah'},
        {'city': 'South San Francisco', 'state': 'California'},
        {'city': 'South San Gabriel', 'state': 'California'},
        {'city': 'South San Jose Hills', 'state': 'California'},
        {'city': 'South Sioux City', 'state': 'Nebraska'},
        {'city': 'South St. Paul', 'state': 'Minnesota'},
        {'city': 'South Valley', 'state': 'New Mexico'},
        {'city': 'South Venice', 'state': 'Florida'},
        {'city': 'South Whittier', 'state': 'California'},
        {'city': 'South Williamsport', 'state': 'Pennsylvania'},
        {'city': 'South Windsor', 'state': 'Connecticut'},
        {'city': 'South Yarmouth', 'state': 'Massachusetts'},
        {'city': 'South Yuba City', 'state': 'California'},
        {'city': 'Southampton', 'state': 'New York'},
        {'city': 'Southaven', 'state': 'Mississippi'},
        {'city': 'Southborough', 'state': 'Massachusetts'},
        {'city': 'Southbridge', 'state': 'Massachusetts'},
        {'city': 'Southbridge', 'state': 'Massachusetts'},
        {'city': 'Southbury', 'state': 'Connecticut'},
        {'city': 'Southeast', 'state': 'New York'},
        {'city': 'Southeast Arcadia', 'state': 'Florida'},
        {'city': 'Southern Pines', 'state': 'North Carolina'},
        {'city': 'Southfield', 'state': 'Michigan'},
        {'city': 'Southgate', 'state': 'Michigan'},
        {'city': 'Southgate', 'state': 'Florida'},
        {'city': 'Southglenn', 'state': 'Colorado'},
        {'city': 'Southington', 'state': 'Connecticut'},
        {'city': 'Southlake', 'state': 'Texas'},
        {'city': 'Southold', 'state': 'New York'},
        {'city': 'Southport', 'state': 'New York'},
        {'city': 'Southport', 'state': 'New York'},
        {'city': 'Southside', 'state': 'Alabama'},
        {'city': 'Southwick', 'state': 'Massachusetts'},
        {'city': 'Southwood Acres', 'state': 'Connecticut'},
        {'city': 'Spanaway', 'state': 'Washington'},
        {'city': 'Spanish Fork', 'state': 'Utah'},
        {'city': 'Spanish Lake', 'state': 'Missouri'},
        {'city': 'Spanish Springs', 'state': 'Nevada'},
        {'city': 'Sparks', 'state': 'Nevada'},
        {'city': 'Sparta', 'state': 'Wisconsin'},
        {'city': 'Spartanburg', 'state': 'South Carolina'},
        {'city': 'Spearfish', 'state': 'South Dakota'},
        {'city': 'Speedway', 'state': 'Indiana'},
        {'city': 'Spencer', 'state': 'Iowa'},
        {'city': 'Spencer', 'state': 'Massachusetts'},
        {'city': 'Spencer', 'state': 'Massachusetts'},
        {'city': 'Spokane', 'state': 'Washington'},
        {'city': 'Spotswood', 'state': 'New Jersey'},
        {'city': 'Spring', 'state': 'Texas'},
        {'city': 'Spring Creek', 'state': 'Nevada'},
        {'city': 'Spring Hill', 'state': 'Tennessee'},
        {'city': 'Spring Hill', 'state': 'Florida'},
        {'city': 'Spring Lake', 'state': 'North Carolina'},
        {'city': 'Spring Lake Park', 'state': 'Minnesota'},
        {'city': 'Spring Valley', 'state': 'California'},
        {'city': 'Spring Valley', 'state': 'Nevada'},
        {'city': 'Spring Valley', 'state': 'New York'},
        {'city': 'Springboro', 'state': 'Ohio'},
        {'city': 'Springdale', 'state': 'Ohio'},
        {'city': 'Springdale', 'state': 'New Jersey'},
        {'city': 'Springdale', 'state': 'Arkansas'},
        {'city': 'Springfield', 'state': 'Florida'},
        {'city': 'Springfield', 'state': 'Missouri'},
        {'city': 'Springfield', 'state': 'Massachusetts'},
        {'city': 'Springfield', 'state': 'Illinois'},
        {'city': 'Springfield', 'state': 'New Jersey'},
        {'city': 'Springfield', 'state': 'Ohio'},
        {'city': 'Springfield', 'state': 'Oregon'},
        {'city': 'Springfield', 'state': 'Pennsylvania'},
        {'city': 'Springfield', 'state': 'Vermont'},
        {'city': 'Springfield', 'state': 'Tennessee'},
        {'city': 'Springfield', 'state': 'Virginia'},
        {'city': 'Springville', 'state': 'Utah'},
        {'city': 'St. Albans', 'state': 'Vermont'},
        {'city': 'St. Albans', 'state': 'West Virginia'},
        {'city': 'St. Andrews', 'state': 'South Carolina'},
        {'city': 'St. Ann', 'state': 'Missouri'},
        {'city': 'St. Anthony', 'state': 'Minnesota'},
        {'city': 'St. Augustine', 'state': 'Florida'},
        {'city': 'St. Charles', 'state': 'Missouri'},
        {'city': 'St. Charles', 'state': 'Illinois'},
        {'city': 'St. Charles', 'state': 'Maryland'},
        {'city': 'St. Clair Shores', 'state': 'Michigan'},
        {'city': 'St. Cloud', 'state': 'Minnesota'},
        {'city': 'St. Cloud', 'state': 'Florida'},
        {'city': 'St. Dennis', 'state': 'Kentucky'},
        {'city': 'St. Francis', 'state': 'Wisconsin'},
        {'city': 'St. George', 'state': 'Utah'},
        {'city': 'St. Helens', 'state': 'Oregon'},
        {'city': 'St. James', 'state': 'New York'},
        {'city': 'St. John', 'state': 'Indiana'},
        {'city': 'St. John', 'state': 'Missouri'},
        {'city': 'St. Johns', 'state': 'Michigan'},
        {'city': 'St. Johnsbury', 'state': 'Vermont'},
        {'city': 'St. Johnsbury', 'state': 'Vermont'},
        {'city': 'St. Joseph', 'state': 'Michigan'},
        {'city': 'St. Joseph', 'state': 'Missouri'},
        {'city': 'St. Louis', 'state': 'Missouri'},
        {'city': 'St. Louis Park', 'state': 'Minnesota'},
        {'city': 'St. Martin', 'state': 'Mississippi'},
        {'city': 'St. Martinville', 'state': 'Louisiana'},
        {'city': 'St. Marys', 'state': 'Georgia'},
        {'city': 'St. Marys', 'state': 'Pennsylvania'},
        {'city': 'St. Marys', 'state': 'Ohio'},
        {'city': 'St. Matthews', 'state': 'Kentucky'},
        {'city': 'St. Michael', 'state': 'Minnesota'},
        {'city': 'St. Paul', 'state': 'Minnesota'},
        {'city': 'St. Pete Beach', 'state': 'Florida'},
        {'city': 'St. Peter', 'state': 'Minnesota'},
        {'city': 'St. Peters', 'state': 'Missouri'},
        {'city': 'St. Petersburg', 'state': 'Florida'},
        {'city': 'St. Rose', 'state': 'Louisiana'},
        {'city': 'St. Simons', 'state': 'Georgia'},
        {'city': 'St. Stephens', 'state': 'North Carolina'},
        {'city': 'Stafford', 'state': 'Texas'},
        {'city': 'Stafford', 'state': 'Connecticut'},
        {'city': 'Stamford', 'state': 'Connecticut'},
        {'city': 'Stamford', 'state': 'Connecticut'},
        {'city': 'Standish', 'state': 'Maine'},
        {'city': 'Stanford', 'state': 'California'},
        {'city': 'Stanton', 'state': 'California'},
        {'city': 'Starkville', 'state': 'Mississippi'},
        {'city': 'State College', 'state': 'Pennsylvania'},
        {'city': 'Statesboro', 'state': 'Georgia'},
        {'city': 'Statesville', 'state': 'North Carolina'},
        {'city': 'Staunton', 'state': 'Virginia'},
        {'city': 'Stayton', 'state': 'Oregon'},
        {'city': 'Steamboat Springs', 'state': 'Colorado'},
        {'city': 'Steger', 'state': 'Illinois'},
        {'city': 'Steilacoom', 'state': 'Washington'},
        {'city': 'Stephenville', 'state': 'Texas'},
        {'city': 'Sterling', 'state': 'Illinois'},
        {'city': 'Sterling', 'state': 'Massachusetts'},
        {'city': 'Sterling', 'state': 'Colorado'},
        {'city': 'Sterling Heights', 'state': 'Michigan'},
        {'city': 'Steubenville', 'state': 'Ohio'},
        {'city': 'Stevens Point', 'state': 'Wisconsin'},
        {'city': 'Stickney', 'state': 'Illinois'},
        {'city': 'Stillwater', 'state': 'Minnesota'},
        {'city': 'Stillwater', 'state': 'New York'},
        {'city': 'Stillwater', 'state': 'Oklahoma'},
        {'city': 'Stockbridge', 'state': 'Georgia'},
        {'city': 'Stockton', 'state': 'California'},
        {'city': 'Stone Mountain', 'state': 'Georgia'},
        {'city': 'Stonegate', 'state': 'Colorado'},
        {'city': 'Stoneham', 'state': 'Massachusetts'},
        {'city': 'Stoneham', 'state': 'Massachusetts'},
        {'city': 'Stonington', 'state': 'Connecticut'},
        {'city': 'Stony Brook', 'state': 'New York'},
        {'city': 'Stony Point', 'state': 'New York'},
        {'city': 'Stony Point', 'state': 'New York'},
        {'city': 'Storm Lake', 'state': 'Iowa'},
        {'city': 'Storrs', 'state': 'Connecticut'},
        {'city': 'Stoughton', 'state': 'Massachusetts'},
        {'city': 'Stoughton', 'state': 'Wisconsin'},
        {'city': 'Stow', 'state': 'Ohio'},
        {'city': 'Stowe Township', 'state': 'Pennsylvania'},
        {'city': 'Stratford', 'state': 'New Jersey'},
        {'city': 'Stratford', 'state': 'Connecticut'},
        {'city': 'Stratford', 'state': 'Connecticut'},
        {'city': 'Stratham', 'state': 'New Hampshire'},
        {'city': 'Strathmore', 'state': 'New Jersey'},
        {'city': 'Stratmoor', 'state': 'Colorado'},
        {'city': 'Streamwood', 'state': 'Illinois'},
        {'city': 'Streator', 'state': 'Illinois'},
        {'city': 'Streetsboro', 'state': 'Ohio'},
        {'city': 'Strongsville', 'state': 'Ohio'},
        {'city': 'Struthers', 'state': 'Ohio'},
        {'city': 'Stuart', 'state': 'Florida'},
        {'city': 'Stuarts Draft', 'state': 'Virginia'},
        {'city': 'Sturbridge', 'state': 'Massachusetts'},
        {'city': 'Sturgeon Bay', 'state': 'Wisconsin'},
        {'city': 'Sturgis', 'state': 'South Dakota'},
        {'city': 'Sturgis', 'state': 'Michigan'},
        {'city': 'Stuttgart', 'state': 'Arkansas'},
        {'city': 'Suamico', 'state': 'Wisconsin'},
        {'city': 'Succasunna-Kenvil', 'state': 'New Jersey'},
        {'city': 'Sudbury', 'state': 'Massachusetts'},
        {'city': 'Sudley', 'state': 'Virginia'},
        {'city': 'Suffern', 'state': 'New York'},
        {'city': 'Suffield', 'state': 'Connecticut'},
        {'city': 'Suffolk', 'state': 'Virginia'},
        {'city': 'Sugar Hill', 'state': 'Georgia'},
        {'city': 'Sugar Land', 'state': 'Texas'},
        {'city': 'Sugarmill Woods', 'state': 'Florida'},
        {'city': 'Suisun City', 'state': 'California'},
        {'city': 'Suitland-Silver Hill', 'state': 'Maryland'},
        {'city': 'Sullivan', 'state': 'Missouri'},
        {'city': 'Sullivan', 'state': 'New York'},
        {'city': 'Sulphur', 'state': 'Louisiana'},
        {'city': 'Sulphur Springs', 'state': 'Texas'},
        {'city': 'Summerfield', 'state': 'North Carolina'},
        {'city': 'Summerville', 'state': 'South Carolina'},
        {'city': 'Summit', 'state': 'New Jersey'},
        {'city': 'Summit', 'state': 'Washington'},
        {'city': 'Summit', 'state': 'Illinois'},
        {'city': 'Summit Park', 'state': 'Utah'},
        {'city': 'Sumner', 'state': 'Washington'},
        {'city': 'Sumter', 'state': 'South Carolina'},
        {'city': 'Sun City', 'state': 'California'},
        {'city': 'Sun City', 'state': 'Arizona'},
        {'city': 'Sun City West', 'state': 'Arizona'},
        {'city': 'Sun Lakes', 'state': 'Arizona'},
        {'city': 'Sun Prairie', 'state': 'Wisconsin'},
        {'city': 'Sun Valley', 'state': 'Nevada'},
        {'city': 'Sunbury', 'state': 'Pennsylvania'},
        {'city': 'Sunland Park', 'state': 'New Mexico'},
        {'city': 'Sunny Isles Beach', 'state': 'Florida'},
        {'city': 'Sunnyside', 'state': 'Oregon'},
        {'city': 'Sunnyside', 'state': 'Washington'},
        {'city': 'Sunnyvale', 'state': 'California'},
        {'city': 'Sunrise', 'state': 'Florida'},
        {'city': 'Sunrise Manor', 'state': 'Nevada'},
        {'city': 'Sunset', 'state': 'Florida'},
        {'city': 'Sunset Hills', 'state': 'Missouri'},
        {'city': 'Superior', 'state': 'Colorado'},
        {'city': 'Superior', 'state': 'Wisconsin'},
        {'city': 'Surprise', 'state': 'Arizona'},
        {'city': 'Susanville', 'state': 'California'},
        {'city': 'Sussex', 'state': 'Wisconsin'},
        {'city': 'Sutherlin', 'state': 'Oregon'},
        {'city': 'Sutton', 'state': 'Massachusetts'},
        {'city': 'Suwanee', 'state': 'Georgia'},
        {'city': 'Swainsboro', 'state': 'Georgia'},
        {'city': 'Swampscott', 'state': 'Massachusetts'},
        {'city': 'Swampscott', 'state': 'Massachusetts'},
        {'city': 'Swansea', 'state': 'Illinois'},
        {'city': 'Swansea', 'state': 'Massachusetts'},
        {'city': 'Swanton', 'state': 'Vermont'},
        {'city': 'Swanzey', 'state': 'New Hampshire'},
        {'city': 'Swarthmore', 'state': 'Pennsylvania'},
        {'city': 'Sweden', 'state': 'New York'},
        {'city': 'Sweet Home', 'state': 'Oregon'},
        {'city': 'Sweetwater', 'state': 'Texas'},
        {'city': 'Sweetwater', 'state': 'Florida'},
        {'city': 'Swissvale', 'state': 'Pennsylvania'},
        {'city': 'Sycamore', 'state': 'Illinois'},
        {'city': 'Sylacauga', 'state': 'Alabama'},
        {'city': 'Sylvania', 'state': 'Ohio'},
        {'city': 'Syosset', 'state': 'New York'},
        {'city': 'Syracuse', 'state': 'New York'},
        {'city': 'Syracuse', 'state': 'Utah'},
        {'city': 'Tacoma', 'state': 'Washington'},
        {'city': 'Taft', 'state': 'California'},
        {'city': 'Tahlequah', 'state': 'Oklahoma'},
        {'city': 'Takoma Park', 'state': 'Maryland'},
        {'city': 'Talladega', 'state': 'Alabama'},
        {'city': 'Tallahassee', 'state': 'Florida'},
        {'city': 'Tallmadge', 'state': 'Ohio'},
        {'city': 'Tallulah', 'state': 'Louisiana'},
        {'city': 'Tamalpais-Homestead Valley', 'state': 'California'},
        {'city': 'Tamaqua', 'state': 'Pennsylvania'},
        {'city': 'Tamarac', 'state': 'Florida'},
        {'city': 'Tamiami', 'state': 'Florida'},
        {'city': 'Tampa', 'state': 'Florida'},
        {'city': 'Tanque Verde', 'state': 'Arizona'},
        {'city': 'Tappan', 'state': 'New York'},
        {'city': 'Tarboro', 'state': 'North Carolina'},
        {'city': 'Tarpon Springs', 'state': 'Florida'},
        {'city': 'Tarrant', 'state': 'Alabama'},
        {'city': 'Tarrytown', 'state': 'New York'},
        {'city': 'Taunton', 'state': 'Massachusetts'},
        {'city': 'Tavares', 'state': 'Florida'},
        {'city': 'Taylor', 'state': 'Michigan'},
        {'city': 'Taylor', 'state': 'Pennsylvania'},
        {'city': 'Taylor', 'state': 'Texas'},
        {'city': 'Taylor Mill', 'state': 'Kentucky'},
        {'city': 'Taylors', 'state': 'South Carolina'},
        {'city': 'Taylorsville', 'state': 'Utah'},
        {'city': 'Taylorville', 'state': 'Illinois'},
        {'city': 'Teaneck', 'state': 'New Jersey'},
        {'city': 'Teays Valley', 'state': 'West Virginia'},
        {'city': 'Tecumseh', 'state': 'Michigan'},
        {'city': 'Tecumseh', 'state': 'Oklahoma'},
        {'city': 'Tehachapi', 'state': 'California'},
        {'city': 'Tell City', 'state': 'Indiana'},
        {'city': 'Temecula', 'state': 'California'},
        {'city': 'Tempe', 'state': 'Arizona'},
        {'city': 'Temperance', 'state': 'Michigan'},
        {'city': 'Temple', 'state': 'Texas'},
        {'city': 'Temple City', 'state': 'California'},
        {'city': 'Temple Hills', 'state': 'Maryland'},
        {'city': 'Temple Terrace', 'state': 'Florida'},
        {'city': 'Templeton', 'state': 'Massachusetts'},
        {'city': 'Tenafly', 'state': 'New Jersey'},
        {'city': 'Terrace Heights', 'state': 'Washington'},
        {'city': 'Terre Haute', 'state': 'Indiana'},
        {'city': 'Terrell', 'state': 'Texas'},
        {'city': 'Terrytown', 'state': 'Louisiana'},
        {'city': 'Terryville', 'state': 'New York'},
        {'city': 'Tewksbury', 'state': 'Massachusetts'},
        {'city': 'Texarkana', 'state': 'Texas'},
        {'city': 'Texarkana', 'state': 'Arkansas'},
        {'city': 'Texas City', 'state': 'Texas'},
        {'city': 'The Colony', 'state': 'Texas'},
        {'city': 'The Crossings', 'state': 'Florida'},
        {'city': 'The Hammocks', 'state': 'Florida'},
        {'city': 'The Pinery', 'state': 'Colorado'},
        {'city': 'The Village', 'state': 'Oklahoma'},
        {'city': 'The Villages', 'state': 'Florida'},
        {'city': 'The Woodlands', 'state': 'Texas'},
        {'city': 'Theodore', 'state': 'Alabama'},
        {'city': 'Thermalito', 'state': 'California'},
        {'city': 'Thibodaux', 'state': 'Louisiana'},
        {'city': 'Thief River Falls', 'state': 'Minnesota'},
        {'city': 'Thomaston', 'state': 'Georgia'},
        {'city': 'Thomaston', 'state': 'Connecticut'},
        {'city': 'Thomasville', 'state': 'Georgia'},
        {'city': 'Thomasville', 'state': 'North Carolina'},
        {'city': 'Thompson', 'state': 'New York'},
        {'city': 'Thompson', 'state': 'Connecticut'},
        {'city': 'Thompsonville', 'state': 'Connecticut'},
        {'city': 'Thomson', 'state': 'Georgia'},
        {'city': 'Thonotosassa', 'state': 'Florida'},
        {'city': 'Thornton', 'state': 'Colorado'},
        {'city': 'Thousand Oaks', 'state': 'California'},
        {'city': 'Three Lakes', 'state': 'Florida'},
        {'city': 'Three Rivers', 'state': 'Michigan'},
        {'city': 'Tiburon', 'state': 'California'},
        {'city': 'Tiffin', 'state': 'Ohio'},
        {'city': 'Tifton', 'state': 'Georgia'},
        {'city': 'Tigard', 'state': 'Oregon'},
        {'city': 'Tillmans Corner', 'state': 'Alabama'},
        {'city': 'Timberlake', 'state': 'Virginia'},
        {'city': 'Timberlane', 'state': 'Louisiana'},
        {'city': 'Tinley Park', 'state': 'Illinois'},
        {'city': 'Tinton Falls', 'state': 'New Jersey'},
        {'city': 'Tipp City', 'state': 'Ohio'},
        {'city': 'Titusville', 'state': 'Pennsylvania'},
        {'city': 'Titusville', 'state': 'Florida'},
        {'city': 'Tiverton', 'state': 'Rhode Island'},
        {'city': 'Tiverton', 'state': 'Rhode Island'},
        {'city': 'Toccoa', 'state': 'Georgia'},
        {'city': 'Toledo', 'state': 'Ohio'},
        {'city': 'Tolland', 'state': 'Connecticut'},
        {'city': 'Tomah', 'state': 'Wisconsin'},
        {'city': 'Tomball', 'state': 'Texas'},
        {'city': 'Toms River', 'state': 'New Jersey'},
        {'city': 'Tonawanda', 'state': 'New York'},
        {'city': 'Tonawanda', 'state': 'New York'},
        {'city': 'Tonawanda', 'state': 'New York'},
        {'city': 'Tooele', 'state': 'Utah'},
        {'city': 'Topeka', 'state': 'Kansas'},
        {'city': 'Toppenish', 'state': 'Washington'},
        {'city': 'Topsfield', 'state': 'Massachusetts'},
        {'city': 'Topsham', 'state': 'Maine'},
        {'city': 'Topsham', 'state': 'Maine'},
        {'city': 'Torrance', 'state': 'California'},
        {'city': 'Torrington', 'state': 'Connecticut'},
        {'city': 'Torrington', 'state': 'Connecticut'},
        {'city': 'Totowa', 'state': 'New Jersey'},
        {'city': "Town 'n' Country", 'state': 'Florida'},
        {'city': 'Town and Country', 'state': 'Missouri'},
        {'city': 'Townsend', 'state': 'Massachusetts'},
        {'city': 'Towson', 'state': 'Maryland'},
        {'city': 'Tracy', 'state': 'California'},
        {'city': 'Traverse City', 'state': 'Michigan'},
        {'city': 'Travilah', 'state': 'Maryland'},
        {'city': 'Treasure Island', 'state': 'Florida'},
        {'city': 'Trenton', 'state': 'Michigan'},
        {'city': 'Trenton', 'state': 'Missouri'},
        {'city': 'Trenton', 'state': 'New Jersey'},
        {'city': 'Trenton', 'state': 'Ohio'},
        {'city': 'Trinidad', 'state': 'Colorado'},
        {'city': 'Trinity', 'state': 'North Carolina'},
        {'city': 'Trooper', 'state': 'Pennsylvania'},
        {'city': 'Trophy Club', 'state': 'Texas'},
        {'city': 'Trotwood', 'state': 'Ohio'},
        {'city': 'Troutdale', 'state': 'Oregon'},
        {'city': 'Troy', 'state': 'Ohio'},
        {'city': 'Troy', 'state': 'New York'},
        {'city': 'Troy', 'state': 'Missouri'},
        {'city': 'Troy', 'state': 'Michigan'},
        {'city': 'Troy', 'state': 'Alabama'},
        {'city': 'Troy', 'state': 'Illinois'},
        {'city': 'Truckee', 'state': 'California'},
        {'city': 'Trumann', 'state': 'Arkansas'},
        {'city': 'Trumbull', 'state': 'Connecticut'},
        {'city': 'Trumbull', 'state': 'Connecticut'},
        {'city': 'Trussville', 'state': 'Alabama'},
        {'city': 'Truth or Consequences', 'state': 'New Mexico'},
        {'city': 'Tualatin', 'state': 'Oregon'},
        {'city': 'Tuba City', 'state': 'Arizona'},
        {'city': 'Tuckahoe', 'state': 'New York'},
        {'city': 'Tuckahoe', 'state': 'Virginia'},
        {'city': 'Tucker', 'state': 'Georgia'},
        {'city': 'Tucson', 'state': 'Arizona'},
        {'city': 'Tucson Estates', 'state': 'Arizona'},
        {'city': 'Tukwila', 'state': 'Washington'},
        {'city': 'Tulare', 'state': 'California'},
        {'city': 'Tullahoma', 'state': 'Tennessee'},
        {'city': 'Tulsa', 'state': 'Oklahoma'},
        {'city': 'Tumwater', 'state': 'Washington'},
        {'city': 'Tupelo', 'state': 'Mississippi'},
        {'city': 'Turlock', 'state': 'California'},
        {'city': 'Turtle Creek', 'state': 'Pennsylvania'},
        {'city': 'Tuscaloosa', 'state': 'Alabama'},
        {'city': 'Tuscumbia', 'state': 'Alabama'},
        {'city': 'Tuskegee', 'state': 'Alabama'},
        {'city': 'Tustin', 'state': 'California'},
        {'city': 'Tustin Foothills', 'state': 'California'},
        {'city': 'Twentynine Palms', 'state': 'California'},
        {'city': 'Twentynine Palms Base', 'state': 'California'},
        {'city': 'Twin Falls', 'state': 'Idaho'},
        {'city': 'Twin Lakes', 'state': 'Colorado'},
        {'city': 'Twin Rivers', 'state': 'New Jersey'},
        {'city': 'Twinsburg', 'state': 'Ohio'},
        {'city': 'Two Rivers', 'state': 'Wisconsin'},
        {'city': 'Tyler', 'state': 'Texas'},
        {'city': 'Tyngsborough', 'state': 'Massachusetts'},
        {'city': 'Tysons Corner', 'state': 'Virginia'},
        {'city': 'Ukiah', 'state': 'California'},
        {'city': 'Ulster', 'state': 'New York'},
        {'city': 'Union', 'state': 'South Carolina'},
        {'city': 'Union', 'state': 'New York'},
        {'city': 'Union', 'state': 'Missouri'},
        {'city': 'Union', 'state': 'New Jersey'},
        {'city': 'Union Beach', 'state': 'New Jersey'},
        {'city': 'Union City', 'state': 'New Jersey'},
        {'city': 'Union City', 'state': 'California'},
        {'city': 'Union City', 'state': 'Georgia'},
        {'city': 'Union City', 'state': 'Tennessee'},
        {'city': 'Union Hill-Novelty Hill', 'state': 'Washington'},
        {'city': 'Union Park', 'state': 'Florida'},
        {'city': 'Uniondale', 'state': 'New York'},
        {'city': 'Uniontown', 'state': 'Pennsylvania'},
        {'city': 'Universal City', 'state': 'Texas'},
        {'city': 'University', 'state': 'Florida'},
        {'city': 'University City', 'state': 'Missouri'},
        {'city': 'University Heights', 'state': 'Ohio'},
        {'city': 'University Park', 'state': 'Texas'},
        {'city': 'University Park', 'state': 'Florida'},
        {'city': 'University Park', 'state': 'Illinois'},
        {'city': 'University Place', 'state': 'Washington'},
        {'city': 'Upland', 'state': 'California'},
        {'city': 'Upper Arlington', 'state': 'Ohio'},
        {'city': 'Upper Grand Lagoon', 'state': 'Florida'},
        {'city': 'Upper Providence Township', 'state': 'Pennsylvania'},
        {'city': 'Upper Saddle River', 'state': 'New Jersey'},
        {'city': 'Upper Sandusky', 'state': 'Ohio'},
        {'city': 'Upper St. Clair', 'state': 'Pennsylvania'},
        {'city': 'Urbana', 'state': 'Ohio'},
        {'city': 'Urbana', 'state': 'Illinois'},
        {'city': 'Urbandale', 'state': 'Iowa'},
        {'city': 'Utica', 'state': 'New York'},
        {'city': 'Uvalde', 'state': 'Texas'},
        {'city': 'Uxbridge', 'state': 'Massachusetts'},
        {'city': 'Vacaville', 'state': 'California'},
        {'city': 'Vadnais Heights', 'state': 'Minnesota'},
        {'city': 'Valdosta', 'state': 'Georgia'},
        {'city': 'Valinda', 'state': 'California'},
        {'city': 'Valle Vista', 'state': 'California'},
        {'city': 'Vallejo', 'state': 'California'},
        {'city': 'Valley', 'state': 'Alabama'},
        {'city': 'Valley Center', 'state': 'California'},
        {'city': 'Valley City', 'state': 'North Dakota'},
        {'city': 'Valley Cottage', 'state': 'New York'},
        {'city': 'Valley Falls', 'state': 'Rhode Island'},
        {'city': 'Valley Park', 'state': 'Missouri'},
        {'city': 'Valley Station', 'state': 'Kentucky'},
        {'city': 'Valley Stream', 'state': 'New York'},
        {'city': 'Valparaiso', 'state': 'Indiana'},
        {'city': 'Valparaiso', 'state': 'Florida'},
        {'city': 'Valrico', 'state': 'Florida'},
        {'city': 'Van Buren', 'state': 'Arkansas'},
        {'city': 'Van Buren', 'state': 'New York'},
        {'city': 'Van Wert', 'state': 'Ohio'},
        {'city': 'Vancouver', 'state': 'Washington'},
        {'city': 'Vandalia', 'state': 'Ohio'},
        {'city': 'Vandalia', 'state': 'Illinois'},
        {'city': 'Vandenberg AFB', 'state': 'California'},
        {'city': 'Vashon', 'state': 'Washington'},
        {'city': 'Venice', 'state': 'Florida'},
        {'city': 'Venice Gardens', 'state': 'Florida'},
        {'city': 'Ventnor City', 'state': 'New Jersey'},
        {'city': 'Veradale', 'state': 'Washington'},
        {'city': 'Vermilion', 'state': 'Ohio'},
        {'city': 'Vermillion', 'state': 'South Dakota'},
        {'city': 'Vernal', 'state': 'Utah'},
        {'city': 'Vernon', 'state': 'Wisconsin'},
        {'city': 'Vernon', 'state': 'Texas'},
        {'city': 'Vernon', 'state': 'Connecticut'},
        {'city': 'Vernon Hills', 'state': 'Illinois'},
        {'city': 'Vero Beach', 'state': 'Florida'},
        {'city': 'Vero Beach South', 'state': 'Florida'},
        {'city': 'Verona', 'state': 'New Jersey'},
        {'city': 'Verona', 'state': 'New York'},
        {'city': 'Verona', 'state': 'Wisconsin'},
        {'city': 'Versailles', 'state': 'Kentucky'},
        {'city': 'Vestal', 'state': 'New York'},
        {'city': 'Vestavia Hills', 'state': 'Alabama'},
        {'city': 'Vicksburg', 'state': 'Mississippi'},
        {'city': 'Victor', 'state': 'New York'},
        {'city': 'Victoria', 'state': 'Texas'},
        {'city': 'Victorville', 'state': 'California'},
        {'city': 'Vidalia', 'state': 'Georgia'},
        {'city': 'Vidor', 'state': 'Texas'},
        {'city': 'Vienna', 'state': 'Virginia'},
        {'city': 'Vienna', 'state': 'West Virginia'},
        {'city': 'View Park-Windsor Hills', 'state': 'California'},
        {'city': 'Villa Hills', 'state': 'Kentucky'},
        {'city': 'Villa Park', 'state': 'Illinois'},
        {'city': 'Village Green-Green Ridge', 'state': 'Pennsylvania'},
        {'city': 'Village Park', 'state': 'Hawaii'},
        {'city': 'Village St. George', 'state': 'Louisiana'},
        {'city': 'Villas', 'state': 'Florida'},
        {'city': 'Villas', 'state': 'New Jersey'},
        {'city': 'Ville Platte', 'state': 'Louisiana'},
        {'city': 'Vincennes', 'state': 'Indiana'},
        {'city': 'Vincent', 'state': 'California'},
        {'city': 'Vineland', 'state': 'New Jersey'},
        {'city': 'Vineyard', 'state': 'California'},
        {'city': 'Vinings', 'state': 'Georgia'},
        {'city': 'Vinita', 'state': 'Oklahoma'},
        {'city': 'Vinton', 'state': 'Virginia'},
        {'city': 'Violet', 'state': 'Louisiana'},
        {'city': 'Virginia', 'state': 'Minnesota'},
        {'city': 'Virginia Beach', 'state': 'Virginia'},
        {'city': 'Visalia', 'state': 'California'},
        {'city': 'Vista', 'state': 'California'},
        {'city': 'Volney', 'state': 'New York'},
        {'city': 'Wabash', 'state': 'Indiana'},
        {'city': 'Waco', 'state': 'Texas'},
        {'city': 'Waconia', 'state': 'Minnesota'},
        {'city': 'Wade Hampton', 'state': 'South Carolina'},
        {'city': 'Wading River', 'state': 'New York'},
        {'city': 'Wadsworth', 'state': 'Ohio'},
        {'city': 'Waggaman', 'state': 'Louisiana'},
        {'city': 'Wagoner', 'state': 'Oklahoma'},
        {'city': 'Wahiawa', 'state': 'Hawaii'},
        {'city': 'Wahpeton', 'state': 'North Dakota'},
        {'city': 'Waianae', 'state': 'Hawaii'},
        {'city': 'Waihee-Waiehu', 'state': 'Hawaii'},
        {'city': 'Wailuku', 'state': 'Hawaii'},
        {'city': 'Waimalu', 'state': 'Hawaii'},
        {'city': 'Waimea', 'state': 'Hawaii'},
        {'city': 'Waipahu', 'state': 'Hawaii'},
        {'city': 'Waipio', 'state': 'Hawaii'},
        {'city': 'Waite Park', 'state': 'Minnesota'},
        {'city': 'Wake Forest', 'state': 'North Carolina'},
        {'city': 'Wakefield', 'state': 'Massachusetts'},
        {'city': 'Wakefield', 'state': 'Massachusetts'},
        {'city': 'Wakefield-Peacedale', 'state': 'Rhode Island'},
        {'city': 'Walden', 'state': 'New York'},
        {'city': 'Waldorf', 'state': 'Maryland'},
        {'city': 'Waldwick', 'state': 'New Jersey'},
        {'city': 'Walker', 'state': 'Michigan'},
        {'city': 'Walker Mill', 'state': 'Maryland'},
        {'city': 'Walla Walla', 'state': 'Washington'},
        {'city': 'Walled Lake', 'state': 'Michigan'},
        {'city': 'Waller', 'state': 'Washington'},
        {'city': 'Wallingford', 'state': 'Connecticut'},
        {'city': 'Wallingford Center', 'state': 'Connecticut'},
        {'city': 'Wallington', 'state': 'New Jersey'},
        {'city': 'Wallkill', 'state': 'New York'},
        {'city': 'Walnut', 'state': 'California'},
        {'city': 'Walnut Creek', 'state': 'California'},
        {'city': 'Walnut Grove', 'state': 'Washington'},
        {'city': 'Walnut Park', 'state': 'California'},
        {'city': 'Walpole', 'state': 'Massachusetts'},
        {'city': 'Waltham', 'state': 'Massachusetts'},
        {'city': 'Walworth', 'state': 'New York'},
        {'city': 'Wanaque', 'state': 'New Jersey'},
        {'city': 'Wantagh', 'state': 'New York'},
        {'city': 'Wapakoneta', 'state': 'Ohio'},
        {'city': 'Wappinger', 'state': 'New York'},
        {'city': 'Ware', 'state': 'Massachusetts'},
        {'city': 'Ware', 'state': 'Massachusetts'},
        {'city': 'Wareham', 'state': 'Massachusetts'},
        {'city': 'Warner Robins', 'state': 'Georgia'},
        {'city': 'Warr Acres', 'state': 'Oklahoma'},
        {'city': 'Warren', 'state': 'Pennsylvania'},
        {'city': 'Warren', 'state': 'Rhode Island'},
        {'city': 'Warren', 'state': 'Arkansas'},
        {'city': 'Warren', 'state': 'Michigan'},
        {'city': 'Warren', 'state': 'Ohio'},
        {'city': 'Warrensburg', 'state': 'Missouri'},
        {'city': 'Warrensville Heights', 'state': 'Ohio'},
        {'city': 'Warrenton', 'state': 'Virginia'},
        {'city': 'Warrenville', 'state': 'Illinois'},
        {'city': 'Warrington', 'state': 'Florida'},
        {'city': 'Warsaw', 'state': 'Indiana'},
        {'city': 'Warwick', 'state': 'New York'},
        {'city': 'Warwick', 'state': 'New York'},
        {'city': 'Warwick', 'state': 'Rhode Island'},
        {'city': 'Wasco', 'state': 'California'},
        {'city': 'Waseca', 'state': 'Minnesota'},
        {'city': 'Washington', 'state': 'Missouri'},
        {'city': 'Washington', 'state': 'New Jersey'},
        {'city': 'Washington', 'state': 'Ohio'},
        {'city': 'Washington', 'state': 'North Carolina'},
        {'city': 'Washington', 'state': 'Indiana'},
        {'city': 'Washington', 'state': 'Iowa'},
        {'city': 'Washington', 'state': 'Illinois'},
        {'city': 'Washington', 'state': 'Pennsylvania'},
        {'city': 'Washington', 'state': 'Utah'},
        {'city': 'Washington', 'state': 'Wisconsin'},
        {'city': 'Washington', 'state': 'Washington'},
        {'city': 'Washington Terrace', 'state': 'Utah'},
        {'city': 'Washington Township', 'state': 'New Jersey'},
        {'city': 'Washougal', 'state': 'Washington'},
        {'city': 'Watauga', 'state': 'Texas'},
        {'city': 'Waterboro', 'state': 'Maine'},
        {'city': 'Waterbury', 'state': 'Connecticut'},
        {'city': 'Waterbury', 'state': 'Connecticut'},
        {'city': 'Waterford', 'state': 'Connecticut'},
        {'city': 'Waterford', 'state': 'California'},
        {'city': 'Waterford', 'state': 'Michigan'},
        {'city': 'Waterford', 'state': 'New York'},
        {'city': 'Waterloo', 'state': 'New York'},
        {'city': 'Waterloo', 'state': 'Illinois'},
        {'city': 'Waterloo', 'state': 'Iowa'},
        {'city': 'Watertown', 'state': 'Connecticut'},
        {'city': 'Watertown', 'state': 'New York'},
        {'city': 'Watertown', 'state': 'Massachusetts'},
        {'city': 'Watertown', 'state': 'South Dakota'},
        {'city': 'Watertown', 'state': 'Wisconsin'},
        {'city': 'Waterville', 'state': 'Maine'},
        {'city': 'Watervliet', 'state': 'New York'},
        {'city': 'Watsonville', 'state': 'California'},
        {'city': 'Wauconda', 'state': 'Illinois'},
        {'city': 'Waukegan', 'state': 'Illinois'},
        {'city': 'Waukesha', 'state': 'Wisconsin'},
        {'city': 'Waukesha', 'state': 'Wisconsin'},
        {'city': 'Waunakee', 'state': 'Wisconsin'},
        {'city': 'Waupun', 'state': 'Wisconsin'},
        {'city': 'Wausau', 'state': 'Wisconsin'},
        {'city': 'Wauseon', 'state': 'Ohio'},
        {'city': 'Wauwatosa', 'state': 'Wisconsin'},
        {'city': 'Waveland', 'state': 'Mississippi'},
        {'city': 'Waverly', 'state': 'Michigan'},
        {'city': 'Waverly', 'state': 'Iowa'},
        {'city': 'Wawarsing', 'state': 'New York'},
        {'city': 'Wawayanda', 'state': 'New York'},
        {'city': 'Waxahachie', 'state': 'Texas'},
        {'city': 'Waycross', 'state': 'Georgia'},
        {'city': 'Wayland', 'state': 'Massachusetts'},
        {'city': 'Wayne', 'state': 'Michigan'},
        {'city': 'Wayne', 'state': 'New Jersey'},
        {'city': 'Waynesboro', 'state': 'Virginia'},
        {'city': 'Waynesboro', 'state': 'Pennsylvania'},
        {'city': 'Waynesville', 'state': 'North Carolina'},
        {'city': 'Weare', 'state': 'New Hampshire'},
        {'city': 'Weatherford', 'state': 'Oklahoma'},
        {'city': 'Weatherford', 'state': 'Texas'},
        {'city': 'Webb City', 'state': 'Missouri'},
        {'city': 'Webster', 'state': 'Massachusetts'},
        {'city': 'Webster', 'state': 'Massachusetts'},
        {'city': 'Webster', 'state': 'New York'},
        {'city': 'Webster', 'state': 'Texas'},
        {'city': 'Webster City', 'state': 'Iowa'},
        {'city': 'Webster Groves', 'state': 'Missouri'},
        {'city': 'Weddington', 'state': 'North Carolina'},
        {'city': 'Weigelstown', 'state': 'Pennsylvania'},
        {'city': 'Weirton', 'state': 'West Virginia'},
        {'city': 'Wekiwa Springs', 'state': 'Florida'},
        {'city': 'Welby', 'state': 'Colorado'},
        {'city': 'Welcome', 'state': 'South Carolina'},
        {'city': 'Wellesley', 'state': 'Massachusetts'},
        {'city': 'Wellesley', 'state': 'Massachusetts'},
        {'city': 'Wellington', 'state': 'Florida'},
        {'city': 'Wellington', 'state': 'Kansas'},
        {'city': 'Wells', 'state': 'Maine'},
        {'city': 'Wells Branch', 'state': 'Texas'},
        {'city': 'Wellston', 'state': 'Ohio'},
        {'city': 'Wellsville', 'state': 'New York'},
        {'city': 'Wenatchee', 'state': 'Washington'},
        {'city': 'Wentzville', 'state': 'Missouri'},
        {'city': 'Weslaco', 'state': 'Texas'},
        {'city': 'West Allis', 'state': 'Wisconsin'},
        {'city': 'West and East Lealman', 'state': 'Florida'},
        {'city': 'West Athens', 'state': 'California'},
        {'city': 'West Babylon', 'state': 'New York'},
        {'city': 'West Bend', 'state': 'Wisconsin'},
        {'city': 'West Bloomfield Township', 'state': 'Michigan'},
        {'city': 'West Boylston', 'state': 'Massachusetts'},
        {'city': 'West Bridgewater', 'state': 'Massachusetts'},
        {'city': 'West Caldwell', 'state': 'New Jersey'},
        {'city': 'West Carrollton City', 'state': 'Ohio'},
        {'city': 'West Carson', 'state': 'California'},
        {'city': 'West Chester', 'state': 'Pennsylvania'},
        {'city': 'West Chicago', 'state': 'Illinois'},
        {'city': 'West Columbia', 'state': 'South Carolina'},
        {'city': 'West Covina', 'state': 'California'},
        {'city': 'West Des Moines', 'state': 'Iowa'},
        {'city': 'West Fargo', 'state': 'North Dakota'},
        {'city': 'West Frankfort', 'state': 'Illinois'},
        {'city': 'West Freehold', 'state': 'New Jersey'},
        {'city': 'West Gate', 'state': 'Virginia'},
        {'city': 'West Glens Falls', 'state': 'New York'},
        {'city': 'West Goshen', 'state': 'Pennsylvania'},
        {'city': 'West Hartford', 'state': 'Connecticut'},
        {'city': 'West Hartford', 'state': 'Connecticut'},
        {'city': 'West Hattiesburg', 'state': 'Mississippi'},
        {'city': 'West Haven', 'state': 'Connecticut'},
        {'city': 'West Haven', 'state': 'Connecticut'},
        {'city': 'West Haven-Sylvan', 'state': 'Oregon'},
        {'city': 'West Haverstraw', 'state': 'New York'},
        {'city': 'West Helena', 'state': 'Arkansas'},
        {'city': 'West Hempstead', 'state': 'New York'},
        {'city': 'West Hollywood', 'state': 'California'},
        {'city': 'West Islip', 'state': 'New York'},
        {'city': 'West Jordan', 'state': 'Utah'},
        {'city': 'West Lafayette', 'state': 'Indiana'},
        {'city': 'West Lake Stevens', 'state': 'Washington'},
        {'city': 'West Linn', 'state': 'Oregon'},
        {'city': 'West Little River', 'state': 'Florida'},
        {'city': 'West Livingston', 'state': 'Texas'},
        {'city': 'West Long Branch', 'state': 'New Jersey'},
        {'city': 'West Melbourne', 'state': 'Florida'},
        {'city': 'West Memphis', 'state': 'Arkansas'},
        {'city': 'West Mifflin', 'state': 'Pennsylvania'},
        {'city': 'West Milford', 'state': 'New Jersey'},
        {'city': 'West Modesto', 'state': 'California'},
        {'city': 'West Monroe', 'state': 'Louisiana'},
        {'city': 'West New York', 'state': 'New Jersey'},
        {'city': 'West Norriton', 'state': 'Pennsylvania'},
        {'city': 'West Odessa', 'state': 'Texas'},
        {'city': 'West Orange', 'state': 'New Jersey'},
        {'city': 'West Palm Beach', 'state': 'Florida'},
        {'city': 'West Paterson', 'state': 'New Jersey'},
        {'city': 'West Pensacola', 'state': 'Florida'},
        {'city': 'West Perrine', 'state': 'Florida'},
        {'city': 'West Plains', 'state': 'Missouri'},
        {'city': 'West Point', 'state': 'New York'},
        {'city': 'West Point', 'state': 'Mississippi'},
        {'city': 'West Point', 'state': 'Utah'},
        {'city': 'West Puente Valley', 'state': 'California'},
        {'city': 'West Richland', 'state': 'Washington'},
        {'city': 'West Sacramento', 'state': 'California'},
        {'city': 'West Seneca', 'state': 'New York'},
        {'city': 'West Seneca', 'state': 'New York'},
        {'city': 'West Slope', 'state': 'Oregon'},
        {'city': 'West Springfield', 'state': 'Virginia'},
        {'city': 'West Springfield', 'state': 'Massachusetts'},
        {'city': 'West Springfield', 'state': 'Massachusetts'},
        {'city': 'West St. Paul', 'state': 'Minnesota'},
        {'city': 'West University Place', 'state': 'Texas'},
        {'city': 'West Valley', 'state': 'Washington'},
        {'city': 'West Valley City', 'state': 'Utah'},
        {'city': 'West Vero Corridor', 'state': 'Florida'},
        {'city': 'West View', 'state': 'Pennsylvania'},
        {'city': 'West Warwick', 'state': 'Rhode Island'},
        {'city': 'West Warwick', 'state': 'Rhode Island'},
        {'city': 'West Whittier-Los Nietos', 'state': 'California'},
        {'city': 'West Yarmouth', 'state': 'Massachusetts'},
        {'city': 'Westborough', 'state': 'Massachusetts'},
        {'city': 'Westbrook', 'state': 'Connecticut'},
        {'city': 'Westbrook', 'state': 'Maine'},
        {'city': 'Westbury', 'state': 'New York'},
        {'city': 'Westchase', 'state': 'Florida'},
        {'city': 'Westchester', 'state': 'Florida'},
        {'city': 'Westchester', 'state': 'Illinois'},
        {'city': 'Westerly', 'state': 'Rhode Island'},
        {'city': 'Westerly', 'state': 'Rhode Island'},
        {'city': 'Western Springs', 'state': 'Illinois'},
        {'city': 'Westerville', 'state': 'Ohio'},
        {'city': 'Westfield', 'state': 'Massachusetts'},
        {'city': 'Westfield', 'state': 'New Jersey'},
        {'city': 'Westfield', 'state': 'Indiana'},
        {'city': 'Westford', 'state': 'Massachusetts'},
        {'city': 'Westgate-Belvedere Homes', 'state': 'Florida'},
        {'city': 'Westlake', 'state': 'Ohio'},
        {'city': 'Westlake Village', 'state': 'California'},
        {'city': 'Westland', 'state': 'Michigan'},
        {'city': 'Westmere', 'state': 'New York'},
        {'city': 'Westminster', 'state': 'Massachusetts'},
        {'city': 'Westminster', 'state': 'California'},
        {'city': 'Westminster', 'state': 'Colorado'},
        {'city': 'Westminster', 'state': 'Maryland'},
        {'city': 'Westmont', 'state': 'Illinois'},
        {'city': 'Westmont', 'state': 'California'},
        {'city': 'Westmoreland', 'state': 'New York'},
        {'city': 'Weston', 'state': 'Connecticut'},
        {'city': 'Weston', 'state': 'Florida'},
        {'city': 'Weston', 'state': 'Massachusetts'},
        {'city': 'Weston', 'state': 'Wisconsin'},
        {'city': 'Westport', 'state': 'Massachusetts'},
        {'city': 'Westport', 'state': 'Connecticut'},
        {'city': 'Westport', 'state': 'Connecticut'},
        {'city': 'Westview', 'state': 'Florida'},
        {'city': 'Westwego', 'state': 'Louisiana'},
        {'city': 'Westwood', 'state': 'Massachusetts'},
        {'city': 'Westwood', 'state': 'Michigan'},
        {'city': 'Westwood', 'state': 'New Jersey'},
        {'city': 'Westwood Lakes', 'state': 'Florida'},
        {'city': 'Wethersfield', 'state': 'Connecticut'},
        {'city': 'Wethersfield', 'state': 'Connecticut'},
        {'city': 'Weymouth', 'state': 'Massachusetts'},
        {'city': 'Weymouth', 'state': 'Massachusetts'},
        {'city': 'Wharton', 'state': 'New Jersey'},
        {'city': 'Wharton', 'state': 'Texas'},
        {'city': 'Wheat Ridge', 'state': 'Colorado'},
        {'city': 'Wheatfield', 'state': 'New York'},
        {'city': 'Wheaton', 'state': 'Illinois'},
        {'city': 'Wheaton-Glenmont', 'state': 'Maryland'},
        {'city': 'Wheelersburg', 'state': 'Ohio'},
        {'city': 'Wheeling', 'state': 'Illinois'},
        {'city': 'Wheeling', 'state': 'West Virginia'},
        {'city': 'White Bear Lake', 'state': 'Minnesota'},
        {'city': 'White Center', 'state': 'Washington'},
        {'city': 'White Horse', 'state': 'New Jersey'},
        {'city': 'White House', 'state': 'Tennessee'},
        {'city': 'White Marsh', 'state': 'Maryland'},
        {'city': 'White Meadow Lake', 'state': 'New Jersey'},
        {'city': 'White Oak', 'state': 'Ohio'},
        {'city': 'White Oak', 'state': 'Maryland'},
        {'city': 'White Oak', 'state': 'Pennsylvania'},
        {'city': 'White Plains', 'state': 'New York'},
        {'city': 'White Rock', 'state': 'New Mexico'},
        {'city': 'White Settlement', 'state': 'Texas'},
        {'city': 'Whitefish Bay', 'state': 'Wisconsin'},
        {'city': 'Whitehall', 'state': 'Pennsylvania'},
        {'city': 'Whitehall', 'state': 'Ohio'},
        {'city': 'Whitestown', 'state': 'New York'},
        {'city': 'Whitewater', 'state': 'Wisconsin'},
        {'city': 'Whitinsville', 'state': 'Massachusetts'},
        {'city': 'Whitman', 'state': 'Massachusetts'},
        {'city': 'Whitmore Lake', 'state': 'Michigan'},
        {'city': 'Whitney', 'state': 'Nevada'},
        {'city': 'Whittier', 'state': 'California'},
        {'city': 'Wichita', 'state': 'Kansas'},
        {'city': 'Wichita Falls', 'state': 'Texas'},
        {'city': 'Wickliffe', 'state': 'Ohio'},
        {'city': 'Wilbraham', 'state': 'Massachusetts'},
        {'city': 'Wildomar', 'state': 'California'},
        {'city': 'Wildwood', 'state': 'Missouri'},
        {'city': 'Wilkes-Barre', 'state': 'Pennsylvania'},
        {'city': 'Wilkins Township', 'state': 'Pennsylvania'},
        {'city': 'Wilkinsburg', 'state': 'Pennsylvania'},
        {'city': 'Willard', 'state': 'Ohio'},
        {'city': 'Williamsburg', 'state': 'Florida'},
        {'city': 'Williamsburg', 'state': 'Virginia'},
        {'city': 'Williamson', 'state': 'New York'},
        {'city': 'Williamsport', 'state': 'Pennsylvania'},
        {'city': 'Williamstown', 'state': 'New Jersey'},
        {'city': 'Williamstown', 'state': 'Massachusetts'},
        {'city': 'Willimantic', 'state': 'Connecticut'},
        {'city': 'Williston', 'state': 'North Dakota'},
        {'city': 'Williston', 'state': 'Vermont'},
        {'city': 'Williston Park', 'state': 'New York'},
        {'city': 'Willmar', 'state': 'Minnesota'},
        {'city': 'Willoughby', 'state': 'Ohio'},
        {'city': 'Willoughby Hills', 'state': 'Ohio'},
        {'city': 'Willow Grove', 'state': 'Pennsylvania'},
        {'city': 'Willow Street', 'state': 'Pennsylvania'},
        {'city': 'Willowbrook', 'state': 'California'},
        {'city': 'Willowbrook', 'state': 'Illinois'},
        {'city': 'Willowick', 'state': 'Ohio'},
        {'city': 'Willows', 'state': 'California'},
        {'city': 'Wilmette', 'state': 'Illinois'},
        {'city': 'Wilmington', 'state': 'Massachusetts'},
        {'city': 'Wilmington', 'state': 'Delaware'},
        {'city': 'Wilmington', 'state': 'Ohio'},
        {'city': 'Wilmington', 'state': 'North Carolina'},
        {'city': 'Wilmington', 'state': 'Massachusetts'},
        {'city': 'Wilmington Island', 'state': 'Georgia'},
        {'city': 'Wilmington Manor', 'state': 'Delaware'},
        {'city': 'Wilna', 'state': 'New York'},
        {'city': 'Wilson', 'state': 'North Carolina'},
        {'city': 'Wilson', 'state': 'Pennsylvania'},
        {'city': 'Wilsonville', 'state': 'Oregon'},
        {'city': 'Wilton', 'state': 'New York'},
        {'city': 'Wilton', 'state': 'Connecticut'},
        {'city': 'Wilton Manors', 'state': 'Florida'},
        {'city': 'Winchendon', 'state': 'Massachusetts'},
        {'city': 'Winchester', 'state': 'Massachusetts'},
        {'city': 'Winchester', 'state': 'Nevada'},
        {'city': 'Winchester', 'state': 'Connecticut'},
        {'city': 'Winchester', 'state': 'Massachusetts'},
        {'city': 'Winchester', 'state': 'Kentucky'},
        {'city': 'Winchester', 'state': 'Tennessee'},
        {'city': 'Winchester', 'state': 'Virginia'},
        {'city': 'Windemere', 'state': 'Texas'},
        {'city': 'Winder', 'state': 'Georgia'},
        {'city': 'Windham', 'state': 'Maine'},
        {'city': 'Windham', 'state': 'Connecticut'},
        {'city': 'Windham', 'state': 'New Hampshire'},
        {'city': 'Windsor', 'state': 'New York'},
        {'city': 'Windsor', 'state': 'Connecticut'},
        {'city': 'Windsor', 'state': 'Colorado'},
        {'city': 'Windsor', 'state': 'California'},
        {'city': 'Windsor Locks', 'state': 'Connecticut'},
        {'city': 'Windsor Locks', 'state': 'Connecticut'},
        {'city': 'Winfield', 'state': 'Kansas'},
        {'city': 'Winfield', 'state': 'Illinois'},
        {'city': 'Winnemucca', 'state': 'Nevada'},
        {'city': 'Winnetka', 'state': 'Illinois'},
        {'city': 'Winona', 'state': 'Minnesota'},
        {'city': 'Winooski', 'state': 'Vermont'},
        {'city': 'Winslow', 'state': 'Maine'},
        {'city': 'Winslow', 'state': 'Maine'},
        {'city': 'Winslow', 'state': 'Arizona'},
        {'city': 'Winsted', 'state': 'Connecticut'},
        {'city': 'Winston', 'state': 'Florida'},
        {'city': 'Winston-Salem', 'state': 'North Carolina'},
        {'city': 'Winter Garden', 'state': 'Florida'},
        {'city': 'Winter Gardens', 'state': 'California'},
        {'city': 'Winter Haven', 'state': 'Florida'},
        {'city': 'Winter Park', 'state': 'Florida'},
        {'city': 'Winter Springs', 'state': 'Florida'},
        {'city': 'Winters', 'state': 'California'},
        {'city': 'Winthrop', 'state': 'Maine'},
        {'city': 'Winthrop', 'state': 'Massachusetts'},
        {'city': 'Winthrop', 'state': 'Massachusetts'},
        {'city': 'Winthrop Harbor', 'state': 'Illinois'},
        {'city': 'Winton', 'state': 'California'},
        {'city': 'Wisconsin Rapids', 'state': 'Wisconsin'},
        {'city': 'Wixom', 'state': 'Michigan'},
        {'city': 'Woburn', 'state': 'Massachusetts'},
        {'city': 'Wolcott', 'state': 'Connecticut'},
        {'city': 'Wolf Trap', 'state': 'Virginia'},
        {'city': 'Wolfeboro', 'state': 'New Hampshire'},
        {'city': 'Wonder Lake', 'state': 'Illinois'},
        {'city': 'Wood Dale', 'state': 'Illinois'},
        {'city': 'Wood River', 'state': 'Illinois'},
        {'city': 'Wood-Ridge', 'state': 'New Jersey'},
        {'city': 'Woodbourne-Hyde Park', 'state': 'Ohio'},
        {'city': 'Woodbridge', 'state': 'New Jersey'},
        {'city': 'Woodbridge', 'state': 'Connecticut'},
        {'city': 'Woodbridge', 'state': 'Virginia'},
        {'city': 'Woodburn', 'state': 'Oregon'},
        {'city': 'Woodbury', 'state': 'Connecticut'},
        {'city': 'Woodbury', 'state': 'New Jersey'},
        {'city': 'Woodbury', 'state': 'Minnesota'},
        {'city': 'Woodbury', 'state': 'New York'},
        {'city': 'Woodbury', 'state': 'New York'},
        {'city': 'Woodcrest', 'state': 'California'},
        {'city': 'Woodfield', 'state': 'South Carolina'},
        {'city': 'Woodhaven', 'state': 'Michigan'},
        {'city': 'Woodinville', 'state': 'Washington'},
        {'city': 'Woodlake', 'state': 'California'},
        {'city': 'Woodland', 'state': 'California'},
        {'city': 'Woodland Park', 'state': 'Colorado'},
        {'city': 'Woodlawn', 'state': 'Maryland'},
        {'city': 'Woodlawn', 'state': 'Maryland'},
        {'city': 'Woodlyn', 'state': 'Pennsylvania'},
        {'city': 'Woodmere', 'state': 'Louisiana'},
        {'city': 'Woodmere', 'state': 'New York'},
        {'city': 'Woodmoor', 'state': 'Colorado'},
        {'city': 'Woodmore', 'state': 'Maryland'},
        {'city': 'Woodridge', 'state': 'Illinois'},
        {'city': 'Woods Cross', 'state': 'Utah'},
        {'city': 'Woodstock', 'state': 'Georgia'},
        {'city': 'Woodstock', 'state': 'Illinois'},
        {'city': 'Woodstock', 'state': 'Connecticut'},
        {'city': 'Woodstock', 'state': 'New York'},
        {'city': 'Woodward', 'state': 'Oklahoma'},
        {'city': 'Woodway', 'state': 'Texas'},
        {'city': 'Woonsocket', 'state': 'Rhode Island'},
        {'city': 'Wooster', 'state': 'Ohio'},
        {'city': 'Worcester', 'state': 'Massachusetts'},
        {'city': 'Worth', 'state': 'Illinois'},
        {'city': 'Worthington', 'state': 'Minnesota'},
        {'city': 'Worthington', 'state': 'Ohio'},
        {'city': 'Wrentham', 'state': 'Massachusetts'},
        {'city': 'Wright', 'state': 'Florida'},
        {'city': 'Wright-Patterson AFB', 'state': 'Ohio'},
        {'city': 'Wyandanch', 'state': 'New York'},
        {'city': 'Wyandotte', 'state': 'Michigan'},
        {'city': 'Wyckoff', 'state': 'New Jersey'},
        {'city': 'Wylie', 'state': 'Texas'},
        {'city': 'Wyndham', 'state': 'Virginia'},
        {'city': 'Wynne', 'state': 'Arkansas'},
        {'city': 'Wyoming', 'state': 'Ohio'},
        {'city': 'Wyoming', 'state': 'Michigan'},
        {'city': 'Wyomissing', 'state': 'Pennsylvania'},
        {'city': 'Wytheville', 'state': 'Virginia'},
        {'city': 'Xenia', 'state': 'Ohio'},
        {'city': 'Yakima', 'state': 'Washington'},
        {'city': 'Yankton', 'state': 'South Dakota'},
        {'city': 'Yardville-Groveville', 'state': 'New Jersey'},
        {'city': 'Yarmouth', 'state': 'Maine'},
        {'city': 'Yarmouth', 'state': 'Massachusetts'},
        {'city': 'Yazoo City', 'state': 'Mississippi'},
        {'city': 'Yeadon', 'state': 'Pennsylvania'},
        {'city': 'Yeehaw Junction', 'state': 'Florida'},
        {'city': 'Yonkers', 'state': 'New York'},
        {'city': 'Yorba Linda', 'state': 'California'},
        {'city': 'York', 'state': 'Nebraska'},
        {'city': 'York', 'state': 'Maine'},
        {'city': 'York', 'state': 'Pennsylvania'},
        {'city': 'York', 'state': 'South Carolina'},
        {'city': 'Yorketown', 'state': 'New Jersey'},
        {'city': 'Yorkshire', 'state': 'Virginia'},
        {'city': 'Yorktown', 'state': 'New York'},
        {'city': 'Yorktown Heights', 'state': 'New York'},
        {'city': 'Yorkville', 'state': 'Illinois'},
        {'city': 'Youngstown', 'state': 'Ohio'},
        {'city': 'Ypsilanti', 'state': 'Michigan'},
        {'city': 'Yreka', 'state': 'California'},
        {'city': 'Yuba City', 'state': 'California'},
        {'city': 'Yucaipa', 'state': 'California'},
        {'city': 'Yucca Valley', 'state': 'California'},
        {'city': 'Yukon', 'state': 'Oklahoma'},
        {'city': 'Yulee', 'state': 'Florida'},
        {'city': 'Yuma', 'state': 'Arizona'},
        {'city': 'Zachary', 'state': 'Louisiana'},
        {'city': 'Zanesville', 'state': 'Ohio'},
        {'city': 'Zephyrhills', 'state': 'Florida'},
        {'city': 'Zion', 'state': 'Illinois'},
        {'city': 'Zionsville', 'state': 'Indiana'},
        {'city': 'Zuni Pueblo', 'state': 'New Mexico'},
    ];



