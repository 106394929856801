import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { defConstant, errConstant } from '@core/data/constant-data';
import {
  AuthService,
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
} from '@core/services';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  loginForm: FormGroup;
  ref: DynamicDialogRef;
  showCancel: boolean = false;

  @Input() opLogin: any;
  @Input() tournamentId: string;

  isLoginClick: boolean;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastService,
    private authService: AuthService,
    private httpService: HttpService,
    private dialogService: DialogService,
    private helperService: HelperService,
    private emitterService: EmitterService,
    private angularFireAuth: AngularFireAuth,
    public loginRef: DynamicDialogRef
  ) {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(this.helperService.emailPattern()),
        ],
      ],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  showResetPassword(): void {
    this.authService.isShowReset$.next(true);
  }

  login() {
    this.toastrService.infoMsg({
      title: 'Login',
      message: defConstant.pleaseWait,
    });
    this.isLoginClick = true;

    const { email, password } = this.loginForm.value;

    if (!this.loginForm.valid) {
      this.isLoginClick = false;
      this.toastrService.errorMsg({ message: errConstant.anErrOccurred });
      return;
    }

    this.angularFireAuth
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        this.httpService.me().subscribe(
          (res) => {
            // Set to log in
            this.authService.setLoggedIn(true);

            // Set UID
            sessionStorage.setItem('uid', res.uid);

            // Emit data
            this.emitterService.meEmit(res);

            // Set Role
            sessionStorage.setItem('role', res.userType);
            

            // Show login message
            this.toastrService.successMsg({
              message: defConstant.welcomeSuccess,
            });

            this.isLoginClick = false;

            // this.opLogin.hide();
            this.close();
          },
          (err) => {
            // Logout firebase
            this.angularFireAuth.signOut();

            this.toastrService.errorMsg({
              message: errConstant.noRecord,
            });

            this.isLoginClick = false;
          }
        );
      })
      .catch((err) => {
        this.isLoginClick = false;
        this.toastrService.errorMsg({ message: err.message });
      });
  }

  goToRegistration(): void {
    this.router.navigate([
      `/public-registration/${this.tournamentId}/registration`,
    ]);
  }

  close() {
    this.loginRef.close();
  }

  async checkUserRole() {
    const me = await this.httpService.me().toPromise();
    if (me?.userType === 'coach') {
      this.goToRegistration();
    } else {
      this.loginRef.close(1);
    }
  }
}
