import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserType } from '@core/models/user-type';
import { CityState, getStates, State } from '@core/utils/city-state';
import { MustMatchValidator } from '@core/utils/validator';

// SERVICES
import {
  ToastService,
  HttpService,
  HelperService,
  EmitterService,
} from '@services/index';
import { CityStateLookupService } from '@services/city-state-lookup.service';

// PRIME
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

// ENV
import { environment } from '@env/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  cityStates: CityState[];
  user: any;
  isCoachRole: boolean = false;

  userTypes: UserType[] = [
    { name: 'Tournament Director', type: 'director' },
    { name: 'Coach', type: 'coach' },
  ];

  timeZones: any[] = [
    { name: 'GMT', type: '0:00' },
    { name: 'UTC', type: '0:00' },
    { name: 'ETC (GMT+1)', type: '+1:00' },
    { name: 'EET (GMT+2)', type: '+2:00' },
    { name: 'EAT (GMT+3)', type: '+3:00' },
    { name: 'MET (GMT+3)', type: '+3:30' },
    { name: 'NET (GMT+4)', type: '+4:00' },
    { name: 'PLT (GMT+5)', type: '+5:00' },
    { name: 'IST (GMT+5)', type: '+5:30' },
    { name: 'BST (GMT+6)', type: '+6:00' },
    { name: 'VST (GMT+7)', type: '+7:00' },
    { name: 'CTT (GMT+8)', type: '+8:00' },
    { name: 'JST (GMT+9)', type: '+9:00' },
    { name: 'ACT (GMT+9)', type: '+9:30' },
    { name: 'AET (GMT+10)', type: '+10:00' },
    { name: 'SST (GMT+11)', type: '+11:00' },
    { name: 'NST (GMT+12)', type: '+12:00' },
    { name: 'MIT (GMT-11)', type: '-11:00' },
    { name: 'HST (GMT-10)', type: '-10:00' },
    { name: 'AST (GMT-9)', type: '-9:00' },
    { name: 'PST (GMT-8)', type: '-8:00' },
    { name: 'PNT (GMT-7)', type: '-7:00' },
    { name: 'MST (GMT-7)', type: '-7:00' },
    { name: 'CST (GMT-6)', type: '-6:00' },
    { name: 'EST (GMT-5)', type: '-5:00' },
    { name: 'IET (GMT-5)', type: '-5:00' },
    { name: 'PRT (GMT-4)', type: '-4:00' },
    { name: 'CNT (GMT-3)', type: '-3:30' },
    { name: 'AGT (GMT-3)', type: '-3:00' },
    { name: 'BET (GMT-3)', type: '-3:00' },
    { name: 'CAT (GMT-1)', type: '-1:00' },
  ];

  states: State[] = getStates();

  errorMsg: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private cityStateLookupService: CityStateLookupService,
    private toastrService: ToastService,
    private httpService: HttpService,
    private helperService: HelperService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private emitterService: EmitterService
  ) {
    this.signupForm = this.formBuilder.group(
      {
        firstName: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
          ],
        ],
        lastName: [
          '',
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(50),
          ],
        ],
        email: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(80),
            Validators.pattern(this.helperService.emailPattern()),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(80),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(80),
          ],
        ],
        city: ['', [Validators.required, Validators.maxLength(255)]],
        state: ['', [Validators.required, Validators.maxLength(255)]],
        organizationName: [
          '',
          [Validators.required, Validators.maxLength(255)],
        ],
        phoneNumber: [
          '',
          [Validators.required, Validators.pattern(/^[0-9\-]*$/)],
        ],
        cityState: [''],
        zipCode: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(5),
          ],
        ],
        timeZone: ['', null],
        website: ['', null],
        userType: ['', [Validators.required]],
        howManyTourneys: [0, null],
        howDidYouFindOut: ['', null],
      },
      {
        validator: MustMatchValidator('password', 'confirmPassword'),
      }
    );
    this.isCoachRole = this.config.data?.coach;
    console.log(this.isCoachRole);
    // if (this.isCoachRole) {
    //   console.log('???');
    //   this.signupForm.get('userType').setValue({
    //     name: 'Coach',
    //     type: 'coach',
    //   });
    // }
  }

  ngOnInit(): void {}

  searchCityState(evt) {
    this.cityStateLookupService.getResults(evt.query).then((data) => {
      this.cityStates = data;
    });
  }

  signUp(): void {
    const body = this.signupForm.value;
    console.log('THIS IS BODYYYYY', body);

    body.phoneNumber = `+1${body.phoneNumber}`;
    body.cityState = `${body.city}, ${body.state}`;
    // if (body.userType) body.userType = body.userType.type;

    this.errorMsg = '';

    this.httpService
      .post({
        url: environment.api.register,
        payload: body,
      })
      .subscribe(
        (_) => {
          this.toastrService.successMsg({ message: 'Successfully signup.' });
          this.emitterService.loginEmit(false);
          this.ref.close();
        },
        (e) => {
          this.errorMsg = e.error.message;
          this.toastrService.errorMsg({ message: e.error.message });
        }
      );
  }

  checkUser() {
    this.user = this.signupForm.get('userType')?.value.type;
    console.log('typeeeeeee' + this.signupForm.get('userType')?.value.type);
  }

  cancel(): void {
    this.ref.close();
  }

  loginEvent() {
    this.ref.close('login');
  }
}
