<div class="p-grid">
  <div class="p-col-12">
    <app-public-header></app-public-header>
  </div>
  <div class="p-col-2"></div>

  <div class="p-col-8 margin-10">
    <div class="detail-header">
      <div>
        <div class="line1">Games for</div>
        <div class="line2">{{ division?.name }}</div>
        <!-- <div class="line3">{{ division?.gender }}</div> -->
        <div class="line4">
          Registered Teams: {{ division?.registeredTeams }}
        </div>
      </div>
    </div>

    <div class="sched-header text-white">SCHEDULES</div>
    <div *ngIf="!isFetching; else alreadyFetched" class="text-center p-3">
      <p-progressSpinner
        [style]="{ width: '40px', height: '40px' }"
      ></p-progressSpinner>
    </div>

    <ng-template #alreadyFetched>
    
      <div *ngIf="games; else noData">
  
        <div class="py-2 px-4" *ngFor="let schedule of schedules">
          <div class="sched-details-wrapper">
            <div class="sched-date TheBoldFont-Bold">
              {{ schedule.date | date: "EEEE, MMMM d, y" }}
            </div>
            <div *ngFor="let timeslot of schedule.timeslots">
              <div class="TheBoldFont-Bold sched-time">{{ timeslot.time }}</div>
                <div *ngFor="let game of timeslot.games; let i = index">
                  <div class="scheduled-container flex">
                    <div class="sched-court flex flex-center">
                      {{ game.court?.name }}
                    </div>
                    <div class="flex flex-column flex-grow1">
                      <div class="flex py-2">
                        <div *ngIf="game.team1 && game.team2; else bracketGame" class="px-3">
                          <label class="px-2">{{
                            game.team1?.name
                          }}</label>
                          vs
                          <label class="px-2">{{
                            game.team2?.name
                          }}</label>
                        </div>
                        <ng-template #bracketGame>
                          <div class="text-center">
                            <label class="px-2">{{
                              game.name
                            }}</label>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
  
        <!--
        <div class="py-2 px-4" *ngFor="let game of games; let i = index">
          
          <div class="sched-details-wrapper">
            <div class="sched-date TheBoldFont-Bold">
              {{ game.date | date: "EEEE, MMMM d, y" }}
            </div>
            <div class="sched-time">{{ game?.timeslot }}</div>
            <div class="scheduled-container flex">
              <div class="sched-court flex flex-center">
                {{ game.court?.name }}
              </div>
  
              <div class="flex flex-column flex-grow1">
                <div class="flex py-2">
                  <div *ngIf="game.team1 && game.team2; else bracketGame" class="px-3">
                    <label class="TheBoldFont-Bold px-2">{{
                      game.team1?.name
                    }}</label>
                    vs
                    <label class="TheBoldFont-Bold px-2">{{
                      game.team2?.name
                    }}</label>
                  </div>
                  <ng-template #bracketGame>
                    <div class="text-center">
                      <label class="TheBoldFont-Bold px-2">{{
                        game.name
                      }}</label>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      -->
      </div>
  
      <ng-template #noData>
        <div class="sched-details p-grid p-3">
          <div class="p-col-3"></div>
  
          <div class="p-col-6 text-center">
            <label class="TheBoldFont-Bold fs-3">No schedules for this division yet.</label>
          </div>
  
          <div class="p-col-3"></div>
        </div>
      </ng-template>
    </ng-template>

    <br/>
    <br/>
  </div>

</div>
