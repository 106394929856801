import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmTeamComponent } from '@components/modal/confirm-team/confirm-team.component';
import { MessageComponent } from '@components/modal/message/message.component';
import { defConstant } from '@core/data/constant-data';
import { Player } from '@core/models/players';
import { Teams } from '@core/models/teams';
import { HttpService, ToastService } from '@core/services';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

const VIEWABLE_SIZE = 15;

@Component({
  selector: 'app-add-existing-player',
  templateUrl: './add-existing-player.component.html',
  styleUrls: ['./add-existing-player.component.scss'],
})
export class AddExistingPlayerComponent implements OnInit {
  headers: any = [
    {
      id: 'fullName',
      value: 'Player',
      textStyles: 'text-black3 text-bold1',
    },
    {
      id: 'jerseyNumber1',
      value: 'Jersey #',
    },
    {
      id: 'position',
      value: 'Position',
    },
    {
      id: 'height',
      value: 'Height',
    },
  ];

  data = null;
  players: Teams[] = [];
  meta: any = {};
  q: string;
  fetching: boolean = false;
  showConfirm: boolean = false;
  selectedPlayer: Player;

  constructor(
    public ref: DynamicDialogRef,
    private httpService: HttpService,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private publicTournament: PublicTournamentsService,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;

    this.initPlayers();
  }

  pageChanged(event: any) {}

  async initPlayers() {
    const me = await this.httpService.me().toPromise();
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.coachPlayers}?userId=${me.id}`,
          payload: [],
        })
        .toPromise();

      this.players = list;
      console.log(list);
      // this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }

  async getPlayers(action: string, meta: any) {
    const me = await this.httpService.me().toPromise();
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.coachPlayers}?userId=${me.id}`,
          payload: [],
        })
        .toPromise();

      this.players = list.items;
      this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }

  previous() {
    this.getPlayers('previous', this.meta);
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    this.getPlayers('index', meta);
  }

  next() {
    this.getPlayers('next', this.meta);
  }

  search() {
    let meta: any = Object.assign({}, this.meta);
    meta.search = this.q;
    this.getPlayers('search', meta);
  }

  async selectPlayer(event: any) {
    let existingPlayers = this.data?.result?.existingPlayers;
    this.selectedPlayer = event.data;
    this.selectedPlayer.team = this.data?.result?.team;

    if (
      existingPlayers.find((player) => player?.id == this.selectedPlayer?.id)
    ) {
      return this.toastService.errorMsg({
        message: 'This player was already added.',
      });
    }

    this.ref.close(this.selectedPlayer);
  }

  close(): void {
    this.ref.close(null);
  }
}
