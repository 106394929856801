import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PaymentAccount } from '@core/models/payment-account';

import { ToastService } from '@core/services';

@Component({
  selector: 'add-payment-account-modal',
  templateUrl: './add-payment-account-modal.component.html',
  styleUrls: ['./add-payment-account-modal.component.scss'],
})

export class AddPaymentAccountModalComponent implements OnInit {

  account: PaymentAccount;
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {

    this.account = {
      defaultAccount: false,
      vendor: this.config.data.vendor,
      organization: this.config.data.organization
    }
  }

  cancel() {
    this.ref.close();
  }

  onSubmit() {
    
    if (!this.account.name) {
      this.toastService.errorMsg({ message: 'Name cannot be empty.' });
      return;
    }

    if (!this.account.email) {
      this.toastService.errorMsg({ message: 'Email cannot be empty.' });
      return;
    }

    this.ref.close(this.account);
  }
}
