import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GamePeriod } from '@core/models/game-period';
import { Games } from '@core/models/games';
import { Tournament } from '@core/models/tournament';
import { HttpService, EmitterService } from '@core/services';
import { environment } from '@env/environment';
import { rejects } from 'assert';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Player } from '@core/models/players';
import { Teams } from '@core/models/teams';
import { TeamsService } from '@core/services/teams.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { User } from '@core/models/user';
import { VenueService } from '@core/services/venue.service';
import { DivisionService } from '@core/services/division.service';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-public-team-games',
  templateUrl: './public-team-games.component.html',
  styleUrls: ['./public-team-games.component.scss'],
})
export class PublicTeamGamesComponent implements OnInit {
  teamId: number;
  team: Teams;

  teamName: string;
  games: Games[];
  game: Games;

  doesHaveGames: boolean = false;
  dateAvailable: Schedule[] = [];
  postSchedule: boolean;

  initLength: number;

  tournament: Tournament;
  tournamentId: any;
  tournamentDates: any[] = [];
  pools: any[] = [];
  gamePeriods: GamePeriod[] = [];
  allGames: any[] = [];
  games$ = new BehaviorSubject<Games[]>([]);
  divisionId: any;
  division: any;
  players: Player;
  listPlayers: Player[];
  user: User;
  venues: any[] = null;
  venuesDuplicate: any[] = [];

  isLoading = true;

  logoUrl: string = '';
  isReview: boolean = false;

  primaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  hasPotentialVenues = false;
  potentialVenues: any[] = [];
  sureVenues: any[] = [];

  innerWidth: any;

  constructor(
    private _http: HttpService,
    private route: ActivatedRoute,
    private router: Router,
    private emitterService: EmitterService,
    private publicTournamentService: PublicTournamentsService,
    private teamsService: TeamsService,
    private venueService: VenueService,
    private divisionService: DivisionService
  ) {
    this.emitterService.meEmitter.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.primaryColor = JSON.parse(sessionStorage.getItem('org'))?.primaryColor;
    this.route.paramMap.subscribe(async (params) => {
      this.tournamentId = params.get('tournamentId');
      this.teamId = parseInt(params.get('teamId'));
      this.divisionId = parseInt(params.get('divisionId'));
      this.teamName = params.get('teamName');
      this.isReview = this.publicTournamentService.isReview;
      this.isReview = JSON.parse(localStorage.getItem('isReview')) ?? false;

      this.team = await this.teamsService.getTeam(this.teamId);

      this.logoUrl = `${environment.api.baseUrl}/teams/logo/${this.team.photo}`;

      await this.getTournamentByParams(this.tournamentId);
      await this.getDivision();
     
      this.getGamesWithPeriods();
    });
    // this.subscribeToPotentialVenues();
    this.subscribeToSureGameVenues();
  }
 async getDivision()  {
  this.division =  await this.divisionService.getDivision(this.divisionId);
  }
  // tournament dates
  enumarateDays(fromDate, toDate): void {
    const day1 = moment(toDate);
    const day2 = moment(fromDate);
    const result = [moment({ ...day2 })];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment({ ...day2 }));
    }
    result.map((x) => {
      this.dateAvailable.push({
        label: x.format('dddd, MMMM D, YYYY'),
        value: x.format('MM/DD/YYYY'),
      });
    });
    this.initLength = result.map((x) => x.format('MM/DD/YYYY')).length;
  }

  async getVenues() {
    if (!this.venues) {
      this.venues = await this.venueService
        .getVenues(this.tournamentId, this.tournament.organization.id)
        .toPromise();

      // Filter the venues that are associated with the games
      let arr = this.venues.map((venue, index) => {
        return this.allGames.some((game) => game.court.venueId === venue.id)
          ? venue
          : null;
      });

      // let venueArr = [];
      // // Filter the venues that are associated with the games
      // this.venues.map((venue, index) => {
      //   let game = this.allGames.find(
      //     (game) => game.court.venueId === venue.id
      //   );

      //   //Only add game venues with assigned team
      //   if (game?.team1 && game?.team2) {
      //     venueArr.push(venue);
      //   }
      // });

      // Using Promise.all to await multiple promises
      Promise.all(arr).then((filteredVenues) => {
        // Remove null values from the array and assign it to this.venues
        this.venues = filteredVenues.filter((venue) => venue !== null);
      });

      this.isLoading = false;

      this.venuesDuplicate = this.venues;
      this.venueService.venuesDuplicate$.next(this.venues);
    }
  }

  getGamesWithPeriods() {
    this._http
      .get({
        url: `${environment.api.assignedGames}?tournamentId=${this.tournamentId}&teamId=${this.teamId}`,
        payload: [],
      })
      .toPromise()
      .then((games) => {
        this.games = games;
        let arr = games.map((game, index) => {
          return this._http
            .get({
              url: `${
                environment.api.gamesPeriods
              }?gameId=${game.id.toString()}`,
              payload: [],
            })
            .toPromise()
            .then((g) => {
              return {
                ...games[index],
                gamePeriods: g,
              };
            });
        });

        Promise.all(arr).then(async (completeGames) => {
          this.allGames = completeGames;
          this.doesHaveGames = completeGames && completeGames.length > 0;
          await this.getVenues();
        });
      });
  }

  getDates(): void {
    if (
      this.tournament?.startDate != null &&
      this.tournament?.endDate != null
    ) {
      this.enumarateDays(this.tournament?.startDate, this.tournament?.endDate);
    }
  }

  getTournamentByParams(id) {
    if (null == this.primaryColor) {
      this.primaryColor = '#a90000';
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    } else {
      this.primaryColor = this.primaryColor;
      this.getFontColor(this.primaryColor);
    }

    return new Promise((resolve, reject) => {
      this._http
        .get({
          url: `${environment.api.tournaments}/${id}`,
        })
        .toPromise()
        .then((res) => {
          resolve(true);
          this.tournament = res;
          this.postSchedule = this.tournament.postSchedule;
          this.getDates();
        });
    }).catch((err) => {
      console.log('error...');
      rejects(err);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getRank(i: number) {
    if (i == 1) return '1st';
    else if (i == 2) return '2nd';
    else if (i == 3) return '3rd';
    else return i + 'th';
  }

  getGroup(team: any) {
    if (team.ageGroup) {
      return 'Age Group: ' + team.ageGroup;
    } else if (team.gradeGroup) {
      return 'Grade Group: ' + team.gradeGroup;
    } else {
      return 'Group: N/A';
    }
  }

  getGender(gender: string) {
    switch (gender) {
      case 'M':
        return 'BOYS';
      case 'F':
        return 'GIRLS';
      case 'B':
        return 'BOYS and GIRLS';
    }
  }

  openVenue(venueName: string) {
    let selectedVenue = this.venues.find((venue) => (venue.name = venueName));
    this.router.navigate([
      `/public-venue-schedules/${this.tournamentId}/${selectedVenue.id}`,
    ]);
  }

  getFontColor(primary: string) {
    let pRed = primary.substring(1, 3);
    let pGreen = primary.substring(3, 5);
    let pBlue = primary.substring(5, 7);
    let primaryInt =
      parseInt(pRed, 16) * 0.299 +
      parseInt(pGreen, 16) * 0.587 +
      parseInt(pBlue, 16) * 0.114;

    if (primaryInt > 150) {
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }
  }

  // subscribeToPotentialVenues() {
  //   this.venueService.potentialVenues$.subscribe((potentialVenues) => {
  //     console.log('sub vennn', potentialVenues);
  //     console.log('sure vennn', this.sureVenues);
  //     this.potentialVenues = [];
  //     potentialVenues?.forEach((ven) => {
  //       if (!this.sureVenues.includes(ven)) {
  //         this.potentialVenues.push(ven);
  //       }
  //     });
  //     console.log('this.potentialVenues', this.potentialVenues);
  //     if (this.potentialVenues.length > 0) {
  //       this.hasPotentialVenues = true;
  //     } else {
  //       this.hasPotentialVenues = false;
  //     }
  //   });
  // }

  subscribeToSureGameVenues() {
    this.venueService.sureGameVenues$.subscribe((sureVenues) => {
      this.sureVenues.push(...sureVenues);
      //Remove sureVenues in Potential Venues List
      // this.potentialVenues?.forEach((ven) => {
      //   if (this.sureVenues.includes(ven)) {
      //     let indexToDelete = this.potentialVenues.findIndex(ven);
      //     this.potentialVenues.splice(indexToDelete, 1);
      //   }
      // });

      // this.potentialVenues = this.potentialVenues?.filter(
      //   (ven) => !this.sureVenues.includes(ven)
      // );
      // console.log('this.potentialVenues2', this.potentialVenues);
      // if (this.potentialVenues.length > 0) {
      //   this.hasPotentialVenues = true;
      // } else {
      //   this.hasPotentialVenues = false;
      // }
    });
  }
  goToTournamentPublicPage() {
    this.router.navigate([
      '/public-tournament/atx-winter-havoc/divisions/',
      this.tournamentId,
    ]);
  }
}
