<div class="p-col-12">
  <div class="p-grid">
    <div class="p-col-6 text-left">
      <div class="search-controls">
        <input
          class="input-text my-2"
          placeholder="Search Team or Coach..."
          [(ngModel)]="q"
          (keyup)="debounceSearch()"
        />
      </div>
    </div>

    <div class="p-col-6 text-right" style="margin-top: 20px">
      <button
        [disabled]="meta.index == 1"
        class="btn-link"
        (click)="previous()"
      >
        « Previous
      </button>
      <button
        [disabled]="meta.index == segment"
        *ngFor="let segment of meta.segments; let i = index"
        class="btn-segment"
        [class.btn-segment-active]="meta.index == segment"
        (click)="navigate(segment)"
      >
        {{ segment }}
      </button>
      <button
        [disabled]="meta.index == meta.totalSegments || meta.totalSize === 0"
        class="btn-link"
        (click)="next()"
      >
        Next »
      </button>
    </div>

    <div class="card">
      <div class="p-grid table-container">
        <p-table
          [value]="teamList"
          [loading]="isFetching"
          [tableStyle]="{ 'min-width': '10rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name" style="width: 15%">
                Name <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="divisionName" style="width: 15%">
                Division <p-sortIcon field="divisionName"></p-sortIcon>
              </th>
              <th class="text-center" style="width: 10%">Players</th>
              <th class="text-center" style="width: 10%">Coaches</th>
              <th class="text-center" style="width: 10%">Checked-In</th>
              <th class="text-center" style="width: 15%">Status</th>
              <th class="text-center" style="width: 15%"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-team>
            <tr class="hoverable">
              <td>{{ team?.name }}</td>
              <td>{{ team?.divisionName }}</td>
              <td class="text-center">{{ team?.playersCount }}</td>
              <td class="text-center">{{ team?.coachesCount }}</td>
              <td class="text-center">{{ team?.coachesCheckInCount }}</td>
              <td class="text-center">
                <div class="statuses">
                  <p-chip
                    *ngIf="team?.owed == 0"
                    styleClass="chip"
                    label="Paid"
                  ></p-chip>
                  <p-chip
                    *ngIf="team?.owed > 0"
                    styleClass="chip"
                    label="Owe ${{ team?.owed }}"
                  ></p-chip>
                  <p-chip
                    *ngIf="team?.verified"
                    styleClass="chip"
                    label="Roster Verified"
                  ></p-chip>
                </div>
              </td>
              <td class="text-center" (click)="selectedTeam(team)">
                <button class="btn btn-rounded-red">Start Check In</button>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div class="no-team" *ngIf="teamList.length == 0">
          <div class="TheBoldFont-Bold text-black2 fs-2">
            No Teams Available
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
