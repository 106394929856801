import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpService, ToastService } from '@core/services';

// PRIME
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'primeng/dynamicdialog';

import { Division } from '@core/models/division';
import { Teams } from '@core/models/teams';
import { Tournament } from '@core/models/tournament';

import { PlayerService } from '@core/services/player.service';
import { DivisionService } from '@core/services/division.service';
import { CheckInService } from '@core/services/check-in.service';
import { TournamentsService } from '@core/services/tournaments.service';

import { AddPlayerComponent } from '@coach/add-player/add-player.component'; // TODO rename to PlayerModalComponent
import { BulkPlayerModalComponent } from '@components/modal/bulk-player-modal/bulk-player-modal.component';
import { ConfirmModalComponent } from '@components/modal/confirm-modal/confirm-modal.component';

// CONSTANT
import { defConstant } from '@data/constant-data';

import SignaturePad from 'signature_pad';

import { environment } from '@env/environment';
import { CoachCoachService } from '@core/services/coach-coach.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'checkin-players',
  templateUrl: './checkin-players.component.html',
  styleUrls: ['./checkin-players.component.scss'],
})
export class CheckinPlayersComponent implements OnInit {
  @ViewChild('signaturePad', { static: true }) signaturePad: ElementRef;
  signature: SignaturePad;

  _team: Teams;
  tournament: Tournament;
  division: Division;
  checkInTeam: any;
  maxTeams: number;
  totalPlayersPerRoster: number;
  minPlayersPerRoster: number;

  isSendRosterDisabled = true;

  isSignatureOpen: boolean = false;
  signaturePadOptions: any = {};
  signatureImg: any;
  signatureSVG: any;

  @Input()
  set team(team: Teams) {
    this._team = team;
    if (team) {
      this.getPlayers();
      this.getCoaches();
    }
  }

  get team(): Teams {
    return this._team;
  }

  host: string;
  players: any = [];
  coaches: any = [];

  constructor(
    private checkInService: CheckInService,
    private dialog: MatDialog,
    private divisionService: DivisionService,
    private playerService: PlayerService,
    private toastService: ToastService,
    private tournamentsService: TournamentsService,
    private coachesService: CoachCoachService,
    private dialogService: DialogService,
    private httpService: HttpService,
    private _sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.host = environment.api.baseUrl;
    this.signature = new SignaturePad(this.signaturePad.nativeElement);
  }

  async getPlayers() {
    const tournamentId = parseInt(sessionStorage.getItem('tournamentId'));
    this.tournament = await this.tournamentsService.getTournament(tournamentId);

    this.divisionService
      .getTeamDivisionInTournament(tournamentId, this._team.id)
      .subscribe((division: Division) => {
        this.division = division;
        this.maxTeams = division.maxTeams;
        this.totalPlayersPerRoster = division.tournament.totalPlayersPerRoster;
        this.minPlayersPerRoster = division.tournament.minPlayersPerRoster;
      });

    this.checkInService
      .getCheckInTeam(tournamentId, this._team.id)
      .subscribe((checkInTeam: any) => {
        this.checkInTeam = checkInTeam;
      });

    this.playerService
      .getPlayersByTeam(this._team)
      .subscribe((players: any) => {
        this.players = players;
      });
  }

  async getCoaches() {
    this.coachesService
      .getTeamAsstCoaches(this._team.id)
      .subscribe((coaches: any) => {
        this.coaches = coaches;
        console.log('PLAYERS COMP COACHES', this.coaches);
        if (this.coaches?.length < 1) {
          this.isSendRosterDisabled = true;
        } else {
          this.isSendRosterDisabled = false;
        }
      });
  }

  addPlayer() {
    if (null != this.checkInTeam) {
      this.dialog
        .open(ConfirmModalComponent, {
          width: defConstant.modalWidth,
          data: {
            message:
              'This roster has already been verified.  By continuing you will undo this, would you like to proceed?',
          },
        })
        .beforeClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.doAddPlayer();
          }
        });
    } else {
      this.doAddPlayer();
    }
  }

  doAddPlayer() {
    let ref = this.dialogService.open(AddPlayerComponent, {
      header: 'Add New Player',
      width: '600px',
      // height: 'calc(100vh - 120px)',
      height: 'auto',

      closable: false,
      contentStyle: { 'overflow-y': 'auto' },
      data: {
        team: this.team,
        isEdit: false,
        isCoach: true,
      },
    });
    ref.onClose.subscribe(() => {
      this.getPlayers();
      if (this.checkInTeam) {
        if (null != this.checkInTeam) {
          this.checkInService.unverifyTeams(this.checkInTeam.id).subscribe(
            () => {
              this.checkInTeam = null;
            },
            (e) => {
              console.log(e);
            }
          );
        }
      }
    });
  }

  editPlayer(event: any, player: any) {
    if (null != this.checkInTeam) {
      this.dialog
        .open(ConfirmModalComponent, {
          width: defConstant.modalWidth,
          data: {
            message:
              'This roster has already been verified.  By continuing you will undo this, would you like to proceed?',
          },
        })
        .beforeClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.doEditPlayer(event, player);
          }
        });
    } else {
      this.doEditPlayer(event, player);
    }
  }

  doEditPlayer(event: any, player: any) {
    event.stopPropagation();

    this.dialogService
      .open(AddPlayerComponent, {
        header: 'Edit Player Info',
        width: '600px',
        height: 'calc(100vh - 120px)',
        contentStyle: { 'overflow-y': 'scroll' },
        closable: false,
        data: {
          team: this.team,
          isEdit: true,
          player: player,
        },
      })
      .onClose.subscribe(() => {
        this.getPlayers();
        if (this.checkInTeam) {
          if (null != this.checkInTeam) {
            this.checkInService.unverifyTeams(this.checkInTeam.id).subscribe(
              () => {
                this.checkInTeam = null;
              },
              (e) => {
                console.log(e);
              }
            );
          }
        }
      });
  }

  deletePlayer(event: any, player: any) {
    if (null != this.checkInTeam) {
      this.dialog
        .open(ConfirmModalComponent, {
          width: defConstant.modalWidth,
          data: {
            message:
              'This roster has already been verified.  By continuing you will undo this, would you like to proceed?',
          },
        })
        .beforeClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.doDeletePlayer(event, player);
          }
        });
    } else {
      this.doDeletePlayer(event, player);
    }
  }

  doDeletePlayer(event: any, player: any) {
    event.stopPropagation();

    this.playerService.deletePlayer(player.id).subscribe(() => {
      this.players = this.players.filter((item: any) => item.id !== player.id);
      if (this.checkInTeam) {
        if (null != this.checkInTeam) {
          this.checkInService.unverifyTeams(this.checkInTeam.id).subscribe(
            () => {
              this.checkInTeam = null;
            },
            (e) => {
              console.log(e);
            }
          );
        }
      }
      this.toastService.successMsg({ message: 'Player Deleted' });
    });
  }

  bulkEditRoster() {
    if (null != this.checkInTeam) {
      this.dialog
        .open(ConfirmModalComponent, {
          width: defConstant.modalWidth,
          data: {
            message:
              'This roster has already been verified.  By continuing you will undo this, would you like to proceed?',
          },
        })
        .beforeClosed()
        .subscribe((confirmed) => {
          if (confirmed) {
            this.openBulkEditRoster();
          }
        });
    } else {
      this.openBulkEditRoster();
    }
  }

  openBulkEditRoster() {
    let ref = this.dialogService.open(BulkPlayerModalComponent, {
      header: 'Edit All Players',
      width: '800px',
      height: 'calc(100vh - 120px)',
      closable: false,
      data: {
        team: this.team,
        players: this.players,
      },
    });
    ref.onClose.subscribe(() => {
      this.getPlayers();
      if (null != this.checkInTeam) {
        this.checkInService.unverifyTeams(this.checkInTeam.id).subscribe(
          () => {
            this.checkInTeam = null;
          },
          (e) => {
            console.log(e);
          }
        );
      }
    });
  }

  sendRosterLink() {
    this.isSendRosterDisabled = true;

    let coachesWithEmail = [];
    coachesWithEmail = this.coaches.filter((coach) => coach.email != null);

    console.log('COACHES WIT EMAIL', coachesWithEmail);
    coachesWithEmail.map((coach) => {
      let body = {
        email: coach.email,
        subject: `${this.team.name} Roster`,
        body: `Hi Coach ${coach.firstName}, \n \n You can update the ${this.team.name} Roster using the link below: \n http://tourneykey.com/public/edit-roster/${this.team.id}/${this.tournament.maximumCoaches}/${this.tournament.totalPlayersPerRoster}`,
      };
      console.log('body', body);

      this.httpService
        .post({
          url: environment.email,
          payload: body,
        })
        .subscribe(
          (data) => {
            this.isSendRosterDisabled = false;
            this.toastService.successMsg({
              message: `Roster Link Sent To Coach ${coach.firstName}`,
            });
          },
          (err) => {
            this.isSendRosterDisabled = false;
            this.toastService.errorMsg({
              message: 'Sending Roster Link Failed',
            });
          }
        );
    });
  }

  openVerifyRoster() {
    this.isSignatureOpen = true;
  }

  verifyRoster() {
    const data = this.signature.toDataURL().split(',')[1];
    console.log('this.signature', this.signature.toData());
    console.log('this.signature2', this.signature.toSVG());
    this.signatureSVG = this.signature.toSVG();
    const checkInTeam = {
      tournament: this.tournament,
      team: this._team,
      data: data,
    };

    this.checkInService.verifyTeams(checkInTeam).subscribe((checkIn) => {
      this.checkInTeam = checkIn;
      this.toastService.successMsg({ message: 'Coach Checked In' });
      this.isSignatureOpen = false;
      this.signature.clear();
    });
  }

  getSVGImage(image) {
    return this._sanitizer.bypassSecurityTrustHtml(`${image}`);
  }

  getSVGImageUrl(image) {
    let base64string = btoa(image);
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      `data:image/svg+xml;base64,${base64string}`
    );
  }

  isOverLimit() {
    return this.players.length >= this.maxTeams;
  }

  drawStart() {}

  drawComplete() {}

  clearSignature() {
    this.signature.clear();
  }

  showImage(event: any) {
    console.log('THIS IS EVENT', event);
  }
}
