import { Component, OnInit } from '@angular/core';
import { BracketService } from '@core/services/bracket-service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@core/services';

@Component({
  selector: 'app-delete-top-team',
  templateUrl: './delete-top-team.component.html',
  styleUrls: ['./delete-top-team.component.scss'],
})
export class DeleteTopTeamComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private bracketService: BracketService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.close(false);
  }

  onSubmit() {

    let bracketId = this.config.data;
    console.log('bracketid', bracketId);
    
    this.bracketService
      .deleteTopTeam(bracketId)
      .then((res) => {
        this.bracketService.deleteTopTeam$.next(bracketId);
        this.ref.close(true);
      })
      .catch((e) => {
        this.toastService.errorMsg({ message: 'Failed! Something went wrong' });
        this.ref.close(false);
      });
  }
}
