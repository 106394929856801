import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { faMale } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '@core/services';
import { Tournament } from '@core/models/tournament';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
})
export class InformationComponent implements OnInit {
  // @Input() tournament: Tournament;

  _tournament: Tournament;

  @Input()
  set tournament(value: Tournament) {
    this._tournament = value;
    this.initTournament();
    console.log('tournamentt', this.tournament);
  }

  get tournament() {
    return this._tournament;
  }

  @Input() divisions: any;

  faMale = faMale;
  faFacebook = faFacebookF;
  faTwitter = faTwitter;
  faAndroid = faAndroid;
  faApple = faApple;
  faCalendar = faCalendar;
  faInstagram = faInstagram;
  faTiktok = faTiktok;

  contactName: string;

  primaryColor: string;
  secondaryColor: string;
  isBlack: boolean;
  fontColor: string;
  fontColorOpposite: string;

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  async initTournament() {
    if (this._tournament) {
      // this.primaryColor = this._tournament.organization.primaryColor;
      // this.secondaryColor = this._tournament.organization.secondaryColor;
      if (null == this._tournament.organization.primaryColor) {
        this.primaryColor = '#a90000';
      } else {
        this.primaryColor = this._tournament.organization.primaryColor;
      }

      if (null == this._tournament.organization.secondaryColor) {
        this.secondaryColor = '#000000';
      } else {
        this.secondaryColor = this._tournament.organization.secondaryColor;
        this.getFontColor(this.secondaryColor);
      }

      // const user: any = await this.userService
      //   .getPrimaryUser(this._tournament.organization.id)
      //   .toPromise();
      // this.contactName = user.fullName;
      this.contactName = '';

      console.log('THIS TOURNA', this._tournament);
    }
  }

  getFontColor(secondary: string) {
    let sRed = secondary.substring(1, 3);
    let sGreen = secondary.substring(3, 5);
    let sBlue = secondary.substring(5, 7);

    let secondaryInt =
      parseInt(sRed, 16) * 0.299 +
      parseInt(sGreen, 16) * 0.587 +
      parseInt(sBlue, 16) * 0.114;

    if (secondaryInt > 150) {
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }

    if (this.fontColor == '#ffffff') {
      this.isBlack = false;
    } else {
      this.isBlack = true;
    }
  }

  openTwitter(twitterUrl: string): void {
    console.log(twitterUrl);
    // const validUrlPattern =
    //   /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[-\w.\/?=&%]*)*\/?$/i;

    // if (twitterUrl && validUrlPattern.test(twitterUrl)) {
    //   if (/^(?!https?:\/\/)/i.test(twitterUrl)) {
    //     window.open('http://' + twitterUrl, '_blank');
    //   } else {
    //     window.open(twitterUrl, '_blank');
    //   }
    // } else {
    //   console.log('err');
    // }
    window.open('http://twitter.com/' + twitterUrl);
  }

  openFacebook(facebookUrl: string): void {
    console.log(facebookUrl);
    // const validUrlPattern =
    //   /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[-\w.\/?=&%]*)*\/?$/i;

    // if (facebookUrl && validUrlPattern.test(facebookUrl)) {
    //   if (/^(?!https?:\/\/)/i.test(facebookUrl)) {
    //     window.open('http://' + facebookUrl, '_blank');
    //   } else {
    //     window.open(facebookUrl, '_blank');
    //   }
    // } else {
    //   console.log('err');
    // }
    window.open('http://facebook.com/' + facebookUrl);
  }

  openInstagram(instagramUrl: string): void {
    console.log(instagramUrl);
    // const validUrlPattern =
    //   /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[-\w.\/?=&%]*)*\/?$/i;

    // if (instagramUrl && validUrlPattern.test(instagramUrl)) {
    //   if (/^(?!https?:\/\/)/i.test(instagramUrl)) {
    //     window.open('http://' + instagramUrl, '_blank');
    //   } else {
    //     window.open(instagramUrl, '_blank');
    //   }
    // } else {
    //   console.log('err');
    // }
    window.open('http://instagram.com/' + instagramUrl);
  }

  openTiktok(tiktokUrl: string): void {
    console.log(tiktokUrl);
    // const validUrlPattern =
    //   /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})(\/[-\w.\/?=&%]*)*\/?$/i;

    // if (tiktokUrl && validUrlPattern.test(tiktokUrl)) {
    //   if (/^(?!https?:\/\/)/i.test(tiktokUrl)) {
    //     window.open('http://' + tiktokUrl, '_blank');
    //   } else {
    //     window.open(tiktokUrl, '_blank');
    //   }
    // } else {
    //   console.log('err');
    // }
    window.open('http://tiktok.com/' + tiktokUrl);
  }

  chipStyle() {
    return {
      'background-color': this.primaryColor,
      color: this.fontColorOpposite,
      'font-size': '0.8em',
      'font-weight': 'bold',
      padding: '3px 20px',
      border: ' #fcd1d1 1px solid',
      ' margin-right': ' 8px',
      'border-radius': '1.625rem',
    };
  }
}
