<div class="p-grid public-container p-2" *ngIf="innerWidth >= 769">
  <div class="p-col-8 px-5">
    <section class="text-left">
      <img src="./assets/img/logo_only.svg" />
    </section>
    <section class="text-left pt-3">
      <img src="./assets/img/logo_white_text_only.svg" width="204" />
    </section>
    <section class="text-left pt-3 icons-container">
      <a href="https://twitter.com/TourneyKey" style="text-decoration: none">
        <div class="icon-button">
          <img
            src="./assets/img/x-footer.svg"
            alt="Twitter"
            class="social-icon"
          />
        </div>
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=61559456433966"
        style="text-decoration: none"
      >
        <div class="icon-button">
          <i class="pi pi-facebook"></i></div
      ></a>
      <a
        href="https://www.instagram.com/tourneykey/"
        style="text-decoration: none"
      >
        <div class="icon-button">
          <img
            src="./assets/img/instagram_icon.svg"
            alt="Instagram"
            class="social-icon"
          />
        </div>
      </a>
      <div class="icon-button">
        <i class="pi pi-android"></i>
      </div>

      <div class="icon-button">
        <i class="pi pi-apple"></i>
      </div>
    </section>
  </div>
  <div class="p-col-2">
    <div (click)="goToFaqs()" class="link">FAQs</div>
    <div (click)="goToContactUs()" class="link">Contact Us</div>
    <div (click)="goToFeatures()" class="link">Features</div>
  </div>

  <div class="p-col-2">
    <div class="link">Legal</div>
    <div class="link" (click)="goToPrivacy()">Privacy Policy</div>
    <div class="link">Terms of Use</div>
  </div>

  <div class="p-col-12 text-center">
    <div class="mx-5" style="border-top: 1px solid lightgray; padding: 16px">
      <label>2019 TourneyKey All Rights Reserved</label>
    </div>
  </div>

  <!-- <div class="p-col-1"></div>
  <div class=" p-md-2 p-xl-2 p-lg-2 p-col-12">
    <div class="footer-subcontainer">
      <section>
        <a class="TheBoldFont-Bold fs-3 pfoot">FAQS</a>
      </section>
      <section>
        <a class="TheBoldFont-Bold fs-3 pfoot">CONTACT</a>
      </section>
      <section>
        <a class="TheBoldFont-Bold fs-3 pfoot">LOGIN</a>
      </section>
    </div>
    <div class="footer">
      <div class="foot-cont">
        <a class="pfoot">Features</a>
      </div>
      <div class="foot-cont">
        <a class="pfoot">Careers</a>
      </div>
      <div class="foot-cont">
        <a class="pfoot">Legal</a>
      </div>
      <div class="foot-cont">
        <a class="pfoot">Privacy Policy</a>
      </div>
      <div class="foot-cont">
        <a class="pfoot">Terms of Use</a>
      </div>
    </div>
  </div>

  <div class=" p-md-6  p-xl-6 p-lg-6 p-col-12">
    <section class="text-center">
      <img src="./assets/img/logo_only.svg" />
    </section>
    <section class="text-center pt-3">
      <img src="./assets/img/logo_text_only.svg" />
    </section>
    <section class="text-center py-3">
      <label>2019 TourneyKey All Rights Reserved</label>
    </section>
  </div>

  <div class="  p-md-2 p-xl-2 p-lg-2  p-col-12">
    <div class="footer-subcontainer">
      <section>
        <a class="TheBoldFont-Bold fs-3 pfoot">FACEBOOK</a>
      </section>
      <section>
        <a class="TheBoldFont-Bold fs-3 pfoot">TWITTER</a>
      </section>
      <section>
        <a class="TheBoldFont-Bold fs-3 pfoot">GOOGLE</a>
      </section>
    </div>
    <div class="foot-cont">
      <div class="pfoot">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repellendus
        voluptas labore vitae optio repellat blanditiis. Voluptatem in libero ad
        velit eligendi iure consectetur eum! Quam officiis vero.
      </div>
    </div>
  </div>
  <div class="p-col-1"></div> -->
</div>
