import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DiscountCode } from '@core/models/discount-code';
import {
  DivisionTiebreakerType,
  DIVISION_TIEBREAKER_OPTIONS,
  DIVISION_TIEBREAKER_TYPE,
} from '@core/models/division-tiebreaker';
import {
  GeneralInfo,
  TournamentDivisionTiebreaker,
  TournamentPoolTiebreaker,
} from '@core/models/gen-info';
import { GenderType, GENDER_TYPES } from '@core/models/gender-type';
import {
  PoolTiebreakerType,
  POOL_TIEBREAKER_OPTIONS,
  POOL_TIEBREAKER_TYPE,
} from '@core/models/pool-tiebreaker';
import { AGE_GROUP_TYPES } from '@core/models/tournament-age-group';
import { GRADE_GROUP_TYPES } from '@core/models/tournament-grade-group';
import { HelperService, HttpService, ToastService } from '@core/services';
import { CityStateLookupService } from '@core/services/city-state-lookup.service';
import { TournamentsService } from '@core/services/tournaments.service';
import { CityState, getUSCityStates } from '@core/utils/city-state';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-publish-tournament',
  templateUrl: './publish-tournament.component.html',
  styleUrls: ['./publish-tournament.component.scss'],
})
export class PublishTournamentComponent implements OnInit {
  datePipe: DatePipe;
  form: FormGroup;
  arrow = 'pi pi-caret-down';
  defaultDate: string;
  genderTypes: GenderType[] = GENDER_TYPES;
  gradeGroupTypes: any[] = GRADE_GROUP_TYPES;
  ageGroupTypes: any[] = AGE_GROUP_TYPES;
  poolTiebreakerTypes: PoolTiebreakerType[] = POOL_TIEBREAKER_TYPE;
  poolTiebreakerOptions: PoolTiebreakerType[] = POOL_TIEBREAKER_OPTIONS;
  divisionTiebreakerTypes: DivisionTiebreakerType[] = DIVISION_TIEBREAKER_TYPE;
  divisionTiebreakerOptions: DivisionTiebreakerType[] =
    DIVISION_TIEBREAKER_OPTIONS;
  poolTiebreaker: PoolTiebreakerType;
  divisionTiebreaker: DivisionTiebreakerType;
  poolTiebreakerSequence = {};
  DivisionTiebreakerSequence = {};
  cityStates: CityState[];
  cityState: any;
  collapseAdvanceSettings = true;
  collapseTieBreakers = true;
  collapsePromoCode = true;
  genInfo: GeneralInfo;
  discountCode: DiscountCode;
  disableSite = false;
  isEditTournament: boolean;
  currentTourneyPic: string;
  isValidated = false;
  isAgeGroup = true;
  isGradeGroup = true;
  isUpdate = false;
  sequence: number = 0;
  poolSeqList: FormArray;
  isPoolDisabled: boolean = false;

  hasLogo: boolean;
  isLogoUploaded: boolean;
  logoUrl: any;
  isLogoFetching: boolean;

  poolContainer: any[];
  isFetchingPoolTB: boolean = false;
  divisionContainer: any[];
  isFetchingDivisionTB: boolean = false;

  close(): void {
    this.ref.close(1);
  }

  clearLogo(): void {
    this.hasLogo = false;
  }

  async onSelectLogo(event) {
    if (!this.isEditTournament) {
      if (event) {
        const readFile: any = await this.helper.fileReader(event.files[0]);
        if (readFile) {
          const newBase64 = readFile.split(',')[1];
          this.form.get('base64Photo').patchValue(newBase64);
        }
      }
    }
    this.isLogoFetching = true;
    this.isLogoUploaded = true;
    this.hasLogo = true;
    this.logoUrl = '';

    const newFile = event.currentFiles[0];

    this.helper.fileReader(newFile).then(
      (res) => {
        this.logoUrl = res;
        setTimeout((_) => {
          this.isLogoFetching = false;
        }, 1000);
      },
      (err) => {
        this.isLogoFetching = false;
      }
    );
  }

  get poolTieBreakers() {
    return this.form.get('poolTieBreakers') as FormArray;
  }

  get divisionTieBreakers() {
    return this.form.get('divisionTieBreakers') as FormArray;
  }

  get discountCodes() {
    return this.form.get('discountCodes') as FormArray;
  }

  addPoolSequence(): void {
    this.poolTieBreakers.push(this.fb.control(''));
  }

  addDivisionSequence(): void {
    this.divisionTieBreakers.push(this.fb.control(''));
  }

  deletePoolSequence(index: number): void {
    this.poolTieBreakers.removeAt(index);
  }

  deleteDivisionSequence(index: number): void {
    this.divisionTieBreakers.removeAt(index);
  }

  addPromoCode(): void {
    let formGroup: FormGroup = this.fb.group({
      code: this.fb.control(null),
      amount: this.fb.control(null),
    });
    this.discountCodes.push(formGroup);
  }

  deletePromo(index: number): void {
    this.discountCodes.removeAt(index);
  }

  getForms(): FormGroup {
    return this.form;
  }

  constructor(
    private fb: FormBuilder,
    private tournamentService: TournamentsService,
    private helper: HelperService,
    private httpService: HttpService,
    private toastService: ToastService,
    private cityStateLookupService: CityStateLookupService,
    public ref: DynamicDialogRef
  ) {
    this.tournamentService.tabIndex$.subscribe(
      (res) => (this.isEditTournament = res.isEdit)
    );

    this.defaultDate = this.helper.dateDefaultFormat(new Date());

    this.form = this.fb.group({
      id: [''],
      base64Photo: [null],
      photo: [''],
      name: ['', [Validators.required]],
      city: [null],
      cityState: [''],
      startDate: [null, [Validators.required]],
      endDate: [null, [Validators.required]],
      entryFee: ['', [Validators.required]],
      games: ['', [Validators.required]],
      gender: ['MALE', [Validators.required]],
      group: [''],
      ageGroups: [[]],
      gradeGroups: [[]],
      poolTieBreakers: this.fb.array([]),
      divisionTieBreakers: this.fb.array([]),
      additionalInformation: [null],
      gameDuration: [90],
      homeTerm: [''],
      awayTerm: [''],
      description: [''],
      featured: [false],
      published: [true],
      totalPlayersPerRoster: [''],
      useMyWhiteLabelSite: new FormControl(false),
      tournamentInfoUrl: [''],
      discountCodes: this.fb.array([]),
    });

    this.form.valueChanges.subscribe((form: GeneralInfo) => {
      if (form.group === 'AGE') {
        this.isAgeGroup = false;
        this.isGradeGroup = true;
      } else if (form.group === 'GRADE') {
        this.isAgeGroup = true;
        this.isGradeGroup = false;
      } else {
        this.isAgeGroup = true;
        this.isGradeGroup = true;
      }

      this.tournamentService.generalInfo$.next(form);
    });
  }

  ngOnInit(): void {}

  ngDestroy() {
    this.tournamentService.generalInformation$.unsubscribe();
    this.tournamentService.tabIndex$.unsubscribe();
  }

  async upload(event) {
    if (!this.isEditTournament) {
      this.toastService.errorMsg({
        message: "Can't upload, An error occurred.",
      });
      return;
    }

    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        const newBase64 = readFile.split(',')[1];
        this.httpService
          .post({
            url: `${environment.api.tournamentPhoto}/base64`,
            payload: {
              id: this.genInfo.id,
              base64: newBase64,
            },
          })
          .subscribe(
            (data) => {
              this.toastService.successMsg({
                message: 'Successfully uploaded',
              });

              this.hasLogo = true;
              this.isLogoUploaded = false;
              this.isLogoFetching = false;
              this.logoUrl = `${environment.api.tournamentPhoto}/${data.photo}`;
            },
            (err) => {
              this.toastService.errorMsg({ message: err.error.message });
            }
          );
      }
    }
  }

  sortFormArray(formArray) {
    let sortArray = formArray.value;
    sortArray.sort((a, b) => {
      const A = a.primaryIndicator.toUpperCase();
      const B = b.primaryIndicator.toUpperCase();

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    });
    return sortArray;
  }

  sortPoolArray(array?: TournamentPoolTiebreaker[]) {
    if (array != null) {
      return array.sort((n1, n2) => n1.seqNo - n2.seqNo);
    } else {
      return array;
    }
  }

  sortDivArrray(array?: TournamentDivisionTiebreaker[]) {
    if (array != null) {
      return array.sort((n1, n2) => n1.seqNo - n2.seqNo);
    } else {
      return array;
    }
  }

  insertPoolTBSequence() {
    var length = this.form.get(`poolTieBreakers`).value.length;
    this.form.get(`poolTieBreakers`).value.forEach((val, index) => {
      if (val != null || val != '') {
        val.seqNo = index;
      }
    });
  }

  insertDivisionTBSequence() {
    var length = this.form.get(`divisionTieBreakers`).value.length;
    this.form.get(`divisionTieBreakers`).value.forEach((val, index) => {
      if (val != null || val != '') {
        val.seqNo = index;
      }
    });
  }

  getLabel(value) {
    switch (value) {
      case 'WINNING_PERCENTAGE':
        return 'WINNING PERCENTAGE';
      case 'HEAD_TO_HEAD':
        return 'HEAD TO HEAD';
      case 'HEAD_TO_HEAD_TWO_TEAMS':
        return 'HEAD TO HEAD TWO TEAMS ONLY';
      case 'HEAD_TO_HEAD_PTS_DIFF':
        return 'HEAD TO HEAD PT DIFF';
      case 'PTS_DIFF':
        return 'PT DIFF';
      case 'PTS_SCORED':
        return 'PTS SCORED';
      case 'PTS_ALLOWED':
        return 'PTS_ALLOWED';
      case 'POOL_PLACE':
        return 'POOL PLACE';
      case 'RECORD':
        return 'RECORD';
      case 'POINTS':
        return 'POINTS';
      default:
        return '';
    }
  }

  searchCityState(evt) {
    this.cityStateLookupService.getResults(evt.query).then((data) => {
      this.cityStates = data;
    });
  }

  insertCityState() {
    this.form.get('city').setValue(this.form.get('cityState').value.label);
  }

  getAdvanceSettingsValue(val): void {
    if (
      val.homeTerm ||
      val.awayTerm ||
      val.gamesDuration ||
      val.totalPlayersPerRoster ||
      val.useMyWhiteLabelSite ||
      val.tournamentInfoUrl
    ) {
      this.collapseAdvanceSettings = false;
    } else {
      this.collapseAdvanceSettings = true;
    }
  }

  onSubmit() {
    this.insertPoolTBSequence();
    this.insertDivisionTBSequence();
    this.insertCityState();
    this.tournamentService
      .publishTourna('Created')
      .catch((er) => {
        console.log('er', er);
      });

    this.close();
  }
}
