<div class="parent-container">
  <div class="row editDiv">
    <div class="table-container" style="margin-bottom: 20px">
      <p-table
        [columns]="scrollableCols"
        [frozenColumns]="frozenCols"
        [value]="divisions"
        [scrollable]="true"
        scrollHeight="400px"
        dataKey="id"
        frozenWidth="200px"
      >
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" style="width: 200px" />
          </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns style="margin: 20px">
          <tr>
            <th *ngFor="let col of columns" style="height: 80px">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-division let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="col.field === 'name'">
                <div>
                  <input
                    id="name"
                    class="addTeamInput"
                    type="text"
                    [(ngModel)]="division.name"
                    (ngModelChange)="updateDivision(division)"
                    pInputText
                  />
                </div>
              </ng-container>

              <ng-container *ngIf="col.field === 'abbreviation'">
                <div>
                  <input
                    id="abbreviation"
                    class="addTeamInput"
                    type="text"
                    [(ngModel)]="division.abbreviation"
                    (ngModelChange)="updateDivision(division)"
                    pInputText
                  />
                </div>
              </ng-container>

              <ng-container *ngIf="col.field === 'gender'">
                <div class="default-dropdown">
                  <p-dropdown
                    appendTo="body"
                    id="gender"
                    placeholder="Select"
                    [options]="gender"
                    optionValue="value"
                    [(ngModel)]="division.gender"
                    (ngModelChange)="updateDivision(division)"
                    styleClass="p-col-12 mx-0 dropdown-menu"
                  ></p-dropdown>
                </div>
              </ng-container>
              <ng-container *ngIf="col.field === 'group1'">
                <div class="default-dropdown">
                  <p-dropdown
                    [options]="group1Options"
                    [(ngModel)]="division.group1"
                    (onChange)="updateGroup1($event)"
                    placeholder="Select a Group"
                  ></p-dropdown>
                </div>
              </ng-container>
              <ng-container *ngIf="col.field === 'group2'">
                <div class="default-dropdown">
                  <p-dropdown
                    [options]="getOptions(division, division.group1)"
                    [(ngModel)]="division.group2"
                    (onChange)="updateGroup2($event)"
                    placeholder="Select an Option"
                    optionLabel="label"
                    optionValue="value"
                    styleClass="p-col-12 mx-0"
                  ></p-dropdown>
                </div>
              </ng-container>
              <ng-container *ngIf="col.field === 'fee'">
                <div>
                  <input
                    id="entryFee"
                    class="addTeamInput"
                    type="number"
                    min="0"
                    (keypress)="numericOnly($event)"
                    [(ngModel)]="division.entryFee"
                    (ngModelChange)="updateDivision(division)"
                    pInputText
                  />
                </div>
              </ng-container>

              <ng-container *ngIf="col.field === 'teams'">
                <div>
                  <input
                    id="maxTeams"
                    class="addTeamInput"
                    type="number"
                    min="0"
                    (keypress)="numericOnly($event)"
                    [(ngModel)]="division.maxTeams"
                    (ngModelChange)="updateDivision(division)"
                    pInputText
                  />
                </div>
              </ng-container>

              <!-- <ng-container *ngIf="col.field === 'message'">
                  <div>
                    <input id="resultsMessage"
                  class="addTeamInput" 
                  type="text" 
                  [(ngModel)]="division.resultsMessage"
                  (ngModelChange)="updateDivision(division)"
                  pInputText>
                 </div>
                </ng-container> -->

              <ng-container *ngIf="col.field === 'color'">
                <div class="picker">
                  <p-colorPicker
                    id="scheduleColor"
                    [(ngModel)]="division.scheduleColor"
                    (ngModelChange)="updateDivision(division)"
                  ></p-colorPicker>
                </div>
              </ng-container>

              <ng-container *ngIf="col.field === 'text'">
                <div class="picker">
                  <p-colorPicker
                    id="textColor"
                    [(ngModel)]="division.textColor"
                    (ngModelChange)="updateDivision(division)"
                  ></p-colorPicker>
                </div>
              </ng-container>

              <!-- <ng-container *ngIf="col.field === 'block'">
                  <div>
                    <input id="gameDuration"
                    class="addTeamInput" 
                    type="number"
                    min="0"
                    (keypress)="numericOnly($event)"
                    [(ngModel)]="division.gameDuration"
                    (ngModelChange)="updateDivision(division)"
                    pInputText>
                 </div>
                </ng-container>


                <ng-container *ngIf="col.field === 'periods'">
                  <div class="default-dropdown">
                    <p-dropdown
                      appendTo="body"
                      id="numPeriod"
                      placeholder="Select"
                      [options]="periods"
                      optionValue="value"
                      [(ngModel)]="division.numPeriod"
                      optionLabel="name"
                      (ngModelChange)="updateDivision(division)"
                      styleClass="p-col-12 mx-0"
                    ></p-dropdown>
                  </div>
                </ng-container>

                <ng-container *ngIf="col.field === 'time period'">
                  <div>
                    <input id="timePerPeriod"
                    class="addTeamInput" 
                    type="number"
                    min="0"
                    (keypress)="numericOnly($event)"
                    [(ngModel)]="division.timePerPeriod"
                    (ngModelChange)="updateDivision(division)"
                    pInputText>
                 </div>
                </ng-container>


                <ng-container *ngIf="col.field === 'full timeout'">
                  <div>
                    <input id="fullTimeOuts"
                    class="addTeamInput" 
                    type="number"
                    min="0"
                    (keypress)="numericOnly($event)"
                    [(ngModel)]="division.fullTimeOuts"
                    (ngModelChange)="updateDivision(division)"
                    pInputText>
                 </div>
                </ng-container> -->

              <ng-container *ngIf="col.field === '30 sec timeouts'">
                <input
                  id="thirtySecTimeOuts"
                  class="addTeamInput"
                  type="number"
                  min="0"
                  (keypress)="numericOnly($event)"
                  [(ngModel)]="division.thirtySecTimeOuts"
                  (ngModelChange)="updateDivision(division)"
                  pInputText
                />
              </ng-container>

              <ng-container *ngIf="col.field === 'delete'">
                <button
                  (click)="showModal(); divisionIdToDelete = division.id"
                  pButton
                  type="button"
                  label="DELETE"
                  class="p-button-rounded p-button-danger deleteBtn"
                ></button>
                <p-dialog
                  header="Are you sure you want to delete this Division?"
                  [(visible)]="isVisible"
                  [style]="{ width: '50vw' }"
                  [closable]="false"
                >
                  <div class="p-col-12 flex flex-center">
                    <button
                      class="btn-rounded-red btn-reset"
                      (click)="closeModal()"
                      style="margin: 10px"
                    >
                      No
                    </button>
                    <button
                      class="btn-rounded-red btn-reset"
                      (click)="deleteDivision(divisionIdToDelete)"
                    >
                      Yes
                    </button>
                  </div>
                </p-dialog>
              </ng-container>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/> -->
    </div>
  </div>
</div>

<div
  class="fixed-container p-col-12 flex flex-center"
  style="padding-top: 10px"
>
  <button class="btn-rounded-red btn-reset" (click)="handleClose()">
    Close
  </button>

  <button
    type="button"
    (click)="submitDivision(divisions)"
    class="btn-rounded-white mx-2"
  >
    Save
  </button>
</div>

<p-dialog
  [(visible)]="isShowUnsavedChangesPopup"
  [modal]="false"
  [dismissableMask]="true"
  [closable]="false"
>
  <div class="container-content">
    <h1>You have unsaved changes. Are you sure you want to leave?</h1>
  </div>

  <div class="flex flex-center m-4 my-4">
    <button class="btn-rounded-white mx-1" (click)="hide()">Cancel</button>
    <button class="btn-rounded-red mx-1" (click)="closePopUp()">Confirm</button>
  </div>
</p-dialog>
