<div class="text-center mb-2">
  <label class="TheBoldFont-Bold header">PLEASE LOGIN</label>
</div>

<div class="mb-4 text-center">
  Please login to register your team and join this tournament.
  <br />
  <br />
</div>
<section class="flex flex-center">
  <!-- <button class="btn-rounded-white mx-2" (click)="close()">Return</button> -->
  <button class="btn-rounded-white mx-2" (click)="signup()">Sign Up</button>
  <button class="btn-rounded-red mx-2" (click)="login()">Login</button>
</section>
<section class="flex flex-center pt-4">
  <button class="btn-rounded-white mx-2" (click)="close()">Cancel</button>
</section>
