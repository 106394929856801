import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'primeng/fileupload';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import {
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
  AuthService,
} from '@core/services';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from '@core/models/user';
import { environment } from '@env/environment';

import { Teams } from '@core/models/teams';
import { GENDER_TYPES, GenderType } from '@core/models/gender-type';
import {
  GRADE_GROUP_TYPES,
  GradeGroupType,
} from '@core/models/tournament-grade-group';
import {
  AGE_GROUP_TYPES,
  AgeGroupType,
} from '@core/models/tournament-age-group';
import { isThisTypeNode } from 'typescript';

@Component({
  selector: 'app-edit-team',
  templateUrl: './edit-team.component.html',
  styleUrls: ['./edit-team.component.scss'],
})
export class EditTeamComponent implements OnInit {
  genderTypes: GenderType[] = GENDER_TYPES;
  gradeGroupTypes: any[] = GRADE_GROUP_TYPES;
  ageGroupTypes: any[] = AGE_GROUP_TYPES;
  gradeGroup = GRADE_GROUP_TYPES;
  ageGroup = AGE_GROUP_TYPES;
  selectedGroups: any;
  selectedAges: any;
  coach: User;
  user: User;
  gender: any;
  logoUrl: any;
  editTeamForm: FormGroup;
  teams: Teams[];
  team;

  isAgeGroup = false;
  isGradeGroup = true;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private helper: HelperService,
    private httpService: HttpService,
    private toastService: ToastService,
    private authService: AuthService,
    private emitterService: EmitterService,
    private ngxLoader: NgxUiLoaderService,
    private coachTeamsService: CoachTeamsService
  ) {
    this.editTeamForm = this.fb.group({
      id: [''],
      base64Photo: [''],
      city: [''],
      state: [''],
      photo: [''],
      name: ['', [Validators.required]],
      abbreviation: [''],
      gender: [''],
      teamPrivate: [true],
      gradeGroup: [null],
      ageGroup: [null],
      coach: [{}],
      group: new FormControl(
        { value: 'GRADE', disabled: false },
        Validators.required
      ),
    });

    this.editTeamForm.valueChanges.subscribe((form) => {
      if (form.group === 'AGE') {
        this.isAgeGroup = true;
        this.isGradeGroup = false;
      } else if (form.group === 'GRADE') {
        this.isAgeGroup = false;
        this.isGradeGroup = true;
      } else {
        this.isAgeGroup = false;
        this.isGradeGroup = false;
      }

      console.log('isAgreGroup', this.isAgeGroup);
    });

    console.log(this.config.data.id.id);
  }

  ngOnInit(): void {
    this.getTeam();
  }

  getTeam() {
    const id = this.config.data.id.id;

    if (id) {
      this.coachTeamsService.getTeam(id).subscribe((team) => {
        this.team = team;
        this.editTeamForm.patchValue({
          id: this.team.id,
          base64Photo: this.team.base64Photo,
          city: this.team.city,
          state: this.team.state,
          photo: this.team.photo,
          name: this.team.name,
          abbreviation: this.team.abbreviation,
          gender: this.team.gender,
          teamPrivate: this.team.teamPrivate,
          coach: this.team.coach,
          ageGroup: this.team.ageGroup,
          gradeGroup: this.team.gradeGroup,
          group: this.team.ageGroup != null ? 'AGE' : 'GRADE',
        });

        console.log(this.team.photo);
        if (this.team.photo) {
          // this.logoUrl = `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/${this.team.photo}`;
          this.logoUrl = `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/${this.team.photo}`;
          console.log(this.logoUrl);
        }
      });
    }
  }

  async onSelectLogo(event) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        const newBase64 = readFile.split(',')[1];
        this.editTeamForm.get('base64Photo').patchValue(newBase64);
        console.log(this.editTeamForm);
      }
    }
    this.logoUrl = '';

    const newFile = event.currentFiles[0];

    this.helper.fileReader(newFile).then(
      (res) => {
        this.logoUrl = res;
        setTimeout((_) => {}, 1000);
      },
      (err) => {}
    );
  }

  async upload(event) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        console.log(event);
        const newBase64 = readFile.split(',')[1];
        this.httpService
          .post({
            // url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/base64`,
            url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/base64`,
            payload: {
              id: this.team.id,
              base64: newBase64,
            },
          })
          .subscribe(
            (data) => {
              this.toastService.successMsg({
                message: 'Successfully uploaded',
              });
              // this.logoUrl = `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/${data.photo}`;
              this.logoUrl = `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/${data.photo}`;
              console.log(this.logoUrl);
            },
            (err) => {
              this.toastService.errorMsg({ message: err.error.message });
            }
          );
      }
    }
  }

  cancel(): void {
    this.ref.close();
  }

  submitHandler() {
    const formValue = this.editTeamForm.value;
    console.log('edit team: ', formValue);
    this.coachTeamsService.addTeam(formValue).then((data) => {
      this.ref.close();
    });
  }
}
