import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BracketSet } from '@core/models/bracket-set';
import { BracketSources, SubSources } from '@core/models/bracket-sources';
import { Bracket } from '@core/models/brackets';
import { removeRank } from '@core/utils/string';

@Component({
  selector: 'app-bracket-preview',
  templateUrl: './bracket-preview.component.html',
  styleUrls: ['./bracket-preview.component.scss'],
})
export class BracketPreviewComponent implements OnInit, AfterViewInit {
  @Input('width') public width: number;
  @Input('height') public height: number;
  // @Input('left') public left: number;
  // @Input('top') public top: number;
  // @Input('topSelectedOption') public topSelectedOption: any;
  // @Input('bottomSelectedOption') public bottomSelectedOption: any;
  // @Input('topTeam') public topTeam: any;
  // @Input('bottomTeam') public bottomTeam: any;
  // @Input('firstOptions') public firstOptions: BracketSources[];
  // @Input('bracketObject') public bracketObject: any;
  // @Input('bracketType') public bracketType: string;
  // @Input('courtName') public courtName: string;
  // @Input('venueName') public venueName: string;
  // @Input('gameName') public gameName: string;
  @Input('bracket') public bracket: Bracket;
  @Input('bracketSet') public bracketSet: BracketSet;
  @Input('mainOptions') public mainOptions:BracketSources[];
  
  scheduledDate: string;
  scheduledTime: string;
  winningTeam: string;
  firstSubOptions: SubSources[];
  firstSubSelected: SubSources;
  secondSubOptions: SubSources[];
  secondSubSelected: SubSources;
  bracketName: string;
  r1: BracketSources;
  r2: BracketSources;
  r1Sub: SubSources;
  r2Sub: SubSources;
  dragPosition = { x: 0, y: 0 };
  defaultFirstSelected: any;
  defaultSecondSelected: any;
  isTopTeam = false;
  itBottomTEam = false;
  isDragDisabled = true;
  topTitle: string;
  bottomTitle: string;
  @ViewChild('resizeBox') resizeBox: ElementRef;
  bracketHeight: number;
  bracketWidth: number;
  isDragDisable = true;

  get resizeBoxElement(): HTMLElement {
    return this.resizeBox.nativeElement;
  }

  constructor() {}

  ngOnInit(): void {
    // this.gameName = this.bracketObject.br.name;
    // this.winningTeam = this.bracketObject.br.winningTeam;
    // this.scheduledDate = this.bracketObject.br.scheduledDate;
    // this.scheduledTime = this.bracketObject.br.scheduledTime;
  }

  ngAfterViewInit() {
    this.dragPosition.x = this.bracket.x1;
    this.dragPosition.y = this.bracket.y1;
    const rect = this.resizeBoxElement.getBoundingClientRect();
    this.height = rect.height;
    this.width = rect.width;

    let dataFirst = {
      value: this.bracket.topSource?.id,
    };
    let dataSecond = {
      value: this.bracket.bottomSource?.id,
    };
    const bottomSource = this.bracket.bottomSource;
    const topSource = this.bracket.topSource;
    this.bracketHeight = this.calculateHeight(this.bracket.y1, this.bracket.y2)
    this.bracketWidth = this.calculateWidth(this.bracket.x3, this.bracket.x1)
    // this.onChangeSecondOptions(dataSecond, true);
    // this.onChangeFirstOptions(dataFirst, true);
    this.checkBottomType(bottomSource);
    this.checkTopType(topSource);
  }

  checkBottomType(bottomSource) {
    switch (bottomSource?.refType) {
      case 'WINNER': {
        const gameName = this.bracket.gameName;
        this.bottomTitle = `Winner of ${gameName}`;
        break;
      }
      case 'LOSER': {
        const gameName = this.bracket.gameName;
        this.bottomTitle = `Loser of ${gameName}`;
        break;
      }
      case 'DIV_PLACER': {
        this.bottomTitle = `${this.r2Sub.description} in ${this.bracket.bottomSource?.division?.abbreviation}`;
        break;
      }
      case 'POOL_PLACER': {
        this.bottomTitle = `${this.r2Sub.description} in ${removeRank(
          this.bracket.bottomSource.description
        )}`;
        break;
      }
      case 'TEAM': {
        this.bottomTitle = this.r2Sub.description;
        // console.log("bracketpreviewajay defaultFirstSelected", this.bracketObject)
        break;
      }
      default:
        this.bottomTitle = '';
        break;
    }
  }

  checkTopType(topSource) {
    switch (topSource?.refType) {
      case 'WINNER': {
        const gameName = this.bracket.gameName;
        this.topTitle = `Winner of ${gameName}`;
        break;
      }
      case 'LOSER': {
        const gameName = this.bracket.gameName;
        this.topTitle = `Loser of ${gameName}`;
        break;
      }
      case 'DIV_PLACER': {
        this.topTitle = `${this.r1Sub.description} in ${this.bracket?.division?.abbreviation}`;
        break;
      }
      case 'TEAM': {
        this.topTitle = this.r1Sub.description;
        break;
      }
      case 'POOL_PLACER': {
        this.topTitle = `${this.r1Sub.description} in ${removeRank(
          this.bracket.topSource.description
        )}`;
        break;
      }
      default:
        this.topTitle = '';
        break;
    }
  }

  calculateHeight(y1: number, y2: number): number {
    let y = y2 - y1;
    return Math.round(y);
  }

  calculateWidth(x3: number, x1: number): number {
    return Math.round(x3 - x1);
  }
  // onChangeFirstOptions(e: any, isFirst = false) {
  //   this.defaultFirstSelected = e.value;
  //   this.r1 = this.firstOptions.find((option) => {
  //     return option.id == this.defaultFirstSelected;
  //   });

  //   this.firstSubOptions = this.r1 ? this.r1.subSources : [];
  //   this.r1Sub = this.firstSubOptions.find((x) => {
  //     return x.refId == this.firstSubSelected;
  //   });
  //   // console.log("bracketpreviewajay r1Sub", this.r1Sub)
  //   // console.log("bracketpreviewajay firstSubOptions", this.firstSubOptions)
  //   // console.log("bracketpreviewajay r1", this.r1)
  //   // console.log("bracketpreviewajay defaultFirstSelected", this.defaultFirstSelected)

  //   // console.log("r1 sub", this.r1Sub)
  // }

  // onChangeSecondOptions(e: any, isFirst = false) {
  //   this.defaultSecondSelected = e.value;
  //   this.r2 = this.firstOptions.find((option) => {
  //     return option.id == this.defaultSecondSelected;
  //   });
  //   this.secondSubOptions = this.r2 ? this.r2.subSources : [];
  //   this.r2Sub = this.secondSubOptions.find((x) => {
  //     return x.refId == this.secondSubSelected;
  //   });
  // }
}
