import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Player } from '@core/models/players';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-player',
  templateUrl: './add-player.component.html',
  styleUrls: ['./add-player.component.scss'],
})
export class AddPlayerModalComponent implements OnInit {
  playerForm: FormGroup;
  data = null;
  team = null;
  isCoach: boolean;
  registration = null;
  isValidated: boolean = true;

  /**
   * CONSTANTS
   */
  REQ = 'REQUIRED';
  NOT_REQ = 'NOT_REQUIRED';

  constructor(
    private formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    let existingPlayer = this.config.data?.result.player;
    this.registration = this.config.data.result.registration;
    this.team = this.config.data.result.team;
    this.playerForm = this.formBuilder.group({
      playerName: [''],
      playerJersey: [''],
      playerBirthdate: [''],
      playerEmail: [''],
      playerFacebook: [''],
      playerGpa: [''],
      playerGraduationYear: [''],
      playerHeight: [''],
      playerInstagram: [''],
      playerParentEmail1: [''],
      playerParentEmail2: [''],
      playerParentName1: [''],
      playerParentName2: [''],
      playerParentPhone1: [''],
      playerParentPhone2: [''],
      playerPhone: [''],
      playerPosition: [''],
      playerTwitter: [''],
      team: [this.team],
      additionalPlayerInfo: this.registration?.additionalPlayerInfo ?? [],
    });

    if (existingPlayer) {
      this.setExistingPlayerData(existingPlayer);
    }

    Object.entries(this.registration).find(([key, value]) => {
      if (value == 'REQUIRED') {
        this.addValidator(key);
      }

      this.addValidator('playerName');
      this.addValidator('playerJersey');
    });

    // if (existingPlayer) {
    //   this.setExistingPlayerData(existingPlayer)
    // }
  }

  addValidator(formControlName: string): void {
    this.playerForm.controls[formControlName]?.setValidators([
      Validators.required,
    ]);
    this.playerForm.controls[formControlName]?.updateValueAndValidity();
  }

  generateFormGrp(formCtrl) {
    this.playerForm.addControl(
      `player_${formCtrl}`,
      this.formBuilder.control('')
    );
    return `player_${formCtrl}`;
  }

  setExistingPlayerData(player: any) {
    console.log('JERSEY1: ', player.jerseyNumber1);

    this.playerForm.patchValue({
      playerName: player?.fullName,
      playerJersey: player?.jerseyNumber1,
      playerBirthdate: player?.birthdate,
      playerEmail: player?.email,
      playerFacebook: player?.facebookPage,
      playerGpa: player?.gpa,
      playerGraduationYear: player?.graduationYear,
      playerHeight: player?.height,
      playerInstagram: player?.instagramHandle,
      playerParentEmail1: player?.parentEmail1,
      playerParentEmail2: player?.parentEmail2,
      playerParentName1: player?.parentName1,
      playerParentName2: player?.parentName2,
      playerParentPhone1: player?.parentPhone1,
      playerParentPhone2: player?.parentPhone2,
      playerPhone: player?.phone,
      playerPosition: player?.position,
      playerTwitter: player?.twitterHandle,
      team: player?.team,
    });
  }

  onSubmit(): void {
    let playerForm = this.playerForm.value;

    console.log('JERSEY2: ', playerForm.playerJersey);

    let playerData: Player = {
      id: null,
      fullName: playerForm.playerName,
      jerseyNumber1: playerForm.playerJersey,
      birthdate: playerForm.playerBirthdate,
      gpa: playerForm.playerGpa,
      email: playerForm.email,
      facebookPage: playerForm.playerFacebook,
      graduationYear: playerForm.playerGraduationYear,
      height: playerForm.playerHeight,
      instagramHandle: playerForm.playerInstagram,
      parentName1: playerForm.playerParentName1,
      parentEmail1: playerForm.playerParentEmail1,
      parentPhone1: playerForm.playerParentPhone1,
      parentName2: playerForm.playerParentName2,
      parentEmail2: playerForm.playerParentEmail2,
      parentPhone2: playerForm.playerParentPhone2,
      phone: playerForm.playerPhone,
      position: playerForm.playerPosition,
      twitterHandle: playerForm.playerTwitter,
      team: this.team,
      playerCustomValues: [],
    };

    try {
      if (!this.playerForm.valid) {
        this.isValidated = false;
        return;
      }

      this.ref.close(playerData);
    } catch (e) {}
  }

  close(): void {
    this.ref.close(null);
  }
}
