import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';

// APP MAIN MODULE
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';

// ADMIN
import { AdminComponent } from '@admin/main/admin.component';

// ADMIN COMPONENTS
import { SidebarComponent } from '@common-components/sidebar/sidebar.component';
import { AdminHeaderComponent } from '@common-components/admin-header/admin-header.component';

// COACH
import { CoachComponent } from '@coach/main/coach.component';

// COACH COMPONENTS
import { CoachSidebarComponent } from '@common-components/coach-sidebar/coach-sidebar.component';
import { CoachHeaderComponent } from '@common-components/coach-header/coach-header.component';

import { StaffComponent } from './pages/staff/staff.component';

// PUBLIC
import { AppComponent } from './app.component';
import { PublicComponent } from '@public/main/public.component';

// PUBLIC COMPONENTS
import { PublicHeaderComponent } from '@common-components/public-header/public-header.component';
import { LoginSuccessModalComponent } from '@public-components/login-success-modal/login-success-modal.component';
import { LoginComponent } from '@public-components/login/login.component';
import { SignupComponent } from '@public-components/signup/signup.component';
import { ForgotPasswordComponent } from './components/public/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/public/change-password/change-password.component';
import { PublicFeaturesComponent } from './pages/public/public-features/public-features.component';
import { PublicFeaturedTournamentsComponent } from './pages/public/public-featured-tournaments/public-featured-tournaments.component';
import { PublicHomeComponent } from './pages/public/public-home/public-home.component';
import { PublicBrowseComponent } from '@public/public-browse/public-browse.component';

// SHARED COMPONENTS
import { ConfirmComponent } from './components/modal/confirm/confirm.component';
import { ModalComponent } from './components/modal/modal/modal.component';
import { DragDropUploadProgressComponent } from '@components/drag-drop-upload/drag-drop-upload-progress/drag-drop-upload-progress.component';
import { MatSidenavModule } from '@angular/material/sidenav';

// PRIMENG
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { CardModule } from 'primeng/card';
import { SidebarModule } from 'primeng/sidebar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ColorPickerModule } from 'primeng/colorpicker';
// FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

// SERVICES
import { TokenInterceptor } from '@core/services/token.interceptor';

// OTHERS
import { ToastrModule } from 'ngx-toastr';
import { TkButtonDirective } from './components/directives/button/tk-button.directive';

// DIRECTIVES
import { RequiredInputContainerDirective } from '@core/directives/required-input/required-input-container.directive';

// ENV
import { environment } from '@env/environment.prod';
// import { PublicFooterComponent } from './pages/public/public-footer/public-footer.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { GridLayoutComponent } from '@components/common/grid-layout/grid-layout.component';
import { ListLayoutComponent } from '@components/common/list-layout/list-layout.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { CalendarModule } from 'primeng/calendar';
import { PublicTournamentsComponent } from '@public/public-tournaments/public-tournaments.component';
import { InformationComponent } from './pages/public/public-tournaments/information/information.component';
import { ScheduleComponent } from './pages/public/public-tournaments/schedule/schedule.component';
import { DivisionsComponent } from './pages/public/public-tournaments/divisions/divisions.component';
import { DivisionScheduleComponent } from './pages/public/public-divisions/division-schedule/division-schedule.component';
import { TeamRosterComponent } from '@public/public-team-games/team-roster/team-roster.component';
import { TeamScheduleComponent } from '@public/public-team-games/team-schedule/team-schedule.component';
import { VenuesComponent } from './pages/public/public-tournaments/venues/venues.component';
import { ViewTeamsComponent } from './pages/coach/view-teams/view-teams.component';
import { ChipModule } from 'primeng/chip';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AgmCoreModule } from '@agm/core';
import { PublicDivisionsComponent } from './pages/public/public-divisions/public-divisions.component';
import { PublicTeamGamesComponent } from '@public/public-team-games/public-team-games.component';
import { PublicSchedulesComponent } from './pages/public/public-schedules/public-schedules.component';
import { PublicRegistrationComponent } from './pages/public/public-registration/public-registration.component';
import { PublicVenueSchedulesComponent } from '@public/public-venue-schedules/public-venue-schedules.component';
import { VenueScheduleComponent } from '@public/public-venue-schedules/venue-schedule/venue-schedule.component';
import { AccordionModule } from 'primeng/accordion';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AddTeamComponent } from './pages/coach/add-team/add-team.component';
import { EditTeamComponent } from '@coach/edit-team/edit-team.component';
import { HeaderComponent } from './pages/public/public-tournaments/header/header.component';
import { FooterComponent } from './pages/public/public-tournaments/footer/footer.component';
import { RegistrationModalComponent } from './pages/public/public-registration/registration-modal/registration-modal.component';
import { LoginModalComponent } from './pages/public/public-registration/login-modal/login-modal.component';
import { PlayersComponent } from './pages/coach/players/players.component';
import { AddTeamModalComponent } from './pages/public/public-registration/add-team-modal/add-team-modal.component';
import { AddPlayerComponent } from './pages/coach/add-player/add-player.component';
import { PublishTournamentComponent } from './pages/public/public-browse/publish-tournament/publish-tournament.component';
import { PublishModalComponent } from './pages/public/public-browse/publish-modal/publish-modal.component';
import { AddExistingTeamComponent } from './pages/public/public-registration/add-existing-team/add-existing-team.component';
import { PublicTableComponent } from './pages/public/public-table/public-table.component';
import { SchedulesComponent } from './pages/coach/schedules/schedules.component';
import { SettingsComponent } from './pages/coach/settings/settings.component';
import { PoolsComponent } from './components/modal/pools/pools.component';
import { PostScoreComponent } from './components/modal/post-score/post-score.component';
import { BracketComponent } from '@components/admin/bracket-builder/bracket/bracket.component';
import { AdvanceComponent } from './components/modal/advance/advance.component';
import { ClearAllBracketsComponent } from './components/modal/clear-all-brackets/clear-all-brackets.component';
import { DeleteBracketComponent } from './components/modal/delete-bracket/delete-bracket.component';
import { DeleteTopTeamComponent } from './components/modal/delete-top-team/delete-top-team.component';
import { DeleteBottomTeamComponent } from './components/modal/delete-bottom-team/delete-bottom-team.component';
import { RemoveTeamComponent } from './components/modal/remove-team/remove-team.component';
import { ScheduleValidationSettingsModalComponent } from './components/modal/schedule-validation-settings-modal/schedule-validation-settings-modal.component';
import { CourtScheduleModalComponent } from './components/modal/court-schedule-modal/court-schedule-modal.component';
import { AddTimeModalComponent } from '@components/modal/add-time-modal/add-time-modal.component';
import { AddGameModalComponent } from '@components/modal/add-game-modal/add-game-modal.component';
import { AddPaymentAccountModalComponent } from '@components/modal/add-payment-account-modal/add-payment-account-modal.component';

import { TemplatePreviewComponent } from './components/admin/bracket-builder/template-preview/template-preview.component';
import { SchedulingComponent } from './components/admin/tournament/scheduling/scheduling.component';
import { BulkEditDivsComponent } from './pages/admin/setup-divisions/bulk-edit-divs/bulk-edit-divs.component';
import { MessageComponent } from './components/modal/message/message.component';
import { TeamInfoComponent } from './pages/public/public-divisions/team-info/team-info.component';
import { EditScoreComponent } from './components/modal/edit-score/edit-score.component';
import { ConfirmTeamComponent } from './components/modal/confirm-team/confirm-team.component';
import { ConfirmModalComponent } from './components/modal/confirm-modal/confirm-modal.component';
import { PublicBracketComponent } from './pages/public/public-bracket/public-bracket.component';
import { EditMultipleGamesComponent } from './components/modal/edit-multiple-games/edit-multiple-games.component';
import { DeleteGamesComponent } from './components/modal/delete-games/delete-games.component';
import { DeleteAllGamesComponent } from './components/modal/delete-all-games/delete-all-games.component';
import { DivisionTeamsComponent } from './pages/public/public-tournaments/divisions/division-teams/division-teams.component';
import { SelectTeamComponent } from './pages/coach/select-team/select-team.component';
import { NotifyCoachComponent } from './components/modal/notify-coach/notify-coach.component';
import { CacheInterceptor } from '@components/interceptors/cache-interceptors';
import { TournamentParamGuard } from '@components/interceptors/route-interceptors';
import { ListPlayersComponent } from './pages/coach/list-players/list-players.component';
import { ListCoachesComponent } from './pages/coach/list-coaches/list-coaches.component';
import { AddPlayerModalComponent } from '@public/public-registration/add-player/add-player.component';
import { UnsaveChangesComponent } from './components/modal/unsave-changes/unsave-changes.component';
import { PhoneFormatDirective } from '@components/directives/phone-format.directive';
import { PublicFeaturesMainComponent } from './pages/public/public-features-main/public-features-main.component';
import { DivisionModalComponent } from './pages/public/public-registration/division-modal/division-modal.component';
import { TeamModalComponent } from './pages/public/public-registration/team-modal/team-modal.component';
import { AddExistingPlayerComponent } from './pages/public/public-registration/add-existing-player/add-existing-player.component';
import { AddExistingCoachComponent } from './pages/public/public-registration/add-existing-coach/add-existing-coach.component';
import { AddCoachComponent } from './pages/public/public-registration/add-coach/add-coach.component';
import { CoachModalComponent } from './pages/coach/modals/coach-modal/coach-modal.component';
import { BulkPlayerModalComponent } from '@components/modal/bulk-player-modal/bulk-player-modal.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NumericInputDirective } from './components/directives/numeric-input.directive';
import { TournamentTeamsComponent } from './components/admin/coach-check-in/tournament-teams/tournament-teams.component';
import { CheckinTeamDetailsComponent } from './components/admin/coach-check-in/checkin-team-details/checkin-team-details.component';
import { CheckinCoachesComponent } from '@components/admin/coach-check-in/checkin-team-details/checkin-coaches/checkin-coaches.component';
import { CheckinPlayersComponent } from '@components/admin/coach-check-in/checkin-team-details/checkin-players/checkin-players.component';
import { CheckinPaymentComponent } from '@components/admin/coach-check-in/checkin-team-details/checkin-payment/checkin-payment.component';
import { CheckinTermsComponent } from '@components/admin/coach-check-in/checkin-team-details/checkin-terms/checkin-terms.component';
import { StaffSidebarComponent } from './components/common/staff-sidebar/staff-sidebar.component';
import { PublicRosterEditComponent } from './pages/public/public-roster-edit/public-roster-edit.component';
import { TeamPotentialGamesComponent } from './pages/public/public-team-games/team-potential-games/team-potential-games.component';
import { PublicBrowseDirectorOwnedComponent } from './pages/public/public-browse-director-owned/public-browse-director-owned.component';
import { NgxStripeModule } from 'ngx-stripe';
import { PublicPricingComponent } from './pages/public/public-pricing/public-pricing.component';
import { PricingCardComponent } from './pages/public/pricing-card/pricing-card.component';
import { PaymentPageComponent } from './pages/public/payment-page/payment-page.component';
import { CustomizedLoginComponent } from './components/public/customized-login/customized-login.component';
import { PublicSupportComponent } from './pages/public/public-support/public-support.component';
import { PublicPaymentModalComponent } from './pages/public/public-payment-modal/public-payment-modal.component';
import { GameScoresComponent } from './components/admin/game-scores/game-scores.component';
import { BracketStageComponent } from './components/admin/bracket-builder/bracket-stage/bracket-stage.component';
import { PublicPrivacyComponent } from './pages/public/public-privacy/public-privacy.component';
import { BracketStagePreviewComponent } from './components/bracket-builder/bracket-stage-preview/bracket-stage-preview.component';
import { EditBracketComponent } from './components/modal/edit-bracket/edit-bracket.component';
import { PublicBracketStageComponent } from './pages/public/public-bracket-stage/public-bracket-stage.component';
import { SharedModule } from '@components/shared/shared.module';
import { PublicBracketPreviewComponent } from './pages/public/public-bracket-preview/public-bracket-preview.component';
import { OrderByPipe } from '@core/pipes/order-by.pipe';
import { PublicTargetTournamentComponent } from './pages/public/public-target-tournament/public-target-tournament.component';
import { SelectVenueModalComponent } from './components/admin/select-venue-modal/select-venue-modal.component';
import { PaymentAuthModalComponent } from './components/admin/coach-check-in/checkin-team-details/checkin-payment/payment-auth-modal/payment-auth-modal.component';
import { PaymentAddModalComponent } from './components/admin/coach-check-in/checkin-team-details/checkin-payment/payment-add-modal/payment-add-modal.component';
import { CheckInSummaryModalComponent } from './components/admin/coach-check-in/checkin-team-details/check-in-summary-modal/check-in-summary-modal.component';

// const configloader: NgxUiLoaderConfig = {
//   bgsColor: 'red',
//   bgsOpacity: 0.5,
//   bgsPosition: 'bottom-right',
//   bgsSize: 60,
//   bgsType: 'three-bounce',
//   blur: 13,
//   delay: 0,
//   fastFadeOut: true,
//   fgsColor: 'red',
//   fgsPosition: 'center-center',
//   fgsSize: 110,
//   fgsType: 'pulse',
//   gap: 24,
//   logoPosition: 'center-center',
//   logoSize: 180,
//   logoUrl: './assets/img/ball-avatar.png',
//   masterLoaderId: 'master',
//   overlayBorderRadius: '0',
//   overlayColor: 'rgb(255,255,255)',
//   pbColor: 'red',
//   pbDirection: 'ltr',
//   pbThickness: 3,
//   hasProgressBar: true,
//   text: '',
//   textColor: '#FFFFFF',
//   textPosition: 'center-center',
//   maxTime: -1,
//   minTime: 300,
// };

const configloader: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 60,
  bgsType: 'three-bounce',
  blur: 13,
  delay: 0,
  fastFadeOut: true,
  fgsColor: 'red',
  fgsPosition: 'center-center',
  fgsSize: 110,
  fgsType: 'pulse',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 180,
  logoUrl: './assets/img/ball-avatar.png',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgb(255,255,255)',
  pbColor: 'red',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 300,
};
@NgModule({
  declarations: [
    OrderByPipe,
    AppComponent,
    ConfirmComponent,
    ModalComponent,
    AdminComponent,
    AdminHeaderComponent,
    SidebarComponent,
    CoachComponent,
    CoachHeaderComponent,
    CoachSidebarComponent,
    StaffComponent,
    PublicComponent,
    PublicHeaderComponent,
    LoginSuccessModalComponent,
    LoginComponent,
    SignupComponent,
    TkButtonDirective,
    PhoneFormatDirective,
    DragDropUploadProgressComponent,
    RequiredInputContainerDirective,
    // PublicFooterComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    PublicFeaturesComponent,
    PublicFeaturedTournamentsComponent,
    PublicHomeComponent,
    ListLayoutComponent,
    GridLayoutComponent,
    PublicBrowseComponent,
    InformationComponent,
    ScheduleComponent,
    DivisionsComponent,
    DivisionScheduleComponent,
    TeamRosterComponent,
    TeamScheduleComponent,
    VenuesComponent,
    PublicTournamentsComponent,
    ViewTeamsComponent,
    PublicDivisionsComponent,
    PublicTeamGamesComponent,
    PublicSchedulesComponent,
    PublicRegistrationComponent,
    PublicVenueSchedulesComponent,
    VenueScheduleComponent,
    AddTeamComponent,
    EditTeamComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationModalComponent,
    LoginModalComponent,
    PlayersComponent,
    AddTeamModalComponent,
    AddPlayerModalComponent,
    AddPlayerComponent,
    PublishTournamentComponent,
    PublishModalComponent,
    AddExistingTeamComponent,
    PublicTableComponent,
    SchedulesComponent,
    SettingsComponent,
    PoolsComponent,
    PostScoreComponent,
    AdvanceComponent,
    ClearAllBracketsComponent,
    DeleteBracketComponent,
    DeleteTopTeamComponent,
    ScheduleValidationSettingsModalComponent,
    CourtScheduleModalComponent,
    AddGameModalComponent,
    AddTimeModalComponent,
    AddPaymentAccountModalComponent,
    RemoveTeamComponent,
    DeleteBottomTeamComponent,
    TemplatePreviewComponent,
    SchedulingComponent,
    BulkEditDivsComponent,
    MessageComponent,
    TeamInfoComponent,
    EditScoreComponent,
    ConfirmTeamComponent,
    ConfirmModalComponent,
    PublicBracketComponent,
    EditMultipleGamesComponent,
    DeleteGamesComponent,
    DeleteAllGamesComponent,
    DivisionTeamsComponent,
    NotifyCoachComponent,
    SelectTeamComponent,
    ListPlayersComponent,
    ListCoachesComponent,
    UnsaveChangesComponent,
    PublicFeaturesMainComponent,
    DivisionModalComponent,
    TeamModalComponent,
    AddExistingPlayerComponent,
    AddExistingCoachComponent,
    AddCoachComponent,
    CoachModalComponent,
    BulkPlayerModalComponent,
    NumericInputDirective,
    TournamentTeamsComponent,
    CheckinTeamDetailsComponent,
    CheckinCoachesComponent,
    CheckinPlayersComponent,
    CheckinPaymentComponent,
    CheckinTermsComponent,
    StaffSidebarComponent,
    PublicRosterEditComponent,
    TeamPotentialGamesComponent,
    PublicBrowseDirectorOwnedComponent,
    PublicPricingComponent,
    PricingCardComponent,
    PaymentPageComponent,
    CustomizedLoginComponent,
    PublicSupportComponent,
    PublicPaymentModalComponent,
    GameScoresComponent,
    BracketStageComponent,
    BracketStagePreviewComponent,
    EditBracketComponent,
    PublicBracketStageComponent,
    PublicPrivacyComponent,
    PublicBracketPreviewComponent,
    PublicTargetTournamentComponent,
    SelectVenueModalComponent,
    PaymentAuthModalComponent,
    PaymentAddModalComponent,
    SelectVenueModalComponent,
    CheckInSummaryModalComponent,
  ],
  imports: [
    FormsModule,
    ProgressSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    ToastrModule.forRoot(),
    CardModule,
    CheckboxModule,
    SelectButtonModule,
    SharedModule,
    SplitButtonModule,
    TooltipModule,
    TableModule,
    ToggleButtonModule,
    DialogModule,
    TabViewModule,
    SidebarModule,
    ColorPickerModule,
    AutoCompleteModule,
    DropdownModule,
    InputTextModule,
    ListboxModule,
    FileUploadModule,
    RadioButtonModule,
    ScrollPanelModule,
    OverlayPanelModule,
    ButtonModule,
    PaginatorModule,
    FontAwesomeModule,
    YouTubePlayerModule,
    NgxUiLoaderModule.forRoot(configloader),
    NgxPaginationModule,
    CalendarModule,
    TableModule,
    ChipModule,
    AvatarModule,
    AvatarGroupModule,
    FieldsetModule,
    RadioButtonModule,
    MatIconModule,
    CardModule,
    AccordionModule,
    SlideMenuModule,
    MatSidenavModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDuBlLbg0JxFJUyr9z11dsDO4JJfjF8WiY',
    }),
    NgxStripeModule.forRoot('pk_live_fG7fCIAgdldtyTUj6D2ZpnvP'),
  ],
  exports: [
    LoginSuccessModalComponent,
    SignupComponent,
    DragDropUploadProgressComponent,
    RequiredInputContainerDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    DatePipe,
    DialogService,
    DynamicDialogRef,
    DynamicDialogConfig,
    TournamentParamGuard,
  ],
  bootstrap: [AppComponent],
  entryComponents: [BracketComponent],
})
export class AppModule {}
