<form [formGroup]="authForm" class="py-2 ml-1 container-form p-grid">
    <div class="p-col-12">
        <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">
            Password <span class="required-indicator">*</span>
        </label>
        <input id="password" type="password" pInputText formControlName="password" style="padding-left: 15px"
            class="input-password" />
        <small *ngIf="
        authForm.controls['password'].invalid &&
            (authForm.controls['password'].dirty ||
            authForm.controls['password'].touched)
          " class="text-error text-left text-primary2">
            <div *ngIf="authForm.controls['password'].errors.required" class="mb-3 ml-2">
                Password is required.
            </div>
        </small>
    </div>
    <div class="flex flex-center my-4">
        <button type="button" (click)="cancel()" class="btn-rounded-white mx-2" [disabled]="isAuthenticating">
            CANCEL
        </button>
        <button type="submit" (click)="submit()" class="btn-rounded-red mx-2" [disabled]="!authForm.valid || isAuthenticating">
            SUBMIT
        </button>
    </div>
</form>