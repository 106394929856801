import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import { EmitterService, HttpService, ToastService } from '@core/services';
import { CoachCoachService } from '@core/services/coach-coach.service';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import { PlayerService } from '@core/services/player.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-bulk-player-modal',
  templateUrl: './bulk-player-modal.component.html',
  styleUrls: ['./bulk-player-modal.component.scss'],
})
export class BulkPlayerModalComponent implements OnInit {
  playersForm = this.fb.group({
    players: this.fb.array([]),
  });

  players: any[];
  team: Teams;

  isEdit = false;
  hasSelectTeam = false;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private emitterService: EmitterService,
    private toast: ToastService,
    private playerService: PlayerService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.players = this.config.data.players;
    this.team = this.config.data.team;
    this.patchPlayers();
  }

  initForm(): void {
    for (let i = 0; i < 15; i++) {
      const playerForm = this.fb.group({
        id: [0],
        jerseyNumber1: [''],
        firstName: [''],
        lastName: [''],
        position: [''],
      });
      this.bulkPlayers.push(playerForm);
    }
  }

  get bulkPlayers() {
    return this.playersForm.controls['players'] as FormArray;
  }

  patchPlayers() {
    for (let i = 0; i < this.players.length; i++) {
      let form = this.bulkPlayers.at(i);
      form.patchValue({
        id: this.players[i].id,
        jerseyNumber1: this.players[i].jerseyNumber1,
        firstName: this.players[i].firstName,
        lastName: this.players[i].lastName,
        position: this.players[i].position,
      });
    }
  }

  async submit() {
    this.toast.toast('Please Wait', 'Updating Players', 'info');

    let valuePlayers = this.playersForm.value.players;

    let players = [];

    for (let i = 0; i < valuePlayers.length; i++) {
      let player = valuePlayers[i];
      if (null != player.jerseyNumber1 && '' != player.jerseyNumber1) {
        players.push(player);
      }
    }

    for (let i = 0; i < players.length; i++) {
      players[i].team = this.team;
    }

    this.playerService.savePlayers(players).subscribe(
      () => {
        this.toast.successMsg({ message: 'Players Updated' });
        this.ref.close();
      },
      () => {
        this.toast.errorMsg({ message: 'Update Failed' });
      }
    );
  }

  cancel(): void {
    this.ref.close();
  }
}
