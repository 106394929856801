import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { AssistantCoach } from '@core/models/assistant-coach';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {

  constructor(private http: HttpService) { }

  /**
   * Gets all the players under a coach
   * @param coachId 
   * @returns 
   */
  getCheckInCoaches(tournamentId: number | string, teamId: number): Observable<any> {
    return this.http
    .get({
      url: `${environment.api.checkIn}/coaches?tournamentId=${tournamentId}&teamId=${teamId}`,
    });
  }

  checkInCoach(checkInCoach: any) {
    return this.http
    .post({
      url: `${environment.api.checkIn}/coaches`,
      payload: checkInCoach
    });
  } 

  undoCheckInCoach(id: number) {
    return this.http
    .delete({
      url: `${environment.api.checkIn}/coaches/${id}`,
    });
  } 

  /**
   * Gets the checkin status of a team
   * @param tournamentId 
   * @param teamId 
   * @returns the checkin status if it exists
   */
  getCheckInTeam(tournamentId: number | string, teamId: number): Observable<any> {
    return this.http
    .get({
      url: `${environment.api.checkIn}/teams?tournamentId=${tournamentId}&teamId=${teamId}`,
    });
  }

  verifyTeams(checkInTeam: any) {
    return this.http
    .post({
      url: `${environment.api.checkIn}/teams`,
      payload: checkInTeam
    });
  } 

  unverifyTeams(id:number) {

    const url = `${environment.api.checkIn}/teams/${id}`;
    console.log('unverify', url);

    return this.http
    .delete({
      url: url,
    });
  } 
}
