<div class="bg-white pb-3 main">
  <div
    class="section-header text-white"
    [class.lower-sticky]="hasPotentialVenues"
  >
    POTENTIAL FUTURE GAMES
  </div>
  <div class="advisory" *ngIf="potentialVenues.length > 0">
    <h1>
      Please note: based on their division’s upcoming game results,
      {{ teamName }} may have potential games that will be played at:
    </h1>
    <div class="venue-list">
      <ng-container *ngFor="let venue of potentialVenues; let i = index">
        <ng-container *ngIf="i < potentialVenues.length - 2; else lastVenue">
          <div class="venue-link" (click)="openVenue(venue)">
            {{ venue }}, &nbsp;
          </div>
        </ng-container>
        <ng-template #lastVenue>
          <ng-container *ngIf="i === potentialVenues.length - 2">
            <div class="venue-link" (click)="openVenue(venue)">
              {{ venue }} & &nbsp;
            </div>
          </ng-container>
          <ng-container *ngIf="i === potentialVenues.length - 1">
            <div class="venue-link" (click)="openVenue(venue)">
              {{ venue }}
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <p-card>
    <div *ngIf="isFetching; else alreadyFetched" class="text-center p-3">
      <p-progressSpinner
        [style]="{ width: '40px', height: '40px' }"
      ></p-progressSpinner>
    </div>

    <ng-template #alreadyFetched>
      <div *ngIf="games">
        <div class="px-4" *ngFor="let schedule of schedules">
          <div
            class="sched-details-wrapper sub-main"
            *ngIf="checkIfScheduleHasGames(schedule)"
          >
            <div class="sched-date TheBoldFont-Bold">
              {{ schedule.date | date : "EEEE - MMMM d, y" }}
            </div>
            <div class="header-container" *ngIf="!isScreen850Below">
              <div class="schedule-header">
                <div class="division" style="width: 19%">Division</div>
                <div class="game-number" style="width: 10%">Game</div>
                <div class="sched-court" style="width: 19%">Location</div>
                <div class="pool-games" style="width: 26%">
                  <span class="team-location" style="width: 40%"
                    >Away Team</span
                  >
                  <span class="team-location" style="width: 40%"
                    >Home Team</span
                  >
                </div>
                <div class="game-score text-center" style="width: 13%">
                  Score
                </div>
                <div class="game-status text-center" style="width: 13%">
                  Status
                </div>
              </div>
              <hr />
            </div>
            <div *ngFor="let timeslot of sortTimeslots(schedule.timeslots)">
              <div
                class="TheBoldFont-Bold sched-time"
                *ngIf="checkIfTimeslotHasGames(timeslot)"
              >
                {{ timeslot.time }}
              </div>
              <div class="schedules-main-container">
                <ng-container
                  *ngFor="let game of sortGames(timeslot.games); let i = index"
                >
                  <!-- List View -->
                  <div
                    *ngIf="!game.team1 && !game.team2 && !isScreen850Below"
                    class="schedule-container"
                    [ngClass]="
                      isScreen450Below || !isScreen850Below
                        ? 'p-col-12'
                        : 'p-col-6'
                    "
                  >
                    <div
                      class="division"
                      style="width: 19%"
                      (click)="openDivision(game.division)"
                    >
                      {{ game.division.name }}
                    </div>
                    <div class="sched-court" style="width: 10%">
                      <div class="court">{{ getGameNumber(game.name) }}</div>
                      <div class="venue">{{ game.pool?.name }}</div>
                    </div>
                    <div
                      class="sched-court"
                      style="width: 19%"
                      (click)="openVenueSchedule(game.court.venueId)"
                    >
                      <div class="court">{{ game.court?.name }}</div>
                      <div class="venue">{{ game.court?.venueName }}</div>
                    </div>
                    <div style="width: 26%">
                      <div>
                        <div class="pool-games">
                          <div class="text-center" style="width: 40%">
                            <div class="div-p-col-6 team-info">
                              <div
                                *ngIf="game.team1"
                                class="team-name"
                                (click)="openTeam(game.team1)"
                              >
                                {{ game.team1?.name }}
                              </div>
                              <div *ngIf="!game.team1" class="source-name">
                                <strong>
                                  {{
                                    fetchFromBracketDetails(game)
                                      ?.refDescription1
                                  }}</strong
                                >
                                <div class="source-description">
                                  {{
                                    (fetchFromBracketDetails(game)?.refType1 ==
                                    "TEAM"
                                      ? ""
                                      : "(" +
                                        fetchFromBracketDetails(game)
                                          ?.refType1 +
                                        ")"
                                    ) | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="versus" style="width: 20%">vs</div>
                          <div class="text-center" style="width: 40%">
                            <div class="div-p-col-6 team-info">
                              <div
                                *ngIf="game.team2"
                                class="team-name"
                                (click)="openTeam(game.team2)"
                              >
                                {{ game.team2?.name }}
                              </div>
                              <div *ngIf="!game.team2" class="source-name">
                                <strong>{{
                                  fetchFromBracketDetails(game)?.refDescription2
                                }}</strong>
                                <div class="source-description">
                                  {{
                                    (fetchFromBracketDetails(game)?.refType2 ==
                                    "TEAM"
                                      ? ""
                                      : "(" +
                                        fetchFromBracketDetails(game)
                                          ?.refType2 +
                                        ")"
                                    ) | titlecase
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="width: 13%">
                      <div class="pool-score">
                        <div class="px-2 text-center">
                          <div class="div-p-col-6 team-info text-center">
                            <span class="team-score text-center">{{
                              game?.team1Score == 0 ? "" : game?.team1Score
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="versus"
                          *ngIf="game?.team1Score != 0 && game?.team2Score != 0"
                        >
                          —
                        </div>
                        <div class="px-2 text-center">
                          <div class="div-p-col-6 team-info text-center">
                            <span class="team-score text-center">{{
                              game?.team2Score == 0 ? "" : game?.team2Score
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="game.gameEnded; else notFinal"
                      class="game-number"
                      style="width: 13%"
                    >
                      Final
                    </div>
                    <ng-template #notFinal style="width: 13%">
                      <div
                        *ngIf="game.period == null || ''; else gamePeriod"
                        class="game-number"
                      ></div>
                      <ng-template #gamePeriod style="width: 13%">
                        <div style="width: 13%">
                          <div class="game-number">
                            {{ game.period }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>

                  <!-- Grid View -->
                  <div
                    class="schedule-container-grid-view"
                    *ngIf="!game.team1 && !game.team2 && isScreen850Below"
                  >
                    <div class="division" (click)="openDivision(game.division)">
                      {{ game.division.name }}
                    </div>
                    <div class="sched-court">
                      <div class="court">{{ getGameNumber(game.name) }}</div>
                      <div class="venue">{{ game.pool?.name }}</div>
                    </div>
                    <div
                      *ngIf="game.gameEnded; else notFinal"
                      class="game-number final"
                    >
                      FINAL
                    </div>
                    <div class="pool-score" *ngIf="game.team1 || game.team2">
                      <div class="score">
                        <span class="team-score text-center">{{
                          game?.team1Score == 0 ? "" : game.team1Score
                        }}</span>
                      </div>
                      <div class="score">
                        <span class="team-score text-center">{{
                          game?.team2Score == 0 ? "" : game.team2Score
                        }}</span>
                      </div>
                    </div>
                    <div class="pool-games">
                      <div class="div-p-col-6 team-info" style="width: 40%">
                        <div
                          *ngIf="game.team1"
                          class="team-name"
                          (click)="openTeam(game.team1)"
                        >
                          {{ game.team1?.name }}
                        </div>
                        <div *ngIf="!game.team1" class="source-name">
                          <strong>
                            {{ fetchFromBracketDetails(game).top1 }}</strong
                          >
                          <div class="source-description">
                            {{
                              (fetchFromBracketDetails(game).refType1 == "TEAM"
                                ? ""
                                : fetchFromBracketDetails(game).bottom1
                              ) | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="versus"
                        [class.higher]="
                          game?.team1Score != 0 || game?.team2Score != 0
                        "
                        style="width: 20%"
                      >
                        vs
                      </div>
                      <div class="div-p-col-6 team-info" style="width: 40%">
                        <div
                          *ngIf="game.team2"
                          class="team-name"
                          (click)="openTeam(game.team2)"
                        >
                          {{ game.team2?.name }}
                        </div>
                        <div *ngIf="!game.team2" class="source-name">
                          <strong>{{
                            fetchFromBracketDetails(game).top2
                          }}</strong>
                          <div class="source-description">
                            {{
                              (fetchFromBracketDetails(game).refType2 == "TEAM"
                                ? ""
                                : fetchFromBracketDetails(game).bottom2
                              ) | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="sched-court"
                      (click)="openVenueSchedule(game.court.venueId)"
                    >
                      <div class="venue">{{ game.court?.venueName }}</div>
                      <div class="court">{{ game.court?.name }}</div>
                    </div>
                    <ng-template #notFinal>
                      <div
                        *ngIf="game.period == null || ''; else gamePeriod"
                        class="game-number"
                      ></div>
                      <ng-template #gamePeriod>
                        <div>
                          <div class="game-number">
                            {{ game.period }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </div>
            <div></div>
          </div>
          <div
            class="text-center p-4"
            *ngIf="!checkIfScheduleHasGames(schedule)"
          >
            <label class="TheBoldFont-Bold fs-3"
              >No potential future games for this team.</label
            >
          </div>
        </div>
      </div>
    </ng-template>
  </p-card>
</div>

<div class="bg-white pb-3 hidden" [hidden]="true">
  <div
    class="section-header text-white"
    [class.lower-sticky]="hasPotentialVenues"
  >
    BRACKET
  </div>
  <p-card>
    <div
      *ngIf="!isHasSet || !division.tournament?.showBrackets; else withBracket"
    >
      <span class="disp-block TheBoldFont-Bold fs-3 text-center p-3">
        Bracket Not Available.
      </span>
    </div>
    <ng-template #withBracket>
      <div class="" [ngClass]="{ show: isHasSet, hide: !isHasSet }">
        <div class="">
          <div class="" [style.height.px]="stageHeight" #parent>
            <div class="" #bracket></div>
          </div>
          <div class="" [style.height.px]="stageHeight"></div>
        </div>
        <div class="venue-container flex flex-wrap p-3">
          <!-- <div *ngFor="let venue of venues" class="p-col-6 ">
          <h5 class="m-0">{{venue}}</h5>
        </div> -->
        </div>
      </div></ng-template
    >
  </p-card>
</div>
