<div class="p-grid">

  <div class="p-col-1"></div>
  <div class="p-col-10">
    <div class="p-grid">
      <div class="p-col-8 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Minimum Rest Time in Minutes</label>
      </div>
      <div class="p-col-4 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 8rem; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="settings.minRest"
        />
      </div>

      <div class="p-col-8 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Maximum Rest Time in Minutes</label>
      </div>
      <div class="p-col-4 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 8rem; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="settings.maxRest"
        />
      </div>

      <div class="p-col-8 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Maximum Games per Team</label>
      </div>
      <div class="p-col-4 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 8rem; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="settings.maxGames"
        />
      </div>
      
      <div class="p-col-8 flex text-center">
        <label class="text-bold1 p-0 mb-1 team-name">Travel Time in Minutes</label>
      </div>
      <div class="p-col-4 text-right">
        <input
          type="number"
          class="tournament-input-text"
          style="width: 8rem; display: inline-block"
          (keypress)="numericOnly($event)"
          max="3"
          [(ngModel)]="settings.travelTime"
        />
      </div>
    </div>
  </div>
  <div class="p-col-1"></div>

  <div class="p-col-12 flex flex-center">
    <button
      pButton
      iconPos="right"
      type="button"
      label="Save"
      class="
        p-button-raised p-button-rounded p-button-danger p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="onSubmit()"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="Cancel"
      class="
        p-button-raised p-button-rounded p-button-text p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="cancel()"
    ></button>
  </div>
</div>
