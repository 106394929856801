<div class="">
  <div class="">
    <div class="container-features">
      <div
        class="text-center"
        style="height: 50px; margin-bottom: 50px; margin-top: 20px"
      >
        <h1>Choose a plan that works for you</h1>
      </div>

      <div class="container-filter">
        <ul>
          <ng-container
            *ngIf="
              returnedValue == 'first';
              then selectedTournamentDirector;
              else defaultTournamentDirector
            "
          >
          </ng-container>
          <ng-template #selectedTournamentDirector>
            <li>
              <button
                (click)="getValue('first')"
                style="background-color: white"
                class="btn-features-left custom-cursor"
              >
                <img
                  src="assets/img/director.png"
                  style="height: 30px; width: 32px"
                />
                <label class="toggle-label custom-cursor">
                  Tournament Director
                </label>
              </button>
            </li>
          </ng-template>
          <ng-template #defaultTournamentDirector>
            <li>
              <button
                (click)="getValue('first')"
                class="btn-features-left custom-cursor"
              >
                <img
                  src="assets/img/director.png"
                  style="height: 30px; width: 32px"
                />
                <label class="toggle-label custom-cursor">
                  Tournament Director
                </label>
              </button>
            </li>
          </ng-template>
          <ng-container
            *ngIf="
              returnedValue == 'second';
              then selectedPlayerParent;
              else defaultPlayerParent
            "
          >
          </ng-container>
          <ng-template #selectedPlayerParent>
            <li>
              <button
                (click)="getValue('second')"
                style="background-color: white"
                class="btn-features-right custom-cursor"
              >
                <label class="toggle-label custom-cursor">
                  Player Recruiting Profile
                </label>
                <img
                  src="assets/img/player.png"
                  style="height: 30px; width: 32px"
                />
              </button>
            </li>
          </ng-template>

          <ng-template #defaultPlayerParent>
            <li>
              <button
                (click)="getValue('second')"
                class="btn-features-right custom-cursor"
              >
                <label class="toggle-label custom-cursor">
                  Player Recruiting Profile
                </label>
                <img
                  src="assets/img/player.png"
                  style="height: 30px; width: 32px"
                />
              </button>
            </li>
          </ng-template>
        </ul>
        <h4 class="acc-type">Choose Account Type</h4>
      </div>

      <div class="tdpricing-card-container" *ngIf="returnedValue == 'first'">
        <div class="content-container">
          <div class="td-pricing-header">
            <h1 class="TheBoldFont">Tournament Director</h1>
            <h2>Straight Forward and Simple Pricing</h2>
          </div>
          <div class="td-pricing-content fs-2">
            <p>
              TourneyKey offers Tournament Directors clear and simple pricing to
              host their tournaments. No more purchasing credits, subscriptions
              or complicated service fees.
            </p>
            <p>
              Simply create your account and post your event for free. Once you
              are ready to post your schedule you will be charged $1 per team in
              your tournament. After your tournament is over, you will be
              automatically billed for any additional teams you added or your
              account credited for any teams that you paid for but didn’t
              participate.
            </p>
          </div>
        </div>
      </div>

      <div class="pricing-card-container" *ngIf="returnedValue == 'second'">
        <div class="pricing-card">
          <div class="pricing-header">
            <h2>Bronze</h2>
            <h4>$10.00<br />Per Package</h4>

            <button class="btn-features" (click)="paymentPermission()">
              <fa-icon class="faicon" [icon]="faMousePointer"></fa-icon>
              <label> Select </label>
            </button>
          </div>
          <!-- <ul>
                <li>Feature 1</li>
                <li>Feature 2</li>
                <li>Feature 3</li>
            </ul>  -->
        </div>
        <div class="pricing-card">
          <div class="pricing-header">
            <h2>Silver</h2>
            <h4>$10.00<br />Per Package</h4>

            <button class="btn-features" (click)="paymentPermission()">
              <fa-icon class="faicon" [icon]="faMousePointer"></fa-icon>
              <label> Select </label>
            </button>
            <div
              class="pricing-info"
              style="background-color: rgba(192, 192, 192, 1)"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum vulputate ac erat ut lobortis. In.
              </p>
            </div>
          </div>

          <!-- <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul> -->
        </div>
        <div class="pricing-card">
          <div class="pricing-header">
            <h2>Gold</h2>
            <h4>$10.00<br />Per Package</h4>

            <button class="btn-features" (click)="paymentPermission()">
              <fa-icon class="faicon" [icon]="faMousePointer"></fa-icon>
              <label> Select </label>
            </button>
          </div>
          <!-- <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul> -->
        </div>
        <div class="pricing-card">
          <div class="pricing-header">
            <h2>Premium</h2>
            <h4>$10.00<br />Per Package</h4>

            <button class="btn-features" (click)="paymentPermission()">
              <fa-icon class="faicon" [icon]="faMousePointer"></fa-icon>
              <label> Select </label>
            </button>
          </div>
          <!-- <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul> -->
        </div>
        <div class="pricing-card">
          <div class="pricing-header">
            <h2>Executive</h2>
            <h4>$10.00<br />Per Package</h4>

            <button class="btn-features" (click)="paymentPermission()">
              <fa-icon class="faicon" [icon]="faMousePointer"></fa-icon>
              <label> Select </label>
            </button>
          </div>
          <!-- <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul> -->
        </div>
      </div>
    </div>
  </div>
</div>
