import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appPhoneFormat]'
})
export class PhoneFormatDirective {
  @Input() appPhoneFormat: string;

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.el.value = value.replace(/\D/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
}
