<div class="bg-white pb-3 main">
  <div
    class="section-header text-white"
    [class.lower-sticky]="hasPotentialVenues"
  >
    ROSTER
  </div>
  <p-card>
    <div *ngIf="players?.length > 0; else noPlayers">
      <!-- LIST VIEW -->
      <div class="row" *ngIf="innerWidth >= 769; else gridView">
        <p-table [value]="players" dataKey="id">
          <ng-template pTemplate="header">
            <tr>
              <th *ngIf="tournament.showPlayerName">PLAYERS</th>
              <th *ngIf="tournament.showJersey" class="text-center">JERSEY</th>
              <th *ngIf="tournament.showPosition" class="text-center">
                POSITION
              </th>
              <th *ngIf="tournament.showHeight" class="text-center">HEIGHT</th>
              <th *ngIf="tournament.showAge" class="text-center">AGE</th>
              <th *ngIf="tournament.showGrade" class="text-center">
                GRAD YEAR
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-player>
            <tr>
              <td *ngIf="tournament.showPlayerName">{{ player.fullName }}</td>
              <td *ngIf="tournament.showJersey" class="text-center">
                {{ player.jerseyNumber1 }}
              </td>
              <td *ngIf="tournament.showPosition" class="text-center">
                {{ player.position || "-" }}
              </td>
              <td *ngIf="tournament.showHeight" class="text-center">
                {{ player.height || "-" }}
              </td>
              <td *ngIf="tournament.showAge" class="text-center">
                {{ calculateAge(player.birthdate) }}
              </td>
              <td *ngIf="tournament.showGrade" class="text-center">
                {{ player.graduationYear > 0 ? player.graduationYear : "-" }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <!-- GRID VIEW -->
      <ng-template #gridView>
        <div class="grid-view-container">
          <div class="player" *ngFor="let player of players">
            <h2>{{ player.jerseyNumber1 }}</h2>
            <h4>{{ player.fullName }}</h4>
            <div class="info">
              <div class="info-title">
                <div class="title">POS</div>
                <div class="title">HT</div>
                <div class="title">AGE</div>
                <div class="title">YEAR</div>
              </div>
              <div class="info-value">
                <div class="value">{{ player.position || "-" }}</div>
                <div class="value">{{ player.height || "-" }}</div>
                <div class="value">{{ calculateAge(player.birthdate) }}</div>
                <div class="value">
                  {{ player.graduationYear > 0 ? player.graduationYear : "-" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>

    <ng-template #noPlayers>
      <div class="text-center p-4">
        <label class="TheBoldFont-Bold fs-3"
          >No players have been added to this team yet.</label
        >
      </div>
    </ng-template>
  </p-card>
</div>
