import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'schedule-validation-settings-modal',
  templateUrl: './schedule-validation-settings-modal.component.html',
  styleUrls: ['./schedule-validation-settings-modal.component.scss'],
})
export class ScheduleValidationSettingsModalComponent implements OnInit {

  settings:any = {
    minRest: 0,
    maxRest: 0,
    maxGames: 0,
    travelTime: 0
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.settings = this.config.data;
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  cancel() {
    this.ref.close(null);
  }

  onSubmit() {
    this.ref.close(this.settings);
  }
}
