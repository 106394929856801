<div class="main-container"

>

    <!-- RIGHT FACING BRACKET -->
    <div class="container"
      *ngIf="bracket.bracketType == 'RIGHT_FACING'"
      #resizeBox
      style="position: absolute"
      [style.width.px]="bracketWidth"
      [style.height.px]="bracketHeight"
      cdkDrag
      [cdkDragFreeDragPosition]="dragPosition"
      [cdkDragDisabled]="isDragDisabled"

      >
      <div class="right-source-top">
        <div *ngIf="!bracket.topTeam; else hasTopTeam">
          <p>
            {{ (bracket.topSource?.description && r1Sub?.description) ? topTitle : 'TBA' }}

        </p>
        </div>
        <ng-template #hasTopTeam>
          <p>{{bracket.topTeam}}</p>
        </ng-template>
      </div>
      <div class="right-bracket-name">
        <div *ngIf="scheduledDate && scheduledTime; else TBA">
          {{bracket.gameName}}
          <div>{{scheduledDate | date: 'EEE MMM dd, YYYY'}} <span>{{scheduledTime}}</span></div>
          {{bracket.venueName}} {{bracket.courtName}} 
        </div>
        <ng-template #TBA>
          TBA
        </ng-template>
      </div>
      <div class="right-source-bottom">
        <div  *ngIf="!bracket.bottomTeam; else hasBottomTeam">
          <p>
            {{ (bracket.bottomSource?.description && r2Sub?.description) ? bottomTitle : 'TBA' }}

        </p>
        </div>
        <ng-template #hasBottomTeam>
          <p>{{bracket.bottomTeam}}</p>
        </ng-template>
      </div>
      <div #box class="right-facing"></div>
    </div>

    <!-- LEFT FACING BRACKET -->
    <div class="container"
      *ngIf="bracket.bracketType == 'LEFT_FACING'"
      #resizeBox
      style="position: absolute"
      [style.width.px]="bracketWidth"
      [style.height.px]="bracketHeight"
      cdkDrag
      [cdkDragFreeDragPosition]="dragPosition"
      [cdkDragDisabled]="isDragDisabled"

      >
      <div class="left-source-top">
        <div *ngIf="!bracket.topTeam; else hasTopTeam">
          <p>
            {{ (bracket.topSource?.description && r1Sub?.description) ? topTitle : 'TBA' }}
        </p>
        </div>
        <ng-template #hasTopTeam>
          {{bracket.topTeam}}
        </ng-template>
      </div>
      <div class="left-bracket-name">
        <div *ngIf="scheduledDate && scheduledTime; else TBA">
          <div>{{scheduledDate | date: 'fullDate'}} <span>{{scheduledTime}}</span></div>
          {{bracket.venueName}} {{bracket.courtName}} 
        </div>
        <ng-template #TBA>
          TBA
        </ng-template>
      </div>
      <div class="left-source-bottom">
        <div  *ngIf="!bracket.bottomTeam; else hasBottomTeam">
          <p>
            {{ (bracket.bottomSource?.description) ? bottomTitle : 'TBA' }}
        </p>
        </div>
        <ng-template #hasBottomTeam>
          {{bracket.bottomTeam}}
        </ng-template>
      </div>
      <div #box class="left-facing"></div>
    </div>

    <div class="container"
      *ngIf="bracket.bracketType == 'BOX'"
      #resizeBox
      style="position: absolute"
      [style.width.px]="bracketWidth"
      [style.height.px]="bracketHeight"
      cdkDrag
      [cdkDragFreeDragPosition]="dragPosition"
      [cdkDragDisabled]="isDragDisabled"

      >
      <div class="box-source-top">
        <div *ngIf="!bracket.topTeam; else hasTopTeam"> 
          <p>
            {{ (bracket.topSource?.description && r1Sub?.description) ? topTitle : 'TBA' }}
        </p>
        </div>
        <ng-template #hasTopTeam>
         <p> {{bracket.topTeam}}</p>
        </ng-template>
      </div>
      <div class="box-bracket-name">
        <div *ngIf="scheduledDate && scheduledTime; else TBA">
          <div>{{scheduledDate | date: 'fullDate'}} <span>{{scheduledTime}}</span></div>
          {{bracket.venueName}} {{bracket.courtName}} p-.l,.
        </div>
        <ng-template #TBA>
          TBA
        </ng-template>
      </div>
      <div class="box-source-bottom">
        <div  *ngIf="!bracket.bottomTeam; else hasBottomTeam">
          <p>
            {{ (bracket.bottomSource?.description && r2Sub?.description) ? bottomTitle : 'TBA' }}
        </p>
        </div>
        <ng-template #hasBottomTeam>
          <p>{{bracket.bottomTeam}}</p>
        </ng-template>
      </div>
        
      <div #box class="box-bracket"></div>
    </div>

    <div class="container"
      *ngIf="bracket.bracketType == 'MIDDLE'"
      #resizeBox
      style="position: absolute"
      [style.width.px]="bracketWidth"
      [style.height.px]="bracketHeight"
      cdkDrag
      [cdkDragFreeDragPosition]="dragPosition"
      [cdkDragDisabled]="isDragDisabled"

      >
      <div *ngIf="!bracket.topTeam; else hasTopTeam"  class="middle-source-top">
        <p>
          {{ (bracket.topSource?.description && r1Sub?.description) ? topTitle : 'TBA' }}
        </p>
      </div>
      <ng-template #hasTopTeam>
        {{bracket.topTeam}}
      </ng-template>
      <div class="middle-bracket-name">
        <div *ngIf="scheduledDate && scheduledTime; else TBA">
          <div>{{scheduledDate | date: 'fullDate'}} <span>{{scheduledTime}}</span></div>
          {{bracket.venueName}} {{bracket.courtName}} 
        </div>
        <ng-template #TBA>
          TBA
        </ng-template>
      </div>
      <div  *ngIf="!bracket.bottomTeam; else hasBottomTeam" class="dp-row-middle-bottom">
        <p>
          {{ (bracket.bottomSource?.description && r2Sub?.description) ? bottomTitle : 'TBA' }}
        </p>
      </div>
      <ng-template #hasBottomTeam>
        {{bracket.bottomTeam}}
      </ng-template>
      <div #box class="middle-bracket"></div>
    </div>

    <div class="container"
      *ngIf="bracket.bracketType == 'BRACKET_WINNER'"
      #resizeBox
      style="position: absolute"
      [style.width.px]="bracketWidth"
      [style.height.px]="bracketHeight"
      cdkDrag
      [cdkDragFreeDragPosition]="dragPosition"
      [cdkDragDisabled]="isDragDisabled"

      >
      <div #box class="winner-bracket"></div>
      <div *ngIf="!bracket.topTeam; else hasTopTeam"  class="winner-bracket-name">
        <!-- <p>{{topSelectedOption?.description}}  -  {{r1Sub?.description}}</p> -->
        <p>{{winningTeam}}</p>
        <!-- <p>{{winningTeam}} - {{gameName}}</p> -->
      </div>
      <ng-template #hasTopTeam >
        <div  class="winner-bracket-name">
          {{bracket.topTeam}}
        </div>
      </ng-template>
      <div class="champion-name">
        Champion
      </div>
    </div>
</div>
