import { Component, Inject, Input, OnInit } from '@angular/core';
// PRIME
import {
  DynamicDialogRef,
  DynamicDialogConfig,
  DialogService,
} from 'primeng/dynamicdialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-team',
  templateUrl: './confirm-team.component.html',
  styleUrls: ['./confirm-team.component.scss'],
  providers: [DialogService],
})
export class ConfirmTeamComponent implements OnInit {
  message: string;
  tournament: any;
  playerLimit: number;
  @Input() msg: string;

  constructor(
    public reff: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public dialogRef: MatDialogRef<ConfirmTeamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.message = this.data.message;
    this.message = this.msg;
  }

  ngOnInit(): void {
    this.message = this.data.message;
  }


  confirm() {
    this.dialogRef.close('YES');
  }
}
