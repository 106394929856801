<div class="bg-white pb-3 main">
  <div
    class="section-header text-white"
    [class.lower-sticky]="hasPotentialVenues"
  >
    TEAM SCHEDULE
  </div>
  <p-card>
    <div *ngIf="postSchedule == false; else fetchData">
      <div class="text-center p-4">
        <label class="TheBoldFont-Bold fs-3">Team Schedule Not Posted.</label>
      </div>
    </div>

    <ng-template #fetchData>
      <div *ngIf="isFetching; else alreadyFetched" class="text-center p-3">
        <p-progressSpinner
          [style]="{ width: '40px', height: '40px' }"
        ></p-progressSpinner>
      </div>
    </ng-template>

    <ng-template #alreadyFetched>
      <div *ngIf="games.length > 0; else noData">
        <div class="py-4 px-4" *ngFor="let schedule of schedules">
          <div class="sched-details-wrapper sub-main">
            <div
              class="sched-date TheBoldFont-Bold"
              [class.sub-main-lower-sticky]="hasPotentialVenues"
              *ngIf="schedule.date"
            >
              {{ schedule.date | date : "EEEE - MMMM d, y" }}
            </div>
            <div
              class="header-container"
              [class.sub-main-header-lower-sticky]="hasPotentialVenues"
              *ngIf="!isScreen850Below"
            >
              <div class="schedule-header">
                <div class="division" style="width: 19%">Division</div>
                <div class="game-number" style="width: 10%">Game</div>
                <div class="sched-court" style="width: 19%">Location</div>
                <div class="pool-games" style="width: 26%">
                  <span class="team-location" style="width: 40%"
                    >Away Team</span
                  >
                  <span class="team-location" style="width: 40%"
                    >Home Team</span
                  >
                </div>
                <div class="game-score text-center" style="width: 13%">
                  Score
                </div>
                <div class="game-status text-center" style="width: 13%">
                  Status
                </div>
              </div>
              <hr />
            </div>
            <div *ngFor="let timeslot of sortTimeslots(schedule.timeslots)">
              <div class="TheBoldFont-Bold sched-time" *ngIf="timeslot.time">
                {{ timeslot.time }}
              </div>
              <div class="schedules-main-container">
                <div
                  *ngFor="let game of sortGames(timeslot.games); let i = index"
                  [ngClass]="
                    isScreen450Below || !isScreen850Below
                      ? 'p-col-12'
                      : 'p-col-6'
                  "
                >
                  <!-- List View -->
                  <div class="schedule-container" *ngIf="!isScreen850Below">
                    <div
                      class="division"
                      style="width: 19%"
                      (click)="openDivision(game?.division)"
                    >
                      {{ game.division?.name }}
                    </div>
                    <div class="sched-court" style="width: 10%">
                      <div class="court">{{ getGameNumber(game?.name) }}</div>
                      <div class="venue">{{ game.pool?.name }}</div>
                    </div>
                    <div
                      class="sched-court"
                      style="width: 19%"
                      (click)="openVenueSchedule(game.court?.venueId)"
                    >
                      <div class="court">{{ game.court?.name }}</div>
                      <div class="venue">{{ game.court?.venueName }}</div>
                      <!-- BELOW IS JUST TO ADD THE venueName TO 'GAMES PLAYED AT' -->
                      <div
                        class="venue"
                        *ngIf="addToSureVenuesList(game.court?.venueName)"
                      ></div>
                    </div>
                    <div style="width: 26%">
                      <div *ngIf="game.team1 || game.team2">
                        <div class="pool-games">
                          <div class="text-center" style="width: 40%">
                            <div class="div-p-col-6 team-info">
                              <div
                                *ngIf="game.team1"
                                class="team-name"
                                (click)="openTeam(game.team1)"
                              >
                                {{ game.team1?.name }}
                              </div>
                              <div *ngIf="!game.team1" class="team-name">
                                TBA
                              </div>
                            </div>
                          </div>
                          <div class="versus" style="width: 20%">vs</div>
                          <div class="text-center" style="width: 40%">
                            <div class="div-p-col-6 team-info">
                              <div
                                *ngIf="game.team2"
                                class="team-name"
                                (click)="openTeam(game.team2)"
                              >
                                {{ game.team2?.name }}
                              </div>
                              <div *ngIf="!game.team2" class="team-name">
                                TBA
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="game.team1 || game.team2" style="width: 13%">
                      <div class="pool-score">
                        <div class="px-2 text-center">
                          <div class="div-p-col-6 team-info text-center">
                            <span class="team-score text-center">{{
                              game.team1Score
                            }}</span>
                          </div>
                        </div>
                        <div class="versus">—</div>
                        <div class="px-2 text-center">
                          <div class="div-p-col-6 team-info text-center">
                            <span class="team-score text-center">{{
                              game.team2Score
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="game.gameEnded; else notFinal"
                      class="game-number text-center"
                      style="width: 13%"
                    >
                      Final
                    </div>
                    <ng-template #notFinal>
                      <div
                        *ngIf="game.period == null || ''; else gamePeriod"
                        class="game-number text-center"
                        style="width: 13%"
                      ></div>
                      <ng-template #gamePeriod>
                        <div class="game-number text-center" style="width: 13%">
                          {{ game.period }}
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>

                  <!-- Grid View -->
                  <div
                    class="schedule-container-grid-view"
                    *ngIf="isScreen850Below"
                  >
                    <div class="division" (click)="openDivision(game.division)">
                      {{ game.division.name }}
                    </div>
                    <div class="sched-court">
                      <div class="court">{{ getGameNumber(game.name) }}</div>
                      <div class="venue">{{ game.pool?.name }}</div>
                    </div>
                    <div
                      *ngIf="game.gameEnded; else notFinal"
                      class="game-number final"
                    >
                      FINAL
                    </div>
                    <div class="pool-score" *ngIf="game.team1 || game.team2">
                      <div class="score">
                        <span class="team-score text-center">{{
                          game?.team1Score == 0 ? "" : game.team1Score
                        }}</span>
                      </div>
                      <div class="score">
                        <span class="team-score text-center">{{
                          game?.team2Score == 0 ? "" : game.team2Score
                        }}</span>
                      </div>
                    </div>
                    <div class="pool-games">
                      <div class="div-p-col-6 team-info" style="width: 40%">
                        <div
                          *ngIf="game.team1"
                          class="team-name"
                          (click)="openTeam(game.team1)"
                        >
                          {{ game.team1?.name }}
                        </div>
                        <div *ngIf="!game.team1" class="source-name">
                          <strong>
                            {{ fetchFromBracketDetails(game).top1 }}</strong
                          >
                          <div class="source-description">
                            {{
                              (fetchFromBracketDetails(game).refType1 == "TEAM"
                                ? ""
                                : fetchFromBracketDetails(game).bottom1
                              ) | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="versus"
                        [class.higher]="
                          game?.team1Score != 0 || game?.team2Score != 0
                        "
                        style="width: 20%"
                      >
                        vs
                      </div>
                      <div class="div-p-col-6 team-info" style="width: 40%">
                        <div
                          *ngIf="game.team2"
                          class="team-name"
                          (click)="openTeam(game.team2)"
                        >
                          {{ game.team2?.name }}
                        </div>
                        <div *ngIf="!game.team2" class="source-name">
                          <strong>{{
                            fetchFromBracketDetails(game).top2
                          }}</strong>
                          <div class="source-description">
                            {{
                              (fetchFromBracketDetails(game).refType2 == "TEAM"
                                ? ""
                                : fetchFromBracketDetails(game).bottom2
                              ) | titlecase
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="sched-court"
                      (click)="openVenueSchedule(game.court.venueId)"
                    >
                      <div class="venue">{{ game.court?.venueName }}</div>
                      <div class="court">{{ game.court?.name }}</div>
                    </div>
                    <ng-template #notFinal>
                      <div
                        *ngIf="game.period == null || ''; else gamePeriod"
                        class="game-number"
                      ></div>
                      <ng-template #gamePeriod>
                        <div>
                          <div class="game-number">
                            {{ game.period }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>

      <ng-template #noData>
        <div class="text-center p-4">
          <label class="TheBoldFont-Bold fs-3"
            >No schedules for this team.</label
          >
        </div>
      </ng-template>
    </ng-template>
  </p-card>
</div>
