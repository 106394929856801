import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bracket-stage-preview',
  templateUrl: './bracket-stage-preview.component.html',
  styleUrls: ['./bracket-stage-preview.component.scss']
})
export class BracketStagePreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
