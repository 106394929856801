<div class="container">
  <form
    [formGroup]="editTeamForm"
    class="mx-auto py-4 addteamwrapper addTeamForm"
  >
    <div>
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"
        >Team Photo</span
      >
    </div>
    <p-fileUpload
      name="fileLogo[]"
      accept="image/*"
      [customUpload]="true"
      (onSelect)="onSelectLogo($event)"
      [showUploadButton]="true"
      [showCancelButton]="false"
      (uploadHandler)="upload($event)"
    >
      <!-- <ng-template pTemplate="toolbar">
          <div>Upload 3 Files</div>
        </ng-template> -->
      <!-- <ng-template let-file pTemplate="file">
          <div>Custom UI to display a file</div>
        </ng-template> -->
      <ng-template pTemplate="content" let-files>
        <div>
          <img [src]="logoUrl" alt="Logo" width="150px" height="150px" />
        </div>
      </ng-template>
    </p-fileUpload>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >Team Name</span
      >
    </div>
    <div>
      <input
        id="name"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="Team Name"
        formControlName="name"
        maxlength="40"

      />
    </div>

    <small
      *ngIf="
        editTeamForm.controls['name'].invalid &&
        (editTeamForm.controls['name'].dirty ||
          editTeamForm.controls['name'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="editTeamForm.controls['name'].errors.required"
        class="mb-3 ml-2"
      >
        Team name is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >Team Abbreviation</span
      >
    </div>
    <div>
      <input
        id="abbreviation"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="Team Abbreviation"
        formControlName="abbreviation"
      />
    </div>
    <small
      *ngIf="
        editTeamForm.controls['abbreviation'].invalid &&
        (editTeamForm.controls['abbreviation'].dirty ||
          editTeamForm.controls['abbreviation'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="editTeamForm.controls['abbreviation'].errors.required"
        class="mb-3 ml-2"
      >
        Team abbreviation is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >City</span
      >
    </div>
    <div>
      <input
        id="city"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="City"
        formControlName="city"
      />
    </div>

    <small
      *ngIf="
        editTeamForm.controls['city'].invalid &&
        (editTeamForm.controls['city'].dirty ||
          editTeamForm.controls['city'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="editTeamForm.controls['city'].errors.required"
        class="mb-3 ml-2"
      >
        City is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >State</span
      >
    </div>
    <div>
      <input
        id="state"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="State"
        formControlName="state"
      />
    </div>

    <small
      *ngIf="
        editTeamForm.controls['state'].invalid &&
        (editTeamForm.controls['state'].dirty ||
          editTeamForm.controls['state'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="editTeamForm.controls['state'].errors.required"
        class="mb-3 ml-2"
      >
        State is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Gender</span
      >
    </div>

    <div class="radio-buttons">
      <p-radioButton
        name="gender"
        value="M"
        formControlName="gender"
        label="Male"
        inputId="male"
      >
      </p-radioButton>
      <p-radioButton
        name="gender"
        value="F"
        formControlName="gender"
        inputId="female"
        label="Female"
      >
      </p-radioButton>
      <div></div>
    </div>

    <small
      *ngIf="
        editTeamForm.controls['gender'].invalid &&
        (editTeamForm.controls['gender'].dirty ||
          editTeamForm.controls['gender'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="editTeamForm.controls['gender'].errors.required"
        class="mb-3 ml-2"
      >
        Gender is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Grade/Age Group</span
      >
    </div>

    <div class="radio-buttons">
      <p-radioButton
        class="tk-radioButton"
        name="group"
        value="GRADE"
        label="Grade"
        formControlName="group"
        inputId="grade"
      ></p-radioButton>
      <p-radioButton
        class="tk-radioButton"
        name="group"
        value="AGE"
        label="Age"
        formControlName="group"
        inputId="age"
      ></p-radioButton>
      <div></div>
    </div>

    <div class="" *ngIf="isAgeGroup">
      <label
        for="gender"
        class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Select an Age Group <span class="required-indicator"></span
      ></label>
      <div class="full-dropdown">
        <p-dropdown
          [options]="ageGroup"
          placeholder="Select an Age Group"
          optionLabel="label"
          optionValue="ageGroup"
          styleClass="p-col-12 mx-0"
          formControlName="ageGroup"
        ></p-dropdown>
      </div>
    </div>
    <div class="" *ngIf="isGradeGroup">
      <label
        for="gender"
        class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Select a Grade Group <span class="required-indicator"></span
      ></label>
      <div class="full-dropdown">
        <p-dropdown
          [options]="gradeGroup"
          placeholder="Select a Grade Group"
          optionLabel="label"
          optionValue="gradeGroup"
          styleClass="p-col-12 mx-0"
          formControlName="gradeGroup"
        ></p-dropdown>
      </div>
    </div>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Team Privacy</span
      >
    </div>

    <div class="radio-buttons">
      <p-radioButton
        name="teamPrivate"
        [value]="false"
        formControlName="teamPrivate"
        inputId="teamPrivate"
        label="Public"
      >
      </p-radioButton>
      <p-radioButton
        name="teamPrivate"
        [value]="true"
        formControlName="teamPrivate"
        inputId="teamPrivate"
        label="Private"
      >
      </p-radioButton>
      <div></div>
      <!-- <label for="female">Private</label> -->
    </div>

    <div class="flex flex-center my-4">
      <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
        CANCEL
      </button>

      <button
        type="submit"
        [disabled]="!editTeamForm.valid"
        (click)="submitHandler()"
        class="btn-rounded-red mx-2"
      >
        SAVE
      </button>
    </div>
  </form>
</div>
