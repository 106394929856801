export const SidebarMenu = [
  {
    name: 'Organizations',
    list: [
      {
        name: 'Dashboard', //former overview//
        path: 'overview',
        icon: 'pi-home',
      },
      {
        name: 'Tournaments',
        path: 'tournaments-list',
        icon: 'pi-sitemap',
      },
      {
        name: " Coach's Check-In",
        path: 'coach-check-in',
        icon: 'pi-user-edit',
      },
    ],
  },
];
