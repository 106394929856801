<div class="container">
  <div>
    <div class="p-col-12 flex flex-center">
      <!-- <form [formGroup]="editForm" class="container"> -->
      <div class="p-col-5 text-center">
        <label class="text-bold1 text-center p-0 mb-1 team-name">{{
          match.team1?.name
        }}</label>
        <input
          type="text"
          (keypress)="numericOnly($event)"
          placeholder=""
          maxlength="3"
          [(ngModel)]="team1Score"
        />
      </div>

      <div class="p-col-5 text-center">
        <label class="text-bold1 text-center p-0 mb-1 team-name">{{
          match.team2?.name
        }}</label>
        <input
          type="text"
          (keypress)="numericOnly($event)"
          maxlength="3"
          placeholder=""
          [(ngModel)]="team2Score"
        />
      </div>

      <!-- </form> -->
    </div>
    <div class="p-col-12 text-center">
      <p-checkbox
        class="tk-checkbox"
        name="tourney-standings"
        [value]="gameEnded"
        label="Mark As Final:"
        [binary]="true"
        [(ngModel)]="gameEnded"
        style="gap: 25px; flex-direction: row-reverse; font-weight: bold"
      ></p-checkbox>
    </div>
    <div class="p-col-12 text-center flex-center flex">
      <div class="full-dropdown p-col-6">
        <p-dropdown
          [options]="periodList"
          [(ngModel)]="selectedPeriod"
          [placeholder]="'Select Period'"
          optionLabel="key"
          styleClass="autocomplete"
          dropdownIcon="pi pi-angle-down"
          appendTo="body"
          style="height: 20px"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="p-col-12 flex flex-center">
      <button
        pButton
        iconPos="right"
        type="button"
        label="Cancel"
        class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
        (click)="close()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Submit"
        class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
        (click)="editGames()"
      ></button>
    </div>
  </div>
</div>
