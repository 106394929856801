import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService, ToastService } from '@core/services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private toastService: ToastService,

  ) { }

  ngOnInit(): void {
    this.changeForm = this.formBuilder.group({
      oldPassword: [''],
      newPassword: [''],
      newPassword1: ['']
    });
  }
 changePassword(){
   const {oldPassword, newPassword, newPassword1} = this.changeForm.value;
   if(newPassword !== newPassword1){
    this.toastService.toast('Failed', 'Password mismatch!' , 'error');

   }else{
    this.auth.changePassword(oldPassword, newPassword);

   }
 }
}
