<div class="features-container">
  <div class="left-container">
    <h1>MAIN FEATURES</h1>
    <span>Click each feature to learn more</span>
    <button
      class="btn-features"
      (click)="
        buttonClicked(
          'Done in real time, upload once the game finishes, and parents & fans can follow along without being there.',
          [
            'assets/img/score.png',
            'assets/img/score2.png',
            'assets/img/score3.png',
            'assets/img/score4.png'
          ]
        )
      "
    >
      <fa-icon class="faicon" [icon]="faRobot"></fa-icon>
      <label>Automated Scoring</label>
    </button>
    <button
      class="btn-features"
      (click)="
        buttonClicked(
          'Team registration and payment processing is free to use and can be applied on any website. Accept any kind of payment, from registration fees, and track payments using our platform.',
          ['assets/img/reg1.png', 'assets/img/reg2.png', 'assets/img/reg3.png']
        )
      "
    >
      <fa-icon class="faicon" [icon]="faRegistered"></fa-icon>
      <label>Team Registration</label>
    </button>
    <button
      class="btn-features"
      (click)="
        buttonClicked(
          'A user friendly bracket builder that gives the director all the control. Our easy to use tools help you quickly generate pool play, create custom brackets, select brackets from our template or copy one from a previous tournament.',
          ['assets/img/bra1.png', 'assets/img/bra2.png', 'assets/img/bra3.png']
        )
      "
    >
      <fa-icon class="faicon" [icon]="faHammer"></fa-icon>
      <label>Bracket Builder</label>
    </button>
    <a href="/features">See All Features</a>
  </div>
  <div class="right-container">
    <div *ngIf="description" class="description-container">
      {{ description }}
    </div>
    <div class="carousel-container" *ngIf="imageSources.length > 0">
      <div
        class="carousel"
        [ngStyle]="{ transform: 'translateX(' + translateX + 'px)' }"
      >
        <div class="carousel-item" *ngFor="let image of imageSources">
          <img [src]="image" style="width: 100%; display: block" />
        </div>
      </div>
      <button (click)="prev()" class="carousel-control prev">&lt;</button>
      <button (click)="next()" class="carousel-control next">&gt;</button>
    </div>
    <div class="video-container" [class.show]="show && videoId">
      <youtube-player
        *ngIf="videoId"
        [height]="playerHeight"
        [width]="playerWidth"
        [videoId]="videoId"
      ></youtube-player>
    </div>
  </div>
</div>
