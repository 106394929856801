import { Tournament } from './tournament';
import { TkModel } from './tk-model';

export enum GradeGroupLabelEnum {
  // ADULT = "18+ or Older",
  ADULT = 'Adult',
  HIGH_SCHOOL = 'High School (9th-12th Grade)',
  MIDDLE_SCHOOL = 'Middle School (6th-8th Grade)',
  ELEMENTARY_SCHOOL = 'Elementary School (1st-5th Grade)',
  GRADE_12 = '12th Grade',
  GRADE_11 = '11th Grade',
  GRADE_10 = '10th Grade',
  GRADE_9 = '9th Grade',
  GRADE_8 = '8th Grade',
  GRADE_7 = '7th Grade',
  GRADE_6 = '6th Grade',
  GRADE_5 = '5th Grade',
  GRADE_4 = '4th Grade',
  GRADE_3 = '3rd Grade',
  GRADE_2 = '2nd Grade',
  GRADE_1 = '1st Grade',
}

export enum GradeGroupEnum {
  ADULT = 'ADULT',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
  ELEMENTARY_SCHOOL = 'ELEMENTARY_SCHOOL',
  GRADE_12 = 'GRADE_12',
  GRADE_11 = 'GRADE_11',
  GRADE_10 = 'GRADE_10',
  GRADE_9 = 'GRADE_9',
  GRADE_8 = 'GRADE_8',
  GRADE_7 = 'GRADE_7',
  GRADE_6 = 'GRADE_6',
  GRADE_5 = 'GRADE_5',
  GRADE_4 = 'GRADE_4',
  GRADE_3 = 'GRADE_3',
  GRADE_2 = 'GRADE_2',
  GRADE_1 = 'GRADE_1',
}

export const GRADE_GROUP_TYPES: GradeGroupType[] = [
  {
    gradeGroup: GradeGroupEnum.GRADE_1,
    label: GradeGroupLabelEnum.GRADE_1,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_2,
    label: GradeGroupLabelEnum.GRADE_2,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_3,
    label: GradeGroupLabelEnum.GRADE_3,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_4,
    label: GradeGroupLabelEnum.GRADE_4,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_5,
    label: GradeGroupLabelEnum.GRADE_5,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_6,
    label: GradeGroupLabelEnum.GRADE_6,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_7,
    label: GradeGroupLabelEnum.GRADE_7,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_8,
    label: GradeGroupLabelEnum.GRADE_8,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_9,
    label: GradeGroupLabelEnum.GRADE_9,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_10,
    label: GradeGroupLabelEnum.GRADE_10,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_11,
    label: GradeGroupLabelEnum.GRADE_11,
  },
  {
    gradeGroup: GradeGroupEnum.GRADE_12,
    label: GradeGroupLabelEnum.GRADE_12,
  },
];

export interface GradeGroupType {
  gradeGroup: string;
  label: string;
}

export interface TournamentGradeGroup extends TkModel {
  id: number;
  tournament: Tournament;
  ageGroup?: GradeGroupEnum[];
  version?: number;
}
