import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], field: string, order: string = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return array;
    }

    array.sort((a: any, b: any) => {
      let aValue = a[field];
      let bValue = b[field];

      if (aValue == null) aValue = ''; // handle null or undefined values
      if (bValue == null) bValue = '';

      let comparison = 0;
      if (aValue > bValue) {
        comparison = 1;
      } else if (aValue < bValue) {
        comparison = -1;
      }

      return order === 'desc' ? comparison * -1 : comparison;
    });

    return array;
  }

}
