import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class CoachTeamsService {
  constructor(private http: HttpService) {}

  getCoachTeams(userId: any) {
    return this.http.get({
      url: `${environment.api.games}/assigned?tournamentId=${userId}`,
      payload: [],
    });
  }

  getTeams(userId) {
    return this.http.get({
      url: `${environment.api.coachTeams}?userId=${userId}`,
    });
  }

  getCoachPlayers(userId) {
    return this.http.get({
      // url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/coaches/teams?userId=${userId}`
      url: `${environment.api.coachPlayers}?userId=${userId}`,
    });
  }

  getTeam(id) {
    return this.http.get({
      url: `${environment.api.teams}/${id}`,
    });
  }

  addTeam(formValue) {
    return this.http
      .post({
        url: `${environment.api.teams}`,
        payload: formValue,
      })
      .toPromise();
  }

  getTeamById(teamId: any) {
    return this.http.get({
      // url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/${teamId}`
      url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/${teamId}`,
    });
  }
  deleteTeam(selectedTeamId) {
    return this.http.delete({
      // url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/${selectedTeamId}`
      url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/${selectedTeamId}`,
    });
  }

  savePlayer(payload: any) {
    return this.http.post({
      url: `${environment.api.players}`,
      payload: payload,
    });
  }

  deletePlayer(playerId: any) {
    return this.http.delete({
      url: `${environment.api.players}/${playerId}`,
    });
  }

  getPlayers(payload: any) {
    return this.http.get({
      url: `${environment.api.players}`,
      payload: payload,
    });
  }
}
