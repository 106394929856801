import { Component, OnInit } from '@angular/core';
import {
  faCalendar,
  faLaptop,
  faMobile,
  faRegistered,
  faRobot,
  faToolbox,
  faPhone,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faHammer } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-public-support',
  templateUrl: './public-support.component.html',
  styleUrls: ['./public-support.component.scss'],
})
export class PublicSupportComponent implements OnInit {
  faRobot = faRobot;
  faAddressCard = faAddressCard;
  faPen = faPen;
  faChartLine = faChartLine;
  faHammer = faHammer;
  faRegistered = faRegistered;
  faCalendar = faCalendar;
  faToolbox = faToolbox;
  faMobile = faMobile;
  faLaptop = faLaptop;
  faPhone = faPhone;
  faEnvelope = faEnvelope;

  searchText = '';
  filteredButtons = [];
  originalButtons = [
    {
      icon: 'assets/img/schedule.svg',
      label: 'Scheduling App',
      clickHandler: () => this.openScheduleModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/payment.svg',
      label: 'Payments',
      clickHandler: () => this.openPaymentModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/whistle.svg',
      label: 'Coach’s Check-In',
      clickHandler: () => this.openCheckInModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/coach.svg',
      label: 'Coach Account',
      clickHandler: () => this.openCoachModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/account-icon.svg',
      label: 'Account',
      clickHandler: () => this.openAccountModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/bracket.svg',
      label: 'Brackets',
      clickHandler: () => this.openBracketsModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/analytics-icon.svg',
      label: 'Analytics',
      clickHandler: () => this.openAnalyticsModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/app-icon.svg',
      label: 'Scoring App',
      clickHandler: () => this.openAppModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/communication.svg',
      label: 'Communication',
      clickHandler: () => this.openCommunicationModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/division-and-standings.svg',
      label: 'Division and Standings',
      clickHandler: () => this.openDivisionModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/store.svg',
      label: 'Event Store',
      clickHandler: () => this.openEventModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/general.svg',
      label: 'General',
      clickHandler: () => this.openGeneralModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/integration.svg',
      label: 'Integrations',
      clickHandler: () => this.openIntegrationsModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/marketing.svg',
      label: 'Marketing',
      clickHandler: () => this.openMarketingModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/monitors.svg',
      label: 'Monitors',
      clickHandler: () => this.openMonitorsModal(),
      tooltip: 'Click to learn more',
    },
    {
      icon: 'assets/img/copying-icon.svg',
      label: 'Copying, Templates, Imports, and Exports',
      clickHandler: () => this.openCopyingModal(),
      tooltip: 'Click to learn more',
    },
  ];
  buttons = [...this.originalButtons];
  items = ['account', 'analytics', 'app', 'brackets'];

  displayModal = false;
  modalTitle = '';
  modalContent = '';

  constructor() {}

  ngOnInit(): void {
    this.buttons = this.sortButtons(this.buttons);
  }
  filterButtons(event: any) {
    const searchText = event.target.value.toLowerCase();
    if (searchText) {
      this.buttons = this.originalButtons.filter((button) =>
        button.label.toLowerCase().includes(searchText)
      );
    } else {
      this.buttons = [...this.originalButtons];
    }
  }
  openScheduleModal() {
    this.displayModal = true;
    this.modalTitle = 'Scheduling App';
    this.modalContent =
      'Parents, coaches, players, and tournament directors can access information, schedules, venues, and more through our exclusive Scheduling App. (Available on Google Play and the IOS App Store)';
  }
  openPaymentModal() {
    this.displayModal = true;
    this.modalTitle = 'Payments';
    this.modalContent =
      'Easily submit, and view payment and registration information through your Coach or Tournament Director account. Tournament Directors can easily view information regarding payments through their account dashboard.';
  }
  openCheckInModal() {
    this.displayModal = true;
    this.modalTitle = 'Coach’s Check-In';
    this.modalContent =
      'Tournament Directors can start their coaches check-in at any time through their account. Easily view payment status, team information, and more through this unique part of our software.';
  }
  openCoachModal() {
    this.displayModal = true;
    this.modalTitle = 'Coach Account';
    this.modalContent =
      ' Browse tournaments, update rosters, view your schedule, and more through your own account.';
  }

  openAccountModal() {
    this.displayModal = true;
    this.modalTitle = 'Account';
    this.modalContent =
      'Tournament Directors, Coaches, and Players will have their own account.';
  }
  openBracketsModal() {
    this.displayModal = true;
    this.modalTitle = 'Brackets';
    this.modalContent =
      'Create custom brackets, or use a template, that are automatically updated and filled as teams progress through your tournament.';
  }
  openDivisionModal() {
    this.displayModal = true;
    this.modalTitle = 'Division and Standings';
    this.modalContent =
      'Standings and game scores are instantly updated as games are played. Scores can be uploaded through the Tournament Director account, or uploaded through our Scoring App once each game is played.';
  }
  openIntegrationsModal() {
    this.displayModal = true;
    this.modalTitle = 'Integrations';
    this.modalContent =
      'Our Tournament Director website, TourneyKey Scoring App, and TourneyKey Scheduling app are integrated in a way that allows players, coaches, parents, and tournament directors to easily upload and view tournament scores, schedules, and information.';
  }
  openCopyingModal() {
    this.displayModal = true;
    this.modalTitle = 'Copying,Templates,Imports, and Exports';
    this.modalContent =
      'You have the ability to quickly and easily copy information related to tournaments, brackets, team details, venues, and more through your organization’s tournaments. You also have the ability to export information from your current tournaments to a spreadsheet.';
  }
  openAnalyticsModal() {
    this.displayModal = true;
    this.modalTitle = 'Analytics';
    this.modalContent =
      'Seamlessly view stats, box scores, and in-depth tournament information through your Tournament Director account.';
  }
  openEventModal() {
    this.displayModal = true;
    this.modalTitle = 'EventStore™';
    this.modalContent = 'Coming Soon!';
  }
  openMonitorsModal() {
    this.displayModal = true;
    this.modalTitle = 'Monitors';
    this.modalContent =
      'Monitor tournament information, payments, players, coaches, and teams through your Tournament Director account. A complete overview of your tournament(s) will be displayed, allowing you to have total control of your organization.';
  }
  openMarketingModal() {
    this.displayModal = true;
    this.modalTitle = 'Marketing';
    this.modalContent =
      'TourneyKey will provide marketing that allows your tournament to reach its full potential.';
  }
  openAppModal() {
    this.displayModal = true;
    this.modalTitle = 'Scoring App';
    this.modalContent =
      'With our TourneyKey Scoring app, Tournament Directors have the ability to run a tournament as smoothly as possible with real-time score and game updates. (Available on Google Play and the IOS App Store)';
  }
  openCommunicationModal() {
    this.displayModal = true;
    this.modalTitle = 'Communication';
    this.modalContent =
      'Simple, fast, and efficient communication with coaches through your Tournament Director account.';
  }
  openGeneralModal() {
    this.displayModal = true;

    this.modalTitle = 'General';
    this.modalContent =
      'TourneyKey aims to deliver an efficient, customized, and comprehensive tournament solution, enabling players, coaches, and directors to experience a seamless and in-depth tournament season. Please contact our support team (support@tourneykey.com) to learn more about specific questions or to schedule an information meeting with one of our staff members.';
  }
  sortButtons(originalButtons: any[]) {
    return originalButtons.sort((a, b) => a.label.localeCompare(b.label));
  }
}
