import { Component, OnInit } from '@angular/core';

// PAGE NAVIGATION
import { SidebarMenu } from './data';

// SERVICE
import { EmitterService } from '@services/emitter.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-staff-sidebar',
  templateUrl: './staff-sidebar.component.html',
  styleUrls: ['./staff-sidebar.component.scss'],
})
export class StaffSidebarComponent implements OnInit {
  items: any = SidebarMenu;

  constructor(private emitterService: EmitterService, private router: Router) {}

  ngOnInit(): void {}

  loadPage(list: any): void {
    this.emitterService.pageNameEmit(list.name);
  }

  gotoHomepage() {
    this.router.navigate(['/']);
  }
}
