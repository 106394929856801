import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmTeamComponent } from '@components/modal/confirm-team/confirm-team.component';
import { MessageComponent } from '@components/modal/message/message.component';
import { defConstant } from '@core/data/constant-data';
import { Player } from '@core/models/players';
import { Teams } from '@core/models/teams';
import { HttpService, ToastService } from '@core/services';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-existing-coach',
  templateUrl: './add-existing-coach.component.html',
  styleUrls: ['./add-existing-coach.component.scss']
})
export class AddExistingCoachComponent implements OnInit {

  headers: any = [
    {
      id: 'firstName',
      value: 'First Name',
      textStyles: 'text-black3 text-bold1',
    },
    {
      id: 'lastName',
      value: 'Last Name',
      textStyles: 'text-black3 text-bold1',
    },
    {
      id: 'role',
      value: 'Role',
    },
    {
      id: 'city',
      value: 'City',
    },
    {
      id: 'state',
      value: 'State',
    },
  ];

  data = null;
  coaches: any[] = [];
  meta: any = {};
  q: string;
  fetching: boolean = false;
  showConfirm: boolean = false;
  selectedCoach: Player;

  constructor(
    public ref: DynamicDialogRef,
    private httpService: HttpService,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private publicTournament: PublicTournamentsService,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.data = this.config.data;

    this.initCoaches();
  }

  pageChanged(event: any) {}

  async initCoaches() {
    const me = await this.httpService.me().toPromise();
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.coachAssistants}?coachId=${me.id}`,
          payload: [],
        })
        .toPromise();

      this.coaches = list;
      console.log(list);
      // this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }


  async getCoaches(action: string, meta: any) {
    const me = await this.httpService.me().toPromise();
    this.fetching = true;
    try {
      const list = await this.httpService
        .get({
          url: `${environment.api.coachAssistants}?coachId=${me.id}`,
          payload: [],
        })
        .toPromise();

      this.coaches = list.items;
      this.meta = list.meta;

      this.fetching = false;
    } catch (err) {
      console.log(err);
    }
  }

  previous() {
    this.getCoaches('previous', this.meta);
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    this.getCoaches('index', meta);
  }

  next() {
    this.getCoaches('next', this.meta);
  }


  search() {
    let meta: any = Object.assign({}, this.meta);
    meta.search = this.q;
    this.getCoaches('search', meta);
  }

  async selectCoach(event: any) {
    let existingCoaches = this.data?.result?.existingCoaches
    this.selectedCoach = event.data;
    this.selectedCoach.team = this.data?.result?.team

    if(existingCoaches.find(coach => coach?.id == this.selectedCoach?.id)) {
      return this.toastService.errorMsg({message: 'This coach was already added.'});
    }

    this.ref.close(this.selectedCoach);
  }

  close(): void {
    this.ref.close(null)
  }

}
