<div class="container">
  <div class="bg-white">
    <!-- <div
      class="divisions-header"
      [ngStyle]="{ 'background-color': secondaryColor, color: fontColor }"
    > -->
    <div
      class="divisions-header"
      [ngStyle]="{ 'background-color': 'black', color: 'white' }"
    >
      DIVISIONS
    </div>
    <p-card>
      <div class="p-grid">
        <div class="row divisionCardsRow">
          <div
            *ngFor="let division of divisions"
            class="p-col-12 division-container"
          >
            <div class="division-panel">
              <div
                class="container-header p-col-12 text-left"
                style="font-size: 1.2em; font-weight: bold"
              >
                <!-- <p-chip
                    styleClass="division-chip"
                    [label]="division.name"
                  ></p-chip> -->
                <div
                  class="custom-chip"
                  [ngClass]="[isBlack ? 'black-font' : 'white-font']"
                  [ngStyle]="{
                    'background-color': primaryColor,
                    color: fontColorOpposite,
                    'border-radius': '1rem'
                  }"
                >
                  {{ division.name }}
                </div>
                <!-- <button
                  *ngIf="registration?.requireRegister && !isReview && checkDate()"
                  pButton
                  pRipple
                  type="button"
                  [label]="'REGISTER HERE'"
                  class="p-button my-1"
                  class="btn-register"
                  (click)="openRegister(division)"
                ></button> -->
              </div>

              <div *ngIf="division.resultsMessage; else noDescription">
                <div class="division-description">
                  {{ division.resultsMessage }}
                </div>
              </div>
              <ng-template #noDescription> </ng-template>

              <app-division-teams
                [divisionId]="division.id"
                [tournamentId]="tournamentId"
                *ngIf="tournament.showTeamName"
              ></app-division-teams>

              <div
                *ngIf="!isReview"
                class="p-col-12 container-register text-center"
                (click)="openRegister(division)"
              >
                <!-- <button
                  pButton
                  label="Register Here"
                  style="width: 160px;"
                  (click)="openRegister(division)"
                  class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
                ></button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
</div>
