import { Component, OnInit } from '@angular/core';

// SERVICES
import { HttpService } from '@services/index';
import { TournamentsService } from '@core/services/tournaments.service';

// PRIME
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';

// CONSTANT
import { defConstant } from '@data/constant-data';

// ENV
import { environment } from '@env/environment';
import { PoolsModalComponent } from '@components/admin/pools-modal/pools-modal.component';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss'],
})
export class SchedulingComponent implements OnInit {
  items: MenuItem[] = [
    { label: 'Info' },
    { label: 'Teams' },
    { label: 'Brackets' },
    // { label: 'Create Pools' },
    { label: 'Manage Pools' },
    { label: 'Manage Games' },
    /*{ label: 'Schedule Restrictions' },*/
  ];

  tabMenu = {
    items: this.items,
    active: this.items[0],
  };

  divisionInfo = null;
  divisions: any = [];
  teams: any = [];
  pools: any = [];
  userId = null;
  selectedDivIndex: number;
  isDivInfoSelect: boolean = false;
  isFetchingDiv: boolean = true;
  isFetchingTeams: boolean = true;
  isFetchingPools: boolean = true;
  hasResult: boolean = true;

  ref: DynamicDialogRef;

  constructor(
    private dialogService: DialogService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private tournamentService: TournamentsService
  ) {
    this.isFetchingDiv = true;
    this.tournamentService.reloadDivision.subscribe((res) => {
      this.getDivisions();
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      if (params.get('id') != null) {
        this.tournamentService.division$.pipe(take(1)).subscribe((division) => {
          console.log('iddasdad', division);
          this.showDivisionForm(division, -1);
        });
      }
    });

    this.getDivisions();

    // this.get();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.divisions, event.previousIndex, event.currentIndex);
    this.processSortedData();
  }
  processSortedData() {
    this.divisions.forEach((value, index) => {
      this.divisions[index].seqNo = index;
    });
    this.tournamentService.postDivisionSorted(this.divisions);
  }
  async getDivisions() {
    this.tournamentService.getDivisionList();

    this.getDivisionList();
  }
  async get() {
    try {
      const me = await this.httpService.me().toPromise();
      this.userId = me.id;
      this.getTournamentDivisions();
    } catch (err) {}
  }

  getDivisionList() {
    setTimeout(() => {
      this.isFetchingDiv = true;
    }, 1000);
    this.tournamentService.divisionList$.subscribe((res) => {
      if (res != null) {
        this.divisions = res;
        this.isFetchingDiv = false;
      }
    });
  }

  async getTournamentDivisions() {
    this.divisions = [];
    this.isFetchingDiv = true;
    this.hasResult = true;

    const data = await this.httpService
      .get({
        url: environment.api.usersTournaments,
        payload: {
          userId: this.userId,
        },
      })
      .toPromise();

    if (data.length > 0) {
      data.map(async (tourna) => {
        const division = await this.httpService
          .get({
            url: environment.api.divisions,
            payload: {
              tournamentId: tourna.id,
            },
          })
          .toPromise();

        if (division.length > 0) {
          this.divisions = [...division, ...this.divisions];
        }
      });

      this.isFetchingDiv = false;
    } else {
      this.hasResult = false;
      this.isFetchingDiv = false;
    }
  }


  setActiveTabMenu(e): void {
    const val = e.innerText;
    this.activeMenu(val);
  }

  activeMenu(name) {
    const activeTab = this.getActive(name);
    this.tabMenu.active = activeTab?.label ? activeTab : this.tabMenu.active;
  }

  addPool(division, pool = null, isAdd: boolean = true): void {
    const { id, gender, name, maxTeams } = division;

    const newDivision = {
      id,
      label: name,
      gender,
      maxTeams,
      value: id,
    };

    this.ref = this.dialogService.open(PoolsModalComponent, {
      header: 'Pools',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        isAdd,
        result: {
          division: newDivision,
          pool,
        },
      },
    });

    this.ref.onClose.subscribe((res: any) => {
      if (res) {
        this.getTournamentDivisions();
      }
    });
  }

  showDivisionForm(division, index): void {
    console.log('index ', index);
    this.selectedDivIndex = index;
    this.activeMenu('Info');
    this.tournamentService.division$.next(division);
    this.divisionInfo = division;
    this.isDivInfoSelect = true;
    console.log(this.divisionInfo);
  }

  getActive(val) {
    const findItem = this.items.find((item) => {
      return val == item.label;
    });
    return findItem ? findItem : {};
  }
}
