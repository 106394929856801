import { Component, HostListener, OnInit } from '@angular/core';
import { HttpService } from '@core/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '@env/environment';
import { log } from 'console';

@Component({
  selector: 'app-game-scores',
  templateUrl: './game-scores.component.html',
  styleUrls: ['./game-scores.component.scss'],
})
export class GameScoresComponent implements OnInit {
  activeIndex: number = 0;
  gameId: number = 0;
  gameName: string;
  gameDate: any;
  divisionName: string;
  divisionNumPeriod = 4;
  is1Period = false;
  is2Period = false;
  is4Period = false;
  savedGame: any;
  rawData: any;
  logData: any;
  logDataWithScores: any[] = [];
  innerWidth: any;
  awayTeam1stPeriod: number = 0;
  awayTeam2ndPeriod: number = 0;
  awayTeam3rdPeriod: number = 0;
  awayTeam4thPeriod: number = 0;
  awayTeamOT: number = 0;
  homeTeam1stPeriod: number = 0;
  homeTeam2ndPeriod: number = 0;
  homeTeam3rdPeriod: number = 0;
  homeTeam4thPeriod: number = 0;
  homeTeamOT: number = 0;

  awayScore: number = 0;
  homeScore: number = 0;

  awayTeamTotalFieldGoals: number = 0;
  awayTeamTotalThreePoints: number = 0;
  awayTeamTotalFreeThrows: number = 0;
  awayTeamTotalPoints: number = 0;
  awayTeamTotalPersonalFouls: number = 0;
  awayTeamTotalOREB: number = 0;
  awayTeamTotalDRB: number = 0;
  awayTeamTotalRebounds: number = 0;
  awayTeamTotalAssists: number = 0;
  awayTeamTotalSteals: number = 0;
  awayTeamTotalBlocks: number = 0;
  awayTeamTotalTimeouts: number = 0;

  homeTeamTotalFieldGoals: number = 0;
  homeTeamTotalThreePoints: number = 0;
  homeTeamTotalFreeThrows: number = 0;
  homeTeamTotalPoints: number = 0;
  homeTeamTotalPersonalFouls: number = 0;
  homeTeamTotalOREB: number = 0;
  homeTeamTotalDRB: number = 0;
  homeTeamTotalRebounds: number = 0;
  homeTeamTotalAssists: number = 0;
  homeTeamTotalSteals: number = 0;
  homeTeamTotalBlocks: number = 0;
  homeTeamTotalTimeouts: number = 0;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.activeIndex = this.config.data.activeIndex;
    this.gameId = this.config.data.gameId;
    this.gameName = this.config.data.gameName;
    this.gameDate = this.config.data.gameDate;
    this.divisionName = this.config.data.divisionName;
    this.divisionNumPeriod = this.config.data.divisionNumPeriod;
    if (this.divisionNumPeriod == 1) {
      this.is1Period = true;
    } else if (this.divisionNumPeriod == 2) {
      this.is2Period = true;
    } else {
      this.is4Period = true;
    }
    this.getSavedGames();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  async getSavedGames() {
    try {
      const savedGame = await this.httpService
        .get({
          url: `${environment.api.savedGame}/${this.gameId}`,
          payload: {},
        })
        .toPromise();

      this.savedGame = savedGame;
      this.rawData = JSON.parse(this.savedGame.rawData);
      this.logData = JSON.parse(this.savedGame.logData);
      this.logData.sort((a, b) => b.updatedAt - a.updatedAt);
      console.log('rawdataa', this.rawData);
      console.log('logDataa', this.logData);

      this.getLogDataWithScores(this.logData);
      this.computeTeamTotals(
        this.rawData.awayPlayers,
        this.rawData.homePlayers
      );
    } catch (err) {
      console.error(err);
    }

    this.getTeamPoints();
  }

  getLogDataWithScores(logData) {
    let awayScore = 0;
    let homeScore = 0;

    logData.forEach((data) => {
      if (data.action == 'pt3' || data.action == 'pt2' || data.action == 'ft') {
        if (data.isHome) {
          switch (data.action) {
            case 'pt3':
              homeScore = homeScore + 3;
              break;
            case 'pt2':
              homeScore = homeScore + 2;
              break;
            case 'ft':
              homeScore = homeScore + 1;
              break;
          }
        } else {
          switch (data.action) {
            case 'pt3':
              awayScore = awayScore + 3;
              break;
            case 'pt2':
              awayScore = awayScore + 2;
              break;
            case 'ft':
              awayScore = awayScore + 1;
              break;
          }
        }
      }
    });

    logData.forEach((data) => {
      let body;

      body = {
        ...data,
        awayScore: awayScore,
        homeScore: homeScore,
      };

      this.logDataWithScores?.push(body);

      if (data.action == 'pt3' || data.action == 'pt2' || data.action == 'ft') {
        if (data.isHome) {
          switch (data.action) {
            case 'pt3':
              homeScore = homeScore - 3;
              break;
            case 'pt2':
              homeScore = homeScore - 2;
              break;
            case 'ft':
              homeScore = homeScore - 1;
              break;
          }
        } else {
          switch (data.action) {
            case 'pt3':
              awayScore = awayScore - 3;
              break;
            case 'pt2':
              awayScore = awayScore - 2;
              break;
            case 'ft':
              awayScore = awayScore - 1;
              break;
          }
        }
      }
    });
  }

  getTeamPoints() {
    this.rawData?.awayPlayers.forEach((player) => {
      this.awayTeam1stPeriod =
        this.awayTeam1stPeriod +
        player.periodStats[0].pt2 * 2 +
        player.periodStats[0].pt3 * 3 +
        player.periodStats[0].ft;
      this.awayTeam2ndPeriod =
        this.awayTeam2ndPeriod +
        player.periodStats[1].pt2 * 2 +
        player.periodStats[1].pt3 * 3 +
        player.periodStats[1].ft;
      this.awayTeam3rdPeriod =
        this.awayTeam3rdPeriod +
        player.periodStats[2].pt2 * 2 +
        player.periodStats[2].pt3 * 3 +
        player.periodStats[2].ft;
      this.awayTeam4thPeriod =
        this.awayTeam4thPeriod +
        player.periodStats[3].pt2 * 2 +
        player.periodStats[3].pt3 * 3 +
        player.periodStats[3].ft;
      if (player.periodStats.length > 4) {
        for (var i = 4; i <= player.periodStats.length; i++) {
          this.awayTeamOT =
            this.awayTeamOT +
            player.periodStats[i].pt2 * 2 +
            player.periodStats[i].pt3 * 3 +
            player.periodStats[i].ft;
        }
      }
    });

    this.rawData?.homePlayers.forEach((player) => {
      this.homeTeam1stPeriod =
        this.homeTeam1stPeriod +
        player.periodStats[0].pt2 * 2 +
        player.periodStats[0].pt3 * 3 +
        player.periodStats[0].ft;
      this.homeTeam2ndPeriod =
        this.homeTeam2ndPeriod +
        player.periodStats[1].pt2 * 2 +
        player.periodStats[1].pt3 * 3 +
        player.periodStats[1].ft;
      this.homeTeam3rdPeriod =
        this.homeTeam3rdPeriod +
        player.periodStats[2].pt2 * 2 +
        player.periodStats[2].pt3 * 3 +
        player.periodStats[2].ft;
      this.homeTeam4thPeriod =
        this.homeTeam4thPeriod +
        player.periodStats[3].pt2 * 2 +
        player.periodStats[3].pt3 * 3 +
        player.periodStats[3].ft;
      if (player.periodStats.length > 4) {
        for (var i = 4; i <= player.periodStats.length; i++) {
          this.homeTeamOT =
            this.homeTeamOT +
            player.periodStats[i].pt2 * 2 +
            player.periodStats[i].pt3 * 3 +
            player.periodStats[i].ft;
        }
      }
    });
  }

  getCountPerPlayer(playerStats, name) {
    let newValue = 0;
    playerStats.forEach((quarter) => {
      newValue = newValue + quarter[name];
    });
    return newValue;
  }

  getCountTotalsPerPlayer(playerStats, name1, name2, name3?) {
    let newValue = 0;
    playerStats.forEach((quarter) => {
      newValue = newValue + quarter[name1] + quarter[name2] + quarter[name3];
    });
    return newValue;
  }

  getOvertimePoints(playerStats) {
    let statsLength = playerStats.length;
    let points = 0;
    if (statsLength > 4) {
      for (let index = 4; index <= statsLength; index++) {
        points =
          points +
          playerStats[index].pt2 * 2 +
          playerStats[index].pt3 * 3 +
          playerStats[index].ft;
      }
    }
    return points;
  }

  getLogFromLog(logData, action) {
    if (action == 'cw-hc') {
      return ' | Head coach is given a warning';
    } else if (action == 'cw-ac') {
      return ' | Assistant coach is given a warning';
    }
    let split = logData.split('|');
    let first = split[0];
    let second = split[1];
    let third = split[2];
    if (second == undefined && third == undefined) {
      return first;
    } else {
      return first + '|' + second;
    }
  }

  getTeamFromLog(logData) {
    let split = logData.split(' ');
    return split[0];
  }

  getPeriodString(period: number) {
    switch (period) {
      case 1:
        return '1st';
      case 2:
        return '2nd';
      case 3:
        return '3rd';
      case 4:
        return '4th';
    }
  }

  computeTeamTotals(awayPlayers, homePlayers) {
    awayPlayers.forEach((player) => {
      this.awayTeamTotalFieldGoals += this.getCountTotalsPerPlayer(
        player.periodStats,
        'pt2',
        'pt3'
      );
      this.awayTeamTotalThreePoints += this.getCountPerPlayer(
        player.periodStats,
        'pt3'
      );
      this.awayTeamTotalFreeThrows += this.getCountPerPlayer(
        player.periodStats,
        'ft'
      );
      this.awayTeamTotalPoints += this.getCountTotalsPerPlayer(
        player.periodStats,
        'pt2',
        'pt3',
        'ft'
      );
      this.awayTeamTotalPersonalFouls += this.getCountTotalsPerPlayer(
        player.periodStats,
        'rf',
        'tf'
      );
      this.awayTeamTotalOREB += this.getCountPerPlayer(
        player.periodStats,
        'oreb'
      );
      this.awayTeamTotalDRB += this.getCountPerPlayer(
        player.periodStats,
        'pt3'
      );
      this.awayTeamTotalRebounds += this.getCountTotalsPerPlayer(
        player.periodStats,
        'oreb',
        'dreb'
      );
      this.awayTeamTotalAssists += this.getCountPerPlayer(
        player.periodStats,
        'ast'
      );
      this.awayTeamTotalSteals += this.getCountPerPlayer(
        player.periodStats,
        'stl'
      );
      this.awayTeamTotalBlocks += this.getCountPerPlayer(
        player.periodStats,
        'blk'
      );
      this.awayTeamTotalTimeouts += this.getCountPerPlayer(
        player.periodStats,
        'to'
      );
    });

    homePlayers.forEach((player) => {
      this.homeTeamTotalFieldGoals += this.getCountTotalsPerPlayer(
        player.periodStats,
        'pt2',
        'pt3'
      );
      this.homeTeamTotalThreePoints += this.getCountPerPlayer(
        player.periodStats,
        'pt3'
      );
      this.homeTeamTotalFreeThrows += this.getCountPerPlayer(
        player.periodStats,
        'ft'
      );
      this.homeTeamTotalPoints += this.getCountTotalsPerPlayer(
        player.periodStats,
        'pt2',
        'pt3',
        'ft'
      );
      this.homeTeamTotalPersonalFouls += this.getCountTotalsPerPlayer(
        player.periodStats,
        'rf',
        'tf'
      );
      this.homeTeamTotalOREB += this.getCountPerPlayer(
        player.periodStats,
        'oreb'
      );
      this.homeTeamTotalDRB += this.getCountPerPlayer(
        player.periodStats,
        'pt3'
      );
      this.homeTeamTotalRebounds += this.getCountTotalsPerPlayer(
        player.periodStats,
        'oreb',
        'dreb'
      );
      this.homeTeamTotalAssists += this.getCountPerPlayer(
        player.periodStats,
        'ast'
      );
      this.homeTeamTotalSteals += this.getCountPerPlayer(
        player.periodStats,
        'stl'
      );
      this.homeTeamTotalBlocks += this.getCountPerPlayer(
        player.periodStats,
        'blk'
      );
      this.homeTeamTotalTimeouts += this.getCountPerPlayer(
        player.periodStats,
        'to'
      );
    });
  }

  sortPlayerByJerseyNumber(players) {
    let sortedPlayers = players?.sort(
      (a, b) => a.jerseyNumber - b.jerseyNumber
    );
    return sortedPlayers;
  }

  showProperDisplay(point) {
    if (point > 0) {
      return point;
    } else {
      return '-';
    }
  }
  close() {
    this.ref.close();
  }
}
