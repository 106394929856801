import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[tourneykeyRequiredInputIndicator]'
})
export class RequiredInputIndicatorDirective {

   // need this to be public so associated directives can access it
  constructor(public element: ElementRef) { }

}
