<form
  [formGroup]="loginForm"
  class="p-4 login-wrapper text-center"
  (ngSubmit)="login()"
>
  <h3 class="TheBoldFont-Bold m-2 fs-7">Login</h3>

  <input
    formControlName="email"
    class="input-text login-text"
    type="text"
    placeholder="Email"
  />

  <section class="text-right py-2">
    <span class="login-placeholder text-right" (click)="showResetPassword()"
      >Forgot password?</span
    >
  </section>
  <input
    formControlName="password"
    class="input-text login-text"
    type="password"
    placeholder="Password"
  />

  <section class="my-2">
    <p-checkbox label="Keep me signed in"></p-checkbox>
  </section>

  <div class="flex flex-center flex-column mt-4">
    <div class="flex flex-center flex-row">
      <div class="">
        <button type="submit" class="btn-rounded-white" (click)="close()">
          Cancel
        </button>
        <button
          type="submit"
          class="btn-rounded-red"
          [disabled]="!loginForm.valid || isLoginClick"
        >
          Login
        </button>
      </div>
    </div>

    <section class="my-2">
      New to TourneyKey?
      <span class="login-placeholder">Create an Account</span>
    </section>
  </div>
</form>
