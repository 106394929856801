<app-public-header></app-public-header>

<div class="support-container">
  <div class="container-features">
    <div class="container-desc p-grid j-center" style="width: 100vw">
      <div class="faq">
        <h1>FAQs</h1>
      </div>

      <!-- WORKING -->
      <!-- DE>LETE THIS COMMENTS -->
      <app-shared-modal
        [(visible)]="displayModal"
        [modalTitle]="modalTitle"
        [modalContent]="modalContent"
      ></app-shared-modal>

      <div class="p-col-12 text-center">
        <input
          class="input-text"
          [(ngModel)]="searchText"
          (input)="filterButtons($event)"
          placeholder="Search FAQs"
        />
        <h3
          class="TheBoldFont-Bold fs-3"
          style="color: white; margin-bottom: -10px"
        >
          Click topic to learn more
        </h3>
      </div>

      <div class="p-col-6 container-list button-grid">
        <button
          class="btn-features"
          *ngFor="let button of buttons; let last = last"
          (click)="button.clickHandler()"
          pTooltip="{{ button.tooltip }}"
          tooltipPosition="top"
          [style.grid-column]="last ? '2 / span 1' : 'auto / span 1'"
        >
          <img [src]="button.icon" style="height: 30px; width: 32px" />
          <label> {{ button.label }} </label>
        </button>
      </div>
      <!-- <div class="container-list" style="margin-top: 30px"> 
        <div class="list-item" *ngFor="let image of fi  lteredData">
          <button class="btn-features">
            <img src="{{ image.url }}" style="height: 30px; width: 32px" />
            <label> {{ image.alt }} </label>
          </button>
        </div>
      </div>-->
      <!-- <div *ngIf="!searchText" class="p-col-6 container-list">
        <button class="btn-features" (click)="openAccountModal()">
          <img
            src="assets/img/account-icon.svg"
            style="height: 30px; width: 32px"
          />
          <label> Account </label>
        </button>

        <button class="btn-features" (click)="openBracketsModal()">
          <img src="assets/img/bracket.svg" style="height: 30px; width: 32px" />
          <label> Brackets </label>
        </button>
        <button class="btn-features" (click)="openIntegrationsModal()">
          <img
            src="assets/img/integration.svg"
            style="height: 30px; width: 32px"
          />
          <label> Integrations </label>
        </button>
      </div>
      <div *ngIf="!searchText" class="p-col-6 container-list">
        <button class="btn-features" (click)="openDivisionModal()">
          <img
            src="assets/img/division-and-standings.svg"
            style="height: 30px; width: 32px"
          />
          <label> Division and Standings </label>
        </button>
        <button class="btn-features" (click)="openCopyingModal()">
          <img
            src="assets/img/copying-icon.svg"
            style="height: 30px; width: 32px"
          />
          <label> Copying,Templates,Imports </label>
        </button>
        <button class="btn-features" (click)="openAnalyticsModal()">
          <img
            src="assets/img/analytics-icon.svg"
            style="height: 30px; width: 32px"
          />
          <label> Analytics </label>
        </button>
      </div>

      <div *ngIf="!searchText" class="p-col-6 container-list">
        <button class="btn-features" (click)="openEventModal()">
          <img src="assets/img/store.svg" style="height: 30px; width: 32px" />
          <label> EventStore&trade; </label>
        </button>
        <button class="btn-features" (click)="openMonitorsModal()">
          <img
            src="assets/img/monitors.svg"
            style="height: 30px; width: 32px"
          />
          <label> Monitors </label>
        </button>
        <button class="btn-features" (click)="openMarketingModal()">
          <img
            src="assets/img/marketing.svg"
            style="height: 30px; width: 32px"
          />
          <label> Marketing </label>
        </button>
      </div>
      <div *ngIf="!searchText" class="p-col-6 container-list">
        <button class="btn-features" (click)="openAppModal()">
          <img
            src="assets/img/app-icon.svg"
            style="height: 30px; width: 32px"
          />
          <label> App </label></button
        ><button class="btn-features" (click)="openCommunicationModal()">
          <img
            src="assets/img/communication.svg"
            style="height: 30px; width: 32px"
          />
          <label> Communication </label>
        </button>
        <button
          class="btn-features"
          (click)="openGeneralModal(true, 'General', 'COntent')"
        >
          <img src="assets/img/general.svg" style="height: 30px; width: 32px" />
          <label> General </label>
        </button>
      </div> -->
    </div>
  </div>
  <div class="contact">
    <div class="contact-text">
      <h1>Need more help?</h1>
      <h1>Contact Us</h1>
      <p>Support Staff</p>
    </div>
    <div class="contact-icons">
      <a
        href="mailto:support@tourneykey.com"
        style="text-decoration: none; color: #000"
      >
        <fa-icon
          class="faicon"
          transform="shrink-9"
          [icon]="faEnvelope"
        ></fa-icon>
      </a>
      <a href="mailto:support@tourneykey.com">support@tourneykey.com</a>
    </div>
  </div>
</div>
