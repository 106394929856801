import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Division } from '@core/models/division';
import { GamePeriod } from '@core/models/game-period';
import { Games } from '@core/models/games';
import { Venue } from '@core/models/venue';
import { HttpService } from '@core/services';
import { BracketService } from '@core/services/bracket-service';
import { DivisionService } from '@core/services/division.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { VenueService } from '@core/services/venue.service';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-public-divisions',
  templateUrl: './public-divisions.component.html',
  styleUrls: ['./public-divisions.component.scss'],
})
export class PublicDivisionsComponent implements OnInit {
  @Input() organizationId: number;

  division: Division;
  divisionId: number;
  games: Games[];
  game: Games;
  doesHaveGames: boolean = false;
  dateAvailable: Schedule[] = [];
  tournamentId: any;
  pools: any[] = [];
  gamePeriods: GamePeriod[] = [];
  allGames: any[] = [];
  games$ = new BehaviorSubject<Games[]>([]);
  isLoadingBracket = true;
  isFetching: boolean;
  schedules: any = [];
  logoUrl: string = '';
  isReview: boolean = false;
  venues: any[] = null;
  isLoading = true;

  primaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  hasChampionTeam = false;
  championTeam: string;

  innerWidth: any;
  isScreenLess1440: boolean;
  isScreenLess850: boolean;
  isScreenLess450: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService,
    private _http: HttpService,
    private divisionService: DivisionService,
    private publicTournamentService: PublicTournamentsService,
    private venueService: VenueService,
    private bracketService: BracketService
  ) {}

  ngOnInit(): void {
    this.onResize('event');
    this.primaryColor = JSON.parse(sessionStorage.getItem('org'))?.primaryColor;

    this.route.paramMap.subscribe(async (params) => {
      this.isReview = this.publicTournamentService.isReview;
      this.isReview = JSON.parse(localStorage.getItem('isReview')) ?? false;

      this.tournamentId = params.get('tournamentId');
      this.divisionId = parseInt(params.get('divisionId'));

      this.division = await this.divisionService.getDivision(this.divisionId);
      this.logoUrl = `${environment.api.tournamentPhoto}/${this.division.tournament.photo}`;

      this.getStandings();
      this.getScheduledGames();
      // this.getVenues();
    });

    this.bracketService.championTeam$.subscribe((champ) => {
      if (champ) {
        this.hasChampionTeam = true;
        this.championTeam = champ;
      } else {
        this.hasChampionTeam = false;
      }
    });
  }

  /**
   * Gets the standings of all the pools in the division.
   */
  async getStandings() {
    await this.httpService
      .get({
        url: `${environment.api.divisionsPools}?divisionId=${this.divisionId}`,
        payload: [],
      })
      .toPromise()
      .then((pools: any) => {
        if (null == this.primaryColor) {
          this.primaryColor = '#a90000';
          this.fontColor = '#ffffff';
          this.fontColorOpposite = '#000000';
        } else {
          this.primaryColor = this.primaryColor;
          this.getFontColor(this.primaryColor);
        }
        this.pools = pools;

        this.pools.forEach((pool: any) => {
          this.getPoolStandings(pool);
        });
      })
      .catch((err) => {
        console.log('Failed to fetch the pools');
      });
  }

  /**
   * Gets the standings within the pool
   *
   * @param pool the pool the get the standings for
   */
  async getPoolStandings(pool: any) {
    await this.httpService
      .get({
        url: `${environment.api.standings}?poolId=${pool.id}`,
        payload: [],
      })
      .toPromise()
      .then((standings: any) => {
        pool.standings = standings;
      })
      .catch((err) => {
        console.log('Failed to fetch the pools');
      });
  }

  async getVenues() {
    this.isLoading = true;
    try {
      const allVenues = await this.venueService
        .getVenues(this.tournamentId, this.organizationId)
        .toPromise();

      // Create a set of venue IDs that are associated with the games
      const venueIdsInGames = new Set();
      for (const schedule of this.schedules) {
        for (const timeslot of schedule.timeslots) {
          for (const game of timeslot.games) {
            venueIdsInGames.add(game?.court?.venueId);
          }
        }
      }

      // Filter the venues that match the venue IDs associated with the games
      this.venues = allVenues.filter((venue) => venueIdsInGames.has(venue.id));
    } catch (error) {
      console.error('Error fetching venues:', error);
    } finally {
      this.isLoading = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth <= 1440) {
      this.isScreenLess1440 = true;
    } else {
      this.isScreenLess1440 = false;
    }

    if (this.innerWidth <= 850) {
      this.isScreenLess850 = true;
    } else {
      this.isScreenLess850 = false;
    }

    if (this.innerWidth <= 450) {
      this.isScreenLess450 = true;
    } else {
      this.isScreenLess450 = false;
    }
  }

  getScheduledGames() {
    const url = `${environment.api.assignedGames}?divisionId=${this.divisionId}`;

    this.httpService
      .get({
        url: url,
      })
      .toPromise()
      .then(
        (games) => {
          if (games?.length > 0) {
            this.games = games;

            let schedules: any[] = [];
            let schedule: any = null;
            let lastDate: string = '';

            games.forEach((game: Games) => {
              const date: string = moment(game.date).format('YYYYMMDD');
              if (lastDate !== date) {
                if (null !== schedule) schedules.push(schedule);
                lastDate = date;
                schedule = { date: game.date, timeslots: [] };
              }
              let timeslot = schedule.timeslots.find((group: any) => {
                return (
                  group.time?.toLowerCase() == game.timeslot?.toLowerCase()
                );
              });
              if (!timeslot) {
                timeslot = { time: game.timeslot, games: [] };
                schedule.timeslots.push(timeslot);
              }
              timeslot.games.push(game);
            });
            if (null !== schedule) schedules.push(schedule);

            this.schedules = schedules;
          }

          setTimeout((_) => {
            this.isFetching = true;
          }, 2000);
          return this.getVenues();
        },
        (err) => {
          setTimeout((_) => {
            this.isFetching = true;
          }, 2000);
        }
      );
  }

  getRank(i: number) {
    if (i == 1) return '1st';
    else if (i == 2) return '2nd';
    else if (i == 3) return '3rd';
    else return i + 'th';
  }

  openTeam(e: Event, teamId: number, teamName: string, standings?: any) {
    e.stopPropagation();

    this.router.navigate([
      `/public-team-games/${this.tournamentId}/${teamId}/${teamName}/${this.divisionId}`,
    ]);
    //"public-team-games/{{ tournamentId }}/{{standings?.teamId}}/{{ standings?.teamName }}">
  }

  openVenue(venueId: number) {
    this.router.navigate([
      `/public-venue-schedules/${this.tournamentId}/${venueId}`,
    ]);
  }

  getFontColor(primary: string) {
    let pRed = primary.substring(1, 3);
    let pGreen = primary.substring(3, 5);
    let pBlue = primary.substring(5, 7);
    let primaryInt =
      parseInt(pRed, 16) * 0.299 +
      parseInt(pGreen, 16) * 0.587 +
      parseInt(pBlue, 16) * 0.114;

    if (primaryInt > 150) {
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }
  }

  goToTournamentPublicPage() {
    this.router.navigate([
      '/public-tournament/atx-winter-havoc/divisions/',
      this.tournamentId,
    ]);
  }
}
