import { Component, OnInit } from '@angular/core';
import { BracketService } from '@core/services/bracket-service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpService, ToastService } from '@services/index';


@Component({
  selector: 'app-remove-team',
  templateUrl: './remove-team.component.html',
  styleUrls: ['./remove-team.component.scss'],
})
export class RemoveTeamComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private bracketService: BracketService,
    private toastService: ToastService,
    private httpService: HttpService,
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.close(false);
  }

  onSubmit() {

    let data = this.config.data;
    
    this.httpService
      .post({
        url: data.url,
        payload: data?.payload
      })
      .subscribe(
        (_) => {
          this.toastService.successMsg({ message: 'Team Successfully Removed.'});
          this.ref.close(1);
        },
        (err) => {
          this.toastService.errorMsg({
            message: err.error.message,
          });
        }
      );

  }
}
