import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmTeamComponent } from '@components/modal/confirm-team/confirm-team.component';
import { MessageComponent } from '@components/modal/message/message.component';
import { defConstant, defStates } from '@core/data/constant-data';
import { GENDER_TYPES_TEAM } from '@core/models/gender-type';
import { AGE_GROUP_TYPES } from '@core/models/tournament-age-group';
import { GRADE_GROUP_TYPES } from '@core/models/tournament-grade-group';
import { HelperService, HttpService, ToastService } from '@core/services';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-team-modal',
  templateUrl: './add-team-modal.component.html',
  styleUrls: ['./add-team-modal.component.scss'],
})
export class AddTeamModalComponent implements OnInit {
  gender = GENDER_TYPES_TEAM;
  gradeGroup = GRADE_GROUP_TYPES;
  ageGroup = AGE_GROUP_TYPES;
  teamsForm: FormGroup;
  data = null;
  team = null;
  cities: any[];
  isCoach: boolean;
  isLogoFetching: boolean;
  hasLogo: boolean;
  isLogoUploaded: boolean;
  logoUrl: any;
  isAgeGroup: boolean = false;
  isGradeGroup: boolean = true;
  isLoading: boolean = false;
  isValidated: boolean = true;
  isTeamValidated: boolean = false;
  registration = null;
  showConfirm: boolean = false;
  message: string =
    'Team has a different age/grade group. Are you sure you want to proceed?';

  states = defStates;

  constructor(
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    private httpService: HttpService,
    private toastService: ToastService,
    private helper: HelperService,
    public config: DynamicDialogConfig,
    private publicTournament: PublicTournamentsService,
    private dialogService: DialogService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let division = this.config?.data?.result?.division;

    this.registration = this.config?.data?.result?.registration;
    this.teamsForm = this.formBuilder.group({
      id: [''],
      name: [
        '',
        // [
        //   Validators.required,
        //   Validators.minLength(2),
        //   Validators.maxLength(50),
        // ],
      ],
      abbreviation: [''],
      city: [''],
      state: [''],
      photo: [''],
      teamPrivate: [true],
      gender: [this.gender[0].value],
      division: [division],
      ageGroup: [null],
      gradeGroup: [null],
      group: new FormControl({ value: 'GRADE', disabled: false }),
      base64Photo: [''],
      teamFacebook: [''],
      teamInstagram: [''],
      teamPhoto: [''],
      teamTwitter: [''],
      teamWebsite: [''],
      teamComment: [''],
    });

    Object.entries(this.registration).find(([key, value]) => {
      if (value == 'REQUIRED') {
        if (key === 'teamPhoto') {
          this.addValidator('base64Photo');
        } else {
          this.addValidator(key);
        }
      }

      this.addValidator('name');
    });

    this.data = this.config.data;

    this.teamsForm.valueChanges.subscribe((form) => {
      if (form.group === 'AGE') {
        this.isAgeGroup = true;
        this.isGradeGroup = false;
      } else if (form.group === 'GRADE') {
        this.isAgeGroup = false;
        this.isGradeGroup = true;
      } else {
        this.isAgeGroup = false;
        this.isGradeGroup = false;
      }
    });

    if (this.data?.result?.team) {
      this.setExistingTeamData(this.data?.result?.team);
    }
  }

  setExistingTeamData(team: any): void {
    console.log('team: ', team);

    this.teamsForm.patchValue({
      id: team?.id,
      name: team?.name,
      abbreviation: team?.abbreviation,
      city: team?.city,
      state: team?.state,
      photo: team?.photo,
      teamPrivate: team?.teamPrivate,
      gender: team?.gender,
      division: team?.division,
      ageGroup: team?.ageGroup,
      gradeGroup: team?.gradeGroup,
      group: team?.gradeGroup ? 'GRADE' : 'AGE',
      base64Photo: team?.base64Photo,
      teamFacebook: team?.teamFacebook,
      teamInstagram: team?.teamInstagram,
      teamPhoto: team?.teamPhoto,
      teamTwitter: team?.teamTwitter,
      teamWebsite: team?.teamWebsite,
      teamComment: team?.teamComment,
    });

    if (team?.photo) {
      this.hasLogo = true;
      this.logoUrl = team?.photo;
    }
  }

  confirm(): void {
    this.showConfirm = false;
    this.isTeamValidated = true;
    this.onSubmit();
  }

  reject(): void {
    this.showConfirm = false;
  }

  async onSubmit() {

    try {
      if (!this.teamsForm.valid) {
        this.isValidated = false;
        return;
      }

      this.isLoading = true;
      console.log('teamsForm: ', this.teamsForm.value);

      const {
        id,
        name,
        abbreviation,
        gender,
        city,
        state,
        base64Photo,
        photo,
        ageGroup,
        gradeGroup,
        division,
        teamFacebook,
        teamInstagram,
        teamPhoto,
        teamTwitter,
        teamWebsite,
        teamComment,
        teamPrivate,
      } = this.teamsForm.value;

      const me = await this.httpService.me().toPromise();

      const body: any = {
        id,
        name,
        abbreviation,
        gender,
        city,
        state,
        coach: me,
        base64Photo,
        photo,
        division,
        ageGroup,
        gradeGroup,
        teamFacebook,
        teamInstagram,
        teamPhoto,
        teamTwitter,
        teamWebsite,
        teamComment,
        teamPrivate,
      };

      console.log('body: ', body);

      if (this.isTeamValidated) {
        this.ref.close(body);
        this.publicTournament.addCoachTeam(body);
        this.isLoading = false;
      }

      if (gender == division.gender || gender.charAt(0) == division.gender) {
        await this.httpService
          .post({
            url: `${environment.api.teamDivisions}/validate`,
            payload: {
              team: body,
              division,
            },
          })
          .toPromise()
          .then((res) => {
            if (res) {
              this.ref.close(body);
              this.publicTournament.addCoachTeam(body);
              this.isLoading = false;
            } else {
              this.isLoading = false;
              this.showConfirm = true;
            }
          });
      } else {
        this.isLoading = false;
        this.showConfirm = true;
      }
    } catch (e) {}
  }

  addValidator(formControlName: string): void {
    console.log('formcontrol: ', formControlName);

    this.teamsForm.controls[formControlName]?.setValidators([
      Validators.required,
    ]);
    this.teamsForm.controls[formControlName]?.updateValueAndValidity();
  }

  generateFormGrp(formCtrl) {
    this.teamsForm.addControl(`team_${formCtrl}`, this.formBuilder.control(''));
    return `team_${formCtrl}`;
  }

  close(): void {
    this.ref.close(null);
  }

  clearLogo(): void {
    this.hasLogo = false;
  }

  searchCity($event) {
    this.httpService
      .get({
        url: `${environment.api.tournamentCities}?city=${$event.query}`,
      })
      .toPromise()
      .then(
        (res: any) => {
          // console.log(res.flat().map(({name})=>name));
          this.cities = res.flat().map(({ name }) => name);
        },
        (err) => {
          console.log('err: ', err);
          this.toastService.errorMsg({ message: err.error.message });
        }
      );
  }

  searchState(event) {
    this.httpService
      .get({
        url: `${environment.api.tournamentStates}?state=${event.query}`,
      })
      .toPromise()
      .then(
        (res: any) => {
          // console.log(res);
          this.states = res.flat().map(({ name }) => name);
        },
        (err) => {
          console.log('err: ', err);
          this.toastService.errorMsg({ message: err.error.message });
        }
      );
  }

  async onSelectLogo(event) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        const newBase64 = readFile.split(',')[1];
        this.teamsForm.get('base64Photo').patchValue(newBase64);
      }
    }

    this.isLogoFetching = true;
    this.isLogoUploaded = true;
    this.hasLogo = true;
    this.logoUrl = '';

    const newFile = event.currentFiles[0];

    this.helper.fileReader(newFile).then(
      (res) => {
        this.logoUrl = res;
        this.teamsForm.get('photo').patchValue(res);
        setTimeout((_) => {
          this.isLogoFetching = false;
        }, 1000);
      },
      (err) => {
        this.isLogoFetching = false;
      }
    );
  }
}
