import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as moment from 'moment';

@Component({
  selector: 'court-schedule-modal',
  templateUrl: './court-schedule-modal.component.html',
  styleUrls: ['./court-schedule-modal.component.scss'],
})
export class CourtScheduleModalComponent implements OnInit {

  startTimeOptions:any = [
    '08:00 AM',
    '08:05 AM',
  ]

  availability:any = {
    startTime: '08:00 AM',
    gameDuration: 60,
  }

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.createOptions(this.config.data.startTime, this.config.data.endTime);
    this.availability = this.config.data.schedule;
  }

  createOptions(startTime: string, endTime: string) {

    let min = moment(startTime, "hh:mm a") // 8:00am
    let max = moment(endTime, "hh:mm a") // 8:00am
    
    let hour = min.hour();
    let minute = min.minute();

    let ndx = 0;

    let timeslot = moment(hour + ':' + minute, 'kk:mm');
    let startTimeOptions = [];

    while (!timeslot.isAfter(max)) {

      let time = timeslot.format('hh:mm a').toUpperCase();
      startTimeOptions.push(time);

      timeslot.add(5, 'minute');
    }

    this.startTimeOptions = startTimeOptions;
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  cancel() {
    this.ref.close(null);
  }

  onSubmit() {
    this.ref.close(this.availability);
  }
}
