<div class="container-browse">
  <app-public-header></app-public-header>
  <div class="container-styles">
    <fa-icon
      (click)="openFilter()"
      [ngClass]="{ red: !isFilter, grey: isFilter }"
      class="faicon"
      [icon]="faFilter"
    ></fa-icon>

    <fa-icon
      (click)="viewGrid()"
      [ngClass]="{ red: isGrid, grey: !isGrid }"
      class="faicon"
      [icon]="faGrid"
    ></fa-icon>
    <fa-icon
      (click)="viewList()"
      [ngClass]="{ red: !isGrid, grey: isGrid }"
      class="faicon"
      [icon]="faList"
    ></fa-icon>
  </div>
  <div class="container-filter" *ngIf="!isFilter">
    <form [formGroup]="formFilter" class="container-form">
      <div class="couple-filters">
        <div class="container-filter-fields">
          <input
            formControlName="name"
            type="text"
            minlength="2"
            maxlength="50"
            pInputText
            placeholder="Tournament Name"
            class="autocomplete-filter"
            style="height: 40px"
          />
        </div>
        <div class="container-filter-fields">
          <p-calendar
            inputStyleClass="autocomplete-filter"
            formControlName="eventDate"
            type="date"
            dateFormat="mm/dd/yy"
            placeholder="Event Date"
            class="browse"
            styleClass="container-calendar"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
          >
          </p-calendar>
        </div>
      </div>
      <div class="couple-filters">
        <div class="container-filter-fields">
          <p-autoComplete
            styleClass="w-100"
            appendTo="body"
            inputStyleClass="autocomplete-filter"
            autocomplete="noauto"
            emptyMessage="No results found"
            formControlName="city"
            placeholder="City"
            [autoHighlight]="true"
            [forceSelection]="false"
            [suggestions]="cities"
            (completeMethod)="searchCity($event)"
            dropdownIcon="pi pi-angle-down"
          >
          </p-autoComplete>
        </div>
        <div class="container-filter-fields">
          <p-dropdown
            id="state"
            [options]="states"
            placeholder="State"
            optionLabel="name"
            optionValue="name"
            styleClass="container-gender"
            formControlName="state"
          ></p-dropdown>
        </div>
      </div>
      <div class="couple-filters">
        <div class="container-filter-fields">
          <p-dropdown
            id="gender"
            [options]="gender"
            placeholder="Gender"
            optionLabel="label"
            optionValue="value"
            styleClass="container-gender"
            formControlName="gender"
          ></p-dropdown>
        </div>
        <div class="icon-buttons">
          <fa-icon
            (click)="searchTournament()"
            class="faicon"
            [icon]="faSearch"
            pTooltip="Search"
          ></fa-icon>
          <div class="fa-layers fa-fw" (click)="resetFilter()">
            <fa-icon
              pTooltip="Reset Filter"
              class="faicon"
              [icon]="faFilter"
            ></fa-icon>
            <fa-icon
              pTooltip="Reset Filter"
              class="faicon"
              [icon]="faTimes"
              [styles]="{
                'font-size': '0.5em',
                color: 'red',
                'text-align': 'center'
              }"
            ></fa-icon>
          </div>
        </div>
      </div>
    </form>
    <div style="padding-bottom: 10px">
      <button
        (click)="publishTournament()"
        pButton
        type="button"
        label="Post Tournament"
        class="p-button-rounded btn-search"
      ></button>
    </div>
  </div>

  <div *ngIf="tournaments" style="margin: 20px 0px; text-align: center">
    <button [disabled]="meta.index == 1" class="btn-link" (click)="previous()">
      « Previous
    </button>
    <button
      [disabled]="meta.index == segment"
      *ngFor="let segment of meta.segments; let i = index"
      class="btn-segment"
      [class.btn-segment-active]="meta.index == segment"
      (click)="navigate(segment)"
    >
      {{ segment }}
    </button>
    <button
      [disabled]="meta.index == meta.totalSegments || meta.totalSize === 0"
      class="btn-link"
      (click)="next()"
    >
      Next »
    </button>
  </div>

  <div *ngIf="isLoading; else notLoading" class="disp-block text-center">
    <p-progressSpinner
      [style]="{
        marginTop: '120px',
        marginBottom: '120px',
        width: '80px',
        height: '80px'
      }"
    ></p-progressSpinner>
  </div>

  <ng-template #notLoading>
    <div class="container-layout">
      <div
        style="padding-bottom: 100px; padding-top: 100px"
        *ngIf="tournaments?.length == 0; else hasTournaments"
        class="text-center TheBoldFont-Bold fs-5 text-black4"
      >
        No Tournaments Found.
      </div>
      <ng-template #hasTournaments>
        <ng-container *ngIf="isGrid; else elseBlock">
          <app-grid-layout [tournaments]="tournaments"> </app-grid-layout>
        </ng-container>
        <ng-template #elseBlock>
          <app-list-layout [tournaments]="tournaments"> </app-list-layout>
        </ng-template>
      </ng-template>
    </div>

    <div *ngIf="tournaments" style="margin: 20px 0px; text-align: center">
      <button
        [disabled]="meta.index == 1"
        class="btn-link"
        (click)="previous()"
      >
        « Previous
      </button>
      <button
        [disabled]="meta.index == segment"
        *ngFor="let segment of meta.segments; let i = index"
        class="btn-segment"
        [class.btn-segment-active]="meta.index == segment"
        (click)="navigate(segment)"
      >
        {{ segment }}
      </button>
      <button
        [disabled]="meta.index == meta.totalSegments || meta.totalSize === 0"
        class="btn-link"
        (click)="next()"
      >
        Next »
      </button>
    </div>
  </ng-template>

  <br />
  <br />
</div>
