    <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="p-grid p-3 coach-container">

            <div class="p-col-12">
                <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
                  >Role
                  <span class="required-indicator"
                    >*</span
                  >
                </label>
            
                <div class="gen-info-dropdown">
                  <p-dropdown
                    [options]="roles"
                    placeholder="Select a role"
                    optionLabel="name"
                    optionValue="value"
                    formControlName="role"
                    autoWidth="false"
                    [style]="{ width: '100%' }"
                  ></p-dropdown>
                </div>
            
                <div
                  *ngIf="!isValidated && form.controls['coachState'].invalid"
                  class="alert"
                >
                  <label class="red-label">State is required.</label>
                </div>
              </div>

        <div class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >First Name <span class="required-indicator">*</span>
          </label>
          <input
            formControlName="firstName"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <div
            *ngIf="!isValidated && form.controls['firstName'].invalid"
            class="alert"
          >
            <label class="red-label">First name is required.</label>
          </div>
        </div>
      
        <div class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Last Name <span class="required-indicator">*</span>
          </label>
          <input
            formControlName="lastName"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <div
            *ngIf="!isValidated && form.controls['lastName'].invalid"
            class="alert"
          >
            <label class="red-label">Last name is required.</label>
          </div>
        </div>
      
        <div class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Email <span class="required-indicator">*</span>
          </label>
          <input
            formControlName="email"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
            placeholder=""
          />
          <div *ngIf="!isValidated && form.controls['email'].invalid" class="alert">
            <label class="red-label">Email is required.</label>
          </div>
        </div>
      
        <div class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Mobile Phone <span class="required-indicator">*</span>
          </label>
          <input
            formControlName="phone"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
            maxlength="12"
            placeholder="+(X) XXX XXX XXXX"
          />
          <div *ngIf="!isValidated && form.controls['phone'].invalid" class="alert">
            <label class="red-label">Mobile Phone is required.</label>
          </div>
        </div>
      
        <div *ngIf="registration?.coachAddress != NOT_REQ" class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Street Address
            <span *ngIf="registration?.coachAddress == REQ" class="required-indicator"
              >*</span
            >
          </label>
          <input
            formControlName="coachAddress"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <div
            *ngIf="!isValidated && form.controls['coachAddress'].invalid"
            class="alert"
          >
            <label class="red-label">Street Address is required.</label>
          </div>
        </div>
      
        <div *ngIf="registration?.coachCity != NOT_REQ" class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >City
            <span *ngIf="registration?.coachCity == REQ" class="required-indicator"
              >*</span
            >
          </label>
          <input
            formControlName="coachCity"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
      
          <div
            *ngIf="!isValidated && form.controls['coachCity'].invalid"
            class="alert"
          >
            <label class="red-label">City is required.</label>
          </div>
        </div>
      
        <div *ngIf="registration?.coachState != NOT_REQ" class="p-col-12">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >State
            <span *ngIf="registration?.coachState == REQ" class="required-indicator"
              >*</span
            >
          </label>
      
          <div class="gen-info-dropdown">
            <p-dropdown
              [options]="states"
              placeholder=" "
              optionLabel="name"
              optionValue="value"
              formControlName="coachState"
              autoWidth="false"
              [style]="{ width: '100%' }"
            ></p-dropdown>
          </div>
      
          <div
            *ngIf="!isValidated && form.controls['coachState'].invalid"
            class="alert"
          >
            <label class="red-label">State is required.</label>
          </div>
        </div>
      
        <!-- <div class="container-fields">
              <label class="py-3 TheOpenSans-Regular text-bold3"
                >Postal Code <span class="required-indicator">*</span>
              </label>
              <input
                formControlName="postal"
                type="text"
                class="tournament-input-text"
              />
              </div> -->
      
        <div
          class="p-col-12"
          *ngIf="registration?.coachFacebook == 'NOT_REQUIRED' ? false : true"
        >
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Facebook Page
            <span
              *ngIf="registration?.coachFacebook == REQ"
              class="required-indicator"
              >*</span
            >
          </label>
          <input
            formControlName="coachFacebook"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <div
            *ngIf="!isValidated && form.controls['coachFacebook'].invalid"
            class="alert"
          >
            <label class="red-label">Facebook Page is required.</label>
          </div>
        </div>
        <div
          class="p-col-12"
          *ngIf="registration?.coachTwitter == 'NOT_REQUIRED' ? false : true"
        >
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Twitter Handle
            <span *ngIf="registration?.coachTwitter == REQ" class="required-indicator"
              >*</span
            >
          </label>
          <input
            formControlName="coachTwitter"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <div
            *ngIf="!isValidated && form.controls['coachTwitter'].invalid"
            class="alert"
          >
            <label class="red-label">Twitter Handle is required.</label>
          </div>
        </div>
        <div
          class="p-col-12"
          *ngIf="registration?.coachInstagram == 'NOT_REQUIRED' ? false : true"
        >
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
            >Instagram Handle
            <span
              *ngIf="registration?.coachInstagram == REQ"
              class="required-indicator"
              >*</span
            >
          </label>
          <input
            formControlName="coachInstagram"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <div
            *ngIf="!isValidated && form.controls['coachInstagram'].invalid"
            class="alert"
          >
            <label class="red-label">Instagram Handle is required.</label>
          </div>
        </div>
      
        <div class="p-col-12 p-0" *ngIf="registration?.additionalCoachInfo != []">
          <div class="p-col-12 p-0" *ngFor="let coachInfo of registration?.additionalCoachInfo">
            <div
              class="p-col-12"
              *ngIf="coachInfo?.status == 'NOT_REQUIRED' ? false : true"
            >
              <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
                >{{ coachInfo?.name }}
                <span *ngIf="coachInfo?.status == REQ" class="required-indicator"
                  >*</span
                >
              </label>
              <input
              *ngIf="coachInfo?.type === 'TEXTBOX'"
              [formControlName]="generateFormGrp(coachInfo?.id)"
              type="text"
              style="width: 100% !important;"
              class="tournament-input-text"
              [ngClass]="isValidated ? null : 'validated'"
            />
            <textarea
              *ngIf="coachInfo?.type === 'TEXTAREA'"
              [formControlName]="generateFormGrp(coachInfo?.id)"
              type="text"
              rows="4"
              class="tournament-inputarea-text mt-3"
              [ngClass]="isValidated ? null : 'validated'"
            ></textarea>
              <!-- <div *ngIf="!isValidated && form.controls['coachInstagram'].invalid" class="alert">
                    <label class="red-label">Instagram Handle is required.</label>
                  </div> -->
            </div>
          </div>
        </div>
      
        <div class="p-col-12 flex flex-center mt-4">
          <button
            pButton
            type="button"
            label="Cancel"
            class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
            (click)="cancel()"
          ></button>
          <button
            pButton
            type="submit"
            label="Submit"
            class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
          ></button>
        </div>
        </div>

      </form>      