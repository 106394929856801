import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// SERVICES
import {
  AuthService,
  HttpService,
  AuthGuardAdminService,
  HelperService,
  ToastService,
  SessionStorageService,
  EmitterService
} from '@core/services';
import { OrderByPipe } from './pipes/order-by.pipe';

@NgModule({
  declarations: [
    
  ],
  imports: [CommonModule],
  providers: [
    AuthService,
    AuthGuardAdminService,
    HttpService,
    HelperService,
    ToastService,
    SessionStorageService,
    EmitterService
  ],
})
export class CoreModule {}
