<p-dialog
  [(visible)]="visible"
  [modal]="true"
  [dismissableMask]="true"
  [responsive]="true"
  [closable]="true"
  [draggable]="false"
  [resizable]="false"
  (onHide)="closeModal()"
  [maximizable]="true"
  styleClass="custom-modal"
>
  <p-header class="header TheBoldFont-Bold fs-4">{{ modalTitle }}</p-header>
  <div class="p-d-flex p-jc-center p-ai-center p-p-4">
    {{ modalContent }}
  </div>
</p-dialog>
