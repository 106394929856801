import { Component, OnInit, Input, Output} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// SERVICES
import {
  ToastService,
  AuthService,
  HttpService,
  HelperService,
  EmitterService,
} from '@services/index';

// COMPONENTS
import { LoginSuccessModalComponent } from '@public-components/login-success-modal/login-success-modal.component';

// PRIME
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

// FIREBASE
import { AngularFireAuth } from '@angular/fire/auth';

// CONSTANT
import { defConstant, errConstant } from '@data/constant-data';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [DialogService],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  ref: DynamicDialogRef;
  showCancel: boolean = false;
  isRegister: boolean = false;
  @Input() opLogin: any;
  @Input() isCoachReg: boolean = false;
  @Output() coachLogged = new EventEmitter();
  @Output() isSignup = new EventEmitter();
  @Output() loginSuccess = new EventEmitter<void>();

  isLoginClick: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastService,
    private authService: AuthService,
    private httpService: HttpService,
    private dialogService: DialogService,
    private helperService: HelperService,
    private emitterService: EmitterService,
    private angularFireAuth: AngularFireAuth,
    public loginRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router,
    private publicTournamentService: PublicTournamentsService
  ) {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(this.helperService.emailPattern()),
        ],
      ],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.showCancel = this.config?.data?.showCancel;
    this.isRegister = this.publicTournamentService.isRegister;
  }

  ngOnDestroy() {
    this.publicTournamentService.setRegisterToFalse();
  }

  showResetPassword(): void {
    this.authService.isShowReset$.next(true);
  }

  login() {
    this.toastrService.infoMsg({
      title: 'Login',
      message: defConstant.pleaseWait,
    });
    this.isLoginClick = true;

    const { email, password } = this.loginForm.value;

    if (!this.loginForm.valid) {
      this.isLoginClick = false;
      this.toastrService.errorMsg({ message: errConstant.anErrOccurred });
      return;
    }

    this.angularFireAuth
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        this.httpService.me().subscribe(
          (res) => {
            console.log('login: ', res);

            // Set to log in
            this.authService.setLoggedIn(true);

            // Set UID
            sessionStorage.setItem('uid', res.uid);

            // Set role
            sessionStorage.setItem('role', res.userType);
            console.log('role', res.userType);

            // Emit data
            this.emitterService.meEmit(res);

            // Show login message
            this.toastrService.successMsg({
              message: defConstant.welcomeSuccess,
            });

            this.isLoginClick = false;
            this.loginSuccess.emit(); console.log('Event emitted')
            

            if(!this.isCoachReg) {

            // Hide login component
            this.opLogin.hide();

            // Show Go to Dashboard Dialog
            this.showGotoDashboardDialog();
           

            } else {
              console.log('nag emit?')
              this.coachLogged.emit('coach')
            }
          },
          (err) => {
            // Logout firebase
            this.angularFireAuth.signOut();
            this.toastrService.errorMsg({
              message: errConstant.noRecord,
            });

            this.isLoginClick = false;
          }
        );
        this.loginRef.close();
      })
      .catch((err) => {
        this.isLoginClick = false;
          let msg;
        if(err.code == "auth/internal-error"){
          msg = "Login Failed Email or Password is incorrect"
        }else{

          msg = "Login Failed! Please try again"
        }
        this.toastrService.errorMsg({ message: msg });
      });
  }

  showGotoDashboardDialog(): void {
    if (this.isRegister) {
      this.goToRegistration();
    } else {
      this.ref = this.dialogService.open(LoginSuccessModalComponent, {
        width: '750px',
        header: `Let's Get Started`,
        closable: false,
      });

      this.ref.onClose.subscribe((res) => {
        this.publicTournamentService.checkIfCoach();
      });
    }
  }

  close() {
    this.loginRef.close();
  }

  goToRegistration() {
    this.router.navigate([
      `/public-registration/${this.publicTournamentService.tournamentId}/registration`,
    ]);
  }

  signup(): void {
    this.ref = this.dialogService.open(SignupComponent, {
      width: defConstant.modalWidth,
      closable: false,
      contentStyle: { 'overflow-y': 'scroll' }
    });

    this.ref.onClose.subscribe((res) => {

      if(res == 'login') {      
          this.isSignup.emit(true);
       
      }
    })

  }
}
