<div class="container-info main">
  <!-- <div
      class="section-header"
      [ngStyle]="{ 'background-color': secondaryColor, color: fontColor }"
    > -->
  <div
    class="section-header"
    [ngStyle]="{ 'background-color': 'black', color: 'white' }"
  >
    DETAILS
  </div>
  <p-card styleClass="card">
    <div class="body">
      <div class="meta-details">
        <div class="detail">
          <label class="TheBoldFont-Bold fs-1">SPORT</label>
          <div
            class="custom-chip"
            [ngClass]="[isBlack ? 'black-font' : 'white-font']"
            [ngStyle]="{
              'background-color': primaryColor,
              color: fontColorOpposite,
              'border-radius': '1.625rem'
            }"
          >
            BASKETBALL
          </div>
        </div>
        <div class="detail">
          <label class="TheBoldFont-Bold fs-1">GENDER</label>
          <div class="chip-container">
            <div
              *ngIf="
                tournament?.gender == 'MALE' ||
                tournament?.gender == 'MALE_FEMALE'
              "
              class="custom-chip"
              [ngClass]="[isBlack ? 'black-font' : 'white-font']"
              [ngStyle]="{
                'background-color': primaryColor,
                color: fontColorOpposite,
                'border-radius': '1.625rem'
              }"
            >
              Boys
            </div>
            <div
              *ngIf="
                tournament?.gender == 'FEMALE' ||
                tournament?.gender == 'MALE_FEMALE'
              "
              class="custom-chip"
              [ngClass]="[isBlack ? 'black-font' : 'white-font']"
              [ngStyle]="{
                'background-color': primaryColor,
                color: fontColorOpposite,
                'border-radius': '1.625rem'
              }"
            >
              Girls
            </div>
          </div>
        </div>

        <div class="detail">
          <label class="TheBoldFont-Bold fs-1">PRICE</label>
          <div
            class="custom-chip"
            [ngClass]="[isBlack ? 'black-font' : 'white-font']"
            [ngStyle]="{
              'background-color': primaryColor,
              color: fontColorOpposite,
              'border-radius': '1.625rem'
            }"
          >
            ${{ tournament?.entryFee }} per team
          </div>
        </div>

        <div class="detail">
          <label class="TheBoldFont-Bold fs-1">GAMES</label>
          <div
            class="custom-chip"
            [ngClass]="[isBlack ? 'black-font' : 'white-font']"
            [ngStyle]="{
              'background-color': primaryColor,
              color: fontColorOpposite,
              'border-radius': '1.625rem'
            }"
          >
            {{ tournament?.games }} guaranteed
          </div>
        </div>
      </div>

      <div class="info" [class.no-description]="tournament?.description == ''">
        <div class="description" *ngIf="tournament?.description != ''">
          <div class="body-content text-center">
            <label class="fs-3"> {{ tournament?.description }} </label>
          </div>
        </div>
        <div
          class="contact"
          [style.width]="tournament?.description != '' ? '50%' : '100%'"
        >
          <div class="title">
            <label class="TheBoldFont-Bold fs-1">CONTACT</label>
          </div>
          <div class="contact-info">
            <div class="mb-1">
              <label class="fs-1">
                {{ contactName }}
              </label>
            </div>
            <div class="mb-1">
              <label class="fs-1">
                {{ tournament?.organization.phoneNumber }}
              </label>
            </div>
            <div>
              <label class="fs-1 email">
                {{ tournament?.organization.email }}
              </label>
            </div>
            <div class="p-grid icons-container mt-1">
              <div *ngIf="tournament?.organization.twitterUrl" class="p-col-3">
                <div
                  class="icon-button"
                  (click)="openTwitter(tournament?.organization.twitterUrl)"
                >
                  <img src="../../../../../assets/img/x-icon.svg" alt="" />
                </div>
              </div>

              <div *ngIf="tournament?.organization.facebookUrl" class="p-col-3">
                <div
                  class="icon-button"
                  style="background-color: #14a2f9"
                  (click)="openFacebook(tournament?.organization.facebookUrl)"
                >
                  <fa-icon [icon]="faFacebook" style="color: white"></fa-icon>
                </div>
              </div>

              <div
                *ngIf="tournament?.organization.instagramUrl"
                class="p-col-3"
              >
                <div
                  class="icon-button"
                  style="background-color: #e61a27"
                  (click)="openInstagram(tournament?.organization.instagramUrl)"
                >
                  <fa-icon [icon]="faInstagram" style="color: white"></fa-icon>
                </div>
              </div>

              <div *ngIf="tournament?.organization.tiktokUrl" class="p-col-3">
                <div
                  class="icon-button"
                  style="background-color: white"
                  (click)="openTiktok(tournament?.organization.tiktokUrl)"
                >
                  <fa-icon [icon]="faTiktok" style="color: black"></fa-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</div>
