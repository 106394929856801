<div class="container-bracketsets">
    <p-selectButton  class="p-grid " [options]="bracketTypes" [(ngModel)]="selectedBracketType">
      <div class="flex gap-4 justify-content-center align-items-center flex-column mt-4" *ngFor="let item of bracketTypes">
        <div style="display: block">
          <div *ngIf="item.value == 'MIDDLE'" class="middle-top-bracket-edit"></div>
          <div [ngClass]="{'right-facing-edit': item.value == 'RIGHT_FACING',
            'left-facing-edit': item.value == 'LEFT_FACING',
            'middle-bracket-edit': item.value == 'MIDDLE',
            'bracket-winner-edit': item.value == 'BRACKET_WINNER',
            'box-bracket-edit': item.value == 'BOX'}">
          </div>
          <div *ngIf="item.value == 'MIDDLE'" class="middle-bottom-bracket-edit"></div>
        </div>
        
        <!-- <div> -->
          <h5>{{item.name}}</h5>
        <!-- </div> -->
      </div>

    </p-selectButton>
    <div class="flex container-btn">
      <div class="p-col-12 flex flex-center">
        <button
          pButton
          pRipple
          type="button"
          label="No"
          class="
            p-button-raised p-button-rounded p-button-text p-button-plain
            mx-2
            px-5
            py-3
          "
          (click)="cancel()"
        ></button>
        <button
          pButton
          iconPos="right"
          type="button"
          label="Yes"
          class="
            p-button-raised p-button-rounded p-button-danger p-button-plain
            mx-2
            px-5
            py-3
          "
          (click)="onSubmitEditBracket()"
        ></button>
      </div>
    </div>