<div class="body">
    <div class="p-col-12 text-center my-2">
      <span class="fs-2 text-black5 text-bold1">
        Are you sure you want to delete all the games?

      </span>
      <div>

      </div>
    </div>
    <div class="p-col-12 flex flex-center">
      <button
        pButton
        pRipple
        type="button"
        label="No"
        class="
          p-button-raised p-button-rounded p-button-text p-button-plain
          mx-2
          px-5
          py-3
        "
        (click)="close()"
      ></button>
      <button
        pButton
        iconPos="right"
        type="button"
        label="Yes"
        class="
          p-button-raised p-button-rounded p-button-danger p-button-plain
          mx-2
          px-5
          py-3
        "
        (click)="deleteAll()"
      ></button>
    </div>
  </div>
  