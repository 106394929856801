<div class="my-5 container-table">
  <table class="full-width">
    <thead>
      <tr>
        <ng-container *ngFor="let header of thead; let i = index">
          <th
            *ngIf="!(header.id === 'status' && isCheckInUrl)"
            [style]="{
              'text-align':
                i == 0 || i == 1
                  ? setAlignHeader(header.id)
                  : setTextAlign(header.id)
            }"
          >
            {{ header.value }}
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody *ngIf="!isFetching">
      <tr *ngFor="let item of data">
        <ng-container *ngFor="let header of thead; let i = index">
          <td
            [ngClass]="i == 0 || i == 1 ? 'p-0' : 'p-2'"
            [style]="{
              'text-align':
                i == 0 || i == 1
                  ? setAlignHeader(header.id)
                  : setTextAlign(header.id)
            }"
            *ngIf="!(header.id === 'status' && isCheckInUrl)"
          >
            <span *ngIf="header.id == 'status'; else othersBlock">
              <span *ngFor="let status of item[header.id]; index as i">
                <p-chip
                  *ngIf="status"
                  styleClass="chip"
                  label="{{ getTournamentStatus(status, i) }}"
                ></p-chip>
              </span>
            </span>
            <ng-template #othersBlock>
              {{ item[header.id] }}
            </ng-template>
          </td>
        </ng-container>
        <td width="150" *ngIf="config?.actionButtons">
          <section *ngFor="let btn of config.actionButtons; let n = index">
            <div
              *ngIf="btn === 'startCheckIn'; else default"
              (click)="onClick(item[config.actionReference], btn, item)"
              class="btn-rounded-red"
              [pTooltip]="setToolTip(btn)"
              tooltipPosition="top"
            >
              Start Check-In
            </div>
            <ng-template #default>
              <div
                (click)="onClick(item[config.actionReference], btn, item)"
                class="pointer btn-table-actions float-right"
              >
                <i
                  class="pi"
                  [pTooltip]="setToolTip(btn)"
                  tooltipPosition="top"
                  [ngClass]="btn ? setBtnIcon(btn) : ''"
                >
                </i>
              </div>
            </ng-template>
          </section>
        </td>
        <td width="150" *ngIf="button != ''">
          <button (click)="clickSingleButton(item)">{{ button }}</button>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="isFetching; else isFetched" class="disp-block text-center py-4">
    <p-progressSpinner
      [style]="{ width: '40px', height: '40px' }"
    ></p-progressSpinner>
  </div>
  <ng-template #isFetched>
    <div *ngIf="data.length == 0">
      <span class="fs-4 disp-block text-center text-bold1 pt-5">{{
        noResult
      }}</span>
    </div>
  </ng-template>
</div>

<p-dialog [(visible)]="isDeleteDialog" [modal]="false" [dismissableMask]="true">
  <div class="container-content">
    <h1>{{ message }}</h1>
  </div>

  <div class="flex flex-center m-4 my-4">
    <button class="btn-rounded-white mx-1" (click)="hide()">Cancel</button>
    <button class="btn-rounded-red mx-1" (click)="deleteNow()">Confirm</button>
  </div>
</p-dialog>
