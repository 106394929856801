// const server = 'http://ec2-3-15-145-96.us-east-2.compute.amazonaws.com:8080';
// const server = 'http://viaduct.proxy.rlwy.net:27079';
// const server = 'http://roundhouse.proxy.rlwy.net:56700';
// const server = 'http://localhost:8085';
const server = 'https://d37v1cycw3jblm.cloudfront.net';

// const server = 'https://tourneykey.com';
const prefix = '/api/tourneykey';
const version = '/v1';

const apiBaseUrl = `${server}${prefix}${version}`;

const api = {
  baseUrl: apiBaseUrl,
  // me: `${server}/me`, // LOCAL
  me: `${apiBaseUrl}/me`, // PROD
  login: `${apiBaseUrl}/login`,
  register: `${apiBaseUrl}/register`,
  registerCoach: `${apiBaseUrl}/coach/register`,
  registerSettings: `${apiBaseUrl}/tournaments/registration-settings`,
  org: `${apiBaseUrl}/organizations`,
  orgUsers: `${apiBaseUrl}/organizations/users`,
  orgLogo: `${apiBaseUrl}/organizations/logo`,
  orgIcon: `${apiBaseUrl}/organizations/icon`,
  orgLogoBase64: `${apiBaseUrl}/organizations/logo/base64`,
  orgIconBase64: `${apiBaseUrl}/organizations/icon/base64`,
  primaryOrgUser: `${apiBaseUrl}/organizations/users/primary`,
  globalPreferences: `${apiBaseUrl}/organizations/global-preferences`,
  paymentAccounts: `${apiBaseUrl}/payments/accounts`,
  paymentStripe: `${apiBaseUrl}/payments/stripe`,
  venuePhoto: `${apiBaseUrl}/venues/photo`,
  users: `${apiBaseUrl}/users`,
  userPhoto: `${apiBaseUrl}/photo`,
  tournaments: `${apiBaseUrl}/tournaments`,
  copyTournaments: `${apiBaseUrl}/tournaments/copy`,
  tournamentRegistration: `${apiBaseUrl}/tournaments/registration-settings`,
  pools: `${apiBaseUrl}/pools`,
  games: `${apiBaseUrl}/games`,
  gamesAll: `${apiBaseUrl}/games/all`,
  bracketGames: `${apiBaseUrl}/brackets/games`,
  unassignedGames: `${apiBaseUrl}/games/unassigned`,
  assignedGames: `${apiBaseUrl}/games/assigned`,
  featured: `${apiBaseUrl}/featured`,
  usersTournaments: `${apiBaseUrl}/users/tournaments`,
  featuredTournaments: `${apiBaseUrl}/users/tournaments/featured`,
  featuredTournamentsRevenue: `${apiBaseUrl}/users/tournaments/featured/revenue`,
  divisions: `${apiBaseUrl}/divisions`,
  divisionsBulk: `${apiBaseUrl}/divisions/multiple`,
  teams: `${apiBaseUrl}/teams`,
  teamCoach: `${apiBaseUrl}/team/assistant-coaches`,
  teamExists: `${apiBaseUrl}/exists/teams`,
  players: `${apiBaseUrl}/players`,
  playerPhoto: `${apiBaseUrl}/players/photo`,
  userTeams: `${apiBaseUrl}/users/teams`,
  teamPhoto: `${apiBaseUrl}/teams/logo`,
  tournamentSchedule: `${apiBaseUrl}/tournaments/schedule`,
  tournamentPools: `${apiBaseUrl}/tournaments/pools`,
  tournamentTeamDivisions: `${apiBaseUrl}/tournaments/teams/divisions`,
  divisionsTeams: `${apiBaseUrl}/divisions/teams`,
  divisionsPools: `${apiBaseUrl}/divisions/pools`,
  divisionsList: `${apiBaseUrl}/divisions/list`,
  teamDivisions: `${apiBaseUrl}/team-divisions`,
  teamsDivisionGroupings: `${apiBaseUrl}/teams/division-groupings`,
  venues: `${apiBaseUrl}/venues`,
  globalVenues: `${apiBaseUrl}/global-venues`,
  tournamentsVenues: `${apiBaseUrl}/tournament-venues`,
  courts: `${apiBaseUrl}/courts`,
  searchAddress: 'https://maps.googleapis.com/maps/api/geocode/json?address&',
  googlekey: 'AIzaSyDuBlLbg0JxFJUyr9z11dsDO4JJfjF8WiY',
  poolTeams: `${apiBaseUrl}/pools/teams`,
  publicFeaturedTournaments: `${apiBaseUrl}/users/tournaments/featured/all`,
  brackets: `${apiBaseUrl}/brackets`,
  bracketsAll: `${apiBaseUrl}/brackets/all`,
  bracketsTopTeam: `${apiBaseUrl}/brackets/advance/clear/top`,
  bracketsBottomTeam: `${apiBaseUrl}/brackets/advance/clear/bottom`,
  validateBracketSources: `${apiBaseUrl}/brackets/sources/validate`,
  divisionBrackets: `${apiBaseUrl}/divisions/brackets`,
  teamAvailability: `${apiBaseUrl}/team-availabilities`,
  courtAvailability: `${apiBaseUrl}/court-availabilities`,
  tournamentPhoto: `${apiBaseUrl}/tournaments/photo`,
  tournamentTeams: `${apiBaseUrl}/tournaments/teams`,
  tournamentStatus: `${apiBaseUrl}/tournaments/status`,
  teamRestrictions: `${apiBaseUrl}/team-restrictions`,
  standings: `${apiBaseUrl}/standings`,
  coachTeams: `${apiBaseUrl}/coaches/teams`,
  coachPlayers: `${apiBaseUrl}/coach/players`,
  coachAssistants: `${apiBaseUrl}/coach/assistant-coaches`,
  gamesPeriods: `${apiBaseUrl}/game-periods`,
  bracketSet: `${apiBaseUrl}/bracket-sets`,
  bracketSources: `${apiBaseUrl}/brackets/sources`,
  scheduleValidationSetting: `${apiBaseUrl}/schedule/settings`,
  teamPoolNames: `${apiBaseUrl}/team-pools/name`,
  moveTeamPools: `${apiBaseUrl}/team-pools/move`,
  saveAllTeamPools: `${apiBaseUrl}/team-pools/all`,
  removeTeamPools: `${apiBaseUrl}/team-pools/remove`,
  sendCoachesEmail: `${apiBaseUrl}/users/coaches/email`,
  acceptTeam: `${apiBaseUrl}/teams/claim-team`,
  tournamentCities: `${apiBaseUrl}/cities`,
  tournamentStates: `${apiBaseUrl}/states`,
  validateCard: `${apiBaseUrl}/payments/validate`,
  payments: `${apiBaseUrl}/payments`,
  publicTeamRegister: `${apiBaseUrl}/team-divisions/registration`,
  assistantCoach: `${apiBaseUrl}/assistant-coaches`,
  assistantTeamCoach: `${apiBaseUrl}/team/assistant-coaches`,
  checkInTeams: `${apiBaseUrl}/coach-checkin/teams`,
  checkIn: `${apiBaseUrl}/check-in`,
};

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBgWlJ1WdHGuZcri5hrh1bETbyL45NVbnA',
    authDomain: 'tourneykey-f2621.firebaseapp.com',
    projectId: 'tourneykey-f2621',
    storageBucket: 'tourneykey-f2621.appspot.com',
    messagingSenderId: '10224237483',
    appId: '1:10224237483:web:bd761acc1ab93319e3f0ee',
    measurementId: 'G-YDQEPM7ZPD',
  },
  api: api,
};
