import { Component, OnInit } from '@angular/core';
import { Venue } from '@core/models/venue';
import { VenueService } from '@core/services/venue.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-check-in-summary-modal',
  templateUrl: './check-in-summary-modal.component.html',
  styleUrls: ['./check-in-summary-modal.component.scss']
})
export class CheckInSummaryModalComponent implements OnInit {

  tournamentName: string;
  divisionName: string;
  teamName: string;
  coaches: any = [];

  venues: any = [];
  isLoading: boolean = true;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private venueService: VenueService,
  ) { }

  ngOnInit(): void {
    this.tournamentName = this.config.data.tournamentName;
    this.divisionName = this.config.data.divisionName;
    this.teamName = this.config.data.teamName;
    this.initCoaches(this.config.data.coaches);
  }

  async initCoaches(coaches: any[]) {
    let coachesList: any = [];
    for (const checkInCoach of coaches) {
      const venue = await this.getCoachCheckInVenue(checkInCoach.venueId ?? 0);
      const coachData = { ...checkInCoach, venue };
      coachesList.push(coachData);
    }
    this.coaches.push(...coachesList);
    this.isLoading = false;
  }

  async getCoachCheckInVenue(venueId: number): Promise<any> {
    return new Promise((resolve) => {
      let venue = this.venues.find((data: Venue) => {
        return data.id == venueId;
      });
      if (!venue) {
        venue = this.venueService.getVenue(venueId);
        this.venues.push(venue);
      }
      resolve(venue);
    });
  }

  onOkay() {
    this.ref.close();
  }

}
