<div class="py-2 ml-1 container-form p-grid">
    <div class="mb-2 p-col-12">
        <div class="text-left">
            <label class="text-bold1 p-0 mb-2">Paid Amount <span class="required-indicator">*</span></label>
        </div>
        <input [(ngModel)]="paidAmount" type="number" min="0" (keypress)="numericOnly($event)"
            class="tournament-input-text" />
    </div>

    <div class="mb-2 p-col-12">
        <div class="text-left">
            <label class="text-bold1 p-0 mb-2">Discount <span class="required-indicator">*</span></label>
        </div>
        <input [(ngModel)]="discount" type="number" min="0" (keypress)="numericOnly($event)"
            class="tournament-input-text" />
    </div>

    <div class="mb-2 p-col-12">
        <div class="text-left">
          <label class="text-bold1 p-0 mb-2">Note </label>
        </div>
        <textarea
          [(ngModel)]="note"
          class="tournament-input-textarea"
          rows="4"
        ></textarea>
      </div>

    <div class="flex flex-center my-4">
        <button type="button" (click)="cancel()" class="btn-rounded-white mx-2" [disabled]="isLoading">
            CANCEL
        </button>
        <button type="submit" (click)="submit()" class="btn-rounded-red mx-2" [disabled]="isLoading">
            SUBMIT
        </button>
    </div>
</div>