import { Injectable } from '@angular/core';
import { Teams } from '@core/models/teams';
import { environment } from '@env/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpService) { }

  getTournamentPlayers(tournamentId: number) {
    return this.http
    .get({
      url: `${environment.api.players}?tournamentId=${tournamentId}`,
    }).toPromise();
  }

  getPlayersByTeam(team: Teams) {
    return this.http
    .get({
      url: `${environment.api.players}?teamId=${team.id}`,
    });
  }

  savePlayers(players:any[]) {

    console.log('url', `${environment.api.players}/batch`);

    return this.http
    .post({
      url: `${environment.api.players}/batch`,
      payload: players,
    });
  }
  
  deletePlayer(playerId: number) {
  return this.http
    .delete({
      url: `${environment.api.players}/${playerId}`,
    });
  }

  getPlayersByDivision(divisionId: number | string) {
    return this.http
    .get({
      url: `${environment.api.players}?divisionId=${divisionId}`,
    });
  }

  // getPlayersByDivision(divisionId: number | string) {
  //   return this.http
  //   .get({
  //     url: `https://tourneykey.com/api/tourneykey/v1/players?divisionId=${divisionId}`,
  //   });
  // }
}
