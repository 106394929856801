import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-all-games',
  templateUrl: './delete-all-games.component.html',
  styleUrls: ['./delete-all-games.component.scss']
})
export class DeleteAllGamesComponent implements OnInit {

  constructor(public ref: DynamicDialogRef) { }

  ngOnInit(): void {
  }

  close(): void {
    this.ref.close()
  }

  deleteAll() {
    this.ref.close("deleteAll")
  }
}
