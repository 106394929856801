import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class TournamentParamGuard implements CanActivate {
 
    constructor(private _router:Router ) {
    }
 
    canActivate(
        route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
                    this._router.navigate(['dashboard/overview'])
        return false;
    }
 
}