import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env/environment';
import { Games } from '@core/models/games';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  constructor(private http: HttpService) {}

  getAssignedGames(tournamentId: number, scheduleDate: Date): Promise<Games[]> {
    const assignMoment = moment(scheduleDate, 'MMMM DD, YYYY');
    const schedDate = assignMoment.format('YYYYMMDDZ');

    return this.http
      .get({
        url: `${environment.api.assignedGames}?tournamentId=${tournamentId}&date=${schedDate}`,
      })
      .toPromise();
  }

  getBracketGames(divisionId: number | string): Promise<Games[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.bracketGames}?divisionId=${divisionId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getTeamGames(tournamentId: number, teamId: number): Promise<Games[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.assignedGames}?tournamentId=${tournamentId}&teamId=${teamId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getPlayerGames(tournamentId: number, playerId: number): Promise<Games[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.assignedGames}?tournamentId=${tournamentId}&playerId=${playerId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getDateGames(tournamentId: number, date: string): Promise<Games[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.assignedGames}?tournamentId=${tournamentId}&date=${date}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getVenueGames(tournamentId: number, venueId: number): Promise<Games[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.assignedGames}?tournamentId=${tournamentId}&venueId=${venueId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getDivisionGames(tournamentId: number, divisionId: number): Promise<Games[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.assignedGames}?tournamentId=${tournamentId}&divisionId=${divisionId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  saveGame(game: any) {
    return this.http.post({
      url: environment.api.games,
      payload: game,
    });
  }

  saveAllGames(games: any[]) {
    return this.http.post({
      url: environment.api.gamesAll,
      payload: games,
    });
  }
}
