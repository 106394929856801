<img src="./assets/img/red-logo.png" (click)="gotoHomepage()" class="a-logo" />
<ul>
  <li *ngFor="let item of items">
    <!-- <section class="px-2 mx-4 my-3">
      <span class="text-gray5 text-uppercase fs-small">{{ item.name }}</span>
    </section> -->
    <ul class="pl-1">
      <li *ngFor="let list of item.list">
        <a
          class="nav-links p-3 disp-block"
          [routerLink]="[list.path]"
          routerLinkActive="active"
          (click)="loadPage(list)"
        >
          <i class="fs-2 pi" [ngClass]="list.icon ? list.icon : ''"></i
          >&#160;&#160;{{ list.name }}
        </a>
      </li>
    </ul>
  </li>
</ul>
