<div class="py-2 ml-1 container-form">
  <div [formGroup]="playersForm">
    <ng-container formArrayName="players">
      <div class="player-row mb-3">
        <div class="ml-3">Number</div>
        <div class="ml-3">First Name</div>
        <div class="ml-3">Last Name</div>
        <div class="ml-3">Position</div>
      </div>
      <div *ngFor="let playerForm of bulkPlayers.controls; let i = index">
        <div [formGroupName]="i">
          <div class="player-row mb-2">
            <input
              type="text"
              pInputText
              formControlName="jerseyNumber1"
              class="tournament-input-text"
              maxlength="3"
            />
            <input
              type="text"
              pInputText
              formControlName="firstName"
              class="tournament-input-text"
              maxlength="60"
            />
            <input
              type="text"
              pInputText
              formControlName="lastName"
              class="tournament-input-text"
              maxlength="60"
            />
            <input
              type="text"
              pInputText
              formControlName="position"
              class="tournament-input-text"
              maxlength="20"
            />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="flex flex-center my-4">
    <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
      CANCEL
    </button>

    <button type="submit" (click)="submit()" class="btn-rounded-red mx-2">
      SAVE
    </button>
  </div>
</div>
