<div class="p-col-12 text-center">

  <div class="button-content">
    <button pButton label="Add Payment" class="p-button-raised p-button-danger mx-2 px-5 py-3"
      (click)="addPaymentAuth()"></button>
  </div>

  <div class="requirement mt-3">
    Tournament Entry Fee =
    <strong>${{ teamDivision.division.entryFee }}</strong>
  </div>
  <div class="transaction">
    <table style="width: 100%">
      <tr>
        <th style="width: 20%">TIMESTAMP</th>
        <th style="width: 15%">PAYMENT</th>
        <th style="width: 15%">DISCOUNT</th>
        <th style="width: 15%">TOTAL</th>
        <th style="width: 15%">OWED</th>
        <th style="width: 20%">NOTE</th>
      </tr>
      <tr *ngFor="let payment of payments; let i = index">
        <td *ngIf="payment.paymentDate; else notApplicable">
          {{ payment?.paymentDate | date : "medium" }}
        </td>
        <ng-template #notApplicable> <td>N/A</td></ng-template>
        <td>${{ payment?.paidAmount }}</td>
        <td>${{ payment?.discount ? payment?.discount : "0" }}</td>
        <td>${{ payment?.runningTotal }}</td>
        <td>
          <strong>${{ payment?.owed }}</strong>
        </td>
        <td>
          {{ payment.note ? payment.note : "N/A" }}
        </td>
      </tr>
    </table>
  </div>

  <!--<div class="checkin-payment-panel">
    <div class="inputs-container">
      <div class="inputs-container-left p-col-6">
        <div class="mb-3">
          <div class="text-left">
            <label class="text-bold1 p-0 mb-2"
              >Paid Amount <span class="required-indicator">*</span></label
            >
          </div>
          <input
            [(ngModel)]="paidAmount"
            type="number"
            min="0"
            (keypress)="numericOnly($event)"
            class="tournament-input-text"
          />
        </div>

        <div class="mb-2">
          <div class="text-left">
            <label class="text-bold1 p-0 mb-2"
              >Discount <span class="required-indicator">*</span></label
            >
          </div>
          <input
            [(ngModel)]="discount"
            type="number"
            min="0"
            (keypress)="numericOnly($event)"
            class="tournament-input-text"
          />
        </div>
      </div>

      <div class="p-col-6">
        <div class="text-left">
          <label class="text-bold1 p-0 mb-2">Note </label>
        </div>
        <textarea
          [(ngModel)]="note"
          class="tournament-input-textarea"
          rows="4"
        ></textarea>
      </div>
    </div>
    <br />
  </div>-->

  <!--<div class="mt-3 text-center">
    <button
      pButton
      label="Save Payment"
      class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
      (click)="savePayment()"
    ></button>
  </div>-->
</div>
