import { Component, HostListener, OnInit } from '@angular/core';
import {
  faRobot,
  faAddressCard,
  faPen,
  faChartLine,
  faHammer,
} from '@fortawesome/free-solid-svg-icons';
import { faRegistered } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-public-features',
  templateUrl: './public-features.component.html',
  styleUrls: ['./public-features.component.scss'],
})
export class PublicFeaturesComponent implements OnInit {
  faRobot = faRobot;
  faAddressCard = faAddressCard;
  faPen = faPen;
  faRegistered = faRegistered;
  faChartLine = faChartLine;
  faHammer = faHammer;

  show = false;
  apiLoaded = false;
  videoId: string | null = 'rrzbN1eMnjQ';
  description: string | null = null;
  innerWidth: number;
  playerWidth: number;
  playerHeight: number;
  imageSources: string[] = [];
  translateX = 0;
  currentIndex = 0;

  constructor() {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.calculatePlayerSize();

    if (!this.apiLoaded) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.calculatePlayerSize();
  }

  calculatePlayerSize() {
    if (this.innerWidth >= 768) {
      this.playerHeight = 350;
      this.playerWidth = this.innerWidth / 2 - this.innerWidth * 0.01;
    } else {
      this.playerHeight = 300;
      this.playerWidth = this.innerWidth - this.innerWidth * 0.01;
    }
  }

  buttonClicked(description: string, images: string[]): void {
    this.description = description;
    this.imageSources = images;
    this.videoId = images.length === 0 ? 'rrzbN1eMnjQ' : null;
    this.show = images.length > 0;
    this.translateX = 0;
    this.currentIndex = 0;
  }

  prev(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.translateX += this.playerWidth;
    }
  }

  next(): void {
    if (this.currentIndex < this.imageSources.length - 1) {
      this.currentIndex++;
      this.translateX -= this.playerWidth;
    }
  }
}
