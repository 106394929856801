import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, HelperService } from '@core/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  resetForm: FormGroup;

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private angularFireAuth: AngularFireAuth,

  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      email: ['',        [
        Validators.required,
        Validators.pattern(this.helperService.emailPattern()),
      ]]
    })
  }

  changePassword(): void{
    const {email} = this.resetForm.value;
    this.auth.resetPassword(email);
  }

}
