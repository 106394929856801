import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// FIREBASE
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

// SERVICES
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpService } from './http.service';
import { SessionStorageService } from '@services/session-storage.service';
import { ToastService } from '@services/toast.service';
import { EmitterService } from '@services/emitter.service';

// CONSTANT
import { errConstant } from '@data/constant-data';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedInEmitter = new BehaviorSubject<boolean>(false);
  hours = 1; // 1 = 1hr
  isShowReset$ = new BehaviorSubject<boolean>(false);
  isShowChangePass$ = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private toastService: ToastService,
    private emitterService: EmitterService,
    private httpService: HttpService,
    private sessionStorageService: SessionStorageService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.ngxLoader.start();
    const expTime = this.sessionStorageService.getExpTime();
    if (
      expTime &&
      new Date().getTime() - parseInt(expTime) > this.hours * 60 * 60 * 1000
    ) {
      this.toastService.errorMsg({ message: errConstant.sessionExpired });
      this.logout();
      return;
    }

    this.angularFireAuth.authState.subscribe(
      (me) => {
        if (me) {
          console.log('INn', me);
          this.setLoggedIn(true);
          sessionStorage.setItem('uid', me.uid);

          this.httpService.me().subscribe((res) => {
            sessionStorage.setItem('role', res.userType);
          });

          this.ngxLoader.stop();
        } else {
          this.setLoggedIn(false);
          // this.logout();
          this.ngxLoader.stop();
        }
      },
      (error) => {}
    );
  }

  setLoggedIn(isLogged: boolean): void {
    this.isLoggedInEmitter.next(isLogged);
  }

  resetPassword(email: string): void {
    this.angularFireAuth
      .sendPasswordResetEmail(email)
      .then((res) => {
        this.toastService.toast(
          'Success!',
          'A password reset link has been sent to your email address',
          'success'
        );
        this.isShowReset$.next(false);
      })
      .catch((e) => {
        this.toastService.toast('Failed', e.message, 'error');
      });
  }

  changePassword(oldPassword: string, newPassword: string): void {
    const fb = firebase.default;
    this.angularFireAuth.onAuthStateChanged((res) => {
      const credentials = fb.auth.EmailAuthProvider.credential(
        res.email,
        oldPassword
      );
      res
        .reauthenticateWithCredential(credentials)
        .then((res) => {
          console.log('auth pw', res);

          res.user
            .updatePassword(newPassword)
            .then((res) => {
              this.toastService.toast(
                'Success!',
                'Password Changed!',
                'success'
              );
              this.isShowChangePass$.next(false);
            })
            .catch((e) => {
              this.toastService.toast('Failed', e.message, 'error');
            });
        })
        .catch((e) => {
          this.toastService.toast('Failed', e.message, 'error');
        });
    });
  }

  reAuthenticateWithPassword(password: string) : Promise<boolean> {
    const fb = firebase.default;
    return new Promise((resolve, reject) => {
      try {
        const user = this.angularFireAuth.currentUser.then( user => {
          const credential = fb.auth.EmailAuthProvider.credential(user.email, password);
          user.reauthenticateWithCredential(credential).then(res => {
            resolve(true);
          }).catch(e => {
            resolve(false);
          });
        }).catch(e => {
          resolve(false);
        });
      }
      catch(error) {
        resolve(false);
      }
    });
  }

  logout(): void {
    console.log('***********************************');
    console.log('PASSED LOGOUT');
    console.log('***********************************');

    this.router.navigate(['']);
    this.setLoggedIn(false);
    this.angularFireAuth.signOut();
    this.emitterService.clearData();
    sessionStorage.clear();
    localStorage.clear();
    this.ngxLoader.stop();
  }
}
