<form [formGroup]="teamsForm" (ngSubmit)="onSubmit()" class="teams">
  <div class="p-grid p-3">
    <div *ngIf="registration?.teamPhoto == 'NOT_REQUIRED' ? false : true" class="p-col-12">
      <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2">Team Photo
        <span *ngIf="registration?.teamPhoto == 'REQUIRED'" class="required-indicator">*</span></label>
      <p-fileUpload name="fileLogo[]" accept="image/*" [showUploadButton]="false" (onClear)="clearLogo()"
        (onSelect)="onSelectLogo($event)" [showCancelButton]="false">
        <ng-template let-file pTemplate="file">
          <div *ngIf="isLogoFetching; else isFetchedLogo" class="text-center upload-content flex flex-center">
            <p-progressSpinner [style]="{ width: '40px', height: '40px' }"></p-progressSpinner>
          </div>
          <ng-template #isFetchedLogo>
            <div class="disp-block text-center p-0" *ngIf="isLogoUploaded">
              <img [src]="logoUrl" alt="Logo" width="150px" height="150px" />
            </div>
          </ng-template>
        </ng-template>

        <ng-template pTemplate="content" let-files>
          <div *ngIf="!isLogoUploaded" class="upload-content flex flex-center">
            <div *ngIf="isLogoFetching; else isFetchedLogo" class="text-center flex flex-center">
              <p-progressSpinner [style]="{ width: '40px', height: '40px' }"></p-progressSpinner>
            </div>

            <ng-template #isFetchedLogo>
              <div *ngIf="!hasLogo; else hasLogoTmp">
                <div class="text-center text-gray3">
                  Upload&#160;<span class="text-bold2 text-primary1">Photo</span>&#160;or Just Drag and Drop
                </div>
                <span class="text-gray3 disp-block text-center">150px X 150px</span>
              </div>
              <ng-template #hasLogoTmp>
                <div *ngIf="logoUrl" class="text-center">
                  <img [src]="logoUrl" alt="Logo" width="150px" height="150px" />
                </div>
              </ng-template>
            </ng-template>
          </div>
        </ng-template>
      </p-fileUpload>
    </div>

    <div class="p-col-12">
      <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2">Name <span
          class="required-indicator">*</span></label>
      <input id="name" formControlName="name" type="text" maxlength="50" minlength="2" class="p-col-12 input-text"
        pInputText maxlength="40" />
      <div *ngIf="!isValidated && teamsForm.controls['name'].invalid" class="alert">
        <label class="red-label">Team Name is required.</label>
      </div>
    </div>

    <div class="p-col-12">
      <label for="abbr" class="text-bold1 disp-block p-col-12 p-0 mb-2">Abbreviation <span
          class="required-indicator"></span></label>
      <input id="abbr" type="text" minlength="1" class="p-col-12 input-text" pInputText formControlName="abbreviation"
        maxlength="7" />
    </div>

    <!-- <div class="city-state-row">
      <div class="half-container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3">
          City <span class="required-indicator">*</span></label
        >
        <p-autoComplete
          styleClass="w-100"
          appendTo="body"
          inputStyleClass="input-text disp-block autocomplete-100"
          autocomplete="noauto"
          emptyMessage="No results found"
          formControlName="city"
          [autoHighlight]="true"
          [forceSelection]="false"
          [suggestions]="cities"
          (completeMethod)="searchCity($event)"
          dropdownIcon="pi pi-angle-down"
        >
        </p-autoComplete>
      </div>
      <div class="half-container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3">
          State <span class="required-indicator">*</span></label
        >
        <p-autoComplete
          styleClass="w-100"
          appendTo="body"
          inputStyleClass="input-text disp-block autocomplete-100"
          autocomplete="noauto"
          emptyMessage="No results found"
          formControlName="state"
          [autoHighlight]="true"
          [forceSelection]="false"
          [suggestions]="states"
          (completeMethod)="searchState($event)"
          dropdownIcon="pi pi-angle-down"
        >
        </p-autoComplete>
      </div>
    </div> -->

    <div class="p-col-12">
      <label for="city" class="text-bold1 disp-block p-col-12 p-0 mb-2">City <span
          class="required-indicator"></span></label>
      <!-- <input
        id="city"
        type="text"
        class="p-col-12 input-text"
        pInputText
        formControlName="city"
        maxlength="100"
      /> -->

      <p-autoComplete styleClass="w-100" appendTo="body" inputStyleClass="input-text w-100 disp-block autocomplete-100"
        autocomplete="noauto" emptyMessage="No results found" formControlName="city" [autoHighlight]="true"
        [forceSelection]="false" [suggestions]="cities" (completeMethod)="searchCity($event)"
        dropdownIcon="pi pi-angle-down">
      </p-autoComplete>
    </div>

    <div class="p-col-12">
      <label for="state" class="text-bold1 disp-block p-col-12 p-0 mb-2">State <span
          class="required-indicator"></span></label>

      <div class="default-dropdown">
        <p-dropdown [options]="states" placeholder="Select State" optionLabel="name" optionValue="value"
          formControlName="state" autoWidth="false" styleClass="p-col-12 mx-0"></p-dropdown>
      </div>
    </div>

    <div class="p-col-12 ml-2">
      <div class="container-fields">
        <div class="tk-radiobutton-group">
          <div class="tk-radiobutton-items mb-2">
            <p-radioButton class="tk-radioButton" name="group" value="GRADE" label="Grade" formControlName="group"
              inputId="grade"></p-radioButton>
          </div>

          <div class="tk-radiobutton-items">
            <p-radioButton class="tk-radioButton" name="group" value="AGE" label="Age" formControlName="group"
              inputId="age"></p-radioButton>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12" *ngIf="isAgeGroup">
      <label for="gender" class="text-bold1 disp-block mb-2">Select an Age Group <span
          class="required-indicator"></span></label>
      <div class="default-dropdown">
        <p-dropdown [options]="ageGroup" placeholder="Select an Age Group" optionLabel="label" optionValue="ageGroup"
          styleClass="p-col-12 mx-0" formControlName="ageGroup"></p-dropdown>
      </div>
    </div>
    <div class="p-col-12" *ngIf="isGradeGroup">
      <label for="gender" class="text-bold1 disp-block mb-2">Select a Grade Group <span
          class="required-indicator"></span></label>
      <div class="default-dropdown">
        <p-dropdown [options]="gradeGroup" placeholder="Select a Grade Group" optionLabel="label"
          optionValue="gradeGroup" styleClass="p-col-12 mx-0" formControlName="gradeGroup"></p-dropdown>
      </div>
    </div>

    <!-- <div class="p-col-12">
        <label for="gradeGroup" class="text-bold1 p-col-12 p-0 mb-2"
          >Grade Group*</label
        >
        <div class="default-dropdown p-0">
          <p-dropdown
            id="gradeGroup"
            [options]="gradeGroup"
            placeholder=""
            optionLabel="label"
            optionValue="gradeGroup"
            styleClass="p-col-12 mx-0"
            formControlName="gradeGroup"
          ></p-dropdown>
        </div>
      </div> -->

    <!-- <div class="p-col-12">
        <label for="ageGroup" class="text-bold1 p-col-12 p-0 mb-2"
          >Age Group*</label
        >
        <div class="default-dropdown p-0">
          <p-dropdown
            id="ageGroup"
            [options]="ageGroup"
            placeholder=""
            optionLabel="label"
            optionValue="ageGroup"
            styleClass="p-col-12 mx-0"
            formControlName="ageGroup"
          ></p-dropdown>
        </div>
      </div> -->

    <div class="p-col-12">
      <label for="gender" class="text-bold1 disp-block p-col-12 p-0 mb-2">Gender <span
          class="required-indicator"></span></label>
      <div class="default-dropdown p-0">
        <p-dropdown id="gender" [options]="gender" placeholder="" optionLabel="label" optionValue="value"
          styleClass="p-col-12 mx-0" formControlName="gender"></p-dropdown>
      </div>
    </div>

    <!--<div class="p-col-12">
      <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
        >Team Privacy <span class="required-indicator"></span
      ></label>
      <div class="tk-radiobutton-group">
        <div class="p-col-6">
          <p-radioButton
            class="tk-radioButton"
            name="teamPrivate"
            label="Public"
            name="teamPrivate"
            [value]="false"
            formControlName="teamPrivate"
            inputId="teamPrivate"
          ></p-radioButton>
        </div>
        <div class="p-col-6">
          <p-radioButton
            class="tk-radioButton"
            name="teamPrivate"
            label="Private"
            name="teamPrivate"
            [value]="true"
            formControlName="teamPrivate"
            inputId="teamPrivate"
          ></p-radioButton>
        </div>
      </div>
    </div>-->

    <div class="p-col-12" *ngIf="registration?.teamTwitter == 'NOT_REQUIRED' ? false : true">
      <label class="text-bold1 disp-block p-col-12 p-0 mb-2">Twitter Handle
        <span *ngIf="registration?.teamTwitter == 'REQUIRED'" class="required-indicator">*</span>
      </label>
      <input formControlName="teamTwitter" type="text" class="tournament-input-text" />
      <div *ngIf="!isValidated && teamsForm.controls['teamTwitter'].invalid" class="alert">
        <label class="red-label">Twitter Handle is required.</label>
      </div>
    </div>
    <div class="p-col-12" *ngIf="registration?.teamInstagram == 'NOT_REQUIRED' ? false : true">
      <label class="text-bold1 disp-block p-col-12 p-0 mb-2">Instagram Handle
        <span *ngIf="registration?.teamInstagram == 'REQUIRED'" class="required-indicator">*</span>
      </label>
      <input formControlName="teamInstagram" type="text" class="tournament-input-text" />
      <div *ngIf="!isValidated && teamsForm.controls['teamInstagram'].invalid" class="alert">
        <label class="red-label">Instagram Handle is required.</label>
      </div>
    </div>
    <div class="p-col-12" *ngIf="registration?.teamFacebook == 'NOT_REQUIRED' ? false : true">
      <label class="text-bold1 disp-block p-col-12 p-0 mb-2">Facebook Page
        <span *ngIf="registration?.teamFacebook == 'REQUIRED'" class="required-indicator">*</span>
      </label>
      <input formControlName="teamFacebook" type="text" class="tournament-input-text" />
      <div *ngIf="!isValidated && teamsForm.controls['teamFacebook'].invalid" class="alert">
        <label class="red-label">Facebook Page is required.</label>
      </div>
    </div>
    <div class="p-col-12" *ngIf="registration?.teamWebsite == 'NOT_REQUIRED' ? false : true">
      <label class="text-bold1 disp-block p-col-12 p-0 mb-2">Website
        <span *ngIf="registration?.teamWebsite == 'REQUIRED'" class="required-indicator">*</span>
      </label>
      <input formControlName="teamWebsite" type="text" class="tournament-input-text" />
      <div *ngIf="!isValidated && teamsForm.controls['teamWebsite'].invalid" class="alert">
        <label class="red-label">Team Website is required.</label>
      </div>
    </div>

    <div *ngIf="registration?.additionalTeamInfo != []" class="p-col-12">
      <div *ngFor="let teamInfo of registration?.additionalTeamInfo" class="p-col-12 addtl-field">
        <div class="p-col-12 addtl-field" *ngIf="teamInfo?.status == 'NOT_REQUIRED' ? false : true">
          <label class="text-bold1 disp-block p-col-12 p-0 mb-2">{{ teamInfo?.name }}
            <span *ngIf="teamInfo?.status == 'REQUIRED'" class="required-indicator">*</span>
          </label>
          <input *ngIf="teamInfo?.type === 'TEXTBOX'" [formControlName]="generateFormGrp(teamInfo?.id)" type="text"
            class="tournament-input-text" [ngClass]="isValidated ? null : 'validated'" />
          <textarea *ngIf="teamInfo?.type === 'TEXTAREA'" [formControlName]="generateFormGrp(teamInfo?.id)" type="text"
            rows="4" class="tournament-inputarea-text mt-1" [ngClass]="isValidated ? null : 'validated'"></textarea>
          <!-- <div *ngIf="!isValidated && form.controls['coachInstagram'].invalid" class="alert">
            <label class="red-label">Instagram Handle is required.</label>
          </div> -->
        </div>
      </div>
    </div>

    <div class="p-col-12" *ngIf="registration?.teamComment == 'NOT_REQUIRED' ? false : true">
      <label class="text-bold1 disp-block p-col-12 p-0 mb-2">Comment
        <span *ngIf="registration?.teamComment == 'REQUIRED'" class="required-indicator">*</span>
      </label>
      <textarea pInputTextarea formControlName="teamComment" class="tournament-inputarea-text"
        [placeholder]="registration?.teamCommentDescription" [rows]="5" [cols]="30"></textarea>
      <div *ngIf="!isValidated && teamsForm.controls['teamComment'].invalid" class="alert">
        <label class="red-label">Comment Description is required.</label>
      </div>
    </div>
    <div class="p-col-12 flex flex-center mt-4">
      <button pButton type="button" label="Cancel"
        class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3" (click)="close()"></button>
      <button pButton type="submit" class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3">
        <span *ngIf="!isLoading">Submit</span>
        <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" style="font-size:24px; color: white"></i></span>
      </button>
    </div>
  </div>
</form>

<p-dialog header="Validate Team" [closable]="false" [(visible)]="showConfirm">
  <div class="p-grid custom-modal">
    <div class="p-col-12 text-center my-2">
      <span class="fs-2 text-black5 text-bold1">
        {{ message }}
      </span>
    </div>
    <div class="p-col-12 flex flex-center">
      <span class="my-2 mx-3">
        <button pButton type="button" label="No"
          class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
          (click)="reject()"></button>
      </span>
      <span class="my-2 mx-3">
        <button pButton type="submit" (click)="confirm()" label="Yes"
          class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"></button>
      </span>
    </div>
  </div>
</p-dialog>