import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// SERVICES
import { HttpService } from '@services/index';
import { TeamsService } from '@core/services/teams.service';
import { GamesService } from '@core/services/games.service';
import { PlayerService } from '@core/services/player.service';
import { VenueService } from '@core/services/venue.service';

// MODELS
import { Division } from '@core/models/division';
import { Teams } from '@core/models/teams';
import { Tournament } from '@core/models/tournament';
import { Games } from '@core/models/games';

// ENV
import { environment } from '@env/environment';
import moment from 'moment';

import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { faBasketballBall } from '@fortawesome/free-solid-svg-icons';
import { DivisionService } from '@core/services/division.service';
import { TournamentsService } from '@core/services/tournaments.service';
import { DatePipe } from '@angular/common';
import { log } from 'console';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {
  faBasketballBall = faBasketballBall;
  // @Input() tournament: Tournament;

  @Input() organizationId: number;

  _tournament: Tournament;
  get tournament(): Tournament {
    return this._tournament;
  }
  @Input() set tournament(value: Tournament) {
    this._tournament = value;
    this.getInitSchedule();
  }

  // tournament: Tournament;
  data: any = [];
  isFetching: boolean;
  dateAvailable: Object[] = [];
  initLength: number;
  games: Games[];
  isLoading = true;
  week = 1;
  uniqueVenuesByDivision: { [divisionId: string]: string[] } = {};

  faFilter = faFilter;
  isFilter: boolean = false;

  schedules: any = [];
  schedulesDuplicate: any = [];
  isHovered = false;
  filterOption: any = 'division';
  filterOptions: any = [
    { label: 'All', value: 'all' },
    { label: 'By Division', value: 'division' },
    { label: 'By Team', value: 'team' },
    { label: 'By Player', value: 'player' },
    { label: 'By Venue', value: 'venue' },
    { label: 'By Date', value: 'date' },
  ];

  filterSubOption: any;
  filterSubOptions: any = [''];
  isFilterSubOptionDisabled: boolean = false;
  isFilterSubOptionsLoading = false;

  switch: string = 'division';

  divisionTournDates: any[] = [];

  teams: any[] = null;
  players: any[] = null;
  dates: any[] = null;
  venues: any[] = null;
  divisions: Division[] = null;
  divisionTeams: any[] = [];

  primaryColor: string;
  secondaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  innerWidth: any;
  isScreen850Below: any;
  isScreen450Below: any;
  isScreenSmall: boolean;

  constructor(
    private router: Router,
    private gamesService: GamesService,
    private httpService: HttpService,
    private playerService: PlayerService,
    private teamsService: TeamsService,
    private venueService: VenueService,
    private divisionService: DivisionService,
    private tournamentService: TournamentsService
  ) {}

  ngOnInit(): void {
    this.onResize('any');
    this.getInitSchedule();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 768) {
      this.isScreenSmall = true;
    } else {
      this.isScreenSmall = false;
    }
    if (this.innerWidth <= 850) {
      this.isScreen850Below = true;
    } else {
      this.isScreen850Below = false;
    }
    if (this.innerWidth <= 450) {
      this.isScreen450Below = true;
    } else {
      this.isScreen450Below = false;
    }
  }

  sortTimeslots(timeslots: any[]): any[] {
    return timeslots.sort((a, b) => {
      const timeA = new Date('1970/01/01 ' + a.time);
      const timeB = new Date('1970/01/01 ' + b.time);
      return timeA.getTime() - timeB.getTime();
    });
  }

  sortGames(games: any[]): any[] {
    return games.sort((a, b) => {
      const venueCompare = (a.court?.venueName || '').localeCompare(
        b.court?.venueName || ''
      );
      if (venueCompare !== 0) return venueCompare;

      const extractNumber = (str: string) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const courtA = extractNumber(a.court?.name || '');
      const courtB = extractNumber(b.court?.name || '');
      return courtA - courtB;
    });
  }

  getScheduleLabel() {
    switch (this.filterOption) {
      case 'all':
        return 'TOURNAMENT SCHEDULES';
      case 'team':
        return 'ALL SCHEDULE';
        break;
      case 'player':
        return 'ALL SCHEDULE';
        break;
      case 'date':
        return 'SCHEDULES FOR ' + this.getDateLabel();
        break;
      case 'venue':
        return 'ALL SCHEDULE';
      case 'division':
        return 'ALL SCHEDULE';
    }
  }

  getTeamLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.teams[ndx].name.toUpperCase();
  }

  getPlayerLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.players[ndx].fullName.toUpperCase();
  }

  getDateLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.dates[ndx].label.toUpperCase();
  }

  getVenueLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.venues[ndx].name.toUpperCase();
  }

  getDivisionLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.divisions[ndx].name.toUpperCase();
  }

  /**
   * Initializes the schedule with all available games.
   */
  getInitSchedule(): void {
    if (null == this._tournament.organization.secondaryColor) {
      this.secondaryColor = '#000000';
    } else {
      this.secondaryColor = this._tournament.organization.secondaryColor;
      this.getFontColor(this.secondaryColor);
    }

    console.log('TOURNNNN', this.tournament);

    if (
      this.tournament?.startDate != null &&
      this.tournament?.endDate != null
    ) {
      this.enumerateDays(this.tournament?.startDate, this.tournament?.endDate);
    }
  }

  getFontColor(secondary: string) {
    let sRed = secondary.substring(1, 3);
    let sGreen = secondary.substring(3, 5);
    let sBlue = secondary.substring(5, 7);

    let secondaryInt =
      parseInt(sRed, 16) * 0.299 +
      parseInt(sGreen, 16) * 0.587 +
      parseInt(sBlue, 16) * 0.114;

    if (secondaryInt > 150) {
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }
  }

  /**
   * Lists all the dates of the tournaments.
   *
   * @param fromDate
   * @param toDate
   */
  enumerateDays(fromDate, toDate): void {
    const day1 = moment(toDate);
    const day2 = moment(fromDate);
    const week = moment(
      day1.format('MM/DD/YYYY'),
      day2.format('MM/DD/YYYY')
    ).week();
    // console.log(week);
    const result = [moment({ ...day2 })];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment({ ...day2 }));
    }

    this.dates = [];
    result.map((x) => {
      const newDate = x.format('MM/DD/YYYY').split('/');
      this.dateAvailable.push({
        label: x.format('dddd, MMMM D, YYYY'),
        value: newDate[2] + newDate[0] + newDate[1],
      });
      this.dates.push({
        label: x.format('MMM DD, YYYY'),
        value: x.format('MMM DD, YYYY'),
      });
    });

    this.initLength = result.map((x) => x.format('MM/DD/YYYY')).length;
    this.dateAvailable.map((date) => {
      this.getAllAssignedGames();
    });
    this.isLoading = false;
  }

  /**
   * Gets all assigned games.
   */
  getAllAssignedGames() {
    this.httpService
      .get({
        url: `${environment.api.assignedGames}?tournamentId=${this.tournament.id}`,
      })
      .toPromise()
      .then(
        (games) => {
          console.log('gamesss', games);

          this.toScheduledList(games);
          this.getDivision();

          setTimeout((_) => {
            this.isFetching = false;
          }, 2000);
        },
        (err) => {
          setTimeout((_) => {
            this.isFetching = false;
          }, 2000);
        }
      );
  }

  /**
   * Opens the division page.
   *
   * @param division
   */
  openDivision(division: Division): void {
    this.router.navigate(
      [`/public-division/${this.tournament.id}/${division.id}`],
      { state: division }
    );
  }

  /**
   * Opens the venue page.
   *
   * @param venueId
   */
  openVenueSchedule(venueId: number): void {
    this.router.navigate([
      `/public-venue-schedules/${this.tournament.id}/${venueId}`,
    ]);
  }

  /**
   * Opens the team page.
   * @param team
   */
  openTeam(team: any, divisionId?): void {
    console.log('THIS IS TEAM', team);
    console.log('THIS IS divisionId', divisionId);

    console.log(
      `/public-team-games/${this.tournament.id}/${team.id}/${team.name}/${divisionId}`
    );

    this.router.navigate(
      [
        `/public-team-games/${this.tournament.id}/${team.id}/${team.name}/${divisionId}`,
      ],
      {
        state: {
          teamValue: team,
        },
      }
    );

    // this.router.navigate([
    //   `/public-team-games/${this.tournament.id}/${team.id}/${team.name}`,
    // ]);
  }

  /**
   * Updates the schedule on main filter changes (the left one).
   */
  onSelectFilter() {
    console.log('this.fil', this.filterOption);

    this.filterSubOptions = ['Fetching...'];
    this.isFilterSubOptionsLoading = true;

    switch (this.filterOption) {
      case 'all':
        this.getInitSchedule();
        this.switch = 'all';
        break;
      case 'team':
        this.getTeams();
        // this.getInitSchedule();
        // this.switch = 'all';
        break;
      case 'player':
        this.getPlayers();
        // this.getInitSchedule();
        this.switch = 'all';
        break;
      case 'date':
        // this.getDates();
        this.getInitSchedule();
        this.switch = 'all';
        break;
      case 'venue':
        this.getVenues();
        // this.getInitSchedule();
        // this.switch = 'all';
        break;
      case 'division':
        this.getDivision();
        this.switch = 'division';
        break;
    }
    console.log('this.filterOption', this.filterOption);
  }

  /**
   * Updates the schedule on sub filter changes (the right one).
   */
  onSelectSubFilter() {
    switch (this.filterOption) {
      case 'team':
        this.getTeamGames();
        const teamIndex = this.teams.findIndex(
          (teams: Teams) => teams.id === this.filterSubOption
        );
        console.log('teamIndex', teamIndex);

        if (teamIndex !== -1) {
          console.log(this.teams[teamIndex]);
          this.openTeam(
            this.teams[teamIndex],
            this.teams[teamIndex].division.id
          );
        }
        this.switch = 'team';
        break;
      case 'player':
        console.log('eto kasi', this.filterSubOption);

        if (this.filterSubOption == null) {
          this.getInitSchedule();
        } else {
          this.getPlayerGames();
        }
        break;
      case 'date':
        this.getDateGames();
        break;
      case 'venue':
        this.getVenueGames();
        this.openVenueSchedule(this.filterSubOption);
        break;
      case 'division':
        const divisionIndex = this.divisions.findIndex(
          (division: Division) => division.id === this.filterSubOption
        );
        if (divisionIndex !== -1) {
          this.openDivision(this.divisions[divisionIndex]);
        }
        this.switch = 'division';
        break;
    }
  }

  /**
   * Gets the teams of a tournament and displays it in the filter upon selection
   * of the team filter.
   */
  async getTeams() {
    if (!this.teams) {
      this.teams = await this.teamsService.getTournamentTeams(
        this.tournament.id
      );
      this.divisionTeams = await this.httpService
        .get({
          url: `${environment.api.teamDivisions}?tournamentId=${this.tournament.id}`,
        })
        .toPromise();
      console.log('teams', this.teams);
      console.log('div teams', this.divisionTeams);
      this.mergeTeamData();
    }

    let options = [];
    options.push({ label: 'All', value: null });
    for (let i = 0; i < this.teams.length; i++) {
      options.push({
        label: `${this.teams[i]?.name} (${this.teams[i].division?.name})`,
        value: this.teams[i]?.id,
      });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;
    this.isFilterSubOptionsLoading = false;

    if (this.filterSubOption) {
      this.getTeamGames();
    }
  }

  mergeTeamData() {
    this.teams = this.teams.map((team) => {
      const matchingTeam = this.divisionTeams.find(
        (t) => t.team.id === team.id
      );
      return {
        ...team,
        division: matchingTeam ? matchingTeam.division : null,
      };
    });
  }

  /**
   * Gets the players of a tournament and displays it in the filter upon selection
   * of the player filter.
   */
  async getPlayers() {
    if (!this.players) {
      this.players = await this.playerService.getTournamentPlayers(
        this.tournament.id
      );
    }

    console.log('pleyes', this.players);

    let options = [];
    options.push({ label: 'All', value: null });
    for (let i = 0; i < this.players.length; i++) {
      options.push({
        label: `${this.players[i].fullName} (${this.players[i].team.name})`,
        value: this.players[i].id,
      });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;
    this.isFilterSubOptionsLoading = false;

    console.log(options);

    if (this.filterSubOption) {
      this.getPlayerGames();
    }
  }

  /**
   * Gets the dates of a tournament and displays it in the filter upon selection
   * of the dates filter.
   */
  async getDates() {
    let dates: any[] = [];

    dates.push({
      label: 'All',
      value: 'all',
    });
    dates.push(...this.dates);

    console.log('ayan dates', dates);
    this.filterSubOption = dates.length > 0 ? dates[0].value : null;
    this.filterSubOptions = dates;
    this.isFilterSubOptionsLoading = false;

    console.log('this.filterSubOption1', this.filterSubOption);
  }

  /**
   * Gets the venues of a tournament and displays it in the filter upon selection
   * of the venues filter.
   */
  async getVenues() {
    if (!this.venues) {
      this.venues = await this.venueService
        .getVenues(this.tournament.id, this.organizationId)
        .toPromise();
    }

    let options = [];
    options.push({ label: 'All', value: null });
    for (let i = 0; i < this.venues.length; i++) {
      options.push({ label: this.venues[i].name, value: this.venues[i].id });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;
    this.isFilterSubOptionsLoading = false;

    console.log('this.filterSubOption2', this.filterSubOption);

    if (this.filterSubOption) {
      this.getVenueGames();
    }
  }

  /**
   * Gets the divisions of a tournament and displays it in the filter upon selection
   * of the venues filter.
   */
  async getDivision() {
    if (!this.divisions) {
      this.venues = await this.venueService
        .getVenues(this.tournament.id, this.organizationId)
        .toPromise();
      this.divisions = await this.divisionService.getTournamentDivision(
        this.tournament.id
      );
      this.handleUniqueVenues();
    }
    let options = [];
    options.push({ label: 'All', value: null });
    for (let i = 0; i < this.divisions.length; i++) {
      options.push({
        label: this.divisions[i].name,
        value: this.divisions[i].id,
      });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    if (this.filterOption == 'division') {
      this.filterSubOptions = options;
    } else if (this.filterOption == 'all') {
      this.filterSubOptions = ['N/A'];
    }
    this.isFilterSubOptionsLoading = false;

    console.log('this.filterSubOption3', this.filterSubOption);

    if (this.filterSubOption) {
      this.getDivisionGames();
    }

    console.log('divisionssssss', this.divisions);
  }

  /**
   * Gets the team's games.
   */
  async getTeamGames() {
    const games = await this.gamesService.getTeamGames(
      this.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  /**
   * Gets the player's games.
   */
  async getPlayerGames() {
    const games = await this.gamesService.getPlayerGames(
      this.tournament.id,
      this.filterSubOption
    );
    console.log('gamess', games);

    this.toScheduledList(games);
  }

  /**
   * Gets the date's games.
   */
  async getDateGames() {
    // const games = await this.gamesService.getDateGames(
    //   this.tournament.id,
    //   this.filterSubOption
    // );
    // this.toScheduledList(games);
    this.schedules = this.schedulesDuplicate;

    if (this.filterSubOption != 'all') {
      this.schedules = this.schedules.filter(
        (schedule) =>
          new DatePipe('en-US').transform(schedule.date, 'MMM d, y') ==
          new DatePipe('en-US').transform(this.filterSubOption, 'MMM d, y')
      );
    }
  }

  /**
   * Gets the venue's games.
   */
  async getVenueGames() {
    const games = await this.gamesService.getVenueGames(
      this.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  /**
   * Gets the division's games.
   */
  async getDivisionGames() {
    const games = await this.gamesService.getDivisionGames(
      this.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  /**
   * Formats the games into the scheduled list.
   *
   * @param games
   */
  toScheduledList(games: any) {
    console.log('geymz', games);
    console.log(games);
    console.log(games.length > 0);

    if (games && games.length > 0) {
      this.games = games;

      console.log('this.games', this.games);

      let schedules: any[] = [];
      let schedule: any = null;
      let lastDate: string = '';

      games.forEach((game: Games) => {
        const date: string = moment(game.date).format('YYYYMMDD');
        if (lastDate !== date) {
          if (null !== schedule) schedules.push(schedule);
          lastDate = date;
          schedule = { date: game.date, timeslots: [] };
        }
        let timeslot = schedule.timeslots.find((group: any) => {
          return group.time?.toLowerCase() == game.timeslot?.toLowerCase();
        });
        if (!timeslot) {
          timeslot = { time: game.timeslot, games: [] };
          schedule.timeslots.push(timeslot);
        }
        timeslot.games.push(game);
      });
      if (null !== schedule) schedules.push(schedule);

      this.schedules = schedules;

      console.log('SCHEDULESSS', this.schedules);

      // Extra function for By Date sorting
      if (this.filterOption == 'date') {
        this.schedulesDuplicate = this.schedules;
        this.getDates();
      }
    } else {
      console.log('here...');
      this.schedules = [];
    }
  }
  getGameNumber(gameName: string): string {
    if (gameName.includes('#')) {
      const match = gameName.match(/Game # \d+/);
      return match ? match[0] : '';
    } else {
      return gameName;
    }
  }

  handleUniqueVenues() {
    for (const division of this.divisions) {
      console.log('filterr', division);
      console.log('this.games', this.games);

      const divisionGames = this.games?.filter(
        (game) => game.division.id === division.id
      );
      const venueSet = new Set<string>();

      console.log('eto ay divisionGames', divisionGames);

      if (divisionGames) {
        for (const game of divisionGames) {
          venueSet.add(game.court?.venueName);
        }
      }

      this.uniqueVenuesByDivision[division.id] = Array.from(venueSet);
    }
  }
}
