<div class="">
  <div class="gen-info-wrapper">
    <form [formGroup]="form" class="tourney-gen-info-form">
      <div class="p-grid flex flex-center">
        <div class="p-md-6 p-sm-12 mb-5">
          <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"
            >Tournament Photo</span
          >
          <p-fileUpload
            [showUploadButton]="isEditTournament"
            name="fileLogo[]"
            accept="image/*"
            [customUpload]="true"
            (onClear)="clearLogo()"
            (onSelect)="onSelectLogo($event)"
            [showCancelButton]="false"
            (uploadHandler)="upload($event)"
          >
            <ng-template let-file pTemplate="file">
              <div
                *ngIf="isLogoFetching; else isFetchedLogo"
                class="text-center upload-content flex flex-center"
              >
                <p-progressSpinner
                  [style]="{ width: '40px', height: '40px' }"
                ></p-progressSpinner>
              </div>
              <ng-template #isFetchedLogo>
                <div class="disp-block text-center p-0" *ngIf="isLogoUploaded">
                  <img
                    [src]="logoUrl"
                    alt="Logo"
                    width="150px"
                    height="150px"
                  />
                </div>
              </ng-template>
            </ng-template>

            <ng-template pTemplate="content" let-files>
              <div
                *ngIf="!isLogoUploaded"
                class="upload-content flex flex-center"
              >
                <div
                  *ngIf="isLogoFetching; else isFetchedLogo"
                  class="text-center flex flex-center"
                >
                  <p-progressSpinner
                    [style]="{ width: '40px', height: '40px' }"
                  ></p-progressSpinner>
                </div>

                <ng-template #isFetchedLogo>
                  <div *ngIf="!hasLogo; else hasLogoTmp">
                    <div class="text-center text-gray3">
                      Upload&#160;<span class="text-bold2 text-primary1"
                        >Photo</span
                      >&#160;or Just Drag and Drop
                    </div>
                    <span class="text-gray3 disp-block text-center"
                      >150px X 150px</span
                    >
                  </div>
                  <ng-template #hasLogoTmp>
                    <div *ngIf="logoUrl" class="text-center">
                      <img
                        [src]="logoUrl"
                        alt="Logo"
                        width="150px"
                        height="150px"
                      />
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >Tournament Name <span class="required-indicator">*</span>
          <span><i class="pi pi-info-circle"></i></span
        ></label>
        <input
          formControlName="name"
          type="text"
          class="tournament-input-text"
        />
        <small
          *ngIf="
            form.controls['name'].invalid &&
            (form.controls['name'].dirty || form.controls['name'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div *ngIf="form.controls['name'].errors.required" class="mb-3 ml-2">
            Tournament name is required.
          </div>
        </small>
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3">
          State <span class="required-indicator">*</span></label
        >
        <p-autoComplete
          styleClass="w-100"
          appendTo="body"
          inputStyleClass="input-text disp-block autocomplete-100 my-2"
          autocomplete="noauto"
          emptyMessage="No results found"
          field="label"
          formControlName="cityState"
          [autoHighlight]="true"
          [forceSelection]="true"
          [suggestions]="cityStates"
          (completeMethod)="searchCityState($event)"
          dropdownIcon="pi pi-angle-down"
        >
        </p-autoComplete>
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >Start Date <span class="required-indicator">*</span>
          <span><i class="pi pi-info-circle"></i></span
        ></label>
        <p-calendar
          formControlName="startDate"
          type="date"
          dateFormat="mm/dd/yy"
          [placeholder]=""
        >
        </p-calendar>
        <small
          *ngIf="
            form.controls['startDate'].invalid &&
            (form.controls['startDate'].dirty ||
              form.controls['startDate'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="form.controls['startDate'].errors.required"
            class="mb-3 ml-2"
          >
            Start Date is required.
          </div>
        </small>
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >End Date <span class="required-indicator">*</span>
          <span><i class="pi pi-info-circle"></i></span
        ></label>
        <p-calendar
          class="tk-date-picker"
          formControlName="endDate"
          type="date"
          dateFormat="mm/dd/yy"
          [placeholder]=""
        >
        </p-calendar>
        <small
          *ngIf="
            form.controls['endDate'].invalid &&
            (form.controls['endDate'].dirty || form.controls['endDate'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="form.controls['endDate'].errors.required"
            class="mb-3 ml-2"
          >
            End Date is required.
          </div>
        </small>
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >Entry Fee <span class="required-indicator">*</span
          ><span><i class="pi pi-info-circle"></i></span
        ></label>
        <input
          formControlName="entryFee"
          type="number"
          class="tournament-input-text"
          appNumericInput
        />
        <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >Games Guaranteed to Play <span class="required-indicator">*</span
          ><span><i class="pi pi-info-circle"></i></span
        ></label>
        <input
          formControlName="games"
          type="number"
          class="tournament-input-text"
        />
        <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >Gender <span class="required-indicator">*</span></label
        >
        <div class="tk-radiobutton-group">
          <div class="tk-radiobutton-items">
            <p-radioButton
              class="tk-radioButton"
              name="gender"
              value="MALE"
              label="Male"
              formControlName="gender"
              inputId="male"
            ></p-radioButton>
          </div>
          <div class="tk-radiobutton-items">
            <p-radioButton
              class="tk-radioButton"
              name="gender"
              value="FEMALE"
              label="Female"
              formControlName="gender"
              inputId="female"
            ></p-radioButton>
          </div>
          <div class="tk-radiobutton-items">
            <p-radioButton
              class="tk-radioButton"
              name="gender"
              value="COED"
              label="Coed"
              formControlName="gender"
              inputId="both"
            ></p-radioButton>
          </div>
        </div>
        <small
          *ngIf="
            form.controls['gender'].invalid &&
            (form.controls['gender'].dirty || form.controls['gender'].touched)
          "
          class="text-error text-left text-primary2"
        >
          <div
            *ngIf="form.controls['gender'].errors.required"
            class="mb-3 ml-2"
          >
            Gender is required.
          </div>
        </small>
      </div>

      <div class="container-fields">
        <label class="py-3 TheOpenSans-Regular text-bold3"
          >Age Group <span class="required-indicator">*</span>
          <span><i class="pi pi-info-circle"></i></span
        ></label>
        <div class="tk-radiobutton-group">
          <div class="tk-radiobutton-items">
            <p-radioButton
              class="tk-radioButton"
              name="group"
              value="GRADE"
              label="Grade"
              formControlName="group"
              inputId="grade"
            ></p-radioButton>
          </div>
          <div class="tk-radiobutton-items">
            <p-radioButton
              class="tk-radioButton"
              name="group"
              value="AGE"
              label="Age"
              formControlName="group"
              inputId="age"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="container-group">
        <div class="age-grade-group-container">
          <p-listbox
            class="age-grade-group-list"
            [options]="gradeGroupTypes"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="false"
            optionLabel="label"
            formControlName="gradeGroups"
            [disabled]="isGradeGroup"
          >
            <p-header> GRADE </p-header>
          </p-listbox>
          <p-listbox
            class="age-grade-group-list"
            [options]="ageGroupTypes"
            [multiple]="true"
            [checkbox]="true"
            [showToggleAll]="false"
            optionLabel="label"
            formControlName="ageGroups"
            [disabled]="isAgeGroup"
          >
            <p-header> AGE </p-header>
          </p-listbox>
        </div>
        <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
      </div>

      <div class="container-fields container-margin-alignment mb-5">
        <label class="pb-3 TheOpenSans-Regular text-bold3"
          >Tournament Description <span class="required-indicator">*</span
          ><span><i class="pi pi-info-circle"></i></span
        ></label>
        <textarea
          [placeholder]="''"
          formControlName="description"
          rows="7"
          cols="30"
          class="tournament-inputarea-text"
          autoResize="autoResize"
        ></textarea>
        <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
      </div>

      <p-fieldset [toggleable]="true" [collapsed]="collapseAdvanceSettings">
        <p-header>Advance Settings</p-header>
        <div class="container-fields">
          <label class="py-3 TheOpenSans-Regular text-bold3"
            >Game Length in Minutes
            <span><i class="pi pi-info-circle"></i></span
          ></label>
          <div class="vs-container">
            <input
              formControlName="gameDuration"
              type="number"
              class="tournament-input-text"
            />
          </div>
        </div>

        <div class="container-fields">
          <label class="py-3 TheOpenSans-Regular text-bold3"
            >Team Terms <span><i class="pi pi-info-circle"></i></span
          ></label>
          <div class="vs-container">
            <input
              formControlName="homeTerm"
              type="text"
              class="tournament-input-text"
            />
            <span class="cross-multiplier-label">v.</span>
            <input
              formControlName="awayTerm"
              type="text"
              class="tournament-input-text"
            />
          </div>
          <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
        </div>

        <div class="container-fields">
          <label class="py-3 TheOpenSans-Regular text-bold3"
            >Total Players per Roster
            <span><i class="pi pi-info-circle"></i></span
          ></label>
          <input
            formControlName="totalPlayersPerRoster"
            type="number"
            min="0"
            class="tournament-input-text"
          />
          <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
        </div>

        <div class="container-fields">
          <label class="py-3 TheOpenSans-Regular text-bold3"
            >Tournament Info Url
            <span><i class="pi pi-info-circle"></i></span>
            <p-checkbox
              formControlName="useMyWhiteLabelSite"
              [binary]="true"
              class="use-white-label ml-5"
              label="Use My White Label Site"
            >
            </p-checkbox>
          </label>
          <input
            formControlName="tournamentInfoUrl"
            [readOnly]="disableSite"
            type="text"
            class="tournament-input-text"
          />
          <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
        </div>

        <div class="container-fields container-margin-alignment mb-5">
          <label class="py-3 TheOpenSans-Regular text-bold3"
            >Additional Information
            <span><i class="pi pi-info-circle"></i></span
          ></label>
          <textarea
            [placeholder]="''"
            formControlName="additionalInformation"
            rows="5"
            cols="30"
            class="tournament-inputarea-text"
            autoResize="autoResize"
          ></textarea>
          <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
        </div>
      </p-fieldset>

      <br />

      <p-fieldset [toggleable]="true" [collapsed]="collapseTieBreakers">
        <p-header>Tiebreakers</p-header>

        <div class="container-fields">
          <label class="py-3 TheOpenSans-Regular text-bold3 disp-block mb-2"
            >Tiebreakers within Pools<span
              ><i class="pi pi-info-circle"></i></span
          ></label>
          <ng-container formArrayName="poolTieBreakers">
            <div
              class="container-fields py-2"
              *ngFor="let tie of poolTieBreakers.controls; let i = index"
            >
              <div class="p-grid">
                <div class="p-col-11">
                  <div class="full-dropdown">
                    <p-dropdown
                      [formControlName]="i"
                      [options]="
                        i == 0 ? poolTiebreakerTypes : poolTiebreakerOptions
                      "
                      [placeholder]="
                        tie.value == ''
                          ? 'SELECT..'
                          : getLabel(tie.value.poolTieBreakerType)
                      "
                      optionLabel="label"
                      styleClass="m-0"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="p-col-1">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger mt-2 top-0 right-0"
                    style="height: 28px; width: 28px"
                    pTooltip="Delete Criteria?"
                    (click)="deletePoolSequence(i)"
                  ></button>
                </div>
              </div>
            </div>

            <div class="p-col-12 text-center">
              <button
                pButton
                label="Add Criteria"
                class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-2"
                [disabled]=""
                (click)="addPoolSequence()"
              ></button>
            </div>
          </ng-container>
        </div>
        <br />

        <div class="container-fields">
          <label class="py-3 TheOpenSans-Regular text-bold3 disp-block mb-2"
            >Tiebreakers within Divisions<span
              ><i class="pi pi-info-circle"></i></span
          ></label>
          <ng-container formArrayName="divisionTieBreakers">
            <div
              class="container-fields py-2"
              *ngFor="let tie of divisionTieBreakers.controls; let i = index"
            >
              <div class="p-grid">
                <div class="p-col-11">
                  <div class="full-dropdown">
                    <p-dropdown
                      [formControlName]="i"
                      [options]="
                        i == 0
                          ? divisionTiebreakerTypes
                          : divisionTiebreakerOptions
                      "
                      [placeholder]="
                        tie.value == ''
                          ? 'SELECT..'
                          : getLabel(tie.value.divisionTieBreakerType)
                      "
                      styleClass="m-0"
                      optionLabel="label"
                    ></p-dropdown>
                  </div>
                </div>

                <div class="p-col-1">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-danger mt-2 top-0 right-0"
                    style="height: 28px; width: 28px"
                    pTooltip="Delete Criteria?"
                    (click)="deleteDivisionSequence(i)"
                  ></button>
                </div>
              </div>
            </div>

            <div class="p-col-12 text-center">
              <button
                pButton
                label="Add Criteria"
                class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-2"
                [disabled]="isUpdate"
                (click)="addDivisionSequence()"
              ></button>
            </div>
          </ng-container>
        </div>
      </p-fieldset>
      <br />
      <p-fieldset [toggleable]="true" [collapsed]="collapsePromoCode">
        <p-header>Discount Codes</p-header>

        <div class="container-fields">
          <!-- <label class="py-3 TheOpenSans-Regular text-bold3 disp-block mb-2"
                >Promo Code Display Name<span><i class="pi pi-info-circle"></i></span
              ></label>
              <input type="text" class="tournament-input-text" [placeholder]="" /> -->
          <!--span class="ui-inputgroup-addon"><i class="pi pi-check-square"></i></span-->
        </div>

        <br />
        <ng-container formArrayName="discountCodes">
          <div
            class="p-grid"
            *ngFor="let promo of discountCodes.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="p-col-6">
              <label class="TheOpenSans-Regular text-bold3 disp-block mb-2"
                >Promo Code<span></span
              ></label>
            </div>
            <div class="p-col-6">
              <label class="TheOpenSans-Regular text-bold3 disp-block pl-0 mb-2"
                >Discount Amount<span></span
              ></label>
            </div>
            <div class="p-col-11">
              <div class="vs-container">
                <input
                  type="text"
                  [placeholder]="''"
                  formControlName="code"
                  class="tournament-input-text"
                />
                <span class="cross-multiplier-label">to</span>
                <input
                  type="number"
                  [placeholder]="''"
                  formControlName="amount"
                  class="tournament-input-text"
                />
              </div>
            </div>
            <div class="p-col-1">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger mt-2 top-0 right-0"
                style="height: 28px; width: 28px"
                pTooltip="Delete Promo?"
                (click)="deletePromo(i)"
              ></button>
            </div>
          </div>
          <div class="p-col-12 text-center">
            <button
              pButton
              label="Add Promo"
              class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-2"
              [disabled]="isUpdate"
              (click)="addPromoCode()"
            ></button>
          </div>
        </ng-container>
      </p-fieldset>
    </form>

    <br />

    <div class="p-col-12 text-center">
      <button
        pButton
        label="Cancel"
        class="p-button-raised p-button-rounded p-button-white mx-2 px-5 py-3"
        (click)="close()"
      ></button>
      <button
        pButton
        type="submit"
        label="Submit"
        class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
        [disabled]="!form.valid"
        (click)="onSubmit()"
      ></button>
    </div>
  </div>
</div>
