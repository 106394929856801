import { Component, OnInit } from '@angular/core';
import { BracketService } from '@core/services/bracket-service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@core/services';

@Component({
  selector: 'app-delete-bracket',
  templateUrl: './delete-bracket.component.html',
  styleUrls: ['./delete-bracket.component.scss'],
})
export class DeleteBracketComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private bracketService: BracketService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.close();
  }

  onSubmit() {
    let bracketId = this.config.data;
    console.log('bracketid', bracketId);

    this.bracketService
      .deleteBracket(bracketId)
      .then((res) => {
        this.bracketService.deleteBracket$.next(bracketId);
        this.ref.close();
      })
      .catch((e) => {
        this.bracketService.deleteBracket$.next(bracketId);
        // this.toastService.errorMsg({ message: 'Failed! Something went wrong' });
        this.ref.close();
      });
  }
}
