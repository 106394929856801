import { GeneralInfoComponent } from '@admin/tournament/general-info/general-info.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignupComponent } from '@components/public/signup/signup.component';
import { defConstant } from '@core/data/constant-data';
import {
  EmitterService,
  HttpService,
  SessionStorageService,
} from '@core/services';
import { LoginModalComponent } from '@public/public-registration/login-modal/login-modal.component';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { PublishTournamentComponent } from '../publish-tournament/publish-tournament.component';

@Component({
  selector: 'app-publish-modal',
  templateUrl: './publish-modal.component.html',
  styleUrls: ['./publish-modal.component.scss'],
})
export class PublishModalComponent implements OnInit {
  ref: DynamicDialogRef;
  constructor(
    public reff: DynamicDialogRef,
    private dialogService: DialogService,
    private router: Router,
    private httpService: HttpService,
    private emitterService: EmitterService,
    private sessionStorageService: SessionStorageService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {}

  close(): void {
    this.reff.close(1);
  }

  signup(): void {
    this.ref = this.dialogService.open(SignupComponent, {
      width: defConstant.modalWidth,
      closable: false,
    });
  }

  async login() {
    this.ref = this.dialogService.open(LoginModalComponent, {
      width: '550px',
      closable: false,
      data: {
        showCancel: true,
      },
    });

    this.ref.onClose.subscribe((res) => {
      this.close();
      this.checkUserRole();
    });
  }

  async checkUserRole() {
    const me = await this.httpService.me().toPromise();
    console.log(me?.userType);
    if (me?.userType === 'director') {
      this.ref = this.dialogService.open(PublishTournamentComponent, {
        header: 'PUBLISH TOURNAMENT',
        width: '1100px',
        dismissableMask: true,
        closable: false,
      });
    }
  }
}
