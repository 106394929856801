import {
    Directive,
    ElementRef,
    Renderer2,
    OnDestroy,
    ContentChild,
    AfterContentInit, Input, Optional, Inject
} from '@angular/core';
import {RequiredInputDirective} from './required-input.directive';
import {RequiredInputIndicatorDirective} from './required-input-indicator.directive';
import {AutoComplete} from 'primeng/autocomplete';
import {Subscription} from "rxjs";

@Directive({
    selector: '[tourneykeyRequiredInputContainer]'
})
export class RequiredInputContainerDirective implements OnDestroy, AfterContentInit {

    @ContentChild(RequiredInputDirective) inputDir;

    @ContentChild(RequiredInputIndicatorDirective) indicatorDir;

    @ContentChild(AutoComplete) autoComplete: AutoComplete;

    @Input()
    positionIndicator: Function;

    @Input()
    checkRequired: Function;

    @Input()
    requiredIndicationPaddingLeft = 4;

    @Input()
    isRequired = true;

    @Input()
    requiredIndicatorStyle = 'required-input-indicator-internal-style';


    changeListener: Function;
    focusListener: Function;
    blurListener: Function;
    inputListener: Function;

    aChangeListener$: Subscription;
    aFocusListener$: Subscription;
    aBlurListener$: Subscription;
    aInputListener$: Subscription;


    currentDisplay = 'none';
    currentVisibility = 'hidden';

    _isFocused = false;

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }


    _positionIndicator() {
        if (this.positionIndicator) {
            this.positionIndicator({
                indicator: this.indicatorDir,
                input: this.inputDir,
                containerEl: this.el,
                autoComplete: this.autoComplete
            });
        } else {
            this.renderer.setStyle(this.indicatorDir.element.nativeElement, 'left',
                `${this.inputDir.element.nativeElement.placeholder.length + this.requiredIndicationPaddingLeft}ch`);
        }
    }

    _checkRequired(targetElem) {

        if (this.checkRequired) {

            return this.checkRequired({
                indicator: this.indicatorDir,
                input: this.inputDir,
                containerEl: this.el,
                isRequired: this.isRequired,
                autoComplete: this.autoComplete
            })

        } else if (!this.isRequired) {
            return false
        } else {
            return targetElem.required &&
                !(targetElem.value &&
                    targetElem.value.trim() != "");
        }

    }

    _hideIndicator() {
        this.renderer.setStyle(this.indicatorDir.element.nativeElement, 'visibility', "hidden");
        this.renderer.setStyle(this.indicatorDir.element.nativeElement, 'display', "none");
    }

    _showIndicator() {
        this.renderer.setStyle(this.indicatorDir.element.nativeElement, 'visibility', this.currentVisibility);
        this.renderer.setStyle(this.indicatorDir.element.nativeElement, 'display', this.currentDisplay);
    }

    _validateIndicator(targetElem) {
        if (this._checkRequired(targetElem)) {
            this._showIndicator();
        } else {
            this._hideIndicator();
        }
    }

    _cacheIndicatorStyle() {
        this.currentVisibility = this.indicatorDir.element.nativeElement.style.visibility;
        this.currentDisplay = this.indicatorDir.element.nativeElement.style.display;

        this.renderer.addClass(this.indicatorDir.element.nativeElement, this.requiredIndicatorStyle);
    }

    _setupListeners() {
        if (this.autoComplete) {

            this.aChangeListener$ = this.autoComplete.onSelect.subscribe(() => {
                this._isFocused = false;
                this._validateIndicator(this.autoComplete);
            });

            this.aFocusListener$ = this.autoComplete.onFocus.subscribe(() => {
                this._isFocused = true;
                this._validateIndicator(this.autoComplete);
            });

            this.aBlurListener$ = this.autoComplete.onBlur.subscribe(() => {
                this._isFocused = false;
                this._validateIndicator(this.autoComplete);
            });

            this.aInputListener$ = this.autoComplete.onKeyUp.subscribe(() => {
                this._isFocused = true;
                this._validateIndicator(this.autoComplete);
            });

        } else {
            this.changeListener = this.renderer.listen(this.inputDir.element.nativeElement, 'change', () => {
                this._isFocused = false;
                this._validateIndicator(this.inputDir.element.nativeElement);
            });

            this.focusListener = this.renderer.listen(this.inputDir.element.nativeElement, 'focus', () => {
                this._isFocused = true;
                this._validateIndicator(this.inputDir.element.nativeElement);
            });

            this.blurListener = this.renderer.listen(this.inputDir.element.nativeElement, 'blur', () => {
                this._isFocused = false;
                this._validateIndicator(this.inputDir.element.nativeElement);
            });

            this.inputListener = this.renderer.listen(this.inputDir.element.nativeElement, 'input', () => {
                this._isFocused = true;
                this._validateIndicator(this.inputDir.element.nativeElement);
            });
        }
    }

    _removeListeners() {
        this.changeListener = null;
        this.focusListener = null;
        this.blurListener = null;
        this.inputListener = null;
        if (this.aChangeListener$)
            this.aChangeListener$.unsubscribe();
        if (this.aFocusListener$)
            this.aFocusListener$.unsubscribe();
        if (this.aBlurListener$)
            this.aBlurListener$.unsubscribe();
        if (this.aInputListener$)
            this.aInputListener$.unsubscribe();
    }


    ngOnDestroy() {
        this._removeListeners();
    }

    ngAfterContentInit(): void {
        this._cacheIndicatorStyle();
        this._validateIndicator(this.autoComplete ? this.autoComplete : this.inputDir.element.nativeElement);
        this._positionIndicator();
        this._setupListeners();
    }

}
