<div class="p-grid">
  <div class="p-col-12 text-center my-2">
    <span class="fs-2 text-black5 text-bold1">
      Are you sure you want to delete this bracket set {{config.data.name}}?
    </span>
    <br/>
    <br/>
    <div>
      <!-- <span class="fs-2 text-red text-bold1">
        (This action will also delete the games you might have created)
      </span> -->
    </div>
  </div>
  <div class="p-col-12 flex flex-center">
    <button
      pButton
      pRipple
      type="button"
      label="No"
      class="
        p-button-raised p-button-rounded p-button-text p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="cancel()"
    ></button>
    <button
      pButton
      iconPos="right"
      type="button"
      label="Yes"
      class="
        p-button-raised p-button-rounded p-button-danger p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="onSubmit()"
    ></button>
  </div>
</div>
