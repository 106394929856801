import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { defConstant, defStates } from '@core/data/constant-data';
import { Division } from '@core/models/division';
import { Registration } from '@core/models/registration';
import { AuthService, HelperService } from '@core/services';
import { HttpService } from '@core/services/http.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { ToastService } from '@core/services/toast.service';
import { environment } from '@env/environment';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { AddExistingTeamComponent } from '@public/public-registration/add-existing-team/add-existing-team.component';
import { AddTeamModalComponent } from '@public/public-registration/add-team-modal/add-team-modal.component';
import { LoginComponent } from '@components/public/login/login.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AddPlayerModalComponent } from '@public/public-registration/add-player/add-player.component';
import { DivisionModalComponent } from '@public/public-registration/division-modal/division-modal.component';
import { TeamModalComponent } from '@public/public-registration/team-modal/team-modal.component';
import { Teams } from '@core/models/teams';
import { AddExistingPlayerComponent } from '@public/public-registration/add-existing-player/add-existing-player.component';
import { AddCoachComponent } from '@public/public-registration/add-coach/add-coach.component';
import { AddExistingCoachComponent } from '@public/public-registration/add-existing-coach/add-existing-coach.component';
import { Player } from '@core/models/players';
import { PlayerService } from '@core/services/player.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripeTest: FormGroup;

  form: FormGroup;
  tournamentId: string;
  registration: Registration;
  divisions: Division[] = [];
  selectedDivision: Division;
  selectedTeam: Teams;
  tournamentDate: any;
  tournament: any = {};
  teams: any[] = [];
  coaches: any[] = [];
  players: any[] = [];
  payments: any[] = [];
  teamPlayerCoaches: any[] = [];
  logoUrl: string = '';
  totalPayment: number = 0;

  collapseInfo = false;
  collapseDivision = false;
  collapseTeam = false;
  collapseCoach = false;
  collapsePayment = false;
  collapsePlayer = false;
  collapseTerms = false;
  isValidated = true;
  arePlayersValid = true;
  areCoachesValid = true;
  isCoach = false;
  headCoach: any;
  tourney: any;
  ref: DynamicDialogRef;
  noTeams: boolean;
  stripeError: string = '';
  teamPhoto: string = '';
  policy: string = '';
  terms: string = '';

  states = defStates;

  primaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  /**
   * Initial terms and condition
   */
  initTerms = `By registering you understand that you and or your players cannot hold the tourney organizer liable for any injury possibly incurred and that we may use you or your teams likeness in our media.`;

  /**
   * Initial refund policy
   */
  initRefund = `• Refunds for cancelled team/individual registrations will be granted if processed 30 days BEFORE the start of an event. NOTE: There will be a ($50.00) charge assessed to process each request.

  • There will be no refunds processed for teams/individuals who withdraw, cancel, or forfeit games/participation within 30 days of the start of an event.

  • All refunds (if granted) will be processed at the end of each specified event.

  • In a circumstance in which the weather causes game cancellations, there will be no refunds granted.

  • Note: If an ENTIRE event is cancelled due to Covid-19, all teams will have their registration fees refunded.`;

  /**
   * CONSTANTS
   */
  REQ = 'REQUIRED';
  NOT_REQ = 'NOT_REQUIRED';

  /**
   * For login function when require acct is required or optional
   */
  @ViewChild('opLogin', { static: false }) opLogin;
  @ViewChild(LoginComponent) child: LoginComponent;
  @ViewChild('opLogins') opLogins: OverlayPanel;
  toggleOverlay = ({ originalEvent }) => this.opLogins.toggle(originalEvent);

  @Input()
  set divisionInfo(value) {}

  gender = [{ label: 'Male' }, { label: 'Female' }, { label: 'Coed' }];

  constructor(
    private _http: HttpService,
    private toastService: ToastService,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private dialogService: DialogService,
    private publicTournament: PublicTournamentsService,
    private helperService: HelperService,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private _location: Location,
    private playerService: PlayerService
  ) {
    this.form = this.fb.group({
      id: [null],
      role: ['coach'],
      firstName: [''],
      lastName: [''],
      email: [''],
      phone: [''],
      coachAddress: [''],
      coachCity: [''],
      coachState: [''],
      postal: [''],
      ccname: [''],
      ccnumber: [''],
      ccexpiration: [''],
      cccode: [''],
      isAccept: [''],
      coachFacebook: [''],
      coachTwitter: [''],
      coachInstagram: [''],
      payments: this.fb.group({}),
      additionalCoachInfo: this.registration?.additionalCoachInfo,
    });
  }

  listenAuth(event: Event): void {
    this.authService.isLoggedInEmitter.subscribe((log) => {
      this.isCoach = true;
      this.setCoachInfo();
      this.opLogins.hide();
    });
  }

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });

    this.activatedRoute.paramMap.pipe(
      map(() => (this.tournamentId = window.history.state))
    );

    const tournamentId = sessionStorage.getItem('tournamentId');

    this.publicTournament
      .getPublicDivisions(tournamentId)
      .subscribe((divs) => (this.divisions = divs));

    this.getData(tournamentId);
    this.getRegistration(tournamentId);

    /**
     * Sets the coach details if logged in.
     */
    this.setCoachInfo();

    this.noTeams = true;
    this.teams.splice(0, this.teams.length);
    this.publicTournament.initialTeam();
    this.isCoach = this.publicTournament.isCoach;
  }

  createToken(): string {
    const name = this.stripeTest.get('name').value;
    let token: string;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          console.log('this is the token', result.token.id);
          token = result.token.id;
          this.registerTeam(token);
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
    return token;
  }

  /**
   * Dynamically add the validator to input fields depending
   * on the registration setting.
   */
  addValidator(formControlName: string) {
    this.form.controls[formControlName]?.setValidators([Validators.required]);
    this.form.controls[formControlName]?.updateValueAndValidity();
  }

  /**
   * Function to set the coach details if logged in.
   */
  setCoachInfo(): void {
    this.httpService.me().subscribe((me) => {
      this.headCoach = me;

      if (me != null && me.userType == 'coach') {
        let cityState = me.cityState.split(',');

        this.form.patchValue({
          id: me.id,
          firstName: me.firstName,
          lastName: me.lastName,
          email: me.email,
          phone: me.phoneNumber,
          postal: me.zipCode,
          address: me.address,
          city: cityState[0],
          state: cityState[1],
        });
      }
    });
  }

  /**
   * Gets the registration data of the tournament
   */
  async getRegistration(id) {
    console.log('pumasooook');
    console.log('pumasooook');
    console.log('pumasooook');

    try {
      this._http
        .get({
          url: `${environment.api.tournamentRegistration}?tournamentId=${id}`,
          payload: {},
        })
        .toPromise()
        .then((res: Registration) => {
          console.log('THIS IS RES', res);

          this.registration = res;
          console.log('this is regg', this.registration);

          this.policy = res?.refundPolicy;
          this.terms = res?.termsAndCondition;
          Object.entries(res).find(([key, value]) => {
            if (value == 'REQUIRED') {
              console.log(key, 'required');
              this.addValidator(key);
            }

            // if (res?.requirePayment == 'REQUIRED') {
            //   this.addValidator('ccname');
            //   this.addValidator('ccnumber');
            //   this.addValidator('cccode');
            //   this.addValidator('ccexpiration');
            // }

            // this.addValidator('firstName');
            // this.addValidator('lastName');
            // this.addValidator('email');
            // this.addValidator('phone');
            this.addValidator('isAccept');
          });
        })
        .catch((err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the tournament registration info',
            'error'
          );
        });
    } catch (err) {}
  }

  /**
   * Gets the tournament data.
   */
  async getData(id) {
    try {
      this.tourney = await this.httpService
        .get({
          url: `${environment.api.tournaments}/${id}`,
          payload: {},
        })
        .toPromise();

      if (this.tourney) {
        const {
          id,
          name,
          base64Photo,
          city,
          organization,
          startDate,
          endDate,
          entryFee,
          eventDeposit,
          games,
          gender,
          additionalInformation,
          gamesDuration,
          homeTerm,
          awayTerm,
          totalPlayersPerRoster,
          useMyWhiteLabelSite,
          tournamentInfoUrl,
          discountCodes,
          photo,
          ageGroups,
          gradeGroups,
          poolTieBreakers,
          divisionTieBreakers,
        } = this.tourney;

        console.log('this.tourneyyy', organization);

        if (null == organization.primaryColor) {
          this.primaryColor = '#a90000';
          this.fontColor = '#ffffff';
          this.fontColorOpposite = '#000000';
        } else {
          this.primaryColor = organization.primaryColor;
          this.getFontColor(organization.primaryColor);
        }

        this.tournamentDate = this.helperService.dateFormatFromTo(
          this.tourney?.startDate,
          this.tourney?.endDate
        );

        this.payments.push({
          name: `$${entryFee} (Registration)`,
          value: parseInt(entryFee),
          type: 'FULLY_PAID',
        });
        this.payments.push({
          name: `$${eventDeposit} (Deposit)`,
          value: parseInt(eventDeposit),
          type: 'DEPOSIT',
        });

        if (photo) {
          this.logoUrl = `${environment.api.tournamentPhoto}/${photo}`;
        }
      }
    } catch (err) {}
  }

  /**
   * Calls the add new coach modal.
   */
  addNewCoach(): void {
    this.ref = this.dialogService.open(AddCoachComponent, {
      header: 'New Coach',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
          registration: this.registration,
          team: this.selectedTeam,
        },
      },
    });

    this.ref.onClose.subscribe((coach: any) => {
      if (coach) {
        this.coaches.push(coach);
        this.teamPlayerCoaches.map((tpc) => {
          if (tpc.team.name === coach.team.name) {
            tpc.coaches.push(coach);
          }
        });
      }
    });
  }

  editCoach(i: number, j: number): void {
    this.ref = this.dialogService.open(AddCoachComponent, {
      header: 'Edit Coach',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
          registration: this.registration,
          team: this.selectedTeam,
          coach: this.teamPlayerCoaches[i].coaches[j],
        },
      },
    });

    this.ref.onClose.subscribe((coach: any) => {
      if (coach) {
        this.teamPlayerCoaches[i].coaches[j] = {
          ...coach,
        };
      }
    });
  }

  /**
   * Calls the add existing coach modal.
   */
  addExistingCoach(): void {
    this.ref = this.dialogService.open(AddExistingCoachComponent, {
      header: 'Existing Coaches',
      width: defConstant.modalLargeWidth,
      closable: false,
      data: {
        result: {
          team: this.selectedTeam,
          existingCoaches: this.coaches,
        },
      },
    });

    this.ref.onClose.subscribe((coach: any) => {
      if (coach) {
        this.coaches.push(coach);
        this.teamPlayerCoaches.map((tpc) => {
          if (tpc.team.name === coach.team.name) {
            tpc.coaches.push(coach);
          }
        });
      }
    });
  }

  /**
   * Calls the add new player modal.
   */
  addNewPlayer(): void {
    this.ref = this.dialogService.open(AddPlayerModalComponent, {
      header: 'New Player',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
          registration: this.registration,
          team: this.selectedTeam,
        },
      },
    });

    this.ref.onClose.subscribe((player: Player) => {
      if (player) {
        this.players.push(player);
        this.teamPlayerCoaches.map((tpc) => {
          if (tpc.team.name === player.team.name) {
            tpc.players.push(player);
          }
        });
        this.sortPlayers();
      }
    });
  }

  /**
   * Calls the add new player modal.
   */
  editPlayer(i: number, j: number): void {
    this.ref = this.dialogService.open(AddPlayerModalComponent, {
      header: 'Edit Player',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
          registration: this.registration,
          player: this.teamPlayerCoaches[i].players[j],
        },
      },
    });

    this.ref.onClose.subscribe((player: Player) => {
      if (player) {
        this.teamPlayerCoaches[i].players[j] = {
          ...player,
        };
        this.sortPlayers();
      }
    });
  }

  /**
   * Sorts the players in a team
   * @returns void
   */
  sortPlayers(): void {
    this.teamPlayerCoaches = this.teamPlayerCoaches.map((team) => ({
      ...team,
      players: team.players.sort((a, b) => a.jerseyNumber1 - b.jerseyNumber1),
    }));
    if (this.players?.length === 0) return;
    this.players.sort((a, b) => a.jerseyNumber1.localeCompare(b.jerseyNumber1));
  }

  /**
   * Sorts the teams in a division
   * @returns void
   */
  sortTeams(): void {
    if (this.teams?.length === 0) return;
    this.teams.sort((a, b) => a.name.localeCompare(b.name));

    if (this.teamPlayerCoaches?.length === 0) return;
    this.teamPlayerCoaches.sort((a, b) =>
      a.team.name.localeCompare(b.team.name)
    );
  }

  generateFormGrp(formCtrl) {
    this.form.addControl(`coach_${formCtrl}`, this.fb.control(''));
    return `coach_${formCtrl}`;
  }

  /**
   * Generate added payment form control programatically.
   * @param index
   * @returns
   */
  generatePaymentFormCtrl(index: number) {
    const formArr = this.form.get('payments') as FormGroup;
    formArr.addControl(`payment${index}`, this.fb.control(''));
    return `payment${index}`;
  }

  calculateTotalPayment() {
    this.totalPayment = 0;

    let teamPayments = this.form.get('payments');

    for (let i = 0; i < this.teams?.length; i++) {
      let payControlValue = teamPayments.get(`payment${i}`).value;
      console.log('payControlValuepayControlValue', payControlValue);

      let paidAmount = payControlValue.value as number;
      let paymentStatus = payControlValue.type as string;

      this.totalPayment = paidAmount + this.totalPayment;

      this.teamPlayerCoaches.map((tpc) => {
        if (tpc.team.name === this.teams[i].name) {
          tpc.paidAmount = paidAmount;
          tpc.paymentStatus = paymentStatus;
        }
      });
    }
  }

  /**
   * Opens the division select modal before adding
   * new or existing team.
   * @param isAdd
   */
  selectDivision(isAdd: boolean) {
    const divId = sessionStorage.getItem('divId');
    let existDiv;

    if (divId) {
      existDiv = this.divisions.find((division) => division?.id == divId);

      console.log('exist div: ', existDiv);
      console.log('div id: ', divId);
    }

    this.ref = this.dialogService.open(DivisionModalComponent, {
      header: 'Select Division',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        divisions: this.divisions,
        division: existDiv,
      },
    });

    this.ref.onClose.subscribe((res: Division) => {
      if (res && isAdd) {
        this.selectedDivision = res;
        this.addNewTeam();
      } else if (res && !isAdd) {
        this.selectedDivision = res;
        this.addExistingTeam();
      }
    });
  }

  /**
   * Opens the team select modal before adding
   * new or existing player.
   * @param isAdd
   */
  selectTeam(isAdd: boolean, isCoach: boolean = true) {
    if (this.teams?.length === 1) {
      this.selectedTeam = this.teams[0];

      if (isAdd && isCoach) this.addNewCoach();
      if (!isAdd && isCoach) this.addExistingCoach();
      if (isAdd && !isCoach) this.addNewPlayer();
      if (!isAdd && !isCoach) this.addExistingPlayer();
      return;
    }

    this.ref = this.dialogService.open(TeamModalComponent, {
      header: 'Select Team',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        teams: this.teams,
      },
    });

    this.ref.onClose.subscribe((team: Teams) => {
      if (team && isAdd && !isCoach) {
        this.selectedTeam = team;
        this.addNewPlayer();
      } else if (team && !isAdd && !isCoach) {
        this.selectedTeam = team;
        this.addExistingPlayer();
      } else if (team && isAdd && isCoach) {
        this.selectedTeam = team;
        this.addNewCoach();
      } else if (team && !isAdd && isCoach) {
        this.selectedTeam = team;
        this.addExistingCoach();
      }
    });
  }

  addNewTeam(): void {
    this.ref = this.dialogService.open(AddTeamModalComponent, {
      header: 'New Team',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
          registration: this.registration,
        },
      },
    });

    this.ref.onClose.subscribe((team: Teams) => {
      if (team) {
        console.log('team value: ', team);

        this.teams.push(team);
        this.teamPhoto = team[0]?.photo;

        let tpcData = {
          team: team,
          players: [],
          coaches: [],
          division: team.division,
          paidAmount: 0,
          paymentStatus: 'FREE',
        };
        this.teamPlayerCoaches.push(tpcData);
        this.sortTeams();

        let division = this.divisions.find(
          (div) => div.id === this.selectedDivision.id
        );
        division.teamCount = division.teamCount ? division.teamCount + 1 : +1;
        if (this.headCoach) this.coaches.push(this.headCoach);
        console.log('coachs: ', this.coaches);
      }
    });
  }

  editTeam(index: number): void {
    this.ref = this.dialogService.open(AddTeamModalComponent, {
      header: 'Edit Team',
      width: defConstant.modalWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
          registration: this.registration,
          team: this.teamPlayerCoaches[index].team,
        },
      },
    });

    this.ref.onClose.subscribe((team: Teams) => {
      if (team) {
        this.teamPlayerCoaches[index].team = {
          ...team,
        };
        this.sortTeams();
      }
    });
  }

  deleteTeam(i) {
    let division = this.divisions.find(
      (div) => div.id === this.teams[i].division.id
    );
    division.teamCount = division.teamCount - 1;

    this.teamPlayerCoaches.splice(i, 1);
    this.teams.splice(i, 1);
  }

  deletePlayer(i: number, j: number) {
    this.teamPlayerCoaches[i].players.splice(j, 1);
    this.players.splice(i, 1);
  }

  deleteCoach(i: number, j: number) {
    this.teamPlayerCoaches[i].coaches.splice(j, 1);
    this.coaches.splice(i, 1);
  }

  addExistingTeam(): void {
    this.ref = this.dialogService.open(AddExistingTeamComponent, {
      header: 'Existing Teams',
      width: defConstant.modalLargeWidth,
      closable: false,
      data: {
        result: {
          division: this.selectedDivision,
        },
      },
    });

    this.ref.onClose.subscribe(async (team: Teams) => {
      if (team) {
        this.teams.push(team);
        this.teamPhoto = `${environment.api.teamPhoto}/${team[0]?.photo}`;

        await this.playerService.getPlayersByTeam(team).subscribe((players) => {
          let tpcData = {
            team: team,
            players: players ?? [],
            coaches: [],
            division: team.division,
            paidAmount: 0,
            paymentStatus: 'FREE',
          };
          this.teamPlayerCoaches.push(tpcData);
          if (players?.length > 0) this.players.push(players);

          this.sortTeams();
          let division = this.divisions.find(
            (div) => div.id === this.selectedDivision.id
          );
          division.teamCount = division.teamCount ? division.teamCount + 1 : +1;
          if (this.headCoach) this.coaches.push(this.headCoach);
          console.log('coachs: ', this.coaches);
        });
      }
    });
  }

  addExistingPlayer(): void {
    this.ref = this.dialogService.open(AddExistingPlayerComponent, {
      header: 'Existing Players',
      width: defConstant.modalLargeWidth,
      closable: false,
      data: {
        result: {
          team: this.selectedTeam,
          existingPlayers: this.players,
        },
      },
    });

    this.ref.onClose.subscribe((player: Player) => {
      if (player) {
        this.players.push(player);
        this.teamPlayerCoaches.map((tpc) => {
          if (tpc.team.name === player.team.name) {
            tpc.players.push(player);
          }
        });
        this.sortPlayers();
      }
    });
  }

  validateCoaches(): boolean {
    if (this.teamPlayerCoaches.length > 0) {
      this.teamPlayerCoaches.map((tpc) => {
        if (
          (tpc.coaches.length >= this.tourney?.minimumCoaches &&
            tpc.coaches.length <= this.tourney?.maximumCoaches) ||
          (this.headCoach &&
            tpc.coaches.length + 1 >= this.tourney?.minimumCoaches &&
            tpc.coaches.length + 1 <= this.tourney?.maximumCoaches)
        ) {
          this.areCoachesValid = true;
          return true;
        } else {
          this.areCoachesValid = false;
          return false;
        }
      });
    } else {
      this.areCoachesValid = false;
      return false;
    }
  }

  validatePlayers(): boolean {
    if (this.teamPlayerCoaches.length > 0) {
      this.teamPlayerCoaches.map((tpc) => {
        if (
          tpc.players.length >= this.tourney?.minPlayersPerRoster &&
          tpc.players.length <= this.tourney?.totalPlayersPerRoster
        ) {
          this.arePlayersValid = true;
          return true;
        } else {
          this.arePlayersValid = false;
          return false;
        }
      });
    } else {
      this.arePlayersValid = false;
      return false;
    }
  }

  async registerTeam(token: string) {
    console.log('pasooooook');

    let regForm = this.form.value;

    let parseExp = regForm.ccexpiration.split('/') ?? '';

    this.validateCoaches();
    this.validatePlayers();

    console.log('this.form.valid', this.form.valid);
    console.log('this.areCoachesValid', this.areCoachesValid);
    console.log('this.arePlayersValid', this.arePlayersValid);

    if (!this.form.valid) {
      this.isValidated = false;
      return;
    }

    if (!this.areCoachesValid) return;
    if (!this.arePlayersValid) return;

    let newCoach;

    if (!this.headCoach && this.teamPlayerCoaches?.length > 0) {
      newCoach = this.teamPlayerCoaches[0].coaches[0];
    }
    let regData: any;

    const name = this.stripeTest.get('name').value;
    let token2: string;
    this.stripeService
      .createToken(this.card.element, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token
          console.log('this is the token2', result.token.id);
          token2 = result.token.id;
          regData = {
            coach: {
              id: this.headCoach?.id,
              userType: this.headCoach ? regForm?.role : 'coach',
              firstName: this.headCoach
                ? this.headCoach?.firstName
                : newCoach?.firstName,
              lastName: this.headCoach
                ? this.headCoach?.lastName
                : newCoach?.lastName,
              email: this.headCoach ? this.headCoach?.email : newCoach?.email,
              address: this.headCoach
                ? this.headCoach?.address
                : newCoach?.address,
              coachCity: this.headCoach
                ? this.headCoach?.coachCity
                : newCoach?.city,
              coachState: this.headCoach
                ? this.headCoach?.coachState
                : newCoach?.state,
              phoneNumber: this.headCoach
                ? this.headCoach?.phone
                : newCoach?.phoneNumber,
              facebookPage: this.headCoach
                ? this.headCoach?.facebookPage
                : newCoach?.facebookPage,
              twitterHandle: this.headCoach
                ? this.headCoach?.twitterHandle
                : newCoach?.twitterHandle,
              instagramHandle: this.headCoach
                ? this.headCoach?.instagramHandle
                : newCoach?.instagramHandle,
            },
            payment: {
              name: regForm?.ccname,
              cardNumber: regForm?.ccnumber,
              expiryDate: regForm?.ccexpiration,
              cvc: regForm.cccode,
              paymentType: 'CARD',
            },
            teamPlayerCoaches: this.teamPlayerCoaches,
            totalPayment: this.totalPayment,
            tournamentId: this.tourney.id,
            token: token2,
          };
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });

    console.log('regData: ', regData);

    try {
      /**
       * Credit card validation
       */
      if (this.registration?.requirePayment == 'REQUIRED') {
        let parseExp = regForm.ccexpiration.split('/');

        const validate = await this.httpService
          .post({
            url: environment.api.validateCard,
            // payload: {
            //   cardNumber: regForm.ccnumber,
            //   nameOnCard: regForm.ccname,
            //   expMonth: parseExp[0],
            //   expYear: parseExp[1],
            //   securityCode: regForm.cccode,
            //   email: regForm.email,
            // },
            payload: { email: regForm?.ccname, token: token },
          })
          .toPromise();
        this.stripeError = '';
      }
    } catch (e) {
      this.stripeError = e.error.errorMessage;

      this.toastService.toast('Failed', e.error.errorMessage, 'error');
      return;
    }

    try {
      const publicReg = await this.httpService
        .post({
          url: environment.api.publicTeamRegister,
          payload: regData,
        })
        .toPromise()
        .then((res) => {
          this.toastService.successMsg({
            message: 'Team Successfully Registered!',
          });

          this.router.navigate(
            [`/public-tournament/${this.tourney.id}/divisions`],
            {
              state: this.tourney,
            }
          );
        });
    } catch (e) {
      this.toastService.toast('Failed', e.error.message, 'error');
      console.log('error in public reg: ', e.error.message);
    }
  }

  toggleLogIn(event, element) {
    element.toggle(event);
  }

  // openSelectDivision() {
  //   this.ref = this.dialogService.open(DivisionModalComponent, {
  //     header: 'Select Division',
  //     width: defConstant.modalConfirmWidth,
  //     closable: false,
  //     data: {
  //       result: {},
  //     },
  //   });
  // }

  goBack(): void {
    this._location.back();
  }

  scroll(el: HTMLElement) {
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  getFontColor(primary: string) {
    let pRed = primary.substring(1, 3);
    let pGreen = primary.substring(3, 5);
    let pBlue = primary.substring(5, 7);
    let primaryInt =
      parseInt(pRed, 16) * 0.299 +
      parseInt(pGreen, 16) * 0.587 +
      parseInt(pBlue, 16) * 0.114;

    if (primaryInt > 150) {
      console.log('MAIN IS BLACK');
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      console.log('MAIN IS WHITE');
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }
  }
}
