<app-public-header></app-public-header>

<div class="container-features">
  <div
    class="container-desc p-grid j-center mx-2"
    style="width: 100vw; margin-right: 10px"
  >
    <div
      class="p-col-12 text-center"
      style="height: 50px; margin-bottom: 50px; margin-top: 20px"
    >
      <h1>FEATURES</h1>
    </div>

    <div class="p-lg-6 p-col-12 container-list">
      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faLaptop"></fa-icon>
        <label> Tournament Posting Site </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1">Tournament Posting Site</span> - Need a place
        to promote your tournament or league? TourneyKey posting is free and
        gets results fast!
      </p>
      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faHammer"></fa-icon>
        <label> Bracket Builder </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1">Bracket Builder</span>
        - A user friendly bracket builder that gives the director all the
        control. Our easy to use tools help you quickly generate pool play,
        create custom brackets, select brackets from our template or copy one
        from a previous tournament.
      </p>

      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faMobile"></fa-icon>
        <label> Tournament App </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1"
          >Tournament App
          <i
            style="
              padding-left: 5px;
              padding-right: 5px;
              font-family: 'sans-serif';
            "
            >Coming Soon!</i
          ></span
        >
        - Have easy access to tournament control in the palm of your hand with
        our mobile website and apps on iPhone, iPad and Android! You can upload
        scores, communicate with customers, and promote events. Contact us about
        our branded app today!
      </p>

      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faCalendar"></fa-icon>
        <label> Referee Scheduler </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1"
          >Referee Scheduler
          <i style="padding-left: 5px; padding-right: 5px; font-family: 'Arial'"
            >Coming Soon!</i
          ></span
        >
        - Increase revenue for your event, and cut out the middleman!
      </p>
    </div>

    <div class="p-lg-6 p-col-12 container-list">
      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faRegistered"></fa-icon>
        <label> Team Registration </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1"> Team Registration </span> - Team registration
        and payment processing is free to use and can be applied on any website.
        Accept any kind of payment, from registration fees, and track payments
        using our platform.
      </p>
      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faRobot"></fa-icon>
        <label> Automated Scoring </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1">Automated Scoring</span> - Done in real time,
        upload once the game finishes, and parents & fans can follow along
        without being there.
      </p>

      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faAddressCard"></fa-icon>
        <label> Player Profile Pages </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1"
          >Player Profile Pages
          <i style="padding-left: 5px; padding-right: 5px; font-family: 'Arial'"
            >Coming Soon!</i
          ></span
        >
        - Make recruiting easier! Every tournament using TK or the scoring app
        tracks your players’ stats.  You can also find measurables from all
        TK-certified combine events.  These come together to create a profile
        searchable by college coaches and scouts to help your player get
        noticed.
      </p>

      <button class="btn-features">
        <fa-icon class="faicon" [icon]="faPen"></fa-icon>

        <label> Simple Credentialing </label>
      </button>
      <p class="p-container" style="padding-left: 50px">
        <span class="text-bold1"
          >Simple Credentialing
          <i style="padding-left: 5px; padding-right: 5px; font-family: 'Arial'"
            >Coming Soon!</i
          ></span
        >
        - Get verified!  Our experts will review the documents you submit to
        pre-verify your player is the correct age and grade for their division
        for any given tournament.
      </p>
    </div>
  </div>

  <!-- <div class="container-features">
        <div class="container-feature-desc container-responsive p-grid ">
          <div class="container-desc p-col j-center mx-5 padding-adjustment" [class.show]="show">
            <h1>FEATURES</h1>
            <p>
              <span class="text-bold1">Bracket Builder</span> - A user friendly
              bracket builder that gives the director all the control. Our easy to use
              tools help you quickly generate pool play, create custom brackets,
              select brackets from our template or copy one from a previous
              tournament.
            </p>
            <p>
              <span class="text-bold1">Tournament App</span> - Have easy access to
              tournament control in the palm of your hand with our mobile website and
              apps on iPhone, iPad and Android! You can upload scores, communicate
              with customers, and promote events. Contact us about our branded app
              today!
            </p>
            <p>
              <span class="text-bold1">FREE Registration creator</span> - Team
              registration and payment processing is free to use and can be appliend
              on any website. Accept any kind of payment, from registration fees, and
              track payments using our platform.
            </p>
            <p>
              <span class="text-bold1">Tournament Posting Site</span> - Need a place
              to promote your tournament or league, TourneyKey posting is free and
              gets results fast!
            </p>
            <p>
              <span class="text-bold1">Referee Scheduler</span> - Increase Revenue for
              your event, and cut out the middleman!
            </p>
            <p>
              <span class="text-bold1">Automated Scoring</span> - Done in real time,
              upload once the game finishes, and parents & fans can follow along
              without being there.
              <a class="text-bold1 text-underline">CLICK HERE For DEMO</a>
            </p>
          </div>
          <div class="container-list p-col p-lg-6 p-xs-12 j-center padding-adjustment">
            <i class="scoring"></i>
            <button class="btn-features">
              <fa-icon class="faicon" [icon]="faRobot"></fa-icon>
              <label> Automated Scoring </label>
            </button>
            <button class="btn-features">
              <fa-icon class="faicon" [icon]="faAddressCard"></fa-icon>
              <label> Player Profile Pages </label></button
            ><button class="btn-features">
              <fa-icon class="faicon" [icon]="faPen"></fa-icon>
              <label> Simple Credentialing </label></button
            ><button class="btn-features">
              <fa-icon class="faicon" [icon]="faChartLine"></fa-icon>
              <label> Verified Player Statistics </label></button
            ><button class="btn-features">
              <fa-icon class="faicon" [icon]="faHammer"></fa-icon>
              <label> Snap Enabled Bracket Builder </label>
            </button>
          </div>
           <div class="container-desc p-col-5">
            <div class="see-more">
              <a>SEE MORE</a>
            </div>
          </div> -->
  <!-- <div class="container-list p-col-5"></div> -->
  <!-- <div class="p-col-10">
            <div class="see-more">
              <a (click)="show = !show">{{ show ? "SHOW LESS" : "SEE MORE" }}</a>
            </div>
          </div>
        </div>
      </div> -->
</div>
