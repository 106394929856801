<div class="public-team">
  <div class="header-container">
    <div class="nav">
      <app-public-header *ngIf="!isReview"></app-public-header>
    </div>

    <!-- <div class="advisory" *ngIf="potentialVenues?.length > 0 && !isLoading">
        <h1>Please note that {{ team.name }} has potential future games at:</h1>
        <div class="venue-list">
          <ng-container *ngFor="let venue of potentialVenues; let i = index">
            <ng-container
              *ngIf="i < potentialVenues.length - 2; else lastVenue"
            >
              <div class="venue-link" (click)="openVenue(venue)">
                {{ venue }},
              </div>
            </ng-container>
            <ng-template #lastVenue>
              <ng-container *ngIf="i === potentialVenues.length - 2">
                <div class="venue-link" (click)="openVenue(venue)">
                  {{ venue }} & &nbsp;
                </div>
              </ng-container>
              <ng-container *ngIf="i === potentialVenues.length - 1">
                <div class="venue-link" (click)="openVenue(venue)">
                  {{ venue }}
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
        </div>
      </div> -->
  </div>
  <div
    class="detail-header"
    [style.backgroundColor]="primaryColor"
    [style.color]="fontColor"
  >
    <div class="back" (click)="goToTournamentPublicPage()">
      <i class="pi pi-arrow-left"></i>
    </div>
    <div class="image-container" *ngIf="innerWidth >= 769">
      <div class="tourney-image" *ngIf="team?.photo; else noPhoto">
        <img class="image" [src]="logoUrl" />
      </div>
      <ng-template #noPhoto>
        <div class="default-image">
          <img class="image" src="assets/img/ball-avatar.png" />
        </div>
      </ng-template>
    </div>
    <div class="team-info">
      <div class="line1">{{ team?.name }}</div>
      <div class="line2">{{ this.division?.name }}</div>
    </div>
    <div class="court-info">
      <div class="grid flex-column">
        <div class="line4">Scheduled games played at:</div>
        <div *ngIf="isLoading; else dataLoaded">Loading...</div>
        <ng-template #dataLoaded>
          <div
            *ngIf="
              sureVenues && sureVenues.length > 0 && postSchedule;
              else noVenue
            "
            class="venues"
          >
            <ng-container *ngFor="let venue of sureVenues; let i = index">
              <ng-container *ngIf="i < sureVenues.length - 2; else lastVenue">
                <div class="venue-link" (click)="openVenue(venue)">
                  {{ venue }},
                </div>
              </ng-container>
              <ng-template #lastVenue>
                <ng-container *ngIf="i === sureVenues.length - 2">
                  <div class="venue-link" (click)="openVenue(venue)">
                    {{ venue }} &
                  </div>
                </ng-container>
                <ng-container *ngIf="i === sureVenues.length - 1">
                  <div class="venue-link" (click)="openVenue(venue)">
                    {{ venue }}
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
        </ng-template>
        <ng-template #noVenue>
          <div class="no-venue">TBD</div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="main-container">
    <app-team-roster
      [teamId]="team?.id"
      *ngIf="tournament?.showRosters && postSchedule"
      [tournament]="tournament"
      [hasPotentialVenues]="hasPotentialVenues"
    ></app-team-roster>
    <app-team-schedule
      [teamId]="team?.id"
      [tournamentId]="tournamentId"
      [postSchedule]="postSchedule"
      [hasPotentialVenues]="hasPotentialVenues"
    ></app-team-schedule>
    <app-team-potential-games
      [hasPotentialVenues]="hasPotentialVenues"
      [teamName]="team?.name"
    ></app-team-potential-games>
  </div>
</div>
