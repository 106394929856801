import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { environment } from '@env/environment';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import {
  SessionStorageService,
  AuthService,
  HttpService,
  EmitterService,
  ToastService,
} from '@services/index';

@Component({
  selector: 'app-select-team',
  templateUrl: './select-team.component.html',
  styleUrls: ['./select-team.component.scss']
})
export class SelectTeamComponent implements OnInit {

  isLogged: boolean = false;
  isLoggedCoach: boolean = false;
  me;
  teamId;
  team;
  isLoading: boolean = true;

  @ViewChild('opLogin') opLogin;
  @ViewChild('btnLogin') btnLogin: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private coachTeamsService: CoachTeamsService,
    private authService: AuthService,
    private httpService: HttpService,
    private emitterService: EmitterService,
    private toastService: ToastService,
    ) {
      this.authService.isLoggedInEmitter.subscribe((isLoggedIn) => {
        this.isLogged = isLoggedIn;
        this.isCoach();
      });

     }

  ngOnInit(): void {
    // this.isLoading =true;
    this.teamId = this.route.snapshot.paramMap.get('teamId');
    console.log(this.teamId);
    this.getTeam(this.teamId);

    setTimeout( _ => {
      this.isLoading = false;
    }, 1000);

    this.emitterService.loginEmitter.subscribe((isDefault) => {
      if(!isDefault) this.opLogin.toggle(this.btnLogin.nativeElement);
    });
    
  }

  getTeam(id) {
    this.coachTeamsService.getTeam(id).subscribe((team) => {
      this.team = team;
      this.isLoading = false;
      console.log(team);
    });
  }

  acceptTeam() {
    this.httpService
        .post({
            url: environment.api.acceptTeam,
            payload: {
              coachId: this.me.id,
              teamId: parseInt(this.teamId)
            }
          })
          .toPromise()
          .then((res:any)=> {
            console.log(res);
            this.toastService.successMsg({
              message: 'Team has been successfully claimed',
            });
            this.router.navigate(['']);
          },
          (err) => {
            console.log("err: ", err)
            this.toastService.errorMsg({ message: err.error.message });
          }
          )
          
  }

  acceptNewTeam(){
    setTimeout(() => {
      console.log('ano laman', this.me);
      this.httpService
          .post({
              url: environment.api.acceptTeam,
              payload: {
                coachId: this.me.id,
                teamId: parseInt(this.teamId)
              }
            })
            .toPromise()
            .then((res:any)=> {
              console.log(res);
              this.toastService.successMsg({
                message: 'Team has been successfully claimed',
              });
            },
            (err) => {
              console.log("err: ", err)
              this.toastService.errorMsg({ message: err.error.message });
            }
            )
    }, 1000);
  }

  rejectTeam() {
    this.router.navigate(['']);
  }

 async isCoach () {
     this.me = await this.httpService.me().toPromise();
    if (this.me.userType === 'coach') {
      this.isLoggedCoach = true;
    } else {
      this.isLoggedCoach = false;
    }
  }

}
