 <ng-container *ngIf="preview; else elseBlock">
  <div class="w-100 p-3 flex" style="justify-content: start">
    <i class="pi pi-minus ml-2 !text-xl" (click)="zoomOut()"></i>
    <i class="pi pi-plus ml-2 !text-xl" (click)="zoomIn()"></i>
  </div>
  <div
    class="container-prev-main"
    #scrollContainer
    (mousedown)="onMouseDown($event)"
    (wheel)="onWheel($event)"
    (mouseup)="onMouseUp()"
    (mouseleave)="onMouseUp()"
    (mousemove)="onMouseMove($event)"
  >
      <div
        class="container-bracket"
        #stage
        [ngStyle]="{
          width: stageWidth + '%',
          height: stageHeight + '%',
          'transform-origin': ' 50% 50%',
          transform: 'scale(' + zoomScale + ')'
        }"
      >
        <div class="bracket-cont" #bracket></div>
    </div>
  </div>
</ng-container>
<ng-template #elseBlock>
  <p-accordion>
    <p-accordionTab>
      <ng-template pTemplate="header">
        <div class="flex flex-row w-100" style="justify-content: space-between">
          <div>
            {{ bracketSet.name }}
          </div>
        </div>
      </ng-template>
      <div class="w-100 flex" style="justify-content: start">
        <i class="pi pi-minus ml-2 !text-xl" (click)="zoomOut()"></i>
        <i class="pi pi-plus ml-2 !text-xl" (click)="zoomIn()"></i>
        <i
          class="pi pi-window-maximize ml-2 !text-xl"
          (click)="openBracket()"
        ></i>
      </div>
      <div
        class="container-main"
        #scrollContainer
        (mousedown)="onMouseDown($event)"
        (wheel)="onWheel($event)"
        (mouseup)="onMouseUp()"
        (mouseleave)="onMouseUp()"
        (mousemove)="onMouseMove($event)"
      >
        <div class="container-stage">
          <div
            class="container-bracket"
            [style.height.px]="stageHeight"
            #stage
            [ngStyle]="{
              width: stageWidth + '%',
              'transform-origin': ' 50% 50%',
              transform: 'scale(' + zoomScale + ')'
            }"
          >
            <div class="bracket-cont" #bracket></div>
          </div>
        </div>
        <!-- <div class="venue-container flex flex-wrap p-3">
          <div *ngFor="let venue of venues" class="p-col-6">
            <h5 class="m-0">{{ venue }}</h5>
          </div>
        </div> -->
      </div>
    </p-accordionTab>
  </p-accordion>
</ng-template>
