import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TeamsService } from '@core/services/teams.service';
import { TeamDivisionsService } from '@core/services/team-divisions.service';

import { environment } from '@env/environment';

import { Teams } from '@core/models/teams';
import { TeamDivision } from '@core/models/team-division';
import { log } from 'console';
import { ToastService } from '@core/services';
import { VenueService } from '@core/services/venue.service';
import { Venue } from '@core/models/venue';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CheckInSummaryModalComponent } from './check-in-summary-modal/check-in-summary-modal.component';
import { TournamentsService } from '@core/services/tournaments.service';
import { Tournament } from '@core/models/tournament';
import { defConstant } from '@core/data/constant-data';

@Component({
  selector: 'app-checkin-team-details',
  templateUrl: './checkin-team-details.component.html',
  styleUrls: ['./checkin-team-details.component.scss'],
})
export class CheckinTeamDetailsComponent implements OnInit, OnDestroy {
  collapseCoaches: boolean = false;
  collapsePlayers: boolean = false;
  collapsePayment: boolean = false;
  collapseTerms: boolean = false;

  divisionName: string;
  teamId: number;
  teamDivisionId: number;
  team: Teams;
  teamDivision: TeamDivision;
  venue: Venue;
  venueId: number;
  tournament: Tournament;
  tournamentId: number;
  logoUrl: string;
  isShowCollapsibleTerms: boolean = false;
  isShowTerms: boolean = false;
  isShowCodeOfConduct: boolean = false;
  ref: DynamicDialogRef;
  coaches: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamsService: TeamsService,
    private teamDivisionService: TeamDivisionsService,
    private toastService: ToastService,
    private venueService: VenueService,
    private dialogService: DialogService,
    private tournamentService: TournamentsService,
  ) {
    this.route.paramMap.subscribe(async (params) => {
      this.teamId = parseInt(params.get('teamId'));
      this.teamDivisionId = parseInt(params.get('teamDivisionId'));
      this.divisionName = params.get('divisionName');
      this.venueId = parseInt(params.get('venueId'));
      this.tournamentId = parseInt(params.get('tournamentId'));

      this.tournament = await this.tournamentService.getTournament(this.tournamentId);
      this.venue = await this.venueService.getVenue(this.venueId);
      this.team = await this.teamsService.getTeam(this.teamId);
      this.teamDivision = await this.teamDivisionService.getTeamDivision(
        this.teamDivisionId
      );

      console.log('tournament', this.tournament);
      console.log('teamId', this.teamId);
      console.log('teamDivisionId', this.teamDivisionId);
      console.log('teamDivision', this.teamDivision);
      console.log("venue", this.venue);

      this.processCheckInShowTerms(
        this.teamDivision.division.tournament.checkInShowTerms
      );

      this.logoUrl = this.team.photo
        ? `${environment.api.baseUrl}/api/tourneykey/v1/teams/logo/${this.team.photo}`
        : '/assets/img/ball-avatar.png';
    });
  }
  ngOnDestroy(): void {
    this.teamDivision = null;
  }

  ngOnInit() {}

  onComplete() {

    const tournamentId = parseInt(sessionStorage.getItem('tournamentId'));
    console.log(
      '*****************terms',
      this.teamDivision.termsAccepted,
      this.isShowTerms
    );
    console.log(
      '*****************code',
      this.teamDivision.codeOfConductAccepted,
      this.isShowCodeOfConduct
    );
    if (!this.teamDivision.termsAccepted && this.isShowTerms) {
      console.log('Terms and Conditions');

      this.toastService.errorMsg({
        message: 'You must agree to the Terms and Conditions',
      });
      return;
    }

    if (!this.teamDivision.codeOfConductAccepted && this.isShowCodeOfConduct) {
      console.log('Code of Conduct');

      this.toastService.errorMsg({
        message: 'You must agree to abide by the Code of Conduct',
      });
      return;
    }

    // this.router.navigate([
    //   '/dashboard',
    //   'check-in',
    //   'tournament-team',
    //   tournamentId,
    // ]);

    this.showSummary();
  }

  showSummary() {
    this.ref = this.dialogService.open(CheckInSummaryModalComponent, {
      header: 'SUMMARY',
      width: defConstant.modalLargeWidth,
      contentStyle: { 'overflow-y': 'scroll' },
      closable: false,
      data: {
        tournamentName: this.tournament.name,
        divisionName: this.divisionName,
        teamName: this.team.name,
        coaches: this.coaches,
      },
    });
    this.ref.onClose.subscribe(res => {
      this.router.navigate(['/dashboard', 'check-in', 'tournament-team', this.tournamentId, this.venueId]);
    });
  }

  processCheckInShowTerms(checkInShowTerms: string) {
    if (checkInShowTerms !== 'NEITHER' && checkInShowTerms != null) {
      this.isShowCollapsibleTerms = true;
    }

    switch (checkInShowTerms) {
      case 'TERMS_ONLY':
        this.isShowTerms = true;
        break;
      case 'CODE_OF_CONDUCT_ONLY':
        this.isShowCodeOfConduct = true;
        console.log('PUMASOK HERE');

        break;
      case 'BOTH':
        this.isShowTerms = true;
        this.isShowCodeOfConduct = true;
        break;
      default:
        break;
    }
  }

  checkIfTermsOrConductIsShown(data) {
    switch (data.prop) {
      case 'terms':
        console.log('PUMASOK TERMS');

        this.isShowTerms = data.isShown;
        this.teamDivision.termsAccepted = data.isAccepted;
        break;
      case 'codeOfConduct':
        console.log('PUMASOK codeOfConduct');

        this.isShowCodeOfConduct = data.isShown;
        this.teamDivision.codeOfConductAccepted = data.isAccepted;
        break;
      default:
        break;
    }
    console.log('this is checkIfTermsOrConductIsShown', data);
  }

  receiveCheckInCoaches(coaches: any[]) {
    this.coaches = coaches;
  }

  onBack() {
    this.router.navigate(['/dashboard', 'check-in', 'tournament-team', this.tournamentId, this.venueId]);

  }
}
