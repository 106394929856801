<form [formGroup]="coachForm" class="py-2 ml-1 container-form p-grid">
  <!-- <div class="p-col-12" *ngIf="hasSelectTeam">
    <label class="text-bold3 TheOpenSans-Regular disp-block"
      >Team <span class="required-indicator">*</span></label
    >

    <div class="gen-info-dropdown" style="align-items: flex-start;">
      <p-dropdown
        [options]="teams"
        placeholder="Select Team"
        optionLabel="name"
        optionValue="id"
        formControlName="team"
        autoWidth="false"
        style="padding-left: 15px; width: 100%; height: 3rem; margin-bottom: 10px;"
      ></p-dropdown>
    </div>
  </div> -->

  <div class="p-col-12">
    <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">First Name <span
        class="required-indicator">*</span></label>

    <input id="firstName" class="addTeamInput" type="text" pInputText formControlName="firstName"
      style="padding-left: 15px" class="tournament-input-text" />
    <small *ngIf="
        coachForm.controls['firstName'].invalid &&
        (coachForm.controls['firstName'].dirty ||
          coachForm.controls['firstName'].touched)
      " class="text-error text-left text-primary2">
      <div *ngIf="coachForm.controls['firstName'].errors.required" class="mb-3 ml-2">
        First name is required.
      </div>
    </small>
  </div>


  <div class="p-col-12">
    <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">Last Name <span
        class="required-indicator">*</span></label>

    <input id="lastName" type="text" pInputText formControlName="lastName" style="padding-left: 15px"
      class="tournament-input-text" />
    <small *ngIf="
        coachForm.controls['lastName'].invalid &&
        (coachForm.controls['lastName'].dirty ||
          coachForm.controls['lastName'].touched)
      " class="text-error text-left text-primary2">
      <div *ngIf="coachForm.controls['lastName'].errors.required" class="mb-3 ml-2">
        Last name is required.
      </div>
    </small>
  </div>

  <div class="p-col-12">
    <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">Email <span
        class="required-indicator">*</span></label>

    <input id="email" class="addTeamInput" type="text" pInputText formControlName="email" style="padding-left: 15px"
      class="tournament-input-text" />
    <small *ngIf="
        coachForm.controls['email'].invalid &&
        (coachForm.controls['email'].dirty ||
          coachForm.controls['email'].touched)
      " class="text-error text-left text-primary2">
      <div *ngIf="coachForm.controls['email'].errors.required" class="mb-3 ml-2">
        Email is required.
      </div>
    </small>
  </div>

  <div class="p-col-12">
    <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">Phone Number</label>

    <input id="phoneNumber" class="addTeamInput" type="text" pInputText formControlName="phoneNumber"
      style="padding-left: 15px" class="tournament-input-text" />
    <!--<small *ngIf="
        coachForm.controls['phoneNumber'].invalid &&
        (coachForm.controls['phoneNumber'].dirty ||
          coachForm.controls['phoneNumber'].touched)
      " class="text-error text-left text-primary2">
      <div *ngIf="coachForm.controls['phoneNumber'].errors.required" class="mb-3 ml-2">
        Phone Number is required.
      </div>
    </small>-->
  </div>

  <!-- <div class="p-col-12">
    <label class="text-bold3 TheOpenSans-Regular disp-block"
      >Title <span class="required-indicator">*</span></label
    >

    <div class="gen-info-dropdown">
        <p-dropdown
          [options]="roles"
          placeholder="Select a title"
          optionLabel="name"
          optionValue="value"
          formControlName="role"
          autoWidth="false"
          style="padding-left: 15px; width: 100%; height: 3rem"
        ></p-dropdown>
      </div>
    <small
      *ngIf="
        coachForm.controls['role'].invalid &&
        (coachForm.controls['role'].dirty ||
          coachForm.controls['role'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="coachForm.controls['role'].errors.required"
        class="mb-3 ml-2"
      >
        Coach Title is required.
      </div>
    </small>
  </div> -->

  <ng-container *ngIf="!isCoach">
    <div class="p-col-12">
      <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">Street Address</label>

      <input id="address" class="addTeamInput" type="text" pInputText formControlName="address"
        style="padding-left: 15px" class="tournament-input-text" />
    </div>

    <div class="p-col-12">
      <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">City</label>

      <input id="city" class="addTeamInput" type="text" pInputText formControlName="city" style="padding-left: 15px"
        class="tournament-input-text" />
    </div>

    <div class="p-col-12">
      <label class="text-bold3 TheOpenSans-Regular mb-2 disp-block">State</label>

      <input id="state" class="addTeamInput" type="text" pInputText formControlName="state" style="padding-left: 15px"
        class="tournament-input-text" />
    </div>
  </ng-container>


  <div class="flex flex-center my-4">
    <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
      CANCEL
    </button>

    <button type="submit" (click)="submit()" class="btn-rounded-red mx-2" [disabled]="!coachForm.valid">
      SAVE
    </button>
  </div>
</form>

<!-- <div>
      <p-autoComplete
        field="label"
        appendTo="body"
        inputStyleClass="input-text disp-block autocomplete my-2"
        [required]="true"
        autocomplete="noauto"
        emptyMessage="No results found"
        placeholder="City & State"
        [autoHighlight]="true"
        [forceSelection]="true"
        [suggestions]="cityStates"
        formControlName="cityState"
        (completeMethod)="searchCityState($event)"
        dropdownIcon="pi pi-angle-down"
      >
      </p-autoComplete>
      <small
        *ngIf="
        playerForm.controls['cityStateZip'].invalid &&
          (playerForm.controls['cityStateZip'].dirty ||
          playerForm.controls['cityStateZip'].touched)
        "
        class="text-error text-left text-primary2"
      >
        <div
          *ngIf="playerForm.controls['cityStateZip'].errors.required"
          class="mb-3 ml-2"
        >
          City & State is required.
        </div>
      </small>
    </div> -->