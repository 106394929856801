import { GeneralInfoComponent } from '@admin/tournament/general-info/general-info.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GENDER_TYPES_FILTER } from '@core/models/gender-type';
import { Tournament } from '@core/models/tournament';
import { HttpService } from '@core/services';
import { CityStateLookupService } from '@core/services/city-state-lookup.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { getStates, State, USA_STATES } from '@core/utils/city-state';
import {
  faFilter,
  faTimes,
  faTable,
  faList,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { PublishModalComponent } from './publish-modal/publish-modal.component';
// import { PublishTournamentComponent } from './publish-tournament/publish-tournament.component';
import moment from 'moment';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-public-browse-director-owned',
  templateUrl: './public-browse-director-owned.component.html',
  styleUrls: ['./public-browse-director-owned.component.scss'],
})
export class PublicBrowseDirectorOwnedComponent implements OnInit {
  tournaments: Tournament[];
  faList = faList;
  faGrid = faTable;
  faFilter = faFilter;
  faSearch = faSearch;
  faTimes = faTimes;
  isFilter = true;
  isGrid = true;
  formFilter: FormGroup;
  state: State[];
  gender = GENDER_TYPES_FILTER;
  states = USA_STATES;
  cities: any[];
  ref: DynamicDialogRef;
  isLoading: boolean = false;
  isSearch: boolean = false;
  selectedStates: any;

  meta: any = {};

  orgId: number;
  logoUrl: string;
  hasLogo = false;

  orgName: string;
  orgInfo: any;
  primaryColor: string;
  secondaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  constructor(
    private http: HttpService,
    private publicTournament: PublicTournamentsService,
    private fb: FormBuilder,
    private cityStateLookupService: CityStateLookupService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe(async (params) => {
      this.orgId = parseInt(params.get('orgId'));
    });
    this.formFilter = this.fb.group({
      states: [''],
      eventDate: [''],
      gender: [''],
      city: [''],
      state: [''],
      events: ['', Validators.required],
      type: ['', Validators.required],
      name: [''],
    });
    this.state = getStates();
  }

  ngOnInit(): void {
    this.initBrowseTournaments();
  }

  initBrowseTournaments(): void {
    this.isLoading = true;
    this.publicTournament
      .initPublicBrowseTournaments()
      .then((data) => {
        //Filter Tournaments that are associated with the Tournament Director's Organization
        //Transfer this logic to the backend in the future
        let directorTournaments = data.items?.filter(
          (tournament) => tournament.organization.id == this.orgId
        );
        this.tournaments = directorTournaments;
        this.meta = data.meta;
        this.isLoading = false;
        this.getOrgInfo();
      })
      .catch((e) => console.log('browse', e));
  }

  getBrowseTournament(
    action: string,
    meta: any,
    search = '',
    city = '',
    state = '',
    startDate = '',
    gender = ''
  ): void {
    this.isLoading = true;
    this.publicTournament
      .getPublicBrowseTournaments(
        action,
        meta,
        search,
        city,
        state,
        startDate,
        gender
      )
      .then((data: any) => {
        this.tournaments = data.items;
        this.meta = data.meta;
        this.isLoading = false;
      })
      .catch((e) => console.log('browse', e));
  }

  searchState(evt) {
    this.cityStateLookupService.getStateResults(evt.query).then((data) => {
      this.state = data;
    });
  }

  searchCity($event) {
    this.http
      .get({
        url: `${environment.api.tournamentCities}?city=${$event.query}`,
      })
      .toPromise()
      .then(
        (res: any) => {
          this.cities = res.flat().map(({ name }) => name);
        },
        (err) => {
          console.log('err: ', err);
        }
      );
  }

  searchTournament() {
    const formValue = this.formFilter.value;

    console.log('SEARCH ITO: ', formValue);

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';

    this.isLoading = true;
    this.isSearch = true;
    this.publicTournament
      .initPublicBrowseTournaments(
        formValue.name ?? '',
        formValue.city ?? '',
        formValue.state ?? '',
        eventDate,
        formValue.gender ?? '',
        'search'
      )
      .then((res) => {
        this.tournaments = res.items;
        this.meta = res.meta;
        this.isLoading = false;
      })
      .catch((e) => console.log('browse', e));
  }

  viewList(): void {
    this.isGrid = false;
  }
  viewGrid(): void {
    this.isGrid = true;
  }

  openFilter(): void {
    this.isFilter = !this.isFilter;
  }

  resetFilter(): void {
    this.formFilter.reset();
    this.initBrowseTournaments();
  }

  // async publishTournament() {

  //   const me = await this.http.me().toPromise();
  //   if (me?.userType === 'director') {
  //     this.ref = this.dialogService.open(PublishTournamentComponent, {
  //       header: 'PUBLISH TOURNAMENT',
  //       width: '1100px',
  //       dismissableMask: true,
  //       closable: false,
  //     });

  //     this.ref.onClose.subscribe((res) => {
  //       this.initBrowseTournaments();
  //     });
  //   } else {
  //     this.ref = this.dialogService.open(PublishModalComponent, {
  //       width: '750px',
  //       dismissableMask: true,
  //       closable: false,
  //     });

  //     this.ref.onClose.subscribe((res) => {
  //       if (me?.userType === 'director') {
  //         this.ref = this.dialogService.open(PublishTournamentComponent, {
  //           header: 'PUBLISH TOURNAMENT',
  //           width: '1100px',
  //           dismissableMask: true,
  //           closable: false,
  //         });
  //       } else {
  //         this.ref.close(1);
  //       }
  //     });
  //   }
  // }

  previous() {
    const formValue = this.formFilter.value;

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';
    this.getBrowseTournament(
      this.isSearch ? 'search-previous' : 'previous',
      this.meta,
      formValue.name ?? '',
      formValue.city ?? '',
      formValue.state ?? '',
      eventDate,
      formValue.gender ?? ''
    );
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    const formValue = this.formFilter.value;

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';
    this.getBrowseTournament(
      this.isSearch ? 'search-index' : 'index',
      meta,
      formValue.name ?? '',
      formValue.city ?? '',
      formValue.state ?? '',
      eventDate,
      formValue.gender ?? ''
    );
  }

  next() {
    const formValue = this.formFilter.value;

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';
    this.getBrowseTournament(
      this.isSearch ? 'search-next' : 'next',
      this.meta,
      formValue.name ?? '',
      formValue.city ?? '',
      formValue.state ?? '',
      eventDate,
      formValue.gender ?? ''
    );
  }

  getFontColor(primary: string) {
    let pRed = primary.substring(1, 3);
    let pGreen = primary.substring(3, 5);
    let pBlue = primary.substring(5, 7);
    let primaryInt =
      parseInt(pRed, 16) * 0.299 +
      parseInt(pGreen, 16) * 0.587 +
      parseInt(pBlue, 16) * 0.114;

    if (primaryInt > 150) {
      console.log('MAIN IS BLACK');
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      console.log('MAIN IS WHITE');
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }

    this.orgInfo = {
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      fontColor: this.fontColor,
      fontColorOpposite: this.fontColorOpposite,
      logoUrl: this.logoUrl,
    };
  }

  getOrgInfo() {
    let org = this.tournaments[0].organization;

    this.orgName = org.name;
    this.primaryColor = org.primaryColor;
    this.secondaryColor = org.secondaryColor;
    if (org.fullLogo) {
      this.logoUrl = `${environment.api.orgLogo}/${org.fullLogo}`;
      this.hasLogo = true;
    } else {
      this.hasLogo = false;
    }

    this.getFontColor(org.primaryColor);
  }
}
