<div class="player-checkin">
  <div class="requirement" *ngIf="totalPlayersPerRoster">
    This event requires <strong>{{ minPlayersPerRoster }}</strong> to
    <strong>{{ totalPlayersPerRoster }}</strong> players per team
  </div>
  <div class="button-content">
    <div class="left-button">
      <button
        pButton
        label="Send Roster Link"
        class="p-button-raised p-button-danger mx-2 px-5 py-3"
        [disabled]="isSendRosterDisabled"
        (click)="sendRosterLink()"
      ></button>
    </div>
    <div class="right-buttons">
      <button
        pButton
        label="Bulk Edit Roster"
        class="p-button-raised p-button-danger mx-2 px-5 py-3"
        (click)="bulkEditRoster()"
      ></button>
      <button
        pButton
        label="Add New Player"
        class="p-button-raised p-button-danger mx-2 px-5 py-3"
        (click)="addPlayer()"
      ></button>
    </div>
  </div>
  <div class="coach-content">
    <div *ngFor="let player of players" class="player-item">
      <div class="controls">
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-pencil"
          class="p-button-secondary top-0 right-0"
          style="height: 24px; width: 24px"
          (click)="editPlayer($event, player)"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-times"
          class="p-button-danger top-0 right-0"
          style="height: 24px; width: 24px"
          (click)="deletePlayer($event, player)"
        ></button>
      </div>
      <div class="player-name">
        {{ player.firstName }} {{ player.lastName }}
      </div>
      <div class="player-number">{{ player.jerseyNumber }}</div>
      <div class="player-position">{{ player.position }}</div>
    </div>
  </div>

  <div style="margin-top: 3rem; text-align: center">
    <div>
      <button
        pButton
        label="Verify Roster"
        class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
        [disabled]="null != checkInTeam"
        (click)="openVerifyRoster()"
      ></button>
    </div>
    <div class="roster-verified">
      <div *ngIf="null != checkInTeam" class="container">
        <!-- <div class="main-coach">{{mainCoach.fullName}}</div> -->
        <!-- <div class="svg">
          <div
            *ngIf="signatureSVG"
            [innerHTML]="getSVGImage(signatureSVG)"
          ></div>
        </div>
         -->
        <img *ngIf="signatureSVG" [src]="getSVGImageUrl(signatureSVG)" />
        <img
          *ngIf="!signatureSVG"
          src="{{ host }}/check-in/signature/{{ checkInTeam.signature }}"
        />
        <div class="date-verified">
          Roster verified at
          {{ checkInTeam.dateCheckedIn | date : "MM/dd/yyyy hh:mm a" }}
        </div>
      </div>
    </div>
  </div>

  <p-dialog
    header="Please Sign Here"
    [(visible)]="isSignatureOpen"
    [modal]="true"
    [dismissableMask]="false"
    appendTo="body"
  >
    <div class="signature-pad">
      <canvas
        #signaturePad
        width="400"
        height="200"
        (done)="showImage($event)"
      ></canvas>
    </div>

    <div class="mt-5 mb-2 mx-3 text-center">
      <button
        pButton
        type="button"
        (click)="clearSignature()"
        label="Clear"
        class="p-button-raised p-button-rounded p-button-secondary mx-2 px-5 py-3"
      ></button>
      <button
        pButton
        type="button"
        (click)="verifyRoster()"
        label="Verify"
        class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
      ></button>
    </div>
  </p-dialog>
</div>
