<div class="division-modal">
    <div class="gen-info-dropdown">
        <label for="state" class="text-bold1 disp-block p-col-12 p-0 mb-2"
        >What division are you registering your team for?</label
      >
        <p-dropdown
          [options]="divisions"
          placeholder="Select Division"
          optionLabel="name"
          [(ngModel)]="division"
          placeholder="Select a division for your team"
          autoWidth="false"
          [style]="{ width: '100%' }"
        ></p-dropdown>
      </div>
      <div class="p-col-12 flex flex-center mt-4">
        <button
          pButton
          type="button"
          label="Cancel"
          class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
          (click)="close()"
        ></button>
        <button
          pButton
          type="submit"
          label="Select"
          class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
          (click)="select()"
        ></button>
      </div>
</div>