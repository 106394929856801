import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services';

@Component({
  selector: 'app-public-home',
  templateUrl: './public-home.component.html',
  styleUrls: ['./public-home.component.scss']
})
export class PublicHomeComponent implements OnInit {
  isResetDialog: boolean;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.isShowReset$.subscribe((res) => {
      this.isResetDialog = res;
    })
  }

}
