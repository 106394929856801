import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

// COMPONENTS
// import { PublicComponent } from '@public/main/public.component';
// import { AdminComponent } from '@admin/main/admin.component';
// import { CoachComponent } from '@coach/main/coach.component';

// SERVICES
// import { AuthGuardAdminService } from '@core/services';
// import { AuthGuardCoachService } from '@services/auth-guard-coach.service';
// import { PublicBrowseComponent } from '@public/public-browse/public-browse.component';
// import { PublicTournamentsComponent } from '@public/public-tournaments/public-tournaments.component';
// import { DivisionsComponent } from '@public/public-tournaments/divisions/divisions.component';
// import { BracketBuilderComponent } from '@components/admin/bracket-builder/bracket-builder.component';
// import { SelectTeamComponent } from '@coach/select-team/select-team.component';

const routes: Routes = [
  // {
  //     path: ':teamId',
  //     pathMatch: 'full',
  //     component: SelectTeamComponent
  // },
  {
    path: '',
    loadChildren: () =>
      import('@core/layout/layout-routing.module').then(
        (m) => m.LayoutRoutingModule
      ),
  },

  // {
  //   path: '',
  //   component: PublicComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('@public/main/public.module').then((m) => m.PublicModule),
  //     },
  //   ],
  // },
  // {
  //   path: 'dashboard',
  //   component: AdminComponent,
  //   canActivate: [AuthGuardAdminService],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('@admin/main/admin.module').then((m) => m.AdminModule),
  //     },
  //   ],
  // },
  // {
  //   path: 'coach-dashboard',
  //   component: CoachComponent,
  //   canActivate: [AuthGuardCoachService],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('@coach/main/coach.module').then((m) => m.CoachModule),
  //     },
  //   ],
  // },
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
