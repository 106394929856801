<div class="container">
    <!-- <div class="row titlerow">
        <div *ngIf="isLoading; else notLoading">
            <p-progressSpinner
              [style]="{ width: '25px', height: '25px', color: 'white' }"
            ></p-progressSpinner>
        </div>   
    </div>
    <div class="row titlerow">
        <ng-template #notLoading>
            <div>
                <span class="TheBoldFont-Bold fs-6 text-primary3 title">Is {{team.name}} your team?</span>
            </div>
            <div class="row">
                <section class=" container-responsive " *ngIf="!isLogged; else loggedTemp">
                    <button
                      class="btn-rounded-red mx-2 margin-adjustment"
                      (click)="opLogin.toggle($event)"
                      #btnLogin
                    >
                      ACCEPT
                    </button>
              
                    <button
                    class="btn-rounded-red mx-2 margin-adjustment"
                    (click)="opLogin.toggle($event)"
                    #btnLogin
                  >
                    REJECT
                  </button>
                  <p-overlayPanel #opLogin>
                    <ng-template pTemplate>
                      <app-login [opLogin]="opLogin"></app-login>
                    </ng-template>
                  </p-overlayPanel>
                </section>

            <ng-template #loggedTemp>
                <section class="disp-flex flex-center container-responsive">
                    <button
                    (click)="acceptTeam()"
                    pButton
                    type="button"
                    label="ACCEPT"
                    class="p-button-rounded p-button-danger mx-2 addBtn"
                  ></button>
                  <button
                  (click)="acceptTeam()"
                  pButton
                  type="button"
                  label="REJECT"
                  class="p-button-rounded p-button-danger mx-2 addBtn"
                ></button>
                </section>
              </ng-template>
            </div>
        </ng-template>
    </div> -->

    <div class="row titlerow">
        <div *ngIf="!isLoading">
            <div>
                <span class="TheBoldFont-Bold fs-6 text-primary3 title">Is {{team.name}} your team?</span>
            </div>
            <section class="disp-flex flex-center my-2 container-responsive " *ngIf="!isLoggedCoach; else loggedTemp">
                <button
                class="btn-rounded-red mx-2 margin-adjustment"
                (click)="opLogin.toggle($event)"
                #btnLogin
              >
                ACCEPT
              </button>
        
              <p-overlayPanel #opLogin>
                <ng-template pTemplate>
                  <app-login [opLogin]="opLogin" (loginSuccess)="acceptNewTeam()"></app-login>
                </ng-template>
              </p-overlayPanel>
              
        
              <button
              class="btn-rounded-red mx-2 margin-adjustment"
              (click)="rejectTeam()"
              #btnLogin
            >
              REJECT
            </button>
            </section>
            <section class="disp-flex flex-center my-2 container-responsive " *ngIf="!isLoggedCoach">
              <p class="TheBoldFont-Bold text-primary3">*Please log in as coach to accept team</p>
            </section>
        
            <ng-template #loggedTemp>
                <section class="disp-flex flex-center my-2 container-responsive">
                    <button
                    (click)="acceptTeam()"
                    class="btn-rounded-red mx-2 margin-adjustment"
                    >
                        ACCEPT
                    </button>
                  <button
                  (click)="rejectTeam()"
                  class="btn-rounded-red mx-2 margin-adjustment"
                    >
                    REJECT
                </button>
                </section>
              </ng-template>
          </div>
    </div>


</div>
