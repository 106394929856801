import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// SERVICES
import { HttpService } from '@services/index';

// ENV
import { environment } from '@env/environment';

@Component({
  selector: 'app-division-teams',
  templateUrl: './division-teams.component.html',
  styleUrls: ['./division-teams.component.scss'],
})
export class DivisionTeamsComponent implements OnInit {
  teams: any = [];

  constructor(private httpService: HttpService, private router: Router) {}

  @Input() divisionId: any;
  @Input() tournamentId: any;

  ngOnInit(): void {
    this.getDivisionTeams();
  }

  getDivisionTeams(): void {
    this.teams = [];
    const url = `${environment.api.divisionsTeams}?divisionId=${this.divisionId}`;
    this.httpService
      .get({
        url: url,
      })
      .subscribe(
        (res: any) => {
          this.teams = res;
        },
        (err) => {}
      );
  }

  openTeam(team: any): void {
    this.router.navigate(
      [
        `/public-team-games/${this.tournamentId}/${team.id}/${team.name}/${this.divisionId}`,
      ],
      {
        state: {
          teamValue: team,
        },
      }
    );
  }
}
