<div class="p-col-12">
  <div class="coach-checkin-header">
    <div class="logo-container">
      <img [src]="logoUrl" />
    </div>
    <div>
      <div class="team-name">{{ team?.name }}</div>
      <div class="division-name">{{ divisionName }}</div>
    </div>
  </div>

  <div class="p-grid">
    <button pButton label="Back" class="p-button-raised p-button-danger px-5 py-3 mb-3 ml-3"
      (click)="onBack()"></button>

    <div class="p-col-12 pl-3">
      <p-fieldset [toggleable]="true" [collapsed]="collapseCoaches">
        <p-header>Coaches</p-header>
        <checkin-coaches [team]="team" [selectedVenue]="venue" [tournament]="tournament"
          (checkInCoaches)="receiveCheckInCoaches($event)"></checkin-coaches>
      </p-fieldset>
    </div>

    <div class="p-col-12 pl-3">
      <p-fieldset [toggleable]="true" [collapsed]="collapsePlayers">
        <p-header>Players</p-header>
        <checkin-players [team]="team"></checkin-players>
      </p-fieldset>
    </div>

    <div class="p-col-12 pl-3">
      <p-fieldset [toggleable]="true" [collapsed]="collapsePayment">
        <p-header>Payment</p-header>
        <checkin-payment [teamDivision]="teamDivision"></checkin-payment>
      </p-fieldset>
    </div>

    <div class="p-col-12 pl-3" *ngIf="isShowCollapsibleTerms">
      <p-fieldset [toggleable]="true" [collapsed]="collapseTerms">
        <p-header>Terms</p-header>
        <checkin-terms [teamDivision]="teamDivision"
          (checkIfTermsOrConductIsShown)="checkIfTermsOrConductIsShown($event)"></checkin-terms>
      </p-fieldset>
    </div>
  </div>

  <div style="margin-top: 2rem; text-align: center">
    <button pButton label="Complete Check-in" class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
      (click)="onComplete()"></button>
  </div>
</div>