import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BracketComponent } from '../bracket/bracket.component';
import { BracketService } from '@core/services/bracket-service';
import { Division } from '@core/models/division';
import * as objectScan from 'object-scan';
import { BracketSet } from '@core/models/bracket-set';
import { DialogService } from 'primeng/dynamicdialog';
import { ClearAllBracketsComponent } from '@components/modal/clear-all-brackets/clear-all-brackets.component';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { Bracket } from '@core/models/brackets';
import { ToastService } from '@core/services';

@Component({
  selector: 'app-bracket-stage',
  templateUrl: './bracket-stage.component.html',
  styleUrls: ['./bracket-stage.component.scss']
})
export class BracketStageComponent implements OnInit,AfterViewInit {
  @Input('stageWidth') public stageWidth: string;
  @Input('stageHeight') public stageHeight: string;
  @Input('bracketSet') public bracketSet: BracketSet;
  @Input('divisionInfo') public divisionInfo: Division;
  @ViewChild('scrollContainer') private scrollContainer: ElementRef<HTMLDivElement>;
  private isMouseDown = false;
  private startX = 0;
  private startY = 0;
  private scrollLeft = 0;
  private scrollTop = 0;
  private draggingChild = false;

  bracketCoordinates: any[] = [];
  bracketDimension: any[] = [];
  setBrackets: any[] = [];
  isOpenBracket = false;
  isHasSet = true;
  isChangeName = false;
  bracketTypes = [
    { name: 'Right Facing', value: 'RIGHT_FACING' },
    { name: 'Left Facing', value: 'LEFT_FACING' },
    { name: 'Middle', value: 'MIDDLE' },
    { name: 'Bracket Winner', value: 'BRACKET_WINNER' },
    { name: 'Box', value: 'BOX' },
  ];
  @ViewChild('bracket', { read: ViewContainerRef }) target: ViewContainerRef;
  panZoom: PanzoomObject;
  @ViewChild('stage', { static: false }) stage: ElementRef;

  dynamicComponent: any[] = [];
  selectedBracketType: string;
  topBrackets: Bracket[];
  bottomBrackets: Bracket[];
  bracketName: string;
  zoomScale = 1;
  isMovingBracket = false;
  private lastX: number = 0;
  private lastY: number = 0;
  private panzoom: PanzoomObject
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private bracketService: BracketService,
    private dialogService: DialogService,
    private toastService: ToastService,
    

  ) { }

  ngOnInit(): void {
    this.initializeBracket();
    this.initSubs();
    this.bracketName = this.bracketSet.name;
  }
  ngAfterViewInit(){
    // this.panZoom=  Panzoom(this.stage.nativeElement, {
    //   maxScale: 5,
    //   minScale: 0.1,
    //   contain: 'outside',
    //   cursor: "pointer"
    // })
    this.bracketService.deleteBracket$.subscribe((id) => {
      this.removeBracketSet(id);
    });


  }


  onMouseDown(event: MouseEvent) {
    if (!this.isMovingBracket) {
      this.isMouseDown = true;
      this.startX = event.pageX - this.scrollContainer.nativeElement.offsetLeft;
      this.startY = event.pageY - this.scrollContainer.nativeElement.offsetTop;
      this.scrollLeft = this.scrollContainer.nativeElement.scrollLeft;
      this.scrollTop = this.scrollContainer.nativeElement.scrollTop;
      this.scrollContainer.nativeElement.style.cursor = 'grab';
    event.preventDefault();

    }

  }
  onWheel(event: WheelEvent) {
    event.preventDefault(); 
  }
  onMouseUp() {
    this.isMouseDown = false;
    this.scrollContainer.nativeElement.style.cursor = 'default';
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;
    if (!this.isMovingBracket) {
      const x = event.pageX - this.scrollContainer.nativeElement.offsetLeft;
      const y = event.pageY - this.scrollContainer.nativeElement.offsetTop;
      const walkX = (x - this.startX);
      const walkY = (y - this.startY);
      this.scrollContainer.nativeElement.scrollLeft = this.scrollLeft - walkX;
      this.scrollContainer.nativeElement.scrollTop = this.scrollTop - walkY;
    }

  }

  zoomIn() {
    this.zoomScale += 0.1;
  }

  zoomOut() {
    this.zoomScale -= 0.1;
  }
  initSubs(){
    this.bracketService.movingBracket$.subscribe((isMoving) => {
      this.isMovingBracket = isMoving
    })
    this.bracketService.setBracketCoordinates$.subscribe((bracket) => {
      if (bracket != null) {
        this.bracketCoordinates.push(bracket);
      }
    });

    this.bracketService.setBrackets$.subscribe((bracket) => {
      if(bracket != null){
        this.setBrackets.push(bracket);
      }
    })

    this.bracketService.setBracketDimension$.subscribe((bracket) => {
      if (bracket!= null) {
        this.bracketDimension.push(bracket);
      }
    });
    this.bracketService.editBracketType$.subscribe((bracketObj) => {
      if (bracketObj != null) {
        this.editBracket(bracketObj, bracketObj.bracket);
        this.setBrackets.push(bracketObj);
      }
    });
  }
  initializeBracket(){
    for(let h = 0; h < this.bracketSet.brackets.length; h++){
      let bracket = this.bracketSet.brackets[h]
      this.bottomBrackets = this.searchBottomBracket(bracket);
      this.topBrackets = this.searchTopBracket(bracket);
      this.createBracket(bracket)
      for (var i = 0, j = 0; i < this.bottomBrackets.length || j < this.topBrackets.length;) {
        if (i < this.bottomBrackets.length) {
          if (this.bottomBrackets[i] != null) {
            this.createBracket(this.bottomBrackets[i])
          }
          i++;
        }
  
        if (j < this.topBrackets.length) {
          if (this.topBrackets[j] != null) {
            this.createBracket(this.topBrackets[j])
          }
          j++;
        }
      }
    }
  }

  removeBracketSet(id: any, showToast:boolean = true) {
    if (this.target?.length < 1 || this.target?.length == null) return;
    let cRef = this.dynamicComponent.filter((x) => {
        return x.bracket.id == id;
      })[0];
    let index: number = this.dynamicComponent.indexOf(cRef);
    if (index != -1) {
      this.target.remove(index);
      this.dynamicComponent = this.dynamicComponent.filter((x) => x.bracket.id !== id);
      if (showToast) this.toastService.successMsg({ message: 'Bracket Shape deleted' });
    }
  }
  async createBracket( bracket: Bracket){
    console.log("creating bracket ", bracket)
    const childComponent = this.componentFactoryResolver.resolveComponentFactory(BracketComponent);
    let bracketComponent: ComponentRef<any>;
    const mainOptions = await this.bracketService.getBracketsSources(this.divisionInfo.id,this.bracketSet.id);
    bracketComponent = this.target.createComponent(childComponent);
    bracketComponent.instance.bracket = bracket;
    bracketComponent.instance.isPreview = false;
    bracketComponent.instance.bracketSet = this.bracketSet;
    bracketComponent.instance.mainOptions = mainOptions;
    this.dynamicComponent.push(bracketComponent.instance)
  }
  searchTopBracket(obj:any) {
    let temp = [];
    let arr = objectScan(['**.topBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }

  searchBottomBracket(obj:any) {
    let temp = [];

    let arr = objectScan(['**.bottomBracket'], { rtn: 'value' })(obj);
    for (let i of arr) i && temp.push(i);
    return arr;
  }

  handleAddBracket(){
    this.isOpenBracket = true;
  }
  handleOpenChangeName(event){
event?.stopPropagation();
this.isChangeName = true;
  }
 async handleSaveBracketName(){
    let data = {
      id: this.bracketSet.id,
      name: this.bracketName
    }
    this.isChangeName = false
    await this.bracketService.saveBracketSetName(data);

  }

  handleClearAll(event) {
    event.stopPropagation();

    this.dialogService.open(ClearAllBracketsComponent, {
      header: 'Delete Bracket Set ',
      width: '500px',
      height: 'auto',
      closable: false,
      contentStyle: { overflow: 'hidden' },
      data: this.bracketSet,
    }).onClose.subscribe((result: any) => {
      if (result) {
        this.isHasSet = false;
        this.bracketService.deleteBracketSet$.next(this.bracketSet.id)
      }
    });
  }

  handleSaveAsTemplate(){

  }

  undoChanges(){

  }

  async handleSave() {
    let payload = {
      bracketCoordinates: this.bracketService.getLastDataByBracketId(this.bracketCoordinates),
      bracketDimensions: this.bracketService.getLastDataByBracketId(this.bracketDimension),
      brackets: this.bracketService.getLastDataById(this.setBrackets),
    };
    await this.bracketService.saveBrackets(payload);
    const mainOptions = await this.bracketService.getBracketsSources(this.divisionInfo.id, this.bracketSet.id);
    this.bracketService.bracketOptions$.next(mainOptions);
  }


  editBracket(bracketObj:any,bracket: Bracket): void {
    
    let { bracketId, height, width, x, y, bracketType, bracketName } =
      bracketObj;
    if (this.target?.length < 1 || this.target?.length == null) return;
    let cRef = this.dynamicComponent.filter((x) => {
      return x.bracket.id == bracketId;
    })[0];
    let index: number = this.dynamicComponent.indexOf(cRef);
    if (index != -1) {
      this.target.remove(index);
      this.dynamicComponent = this.dynamicComponent.filter(
        (x) => x.bracket.id !== bracketId
      );
      this.createBracket(bracket)
    }
  }
}
