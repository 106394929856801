import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
  NgModule,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentRef,
  ViewChildren,
  QueryList,
  ContentChildren,
  ComponentFactory,
  HostListener,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastService } from '@core/services';
import { BracketService } from '@core/services/bracket-service';
import { BracketSetTemplateService } from '@core/services/bracket-set-template.service';
import { BracketSources } from '@core/models/bracket-sources';
import { BracketComponent } from '../bracket/bracket.component';
import { BracketSet } from '@core/models/bracket-set';
import { Bracket } from '@core/models/brackets';
import * as objectScan from 'object-scan';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ClearAllBracketsComponent } from '@components/modal/clear-all-brackets/clear-all-brackets.component';
import { Division } from '@core/models/division';
import { BracketPreviewComponent } from '../bracket-preview/bracket-preview.component';

@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss'],
})
export class TemplatePreviewComponent implements OnInit, AfterViewInit {
  data = new BehaviorSubject<any>({});
  selectedDivision: any;
  isShowBracketDialog = false;
  bracketSetForm: FormGroup;
  bracketSizeForm: FormGroup;
  bracketSaveTemplate: FormGroup;
  allBracketTemplates;
  division: Division;
  selectedTemplate;
  isShowBracketSave = false;
  isShowCopyTemplate = false;
  height = 160;
  width = 300;
  firstOptions: BracketSources[];
  bracketSet: BracketSet;
  isHasSet = false;
  isShowStageDialog = false;
  topBrackets: any;
  bottomBrackets: any;

  @ViewChild('bracket', { read: ViewContainerRef }) target: ViewContainerRef;

  @Input()
  set divisionInfo(value) {
    this.selectedDivision = value;
    console.log('division to ??', value);
    this.data.next(value);
  }
  private componentRef: ComponentRef<any>;

  get divisionInfo() {
    return this.data.getValue();
  }
  @ViewChild('parent', { static: false }) parent: ElementRef;
  @ViewChild('bracket', { static: false }) bracketStage: ElementRef;
  @ViewChildren('bracket', { read: BracketComponent })
  bracketComponent: QueryList<BracketComponent>;
  @ViewChildren('viewRef', { read: ViewContainerRef })
  @ContentChildren(BracketComponent)
  ccc: QueryList<BracketComponent>;
  @ViewChildren(BracketComponent)
  customComponentChildren: QueryList<BracketComponent>;

  dynamicComponent: any[] = [];
  stageHeight: number;
  stageWidth: number;
  public viewRefs: QueryList<ViewContainerRef>;
  p5: any;
  elementHost: any;
  constructor(
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    private bracketService: BracketService,
    private bracketSetTemplateService: BracketSetTemplateService,
    private dialogService: DialogService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.division = this.config.data.division;
    this.bracketSetForm = this.formBuilder.group({
      divisionId: [''],
      teamsCount: [''],
      gamesCount: [''],
    });
    this.bracketSizeForm = this.formBuilder.group({
      stageHeight: [''],
      stageWidth: [''],
    });
    this.bracketSaveTemplate = this.formBuilder.group({
      name: [''],
      bracketSetId: [''],
    });
  }
  @HostListener('dragstart', ['$event.target']) onMouseDown(event) {
    console.log(event.clientY);
  }

  ngAfterViewInit(): void {
    this.onPreviewTemplate();

  }

  SaveBracket() {
    console.log('bracketComponent', this.dynamicComponent);
    let data = {};
    this.bracketService.saveBracket(data);
  }
  
  searchTopBracket(obj) {
    this.topBrackets = objectScan(['**.topBracket'], { rtn: 'value' })(obj);
    console.log('top brackets ', this.topBrackets);
    for (let i = 0; i < this.topBrackets.length; i++) {
      if (this.topBrackets[i] != null)
        this.addBracket(
          this.topBrackets[i].x1,
          this.topBrackets[i].y1,
          this.topBrackets[i].name,
          this.calculateHeight(this.topBrackets[i].y1, this.topBrackets[i].y2),
          this.topBrackets[i],
        );
    }
  }

  searchBottomBracket(obj) {
    this.bottomBrackets = objectScan(['**.bottomBracket'], { rtn: 'value' })(
      obj
    );
    console.log('bottom brackets ', this.bottomBrackets);

    for (let i = 0; i < this.bottomBrackets.length; i++) {
      if (this.bottomBrackets[i] != null)
        this.addBracket(
          this.bottomBrackets[i].x1,
          this.bottomBrackets[i].y1,
          this.bottomBrackets[i].name,
          this.calculateHeight(
            this.bottomBrackets[i].y1,
            this.bottomBrackets[i].y2
          ),
          this.bottomBrackets[i]
        );
    }
  }
  calculateHeight(y1, y2): number {
    let y = y2 - y1;

    return y;
  }

  close() {
    this.bracketService.setCopyTemplateFalse();
    this.ref.close();
  }
  ngOnInit(): void {}

  addBracket(x, y, name, h, br?) {
    console.log('bbbrrr ', br);
    let childComponent =
      this.componentFactoryResolver.resolveComponentFactory(BracketPreviewComponent);
    this.componentRef = this.target.createComponent(childComponent);
    this.componentRef.instance.firstOptions = this.firstOptions;
    this.componentRef.instance.height = h/2;
    this.componentRef.instance.width =( this.width - 60) / 2;
    this.componentRef.instance.left = x/2;
    this.componentRef.instance.top = y/2;
    this.componentRef.instance.id = 'bracket';
    this.componentRef.instance.bracketName = name;
    this.componentRef.instance.bracketObject = br;
    this.componentRef.instance.bottomSource = br.bottomSource?.description ?? ''
    this.componentRef.instance.topSource = br.topSource?.description ?? ''
    this.componentRef.instance.bracketObject = {
      division: this.division,
      br: br,
      bracketSet: this.bracketSet,
    };
    this.dynamicComponent.push(this.componentRef.instance);
  }

  onBracketSetSubmit() {
    var valueForm = this.bracketSetForm.value;
    var parentBound = this.parent.nativeElement.getBoundingClientRect();

    var data = {
      divisionId: this.division.id,
      teamsCount: valueForm.teamsCount,
      gamesCount: valueForm.gamesCount,
      width: parentBound.width,
      height: parentBound.height,
    };

    this.bracketService.generateBracketSet(data).then((res) => {
      this.bracketSet = res;
      this.stageHeight = this.bracketSet.height;
      this.stageWidth = this.bracketSet.width;
      this.bracketSizeForm.setValue({
        stageHeight: this.stageHeight,
        stageWidth: this.stageWidth,
      });

      console.log('set', this.bracketSizeForm.value);
      this.isHasSet = true;
      this.renderBrackets();

      this.isShowBracketDialog = false;
    });
  }

  onPreviewTemplate() {
    const data = this.config.data.data;
    console.log('data', data);
    this.bracketService.getPreview(data.bracketSetId).then((res) => {
      this.bracketSet = res;
      console.log('co this.stageHeight py', res);
      console.log('stageWidth', this.bracketSet);
      this.stageHeight = this.bracketSet.height;
      this.stageWidth = this.bracketSet.width;
      this.bracketSizeForm.setValue({
        stageHeight: this.stageHeight,
        stageWidth: this.stageWidth,
      });
      this.isHasSet = true;
      this.renderBrackets();
      this.isShowCopyTemplate = false;
    });
  }
  onCopyTemplate() {
    const data = this.config.data.data;
    this.bracketService.copyBracketSet(data, true).then((res) => {
      this.bracketSet = res;
      console.log('co this.stageHeight py', res);
      console.log('stageWidth', this.bracketSet);
      this.stageHeight = this.bracketSet.height;
      this.stageWidth = this.bracketSet.width;
      this.bracketSizeForm.setValue({
        stageHeight: this.stageHeight,
        stageWidth: this.stageWidth,
      });
      this.isHasSet = true;
      this.renderBrackets();
      this.isShowCopyTemplate = false;
    });
  }

  renderBrackets() {
    this.bracketSet.brackets.forEach((v: Bracket) => {
      this.addBracket(
        v.x1,
         v.y1,
          v.name,
           this.calculateHeight(v.y1, v.y2),v,
           );

      this.bracketService
        .getBracketsSources(this.division.id, this.bracketSet.id)
        .then((res: BracketSources[]) => {
          this.firstOptions = res;
          this.searchTopBracket(v);
          this.searchBottomBracket(v);
        });
    });
  }

  onStageSizeSubmit() {
    var vForm = this.bracketSizeForm.value;

    let data = {
      id: this.bracketSet.id,
      height: vForm.stageHeight,
      width: vForm.stageWidth,
    };
    this.isShowStageDialog = false;
    this.bracketService.setCanvas(data).then((res: any) => {
      console.log('submit size', res);
      this.stageHeight = res.height;
      this.stageWidth = res.width;
    });
  }
  openStageSize() {
    this.isShowStageDialog = true;
  }
  openBracketDialog() {
    this.isShowBracketDialog = true;
  }

  openSaveDialog() {
    this.isShowBracketSave = true;
  }

  openCopyDialog() {
    this.isShowCopyTemplate = true;
  }

  updateBracket() {
    const bracket: Bracket = {
      division: this.division,
      bracketSet: this.bracketSet,
    };

    this.bracketService.saveBracket(bracket);
  }

  saveTemplate() {
    let data = {
      name: this.bracketSaveTemplate.get('name').value,
      teamsCount: this.bracketSet?.teamsCount,
      bracketSetId: this.bracketSet?.id,
    };
    this.bracketSetTemplateService.saveBracketSetTemplate(data);
    this.isShowBracketSave = false;
    this.getBracketTemplates();
  }

  getBracketTemplates() {
    this.bracketService.generateBracketSetTemplate().then((res) => {
      this.allBracketTemplates = res;
      if (this.allBracketTemplates.length > 0) {
        this.selectedTemplate = this.allBracketTemplates[0];
      }
    });
  }

  onSubmit() {
    this.bracketService.setCopyTemplate();
    this.ref.close(1);
  }
}
