<div *ngIf="!divisionInfo; else elseBlock">
  <span class="disp-block fs-4 text-bold1 text-gray5 px-2 text-center"
    >Please select a division.</span
  >
</div>
<ng-template #elseBlock>

  <div class="container-main" [ngClass]="{ show: isHasSet, hide: !isHasSet }">
    <div class="container-stage">
      <div class="container-bracket" [style.height.px]="stageHeight" #parent>
        <div class="shadow-container">
  
        </div>
        <div class="bracket-cont" #bracket></div>

        <!-- <app-bracket #bracket  [height]="height" [width]="width" [firstOptions]="firstOptions" class="bracket">
      </app-bracket> -->
      </div>
    </div>
  </div>
  <div class="p-col-12 flex flex-center">
    <button
      pButton
      pRipple
      type="button"
      label="Cancel"
      class="
        p-button-raised p-button-rounded p-button-text p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="close()"
    ></button>
    <button
      pButton
      iconPos="right"
      type="button"
      label="Accept"
      class="
        p-button-raised p-button-rounded p-button-danger p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="onSubmit()"
    ></button>
  </div>
</ng-template>

<p-dialog header="Save Template" [(visible)]="isShowBracketSave">
  <div class="container-bracketsets">
    <form [formGroup]="bracketSaveTemplate" class="container-forms">
      <input
        formControlName="name"
        class="input-text my-2"
        placeholder="Bracket Name"
      />
      <div class="flex container-btn">
        <button class="btn-rounded-red btn-reset" (click)="saveTemplate()">
          Submit
        </button>
      </div>
    </form>
  </div>
</p-dialog>

<p-dialog
  header="Create Bracket from Template"
  [(visible)]="isShowCopyTemplate"
>
  <div class="container-bracketsets">
    <div class="container-forms">
      <label class="py-3">Select a Template</label>
      <div class="full-dropdown">
        <p-dropdown
          [options]="allBracketTemplates"
          [(ngModel)]="selectedTemplate"
          placeholder=""
          optionLabel="name"
          styleClass="m-0"
        ></p-dropdown>
      </div>
      <div class="flex container-btn">
        <button
          class="btn-rounded-red btn-reset text-center"
          (click)="onCopyTemplate()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</p-dialog>
