<div class="container">
  <div class="row">
    <!-- <div class="dropdownBtns">
        <p-dropdown
          [placeholder]="'BATCH ACTIONS'"
          optionLabel="name"
          optionValue="id"
          styleClass="autocomplete"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
      </div>
      <div class="dropdownBtns">
        <p-dropdown
          [placeholder]="'FILTERS'"
          optionLabel="name"
          optionValue="id"
          styleClass="autocomplete"
          dropdownIcon="pi pi-angle-down"
        >
        </p-dropdown>
      </div> -->
      <!-- <div class="searchBar">
        <span class="p-input-icon-right">
          <i class="pi pi-search"></i>
          <input type="text" 
          pInputText 
          placeholder="Find a Team" >        
      </span> 
      </div>
      <div>
        <button pButton label="Clear" class="p-button-rounded p-button-danger ml-4" icon="pi pi-filter-slash"></button>
      </div> -->
    <div class="rightBtns">
      <button
        (click)="showAddTeam()"
        pButton
        type="button"
        label="ADD TEAM"
        class="p-button-rounded p-button-danger addBtn"
      ></button>
      <button
        [disabled]="
          this.selectedTeams == null || this.selectedTeams.length == 0
            ? true
            : false
        "
        (click)="onDelete()"
        pButton
        type="button"
        label="DELETE TEAM"
        class="p-button-rounded p-button-danger deleteBtn"
      ></button>
    </div>
  </div>
  <div class="row">
    <p-table #dt
      [value]="teams"
      selectionMode="multiple"
      [(selection)]="selectedTeams"
      [globalFilterFields]="['name','rosterSize','gender','city', 'state']"
      dataKey="id"
    >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
          <button pButton label="Clear" class="p-button-rounded p-button-danger" icon="pi pi-filter-slash" (click)="clear(dt)"></button>
          <span class="p-input-icon-left p-ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="Search keyword" />
          </span>
      </div>
  </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th>
            <div class="table-data">
              TEAM
              <!-- <p-columnFilter type="text" field="name" display="menu"></p-columnFilter> -->
          </div>
          </th>
          <th>
            <div class="table-data">
              # OF PLAYERS
            </div>
          </th>
          <th>
            <div class="table-data">
              # OF COACHES
            </div>
          </th>
          <th ><div class="table-data">GENDER</div></th>
          <th ><div class="table-data">GRADE/AGE GROUP</div></th>
          <th  colspan=  "2"><div class="table-data">UPCOMING TOURNAMENT</div></th>

          <th></th>

          <!--
          <th></th>
          <th><i class="pi pi-ellipsis-h"></i></th>
          <th></th>
          <th></th>
          -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-team>
        <tr>
          <td>
            <p-tableCheckbox [value]="team"></p-tableCheckbox>
          </td>
          <td><div class="table-data">{{team.name }}</div> </td>
          
          <td><div class="table-data">{{ team.rosterSize }}</div></td>
          <td><div class="table-data">{{ team.coachesCount }}</div></td>
          <td><div class="table-data">{{ team.gender }}</div></td>
          <td><div class="table-data">{{ ageGradeDisplay(team.ageGroup) || ageGradeDisplay(team.gradeGroup) }}</div></td>
          <!-- <ng-container *ngIf="team.ageGroup == null" else ageGroup>
            <td>{{ team.gradeGroup }}</td>
          </ng-container>
          <ng-template #ageGroup>
            <td>{{ team.ageGroup }}</td>
          </ng-template> -->
 

          <td colspan="2"><div class="table-data">
            <span *ngIf="team.currentDivisions.length == 0">No upcoming tournament</span>
            <span *ngFor="let division of team.currentDivisions; let i=index">
              <span *ngIf="team.currentDivisions.length > 1 && i >= 1">, </span>
              {{ division.tournament.name }}
            </span>
          </div>
          </td>
          <td>
            <!-- <button (click)="showEditTeam()" pButton type="button" label="EDIT TEAM" class="p-button-rounded p-button-danger addBtn"></button> -->
            <i
              class="pi pi-pencil"
              style="cursor: pointer;"
              pTooltip="Edit Team"
              (click)="showEditTeam(team)"
            ></i>
            &nbsp;&nbsp;
            <i
              class="pi pi-users"
              style="cursor: pointer;"
              pTooltip="Manage Players"
              (click)="goToPlayers(team.id)"
            ></i>
            &nbsp;&nbsp;
            <i
            class="pi pi-user"
            style="cursor: pointer;"
            pTooltip="Coaches"
            (click)="goToCoach()"
          ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="7">No teams found.</td>
        </tr>
    </ng-template>
    </p-table>
  </div>
  <!-- <div>
    <p (click)="goTopage()">select</p>
  </div> -->
</div>
