<div>
  <div class="p-col-12 text-center my-2">
    <span class="fs-2 text-black5 text-bold1">
      {{ message }}
    </span>
  </div>
  <div class="p-col-12 flex flex-center">
    <button
      pButton
      pRipple
      *ngIf="hasCancel"
      type="button"
      label="No"
      class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
      (click)="cancel()"
    ></button>
    <button
      pButton
      pRipple
      *ngIf="hasSubmit"
      type="button"
      label="Yes"
      class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
      (click)="submit()"
    ></button>
    <button
      pButton
      pRipple
      *ngIf="hasHandle"
      type="button"
      label="Okay"
      class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
      (click)="handle()"
    ></button>
  </div>
</div>
