import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// MODELS
import { Division } from '@core/models/division';
import { Court } from '@core/models/courts';

// SERVICES
import { HttpService } from '@services/index';

// ENV
import { environment } from '@env/environment';
import moment from 'moment';
import { Tournament } from '@core/models/tournament';
import { rejects } from 'assert';
import { Games } from '@core/models/games';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { TeamsService } from '@core/services/teams.service';
import { Teams } from '@core/models/teams';
import { GamesService } from '@core/services/games.service';
import { PlayerService } from '@core/services/player.service';
import { VenueService } from '@core/services/venue.service';
import { BracketService } from '@core/services/bracket-service';
import { log, table, time } from 'console';
import { DatePipe } from '@angular/common';
import { removeRank } from '@core/utils/string';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GameScoresComponent } from '@components/admin/game-scores/game-scores.component';

interface ITeamInfo {
  refType1: string;
  refType2: string;
  top1: string;
  top2: string;
  bottom1: string;
  bottom2: string;
}

@Component({
  selector: 'app-division-schedule',
  templateUrl: './division-schedule.component.html',
  styleUrls: ['./division-schedule.component.scss'],
})
export class DivisionScheduleComponent implements OnInit {
  _division: Division;
  get division(): Division {
    return this._division;
  }

  @Input()
  set division(value: Division) {
    this._division = value;
    if (value) this.getAllAssignedGames();
  }

  data: any = [];
  isFetching: boolean;
  dateAvailable: Object[] = [];
  initLength: number;
  games: Games[];
  isLoading = true;
  week = 1;

  faFilter = faFilter;
  isFilter: boolean = false;

  schedules: any = [];
  schedulesDuplicate: any = [];
  isHovered = false;
  filterOption: any = 'date';
  filterOptions: any = [
    { label: 'By Team', value: 'team' },
    { label: 'By Player', value: 'player' },
    { label: 'By Venue', value: 'venue' },
    { label: 'By Date', value: 'date' },
  ];

  filterSubOption: any;
  filterSubOptions: any = [''];
  isFilterSubOptionsLoading = false;

  switch: string = 'date';

  teams: any[] = null;
  players: any[] = null;
  dates: any[] = null;
  venues: any[] = null;
  divisionTeams: any[] = [];
  divisionBracketsDetails: any[] = [];
  refType1: string;
  refType2: string;
  refDescription1: string;
  refDescription2: string;
  selectedOptionDescription1: string;
  selectedOptionDescription2: string;

  divisionTournDates: any[] = [];

  innerWidth: number;
  isScreen850Below: boolean;
  isScreenBelow650: boolean;

  gamesBolean: any[] = [];

  ref: DynamicDialogRef;

  constructor(
    private router: Router,
    private httpService: HttpService,
    private gamesService: GamesService,
    private playerService: PlayerService,
    private venueService: VenueService,
    private bracketService: BracketService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.onResize('event');
    this.getInitSchedule();
    this.subscribeToDivisionBracketsDetails();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 850) {
      this.isScreen850Below = true;
    } else {
      this.isScreen850Below = false;
    }
    if (this.innerWidth < 650) {
      this.isScreenBelow650 = true;
    } else {
      this.isScreenBelow650 = false;
    }
  }

  subscribeToDivisionBracketsDetails() {
    this.bracketService.divisionBracketsDetails$.subscribe((details) => {
      this.divisionBracketsDetails = details;
    });
  }

  sortTimeslots(timeslots: any[]): any[] {
    return timeslots.sort((a, b) => {
      const timeA = new Date('1970/01/01 ' + a.time);
      const timeB = new Date('1970/01/01 ' + b.time);
      return timeA.getTime() - timeB.getTime();
    });
  }

  sortGames(games: any[]): any[] {
    return games.sort((a, b) => {
      const venueCompare = (a.court?.venueName || '').localeCompare(
        b.court?.venueName || ''
      );
      if (venueCompare !== 0) return venueCompare;

      const extractNumber = (str: string) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const courtA = extractNumber(a.court?.name || '');
      const courtB = extractNumber(b.court?.name || '');
      return courtA - courtB;
    });
  }

  getScheduleLabel() {
    switch (this.filterOption) {
      case 'all':
        return 'SCHEDULE';
      case 'team':
        return 'SCHEDULE';
        break;
      case 'player':
        return 'SCHEDULE';
        break;
      case 'date':
        return 'SCHEDULE';
        break;
      case 'venue':
        return 'SCHEDULE';
    }
  }

  getTeamLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.teams[ndx].name.toUpperCase();
  }

  getPlayerLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.players[ndx].fullName.toUpperCase();
  }

  getDateLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.dates[ndx].label.toUpperCase();
  }

  getVenueLabel() {
    const ndx = this.filterSubOptions.findIndex((item: any) => {
      return item.value === this.filterSubOption;
    });
    return this.venues[ndx].name.toUpperCase();
  }

  getInitSchedule(): void {
    if (
      this.division?.tournament?.startDate != null &&
      this.division?.tournament?.endDate != null
    ) {
      this.enumerateDays(
        this.division.tournament?.startDate,
        this.division.tournament?.endDate
      );
    }
  }

  enumerateDays(fromDate, toDate): void {
    const day1 = moment(toDate);
    const day2 = moment(fromDate);
    const week = moment(
      day1.format('MM/DD/YYYY'),
      day2.format('MM/DD/YYYY')
    ).week();
    const result = [moment({ ...day2 })];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment({ ...day2 }));
    }

    this.dates = [];
    result.map((x) => {
      const newDate = x.format('MM/DD/YYYY').split('/');
      this.dateAvailable.push({
        label: x.format('dddd, MMMM D, YYYY'),
        value: newDate[2] + newDate[0] + newDate[1],
      });
      this.dates.push({
        label: x.format('MMM DD, YYYY'),
        value: x.format('MMM DD, YYYY'),
      });
    });

    this.initLength = result.map((x) => x.format('MM/DD/YYYY')).length;
    this.dateAvailable.map((date) => {
      this.getAllAssignedGames();
    });
    this.isLoading = false;
  }

  getAllAssignedGames() {
    this.isFetching = true;

    const url = `${environment.api.assignedGames}?divisionId=${this.division.id}`;
    this.httpService
      .get({
        url: url,
      })
      .toPromise()
      .then(
        (games) => {
          this.toScheduledList(games);

          setTimeout((_) => {
            this.isFetching = false;
          }, 250);
        },
        (err) => {
          setTimeout((_) => {
            this.isFetching = false;
          }, 250);
        }
      );
  }

  openDivision(division: Division): void {
    this.router.navigate(
      [`/public-division/${this.division.tournament.id}/${division.id}`],
      { state: division }
    );
  }

  openVenueSchedule(venueId: number): void {
    this.router.navigate([
      `/public-venue-schedules/${this.division.tournament.id}/${venueId}`,
    ]);
  }

  openTeam(team: any): void {
    this.router.navigate(
      [
        `/public-team-games/${this.division.tournament.id}/${team.id}/${team.name}/${this.division.id}`,
      ],
      {
        state: {
          teamValue: team,
          // divisionId: this.divisionId
        },
      }
    );
  }

  onSelectFilter() {
    this.filterSubOptions = ['Fetching...'];
    this.isFilterSubOptionsLoading = true;
    switch (this.filterOption) {
      case 'team':
        // this.getInitSchedule();
        this.getTeams();
        break;
      case 'player':
        this.getPlayers();
        break;
      case 'date':
        this.getDates();
        // this.getInitSchedule();
        break;
      case 'venue':
        // this.getInitSchedule();
        this.getVenues();
        break;
    }
  }

  /**
   * Updates the schedule on sub filter changes (the right one).
   */
  onSelectSubFilter() {
    switch (this.filterOption) {
      case 'team':
        // this.getTeamGames();
        const teamIndex = this.teams.findIndex(
          (teams: Teams) => teams.id === this.filterSubOption
        );
        if (teamIndex !== -1) {
          this.openTeam(this.teams[teamIndex]);
        }
        break;
      case 'player':
        this.getPlayerGames();
        break;
      case 'date':
        this.getDateGames();
        break;
      case 'venue':
        this.getVenueGames();
        this.openVenueSchedule(this.filterSubOption);
        break;
    }
  }

  async getTeams() {
    this.teams = [];
    const url = `${environment.api.divisionsTeams}?divisionId=${this.division.id}`;
    this.httpService
      .get({
        url: url,
      })
      .subscribe(
        (res: any) => {
          this.teams = res;

          let options = [];
          options.push({ label: 'All', value: null });
          for (let i = 0; i < this.teams.length; i++) {
            options.push({
              label: this.teams[i].name,
              value: this.teams[i].id,
            });
          }

          this.filterSubOption = options.length > 0 ? options[0].value : null;
          this.filterSubOptions = options;
          this.isFilterSubOptionsLoading = false;

          if (this.filterSubOption) {
            // this.getTeamGames();
          }
        },
        (err) => {
          this.isFilterSubOptionsLoading = false;
        }
      );
  }

  async getPlayers() {
    if (!this.players) {
      this.players = await this.playerService
        .getPlayersByDivision(this.division.id)
        .toPromise();
    }

    let options = [];
    options.push({ label: 'All', value: null });
    for (let i = 0; i < this.players.length; i++) {
      options.push({
        label: `${this.players[i].fullName} (${this.players[i].team.name})`,
        value: this.players[i].id,
      });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;
    this.isFilterSubOptionsLoading = false;

    if (this.filterSubOption) {
      this.getPlayerGames();
    }
  }

  async getVenues() {
    if (!this.venues) {
      this.venues = await this.venueService
        .getDivisionVenues(this.division.id)
        .toPromise();
    }

    let options = [];
    options.push({ label: 'All', value: null });
    for (let i = 0; i < this.venues.length; i++) {
      options.push({ label: this.venues[i].name, value: this.venues[i].id });
    }

    this.filterSubOption = options.length > 0 ? options[0].value : null;
    this.filterSubOptions = options;
    this.isFilterSubOptionsLoading = false;

    if (this.filterSubOption) {
      this.getVenueGames();
    }
  }

  async getPlayerGames() {
    const games = await this.gamesService.getPlayerGames(
      this.division.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  async getDates() {
    let dates: any[] = [];

    dates.push({
      label: 'All',
      value: 'all',
    });
    dates.push(...this.dates);

    this.filterSubOption = dates.length > 0 ? dates[0].value : null;
    this.filterSubOptions = dates;
    this.isFilterSubOptionsLoading = false;
  }

  async getDateGames() {
    this.schedules = this.schedulesDuplicate;

    if (this.filterSubOption != 'all') {
      this.schedules = this.schedules.filter(
        (schedule) =>
          new DatePipe('en-US').transform(schedule.date, 'MMM d, y') ==
          this.filterSubOption
      );
    }
  }

  async getVenueGames() {
    const games = await this.gamesService.getVenueGames(
      this.division.tournament.id,
      this.filterSubOption
    );
    this.toScheduledList(games);
  }

  toScheduledList(games: any) {
    this.gamesBolean = [];
    if (games && games.length > 0) {
      this.games = games;

      games = games.filter((game) => game.division.id === this.division.id);

      let schedules: any[] = [];
      let schedule: any = null;
      let lastDate: string = '';

      games.forEach((game: Games) => {
        const date: string = moment(game.date).format('YYYYMMDD');
        if (lastDate !== date) {
          if (null !== schedule) schedules.push(schedule);
          lastDate = date;
          schedule = { date: game.date, timeslots: [] };
        }
        let timeslot = schedule.timeslots.find((group: any) => {
          return group.time?.toLowerCase() == game.timeslot?.toLowerCase();
        });
        if (!timeslot) {
          timeslot = { time: game.timeslot, games: [] };
          schedule.timeslots.push(timeslot);
        }
        timeslot.games.push(game);
      });
      if (null !== schedule) schedules.push(schedule);

      this.schedules = schedules;

      // Extra function for By Date sorting
      if (!this.dates) {
        // this.onSelectFilter();
        this.getInitSchedule();
      } else {
        if (this.filterOption == 'date') {
          this.schedulesDuplicate = this.schedules;
          this.getDates();
        }
      }

      //For Speed Dial Feature
      this.schedules.forEach((sched) => {
        sched.timeslots.forEach((time) => {
          time.games.forEach((game) => {
            this.gamesBolean = {
              ...this.gamesBolean,
              [game.id]: false,
            };
          });
        });
      });
    } else {
      this.schedules = [];
    }
  }

  displayMoreInfo(id: number) {
    this.gamesBolean[id] = !this.gamesBolean[id];
  }

  getGameNumber(gameName: string): string {
    if (gameName.includes('#')) {
      const match = gameName.match(/Game # \d+/);
      return match ? match[0] : '';
    } else {
      return gameName;
    }
  }

  fetchFromBracketDetails(game) {
    let gameBracketDetails = this.divisionBracketsDetails?.find(
      (detail) => detail.bracketName === game.name
    );
    this.refType1 = gameBracketDetails?.r1?.refType;
    this.refType2 = gameBracketDetails?.r2?.refType;
    this.refDescription1 = gameBracketDetails?.r1Sub?.description;
    this.refDescription2 = gameBracketDetails?.r2Sub?.description;

    this.selectedOptionDescription1 =
      gameBracketDetails?.topSelectedOption?.description;
    this.selectedOptionDescription2 =
      gameBracketDetails?.bottomSelectedOption?.description;

    let info: ITeamInfo = {
      refType1: '',
      refType2: '',
      top1: '',
      top2: '',
      bottom1: '',
      bottom2: '',
    };

    switch (this.refType1) {
      case 'POOL_PLACER': {
        info.refType1 = this.refType1;
        info.top1 = this.refDescription1 + ' in';
        info.bottom1 = removeRank(this.selectedOptionDescription1);
        break;
      }
      case 'LOSER':
      case 'WINNER': {
        info.refType1 = this.refType1;
        info.top1 = this.selectedOptionDescription1 + ' of';
        info.bottom1 = this.refDescription1;

        break;
      }
      case 'DIV_PLACER': {
        info.refType1 = '';
        info.top1 = this.refDescription1 + ' in';
        info.bottom1 = gameBracketDetails?.r1?.division?.name;

        break;
      }
      default: {
        info.refType1 = '';
        info.top1 = this.refDescription1;
        info.bottom1 = this.selectedOptionDescription1;
        break;
      }
    }

    switch (this.refType2) {
      case 'POOL_PLACER': {
        info.refType2 = '';
        info.top2 = this.refDescription2 + ' in';
        info.bottom2 = removeRank(this.selectedOptionDescription2);
        break;
      }
      case 'LOSER':
      case 'WINNER': {
        info.refType2 = this.refType2;
        info.top2 = this.selectedOptionDescription2 + ' of';
        info.bottom2 = this.refDescription2;

        break;
      }
      case 'DIV_PLACER': {
        info.refType2 = '';
        info.top2 = this.refDescription2 + ' in';
        info.bottom2 = gameBracketDetails?.r2?.division?.name;

        break;
      }
      default: {
        info.refType2 = this.refType2;
        info.top2 = this.refDescription2;
        info.bottom2 = this.selectedOptionDescription2;

        break;
      }
    }

    return info;
  }

  displayGameScores(
    activeIndex: number,
    gameId: number,
    gameName: string,
    gameDate,
    divisionName
  ) {
    this.displayMoreInfo(gameId);
    this.ref = this.dialogService.open(GameScoresComponent, {
      // header: gameName,
      width: '97%',
      closable: true,
      contentStyle: {
        padding: '0',
        'background-color': 'transparent',
      },
      data: {
        activeIndex: activeIndex,
        gameId: gameId,
        gameName: gameName,
        gameDate: gameDate,
        divisionName: divisionName,
        divisionNumPeriod: this.division.numPeriod,
      },
    });

    this.ref.onClose.subscribe((res: any) => {
      console.log('CLOSEDDDDDDDDDDDD', res);
    });
  }
}
