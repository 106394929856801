<form [formGroup]="playerForm" (ngSubmit)="onSubmit()" class="player">
    <div class="p-grid p-3">
  
      <div class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Full Name <span class="required-indicator">*</span></label
        >
        <input
          id="name"
          formControlName="playerName"
          type="text"
          maxlength="50"
          minlength="2"
          class="p-col-12 input-text"
          pInputText
        />
        <div *ngIf="!isValidated && playerForm.controls['playerName'].invalid" class="alert">
          <label class="red-label">Full Name is required.</label>
        </div>
      </div>

      <div class="p-col-12">
        <label for="playerJersey" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Jersey Number <span class="required-indicator">*</span></label
        >
        <input
          id="playerJersey"
          formControlName="playerJersey"
          type="text"
          maxlength="2"
          minlength="0"
          class="p-col-12 input-text"
          pInputText
          appNumericInput
        />
        <div *ngIf="!isValidated && playerForm.controls['playerJersey'].invalid" class="alert">
          <label class="red-label">Jersey Number is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerBirthdate!= NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Birthdate
          <span *ngIf="registration?.playerBirthdate == REQ" class="required-indicator">*</span>
        </label>
        <p-calendar
          class="tk-date-picker"
          formControlName="playerBirthdate"
          type="date"
          dateFormat="mm/dd/yy"

        >
        </p-calendar>
        <div *ngIf="!isValidated && playerForm.controls['playerBirthdate'].invalid" class="alert">
          <label class="red-label">Birthdate is required.</label>
        </div>
      </div>
      <div *ngIf="registration?.playerGraduationYear != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Graduation Year <span *ngIf="registration?.playerGraduationYear == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerGraduationYear"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerGraduationYear'].invalid" class="alert">
          <label class="red-label">Graduation Year is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerGpa != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >GPA <span *ngIf="registration?.playerGpa == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerGpa"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerGpa'].invalid" class="alert">
          <label class="red-label">GPA is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerHeight != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Height <span *ngIf="registration?.playerHeight == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerHeight"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerHeight'].invalid" class="alert">
          <label class="red-label">Height is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerPosition != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Position <span *ngIf="registration?.playerPosition == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerPosition"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerPosition'].invalid" class="alert">
          <label class="red-label">Position is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerEmail != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          > Email <span *ngIf="registration?.playerEmail == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerEmail"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerEmail'].invalid" class="alert">
          <label class="red-label">Email is required.</label>
        </div>
      </div>

      
      <div *ngIf="registration?.playerPhone != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Phone # <span *ngIf="registration?.playerPhone == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerPhone"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerPhone'].invalid" class="alert">
          <label class="red-label">Phone # is required.</label>
        </div>
      </div>


      <div *ngIf="registration?.playerFacebook != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Facebook Page <span *ngIf="registration?.playerFacebook == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerFacebook"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerFacebook'].invalid" class="alert">
          <label class="red-label">Facebook Page is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerInstagram != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Instagram Handle <span *ngIf="registration?.playerInstagram == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerInstagram"
          type="text"
          class="tournament-input-text"

        />

        <div *ngIf="!isValidated && playerForm.controls['playerInstagram'].invalid" class="alert">
          <label class="red-label">Instagram Handle is required.</label>
        </div>
      </div>


      <div *ngIf="registration?.playerTwitter != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Twitter Handle <span *ngIf="registration?.playerTwitter == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerTwitter"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerTwitter'].invalid" class="alert">
          <label class="red-label">Twitter  Handle is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerParentName1 != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Parent's/Guardian's Name <span *ngIf="registration?.playerParentName1 == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerParentName1"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerParentName1'].invalid" class="alert">
          <label class="red-label">Parent's/Guardian's Name is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerParentEmail1 != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Parent's/Guardian's Email <span *ngIf="registration?.playerParentEmail1 == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerParentEmail1"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerParentEmail1'].invalid" class="alert">
          <label class="red-label">Parent's/Guardian's Email is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerParentPhone1 != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Parent's/Guardian's Phone <span *ngIf="registration?.playerParentPhone1 == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerParentPhone1"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerParentPhone1'].invalid" class="alert">
          <label class="red-label">Parent's/Guardian's Phone is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerParentName2 != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Parent's/Guardian's Name 2 <span *ngIf="registration?.playerParentName2 == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerParentName2"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerParentName2'].invalid" class="alert">
          <label class="red-label">Parent's/Guardian's Name 2 is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerParentEmail2 != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Parent's/Guardian's Email 2 <span *ngIf="registration?.playerParentEmail2 == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerParentEmail2"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerParentEmail2'].invalid" class="alert">
          <label class="red-label">Parent's/Guardian's Email 2 is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.playerParentPhone2 != NOT_REQ" class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Parent's/Guardian's Phone 2 <span *ngIf="registration?.playerParentPhone2 == REQ" class="required-indicator">*</span>
        </label>
        <input
          formControlName="playerParentPhone2"
          type="text"
          class="tournament-input-text"

        />
        <div *ngIf="!isValidated && playerForm.controls['playerParentPhone2'].invalid" class="alert">
          <label class="red-label">Parent's/Guardian's Phone 2 is required.</label>
        </div>
      </div>

      <div *ngIf="registration?.additionalPlayerInfo != []" class="p-col-12">
        <div *ngFor="let playerInfo of registration?.additionalPlayerInfo" class="p-col-12 addtl-field">
          <div class="p-col-12 addtl-field" *ngIf="playerInfo?.status == 'NOT_REQUIRED' ? false: true">
            <label class="text-bold1 disp-block p-col-12 p-0 mb-2"
              >{{ playerInfo?.name }} <span *ngIf="playerInfo?.status == 'REQUIRED'" class="required-indicator">*</span>
            </label>
            <input
            *ngIf="playerInfo?.type === 'TEXTBOX'"
            [formControlName]="generateFormGrp(playerInfo?.id)"
            type="text"
            class="tournament-input-text"
            [ngClass]="isValidated ? null : 'validated'"
          />
          <textarea
            *ngIf="playerInfo?.type === 'TEXTAREA'"
            [formControlName]="generateFormGrp(playerInfo?.id)"
            type="text"
            rows="4"
            class="tournament-inputarea-text mt-1"
            [ngClass]="isValidated ? null : 'validated'"
          ></textarea>
            <!-- <div *ngIf="!isValidated && form.controls['coachInstagram'].invalid" class="alert">
              <label class="red-label">Instagram Handle is required.</label>
            </div> -->
          </div>
        </div>
      </div>
  
     
      <div class="p-col-12 flex flex-center mt-4">
        <button
          pButton
          type="button"
          label="Cancel"
          class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
          (click)="close()"
        ></button>
        <button
          pButton
          type="submit"
          label="Submit"
          class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
        ></button>
      </div>
    </div>
  </form>
  