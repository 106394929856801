import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject } from 'rxjs';

import { environment } from '@env/environment';

import { TeamDivision } from '@core/models/team-division';

@Injectable({
  providedIn: 'root',
})
export class TeamDivisionsService {

  teamDivision$ = new BehaviorSubject<TeamDivision>(null);

  constructor(private http: HttpService) {
  }

  getTeamDivision(id:number): Promise<TeamDivision> {

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.teamDivisions}/${id}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getTeamDivisions(tournamentId:number): Promise<TeamDivision[]> {

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.teamDivisions}?tournamentId=${tournamentId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  saveTeamDivision(teamDivision:any) {
    return this.http.put({
        url: environment.api.teamDivisions,
        payload: teamDivision,
    });
  }

}
