import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  
  constructor( ) { }

  ngOnInit(): void {

  }

}
