import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Division } from '@core/models/division';
import { Games } from '@core/models/games';

import { HttpService } from '@core/services';

import { environment } from '@env/environment';
import * as moment from 'moment';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-venue-schedule',
  templateUrl: './venue-schedule.component.html',
  styleUrls: ['./venue-schedule.component.scss'],
})
export class VenueScheduleComponent implements OnInit {
  @Input() venueId: number;
  @Input() tournamentId: number;

  game: Games;
  games: Games[];
  schedules: any = [];

  isFetching: boolean;

  innerWidth: number;
  isScreen850Below: boolean;
  isScreenBelow600: boolean;

  divisionBracketsDetails: any[] = [];
  refType1: string;
  refType2: string;
  refDescription1: string;
  refDescription2: string;
  selectedOptionDescription1: string;
  selectedOptionDescription2: string;

  constructor(private httpService: HttpService, private router: Router) {}

  ngOnInit(): void {
    this.onResize('event');
    this.getAssignedGames();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 850) {
      this.isScreen850Below = true;
    } else {
      this.isScreen850Below = false;
    }
    if (this.innerWidth < 600) {
      this.isScreenBelow600 = true;
    } else {
      this.isScreenBelow600 = false;
    }
  }

  convertedDate(date) {
    var formattedDate = date.split('/');
    return formattedDate[2] + formattedDate[0] + formattedDate[1];
  }

  getAssignedGames() {
    const url = `${environment.api.assignedGames}?tournamentId=${this.tournamentId}&venueId=${this.venueId}`;

    this.httpService
      .get({
        url: url,
      })
      .toPromise()
      .then(
        (games) => {
          if (games?.length > 0) {
            this.games = games;

            let schedules: any[] = [];
            let schedule: any = null;
            let lastDate: string = '';

            games.forEach((game: Games) => {
              const date: string = moment(game.date).format('YYYYMMDD');
              if (lastDate !== date) {
                if (null !== schedule) schedules.push(schedule);
                lastDate = date;
                schedule = { date: game.date, timeslots: [] };
              }
              let timeslot = schedule.timeslots.find((group: any) => {
                return (
                  group.time?.toLowerCase() == game.timeslot?.toLowerCase()
                );
              });
              if (!timeslot) {
                timeslot = { time: game.timeslot, games: [] };
                schedule.timeslots.push(timeslot);
              }
              timeslot.games.push(game);
            });
            if (null !== schedule) schedules.push(schedule);

            this.schedules = schedules;
          }

          this.isFetching = false;
        },

        (err) => {
          this.isFetching = false;
        }
      );
  }

  openDivision(division: Division): void {
    this.router.navigate(
      [`/public-division/${this.tournamentId}/${division.id}`],
      { state: division }
    );
  }

  openVenueSchedule(venueId: number): void {
    this.router.navigate([
      `/public-venue-schedules/${this.tournamentId}/${venueId}`,
    ]);
  }

  openTeam(team: any, division): void {
    this.router.navigate(
      [
        `/public-team-games/${this.tournamentId}/${team.id}/${team.name}/${division.id}`,
      ],
      {
        state: {
          teamValue: team,
          // divisionId: this.divisionId
        },
      }
    );
  }

  getGameNumber(gameName: string): string {
    if (gameName.includes('#')) {
      const match = gameName.match(/Game # \d+/);
      return match ? match[0] : '';
    } else {
      return gameName;
    }
  }

  sortGames(games: any[]): any[] {
    return games.sort((a, b) => {
      const venueCompare = (a.court?.venueName || '').localeCompare(
        b.court?.venueName || ''
      );
      if (venueCompare !== 0) return venueCompare;

      const extractNumber = (str: string) => {
        const match = str.match(/\d+/);
        return match ? parseInt(match[0], 10) : 0;
      };

      const courtA = extractNumber(a.court?.name || '');
      const courtB = extractNumber(b.court?.name || '');
      return courtA - courtB;
    });
  }

  fetchFromBracketDetails(game) {
    let gameBracketDetails = this.divisionBracketsDetails?.find(
      (detail) => detail.bracketName === game.name
    );

    console.log('game brackk', gameBracketDetails);

    this.refType1 = gameBracketDetails?.r1?.refType;
    this.refType2 = gameBracketDetails?.r2?.refType;
    this.refDescription1 = gameBracketDetails?.r1Sub?.description;
    this.refDescription2 = gameBracketDetails?.r2Sub?.description;
    this.selectedOptionDescription1 =
      gameBracketDetails?.topSelectedOption?.description;
    this.selectedOptionDescription2 =
      gameBracketDetails?.bottomSelectedOption?.description;

    let data = {
      refType1: this.refType1,
      refType2: this.refType2,
      refDescription1: this.refDescription1,
      refDescription2: this.refDescription2,
      selectedOptionDescription1: this.selectedOptionDescription1,
      selectedOptionDescription2: this.selectedOptionDescription2,
    };

    let info;

    if (this.refType1 == 'POOL_PLACER' || this.refType2 == 'POOL_PLACER') {
      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.refDescription1 + ' in',
        top2: this.refDescription2 + ' in',
        bottom1: this.selectedOptionDescription1,
        bottom2: this.selectedOptionDescription2,
      };
    } else if (
      this.refType1 == 'WINNER' ||
      this.refType1 == 'LOSER' ||
      this.refType2 == 'WINNER' ||
      this.refType2 == 'LOSER'
    ) {
      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.selectedOptionDescription1 + ' of',
        top2: this.selectedOptionDescription2 + ' of',
        bottom1: this.refDescription1,
        bottom2: this.refDescription2,
      };
    } else {
      console.log('ELSEEEEE', data);

      info = {
        refType1: this.refType1,
        refType2: this.refType2,
        top1: this.refDescription1,
        top2: this.refDescription2,
        bottom1: this.selectedOptionDescription1,
        bottom2: this.selectedOptionDescription2,
      };
    }

    return info;
  }
}
