<p-dialog
  [(visible)]="display"
  [modal]="true"
  [closable]="false"
  [style]="{ width: '500px' }"
>
  <div class="ui-g">
    <div class="ui-g-12">
      <p class="content">Are you sure you want to delete {{ user?.name }}?</p>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="button">
      <button
        pButton
        type="button"
        icon="pi pi-times"
        label="Cancel"
        class="custom-cancel-button"
        (click)="hideModal()"
      >
        Cancel
      </button>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        label="Delete"
        class="custom-delete-button"
        (click)="deleteData()"
      >
        Delete
      </button>
    </div>
  </ng-template>
</p-dialog>
