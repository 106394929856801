import { Injectable, Input } from '@angular/core';
import { Division } from '@core/models/division';
import { Pools } from '@core/models/pools';
import { Teams } from '@core/models/teams';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DivisionService {
  poolTeams = [];
  divisionPools = [];
  divisionCounts = [];
  poolTeams$ = new BehaviorSubject<any[]>(null);
  prevPoolTeams$ = new BehaviorSubject<any[]>(null);
  pools$ = new BehaviorSubject<Pools[]>(null);
  divisionPools$ = new BehaviorSubject<Pools[]>(null);
  poolName: String = '';
  teams: Teams[];
  tempTeams$ = new BehaviorSubject<any[]>(null);
  excludedTeams = [];
  diff = [];
  reloadDivision = new Subject<void>();
  selectedDivision$ = new Subject<{}>();
  isStillCreatingNewDivision$ = new BehaviorSubject<boolean>(false);

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  /**
   * Gets the division given the id.
   *
   * @param divisionId the id of the division
   * @returns the division
   */
  async getDivision(divisionId: number): Promise<Division> {
    const division: Division = await this.httpService
      .get({
        url: `${environment.api.divisions}/${divisionId}`,
      })
      .toPromise();

    return division;
  }

  /**
   * Gets the division given the id.
   *
   * @param divisionId the id of the division
   * @returns the division
   */
  getTournamentDivisions(tournamentId: number) {
    return this.httpService.get({
      url: `${environment.api.divisions}?tournamentId=${tournamentId}`,
    });
  }

  /**
   * Gets the division of a team in a tournament given the id.
   *
   * @param tournamentId the id of the tournament
   * @param teamId the id of the teamId
   * @returns the division
   */
  getTeamDivisionInTournament(tournamentId: number, teamId: number) {
    return this.httpService.get({
      url: `${environment.api.tournamentTeamDivisions}?tournamentId=${tournamentId}&teamId=${teamId}`,
    });
  }

  addPool(poolCount: number, divisionId: number): void {
    this.divisionCounts = [];

    const existedDivCount = this.divisionCounts.find(
      (div) => div.divisionId === divisionId
    );

    if (!existedDivCount) {
      this.divisionCounts.push({
        poolCount,
        divisionId,
      });
    } else {
      existedDivCount.poolCount = poolCount;
    }

    this.poolTeams = [];

    this.httpService
      .post({
        url: `${environment.api.pools}/create`,
        payload: this.divisionCounts,
      })
      .toPromise()
      .then((res) => {
        if (res) {
          this.pools$.next(res);

          res.forEach((d) => {
            const existedDiv = this.poolTeams.find(
              (pool) => d.division.id === pool.division.id
            );
            if (!existedDiv) {
              this.poolTeams.push({
                pools: [
                  {
                    id: d.id,
                    name: d.name,
                    teams: [],
                  },
                ],
                division: d.division,
              });
            } else {
              existedDiv.pools.push({
                id: d.id,
                name: d.name,
                teams: [],
              });
            }
          });
          for (let i = 0; i < this.poolTeams.length; i++) {
            for (let y = 0; y < this.poolTeams[i].pools.length; y++) {
              this.getPoolTeams(this.poolTeams[i].pools[y].id).then((d) => {
                for (let x = 0; x < d.length; x++) {
                  this.poolTeams[i].pools[y].teams.push(d[x]);
                }
              });
            }
          }
        }
      });

    this.poolTeams$.next(this.poolTeams);
    this.prevPoolTeams$.next(this.poolTeams);
  }

  getPoolTeams(poolId) {
    return this.httpService
      .get({
        // url: `${environment.api.pools}/teams?poolId=${poolId}`,
        url: `${environment.api.divisions}/team-pool?poolId=${poolId}`,
      })
      .toPromise()
      .then((res) => {
        return res;
      });
  }

  editPool(name: string, poolId: number, division: Division): void {
    const body: any = {
      id: poolId,
      name,
      division,
    };

    this.httpService
      .post({
        url: `${environment.api.pools}`,
        payload: body,
      })
      .toPromise();
  }

  async saveGames(matches: any) {
    return new Promise((resolve, reject) => {
      try {
        this.httpService
          .post({
            url: `${environment.api.games}/create`,
            payload: matches,
          })
          .subscribe(
            (res: any) => {
              if (res) {
                resolve(true);
                this.toastService.toast(
                  'Games Successfully created.',
                  'success'
                );
              } else {
                reject(false);
                this.toastService.toast(
                  'Failed',
                  'Failed Creating Games',
                  'error'
                );
              }
            },
            (_) => {
              reject(false);
              this.toastService.toast(
                'Failed',
                'Something went wrong when saving the game',
                'error'
              );
            }
          );
      } catch (error) {}
    });
  }

  async generateGames(divisionId: number) {
    return new Promise((resolve, reject) => {
      try {
        this.httpService
          .post({
            url: `${environment.api.games}/create/${divisionId}`,
          })
          .subscribe(
            (res: any) => {
              if (res) {
                resolve(true);
                this.toastService.toast(
                  'Games Successfully created.',
                  'success'
                );
              } else {
                reject(false);
                this.toastService.toast(
                  'Failed',
                  'Failed Creating Games',
                  'error'
                );
              }
            },
            (_) => {
              reject(false);
              this.toastService.toast(
                'Failed',
                'Something went wrong when saving the game',
                'error'
              );
            }
          );
      } catch (error) {}
    });
  }

  async getPoolByDivision(divisionId) {
    this.poolTeams = [];
    this.poolTeams$.next([]);
    this.prevPoolTeams$.next([]);

    try {
      await this.httpService
        .get({
          url: `${environment.api.divisions}/pools?divisionId=${divisionId}`,
        })
        .toPromise()
        .then((pools) => {
          if (pools) {
            this.pools$.next(pools);

            pools.forEach((pool: any) => {
              const existedDiv = this.poolTeams.find((poolItem) => {
                return pool.division.id === poolItem.division.id;
              });

              if (!existedDiv) {
                this.poolTeams.push({
                  pools: [
                    {
                      id: pool.id,
                      name: pool.name,
                      teams: [],
                    },
                  ],
                  division: pool.division,
                });
              } else {
                existedDiv.pools.push({
                  id: pool.id,
                  name: pool.name,
                  teams: [],
                });
              }
            });

            console.log('poolTeams', this.poolTeams);

            this.excludedTeams = [];

            for (let i = 0; i < this.poolTeams.length; i++) {
              for (let y = 0; y < this.poolTeams[i].pools.length; y++) {
                this.getPoolTeams(this.poolTeams[i].pools[y].id).then((d) => {
                  for (let x = 0; x < d.length; x++) {
                    this.poolTeams[i].pools[y].teams.push(d[x]);
                    this.removeElement(d[x]);
                  }
                });
              }
            }
          }
        });

      this.poolTeams$.next(this.poolTeams);
      this.prevPoolTeams$.next(this.poolTeams);
    } catch (err) {}
  }

  removeElement(item) {
    this.teams = this.teams.filter((e) => {
      return e.id !== item.teamId;
    });

    let tempTeamPools = [];

    this.teams.map((team) => {
      let tempTp = {
        id: null,
        name: team.name,
        seqNo: null,
        teamId: team.id,
      };

      tempTeamPools.push(tempTp);
    });

    this.tempTeams$.next(tempTeamPools);
    console.log();
  }

  async getDivisionTeams(divId: string) {
    try {
      const result = await this.httpService
        .get({
          url: environment.api.divisionsTeams,
          payload: {
            divisionId: divId,
          },
        })
        .toPromise();
      this.teams = result;
    } catch (e) {}
  }

  async postPool(pool: Pools, division: Division): Promise<any> {
    const body: any = {
      id: pool.id,
      name: pool.name,
      division,
    };

    try {
      const response = await this.httpService
        .post({
          url: `${environment.api.pools}`,
          payload: body,
        })
        .toPromise();

      this.toastService.successMsg({
        message: `${response.name} has been saved!`,
      });

      return response;
    } catch (err) {
      this.toastService.errorMsg({ message: 'Failed' });
      throw err;
    }
  }

  deletePools(id) {
    try {
      this.httpService
        .delete({
          url: `${environment.api.pools}/${id}`,
        })
        .subscribe(
          (_) => {},
          (err) => {}
        );
      this.poolTeams$.next([]);
      this.poolTeams = [];
    } catch (e) {
      this.toastService.errorMsg({ message: 'Failed Deleting Pools' });
    }
  }

  async moveTeamToPool(newPoolId, teamPoolId, teamId, index) {
    const body: any = {
      newPoolId: parseInt(newPoolId),
      teamPoolId: parseInt(teamPoolId),
      teamId: parseInt(teamId),
      seqNo: parseInt(index),
    };

    try {
      await this.httpService
        .post({
          url: `${environment.api.moveTeamPools}`,
          payload: body,
        })
        .toPromise()
        .then((res) => {});
    } catch (err) {}
  }

  async saveAllTeamPools(groupTeamPool, divisionId) {
    try {
      await this.httpService
        .post({
          url: `${environment.api.saveAllTeamPools}?divisionId=${divisionId}`,
          payload: groupTeamPool,
        })
        .toPromise()
        .then((response) => {
          this.toastService.successMsg({ message: 'Pools Saved Succesfully!' });
        });
    } catch (err) {
      this.toastService.errorMsg({ message: err.error.message });
    }
  }
  async updateTeamPools(groupTeamPool, divisionId) {
    try {
      await this.httpService
        .put({
          url: `${environment.api.saveAllTeamPools}?divisionId=${divisionId}`,
          payload: groupTeamPool,
        })
        .toPromise()
        .then((response) => {
          this.toastService.successMsg({ message: 'Pools Saved Succesfully!' });
        });
    } catch (err) {
      this.toastService.errorMsg({ message: err.error.message });
    }
  }

  async removeTeamToPool(oldPoolId, newPoolId, team) {
    const body: any = {
      poolId: parseInt(oldPoolId),
      teamId: parseInt(team.id),
    };

    try {
      await this.httpService
        .delete({
          url: `${environment.api.removeTeamPools}`,
          payload: body,
        })
        .toPromise()
        .then((res) => {});
    } catch (err) {}
  }

  async deletePool(id) {
    try {
      await this.httpService
        .delete({
          url: `${environment.api.pools}/${id}`,
        })
        .toPromise()
        .then((res) => {
          this.toastService.toast(
            'Success',
            'Pool successfully deleted.',
            'success'
          );
        });
    } catch (e) {
      this.toastService.errorMsg({ message: 'Failed Deleting Pool' });
    }
  }

  editPoolName(value) {
    this.poolName = value;
  }

  clearPoolName() {
    this.poolName = '';
  }

  getTournamentDivision(tournamentId: number): Promise<Division[]> {
    return new Promise((resolve, reject) => {
      this.httpService
        .get({
          url: `${environment.api.divisions}?tournamentId=${tournamentId}`,
        })
        .toPromise()
        .then((res: Division[]) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }
}
