import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tournament } from '@core/models/tournament';

// MODELS
import { Venue } from '@core/models/venue';

// SERVICES
import { VenueService } from '@core/services/venue.service';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.scss'],
})
export class VenuesComponent implements OnInit {
  venues: Venue[];
  selectedVenue: Venue;
  secondaryColor: string;
  fontColor: string;

  @Input() organizationId: number;
  // @Input() tournamentId:number;

  _tournament: Tournament;
  get tournament(): Tournament {
    return this._tournament;
  }
  @Input() set tournament(value: Tournament) {
    this._tournament = value;
    this.getVenues();
  }

  constructor(private router: Router, private venueService: VenueService) {}

  ngOnInit(): void {}

  selectVenue(venue: Venue) {
    this.selectedVenue = venue;
  }

  getVenues() {
    this.venueService
      .getVenues(this.tournament?.id, this.organizationId)
      .subscribe((venues) => {
        if (null == this._tournament.organization?.secondaryColor) {
          this.secondaryColor = '#000000';
        } else {
          this.secondaryColor = this._tournament.organization?.secondaryColor;
        }
        this.getFontColor(this.secondaryColor);

        this.venues = venues;
        if (venues.length > 0) {
          this.selectedVenue = venues[0];
        }
      });
  }

  getFontColor(secondary: string) {
    let sRed = secondary.substring(1, 3);
    let sGreen = secondary.substring(3, 5);
    let sBlue = secondary.substring(5, 7);

    let secondaryInt =
      parseInt(sRed, 16) * 0.299 +
      parseInt(sGreen, 16) * 0.587 +
      parseInt(sBlue, 16) * 0.114;

    if (secondaryInt > 150) {
      console.log('SECONDARY IS BLACK');
      this.fontColor = '#000000';
    } else {
      console.log('SECONDARY IS WHITE');
      this.fontColor = '#ffffff';
    }
  }

  openVenueSchedule(venueId: number): void {
    if (this.tournament.postSchedule == true) {
      this.router.navigate([
        `/public-venue-schedules/${this.tournament.id}/${venueId}`,
      ]);
    } else {
    }
  }
}
