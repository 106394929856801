<div class="my-5">
  <table class="full-width">
    <thead>
      <tr>
        <th
          *ngFor="let header of thead; let i = index"
          [style]="{
            'text-align':
              i == 0 || i == 1
                ? setAlignHeader(header.id)
                : setTextAlign(header.id)
          }"
        >
          {{ header.value }}
        </th>
      </tr>
    </thead>
    <tbody *ngIf="!isFetching">
      <tr *ngFor="let item of data">
        <td
          *ngFor="let header of thead; let i = index"
          [ngClass]="i == 0 || i == 1 ? 'p-0' : 'p-2'"
          [style]="{
            'text-align':
              i == 0 || i == 1
                ? setAlignHeader(header.id)
                : setTextAlign(header.id)
          }"
        >
          <span>
            {{ (header.id == 'ageGroup' || header.id == 'gradeGroup') ? ageGradeDisplay(item[header.id]) : item[header.id] }}
          </span>
        </td>
        <td width="150" *ngIf="config?.actionButtons">
          <section
            class="pointer btn-table-actions float-right"
            *ngFor="let btn of config.actionButtons; let n = index"
          >
            <div
              *ngIf="n === 1"
              (click)="showDialog(item[config.actionReference], btn)"
            >
              <i
                class="pi"
                [pTooltip]="setToolTip(btn)"
                tooltipPosition="top"
                [ngClass]="btn ? setBtnIcon(btn) : ''"
              ></i>
            </div>

            <div
              *ngIf="n !== 1"
              (click)="onClick(item[config.actionReference], btn, item)"
            >
              <i
                class="pi"
                [pTooltip]="setToolTip(btn)"
                tooltipPosition="top"
                [ngClass]="btn ? setBtnIcon(btn) : ''"
              ></i>
            </div>
          </section>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="isFetching; else isFetched" class="disp-block text-center py-4">
    <p-progressSpinner
      [style]="{ width: '40px', height: '40px' }"
    ></p-progressSpinner>
  </div>
  <ng-template #isFetched>
    <div *ngIf="data.length == 0">
      <span class="fs-4 disp-block text-center text-bold1 pt-5">{{
        noResult
      }}</span>
    </div>
  </ng-template>
</div>

<p-dialog [(visible)]="isDeleteDialog" [modal]="false" [dismissableMask]="true">
  <div class="container-content">
    <h1>{{ message }}</h1>
  </div>

  <div class="flex flex-center m-4 my-4">
    <button class="btn-rounded-white mx-1" (click)="hide()">Cancel</button>
    <button class="btn-rounded-red mx-1" (click)="deleteNow()">Confirm</button>
  </div>
</p-dialog>
