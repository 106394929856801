import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from '@components/public/login/login.component';
import { SignupComponent } from '@components/public/signup/signup.component';
import { defConstant } from '@core/data/constant-data';
import { User } from '@core/models/user';
import {
  EmitterService,
  HttpService,
  SessionStorageService,
} from '@core/services';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
})
export class RegistrationModalComponent implements OnInit {
  user: User;
  ref: DynamicDialogRef;
  @Output() open: EventEmitter<any> = new EventEmitter();
  @ViewChild('opLogins') op: OverlayPanel;
  toggleOverlay = ({ originalEvent }) => this.op.toggle(originalEvent);

  @ViewChild('opLogin', { static: false }) opLogin;

  constructor(
    public reff: DynamicDialogRef,
    private dialogService: DialogService,
    private router: Router,
    private httpService: HttpService,
    private emitterService: EmitterService,
    private sessionStorageService: SessionStorageService,
    public config: DynamicDialogConfig,
    private publicTournamentService: PublicTournamentsService
  ) {}

  ngOnInit(): void {
    this.user = this.emitterService.userData;
    if (!this.user) {
      this.httpService.me().subscribe(
        (user: User) => {
          this.user = user;
        },
        (err) => {}
      );
    }

    this.me();
  }

  close(): void {
    this.reff.close();
  }

  signup(): void {
    this.ref = this.dialogService.open(SignupComponent, {
      width: defConstant.modalWidth,
      closable: false,
    });

    this.ref.onClose.subscribe((res) => {
      this.reff.close();
    });
  }

  login() {
    this.publicTournamentService.setRegisterToTrue();
    this.emitterService.loginEmit(false);
    this.close();
  }

  async me() {
    const me = await this.httpService.me().toPromise();
  }
}
