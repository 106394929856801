<div class="heading" (click)="gotoHomepage()">
  <img src="./assets/img/logo-only-white.svg" />

  <div class="text">
    <h1 class="title-header">TOURNAMENT</h1>
    <h1 class="title-header">DIRECTOR</h1>
  </div>
</div>
<ul>
  <li *ngFor="let item of items">
    <ul class="pl-1">
      <li *ngFor="let list of item.list">
        <a
          class="nav-links p-3 disp-block"
          [routerLink]="[list.path]"
          routerLinkActive="active"
          (click)="loadPage(list)"
        >
          <i class="fs-2 pi" [ngClass]="list.icon ? list.icon : ''"></i
          >&#160;&#160;{{ list.name }}
        </a>
      </li>
    </ul>
  </li>
</ul>
