import { Injectable } from '@angular/core';
import { Bracket } from '@core/models/bracket';
import { BracketSetTemplate } from '@core/models/bracket-set-template';
import { environment } from '@env/environment';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class BracketSetTemplateService {

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  async getBracketTemplates(teamCount:number): Promise<BracketSetTemplate[]> {

    const bracketTemplates:BracketSetTemplate[] = await this.httpService.get({
        url: `${environment.api.bracketSet}/templates?teamCount=${teamCount}`
    }).toPromise();

    return bracketTemplates;
  }

  saveBracketSetTemplate(data: BracketSetTemplate): Promise<any> {
    
    return new Promise((resolve, reject) => {
      this.httpService
        .post({
          url: `${environment.api.bracketSet}/templates`,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              resolve(res);
              this.toastService.toast(
                'Success!',
                `Bracket Saved Successfully.`,
                'success'
              );
            } else {
              reject(res);
              this.toastService.toast(
                'Failed',
                'Failed Saving Bracket',
                'error'
              );
            }
          },
          (_) => {
            // reject(false);
            this.toastService.toast('Failed', 'Something went wrong when saving the bracket', 'error');
          }
        );
    });
  }
}
