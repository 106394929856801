import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  innerWidth: any;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  goToFeatures(): void {
    this.router.navigate(['/features']);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  goToFaqs(): void {
    this.router.navigate(['/support']);
  }
  goToContactUs(): void {
    this.router.navigate(['/support']);
  }
  goToPrivacy(): void {
    this.router.navigate(['/privacy-policy']);
  }
}
