<div class="container">
  <div class="bg-white pb-3">
    <!-- <div
      class="venue-header"
      [ngStyle]="{ 'background-color': secondaryColor, color: fontColor }"
    > -->
    <div
      class="venue-header"
      [ngStyle]="{ 'background-color': 'black', color: 'white' }"
    >
      VENUES
    </div>
    <div class="venues">
      <div class="info">
        <div
          *ngFor="let venue of venues"
          class="venue-name"
          (click)="selectVenue(venue)"
        >
          <div
            class="fs-2"
            [class.venue-selected]="venue.id == selectedVenue?.id"
          >
            <div class="venue-link" (click)="openVenueSchedule(venue.id)">
              {{ venue.name }}
            </div>
            <div class="venue-address">{{ venue?.address.fullAddress }}</div>
            <div class="venue-additional">
              {{ venue?.additionalInformation }}
            </div>
          </div>
          <div class="pi pi-chevron-right"></div>
        </div>
      </div>
      <div class="display">
        <agm-map
          [latitude]="selectedVenue?.lat"
          [longitude]="selectedVenue?.lng"
          [zoom]="14"
          [disableDefaultUI]="false"
          [scrollwheel]="null"
        >
          <agm-marker
            [latitude]="selectedVenue?.lat"
            [longitude]="selectedVenue?.lng"
          ></agm-marker>
        </agm-map>
      </div>
    </div>
  </div>
</div>
