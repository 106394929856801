<div class="container">
  <div class="container-header">
    <div class="container-header-images">
      <img class="logo" src="../../../../assets/img/logo_only.svg" alt="" />
      <img
        class="text"
        src="../../../../assets/img/logo_white_text_only.svg"
        alt=""
      />
    </div>
    <div class="container-header-details">
      <h1>{{ teamName }}</h1>
      <h4>{{ teamCity }}</h4>
    </div>
  </div>

  <p-fieldset [toggleable]="true" [collapsed]="false">
    <p-header>Coaches</p-header>
    <div class="container-body" *ngIf="!isCoachesLoading">
      <div [formGroup]="coachesForm">
        <ng-container formArrayName="coaches">
          <div class="row">
            <div class="title p-col-1"></div>
            <div class="title p-col-3">First Name</div>
            <div class="title p-col-3">Last Name</div>
            <div class="title p-col-3">Email</div>
            <div class="title p-col-2">Phone Number</div>
          </div>
          <div *ngFor="let coachesForm of bulkCoaches.controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="input-container p-col-1 count">
                  {{ i + 1 }}
                </div>
                <div class="input-container p-col-3">
                  <input
                    type="text"
                    pInputText
                    formControlName="firstName"
                    class=""
                    maxlength="60"
                  />
                </div>
                <div class="input-container p-col-3">
                  <input
                    type="text"
                    pInputText
                    formControlName="lastName"
                    class=""
                    maxlength="60"
                  />
                </div>
                <div class="input-container p-col-3">
                  <input
                    type="text"
                    pInputText
                    formControlName="email"
                    class=""
                    maxlength="60"
                  />
                </div>
                <div class="input-container p-col-2">
                  <input
                    type="text"
                    pInputText
                    formControlName="phoneNumber"
                    class=""
                    maxlength="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-center my-4">
        <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
          CANCEL
        </button>

        <button
          type="submit"
          (click)="submitCoaches()"
          class="btn-rounded-red mx-2"
        >
          SAVE COACHES
        </button>
      </div>
    </div>
    <div class="container-spinner" *ngIf="isCoachesLoading">
      <p-progressSpinner
        [style]="{ width: '25px', height: '25px', color: 'white' }"
      ></p-progressSpinner>
    </div>
  </p-fieldset>

  <p-fieldset [toggleable]="true" [collapsed]="false">
    <p-header>Players</p-header>
    <div class="container-body" *ngIf="!isPlayersLoading">
      <div [formGroup]="playersForm">
        <ng-container formArrayName="players">
          <div class="row">
            <div class="title p-col-1"></div>
            <div class="title p-col-1">Jersey Number</div>
            <div class="title p-col-4">First Name</div>
            <div class="title p-col-4">Last Name</div>
            <div class="title p-col-2">Position</div>
          </div>
          <div *ngFor="let playerForm of bulkPlayers.controls; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="input-container p-col-1 count">
                  {{ i + 1 }}
                </div>
                <div class="input-container p-col-1">
                  <input
                    type="text center"
                    pInputText
                    formControlName="jerseyNumber1"
                    class=""
                    maxlength="3"
                  />
                </div>
                <div class="input-container p-col-4">
                  <input
                    type="text"
                    pInputText
                    formControlName="firstName"
                    class=""
                    maxlength="60"
                  />
                </div>
                <div class="input-container p-col-4">
                  <input
                    type="text"
                    pInputText
                    formControlName="lastName"
                    class=""
                    maxlength="60"
                  />
                </div>
                <div class="input-container p-col-2">
                  <input
                    type="text"
                    pInputText
                    formControlName="position"
                    class=""
                    maxlength="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-center my-4">
        <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
          CANCEL
        </button>

        <button type="submit" (click)="submit()" class="btn-rounded-red mx-2">
          SAVE PLAYERS
        </button>
      </div>
    </div>
    <div class="container-spinner" *ngIf="isPlayersLoading">
      <p-progressSpinner
        [style]="{ width: '25px', height: '25px', color: 'white' }"
      ></p-progressSpinner>
    </div>
  </p-fieldset>
</div>
