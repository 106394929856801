import {
  Component,
  ElementRef,
  OnInit,
  HostListener,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

// COMPONENTS
import { SignupComponent } from '@public-components/signup/signup.component';

// SERVICES
import {
  SessionStorageService,
  AuthService,
  HttpService,
  EmitterService,
  ToastService,
} from '@services/index';

// PRIME
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

// CONSTANT
import { defConstant, errConstant } from '@data/constant-data';

// ENV
import { environment } from '@env/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from '@core/models/user';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent implements OnInit {
  innerWidth: any;
  isLogged: boolean = false;
  isLoad: boolean = true;
  isAndroidDevice: boolean;
  isMobileNaviClicked = false;

  @ViewChild('opLogin') opLogin;
  @ViewChild('btnLoginWeb') btnLoginWeb: ElementRef;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private authService: AuthService,
    private httpService: HttpService,
    private emitterService: EmitterService,
    private toastService: ToastService,
    private sessionStorageService: SessionStorageService,
    public ref: DynamicDialogRef,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.authService.isLoggedInEmitter.subscribe((isLoggedIn) => {
      this.isLogged = isLoggedIn;
    });
  }
  openLogin() {
    this.emitterService.loginEmitter.emit(false);
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    // this.user = this.emitterService.userData;
    // if (!this.user) {
    //   this.httpService.me().subscribe(
    //     (user: User) => {
    //       this.user = user;
    //     },
    //     (err) => {}
    //   );
    // }

    this.isAndroidDevice = this.checkDevice();
    setTimeout((_) => {
      this.isLoad = false;
    }, 1000);

    this.emitterService.loginEmitter.subscribe((isDefault) => {
      if (!isDefault) this.opLogin.toggle(this.btnLoginWeb.nativeElement);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  checkDevice(): boolean {
    const userAgent = navigator.userAgent;
    return (
      /android/i.test(userAgent) ||
      /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
    );
  }

  signup(): void {
    this.ref = this.dialogService.open(SignupComponent, {
      width: defConstant.modalWidth,
      closable: false,
      contentStyle: { 'overflow-y': 'scroll' },
    });

    this.ref.onClose.subscribe((res) => {
      if (res == 'login') {
        let el: HTMLElement = this.btnLoginWeb.nativeElement;

        setTimeout(() => {
          el.click();
        }, 500);
      }
    });
  }

  loginButton(isTrigger: boolean) {
    if (isTrigger == true) {
      let el: HTMLElement = this.btnLoginWeb.nativeElement;

      setTimeout(() => {
        el.click();
      }, 500);
    }
  }

  async gotoDashboard() {
    this.ngxLoader.start();
    const me = await this.httpService.me().toPromise();

    console.log('%%%%me%%%%%', me);

    if (me.userType === 'director') {
      this.gotoDirectorDashboard(me);
    } else if (me.userType === 'coach') {
      this.gotoCoachDashboard();
    }
  }

  async gotoDirectorDashboard(me: any) {
    try {
      const org = await this.httpService
        .get({
          url: environment.api.org,
          payload: {
            userId: me.id,
          },
        })
        .toPromise();

      this.sessionStorageService.setSession('org', org);
      this.emitterService.orgEmit(org);

      if (org.completed) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/dashboard/organization-settings/info']);
        this.emitterService.pageNameEmit('Organization Settings');
      }
    } catch (err) {
      console.log(err);
      this.toastService.errorMsg({ message: errConstant.anErrOccurred });
    }
  }

  gotoCoachDashboard() {
    this.router.navigate(['/coach-dashboard']);
  }

  logout(): void {
    this.authService.logout();
  }

  goToBrowse(): void {
    this.router.navigate(['/browse-tournaments']);
  }
  mobileNavi(): void {
    this.isMobileNaviClicked = !this.isMobileNaviClicked;
  }

  private isEven(number: number): boolean {
    return number % 2 === 0;
  }

  goToFeatures(): void {
    this.router.navigate(['/features']);
  }

  gotoHome(): void {
    this.router.navigate(['']);
  }

  goToPricingPage(): void {
    this.router.navigate(['/pricing']);
  }

  goToSupportPage(): void {
    console.log('dddd');
    this.router.navigate(['/support']);
  }
}
