<div class="main-container" (mouseup)="mouseup($event)">
  <!-- RIGHT_FACING BRACKET -->
  <div
    *ngIf="bracket.bracketType == 'RIGHT_FACING'"
    #resizeBox
    class="container bracket-item"
    [id]="bracket.id"
    cdkDragBoundary=".container-bracket"
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    style="position: absolute"
    [style.width.px]="bracketWidth"
    [style.height.px]="bracketHeight"
    (cdkDragEnded)="dragEnded($event)"
    (cdkDragMoved)="dragData($event)"
    [cdkDragDisabled]="isDragDisabled"
  >
    <div *ngIf="!topTeam; else hasTopTeam" class="dp-left-right-row-top">
      <!-- <div *ngIf="!isPreview"> -->

      <p-dropdown
        (onChange)="onChangeTopOption($event)"
        [options]="mainOptions"
        [(ngModel)]="topSelectedOption"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <p-dropdown
        [options]="topSubOptions"
        [(ngModel)]="topSubSelected"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        (onChange)="onChangeTopSubOptions()"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <!-- </div> -->
    </div>

    <ng-template #hasTopTeam>
      <div class="dp-left-right-row-top">
        <div class="advanced-top-team-name">{{ topTeam }}</div>
        <i
          class="pi pi-times"
          style="cursor: pointer; margin-top: 4px; margin-left: 8px; color: red"
          (click)="onDeleteTopTeam()"
        ></i>
      </div>
    </ng-template>

    <div class="bracket-name">
      <div
        class="btn-settings-container"
        (click)="onEditBracket('RIGHT_FACING')"
      >
        <span #dragGearBtn class="dragHandle btn-settings">
          <i
            class="pi pi-cog"
            style="
              font-size: 12px !important;
              color: white !important;
              line-height: 0 !important;
            "
          ></i>
        </span>
      </div>
      <input
        maxlength="25"
        type="text"
        pInputText
        (mousedown)="dragMoveStatus(true)"
        [(ngModel)]="bracketName"
        (change)="onChangeName($event)"
      />
    </div>

    <span
      #dragDeleteBtn
      cdkDrag
      (cdkDragMoved)="dragMove(dragDeleteBtn, $event)"
      class="dragHandle btn-delete"
    >
      <i class="pi pi-trash" (click)="onDeleteBracket()"></i>
    </span>
    <span
      #dragHandleCorner
      class="dragHandle corner"
      cdkDrag
      *ngIf="bracket.bracketType == 'RIGHT_FACING'"
      (cdkDragMoved)="dragMove(dragHandleCorner, $event)"
    ></span>
    <span
      #dragHandleRight
      cdkDragLockAxis="x"
      class="dragHandle right"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleRight, $event)"
    ></span>
    <span
      #dragHandleBottom
      cdkDragLockAxis="y"
      class="dragHandle bottom"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleBottom, $event)"
    ></span>

    <div #box class="right-facing">
      <div *ngIf="!bottomTeam; else hasBottomTeam" class="dp-row-bottom">
        <p-dropdown
          (onChange)="onChangeBottomOption($event)"
          [options]="mainOptions"
          class="dp"
          [(ngModel)]="bottomSelectedOption"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          class="advanced-bottom-team-border container-dp"
          (itemSize)="(itemCount)"
        ></p-dropdown>
        <p-dropdown
          [options]="bottomSubOptions"
          [(ngModel)]="bottomSubSelected"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          (itemSize)="(itemCount)"
          (onChange)="onChangeBottomSubOptions()"
          class="advanced-bottom-team-border container-dp"
        ></p-dropdown>
      </div>

      <ng-template #hasBottomTeam>
        <div class="dp-row-bottom">
          <div class="advanced-bottom-team-name">{{ bottomTeam }}</div>
          <i
            class="pi pi-times"
            style="
              cursor: pointer;
              margin-top: 4px;
              margin-left: 8px;
              color: red;
            "
            (click)="onDeleteBottomTeam()"
          ></i>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- LEFT_FACING BRACKET -->
  <div
    *ngIf="bracket.bracketType === 'LEFT_FACING'"
    #resizeBox
    class="container bracket-item"
    [id]="bracket.id"
    cdkDragBoundary=".container-bracket"
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    style="position: absolute"
    [style.width.px]="bracketWidth"
    [style.height.px]="bracketHeight"
    (cdkDragEnded)="dragEnded($event)"
    (cdkDragMoved)="dragData($event)"
    [cdkDragDisabled]="isDragDisabled"
  >
    <div *ngIf="!topTeam; else hasTopTeam" class="dp-left-right-row-top">
      <!-- <div *ngIf="!isPreview"> -->
      <p-dropdown
        (onChange)="onChangeTopOption($event)"
        [options]="mainOptions"
        [(ngModel)]="topSelectedOption"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <p-dropdown
        [options]="topSubOptions"
        [(ngModel)]="topSubSelected"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        (onChange)="onChangeTopSubOptions()"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <!-- </div> -->
    </div>

    <ng-template #hasTopTeam>
      <div class="dp-left-right-row-top">
        <div class="advanced-top-team-name">{{ topTeam }}</div>
        <i
          class="pi pi-times"
          style="cursor: pointer; margin-top: 4px; margin-left: 8px; color: red"
          (click)="onDeleteTopTeam()"
        ></i>
      </div>
    </ng-template>

    <div class="bracket-name">
      <div class="btn-settings-container">
        <span
          #dragGearBtn
          cdkDrag
          (cdkDragMoved)="dragMove(dragGearBtn, $event)"
          class="dragHandle btn-settings"
        >
          <i
            class="pi pi-cog"
            (click)="onEditBracket('LEFT_FACING')"
            style="
              font-size: 12px !important;
              color: white !important;
              line-height: 0 !important;
            "
          ></i>
        </span>
      </div>
      <input
        maxlength="25"
        type="text"
        pInputText
        [(ngModel)]="bracketName"
        (change)="onChangeName($event)"
        (mousedown)="dragMoveStatus(true)"
      />
    </div>
    <span
      #dragDeleteBtn
      cdkDrag
      (cdkDragMoved)="dragMove(dragDeleteBtn, $event)"
      class="dragHandle btn-delete"
    >
      <i class="pi pi-trash" (click)="onDeleteBracket()"></i>
    </span>
    <span
      #dragHandleCorner
      class="dragHandle corner"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleCorner, $event)"
    ></span>
    <span
      #dragHandleRight
      cdkDragLockAxis="x"
      class="dragHandle right"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleRight, $event)"
    ></span>
    <span
      #dragHandleBottom
      cdkDragLockAxis="y"
      class="dragHandle bottom"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleBottom, $event)"
    ></span>

    <div #box class="left-facing">
      <div *ngIf="!bottomTeam; else hasBottomTeam" class="dp-row-bottom">
        <!-- <div *ngIf="!isPreview"> -->
        <p-dropdown
          (onChange)="onChangeBottomOption($event)"
          [options]="mainOptions"
          class="dp"
          [(ngModel)]="bottomSelectedOption"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          class="advanced-bottom-team-border container-dp"
          (itemSize)="(itemCount)"
        ></p-dropdown>
        <p-dropdown
          [options]="bottomSubOptions"
          [(ngModel)]="bottomSubSelected"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          (itemSize)="(itemCount)"
          (onChange)="onChangeBottomSubOptions()"
          class="advanced-bottom-team-border container-dp"
        ></p-dropdown>
        <!-- </div> -->
      </div>

      <ng-template #hasBottomTeam>
        <div class="dp-row-bottom">
          <div class="advanced-bottom-team-name">{{ bottomTeam }}</div>
          <i
            class="pi pi-times"
            style="
              cursor: pointer;
              margin-top: 4px;
              margin-left: 8px;
              color: red;
            "
            (click)="onDeleteBottomTeam()"
          ></i>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- MIDDLE BRACKET -->
  <div
    *ngIf="bracket.bracketType === 'MIDDLE'"
    #resizeBox
    class="container bracket-item"
    [id]="bracket.id"
    cdkDragBoundary=".container-bracket"
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    style="position: absolute"
    [style.width.px]="bracketWidth"
    [style.height.px]="bracketHeight"
    (cdkDragEnded)="dragEnded($event)"
    (cdkDragMoved)="dragData($event)"
    [cdkDragDisabled]="isDragDisabled"
  >
    <div *ngIf="!topTeam; else hasTopTeam" class="dp-row-middle-top">
      <!-- <div *ngIf="!isPreview"> -->
      <p-dropdown
        (onChange)="onChangeTopOption($event)"
        [options]="mainOptions"
        [(ngModel)]="topSelectedOption"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <p-dropdown
        [options]="topSubOptions"
        [(ngModel)]="topSubSelected"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        (onChange)="onChangeTopSubOptions()"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <!-- </div> -->
    </div>

    <ng-template #hasTopTeam>
      <div class="dp-row-middle-top">
        <div class="advanced-top-team-name">{{ topTeam }}</div>
        <i
          class="pi pi-times"
          style="cursor: pointer; margin-top: 4px; margin-left: 8px; color: red"
          (click)="onDeleteTopTeam()"
        ></i>
      </div>
    </ng-template>

    <div class="bracket-name-center flex">
      <div class="btn-settings-container">
        <span
          #dragGearBtn
          cdkDrag
          (cdkDragMoved)="dragMove(dragGearBtn, $event)"
          class="dragHandle btn-settings"
        >
          <i
            class="pi pi-cog"
            (click)="onEditBracket('MIDDLE')"
            style="
              font-size: 12px !important;
              color: white !important;
              line-height: 0 !important;
            "
          ></i>
        </span>
      </div>
      <input
        type="text"
        pInputText
        [(ngModel)]="bracketName"
        (change)="onChangeName($event)"
        (mousedown)="dragMoveStatus(true)"
        maxlength="25"
      />
    </div>

    <span
      #dragDeleteBtn
      cdkDrag
      (cdkDragMoved)="dragMove(dragDeleteBtn, $event)"
      class="dragHandle btn-delete"
    >
      <i class="pi pi-trash" (click)="onDeleteBracket()"></i>
    </span>
    <span
      #dragHandleCorner
      class="dragHandle corner"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleCorner, $event)"
    ></span>
    <span
      #dragHandleRight
      cdkDragLockAxis="x"
      class="dragHandle right"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleRight, $event)"
    ></span>
    <span
      #dragHandleBottom
      cdkDragLockAxis="y"
      class="dragHandle bottom"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleBottom, $event)"
    ></span>

    <div #box class="middle-bracket">
      <div *ngIf="!bottomTeam; else hasBottomTeam" class="dp-row-middle-bottom">
        <!-- <div *ngIf="!isPreview"> -->
        <p-dropdown
          (onChange)="onChangeBottomOption($event)"
          [options]="mainOptions"
          class="dp"
          [(ngModel)]="bottomSelectedOption"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          class="advanced-bottom-team-border container-dp"
          (itemSize)="(itemCount)"
        ></p-dropdown>
        <p-dropdown
          [options]="bottomSubOptions"
          [(ngModel)]="bottomSubSelected"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          (itemSize)="(itemCount)"
          (onChange)="onChangeBottomSubOptions()"
          class="advanced-bottom-team-border container-dp"
        ></p-dropdown>
        <!-- </div> -->
      </div>

      <ng-template #hasBottomTeam>
        <div class="dp-row-middle-bottom">
          <div class="advanced-bottom-team-name">{{ bottomTeam }}</div>
          <i
            class="pi pi-times"
            style="
              cursor: pointer;
              margin-top: 4px;
              margin-left: 8px;
              color: red;
            "
            (click)="onDeleteBottomTeam()"
          ></i>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- BRACKET WINNER BRACKET -->
  <div
    *ngIf="bracket.bracketType == 'BRACKET_WINNER'"
    #resizeBox
    class="container bracket-item"
    [id]="bracket.id"
    cdkDragBoundary=".container-bracket"
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    style="position: absolute"
    [style.width.px]="bracketWidth"
    [style.height.px]="bracketHeight"
    (cdkDragEnded)="dragEnded($event)"
    (cdkDragMoved)="dragData($event)"
    [cdkDragDisabled]="isDragDisabled"
  >
    <div *ngIf="!topTeam; else hasTopTeam" class="dp-row-top">
      <!-- <div *ngIf="!isPreview"> -->
      <p-dropdown
        (onChange)="onChangeTopOption($event)"
        [options]="mainOptions"
        [(ngModel)]="topSelectedOption"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <p-dropdown
        [options]="topSubOptions"
        [(ngModel)]="topSubSelected"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        (onChange)="onChangeTopSubOptions()"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <!-- </div> -->
    </div>

    <ng-template #hasTopTeam>
      <div class="dp-row-top">
        <div class="advanced-top-team-name">{{ topTeam }}</div>
        <i
          class="pi pi-times"
          style="cursor: pointer; margin-top: 4px; margin-left: 8px; color: red"
          (click)="onDeleteTopTeam()"
        ></i>
      </div>
    </ng-template>

    <div class="bracket-name">
      <div class="btn-settings-container">
        <span
          #dragGearBtn
          cdkDrag
          (cdkDragMoved)="dragMove(dragGearBtn, $event)"
          class="dragHandle btn-settings"
        >
          <i
            class="pi pi-cog"
            (click)="onEditBracket('LEFT_FACING')"
            style="
              font-size: 12px !important;
              color: white !important;
              line-height: 0 !important;
            "
          ></i>
        </span>
      </div>
      <input
        type="text"
        pInputText
        [(ngModel)]="bracketName"
        (change)="onChangeName($event)"
        (mousedown)="dragMoveStatus(true)"
        maxlength="25"
      />
    </div>
    <span
      #dragDeleteBtn
      cdkDrag
      (cdkDragMoved)="dragMove(dragDeleteBtn, $event)"
      class="dragHandle btn-delete"
    >
      <i class="pi pi-trash" (click)="onDeleteBracket()"></i>
    </span>
    <span
      #dragHandleCorner
      class="dragHandle corner"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleCorner, $event)"
    ></span>
    <span
      #dragHandleRight
      cdkDragLockAxis="x"
      class="dragHandle right"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleRight, $event)"
    ></span>
    <span
      #dragHandleBottom
      cdkDragLockAxis="y"
      class="dragHandle bottom"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleBottom, $event)"
    ></span>

    <div #box class="bracket-winner"></div>
  </div>
  <!-- BOX BRACKET -->
  <div
    *ngIf="bracket.bracketType == 'BOX'"
    #resizeBox
    class="container bracket-item"
    [id]="bracket.id"
    cdkDragBoundary=".container-bracket"
    cdkDrag
    [cdkDragFreeDragPosition]="dragPosition"
    style="position: absolute"
    [style.width.px]="bracketWidth"
    [style.height.px]="bracketHeight"
    (cdkDragEnded)="dragEnded($event)"
    (cdkDragMoved)="dragData($event)"
    [cdkDragDisabled]="isDragDisabled"
  >
    <div *ngIf="!topTeam; else hasTopTeam" class="dp-row-top">
      <!-- <div *ngIf="!isPreview"> -->
      <p-dropdown
        (onChange)="onChangeTopOption($event)"
        [options]="mainOptions"
        [(ngModel)]="topSelectedOption"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <p-dropdown
        [options]="topSubOptions"
        [(ngModel)]="topSubSelected"
        optionLabel="description"
        placeholder="Select..."
        [disabled]="isPreview ? true : false"
        (onChange)="onChangeTopSubOptions()"
        class="advanced-top-team-border container-dp"
      ></p-dropdown>
      <!-- </div> -->
    </div>

    <ng-template #hasTopTeam>
      <div class="dp-row-top">
        <div class="advanced-top-team-name">{{ topTeam }}</div>
        <i
          class="pi pi-times"
          style="cursor: pointer; margin-top: 4px; margin-left: 8px; color: red"
          (click)="onDeleteTopTeam()"
        ></i>
      </div>
    </ng-template>

    <div class="bracket-name">
      <div class="btn-settings-container">
        <span
          #dragGearBtn
          cdkDrag
          (cdkDragMoved)="dragMove(dragGearBtn, $event)"
          class="dragHandle btn-settings"
        >
          <i
            class="pi pi-cog"
            (click)="onEditBracket('BOX')"
            style="
              font-size: 12px !important;
              color: white !important;
              line-height: 0 !important;
            "
          ></i>
        </span>
      </div>
      <input
        type="text"
        pInputText
        [(ngModel)]="bracketName"
        (change)="onChangeName($event)"
        (mousedown)="dragMoveStatus(true)"
        maxlength="25"
      />
    </div>
    <span
      #dragDeleteBtn
      cdkDrag
      (cdkDragMoved)="dragMove(dragDeleteBtn, $event)"
      class="dragHandle btn-delete"
    >
      <i class="pi pi-trash" (click)="onDeleteBracket()"></i>
    </span>
    <span
      #dragHandleCorner
      class="dragHandle corner"
      cdkDrag
      *ngIf="bracket.bracketType == 'BOX'"
      (cdkDragMoved)="dragMove(dragHandleCorner, $event)"
    ></span>
    <span
      #dragHandleRight
      cdkDragLockAxis="x"
      class="dragHandle right"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleRight, $event)"
    ></span>
    <span
      #dragHandleBottom
      cdkDragLockAxis="y"
      class="dragHandle bottom"
      cdkDrag
      (cdkDragMoved)="dragMove(dragHandleBottom, $event)"
    ></span>

    <div #box class="box-bracket">
      <div *ngIf="!bottomTeam; else hasBottomTeam" class="dp-row-bottom">
        <!-- <div *ngIf="!isPreview"> -->
        <p-dropdown
          (onChange)="onChangeBottomOption($event)"
          [options]="mainOptions"
          class="dp"
          [(ngModel)]="bottomSelectedOption"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          class="advanced-bottom-team-border container-dp"
          (itemSize)="(itemCount)"
        ></p-dropdown>
        <p-dropdown
          [options]="bottomSubOptions"
          [(ngModel)]="bottomSubSelected"
          optionLabel="description"
          placeholder="Select..."
          [disabled]="isPreview ? true : false"
          (itemSize)="(itemCount)"
          (onChange)="onChangeBottomSubOptions()"
          class="advanced-bottom-team-border container-dp"
        ></p-dropdown>
        <!-- </div> -->
      </div>

      <ng-template #hasBottomTeam>
        <div class="dp-row-bottom">
          <div class="advanced-bottom-team-name">{{ bottomTeam }}</div>
          <i
            class="pi pi-times"
            style="
              cursor: pointer;
              margin-top: 4px;
              margin-left: 8px;
              color: red;
            "
            (click)="onDeleteBottomTeam()"
          ></i>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!-- <p-dialog header="Select Bracke Shape" [(visible)]="this.isOpenEditBracket" >
  <div class="container-bracketsets">
      <p-selectButton  class="p-grid " [options]="bracketTypes" [(ngModel)]="selectedBracketType">
        <div class="flex gap-4 justify-content-center align-items-center flex-column mt-4" *ngFor="let item of bracketTypes">
          <div style="display: block">
            <div *ngIf="item.value == 'MIDDLE'" class="middle-top-bracket-edit"></div>
            <div [ngClass]="{'right-facing-edit': item.value == 'RIGHT_FACING',
              'left-facing-edit': item.value == 'LEFT_FACING',
              'middle-bracket-edit': item.value == 'MIDDLE',
              'bracket-winner-edit': item.value == 'BRACKET_WINNER',
              'box-bracket-edit': item.value == 'BOX'}">
            </div>
            <div *ngIf="item.value == 'MIDDLE'" class="middle-bottom-bracket-edit"></div>
          </div>
          
            <h5>{{item.name}}</h5>
        </div>
  
      </p-selectButton>
      <div class="flex container-btn">
        <button class="btn-rounded-red btn-reset" (click)="onSubmitEditBracket()">
          Submit
        </button>
      </div>
  </div>
</p-dialog> -->
