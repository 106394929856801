import { GeneralInfoComponent } from '@admin/tournament/general-info/general-info.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GENDER_TYPES_FILTER } from '@core/models/gender-type';
import { Tournament } from '@core/models/tournament';
import { HttpService } from '@core/services';
import { CityStateLookupService } from '@core/services/city-state-lookup.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { getStates, State, USA_STATES } from '@core/utils/city-state';
import {
  faFilter,
  faTimes,
  faTable,
  faList,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { PublishModalComponent } from './publish-modal/publish-modal.component';
import { PublishTournamentComponent } from './publish-tournament/publish-tournament.component';
import moment from 'moment';
import { environment } from '@env/environment';

@Component({
  selector: 'app-public-browse',
  templateUrl: './public-browse.component.html',
  styleUrls: ['./public-browse.component.scss'],
})
export class PublicBrowseComponent implements OnInit {
  tournaments: Tournament[];
  faList = faList;
  faGrid = faTable;
  faFilter = faFilter;
  faSearch = faSearch;
  faTimes = faTimes;
  isFilter = false;
  isGrid = true;
  formFilter: FormGroup;
  state: State[];
  gender = GENDER_TYPES_FILTER;
  states = USA_STATES;
  cities: any[];
  ref: DynamicDialogRef;
  isLoading: boolean = false;
  isSearch: boolean = false;
  selectedStates: any;

  meta: any = {};

  constructor(
    private http: HttpService,
    private publicTournament: PublicTournamentsService,
    private fb: FormBuilder,
    private cityStateLookupService: CityStateLookupService,
    private dialogService: DialogService
  ) {
    this.formFilter = this.fb.group({
      states: [''],
      eventDate: [''],
      gender: [''],
      city: [''],
      state: [''],
      events: ['', Validators.required],
      type: ['', Validators.required],
      name: [''],
    });
    this.state = getStates();
  }

  ngOnInit(): void {
    this.initBrowseTournaments();
  }

  initBrowseTournaments(): void {
    this.isLoading = true;
    this.publicTournament
      .initPublicBrowseTournaments()
      .then((data) => {
        this.tournaments = data.items;
        this.meta = data.meta;

        // Sort the tournaments
        this.tournaments.sort((a, b) => {
          const startDateA = new Date(a.startDate).getTime();
          const startDateB = new Date(b.startDate).getTime();
          const endDateA = new Date(a.endDate).getTime();
          const endDateB = new Date(b.endDate).getTime();
          const currentDate = new Date().getTime();

          // If both tournaments are ongoing, sort by start date
          if (endDateA >= currentDate && endDateB >= currentDate) {
            return startDateA - startDateB;
          }
          // If one tournament is ongoing and the other is not, prioritize the ongoing one
          else if (endDateA >= currentDate) {
            return -1;
          } else if (endDateB >= currentDate) {
            return 1;
          }
          // If both tournaments have ended, sort by end date
          else {
            return endDateB - endDateA;
          }
        });

        this.isLoading = false;
      })
      .catch((e) => console.log('browse', e));
  }

  getBrowseTournament(
    action: string,
    meta: any,
    search = '',
    city = '',
    state = '',
    startDate = '',
    gender = ''
  ): void {
    this.isLoading = true;
    this.publicTournament
      .getPublicBrowseTournaments(
        action,
        meta,
        search,
        city,
        state,
        startDate,
        gender
      )
      .then((data: any) => {
        this.tournaments = data.items;
        this.meta = data.meta;
        this.isLoading = false;
      })
      .catch((e) => console.log('browse', e));
  }

  searchState(evt) {
    this.cityStateLookupService.getStateResults(evt.query).then((data) => {
      this.state = data;
    });
  }

  searchCity($event) {
    this.http
      .get({
        url: `${environment.api.tournamentCities}?city=${$event.query}`,
      })
      .toPromise()
      .then(
        (res: any) => {
          this.cities = res.flat().map(({ name }) => name);
        },
        (err) => {
          console.log('err: ', err);
        }
      );
  }

  searchTournament() {
    const formValue = this.formFilter.value;

    console.log('SEARCH ITO: ', formValue);

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';

    this.isLoading = true;
    this.isSearch = true;
    this.publicTournament
      .initPublicBrowseTournaments(
        formValue.name ?? '',
        formValue.city ?? '',
        formValue.state ?? '',
        eventDate,
        formValue.gender ?? '',
        'search'
      )
      .then((res) => {
        this.tournaments = res.items;
        this.meta = res.meta;
        this.isLoading = false;
      })
      .catch((e) => console.log('browse', e));
  }

  viewList(): void {
    this.isGrid = false;
  }
  viewGrid(): void {
    this.isGrid = true;
  }

  openFilter(): void {
    this.isFilter = !this.isFilter;
  }

  resetFilter(): void {
    this.formFilter.reset();
    this.initBrowseTournaments();
  }

  async publishTournament() {
    const me = await this.http.me().toPromise();
    if (me?.userType === 'director') {
      this.ref = this.dialogService.open(PublishTournamentComponent, {
        header: 'PUBLISH TOURNAMENT',
        width: '1100px',
        dismissableMask: true,
        closable: false,
      });

      this.ref.onClose.subscribe((res) => {
        this.initBrowseTournaments();
      });
    } else {
      this.ref = this.dialogService.open(PublishModalComponent, {
        width: '750px',
        dismissableMask: true,
        closable: false,
      });

      this.ref.onClose.subscribe((res) => {
        if (me?.userType === 'director') {
          this.ref = this.dialogService.open(PublishTournamentComponent, {
            header: 'PUBLISH TOURNAMENT',
            width: '1100px',
            dismissableMask: true,
            closable: false,
          });
        } else {
          this.ref.close(1);
        }
      });
    }
  }

  previous() {
    const formValue = this.formFilter.value;

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';
    this.getBrowseTournament(
      this.isSearch ? 'search-previous' : 'previous',
      this.meta,
      formValue.name ?? '',
      formValue.city ?? '',
      formValue.state ?? '',
      eventDate,
      formValue.gender ?? ''
    );
  }

  navigate(segment: number) {
    let meta: any = Object.assign({}, this.meta);
    meta.index = segment;
    const formValue = this.formFilter.value;

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';
    this.getBrowseTournament(
      this.isSearch ? 'search-index' : 'index',
      meta,
      formValue.name ?? '',
      formValue.city ?? '',
      formValue.state ?? '',
      eventDate,
      formValue.gender ?? ''
    );
  }

  next() {
    const formValue = this.formFilter.value;

    const eventDate = formValue.eventDate
      ? moment(formValue.eventDate).format('YYYYMMDD')
      : '';
    this.getBrowseTournament(
      this.isSearch ? 'search-next' : 'next',
      this.meta,
      formValue.name ?? '',
      formValue.city ?? '',
      formValue.state ?? '',
      eventDate,
      formValue.gender ?? ''
    );
  }
}
