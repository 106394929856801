<div class="container-public">
  <div class="p-col-12 no-padding">
    <app-public-header></app-public-header>
    <div class="p-banner">
      <div class="banner-left">
        <h1>Premier Tournament Management</h1>
        <p class="fs-3">
          We are coaches, referees and managers who demanded more out of our
          tournament management solution.
        </p>
        <p class="disp-block fs-3">So we built one from the ground up.</p>
        <!-- <button class="btn-rounded-red fs-normal" type="button">
          Try the Demo
        </button> -->
      </div>

      <div class="banner-right">
        <img src="../../../../assets/img/home-image.jpg" />

        <div class="btn-bottom">
          <a [routerLink]="['/browse-tournaments']">
            <button class="btn-rounded-red">View Tournament Listings</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 no-padding">
    <app-public-features></app-public-features>
  </div>
  <div class="p-col-12 no-padding">
    <app-public-featured-tournaments> </app-public-featured-tournaments>
  </div>
  <div class="p-col-12 no-padding">
    <app-footer></app-footer>
  </div>
</div>

<p-dialog
  header="Reset Password"
  [(visible)]="isResetDialog"
  [modal]="false"
  [dismissableMask]="true"
  appendTo="body"
>
  <app-forgot-password></app-forgot-password>
</p-dialog>
