<div class="p-grid p-nogutter admin-wrapper">
  <aside class="p-col-2">
    <app-coach-sidebar></app-coach-sidebar>
  </aside>
  <div class="p-col-10 bg-light-gray1 pos-relative">
    <header >
      <app-coach-header></app-coach-header>
    </header>
    <main class="p-5" style="height: inherit;">
      <p-card>
        <router-outlet></router-outlet>
      </p-card>
    </main>
  </div>
</div>
