import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent {
  deleteEvent: EventEmitter<void> = new EventEmitter<void>();
  display: boolean = false;
  user: any;

  showModal(data: any) {
    this.user = data;
    this.display = true;
  }

  hideModal() {
    this.display = false;
  }

  deleteData() {
    // Add your delete team logic here
    console.log(`Deleting team ${this.user.name}`);
    this.deleteEvent.emit();
    this.hideModal();
  }
}
