import { Component, OnInit } from '@angular/core';
import { BracketService } from '@core/services/bracket.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-clear-all-brackets',
  templateUrl: './clear-all-brackets.component.html',
  styleUrls: ['./clear-all-brackets.component.scss'],
})
export class ClearAllBracketsComponent implements OnInit {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private bracketService: BracketService
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.ref.close(false);
  }

  onSubmit() {
    const data = this.config.data;
    this.bracketService.deleteBracketSet(data.id).then((x) => {
      this.ref.close(true);
    });
  }
}
