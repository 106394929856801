import { Component, OnInit } from '@angular/core';

// PRIME
import {
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-edit-score',
  templateUrl: './edit-score.component.html',
  styleUrls: ['./edit-score.component.scss'],
})
export class EditScoreComponent implements OnInit {
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close(false);
  }

  onSubmit(): void {
    this.ref.close(true);
  }
}
