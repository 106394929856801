export const SidebarMenu = [
  {
    name: 'Organizations',
    list: [
      {
        name: 'Dashboard', //former overview//
        path: 'overview',
        icon: 'pi-home',
      },
      {
        name: 'Tournaments',
        path: 'tournament-list',
        icon: 'pi-sitemap',
      },
      {
        name: 'Organization Settings',
        path: 'organization-settings',
        icon: 'pi-sliders-h',
      },
      // {
      //   name: 'Venues',
      //   path: 'organization-venues',
      //   icon: 'pi-map-marker',
      // },
      // {
      //   name: 'Referee Scheduler',
      //   path: 'referee-scheduler',
      //   icon: 'pi-calendar',
      // },
      {
        name: 'Payment',
        path: 'payment',
        icon: 'pi-wallet',
      },
      // {
      //   name: 'Organization Website',
      //   path: 'website',
      //   icon: 'pi-globe',
      // },
      // {
      //   name: ' Public Results',
      //   path: 'public-results',
      //   icon: 'pi-chart-line',
      // },
      {
        name: " Coach's Check-In",
        path: 'check-in',
        icon: 'pi-user-edit',
      },

      // {
      //   name: 'Divisions',
      //   path: 'setup-divisions',
      //   icon: 'pi-table'
      // },
    ],
  },
];
