import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Games } from '@core/models/games';

import { PoolService } from '@core/services/pool.service';
import { TeamsService } from '@core/services/teams.service';
import { ToastService } from '@core/services';

@Component({
  selector: 'add-time-modal',
  templateUrl: './add-time-modal.component.html',
  styleUrls: ['./add-time-modal.component.scss'],
})

export class AddTimeModalComponent implements OnInit {

  whereToAdd:any;
  blocks:number;

  whereOptions:any = [];
  blockOptions:any = [];
  
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService,
  ) {}

  async ngOnInit(): Promise<void> {

    this.whereOptions = [
      {name: 'Before Earliest Schedule', value: 0},
      {name: 'After Latest Schedule', value: 1},
    ];
  
    this.blockOptions = [
      {name: '1', value: 1},
      {name: '2', value: 2},
      {name: '3', value: 3},
      {name: '4', value: 4},
    ]

    this.whereToAdd = this.whereOptions[0];
    this.blocks = this.blockOptions[0];
  }

  cancel() {
    this.ref.close();
  }

  onSubmit() {
    
    this.ref.close({whereToAdd: this.whereToAdd, blocks: this.blocks});
  }
}
