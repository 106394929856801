import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { defConstant } from '@core/data/constant-data';
import { Organization } from '@core/models/organization';
import { User } from '@core/models/user';
import { AuthService, EmitterService, HttpService } from '@core/services';
import { environment } from '@env/environment';
import { log } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss'],
})
export class StaffComponent implements OnInit {
  mode: MatDrawerMode = 'side';
  isChangePassowrd: boolean;
  currentVersion = defConstant.currentVersion;
  pageName: string = 'Dashboard'; // former overview
  org: Organization;
  user: User;
  @ViewChild('drawer') public drawer: MatDrawer;
  isOpen = true;
  private dr: MatDrawer;
  isChange = false;
  constructor(
    private authService: AuthService,
    private emitterService: EmitterService,
    private httpService: HttpService,
    private auth: AuthService,
    private ngxLoader: NgxUiLoaderService,
    private router: Router
  ) {
    this.dr = this.drawer;
    this.router.events.subscribe((val) => {
      const currentUrl = this.router.url;
      const parts = currentUrl.split('/', 3);
      const currentPage = parts[2];

      switch (currentPage) {
        case 'tournament-list': {
          this.pageName = 'Tournaments';
          break;
        }
        case 'organization-settings': {
          this.pageName = 'Organization Settings';
          break;
        }
        case 'organization-venues': {
          this.pageName = 'Venues';
          break;
        }
        case 'payment': {
          this.pageName = 'Payment';
          break;
        }
        case 'check-in': {
          this.pageName = "Coach's Check-in";
          break;
        }
        default: {
          this.pageName = 'Dashboard'; // former overview
          break;
        }
      }
    });
  }

  public get width() {
    return window.innerWidth;
  }
  toggle(e) {
    this.isOpen = !this.isOpen;
  }
  backdropClick() {
    this.isOpen = !this.isOpen;
  }
  ngOnInit(): void {
    this.getData();
    // console.log('=======================================');
    // console.log(this.router.url);
    // console.log('=======================================');
  }

  onResize(e) {
    if (e.target.innerWidth < 950) {
      this.isChange = true;
    } else {
      this.isChange = false;
    }
  }

  changePassword() {
    this.auth.isShowChangePass$.next(true);
  }
  async getData() {
    const userData = this.emitterService.userData;
    if (userData) {
      this.user = userData;
      this.ngxLoader.stop();
    } else {
      this.user = await this.httpService.me().toPromise();
      this.ngxLoader.stop();
    }

    const orgData = this.emitterService.orgData;
    if (orgData?.name) {
      this.org = orgData;
    } else {
      this.org = await this.httpService
        .get({
          url: environment.api.org,
          payload: {
            userId: this.user.id,
          },
        })
        .toPromise();
    }
  }

  logout(): void {
    this.authService.logout();
  }
}
