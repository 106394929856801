<div class="bg-white pb-3 main">
  <!-- <div
    class="sched-header"
    [ngStyle]="{ 'background-color': secondaryColor, color: fontColor }"
  > -->
  <div
    class="sched-header"
    [ngStyle]="{ 'background-color': 'black', color: 'white' }"
  >
    <span>SCHEDULE</span>
  </div>
  <p-card>
    <div class="p-col-12">
      <div class="filter-text">
        <span
          >Choose a division or search for a specific team, player or
          venue</span
        >
      </div>
      <div
        [class.filter-container-all]="filterOptions == 'all'"
        [class.filter-container]="filterOptions != 'all'"
      >
        <div class="filters">
          <p-dropdown
            class="main-filter"
            [options]="filterOptions"
            [(ngModel)]="filterOption"
            (onChange)="onSelectFilter()"
            [style]="{
              width: isScreenSmall ? '135px' : '150px'
            }"
          >
          </p-dropdown>
          <p-dropdown
            class="main-filter"
            [options]="filterSubOptions"
            [(ngModel)]="filterSubOption"
            (onChange)="onSelectSubFilter()"
            [disabled]="filterOption == 'all'"
            [style]="{ width: isScreenSmall ? '135px' : '150px' }"
            [dropdownIcon]="
              isFilterSubOptionsLoading
                ? 'pi pi-spinner pi-spin'
                : 'pi pi-chevron-down'
            "
          >
          </p-dropdown>
        </div>
      </div>
    </div>
    <div *ngIf="isFetching; else alreadyFetched" class="text-center p-3">
      <p-progressSpinner
        [style]="{ width: '40px', height: '40px' }"
      ></p-progressSpinner>
    </div>

    <ng-template #alreadyFetched>
      <div *ngIf="games; else noData">
        <ng-container *ngIf="switch == 'all'">
          <div class="py-4 px-4" *ngFor="let schedule of schedules">
            <div class="sched-details-wrapper-all sub-main">
              <div
                class="sched-date-all TheBoldFont-Bold"
                [style]="{ top: isScreen850Below ? '63px' : '' }"
                *ngIf="schedule.date"
              >
                {{ schedule.date | date : "EEEE - MMMM d, y" }}
              </div>
              <div class="header-container" *ngIf="!isScreen850Below">
                <div class="schedule-header">
                  <div class="division" style="width: 19%">Division</div>
                  <div class="game-number" style="width: 10%">Game</div>
                  <div class="sched-court" style="width: 19%">Location</div>
                  <div class="pool-games" style="width: 26%">
                    <span class="team-location">Away Team</span>
                    <span class="team-location">Home Team</span>
                  </div>
                  <div class="game-score text-center" style="width: 13%">
                    Score
                  </div>
                  <div class="game-status text-center" style="width: 13%">
                    Status
                  </div>
                </div>
                <hr />
              </div>
              <div *ngFor="let timeslot of sortTimeslots(schedule.timeslots)">
                <div class="TheBoldFont-Bold sched-time" *ngIf="timeslot.time">
                  {{ timeslot.time }}
                </div>
                <div
                  [class]="
                    isScreen850Below ? 'schedules-main-container' : 'schedules'
                  "
                >
                  <div
                    *ngFor="
                      let game of sortGames(timeslot.games);
                      let i = index
                    "
                    [ngClass]="
                      isScreen450Below || !isScreen850Below
                        ? 'p-col-12'
                        : 'p-col-6'
                    "
                  >
                    <div class="schedule-container" *ngIf="!isScreen850Below">
                      <div
                        class="division"
                        style="width: 19%"
                        (click)="openDivision(game.division)"
                      >
                        {{ game.division.name }}
                      </div>
                      <div class="sched-court" style="width: 10%">
                        <div class="court">{{ getGameNumber(game?.name) }}</div>
                        <div class="venue">{{ game.pool?.name }}</div>
                      </div>
                      <div
                        class="sched-court"
                        style="width: 19%"
                        (click)="openVenueSchedule(game.court?.venueId)"
                      >
                        <div class="court">{{ game.court?.name }}</div>
                        <div class="venue">{{ game.court?.venueName }}</div>
                      </div>
                      <div style="width: 26%">
                        <div
                          class="pool-games"
                          *ngIf="game.team1 && game.team2"
                        >
                          <div
                            class="team-name"
                            (click)="openTeam(game?.team1, game?.division?.id)"
                          >
                            {{ game.team1?.name }}
                          </div>
                          <div class="versus">vs</div>
                          <div
                            class="team-name"
                            (click)="openTeam(game?.team2, game?.division?.id)"
                          >
                            {{ game.team2?.name }}
                          </div>
                        </div>
                        <ng-template #bracketGame>
                          <div class="bracket-games">
                            <label class="px-2">{{ game?.name }}</label>
                          </div>
                        </ng-template>
                      </div>
                      <div *ngIf="game.team1 && game.team2" style="width: 13%">
                        <div class="pool-score">
                          <div class="px-2 text-center">
                            <div class="div-p-col-6 team-info text-center">
                              <span class="team-score text-center">{{
                                game.team1Score == 0 ? "" : game.team1Score
                              }}</span>
                            </div>
                          </div>
                          <div class="versus">—</div>
                          <div class="px-2 text-center">
                            <div class="div-p-col-6 team-info text-center">
                              <span class="team-score text-center">{{
                                game.team2Score == 0 ? "" : game.team2Score
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="game.gameEnded; else notFinal"
                        class="game-number text-center"
                        style="width: 13%"
                      >
                        Final
                      </div>
                      <ng-template #notFinal style="width: 13%">
                        <div
                          *ngIf="game.period == null || ''; else gamePeriod"
                          class="game-number text-center"
                        ></div>
                        <ng-template #gamePeriod style="width: 13%">
                          <div class="game-number text-center">
                            {{ game.period }}
                          </div>
                        </ng-template>
                      </ng-template>
                    </div>
                    <div
                      class="schedule-container-grid-view"
                      *ngIf="isScreen850Below"
                    >
                      <div
                        class="division"
                        (click)="openDivision(game.division)"
                      >
                        {{ game.division.name }}
                      </div>
                      <div class="sched-court">
                        <div class="court">{{ getGameNumber(game?.name) }}</div>
                        <div class="venue">{{ game.pool?.name }}</div>
                      </div>
                      <div
                        *ngIf="game.gameEnded; else notFinal"
                        class="game-number finals"
                      >
                        Final
                      </div>
                      <ng-template #notFinal>
                        <div
                          *ngIf="game.period == null || ''; else gamePeriod"
                          class="game-number text-center"
                        ></div>
                        <ng-template #gamePeriod>
                          <div class="game-number text-center">
                            {{ game.period }}
                          </div>
                        </ng-template>
                      </ng-template>
                      <div class="pool-score" *ngIf="game.team1 && game.team2">
                        <div class="score">
                          <span class="team-score text-center">{{
                            game.team1Score == 0 ? "" : game.team1Score
                          }}</span>
                        </div>
                        <div class="score">
                          <span class="team-score text-center">{{
                            game.team2Score == 0 ? "" : game.team2Score
                          }}</span>
                        </div>
                      </div>
                      <div class="pool-games" *ngIf="game.team1 && game.team2">
                        <div class="team-info">
                          <div
                            class="team-name"
                            (click)="openTeam(game?.team1, game?.division?.id)"
                          >
                            {{ game.team1?.name }}
                          </div>
                        </div>
                        <div class="versus">vs</div>
                        <div class="team-info">
                          <div
                            class="team-name"
                            (click)="openTeam(game?.team2, game?.division?.id)"
                          >
                            {{ game.team2?.name }}
                          </div>
                        </div>
                      </div>
                      <ng-template #bracketGame>
                        <div class="bracket-games">
                          <label class="px-2">{{ game?.name }}</label>
                        </div>
                      </ng-template>

                      <div
                        class="sched-court"
                        (click)="openVenueSchedule(game.court?.venueId)"
                      >
                        <div class="court">{{ game.court?.name }}</div>
                        <div class="venue">{{ game.court?.venueName }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="switch == 'division'">
          <div class="game-row">
            <div *ngFor="let division of divisions; let i = index">
              <p-card
                styleClass="game-card"
                (click)="openDivision(division)"
                (mouseenter)="isHovered = true"
                (mouseleave)="isHovered = false"
                [style]="{
                  cursor: 'pointer',
                  width: '20vw',
                  margin: '.5em',
                  padding: '25px 10px 25px 10px',
                  backgroundColor: isHovered ? '#f0f0f0' : '',
                  transition: 'background-color 0.3s ease',
                  textAlign: 'center',
                  color: 'black',
                  'min-width': '15rem'
                }"
              >
                <div class="card-header" style="max-width: 100%">
                  {{ division.name }}
                </div>
                <div>
                  <div>
                    <div>
                      <div class="div-teams">
                        {{ division.registeredTeams }} Teams
                      </div>
                      <div class="games-played text-bold1">
                        Games played at:
                      </div>
                      <div class="venues">
                        <ng-container
                          *ngIf="
                            uniqueVenuesByDivision[division.id]?.length > 0;
                            else noVenue
                          "
                        >
                          <ng-container
                            *ngFor="
                              let uniqueVenueName of uniqueVenuesByDivision[
                                division.id
                              ];
                              let i = index;
                              let isLast = last
                            "
                          >
                            <ng-container
                              *ngIf="
                                i <
                                  uniqueVenuesByDivision[division.id].length -
                                    2;
                                else lastVenue
                              "
                            >
                              {{ uniqueVenueName }},
                            </ng-container>
                            <ng-template #lastVenue>
                              <ng-container
                                *ngIf="
                                  i ===
                                  uniqueVenuesByDivision[division.id].length - 2
                                "
                              >
                                {{ uniqueVenueName }} &
                              </ng-container>
                              <ng-container *ngIf="isLast">
                                {{ uniqueVenueName }}
                              </ng-container>
                            </ng-template>
                          </ng-container>
                        </ng-container>
                        <ng-template #noVenue>TBD</ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
            </div>
          </div>
        </ng-container>

        <div class="text-center py-4" *ngIf="schedules.length < 1">
          <label class="TheBoldFont-Bold fs-3">No schedule available.</label>
        </div>

        <!-- <ng-container
          *ngIf="filterOption != 'division' && filterOption != 'all'"
        >
          <div class="py-2 px-4" *ngFor="let schedule of schedules">
            <div class="sched-details-wrapper">
              <div class="sched-date TheBoldFont-Bold">
                {{ schedule.date | date : "EEEE - MMMM d, y" }}
              </div>

              <div *ngFor="let timeslot of sortTimeslots(schedule.timeslots)">
                <div class="TheBoldFont-Bold sched-time">
                  <fa-icon
                    [icon]="faBasketballBall"
                    style="color: red; padding-right: 0.5em"
                  ></fa-icon>
                  {{ timeslot.time }}
                </div>

                <div class="game-row">
                  <div *ngFor="let game of timeslot.games; let i = index">
                    <p-card
                      class="game-card"
                      (click)="openDivision(game.division)"
                      (mouseenter)="isHovered = true"
                      (mouseleave)="isHovered = false"
                      [style]="{
                        cursor: 'pointer',
                        width: '20rem',
                        height: '12rem',
                        margin: '.5em',
                        padding: '10px',
                        backgroundColor: isHovered ? '#f0f0f0' : '',
                        transition: 'background-color 0.3s ease',
                        color: 'black',
                        overflow: 'hidden'
                      }"
                    >
                      <div class="card-header" style="max-width: 100%">
                        {{ game.division.name }}
                      </div>
                      <div style="max-width: 100%">Champion: N/A</div>
                      <div>
                        <div *ngIf="game.team1 && game.team2">
                          <div
                            class="sched-court"
                            (click)="openVenueSchedule(game.court.venueId)"
                          >
                            <div class="court">{{ game.court?.name }}</div>
                            <div class="venue">{{ game.court?.venueName }}</div>
                          </div>
                          <div class="container-footer card-gender-fee">
                            <div
                              class="sched-team px-2 text-center"
                              style="text-overflow: ellipsis"
                              (click)="openTeam(game.team1)"
                            >
                              {{ game.team1?.name }}
                            </div>
                            <div class="versus">vs</div>
                            <div
                              class="sched-team px-2 text-center"
                              style="text-overflow: ellipsis"
                              (click)="openTeam(game.team2)"
                            >
                              {{ game.team2?.name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </ng-container> -->
      </div>

      <ng-template #noData>
        <div class="text-center">
          <label class="TheBoldFont-Bold fs-3"
            >No schedules for this tournament.</label
          >
        </div>
      </ng-template>
    </ng-template>
  </p-card>
</div>
