import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

// MODELS
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import { Games } from '@core/models/games';

// SERVICES
import {
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
  AuthService,
} from '@core/services';
import { CoachTeamsService } from '@core/services/coach-teams.service';

// ENV
import { NgxUiLoaderService } from 'ngx-ui-loader';
import moment from 'moment';
import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
  providers: [DatePipe],
})
export class SchedulesComponent implements OnInit {
  teams: Teams[];
  user: User;

  games: Games[];
  date;

  myDate = new Date();

  constructor(
    private emitterService: EmitterService,
    private toastService: ToastService,
    private httpService: HttpService,
    private ngxLoader: NgxUiLoaderService,
    private coachTeamsService: CoachTeamsService,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.emitterService.meEmitter.subscribe((user) => {
      this.user = user;
      console.log(user.id);
      this.ngxLoader.stop();
    });
    this.date = this.datePipe.transform(this.myDate, 'YYYYMMdd');
    console.log(this.date);
  }

  ngOnInit(): void {
    this.getCoachGames();
  }

  async getCoachGames() {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    this.httpService
      .get({
        // url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/games/coach?coachId=${userId}&date=${this.date}`,
        url: `http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/games/coach?coachId=${userId}&date=${this.date}`,
      })
      .subscribe((games) => {
        this.games = games;
        console.log(games);
      });
  }
}
