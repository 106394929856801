import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import {
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
  AuthService,
} from '@core/services';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { User } from '@core/models/user';
import { environment } from '@env/environment';

import { Teams } from '@core/models/teams';
import { GENDER_TYPES, GenderType } from '@core/models/gender-type';
import {
  GRADE_GROUP_TYPES,
  GradeGroupType,
} from '@core/models/tournament-grade-group';
import {
  AGE_GROUP_TYPES,
  AgeGroupType,
} from '@core/models/tournament-age-group';
import { isThisTypeNode } from 'typescript';
import { defStates } from '@core/data/constant-data';

@Component({
  selector: 'app-add-team',
  templateUrl: './add-team.component.html',
  styleUrls: ['./add-team.component.scss'],
})
export class AddTeamComponent implements OnInit {
  genderTypes: GenderType[] = GENDER_TYPES;
  gradeGroupTypes: any[] = GRADE_GROUP_TYPES;
  ageGroupTypes: any[] = AGE_GROUP_TYPES;
  gradeGroup = GRADE_GROUP_TYPES;
  ageGroup = AGE_GROUP_TYPES;
  selectedGroups: any;
  selectedAges: any;
  coach: User;
  user: User;
  gender: any;
  logoUrl: any;
  teamForm: FormGroup;
  teams: Teams[];
  isAgeGroup = false;
  isGradeGroup = true;

  states = defStates;

  // form state
  loading = false;
  success = false;

  constructor(
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private fb: FormBuilder,
    private helper: HelperService,
    private httpService: HttpService,
    private toastService: ToastService,
    private authService: AuthService,
    private emitterService: EmitterService,
    private ngxLoader: NgxUiLoaderService,
    private coachTeamsService: CoachTeamsService
  ) {
    this.teamForm = this.fb.group({
      id: [''],
      base64Photo: [''],
      city: [''],
      state: [''],
      photo: [''],
      name: ['', [Validators.required]],
      abbreviation: [''],
      gender: ['M',],
      gradeGroup: [null],
      ageGroup: [null],
      teamPrivate: [true],
      coach: [{}],
      group: new FormControl(
        { value: 'GRADE', disabled: false },
        Validators.required
      ),
    });

    this.teamForm.valueChanges.subscribe((form) => {
      if (form.group === 'AGE') {
        this.isAgeGroup = true;
        this.isGradeGroup = false;
      } else if (form.group === 'GRADE') {
        this.isAgeGroup = false;
        this.isGradeGroup = true;
      } else {
        this.isAgeGroup = false;
        this.isGradeGroup = false;
      }

      console.log('isAgreGroup', this.isAgeGroup);
    });
  }

  ngOnInit(): void {
    this.getUser();
    this.getTeam();
  }

  async getTeam(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    console.log(userId);
    this.coachTeamsService.getTeams(userId).subscribe((teams) => {
      this.teams = teams;
      console.log(teams);
    });
  }

  async getUser() {
    const userData = this.emitterService.userData;
    if (userData) {
      this.user = userData;
      this.teamForm.controls['coach'].setValue(userData);
      this.ngxLoader.stop();
    } else {
      this.user = await this.httpService.me().toPromise();
      this.ngxLoader.stop();
    }
  }

  cancel(): void {
    this.ref.close();
  }

  async onSelectLogo(event) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        const newBase64 = readFile.split(',')[1];
        this.teamForm.get('base64Photo').setValue(newBase64);
      }
    }
    this.logoUrl = '';

    const newFile = event.currentFiles[0];

    this.helper.fileReader(newFile).then(
      (res) => {
        this.logoUrl = res;
        setTimeout((_) => {}, 1000);
      },
      (err) => {}
    );
  }

  async upload(event) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        console.log(event);
        const newBase64 = readFile.split(',')[1];
        this.httpService
          .post({
            url: `${environment.api.teams}/logo/base64`,
            payload: {
              id: '0',
              base64Photo: newBase64,
            },
          })
          .subscribe(
            (data) => {
              this.toastService.successMsg({
                message: 'Successfully uploaded',
              });
              this.logoUrl = `${environment.api.teams}/logo/${data.base64Photo}`;
              console.log(this.logoUrl);
            },
            (err) => {
              this.toastService.errorMsg({ message: err.error.message });
            }
          );
      }
    }
  }

  submitHandler() {
    this.loading = true;
    const formValue = this.teamForm.value;
    console.log('formValue: ', formValue);
    this.coachTeamsService.addTeam(formValue).then((data) => {
      this.teams.push(data);
      console.log(this.teams);
      this.ref.close();
    });

  }
}
