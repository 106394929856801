import { Injectable } from '@angular/core';
import { Organization } from '@core/models/organization';
import { Tournament } from '@core/models/tournament';
import { Venue } from '@core/models/venue';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';
import { TournamentsService } from './tournaments.service';

@Injectable({
  providedIn: 'root',
})
export class VenueService {
  orgId: number;
  venueId: number;
  tournamentId: number;
  org: Organization;
  data: Venue;
  tournaments: Tournament;
  venues$ = new BehaviorSubject<Venue[]>(null);
  venue$ = new BehaviorSubject<Venue>(null);
  venuesGlobal$ = new BehaviorSubject<Venue[]>(null);
  updateVenue$ = new BehaviorSubject<Venue>(null);
  potentialVenues$ = new BehaviorSubject<any[]>(null);
  sureGameVenues$ = new BehaviorSubject<any[]>([]);
  venuesDuplicate$ = new BehaviorSubject<any[]>([]);
  selectedVenue = new BehaviorSubject<Venue>(null);

  constructor(
    private tournamentService: TournamentsService,
    private http: HttpService,
    private toast: ToastService
  ) {}

  initAllId() {
    this.tournamentId = +this.tournamentService.getSessionTournamentId();
    this.orgId = +this.tournamentService.getSessionOrganization();
    this.org = this.tournamentService.getSessionOrg();
    this.getTournamentVenues(this.tournamentId);
  }

  getTournamentVenues(id: number) {
    try {
      this.http
        .get({
          url: `${environment.api.venues}?tournamentId=${id}`,
          payload: [],
        })
        .toPromise()
        .then((v) => {
          this.venues$.next(v);
        });
    } catch (e) {
      return null;
    }
  }

  getSpecificVenue(id) {
    try {
      this.http
        .get({
          url: `${environment.api.venues}/${id}`,
          payload: [],
        })
        .toPromise()
        .then((v) => {
          this.venue$.next(v);
        });
    } catch (e) {
      return null;
    }
  }

  getGlobalVenues() {
    try {
      this.http
        .get({
          url: `${environment.api.venues}?organizationId=${this.orgId}`,
          payload: [],
        })
        .toPromise()
        .then((v) => {
          this.venuesGlobal$.next(v);
        });
    } catch (e) {
      return null;
    }
  }

  getVenue(id: number): Promise<Venue> {
    try {
      return this.http
        .get({ url: `${environment.api.venues}/${id}` })
        .toPromise();
    } catch (e) {
      return null;
    }
  }

  getVenues(tournamentId: number, organizationId: number) {
    return this.http.get({
      url: `${environment.api.venues}?tournamentId=${tournamentId}&organizationId=${organizationId}`,
    });
  }

  /*
  getGlobalVenues() {
    try {
      this.http
        .get({
          url: `${environment.api.venues}?organizationId=${this.orgId}`,
          payload: [],
        })
        .toPromise()
        .then((v) => {
          this.venuesGlobal$.next(v);
        });
    } catch (e) {
      return null;
    }
  }
*/

  createNewVenue(data: any) {
    return this.http
      .post({
        url: environment.api.venues,
        payload: this.parseCompleteVenue(data),
      })
      .toPromise()
      .then(async (v) => {
        this.toast.toast('Success', 'Venue Created', 'success');
      })
      .catch((e) => {
        this.toast.toast('Failed', 'Error while creating a venue', 'error');
      });
  }

  postVenue(data: any, inOrg: boolean = false) {
    return this.http
      .post({
        url: environment.api.venues,
        payload: this.parseCompleteVenue(data),
      })
      .toPromise()
      .then(async (v) => {
        if (data.id == 0) {
          if (!inOrg) {
            const tournamentVenue = await this.postTournamentVenue(v);
            // if (tournamentVenue) {
            // this.toast.toast('Success', 'Venue Created', 'success');
            // }
          }
          this.toast.toast('Success', 'Venue Created', 'success');
        } else {
          this.toast.toast('Success', 'Venue Updated', 'success');
        }
        if (this.tournamentId) this.getTournamentVenues(this.tournamentId);
      })
      .catch((e) => {
        this.toast.toast('Failed', e.error.message, 'error');
      });
  }

  async postTournamentVenue(data: any) {
    this.tournaments = await this.getTournamentInfo();
    if (this.tournaments) {
      const finalPayload = {
        tournament: this.tournaments,
        venue: data,
      };
      try {
        return this.http
          .post({
            url: environment.api.tournamentsVenues,
            payload: finalPayload,
          })
          .toPromise();
      } catch (e) {}
    }
  }

  getTournamentInfo() {
    try {
      return this.http
        .get({
          url: `${environment.api.tournaments}/${this.tournamentId}`,
        })
        .toPromise();
    } catch (e) {
      return e;
    }
  }

  deleteVenue(venueId: string): void {
    this.http
      .delete({
        url: `${environment.api.venues}/${this.tournamentId}/${venueId}`,
        payload: {},
      })
      .toPromise()
      .then((v) => {
        this.toast.toast('Success', 'Venues Successfully deleted.', 'success');
        this.getTournamentVenues(this.tournamentId);
      })
      .catch((e) => {
        this.toast.toast(
          'Failed',
          'Something went wrong while deleting the venue',
          'error'
        );
      });
  }

  deleteVenueGlobal(venueId: string) {
    this.http
      .delete({
        url: `${environment.api.venues}/${this.tournamentId}/${venueId}`,
        payload: {},
      })
      .toPromise()
      .then((v) => {
        this.toast.toast('Success', 'Venues Successfully deleted.', 'success');
        this.getTournamentVenues(this.tournamentId);
      })
      .catch((e) => {
        this.toast.toast(
          'Failed',
          'Something went wrong while deleting a venue from the global list',
          'error'
        );
      });
  }

  getDivisionVenues(divisionId: number | string) {
    return this.http.get({
      url: `${environment.api.venues}?divisionId=${divisionId}`,
    });
  }

  // getDivisionVenues(divisionId: number | string){
  //   return this.http
  //   .get({
  //     url: `https://tourneykey.com/api/tourneykey/v1/venues?divisionId=${divisionId}`,
  //   });
  // }

  parseCompleteVenue(data: any): Venue {
    this.venueId = data.id == null ? 0 : +data.id;
    this.data = {
      photo: data?.photo,
      base64Photo: data?.base64Photo,
      id: this.venueId,
      name: data?.name,
      abbreviation: data?.abbreviation,
      additionalInformation: data?.additionalInformation,
      wifi: data?.wifi,
      courtCount: data?.courtCount,
      address: data?.address,
      lat: data.lat,
      lng: data.lng,
      organization: this.org,
      startTime: data.startTime,
      endTime: data.endTime,
    };
    return this.data;
  }
}
