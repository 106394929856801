import { Component, OnInit } from '@angular/core';
import { Bracket } from '@core/models/brackets';
import { BracketService } from '@core/services/bracket-service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-edit-bracket',
  templateUrl: './edit-bracket.component.html',
  styleUrls: ['./edit-bracket.component.scss']
})
export class EditBracketComponent implements OnInit {
  selectedBracketType: string;
  bracketTypes = [
    { name: 'Right Facing', value: 'RIGHT_FACING' },
    { name: 'Left Facing', value: 'LEFT_FACING' },
    { name: 'Middle', value: 'MIDDLE' },
    { name: 'Bracket Winner', value: 'BRACKET_WINNER' },
    { name: 'Box', value: 'BOX' },
  ];
  data: any;
  constructor(
    public config: DynamicDialogConfig,
    private bracketService: BracketService,
    public ref: DynamicDialogRef,

  ) { }

  ngOnInit(): void {
    this.data = this.config.data;

    this.bracketTypes = [
      { name: 'Right Facing', value: 'RIGHT_FACING' },
      { name: 'Left Facing', value: 'LEFT_FACING' },
      { name: 'Middle', value: 'MIDDLE' },
      { name: 'Bracket Winner', value: 'BRACKET_WINNER' },
      { name: 'Box', value: 'BOX' },
    ];
    this.bracketTypes = this.bracketTypes.filter((v) => v.value != this.data.bracketType);
  }
  cancel():void{
    this.ref.close();
  }
  onSubmitEditBracket() {
    if(this.selectedBracketType != ""){
      let bracket: Bracket = this.config.data.bracket;
      const bracketHeight = this.calculateHeight(bracket.y1, bracket.y2)
     const bracketWidth = this.calculateWidth(bracket.x3, bracket.x1)
     bracket.bracketType = this.selectedBracketType;
      let data = {
        id: bracket.id,
        height: bracketHeight,
        width: bracketWidth,
        x: bracket.x1,
        y: bracket.y1,
        bracketType: this.selectedBracketType,
        name: bracket.name,
        bracket: bracket
      };
      this.bracketService.editBracketType$.next(data);
      this.ref.close();
    }
    this.ref.close();
  }
  calculateHeight(y1: number, y2: number): number {
    let y = y2 - y1;
    return Math.round(y);
  }

  calculateWidth(x3: number, x1: number): number {
    return Math.round(x3 - x1);
  }
}
