<div class="public-tournament">
  <div class="header-container">
    <div class="nav">
      <app-public-header *ngIf="!isReview"></app-public-header>
    </div>
  </div>
  <div
    class="detail-header"
    [style.backgroundColor]="primaryColor ? primaryColor : '#a90000'"
  >
    <div class="tournament-panel left" *ngIf="this.innerWidth >= 769">
      <div class="tourney-image" *ngIf="tournamentData?.photo; else noPhoto">
        <img class="image" [src]="logoUrl" />
      </div>
      <ng-template #noPhoto>
        <div class="tourney-image">
          <img class="image" src="../../assets/img/tk-placeholder.png" />
        </div>
      </ng-template>
    </div>

    <div class="right">
      <div class="tournament-panel">
        <div class="division-details">
          <div
            class="tournament-name"
            [style.color]="fontColor ? fontColor : 'black'"
          >
            {{ tournamentData?.name }}
          </div>
          <div
            class="org-name"
            [style.color]="fontColor ? fontColor : 'black'"
            (click)="navigateToOrganizationPage()"
          >
            {{ tournamentData?.orgName }}
          </div>
        </div>
        <div class="register-button-container" *ngIf="isRequireRegister">
          <!-- <button
                *ngIf="checkDate()"
                pButton
                pRipple
                type="button"
                [label]="'REGISTER HERE'"
                class="p-button my-1"
                class="btn-register"
                (click)="registerHere()"
              ></button> -->
          <button
            pButton
            pRipple
            type="button"
            [label]="'REGISTER HERE'"
            class="p-button my-1"
            class="btn-register"
            (click)="registerHere()"
          ></button>
        </div>
      </div>

      <div class="date-panel">
        <div
          class="date-item"
          [ngStyle]="{
            'background-color': fontColor,
            color: fontColorOpposite
          }"
        >
          {{ tournamentData?.publishedDate }}
        </div>
        <div
          class="date-item"
          [ngStyle]="{
            'background-color': fontColor,
            color: fontColorOpposite
          }"
        >
          {{ tournamentData?.city }}, {{ tournamentData?.state }}
        </div>
      </div>

      <div class="nav-button-panel">
        <button
          pButton
          pRipple
          icon="pi pi-info"
          iconPos="left"
          type="button"
          label="Details"
          class="p-button-outlined my-1"
          class="btn-navigator"
          (mouseover)="isDetailsHovered = true"
          (mouseleave)="isDetailsHovered = false"
          [ngStyle]="isDetailsHovered ? hoveredbuttonStyle() : buttonStyle()"
          (click)="scroll(details, 'details', 1000)"
        ></button>
        <button
          pButton
          icon="pi pi-sitemap"
          iconPos="left"
          *ngIf="!tournament?.postSchedule"
          pRipple
          type="button"
          label="Divisions"
          class="p-button-outlined p-button-danger my-1 links-font"
          class="btn-navigator"
          (mouseover)="isDivisionsHovered = true"
          (mouseleave)="isDivisionsHovered = false"
          [ngStyle]="isDivisionsHovered ? hoveredbuttonStyle() : buttonStyle()"
          (click)="scroll(divisions, 'divisions', 350)"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          icon="pi pi-map"
          iconPos="left"
          label="Venues"
          class="p-button-outlined p-button-danger p-col-12 my-1"
          class="btn-navigator"
          (mouseover)="isVenuesHovered = true"
          (mouseleave)="isVenuesHovered = false"
          [ngStyle]="isVenuesHovered ? hoveredbuttonStyle() : buttonStyle()"
          (click)="scroll(venueList, 'venues', 350)"
        ></button>
        <button
          pButton
          *ngIf="tournament?.postSchedule"
          pRipple
          type="button"
          icon="pi pi-calendar"
          iconPos="left"
          label="Schedule"
          class="p-button-outlined p-button-danger p-col-12 my-1"
          class="btn-navigator"
          (mouseover)="isScheduleHovered = true"
          (mouseleave)="isScheduleHovered = false"
          [ngStyle]="isScheduleHovered ? hoveredbuttonStyle() : buttonStyle()"
          (click)="scroll(schedule, 'schedule', 350)"
        ></button>
      </div>
    </div>
  </div>

  <div class="main-container">
    <div #details id="details">
      <app-information
        #info
        [tournament]="tournament"
        [divisions]="publicDivisions"
      ></app-information>
    </div>

    <div #divisions id="divisions">
      <app-divisions
        *ngIf="!tournament?.postSchedule"
        [isLoggedIn]="null != me"
        [isReview]="isReview"
        [registration]="registration"
        [tournamentId]="tournamentId"
        [tournament]="tournament"
      ></app-divisions>
    </div>

    <div #schedule id="schedule">
      <app-schedule
        [tournament]="tournament"
        *ngIf="tournament?.postSchedule"
        [organizationId]="tournament?.organization.id"
      ></app-schedule>
    </div>

    <div #venueList id="venues">
      <app-venues
        [tournament]="tournament"
        [organizationId]="tournament?.organization.id"
        #venues
      ></app-venues>
    </div>

    <app-footer *ngIf="!isReview"></app-footer>
  </div>

  <p-overlayPanel *ngIf="!isReview" #opLogin>
    <ng-template pTemplate>
      <app-login [opLogin]="opLogin"></app-login>
    </ng-template>
  </p-overlayPanel>
</div>
