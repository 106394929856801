import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

// MODELS
import { APIParams } from '@core/models/api-params';
import { User } from '@models/user';

// SERVICES
import { HelperService } from './helper.service';
import { EmitterService } from './emitter.service';

// ENV
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  url: string;

  constructor(
    private http: HttpClient,
    private emitterService: EmitterService,
    private helperService: HelperService,
  ) {

  }

  private getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'          })
    };
  }

  private getByteHttpOptions() {
    
    let httpOptions:any = {
      headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
      }),
      responseType: 'arraybuffer'
    }
    return httpOptions;
  };

  get(params: APIParams): Observable<any> {
    const serialize = params.payload ? this.helperService.serialize(params.payload) : '';
    this.url = `${params.url}${serialize}`;
    return this.http.get(this.url, this.getHttpOptions()).pipe(
      map((res: any) => {
        return res;
      }),
      // catchError(this.handleError)
    );
  }

  getBytes(params: APIParams): Observable<any> {
    const serialize = params.payload ? this.helperService.serialize(params.payload) : '';
    this.url = `${params.url}${serialize}`;
    
    return this.http.get(this.url, this.getByteHttpOptions()).pipe(
      map((res: any) => {
        return res;
      }),
      // catchError(this.handleError)
    );
  }

  post(params: APIParams): Observable<any> {
    this.url = `${params.url}`;
    return this.http.post(this.url, params.payload).pipe(
      map((res: any) => {
        return res;
      }),
      // catchError(this.handleError)
    );
  }

  put(params: APIParams): Observable<any> {
    this.url = params.url;
    return this.http.put(this.url, params.payload).pipe(
      map((res: any) => {
        return res;
      }),
      // catchError(this.handleError)
    );
  }

  delete(params: APIParams): Observable<any> {
    this.url = params.url;
    return this.http.delete(this.url).pipe(
      map((res: any) => {
        return res;
      }),
      // catchError(this.handleError)
    );
  }

  me(): Observable<User> {
    this.url = environment.api.me;
    return this.http.get(this.url).pipe(
      map((res: User) => {
        this.emitterService.meEmit(res);
        return res;
      }),
    );
  }

}
