<div class="coach-checkin">
  <div class="requirement" *ngIf="maximumCoaches">
    This event requires <strong>{{ minimumCoaches }}</strong> to
    <strong>{{ maximumCoaches }}</strong> coaches per team
  </div>
  <div class="instructions">Tap a coach to check them in</div>
  <div class="button-content">
    <button pButton label="Add Coach" class="p-button-raised p-button-danger mx-2 px-5 py-3"
      (click)="addCoach()"></button>
  </div>
  <div class="coach-content">
    <div *ngFor="let coach of coaches" class="coach-item" [class.checkedIn]="coach.checkedIn"
      (click)="checkinCoach(coach)">
      <div *ngIf="!coach.checkedIn" class="controls">
        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-secondary top-0 right-0"
          style="height: 24px; width: 24px" (click)="editCoach($event, coach)"></button>
        <button pButton pRipple type="button" icon="pi pi-times" class="p-button-danger top-0 right-0"
          style="height: 24px; width: 24px" (click)="deleteCoach($event, coach)"></button>
      </div>
      <div *ngIf="coach.undoAllowed" class="controls">
        <button pButton pRipple type="button" icon="pi pi-undo" class="p-button-secondary top-0 right-0"
          style="height: 24px; width: 24px" (click)="undoCheckin($event, coach)"></button>
      </div>
      <div class="coach-name">{{ coach.firstName }} {{ coach.lastName }}</div>
      <div class="coach-role">{{ coach.role }}</div>
      <div *ngIf="coach.dateCheckedIn" class="checked-in-at-container">Checked-in at</div>
      <div *ngIf="coach.dateCheckedIn" class="venue-name-container">
        {{ coach.venue?.name }}
      </div>
      <div class="coach-date">
        <div>{{ coach.dateCheckedIn | date : "MM/dd/yyyy hh:mm a" }}</div>
      </div>
    </div>
  </div>
</div>