import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env/environment';
import { Teams } from '@core/models/teams';

const VIEWABLE_SIZE = 15;

@Injectable({
  providedIn: 'root',
})
export class TeamsService {

  teams: Teams;
  
  constructor(private http: HttpService) {
  }

  getTeam(teamId:number): Promise<Teams> {

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.teams}/${teamId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  teamExists(divisionId: number, name:String): Promise<Boolean> {

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.teamExists}?divisionId=${divisionId}&name=${name}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getPoolTeams(poolId:number): Promise<Teams[]> {

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.poolTeams}`,
          payload: {poolId: poolId},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getTournamentTeams(tournamentId:number): Promise<Teams[]> {

    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.tournamentTeams}`,
          payload: {tournamentId: tournamentId},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }


  async initCheckInTeams(tournamentId:number) {

    try {
      const list = await this.http
        .get({
          url: `${environment.api.checkInTeams}?action=init&tournamentId=${tournamentId}&index=1&viewableSize=${VIEWABLE_SIZE}&totalSize=330&ascending=true`,
          payload: [],
        }).toPromise();

      return list;
    } catch (err) {
      console.log(err);
    }
  }

  async getCheckInTeams(tournamentId:number, action: string, meta: any, q: string) {
    
    try {

      const list = await this.http
        .get({
          url: `${environment.api.checkInTeams}?action=${action}&tournamentId=${tournamentId}&q=${q}&index=${meta.index}&viewableSize=${VIEWABLE_SIZE}&totalSize=${meta.totalSize}&ascending=true`,
          payload: [],
        })
        .toPromise();
      return list;
    } catch (err) {
      console.log(err);
    }
  }
}
