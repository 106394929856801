import { Component, OnInit, Input } from '@angular/core';

// SERVICES
import { HttpService, ToastService } from '@services/index';

// ENV
import { environment } from '@env/environment';

// PRIME
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  message: string;
  hasSubmit: boolean;
  hasCancel: boolean;
  hasHandle: boolean;
  @Input() buttonText: string;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    const data = this.config.data;
    this.message = data.result.message;
    this.hasSubmit = data.result?.hasSubmit;
    this.hasHandle = data.result?.hasHandle;
    this.hasCancel = data.result?.hasCancel;
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    this.ref.close(true);
  }
  handle() {
    this.ref.close();
  }
}
