import { Component, Input, OnInit, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Player } from '@core/models/players';
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import {
  EmitterService,
  HelperService,
  HttpService,
  ToastService,
} from '@core/services';
import { CityStateLookupService } from '@core/services/city-state-lookup.service';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import { CityState } from '@core/utils/city-state';
import { environment } from '@env/environment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-player',
  templateUrl: './add-player.component.html',
  styleUrls: ['./add-player.component.scss'],
  providers: [MessageService],
})
export class AddPlayerComponent implements OnInit {
  playerForm: FormGroup;
  logoUrl: any;
  cityStates: CityState[];
  teamId: any;
  user: User;
  team: Teams;
  teams: Teams[];
  selectedTeam: Number;
  hasLogo: boolean;
  isLogoUploaded: boolean | null = null;
  buttonLabel: string = 'Upload Photo';
  isLogoFetching: boolean;

  isSaving: boolean = false;
  isEdit: boolean = false;
  hasSelectTeam = false;
  isCoach = false;

  constructor(
    private fb: FormBuilder,
    private helper: HelperService,
    private httpService: HttpService,
    private toastService: ToastService,
    private cityStateLookupService: CityStateLookupService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private route: ActivatedRoute,
    private playerService: CoachTeamsService,
    private emitterService: EmitterService,
    private toast: ToastService,
    private coachTeamService: CoachTeamsService
  ) {
    this.isEdit = this.config.data.isEdit;
    this.team = this.config.data.team;
    this.hasSelectTeam = this.config.data.hasSelectTeam;
    this.isCoach = this.config.data?.isCoach ?? false;
  }

  ngOnInit(): void {
    this.isEdit = this.config.data.isEdit;
    this.initForm();
    this.getTeams();
    this.emitterService.meEmitter.subscribe((user) => {
      this.user = user;
    });
    this.checkIsEdit();
  }

  initForm(): void {
    this.playerForm = this.fb.group({
      id: [null],
      base64Photo: [''],
      // fullName: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      birthdate: [null],
      jerseyNumber1: ['', [Validators.required]],
      height: [''],
      gpa: [''],
      email: [''],
      photo: [''],
      school: [''],
      phone: [''],
      address: [''],
      graduationYear: [''],
      position: [''],
      note: [''],
      parentName1: [''],
      parentEmail1: [''],
      parentPhone1: [''],
      parentName2: [''],
      parentEmail2: [''],
      parentPhone2: [''],
      facebookPage: [''],
      twitterHandle: [''],
      instagramHandle: [''],
      team: [{}, [Validators.required]],
    });
  }

  checkIsEdit() {
    if (this.isEdit) {
      let player: Player = this.config.data.player;

      this.playerForm.patchValue({
        id: player?.id,
        base64Photo: player?.base64Photo,
        firstName: player?.firstName,
        lastName: player?.lastName,
        birthdate: player?.birthdate,
        jerseyNumber1: player?.jerseyNumber1,
        height: player?.height,
        gpa: player?.gpa,
        email: player?.email,
        photo: player?.photo,
        school: player?.school,
        address: player?.address,
        phone: player?.phone,
        graduationYear: player?.graduationYear,
        position: player?.position,
        note: player?.note,
        parentName1: player?.parentName1,
        parentEmail1: player?.parentEmail1,
        parentPhone1: player?.parentPhone1,
        parentName2: player?.parentName2,
        parentEmail2: player?.parentEmail2,
        parentPhone2: player?.parentPhone2,
        facebookPage: player?.facebookPage,
        twitterHandle: player?.twitterHandle,
        instagramHandle: player?.instagramHandle,
        team: player?.team.id,
      });
      if (player?.photo) {
        this.logoUrl = `${environment.api.playerPhoto}/${player?.photo}`;
        this.hasLogo = true;
      }
      setTimeout((_) => {
        this.isLogoFetching = false;
      }, 1000);
    }
  }

  async getTeams(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    this.coachTeamService.getTeams(userId).subscribe((teams) => {
      this.teams = teams;
    });
  }

  getPhoto() {
    try {
      this.httpService.get({
        url: this.logoUrl,
      });
    } catch {}
  }

  searchCityState(evt) {
    this.cityStateLookupService.getResults(evt.query).then((data) => {
      this.cityStates = data;
    });
  }

  async onSelectLogo(event: any) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        const newBase64 = readFile.split(',')[1];
        this.playerForm.get('base64Photo').setValue(newBase64);
      }
    }
    this.logoUrl = '';
    this.isLogoFetching = true;
    this.isLogoUploaded = true;
    this.hasLogo = true;
    this.logoUrl = '';
    const newFile = event.currentFiles[0];

    this.helper.fileReader(newFile).then(
      (res) => {
        this.logoUrl = res;
        setTimeout(() => {
          this.isLogoFetching = false;
        }, 1000);
      },
      (err) => {
        this.isLogoFetching = false;
      }
    );

    if (this.isEdit) {
      this.isSaving = true;
      this.playerService
        .savePlayer(this.playerForm.value)
        .pipe(
          finalize(() => {
            this.isSaving = false;
            this.toastService.successMsg({
              message: 'Player Photo Updated',
            });
          })
        )
        .subscribe();
    }
  }
  submit() {
    if (this.isEdit) {
      this.toast.toast('Please Wait', 'Editing Player', 'info');
      this.isLogoFetching = true;
    } else {
      this.toast.toast('Please Wait', 'Creating Player', 'info');
    }
    let checkTeam =
      this.team ??
      this.teams.find((team) => {
        return this.playerForm.value.team == team.id;
      });
    let data = {
      ...this.playerForm.value,
      team: checkTeam,
    };

    this.playerService
      .savePlayer(data)
      .toPromise()
      .then((res) => {
        this.toast.successMsg({
          message: this.isEdit ? 'Player Edited' : 'Player Created',
        });
        this.ref.close();
      })
      .catch((err) => {
        this.toast.errorMsg({
          message: this.isEdit
            ? 'Editing Player Failed'
            : 'Creating Player Failed',
        });
      });
  }

  cancel(): void {
    this.ref.close();
  }

  async upload(event) {
    if (event) {
      const readFile: any = await this.helper.fileReader(event.files[0]);
      if (readFile) {
        const newBase64 = readFile.split(',')[1];
        this.httpService
          .post({
            url: `${environment.api.teams}/logo/base64`,
            payload: {
              id: '0',
              base64Photo: newBase64,
            },
          })
          .subscribe(
            (data) => {
              this.toastService.successMsg({
                message: 'Successfully uploaded',
              });
              this.logoUrl = `${environment.api.teams}/logo/${data.base64Photo}`;
            },
            (err) => {
              this.toastService.errorMsg({ message: err.error.message });
            }
          );
      }
    }
  }
  exit() {
    this.ref.close();
  }
  clearLogo() {
    this.isLogoUploaded = false;
    this.logoUrl = '';
  }
}
