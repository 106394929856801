import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationModalComponent } from '@public/public-registration/registration-modal/registration-modal.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { User } from '@core/models/user';
import { environment } from '@env/environment';
import { Games } from '@core/models/games';
import { Location } from '@angular/common';
import { Division } from '@core/models/division';

// SERVICES
import {
  HttpService,
  HelperService,
  ToastService,
  AuthService,
} from '@services/index';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { TournamentsService } from '@core/services/tournaments.service';
import { Tournament } from '@core/models/tournament';
import { SignupComponent } from '@components/public/signup/signup.component';
import { defConstant } from '@core/data/constant-data';
import { Registration } from '@core/models/registration';

@Component({
  selector: 'app-public-tournaments',
  templateUrl: './public-tournaments.component.html',
  styleUrls: ['./public-tournaments.component.scss'],
})
export class PublicTournamentsComponent
  implements OnInit, AfterViewInit, OnChanges
{
  ref: DynamicDialogRef;
  user: User;
  faLocationArrow = faLocationArrow;
  tournamentId: number;
  title: string;
  games: Games[];
  doesHaveGames: boolean;
  tournamentData: any;
  tournament: Tournament;
  logoUrl: string = '';
  hasLogo: boolean = false;
  standings: any = [];
  isCoachRole: boolean;
  isReview: boolean = false;
  isCoach: boolean = false;
  published: boolean = false;
  registration: Registration;
  me: any;
  isLogged = false;

  publicDivisions: Division[] = [];
  primaryColor: string;
  fontColor: string;
  fontColorOpposite: string;

  isDetailsHovered: boolean;
  isDivisionsHovered: boolean;
  isVenuesHovered: boolean;
  isScheduleHovered: boolean;

  isRequireRegister: boolean;

  orgId: number;

  @Input() tourney: any;

  innerWidth: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private httpService: HttpService,
    private toastService: ToastService,
    private helperService: HelperService,
    public publicTournamentService: PublicTournamentsService,
    private tournamentService: TournamentsService,
    private location: Location,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.paramMap();
    this.isReview = this.publicTournamentService.isReview;
    this.isReview = JSON.parse(localStorage.getItem('isReview')) ?? false;
    this.getMe();
    this.publicTournamentService.checkIfCoach();
    this.authService.isLoggedInEmitter.subscribe((isLoggedIn) => {
      this.isLogged = isLoggedIn;
    });
  }

  ngAfterViewInit(): void {
    this.paramMap();

    this.getTournamentInfo();
    this.getPublicDivisionsList();
    this.getRegistration(this.tournamentId);
    this.publicTournamentService.checkIfCoach();
  }

  ngOnChanges() {
    this.publicTournamentService.checkIfCoach();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  getStandings(): void {
    this.standings = [];

    this.httpService
      .get({
        url: `${environment.api.standings}?tournamentId=${this.tournamentId}`,
      })
      .subscribe(
        (res: any) => {
          this.standings = res.map((data: any) => {
            return {
              wins: data.wins,
              losses: data.losses,
              team: data.team.name,
            };
          });
        },
        (err) => {}
      );
  }

  getTournamentInfo(): void {
    this.httpService
      .get({ url: `${environment.api.tournaments}/${this.tournamentId}` })
      .subscribe(
        (res: any) => {
          console.log('**********************************************');
          console.log('THIS IS RES', res);

          console.log('**********************************************');

          this.tournament = res;

          const {
            id,
            name,
            city,
            state,
            organization,
            startDate,
            endDate,
            photo,
            published,
          } = res;

          if (null == organization.primaryColor) {
            this.primaryColor = '#a90000';
            this.fontColor = '#ffffff';
            this.fontColorOpposite = '#000000';
          } else {
            this.primaryColor = organization.primaryColor;
            this.getFontColor(organization.primaryColor);
          }

          console.log('ORGGGG', organization);

          this.orgId = organization.id;

          this.tournamentData = {
            id,
            name,
            city,
            state,
            published,
            orgName: organization.name,
            photo,
            publishedDate: this.helperService.dateFormatFromTo(
              startDate,
              endDate
            ),
          };

          if (photo) {
            this.logoUrl = `${environment.api.tournamentPhoto}/${photo}`;
            this.hasLogo = true;
          }
        },
        (err) => {}
      );
  }

  getFontColor(primary: string) {
    let pRed = primary.substring(1, 3);
    let pGreen = primary.substring(3, 5);
    let pBlue = primary.substring(5, 7);
    let primaryInt =
      parseInt(pRed, 16) * 0.299 +
      parseInt(pGreen, 16) * 0.587 +
      parseInt(pBlue, 16) * 0.114;

    if (primaryInt > 150) {
      console.log('MAIN IS BLACK');
      this.fontColor = '#000000';
      this.fontColorOpposite = '#ffffff';
    } else {
      console.log('MAIN IS WHITE');
      this.fontColor = '#ffffff';
      this.fontColorOpposite = '#000000';
    }
  }

  getRegistration(id) {
    console.log('id?', id);

    this.publicTournamentService.getRegistration(id).then((res) => {
      console.log('ressss', res);

      this.registration = res;
      this.isRequireRegister = res.requireRegister;
      console.log('THIS IS REQUIRE REGG', this.isRequireRegister);

      console.log('registration: ', this.registration);
    });
  }

  paramMap(): void {
    this.route.paramMap.subscribe((params) => {
      console.log('id params', params);

      if (params.get('id') != null) {
        this.tournamentId = parseInt(params.get('id'));
        this.title = params.get('title');

        console.log('id params again', params);

        this.onRefresh('division');
      }
    });
  }

  onRefresh(title: string) {
    let el = document.getElementById(title);
    el?.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToHeader(el: HTMLElement, offset: number = 0) {
    const yOffset = -Math.abs(offset);
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  scroll(el: HTMLElement, title: string, offset: number = 0) {
    // this.router.navigate([`/public-tournament/${this.tournamentId}/${title}`]);
    this.scrollToHeader(el, offset);
  }

  goToRegistration() {
    this.router.navigate([
      `/public-registration/${this.tournament.name
        .replace(/ /g, '-')
        .toLowerCase()}/registration/${this.tournamentId}`,
    ]);
  }

  getUserDetails(): void {
    this.httpService.me().subscribe(
      (user: User) => {
        this.user = user;
      },
      (err) => {}
    );
  }

  async showConfirmCoach() {
    if (this.publicTournamentService.isCoach == false) {
      this.ref = this.dialogService.open(RegistrationModalComponent, {
        width: '750px',
        dismissableMask: true,
        closable: false,
        data: { tournamentId: this.tournamentId },
      });
    } else {
      this.goToRegistration();
    }
  }

  registerHere() {
    let userRole = sessionStorage.getItem('role');
    sessionStorage.setItem('tournamentId', this.tournamentId.toString());
    sessionStorage.removeItem('divId');
    if (!userRole && this.registration?.requireAccount === 'REQUIRED') {
      this.signup();
    } else {
      this.goToRegistration();
    }
  }

  signup(): void {
    this.ref = this.dialogService.open(SignupComponent, {
      width: defConstant.modalWidth,
      closable: false,
      data: { coach: true },
    });

    this.ref.onClose.subscribe((res) => {
      this.ref.close();
    });
  }

  async checkIfCoach() {
    const me = await this.httpService.me().toPromise();
    if (me?.userType == 'coach') {
      this.isCoachRole = true;
    } else {
      this.isCoachRole = false;
    }
  }

  getFeaturedGames() {
    this.httpService
      .get({
        url: `${environment.api.games}?tournamentId=${this.tournamentId}`,
        payload: [],
      })
      .subscribe(
        (res: any) => {
          this.games = res;

          if (this.games.length > 0) {
            this.doesHaveGames = true;
          } else {
            this.doesHaveGames = false;
          }
        },
        (err) => {
          this.toastService.toast(
            'Failed',
            'Something went wrong while getting the featured games',
            'error'
          );
        }
      );
  }

  onPublish() {
    if (this.tournamentData.published) {
      this.toastService.toast('Error', 'Tournament Already Published', 'error');
    } else {
      this.tournamentService.postTournament();
      this.goBack();
    }
  }

  goBack() {
    this.location.back();
  }

  async getMe() {
    this.me = await this.httpService.me().toPromise();
  }

  checkDate() {
    var d1 = new Date(this.registration?.startDate).getTime();
    var d2 = new Date(this.registration?.endDate).getTime();
    var now = new Date().getTime();
    if (d1 < now && d2 > now) {
      return true;
    } else {
      return false;
    }
  }

  getPublicDivisionsList(): void {
    this.publicTournamentService
      .getPublicDivisions(this.tournamentId)
      .subscribe((divisions) => {
        this.publicDivisions = divisions;
      });
  }

  buttonStyle() {
    return {
      'background-color': this.fontColor,
      color: this.fontColorOpposite,
    };
  }

  hoveredbuttonStyle() {
    return {
      'background-color': this.fontColorOpposite,
      color: this.fontColor,
    };
  }

  navigateToOrganizationPage() {
    this.router.navigate([`/browse-director-tournaments/${this.orgId}`]);
  }
}
