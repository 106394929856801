export enum DivisionTiebreakerEnum {
  POOL_PLACE = 'POOL_PLACE',
  RECORD = 'RECORD',
  POINTS = 'POINTS',
  WINNING_PERCENTAGE = 'WINNING_PERCENTAGE',
  HEAD_TO_HEAD = 'HEAD_TO_HEAD',
  HEAD_TO_HEAD_TWO_TEAMS_ONLY = 'HEAD_TO_HEAD_TWO_TEAMS',
  HEAD_TO_HEAD_PT_DIFF = 'HEAD_TO_HEAD_PTS_DIFF',
  PT_DIFF = 'PTS_DIFF',
  PTS_SCORED = 'PTS_SCORED',
  PTS_ALLOWED = 'PTS_ALLOWED',
  MAX_POINT_DIFFERENTIAL = 'MAX_POINT_DIFFERENTIAL',
}

export interface DivisionTiebreakerType {
  id?: number;
  divisionTieBreakerType: string;
  seqNo?: number;
  label: string;
}

export const DIVISION_TIEBREAKER_TYPE: DivisionTiebreakerType[] = [
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.POOL_PLACE,
    label: 'POOL PLACE',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.RECORD,
    label: DivisionTiebreakerEnum.RECORD,
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.POINTS,
    label: DivisionTiebreakerEnum.POINTS,
  },
];

export const DIVISION_TIEBREAKER_OPTIONS: DivisionTiebreakerType[] = [
  // {
  //   divisionTieBreakerType: DivisionTiebreakerEnum.POINTS,
  //   label: DivisionTiebreakerEnum.POINTS,
  // },
  // {
  //   divisionTieBreakerType: DivisionTiebreakerEnum.RECORD,
  //   label: DivisionTiebreakerEnum.RECORD,
  // },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.POOL_PLACE,
    label: 'POOL PLACE',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.WINNING_PERCENTAGE,
    label: 'WINNING PERCENTAGE',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.HEAD_TO_HEAD,
    label: 'HEAD TO HEAD',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.HEAD_TO_HEAD_TWO_TEAMS_ONLY,
    label: 'HEAD TO HEAD TWO TEAMS ONLY',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.HEAD_TO_HEAD_PT_DIFF,
    label: 'HEAD TO HEAD POINT DIFFERENTIAL',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.PT_DIFF,
    label: 'POINT DIFFERENTIAL',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.PTS_SCORED,
    label: 'POINTS SCORED',
  },
  {
    divisionTieBreakerType: DivisionTiebreakerEnum.PTS_ALLOWED,
    label: 'POINTS ALLOWED',
  },
  // {
  //   divisionTieBreakerType: DivisionTiebreakerEnum.MAX_POINT_DIFFERENTIAL,
  //   label: 'MAX POINT DIFFERENTIAL',
  // },
];
