<div class="notify-coach">
  <p-table
    [responsive]="true"
    [scrollable]="true"
    [value]="coaches"
  >
  
    <ng-template pTemplate="header">
      <tr>
        <th class="w-150">Coach First Name</th>
        <th class="w-150">Coach Last Name</th>
        <th class="w-150">Coach Email</th>
        <!-- <th></th> -->
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-coach let-index="rowIndex">
      <tr>
        <td class="w-150">
          <input
          id="firstName"
          [(ngModel)]="coaches[index].firstName"
          type="text"
          class="p-col-12 input-text"
          pInputText
        />
        </td>
        <td class="w-150">
          <input
          id="lastName"
          [(ngModel)]="coaches[index].lastName"
          type="text"
          class="p-col-12 input-text"
          pInputText
        />
        </td>
        <td class="w-150">
          <input
              id="email"
              type="email"
              [(ngModel)]="coaches[index].email"
              class="p-col-12 input-text"
              email="true" #prmEmail="ngModel"
              pInputText
            />
            <!-- <div *ngIf="prmEmail.errors?.email">
              Email address is not valid.
            </div> -->
        </td>
      </tr>
    
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between"></div>
    </ng-template>
  </p-table>
  <div class="center-cont mb-2">
    <button
    pButton
    iconPos="left"
    icon="pi pi-plus"
    pRipple
    type="button"
    label="Add Coach"
    (click)="addCoach()"
    class="p-button-raised p-button-danger"
  ></button>
  </div>
  <div class="p-col-12 mt-2 flex flex-center">
    <button
      pButton
      pRipple
      type="button"
      label="Cancel"
      (click)="cancel()"
      class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
    ></button>
    <button
      pButton
      iconPos="right"
      type="button"
      label="Proceed"
      [disabled]="coaches[0].firstName == '' || coaches[0].lastName == '' || coaches[0].email == ''"
      (click)="submit()"
      class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
    ></button>
  </div>
</div>

<!-- <div>
    <div class="p-col-12 text-center my-2">
        <div class="p-col-12">
            <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
              >Coach Name <span class="required-indicator">*</span></label
            >
            <input
              id="name"
              formControlName="name"
              type="text"
              class="p-col-12 input-text"
              pInputText
            />
          </div>

          <div class="p-col-12">
            <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
              >Coach Email <span class="required-indicator">*</span></label
            >
            <input
              id="name"
              formControlName="name"
              type="text"
              class="p-col-12 input-text"
              pInputText
            />
          </div>


    </div>
    <div class="p-col-12 flex flex-center">
      <button
        pButton
        pRipple
        type="button"
        label="Confirm"
        class="
          p-button-raised p-button-rounded p-button-danger p-button-plain
          mx-2
          px-5
          py-3
        "
        (click)="submit()"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        label="Close"
        class="
          p-button-raised p-button-rounded p-button-text p-button-plain
          mx-2
          px-5
          py-3
        "
        (click)="cancel()"
      ></button>
    </div>
  </div>
   -->