import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Division } from '@core/models/division';
import { Tournament } from '@core/models/tournament';
import { TournamentsService } from '@core/services/tournaments.service';
import { VenueService } from '@core/services/venue.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-public-table',
  templateUrl: './public-table.component.html',
  styleUrls: ['./public-table.component.scss'],
})
export class PublicTableComponent implements OnInit {
  @Input() config: any = {};
  @Input() data: any = [];
  @Input() thead: any = [];
  @Input() hasResult = true;
  @Input() isFetching = true;
  @Input() isAgeGrade = false;
  @Input() noResult = 'No results.';
  @Input() textAlignment = 'center';

  @Output() onActionButtonClick: EventEmitter<any> = new EventEmitter<any>();

  message = 'Do you want to delete this tournament?';
  tabIndex = 0;
  tooltip: string;
  hasActionButtons = false;
  isDeleteDialog = false;
  itemData: any;
  btnData: any;
  tournamentId: number;

  constructor(
    private tournamentService: TournamentsService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private venueService: VenueService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdref.detectChanges();
    if (this.config?.actionButtons) {
      this.hasActionButtons = true;
      this.config.actionReference = 'id';
    }

    console.log(`table data ${this.data}`);
  }
  showDialog(item?: any, btn?: any) {
    this.checkMessage(btn);
    this.itemData = item;
    this.btnData = btn;
  }
  checkMessage(type) {
    console.log('type', type);
    switch (type) {
      case 'deleteVenue':
        this.isDeleteDialog = !this.isDeleteDialog;
        this.message = 'Do you want to delete this venue?';
        break;
      case 'delete':
        this.message = 'Do you want to delete this tournament?';
        this.isDeleteDialog = !this.isDeleteDialog;

      default:
        this.message = 'Do you want to delete this tournament?';

        break;
    }
  }
  hide() {
    this.isDeleteDialog = false;
  }
  deleteNow() {
    this.onClick(this.itemData, this.btnData);
  }
  setAlignHeader(header: any) {
    if (this.textAlignment != 'center') {
      return this.textAlignment;
    } else {
      let textAlign = 'center';
      const index = this.thead.findIndex((res) => res.id === header);
      if (index == 0) {
        textAlign = 'left';
      } else if (index == this.thead.length - (this.hasActionButtons ? 0 : 1)) {
        textAlign = 'right';
      }
      return textAlign;
    }
  }
  setTextAlign(header: any) {
    let textAlign = 'center';
    const index = this.thead.findIndex((res) => res.id === header);
    if (index == 0) {
      textAlign = 'left';
    } else if (index == this.thead.length - (this.hasActionButtons ? 0 : 1)) {
      textAlign = 'right';
    }
    return textAlign;
  }
  setToolTip(action: string): string {
    switch (action) {
      case 'delete':
        this.tooltip = 'Delete Tournament';
        break;
      case 'deleteVenue':
        this.tooltip = 'Delete Venue';
        break;
      case 'view':
        this.tooltip = 'View Division';
        break;
      case 'edit':
        this.tooltip = 'Edit Tournament';
        break;
      case 'editVenue':
        this.tooltip = 'Edit Venue';
        break;
      case 'schedule':
        this.tooltip = 'View Scheduling Grid';
        break;
      case 'division':
        this.tooltip = 'Manage Divisions';
        break;
      case 'selectDivision':
        this.tooltip = 'Manage Divisions';
        break;
      case 'selectTeam':
        this.tooltip = 'Select Team';
        break;
      default:
        break;
    }
    return this.tooltip;
  }
  setBtnIcon(action: string) {
    let icon;
    switch (action) {
      case 'delete':
        icon = 'pi-trash';
        break;
      case 'deleteVenue':
        icon = 'pi-trash';
        break;
      case 'view':
        icon = 'pi-table';
        break;
      case 'edit':
        icon = 'pi-pencil';
        break;
      case 'editVenue':
        icon = 'pi-pencil';
        break;
      case 'schedule':
        icon = 'pi-table';
        break;
      case 'division':
        icon = 'pi-book';
        break;
      case 'selectDivision':
        icon = 'pi-book';
        break;
      case 'selectTeam':
        icon = 'pi-plus';
        break;
      default:
        icon = 'pi-pencil';
        break;
    }
    return icon;
  }

  onClick(id: any, action: string, data?: any): void {
    this.tournamentId = +this.tournamentService.getSessionTournamentId();

    console.log('id onclick', data, action);
    if (action === 'delete') {
      this.hide();
      this.tournamentService.deleteTournament(id);
    } else if (action === 'edit') {
      this.router.navigate([`/dashboard/tournaments/gen-info/${data.id}`]);
      this.ngxLoader.start();

      this.tabIndex = 0;
      this.tournamentService.setSessionTournamentId(id);
      this.tournamentService.tabIndex$.next({
        index: this.tabIndex,
        isEdit: true,
      });
    } else if (action === 'division') {
      this.router.navigate([
        `/dashboard/tournaments/division/${this.tournamentId}`,
      ]);
      this.ngxLoader.start();

      this.tabIndex = 3;
      this.tournamentService.divisionList$.next(null);
      this.tournamentService.setSessionTournamentId(id);
      this.tournamentService.tabIndex$.next({
        index: this.tabIndex,
        isEdit: true,
      });
    } else if (action === 'selectDivision') {
      this.router.navigate([
        `/dashboard/tournaments/division/${data.tournamentId}/${data.id}`,
      ]);
      const division: Division = {
        name: data.division,
        gender: data.gender,
        maxTeams: data.maxTeams,
        id: data.id,
        abbreviation: data.abbreviation,
        scheduleColor: data.scheduleColor,
        entryFee: data.entryFee,
        resultsMessage: data.resultsMessage,
        tournament: {
          id: data.tournamentId,
        },
      };
      console.log('selected', division);
      this.ngxLoader.start();
      this.tabIndex = 3;
      this.tournamentService.divisionList$.next(null);
      this.tournamentService.setSessionTournamentId(data.tournamentId);
      this.tournamentService.division$.next(division);

      this.tournamentService.tabIndex$.next({
        index: this.tabIndex,
        isEdit: true,
      });
    } else if (action === 'schedule') {
      this.router.navigate([
        `/dashboard/tournaments/scheduling/${this.tournamentId}`,
      ]);

      this.ngxLoader.start();

      this.tabIndex = 4;

      this.tournamentService.setSessionTournamentId(id);
      this.tournamentService.tabIndex$.next({
        index: this.tabIndex,
        isEdit: true,
      });
    } else if (action === 'deleteVenue') {
      this.isDeleteDialog = true;
      this.venueService.deleteVenueGlobal(id);
    } else if (action === 'editVenue') {
      this.router.navigate([
        `/dashboard/organization-edit-venues/${this.tournamentId}`,
      ]);
      this.ngxLoader.start();
      this.venueService.updateVenue$.next(data);
    }

    if (this.onActionButtonClick)
      this.onActionButtonClick.emit({ action: action, data: data });
  }

  ageGradeDisplay(input: string) {
    if (input != null) {
      return input.replace('_', ' ');
    }
  }
}
