<!-- <p-card>
  <div
    *ngIf="!isHasSet || !divisionInfo.tournament.showBrackets; else withBracket"
  >
    <span class="disp-block TheBoldFont-Bold fs-3 text-center p-3">
      Bracket Not Available.
    </span>
  </div>
  <ng-template #withBracket>
    <div class="container-main" [ngClass]="{ show: isHasSet, hide: !isHasSet }">
      <div class="container-stage">
        <div class="container-bracket" [style.height.px]="stageHeight" #parent>
          <div class="bracket-cont" #bracket></div>
        </div>
        <div class="container-overlap" [style.height.px]="stageHeight"></div>
      </div>
      <div class="venue-container flex flex-wrap p-3">
        <div *ngFor="let venue of venues" class="p-col-6">
          <h5 class="m-0">{{ venue }}</h5>
        </div>
      </div>
    </div>
    
    </ng-template
  >
</p-card> -->
<div #bracketStage #parent >

</div>
<!-- <div
*ngIf="!isHasSet || !divisionInfo.tournament.showBrackets; else withBracket"
>
<span class="disp-block TheBoldFont-Bold fs-3 text-center p-3">
  Bracket Not Available.
</span>
</div>
<ng-template #withBracket>
  <div #bracketStage #parent >

  </div>


  
  </ng-template
> -->