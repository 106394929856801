import {Component, OnInit, Input} from '@angular/core';

@Component({
    selector: 'drag-drop-upload-progress',
    templateUrl: './drag-drop-upload-progress.component.html',
    styleUrls: ['./drag-drop-upload-progress.component.scss']
})
export class DragDropUploadProgressComponent implements OnInit {
    @Input()
    progress = 0;

    constructor() {
    }

    ngOnInit() {
    }
}
