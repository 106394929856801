<form [formGroup]="poolsForm" (ngSubmit)="onSubmit()" class="pools">
  <div class="p-3">
    <div class="p-col-12" *ngIf="data.isAdd; else elseEdit">
      <label for="number" class="text-bold1 disp-block p-col-12 p-0 mb-2"
        >Count *</label
      >
      <input
        type="number"
        min="0"
        placeholder=""
        pInputText
        class="p-col-12 input-text"
        formControlName="poolCount"
        style="border-radius: 15px"
      />
    </div>

    <ng-template #elseEdit>
      <div class="p-col-12">
        <label for="name" class="text-bold1 disp-block p-col-12 p-0 mb-2"
          >Pool Name*</label
        >
        <input
          id="name"
          formControlName="name"
          type="text"
          class="p-col-12 input-text"
          pInputText
        />
      </div>
    </ng-template>

    <div class="p-col-12 flex flex-center mt-4">
      <button
        pButton
        type="button"
        label="Cancel"
        class="
          p-button-raised p-button-rounded p-button-text p-button-plain
          mx-2
          px-5
          py-3
        "
        (click)="close()"
      ></button>
      <button
        pButton
        type="submit"
        class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
        [disabled]="!poolsForm.valid"
      >
      <span *ngIf="!isLoading">Submit</span>
      <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" style="font-size:24px; color: white"></i></span>
    </button>
    </div>
  </div>
</form>
