import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '@core/services';

// PRIME
import { DialogService } from 'primeng/dynamicdialog';

import { Teams } from '@core/models/teams';
import { Tournament } from '@core/models/tournament';

import { CoachCoachService } from '@core/services/coach-coach.service';
import { CheckInService } from '@core/services/check-in.service';
import { TournamentsService } from '@core/services/tournaments.service';

import { CoachModalComponent } from '@coach/modals/coach-modal/coach-modal.component';

import * as moment from 'moment';
import { VenueService } from '@core/services/venue.service';
import { Venue } from '@core/models/venue';

@Component({
  selector: 'checkin-coaches',
  templateUrl: './checkin-coaches.component.html',
  styleUrls: ['./checkin-coaches.component.scss'],
})
export class CheckinCoachesComponent implements OnInit {
  _team: Teams;
  _selectedVenue: Venue;
  _tournament: Tournament;
  minimumCoaches: number;
  maximumCoaches: number;
  venue: Venue;

  @Input()
  set team(team: Teams) {
    this._team = team;
    if (team) {
      this.getCoaches();
    }
  }

  get team(): Teams {
    return this._team;
  }

  @Input()
  set selectedVenue(venue: Venue) {
    this._selectedVenue = venue;
  }

  get selectedVenue() {
    return this._selectedVenue;
  }

  @Input()
  set tournament(tournament: Tournament) {
    this._tournament = tournament;
  }

  get tournament() {
    return this._tournament;
  }

  @Output() checkInCoaches = new EventEmitter<any[]>();

  coaches: any = [];
  venues: any = [];

  constructor(
    private checkInService: CheckInService,
    private coachesService: CoachCoachService,
    private toastService: ToastService,
    private tournamentsService: TournamentsService,
    private dialogService: DialogService,
    private venueService: VenueService,
  ) { }

  ngOnInit() {
    // this.getCoaches();
  }

  async getCoaches() {
    this.minimumCoaches = this.tournament.minimumCoaches;
    this.maximumCoaches = this.tournament.maximumCoaches;

    this.coachesService
      .getTeamAsstCoaches(this._team.id)
      .subscribe((coaches: any) => {
        this.coaches = coaches;
        this.checkInService
          .getCheckInCoaches(this.tournament.id, this._team.id)
          .subscribe((checkInCoaches: any[]) => {
            checkInCoaches.forEach(async (checkInCoach) => {
              let coach = this.coaches.find((coach: any) => {
                return coach.id == checkInCoach.coach.id;
              });
              if (coach) {
                coach.checkInId = checkInCoach.id;
                coach.checkedIn = true;
                coach.dateCheckedIn = checkInCoach.dateCheckedIn;
                coach.venue = await this.getCoachCheckInVenue(checkInCoach.venueId ?? 0);
              }
            });

            console.log('checkedInCoaches', checkInCoaches);
            this.checkInCoaches.emit(checkInCoaches);
          });
      });
  }

  async getCoachCheckInVenue(venueId: number) : Promise<any> {
    return new Promise((resolve) => {
      let venue = this.venues.find((data: Venue) => {
        return data.id == venueId;
      });
      if (venue) {
        resolve(venue);
      }
      else {
        const venueData = this.venueService.getVenue(venueId);
        this.venues.push(venueData);
        resolve(venueData);
      }
    });
  } 

  addCoach() {
    const ref = this.dialogService.open(CoachModalComponent, {
      header: 'Add Coach',
      width: '600px',
      // height: 'calc(100vh - 120px)',
      height: 'auto',
      contentStyle: { 'overflow-y': 'hidden' },
      closable: false,
      data: {
        team: this._team,
        isEdit: false,
        isCoach: true,
      },
    });
    ref.onClose.subscribe(() => {
      this.getCoaches();
    });
  }

  editCoach(event: any, coach: any) {
    event.stopPropagation();

    const ref = this.dialogService.open(CoachModalComponent, {
      header: 'Edit Coach',
      width: '600px',
      height: 'calc(100vh - 120px)',
      closable: false,
      contentStyle: { 'overflow-y': 'scroll' },
      data: {
        team: this._team,
        isEdit: true,
        coach: coach,
      },
    });
    ref.onClose.subscribe(() => {
      this.getCoaches();
    });
  }

  deleteCoach(event: any, coach: any) {
    event.stopPropagation();

    this.coachesService
      .deleteCoach(coach.id)
      .subscribe(
        () =>
        (this.coaches = this.coaches.filter(
          (item: any) => item.id !== coach.id
        ))
      );
    this.toastService.successMsg({ message: 'Coach Deleted' });
  }

  checkinCoach(coach: any) {
    if (!coach.checkedIn) {
      
      const checkInCoach = {
        tournament: this.tournament,
        team: this._team,
        coach: coach,
        venueId: this.selectedVenue.id,
      };

      this.checkInService.checkInCoach(checkInCoach).subscribe((checkIn) => {
        coach.checkInId = checkIn.id;
        coach.checkedIn = true;
        coach.dateCheckedIn = checkIn.dateCheckedIn;
        coach.undoAllowed = true;
        coach.undoDate = moment();
        coach.venue = this.selectedVenue;
        this.toastService.successMsg({ message: 'Coach Checked In' });
      });
    }
  }

  undoCheckin(event: any, coach: any) {
    event.stopPropagation();

    if (this.undoAllowed(coach)) {
      this.checkInService.undoCheckInCoach(coach.checkInId).subscribe(() => {
        delete coach.checkInId;
        coach.checkedIn = false;
        coach.dateCheckedIn = null;
        coach.undoAllowed = false;
        coach.undoDate = null;
        this.toastService.successMsg({ message: 'Checked In Undone' });
      });
    } else {
      coach.undoAllowed = false;
      this.toastService.warningMsg({ message: 'Undo is no longer allowed.' });
    }
  }

  undoAllowed(coach: any) {
    let now = moment();
    return null != coach.undoDate && now.diff(coach.undoDate, 'minute') < 1;
  }
}
