import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { StepsComponent } from './steps/steps.component';

// PrimeNg Modules
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';
import {
  ProgressSpinner,
  ProgressSpinnerModule,
} from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { SharedTooltipComponent } from './shared-tooltip/shared-tooltip.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { SharedModalComponent } from './shared-modal/shared-modal.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';

@NgModule({
  declarations: [
    StepsComponent,
    TableComponent,
    SharedTooltipComponent,
    SharedModalComponent,
    DeleteModalComponent,
  ],
  imports: [
    CommonModule,
    ChipModule,
    TooltipModule,
    ProgressSpinnerModule,
    DialogModule,
    StepsModule,
    YouTubePlayerModule,
  ],
  exports: [
    TableComponent,
    StepsComponent,
    SharedTooltipComponent,
    SharedModalComponent,
    DeleteModalComponent,
  ],
})
export class SharedModule {}
