import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Bracket } from '@core/models/bracket';
import { BracketSources } from '@core/models/bracket-sources';
import { environment } from '@env/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';
import { BracketSet } from '@core/models/bracket-set';

@Injectable({
  providedIn: 'root',
})
export class BracketService {
  isCopied: boolean = false;
  onValidate$ = new BehaviorSubject<any>(null);
  deleteBracket$ = new BehaviorSubject<any>(null);
  deleteBracketSet$ = new BehaviorSubject<any>(null);
  deleteTopTeam$ = new BehaviorSubject<any>(null);
  deleteBottomTeam$ = new BehaviorSubject<any>(null);
  setBracketDimension$ = new BehaviorSubject<any>(null);
  setBracketCoordinates$ = new BehaviorSubject<any>(null);
  setBrackets$ = new BehaviorSubject<any>(null);
  editBracketType$ = new BehaviorSubject<any>(null);
  undoData$ = new Subject<any>();
  movingBracket$ = new BehaviorSubject<boolean>(false);
  bracketSet$ = new Subject<BracketSet>();
  bracketOptions$ = new Subject<BracketSources[]>();
  divisionBracketsDetails$ = new BehaviorSubject<any[]>(null);
  championTeam$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute
  ) {}

  addBracket() {}

  setCopyTemplate() {
    this.isCopied = true;
  }

  setCopyTemplateFalse() {
    this.isCopied = false;
  }

  getAdvancedTeams(divisionId) {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.brackets}/advance?divisionId=${divisionId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while advancing teams.',
            'error'
          );
        });
    });
  }

  saveBrackets(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: `${environment.api.brackets}/all`,
          payload: data,
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
          this.toastService.toast('Success!', `Bracket Saved`, 'success');
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when saving the bracket information',
            'error'
          );
        });
    });
  }

  saveBracketSetName(data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: `${environment.api.bracketSet}/name`,
          payload: data,
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
          this.toastService.toast('Success!', `Bracket Name Saved`, 'success');
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when saving the bracket set name',
            'error'
          );
        });
    });
  }

  deleteBracket(brakcetId: any) {
    return new Promise((resolve, reject) => {
      this.http
        .delete({
          url: `${environment.api.brackets}/${brakcetId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while deleting bracket',
            'error'
          );
        });
    });
  }
  deleteTopTeam(bracketId: any) {
    return new Promise((resolve, reject) => {
      this.http
        .delete({
          url: `${environment.api.bracketsTopTeam}/${bracketId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while deleting team from top of bracket',
            'error'
          );
        });
    });
  }
  deleteBottomTeam(bracketId: any) {
    return new Promise((resolve, reject) => {
      this.http
        .delete({
          url: `${environment.api.bracketsBottomTeam}/${bracketId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong while deleting team from bottom of bracket',
            'error'
          );
        });
    });
  }
  setCanvas(data) {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: `${environment.api.bracketSet}/canvas`,
          payload: data,
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
          this.toastService.toast(
            'Success!',
            `Bracket Stage Size Successfully Changed.`,
            'success'
          );
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when saving the canvas information',
            'error'
          );
        });
    });
  }

  getBracketSet(divisionId: string | number) {

    console.log("getBracketSet divisionId: ", divisionId);
    
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.bracketSet}?divisionId=${divisionId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when getting the bracket set',
            'error'
          );
        });
    });
  }

  deleteBracketSet(id: string | number) {
    return new Promise((resolve, reject) => {
      this.http
        .delete({
          url: `${environment.api.bracketSet}/${id}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          this.toastService.toast('Success', 'Bracket Set Deleted!', 'success');
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when deleting the bracket set',
            'error'
          );
        });
    });
  }
  generateBracketSetTemplate() {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.bracketSet}/templates`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when getting the bracket set templates',
            'error'
          );
        });
    });
  }

  getBracket(id: string | number) {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.brackets}/${id}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            `Something went wrong when getting a bracket (id: ${id})`,
            'error'
          );
        });
    });
  }

  async getBracketsSources(
    divisionId: string | number,
    bracketSetId: string | number
  ): Promise<BracketSources[]> {
    const sources = await this.http
      .get({
        url: `${environment.api.bracketSources}/first?divisionId=${divisionId}&bracketSetId=${bracketSetId}`,
        payload: {},
      })
      .toPromise();

    return sources;

    // return new Promise((resolve, reject) => {
    //   this.http
    //     .get({
    //       url: `${environment.api.bracketSources}/first?divisionId=${divisionId}`,
    //       payload: {},
    //     })
    //     .toPromise()
    //     .then((res: any) => {
    //       resolve(res);
    //     })
    //     .catch((err) => {
    //       reject(err);
    //       this.toastService.toast('Failed', 'Something went wrong when getting the first source for bracket', 'error');
    //     });
    // });
  }

  generateBracketSet(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: environment.api.bracketSet,
          payload: data,
        })
        .toPromise()
        .then((res: any) => {
          if (res) {
            resolve(res);
            this.toastService.toast(
              'Success!',
              `Bracket Generated Successfully.`,
              'success'
            );
          } else {
            reject(res);
            this.toastService.toast(
              'Failed',
              'Failed Generating Bracket',
              'error'
            );
          }
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast(
            'Failed',
            'Something went wrong when generating the bracket set',
            'error'
          );
        });
    });
  }

  copyBracketSet(data: any, isTemplate: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: `${environment.api.bracketSet}/templates/copy`,
          payload: data,
        })
        .subscribe(
          (res: any) => {
            if (res) {
              resolve(res);
              if (!isTemplate) {
                this.toastService.toast(
                  'Success!',
                  `Bracket Copied Successfully.`,
                  'success'
                );
              }
            } else {
              reject(false);
              this.toastService.toast(
                'Failed',
                'Failed Copying Bracket',
                'error'
              );
            }
          },
          (_) => {
            reject(false);
            this.toastService.toast(
              'Failed',
              'Something went wrong when copying the bracket',
              'error'
            );
          }
        );
    });
  }

  getPreview(bracketSetId: string | number) {
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: `${environment.api.bracketSet}/${bracketSetId}`,
          payload: {},
        })
        .toPromise()
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          this.toastService.toast('Failed', 'Something went wrong', 'error');
        });
    });
  }

  saveBracketCoordinates(data): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('Saving...');
      this.http
        .post({
          url: `${environment.api.brackets}/coordinates`,
          payload: data,
        })
        .pipe(debounceTime(1000))
        .subscribe(
          (res: any) => {
            if (res) {
              this.toastService.toast(
                'Success!',
                `Bracket Saved Successfully.`,
                'success'
              );
              resolve(res);
            } else {
              this.toastService.toast(
                'Failed',
                'Failed Saving Bracket',
                'error'
              );
              reject(false);
            }
          },
          (_) => {
            this.toastService.toast(
              'Failed',
              'Something went wrong when setting the bracket coordinates',
              'error'
            );

            reject(false);
          }
        );
    });
  }
  saveBracketDimensions(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: `${environment.api.brackets}/dimensions`,
          payload: data,
        })
        .pipe(debounceTime(1000))
        .subscribe(
          (res: any) => {
            if (res) {
              this.toastService.toast(
                'Success!',
                `Bracket Saved Successfully.`,
                'success'
              );
              resolve(res);
            } else {
              this.toastService.toast(
                'Failed',
                'Failed Saving Bracket',
                'error'
              );
              reject(false);
            }
          },
          (_) => {
            this.toastService.toast(
              'Failed',
              'Something went wrong when setting the bracket dimensions',
              'error'
            );

            reject(false);
          }
        );
    });
  }

  saveBracket(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post({
          url: `${environment.api.brackets}`,
          payload: data,
        })
        .pipe()
        .subscribe(
          (res: any) => {
            if (res) {
              this.toastService.toast(
                'Success!',
                `Bracket Saved Successfully.`,
                'success'
              );
              resolve(res);
            } else {
              this.toastService.toast(
                'Failed',
                'Failed Saving Bracket',
                'error'
              );
              reject(false);
            }
          },
          (_) => {
            this.toastService.toast(
              'Failed',
              'Something went wrong when saving the bracket',
              'error'
            );

            reject(false);
          }
        );
    });
  }

  validateBracketSources(divisionId): Promise<any> {
    const url = `${environment.api.validateBracketSources}?divisionId=${divisionId}`;
    console.log(url);
    return new Promise((resolve, reject) => {
      this.http
        .get({
          url: url,
        })
        .pipe()
        .subscribe(
          (res: any) => {
            if (res) {
              resolve(res);
            } else {
              reject(false);
            }
          },
          (_) => {
            this.toastService.toast(
              'Failed',
              'Something went wrong when validating the bracket source',
              'error'
            );

            reject(false);
          }
        );
    });
  }

  putBracket(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put({
          url: `${environment.api.brackets}`,
          payload: data,
        })
        .pipe(debounceTime(1000))
        .subscribe(
          (res: any) => {
            if (res) {
              this.toastService.toast(
                'Success!',
                `Bracket Saved Successfully.`,
                'success'
              );
              resolve(res);
            } else {
              this.toastService.toast(
                'Failed',
                'Failed Saving Bracket',
                'error'
              );
              reject(false);
            }
          },
          (_) => {
            this.toastService.toast(
              'Failed',
              'Something went wrong when updating the bracket',
              'error'
            );

            reject(false);
          }
        );
    });
  }

  getLastDataByBracketId(arr) {
    return [...new Map(arr.map((item) => [item['bracketId'], item])).values()];
  }

  getLastDataById(arr) {
    return [...new Map(arr.map((item) => [item['id'], item])).values()];
  }

  // getBracketsSourceNext(divisionId: string | number) {
  //   return new Promise((resolve, reject) => {
  //     this.http
  //       .get({
  //         url: `${environment.api.bracketSources}/next?divisionId=${divisionId}`,
  //         payload: {},
  //       })
  //       .toPromise()
  //       .then((res: any) => {
  //         resolve(res);
  //         this.toastService.toast(
  //           'Success!',
  //           `Bracket Generated Successfully.`,
  //           'success'
  //         );
  //       })
  //       .catch((err) => {
  //         reject(err);
  //         this.toastService.toast('Failed', 'Something went wrong when getting next source for bracket', 'error');
  //       });
  //   });
  // }
}
