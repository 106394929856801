import {
    Directive,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    Input,
    Renderer2
} from '@angular/core';

@Directive({
    selector: '[tkButton]'
})
export class TkButtonDirective implements AfterViewInit, OnDestroy {

    @Input() iconPos: 'left' | 'right' = 'left';

    @Input() cornerStyleClass: string = 'ui-corner-all';

    public _label: string;

    public _icon: string;

    public initialized: boolean;

    _iconElement: any;
    _labelElement: any;
    _textElement: any;

    constructor(public el: ElementRef, private renderer: Renderer2) {
    }

    _addMultipleClasses(elem: any, styles: string) {
        let styleClasses = styles.split(' ');
        if (styleClasses) {
            styleClasses.forEach(styleClass => {
                this.renderer.addClass(this.el.nativeElement, styleClass);
            })
        }
    }

    ngAfterViewInit() {
        this._addMultipleClasses(this.el.nativeElement, this.getStyleClass());
        if (this.icon) {
            this._iconElement = this.renderer.createElement("span");
            this.renderer.setAttribute(this._iconElement, "aria-hidden", "true");
            let iconPosClass = (this.iconPos == 'right') ? 'ui-button-icon-right' : 'ui-button-icon-left';
            this._iconElement.className = iconPosClass + ' ui-clickable ' + this.icon;
            this.renderer.appendChild(this.el.nativeElement, this._iconElement);
        }

        this._labelElement = this.renderer.createElement("span");
        if (this.icon && !this.label) {
            this.renderer.setAttribute(this._labelElement, 'aria-hidden', 'true');
        }
        this._labelElement.className = 'ui-button-text ui-clickable';
        this._textElement = this.renderer.createText(this.label || 'ui-btn');
        this.renderer.appendChild(this._labelElement, this._textElement);
        this.renderer.appendChild(this.el.nativeElement, this._labelElement);
        this.initialized = true;
    }

    getStyleClass(): string {
        let styleClass = 'ui-button ui-widget ui-state-default ' + this.cornerStyleClass;
        if (this.icon) {
            if (this.label != null && this.label != undefined) {
                if (this.iconPos == 'left')
                    styleClass = styleClass + ' ui-button-text-icon-left';
                else
                    styleClass = styleClass + ' ui-button-text-icon-right';
            } else {
                styleClass = styleClass + ' ui-button-icon-only';
            }
        } else {
            if (this.label) {
                styleClass = styleClass + ' ui-button-text-only';
            } else {
                styleClass = styleClass + ' ui-button-text-empty';
            }
        }

        return styleClass;
    }

    @Input() get label(): string {
        return this._label;
    }

    set label(val: string) {
        this._label = val;

        if (this.initialized) {
            this._labelElement.textContent = this._label;

            if (!this.icon) {
                if (this._label) {
                    this.renderer.removeClass(this.el.nativeElement, 'ui-button-text-empty');
                    this.renderer.addClass(this.el.nativeElement, 'ui-button-text-only');
                } else {
                    this.renderer.addClass(this.el.nativeElement, 'ui-button-text-empty');
                    this.renderer.removeClass(this.el.nativeElement, 'ui-button-text-only');
                }
            }
        }
    }

    @Input() get icon(): string {
        return this._icon;
    }

    set icon(val: string) {
        this._icon = val;

        if (this.initialized) {
            let iconPosClass = (this.iconPos == 'right') ? 'ui-button-icon-right' : 'ui-button-icon-left';
            (this.icon ? this._iconElement : this._labelElement).className =
                iconPosClass + ' ui-clickable ' + this.icon;
        }
    }

    ngOnDestroy() {
        const childElements = this.el.nativeElement.childNodes;
        for (let child of childElements) {
            this.renderer.removeChild(this.el.nativeElement, child);
        }

        this.initialized = false;
    }
}
