<div class="list-main">
  <div class="list-layout">
    <ng-container
      *ngFor="
        let tournament of tournaments
          | paginate : { itemsPerPage: 24, currentPage: page, totalItems: 24 }
      "
    >
      <p-card [style]="{ cursor: 'pointer', width: '90vw', margin: '2em' }">
        <div class="card-container">
          <div
            class="card-image-container"
            (click)="goToPublicTournament(tournament)"
          >
            <img
              *ngIf="!tournament?.photo; else hasPhoto"
              class="card-image"
              alt="Card"
              src="../../../../assets/img/tk-placeholder.png"
            />
            <ng-template #hasPhoto>
              <img class="card-image" alt="Card" [src]="getPhoto(tournament)" />
            </ng-template>
          </div>
          <div class="card-content-container">
            <div class="card-content">
              <div
                class="card-content-date"
                (click)="goToPublicTournament(tournament)"
              >
                <div class="card-date">
                  <div class="date-day">
                    <label>{{ tournament?.startDate | date : "dd" }}</label>
                  </div>
                  <div class="date-month">
                    <label>{{ tournament?.startDate | date : "MMM" }}</label>
                  </div>
                </div>
                <div class="divider">.</div>
                <div class="card-date">
                  <div class="date-day">
                    <label>{{ tournament?.endDate | date : "dd" }}</label>
                  </div>
                  <div class="date-month">
                    <label>{{ tournament?.endDate | date : "MMM" }}</label>
                  </div>
                </div>
              </div>
              <div class="card-title">
                <div
                  class="card-subtitle"
                  (click)="
                    goToBrowseDirectorTournaments(tournament.organization.id)
                  "
                >
                  <i>
                    <span class="subtitle"
                      >{{ tournament?.organization?.name }}
                    </span>
                  </i>
                  <i> presents</i>
                </div>
                <div
                  class="card-tournament-name"
                  (click)="goToPublicTournament(tournament)"
                >
                  <label>{{ tournament?.name }}</label>
                </div>
                <div (click)="goToPublicTournament(tournament)">
                  <label>{{ tournament?.description }}</label>
                </div>
              </div>
            </div>
            <div class="footer">
              <div
                class="container-footer card-gender-fee"
                [style.backgroundColor]="
                  orgInfo.primaryColor ? orgInfo.primaryColor : '#ffffff'
                "
                [style.color]="
                  orgInfo.fontColor ? orgInfo.fontColor : '#000000'
                "
                (click)="goToPublicTournament(tournament)"
              >
                <label>{{ getGender(tournament?.gender) }}</label>
                <label>Entry Fee: ${{ tournament?.entryFee }}</label>
              </div>
              <div
                class="container-footer"
                [style.backgroundColor]="
                  orgInfo.primaryColor ? orgInfo.primaryColor : '#ffffff'
                "
                [style.color]="
                  orgInfo.fontColor ? orgInfo.fontColor : '#000000'
                "
                (click)="goToPublicTournament(tournament)"
              >
                <fa-icon class="faicon" [icon]="faMarker"></fa-icon>
                <label>{{ tournament?.city }}</label
                ><label *ngIf="tournament?.state"
                  >, {{ tournament?.state }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </ng-container>
  </div>
  <!-- <pagination-controls (pageChange)="page = $event"></pagination-controls> -->
</div>
