import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Player } from '@core/models/players';
import { Teams } from '@core/models/teams';
import { User } from '@core/models/user';
import { EmitterService, HttpService, ToastService } from '@core/services';
import { CoachTeamsService } from '@core/services/coach-teams.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogService , DynamicDialogRef} from 'primeng/dynamicdialog';
import { AddPlayerComponent } from '../add-player/add-player.component';
import { Location } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss']
})
export class PlayersComponent implements OnInit {
  user: User;
  teamId: any;
  teams: Teams[];
  players: Player;
  listPlayers: Player[];
  selectedPlayers: Player[];
  constructor(
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private playerService: CoachTeamsService,
    private httpService: HttpService,
    private toast: ToastService,
    private ngxLoader: NgxUiLoaderService,
    private emitterService: EmitterService,
    private router: Router,
    private location: Location

  ) { 


    this.emitterService.meEmitter.subscribe( user => {
      this.user = user;
    });
  }
  calculateAge(birthdate){
    return  moment().diff(birthdate, 'years');
  }
  ngOnInit(): void {
    this.getPlayerId();
    this.getTeam();
    this.getPlayers();
  }

  getPlayerId(){
    this.route.paramMap.subscribe((params) => {
      console.log('team id', params.get('teamId'));
      this.teamId = params.get('teamId');
      if(params.get('') != null){
      }
    });
  }

  async getTeam(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const userId = this.user.id;
    console.log(userId);
    this.playerService.getTeamById(this.teamId)
    .subscribe(v => {
      this.teams = v;
    });
  }


  async getPlayers(): Promise<any> {
    this.user = await this.httpService.me().toPromise();
    const data ={
      'teamId': this.teamId
    }
    this.playerService.getPlayers(data).toPromise().then(v => {
      this.listPlayers = v;

    })
  }
  showAddPlayer(): void {
    const teamId = sessionStorage.getItem('teamId');
    const ref = this.dialogService.open(AddPlayerComponent, {
         header: 'Add Player',
         width: '600px',
         closable: false,
         height: 'calc(100vh - 120px)',
         contentStyle: { 'overflow-y': 'scroll' },
         data: {
            'team': this.teams ?? {id: teamId},
            'isEdit': false,
            'hasTeamSelection': false,
         }
     });
    ref.onClose.subscribe(() => {
      this.getPlayers();
    })
 }

 editPlayer(player:Player){
  const ref = this.dialogService.open(AddPlayerComponent, {
    header: 'Edit Player',
    width: '600px',
    closable: false,
    height: 'calc(100vh - 120px)',
    contentStyle: { 'overflow-y': 'scroll' },
    data: {
       'team': this.teams,
       'isEdit': true,
       'player': player,
       'hasTeamSelection': false,
    }
});


ref.onClose.subscribe(() => {
 this.getPlayers();
})
 }
 onDeletePlayer(): void {
  this.toast.toast('Please Wait', 'Deleting Player', 'info')
   setTimeout(() => {
    this.selectedPlayers.forEach((i) => {
     console.log('selectedPlayers, ', i.id);

      i.id = i.id;
      this.playerService.deletePlayer(i.id).toPromise().then(res => {
          console.log('delete response ', res)
      }).catch(e => console.log('erroe ', e))
    });
    this.getPlayers();
    this.toast.successMsg({message: 'Player Deleted'});

   },1000)

}

 navigateBack(): void{
  this.location.back();
 }
}
