import { Injectable } from '@angular/core';
import { Bracket } from '@core/models/bracket';
import { environment } from '@env/environment';
import { HttpService } from './http.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root',
})
export class BracketService {

  constructor(
    private httpService: HttpService,
    private toastService: ToastService
  ) {}

  async getBracket(divisionId:number): Promise<Bracket> {

    const bracket:Bracket = await this.httpService.get({
        url: `${environment.api.divisionBrackets}?divisionId=${divisionId}`
    }).toPromise();

    return bracket;
  }

  async createBracket(divisionId:number, teamsCount:number, gamesCount:number): Promise<Bracket> {
    const bracketInfo = {
      divisionId: divisionId,
      teamsCount: teamsCount,
      gamesCount: gamesCount,
    }

    const bracket:Bracket = await this.httpService.post({
        url: environment.api.brackets,
        payload: bracketInfo,
    }).toPromise();

    return bracket;
  }

  async deleteBracket(bracketId:number): Promise<Bracket> {
    
    const bracket:Bracket = await this.httpService.delete({
        url: `${environment.api.brackets}/${bracketId}`,
    }).toPromise();

    return bracket;
  }

  async deleteBracketSetByDivision(divisionId:number): Promise<Bracket> {
    
    const url = `${environment.api.bracketSet}/division/${divisionId}`;
    console.log(url);
    const bracket:Bracket = await this.httpService.delete({
        url: url,
    }).toPromise();

    return bracket;
  }



  async deleteBracketSet(id:number): Promise<void> {
    
    const url = `${environment.api.bracketSet}/${id}`;
    console.log(url);
     await this.httpService.delete({
        url: url,
    }).toPromise();

  }
}
