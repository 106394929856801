import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '@env/environment';
import { Pools } from '@core/models/pools';

@Injectable({
  providedIn: 'root',
})
export class PoolService {

  constructor(private http: HttpService) {
  }

  getTournamentPools(tournamentId: string | number): Promise<Pools[]> {

    return new Promise((resolve, reject) => {

      this.http.get({
          url: `${environment.api.tournamentPools}?tournamentId=${tournamentId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }

  getDivisionPools(divisionId: string | number): Promise<Pools[]> {

    return new Promise((resolve, reject) => {

      this.http.get({
          url: `${environment.api.divisionsPools}?divisionId=${divisionId}`,
          payload: {},
        })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((e) => reject(e));
    });
  }
}
