import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BracketPreviewComponent } from '@components/admin/bracket-builder/bracket-preview/bracket-preview.component';
import { BracketSet } from '@core/models/bracket-set';
import { BracketService } from '@core/services/bracket-service';
import { DivisionService } from '@core/services/division.service';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import { PublicBracketStageComponent } from '@public/public-bracket-stage/public-bracket-stage.component';

@Component({
  selector: 'app-public-bracket-preview',
  templateUrl: './public-bracket-preview.component.html',
  styleUrls: ['./public-bracket-preview.component.scss']
})
export class PublicBracketPreviewComponent implements OnInit, AfterViewInit {
  bracketSetId: any;
  bracketSet: BracketSet
  stageHeight: number;
  stageWidth: number;
  dynamicComponent: any[] = [];
  @ViewChild('bracketStage', { read: ViewContainerRef }) bracketStage: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private divisionService: DivisionService,
    private publicTournamentService: PublicTournamentsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private bracketService: BracketService,

  ) { }
  ngAfterViewInit(): void {

  }

  ngOnInit(
  ): void {
    this.route.paramMap.subscribe(async (params) => {
      this.bracketSetId = params.get('id');
      this.bracketSet = await this.publicTournamentService.getPublicBracketSet(this.bracketSetId);
      this.createNewBracketSet(this.bracketSet);

    });  
  }
  
  async createNewBracketSet(bracketSet:BracketSet): Promise<void>{
    this.stageHeight = window.innerHeight;
    this.stageWidth = window.innerWidth;
    const bracketStageComponent =this.componentFactoryResolver.resolveComponentFactory(PublicBracketStageComponent);
    let stageComponent: ComponentRef<any>;
    stageComponent = this.bracketStage.createComponent(bracketStageComponent);
    stageComponent.instance.bracketSet = bracketSet
    stageComponent.instance.isPreview = true;
    stageComponent.instance.stageHeight = this.stageHeight
    stageComponent.instance.stageWidth = this.stageWidth
    stageComponent.instance.divisionInfo = bracketSet.division
    this.dynamicComponent.push(stageComponent.instance)
  }


}
