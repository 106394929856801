import { Component, OnInit } from '@angular/core';
import { Division } from '@core/models/division';
import { ToastService } from '@core/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-division-modal',
  templateUrl: './division-modal.component.html',
  styleUrls: ['./division-modal.component.scss'],
})
export class DivisionModalComponent implements OnInit {
  divisions: Division[];
  division: Division;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.divisions = this.config.data.divisions;
    this.division = this.config.data.division;

    console.log('DIVISION: ', this.config.data.division)
  }

  close(): void {
    this.ref.close();
  }

  select(): void {
    if (!this.division) return this.toastService.errorMsg({message: 'No division selected.'})
    console.log('select: ', this.division);
    this.ref.close(this.division);
  }
}
