export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  COED = 'COED',
}

export interface GenderType {
  value: string;
  label: string;
}

export const GENDER_TYPES: GenderType[] = [
  {
    value: 'M',
    label: GenderEnum.MALE,
  },
  {
    value: 'F',
    label: GenderEnum.FEMALE,
  },
  {
    value: 'C',
    label: GenderEnum.COED,
  },
];

export const GENDER_TYPES_FILTER: GenderType[] = [
  {
    value: GenderEnum.MALE,
    label: GenderEnum.MALE,
  },
  {
    value: GenderEnum.FEMALE,
    label: GenderEnum.FEMALE,
  },
];

export const GENDER_TYPES_DIVISION: GenderType[] = [
  {
    value: 'M',
    label: GenderEnum.MALE,
  },
  {
    value: 'F',
    label: GenderEnum.FEMALE,
  },
  {
    value: 'C',
    label: 'COED',
  },
];

export const GENDER_TYPES_TEAM: GenderType[] = [
  {
    value: 'M',
    label: GenderEnum.MALE,
  },
  {
    value: 'F',
    label: GenderEnum.FEMALE,
  },
  {
    value: 'C',
    label: 'COED',
  },
];
