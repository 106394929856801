export const SidebarMenu = [
  {
    list: [
      {
        name: 'Dashboard',
        path: 'dashboard',
        icon: 'pi-home',
      },
      {
        name: 'Players',
        path: 'players',
        icon: 'pi-users',
      },
      {
        name: 'Coaches',
        path: 'coaches',
        icon: 'pi-user',
      },
      {
        name: 'Schedules',
        path: 'schedules',
        icon: 'pi-calendar',
      },
      // {
      //   name: 'Settings',
      //   path: 'settings',
      //   icon: 'pi-sliders-h',
      // },
    ],
  },
];
