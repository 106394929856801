import { Component, OnInit } from '@angular/core';

// SERVICES
import { HttpService, ToastService } from '@services/index';

// ENV
import { environment } from '@env/environment';

// PRIME
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { faLaptopHouse } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-advance',
  templateUrl: './advance.component.html',
  styleUrls: ['./advance.component.scss'],
})
export class AdvanceComponent implements OnInit {
  constructor(
    private httpService: HttpService,
    private toastService: ToastService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close(false);
  }

  onSubmit(): void {
    this.ref.close(true);
  }
}
