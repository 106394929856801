import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, ToastService } from '@core/services';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-payment-auth-modal',
  templateUrl: './payment-auth-modal.component.html',
  styleUrls: ['./payment-auth-modal.component.scss']
})
export class PaymentAuthModalComponent implements OnInit {

  authForm: FormGroup;
  isShowAuthFailed: boolean;
  isAuthenticating: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    private toast: ToastService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.authForm = this.fb.group({
      password: ['', [Validators.required]],
    });
  }

  async submit() {
    this.toast.infoMsg({ message: "Authenticating" });
    this.isAuthenticating = true;
    const password = this.authForm.get("password").value;
    this.authService.reAuthenticateWithPassword(password).then(res => {
      if (res) {
        this.toast.successMsg({ message: "Authentication Success" });
        this.ref.close(true);
      }
      else {
        this.toast.errorMsg({ message: "Authentication Failed" });
      }
      this.isAuthenticating = false;
    });
  }

  cancel() {
    this.ref.close(false);
  }

}
