<div class="container">
  <form [formGroup]="teamForm" class="mx-auto py-4 addteamwrapper addTeamForm">
    <div>
      <span class="text-bold3 TheOpenSans-Regular mb-2 disp-block"
        >Team Photo</span
      >
    </div>
    <p-fileUpload
      name="fileLogo[]"
      accept="image/*"
      [customUpload]="true"
      (onSelect)="onSelectLogo($event)"
      [showUploadButton]="false"
      [showCancelButton]="false"
      (uploadHandler)="upload($event)"
    >
    </p-fileUpload>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >Team Name</span
      >
    </div>
    <div>
      <input
        id="name"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="Team Name"
        formControlName="name"
        maxlength="40"
      />
    </div>

    <small
      *ngIf="
        teamForm.controls['name'].invalid &&
        (teamForm.controls['name'].dirty || teamForm.controls['name'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div *ngIf="teamForm.controls['name'].errors.required" class="mb-3 ml-2">
        Team name is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >Team Abbreviation</span
      >
    </div>
    <div>
      <input
        id="abbreviation"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="Team Abbreviation"
        formControlName="abbreviation"
      />
    </div>
    <small
      *ngIf="
        teamForm.controls['abbreviation'].invalid &&
        (teamForm.controls['abbreviation'].dirty ||
          teamForm.controls['abbreviation'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="teamForm.controls['abbreviation'].errors.required"
        class="mb-3 ml-2"
      >
        Team abbreviation is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >City</span
      >
    </div>
    <div>
      <input
        id="city"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="City"
        formControlName="city"
      />
    </div>

    <small
      *ngIf="
        teamForm.controls['city'].invalid &&
        (teamForm.controls['city'].dirty || teamForm.controls['city'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div *ngIf="teamForm.controls['city'].errors.required" class="mb-3 ml-2">
        City is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-3 mb-2 disp-block"
        >State</span
      >
    </div>

    <div class="addTeamDropdown">
      <p-dropdown
        [options]="states"
        placeholder="State"
        optionLabel="name"
        optionValue="value"
        formControlName="state"
        autoWidth="false"
        [style]="{ width: '100%', padding: '6px 14px' }"
      ></p-dropdown>
    </div>
    
    <!-- <div>
      <input
        id="state"
        class="addTeamInput"
        type="text"
        pInputText
        placeholder="State"
        formControlName="state"
      />
    </div> -->

    <small
      *ngIf="
        teamForm.controls['state'].invalid &&
        (teamForm.controls['state'].dirty || teamForm.controls['state'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div *ngIf="teamForm.controls['state'].errors.required" class="mb-3 ml-2">
        State is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Gender</span
      >
    </div>

    <div class="radio-buttons">
      <p-radioButton
        name="gender"
        value="M"
        formControlName="gender"
        inputId="male"
        label="Male">
      </p-radioButton>
      <p-radioButton
        name="gender"
        value="F"
        formControlName="gender"
        inputId="female"
        label="Female">
      </p-radioButton>
      <div></div>
    </div>

    <small
      *ngIf="
        teamForm.controls['gender'].invalid &&
        (teamForm.controls['gender'].dirty ||
          teamForm.controls['gender'].touched)
      "
      class="text-error text-left text-primary2"
    >
      <div
        *ngIf="teamForm.controls['gender'].errors.required"
        class="mb-3 ml-2"
      >
        Gender is required.
      </div>
    </small>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Grade/Age Group</span
      >
    </div>

    <div class="radio-buttons">
      <p-radioButton
        class="tk-radioButton"
        name="group"
        value="GRADE"
        label="Grade"
        formControlName="group"
        inputId="grade">
      </p-radioButton>
      <p-radioButton
        class="tk-radioButton"
        name="group"
        value="AGE"
        label="Age"
        formControlName="group"
        inputId="age">
      </p-radioButton>
      <div></div>
    </div>

    <div class="" *ngIf="isAgeGroup">
      <label
        for="gender"
        class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Select an Age Group <span class="required-indicator"></span
      ></label>
      <div class="addTeamDropdown">
        <p-dropdown
          [options]="ageGroup"
          placeholder="Select an Age Group"
          optionLabel="label"
          optionValue="ageGroup"
          styleClass="p-col-12 mx-0"
          formControlName="ageGroup"
        ></p-dropdown>
      </div>
    </div>
    <div class="" *ngIf="isGradeGroup">
      <label
        for="gender"
        class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block"
        >Select a Grade Group <span class="required-indicator"></span
      ></label>
      <div class="addTeamDropdown">
        <p-dropdown
          [options]="gradeGroup"
          placeholder="Select a Grade Group"
          optionLabel="label"
          optionValue="gradeGroup"
          styleClass="p-col-12 mx-0"
          formControlName="gradeGroup"
        ></p-dropdown>
      </div>
    </div>

    <div>
      <span class="text-bold3 TheOpenSans-Regular mt-4 mb-3 disp-block">
        Team Privacy
      </span>
    </div>

    <div class="radio-buttons">
      <p-radioButton
        name="teamPrivate"
        [value]="false"
        formControlName="teamPrivate"
        inputId="public"
        label="Public">
      </p-radioButton>
      <p-radioButton
        name="teamPrivate"
        [value]="true"
        formControlName="teamPrivate"
        inputId="private"
        label="Private">
      </p-radioButton>
      <div></div>
    </div>

    <div class="flex flex-center my-4">
      <button type="button" (click)="cancel()" class="btn-rounded-white mx-2">
        Cancel
      </button>

      <button
        type="submit"
        [disabled]="!teamForm.valid"
        (click)="submitHandler()"
        class="btn-rounded-red mx-2"
      >
        Add Team
      </button>
    </div>
  </form>
</div>
