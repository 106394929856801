import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// PRIME
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

// SERVICES
import { HttpService, ToastService } from '@services/index';

// ENV
import { environment } from '@env/environment';
import { DivisionService } from '@core/services/division.service';
import { Pools } from '@core/models/pools';

@Component({
  selector: 'app-pools-modal',
  templateUrl: './pools-modal.component.html',
  styleUrls: ['./pools-modal.component.scss'],
})
export class PoolsModalComponent implements OnInit {
  isLoading: boolean = false;
  poolsForm: FormGroup;
  data = null;
  pool = null;

  constructor(
    private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    private httpService: HttpService,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private divisionService: DivisionService
  ) {}

  ngOnInit(): void {
    this.poolsForm = this.formBuilder.group({
      name: [''],
      poolCount: [''],
      division: [''],
    });

    this.data = this.config.data;

    if (this.data?.result) {
      this.poolsForm.get('division').setValue(this.data.result.division);

      this.pool = this.data.result.pool;

      if (this.pool) {
        const { name, poolCount } = this.pool;
        this.poolsForm.patchValue({
          name,
          poolCount,
          division: this.pool.division,
        });
      }
    }
  }

  async onSubmit() {
    const { name } = this.poolsForm.value;
    this.isLoading = true;

    try {
      const pool: Pools = {
        id: this.pool.id,
        name: name,
        division: this.data.result.division,
      };

      await this.divisionService.postPool(pool, this.data.result.division).then(newPool => {
        this.isLoading = false;
        this.ref.close(newPool);
      });

     
    } catch (err) {
      this.isLoading = false;
      this.toastService.errorMsg({ message: err.error.message });
    }
  }

  close(): void {
    this.ref.close();
  }
}
