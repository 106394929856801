import { PaymentComponent } from '@admin/payment/payment.component';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { CustomizedLoginComponent } from '@components/public/customized-login/customized-login.component';
import { PublicPaymentModalComponent } from '@public/public-payment-modal/public-payment-modal.component';
import { faCalendar, faLaptop, faMobile, faMouse, faMousePointer, faRegistered, faRobot, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { faHammer } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss']
})
export class PricingCardComponent implements OnInit {
  returnedValue : string;
  faRobot = faRobot;
  faAddressCard = faAddressCard;
  faPen = faPen;
  faChartLine = faChartLine;
  faHammer = faHammer;
  faRegistered = faRegistered;
  faCalendar = faCalendar;
  faToolbox = faToolbox;
  faMobile = faMobile;
  faLaptop = faLaptop;
  faMousePointer = faMousePointer;
  constructor(
    private dialogService: DialogService,
    private router: Router
  ) { 
  }

  ngOnInit(): void {
    this.getValue('first');
  }

  paymentPermission() : void {
    if(sessionStorage.getItem('uid') == null) {
      this.dialogService.open(CustomizedLoginComponent, {
        width: '500px',
        header: ``,
        closable: false,
      });    
    } else {
      this.dialogService.open(PublicPaymentModalComponent, {
        width: '500px',
        header: ``,
        closable: true,
      });
    }
  }

  getValue(page) {
     this.returnedValue = page;
     console.log(this.returnedValue);
  }

}
