import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';

// SERVICES
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardAdminService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // if (!this.sessionStorageService.getMe()) {
    //   this.router.navigate(['/login']);
    // }

    if (!this.authService.isLoggedInEmitter || !sessionStorage.getItem('uid')) {
      this.router.navigate(['/login']);
    }
    return true;
  }
}
