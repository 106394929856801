import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tournament } from '@core/models/tournament';
import { PublicTournamentsService } from '@core/services/public-tournaments.service';
import { environment } from '@env/environment';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-list-layout',
  templateUrl: './list-layout.component.html',
  styleUrls: ['./list-layout.component.scss'],
})
export class ListLayoutComponent implements OnInit {
  faMarker = faLocationArrow;
  @Input() tournaments: Tournament[];
  @Input() isBrowseDirectorTournaments: boolean;
  @Input() orgInfo: any;

  page: any;
  constructor(
    private router: Router,
    private publicTournamentService: PublicTournamentsService
  ) {}

  goToPublicTournament(tournament: any): void {
    if (tournament?.tournamentInfoUrl) {
      window.open(tournament?.tournamentInfoUrl, '_blank');
    } else {
      this.publicTournamentService.setReviewToFalse();
      console.log(
        'NAVIGATE TO ',
        `/public-tournament/${tournament.name
          .replace(/ /g, '-')
          .toLowerCase()}/divisions/${tournament.id}`
      );
      this.router.navigate(
        [
          `/public-tournament/${tournament.name
            .replace(/ /g, '-')
            .toLowerCase()}/divisions/${tournament.id}`,
        ],
        {
          state: tournament,
        }
      );
    }
  }

  getPhoto(tournament: Tournament) {
    return `${environment.api.tournamentPhoto}/${tournament.photo}`;
  }

  getGender(gender: string) {
    switch (gender) {
      case 'MALE':
        return 'Boys';
      case 'FEMALE':
        return 'Girls';
      case 'COED':
        return 'Boys & Girls';
      case 'MALE_FEMALE':
        return 'Boys & Girls';
      default:
        return '';
    }
  }

  goToBrowseDirectorTournaments(orgId: number) {
    this.router.navigate([`/browse-director-tournaments/${orgId}`]);
  }

  ngOnInit(): void {}
}
