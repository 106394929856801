import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CoachPlayerService {

  constructor(private http: HttpService) { }

  /**
   * Gets all the players under a coach
   * @param coachId 
   * @returns 
   */
  getCoachPlayers(coachId: number | string): Observable<any> {
    return this.http
    .get({
      url: `${environment.api.coachPlayers}?userId=${coachId}`,
    });
  }
}
