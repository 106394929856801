<div class="teams">
  <div class="p-col-12 text-bold1">
    Please select a team from the list to add to the division.
  </div>

  <app-public-table
    [thead]="headers"
    [data]="teams"
    [config]="{ actionButtons: ['selectTeam'] }"
    (onActionButtonClick)="selectTeam($event)"
    [isFetching]="fetching"
    [hasResult]="true"
  >
  </app-public-table>

  <div class="p-col-12 flex flex-center mt-4">
    <button
      pButton
      type="button"
      label="Cancel"
      class="
        p-button-raised p-button-rounded p-button-text p-button-plain
        mx-2
        px-5
        py-3
      "
      (click)="close()"
    ></button>
  </div>
</div>

<p-dialog header="Validate Team" [closable]="false" [(visible)]="showConfirm">
  <div class="p-grid custom-modal">
    <div class="p-col-12 text-center my-2">
      <span class="fs-2 text-black5 text-bold1">
        {{ message }}
      </span>
    </div>
    <div class="p-col-12 flex flex-center">
      <span class="my-2 mx-3">
        <button
        pButton
        type="button"
        label="No"
        class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
        (click)="cancel()"
      ></button>
      </span>
      <span class="my-2 mx-3">
        <button
        pButton
        type="submit"
        (click)="confirm()"
        label="Yes"
        class="p-button-raised p-button-rounded p-button-danger mx-2 px-5 py-3"
      ></button>
      </span>
    </div>
  </div>
</p-dialog>