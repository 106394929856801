import { Component, HostListener, Input, OnInit } from '@angular/core';
import { GamePeriod } from '@core/models/game-period';
import { Games } from '@core/models/games';
import { Tournament } from '@core/models/tournament';
import { HttpService } from '@core/services';
import { environment } from '@env/environment';
import { rejects } from 'assert';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

interface Schedule {
  label: string;
  value: string;
}

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.component.html',
  styleUrls: ['./team-info.component.scss'],
})
export class TeamInfoComponent implements OnInit {
  @Input() teamId: string;
  @Input() teamName: string;
  @Input() tournamentId: any;
  games: Games[];
  game: Games;
  doesHaveGames: boolean = false;
  dateAvailable: Schedule[] = [];
  initLength: number;
  tournament: Tournament;
  tournamentDates: any[] = [];
  pools: any[] = [];
  gamePeriods: GamePeriod[] = [];
  allGames: any[] = [];
  games$ = new BehaviorSubject<Games[]>([]);
  isLoading: boolean = true;
  photoUrl: string =
    'http://d37v1cycw3jblm.cloudfront.net/api/tourneykey/v1/teams/logo/';

  innerWidth: any;
  isScreenLess450: any;

  constructor(private _http: HttpService) {}

  ngOnInit(): void {
    this.getTournamentByParams(this.tournamentId);
    this.getGamesWithPeriods();
    this.onResize('event');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 450) {
      this.isScreenLess450 = true;
    } else {
      this.isScreenLess450 = false;
    }
  }

  // tournament dates
  enumarateDays(fromDate, toDate): void {
    const day1 = moment(toDate);
    const day2 = moment(fromDate);
    const result = [moment({ ...day2 })];

    while (day1.date() !== day2.date()) {
      day2.add(1, 'day');
      result.push(moment({ ...day2 }));
    }
    result.map((x) => {
      this.dateAvailable.push({
        label: x.format('dddd, MMMM D, YYYY'),
        value: x.format('MM/DD/YYYY'),
      });
    });
    this.initLength = result.map((x) => x.format('MM/DD/YYYY')).length;
  }

  getGamesWithPeriods() {
    this._http
      .get({
        url: `${environment.api.assignedGames}?tournamentId=${this.tournamentId}&teamId=${this.teamId}`,
        payload: [],
      })
      .toPromise()
      .then((games) => {
        this.games = games;
        console.log('lez g games', this.games);

        let arr = games.map((game, index) => {
          return this._http
            .get({
              url: `${
                environment.api.gamesPeriods
              }?gameId=${game.id.toString()}`,
              payload: [],
            })
            .toPromise()
            .then((g) => {
              return {
                ...games[index],
                gamePeriods: g,
              };
            });
        });

        Promise.all(arr).then((completeGames) => {
          console.log('comppleteGamess', completeGames);

          this.allGames = completeGames;
          this.doesHaveGames = completeGames && completeGames.length > 0;
          this.isLoading = false;
        });
      });
  }

  getDates(): void {
    if (
      this.tournament?.startDate != null &&
      this.tournament?.endDate != null
    ) {
      this.enumarateDays(this.tournament?.startDate, this.tournament?.endDate);
    }
  }

  getTournamentByParams(id) {
    return new Promise((resolve, reject) => {
      this._http
        .get({
          url: `${environment.api.tournaments}/${id}`,
        })
        .toPromise()
        .then((res) => {
          resolve(true);
          this.tournament = res;
          console.log(res);
          this.getDates();
        });
    }).catch((err) => {
      console.log('error...');
      rejects(err);
    });
  }

  getRank(i: number) {
    if (i == 1) return '1st';
    else if (i == 2) return '2nd';
    else if (i == 3) return '3rd';
    else return i + 'th';
  }
}
