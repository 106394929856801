import { Injectable, EventEmitter } from '@angular/core';

// MODEL
import { User } from '@models/user';
import { Organization } from '@models/organization';

@Injectable({
  providedIn: 'root',
})
export class EmitterService {
  pageNameEmitter = new EventEmitter();
  orgEmitter = new EventEmitter();
  meEmitter = new EventEmitter();
  loginEmitter = new EventEmitter<boolean>(true);

  userData: User;
  orgData: Organization;

  constructor() {}

  pageNameEmit(pageName: string): void {
    this.pageNameEmitter.emit(pageName);
  }

  meEmit(user: User): void {
    this.userData = user;
    console.log('EMITTED USER', user);

    this.meEmitter.emit(user);
  }

  orgEmit(org: Organization): void {
    this.orgData = org;
    this.orgEmitter.emit(org);
  }

  loginEmit(isDefault: boolean): void {
    this.loginEmitter.emit(isDefault);
  }

  clearData(): void {
    this.meEmit(null);
    this.orgEmit(null);
    this.loginEmit(true);
  }
}
