<div class="container">
  <div class="row">
    <div class="rightBtns">
      <button
        (click)="showAddPlayer()"
        pButton
        type="button"
        label="ADD PLAYER"
        class="p-button-rounded p-button-danger addBtn"
      ></button>
      <button
        [disabled]="
          this.selectedPlayers == null || this.selectedPlayers.length == 0
            ? true
            : false
        "
        (click)="onDeletePlayer()"
        pButton
        type="button"
        label="DELETE PLAYER"
        class="p-button-rounded p-button-danger deleteBtn"
      ></button>
    </div>
  </div>
  <div class="row">
    <p-table
      #dt
      [value]="players"
      selectionMode="multiple"
      [(selection)]="selectedPlayers"
      [globalFilterFields]="[
        'fullName',
        'position',
        'jerseyNumber1',
        'school',
        'team'
      ]"
      dataKey="id"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <button
            pButton
            label="Clear"
            class="p-button-rounded p-button-danger"
            icon="pi pi-filter-slash"
            (click)="clear(dt)"
          ></button>
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="applyFilterGlobal($event, 'contains')"
              placeholder="Search keyword"
            />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th colspan="2">
            <div>NAME</div>
          </th>
          <th>
            <div class="text-center">POSITION</div>
          </th>
          <th class="text-center">JERSEY #</th>
          <th colspan="2">SCHOOL</th>
          <th class="text-center" colspan="2">TEAM</th>

          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-player>
        <tr>
          <td>
            <p-tableCheckbox [value]="player"></p-tableCheckbox>
          </td>
          <td colspan="2">{{ player?.fullName }}</td>

          <td class="text-center">{{ player?.position }}</td>
          <td class="text-center">{{ player?.jerseyNumber1 }}</td>
          <td colspan="2">{{ player?.school }}</td>

          <td class="text-center" colspan="2">
            {{ player?.team.name }}
          </td>
          <td>
            <i
              class="pi pi-pencil"
              style="cursor: pointer"
              pTooltip="Edit Player"
              (click)="showEditPlayer(player)"
            ></i>
            &nbsp;&nbsp;
            <!-- <i
                class="pi pi-user"
                pTooltip="Manage Players"

              ></i> -->
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No Players found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- <div>
      <p (click)="goTopage()">select</p>
    </div> -->
</div>
