<div class="table-container">
    <p-table
      [responsive]="true"
      [scrollable]="true"
      [value]="[1]"
    >
    
      <ng-template pTemplate="header">
        <tr>
          <th class="w-300">Court</th>
          <th class="w-300">Date</th>
          <!-- <th></th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td class="w-300">
            <div class="full-dropdown">
              <p-dropdown
                appendTo="body"
                [style]="{ width: '100%' }"
                placeholder="Select a Court"
                [options]="courts"
                [(ngModel)]="selectedCourt"
                optionLabel="name"
                styleClass="m-0"
              ></p-dropdown>
            </div>
          </td>
          <td class="w-300">
            <div class="full-dropdown">
              <p-dropdown
                placeholder="Select a Tournament Date"
                appendTo="body"
                [style]="{ width: '100%' }"
                optionLabel="label"
                optionValue="value"
                styleClass="m-0"
                [(ngModel)]="selectedDate"
                [options]="filterCourtAvail(selectedCourt)"
              ></p-dropdown>
            </div>

            <!-- <p-calendar
              [(ngModel)]="assignedGames[index].date"
              [minDate]="minDate"
              [maxDate]="maxDate"
              type="date"
              dateFormat="mm/dd/yy"
              [placeholder]=""
              (onSelect)="updateGame(assignedGames[index])"
            >
            </p-calendar> -->
          </td>
          <!-- <td>
            <input
              type="time"
              [(ngModel)]="assignedGames[index].timeslot"
              class="tournament-input-text"
              (change)="updateGame(assignedGames[index])"
            />
          </td> -->
          <!-- <td class="text-right">
            <button
              pButton
              iconPos="left"
              icon="pi pi-minus"
              pRipple
              type="button"
              label="Delete All Games"
              class="p-button-raised p-button-danger"
            ></button>
          </td> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="p-d-flex p-ai-center p-jc-between"></div>
      </ng-template>
    </p-table>

    <div class="p-col-12 mt-2 flex flex-center">
      <button
        pButton
        pRipple
        type="button"
        label="Cancel"
        class="p-button-raised p-button-rounded p-button-text p-button-plain mx-2 px-5 py-3"
        (click)="close()"
      ></button>
      <button
        pButton
        iconPos="right"
        type="button"
        label="Save"
        (click)="save()"
        [disabled]="!selectedCourt || !selectedDate"
        class="p-button-raised p-button-rounded p-button-danger p-button-plain mx-2 px-5 py-3"
      ></button>
    </div>
  </div>